define("manage/pods/companies/company/projects/project/components/edit-location-zone-weight-price/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      add_price() {
        (this.get('zone.content') || this.get('zone')).addDefaultPrice();
      },

      delete_price(price) {
        let shipping = (this.get('zone.content') || this.get('zone')).get('shipping');
        shipping.get('prices').removeObject(price);
      }

    }
  });

  _exports.default = _default;
});