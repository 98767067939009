define("manage/pods/companies/company/projects/project/segments/components/remove-product-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/filters/products", "manage/mixins/segment-action", "shared/mixins/product-variation-selection", "ember-cp-validations", "ember-concurrency"], function (_exports, _ssModal, _products, _segmentAction, _productVariationSelection, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });
  const lineTypeOptions = [Ember.Object.create({
    id: 'any',
    title: 'Any (External, Extra and Manual)'
  }), Ember.Object.create({
    id: 'external',
    title: 'External'
  }), Ember.Object.create({
    id: 'extra',
    title: 'Extra'
  }), Ember.Object.create({
    id: 'manual',
    title: 'Manual'
  })];

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, _products.default, Validations, _productVariationSelection.default, {
    classNames: ['remove-product-modal', 'segment-modal', 'component'],
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    closable: false,
    type: 'remove-product',
    isRunning: Ember.computed.or('initTask.isRunning', 'saveTask.isRunning'),
    product: Ember.computed.readOnly('order_selection.product'),
    fromBundle: false,
    bundle: null,
    bundleNotDefined: Ember.computed.none('bundle'),
    cannotChooseProduct: Ember.computed.and('fromBundle', 'bundleNotDefined'),
    specificVariation: false,
    line_type: 'any',
    line_type_options: lineTypeOptions,

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let order = this.get('store').createRecord('order', {});
      let order_line = this.get('store').createRecord('order-line', {});
      let order_selection = this.get('store').createRecord('order-selection', {
        project: project,
        order: order,
        line: order_line
      });
      this.set('order_selection', order_selection);
    }),
    filter_bundles: Ember.computed('project.products.@each.{product_type,name}', function () {
      return this.get('project.products').filterBy('product_type', 'bundle').sortBy('name');
    }),
    products_filtered: Ember.computed('fromBundle', 'bundle', 'project.products.@each.{product_type,name}', function () {
      return this.get('filterTask').perform();
    }),
    filterTask: (0, _emberConcurrency.task)(function* () {
      if (!this.get('fromBundle')) {
        return this.get('filter_products');
      } else if (this.get('bundle')) {
        let mappings = yield this.get('bundle.mappings');
        return mappings.filterBy('isDeleted', false).sortBy('sort_order').mapBy('child').filterBy('is_physical_or_digital', true);
      }

      return null;
    }),
    setBundleTask: (0, _emberConcurrency.task)(function* (bundle) {
      bundle = yield bundle;
      bundle = yield this.get('store').findRecord('product', bundle.get('id'), {
        include: 'mappings,mappings.child'
      });
      this.set('bundle', bundle); // If the selected product isn't in the newly chosen bundle, null out the product.

      let currentProduct = this.get('product');

      if (currentProduct != null) {
        let products_filtered = yield this.get('products_filtered');

        if (!products_filtered.find(product => product.get('id') == currentProduct.get('id'))) {
          this.set('order_selection.product', null);
          this.set('order_selection.product_variation', null);
        }
      }
    }),
    setProductTask: (0, _emberConcurrency.task)(function* (product) {
      try {
        product = yield product;
        product = yield this.get('store').findRecord('product', product.get('id'), {
          include: ['variations'].join(',')
        });
        let order_selection = yield this.get('order_selection');
        order_selection.set('product', product);
        order_selection.set('product_variation', null);
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to update product selection."
        });
      }
    }).drop(),
    show_selection_error_message: Ember.computed('submitted', 'specificVariation', 'order_selection.in_bundle', 'order.is_completed', 'order_selection.validations.isInvalid', function () {
      if (!this.get('submitted') || !this.get('specificVariation')) {
        return false;
      }

      return this.get('order_selection.validations.isInvalid');
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let order_selection = yield this.get('order_selection'); // Validate that the variation is chosen if the specificVariation option is chosen.

      if (this.get('specificVariation')) {
        let {
          validations: selection_validations
        } = yield order_selection.validate();

        if (!selection_validations.get('isValid')) {
          return;
        }
      }

      let product = yield order_selection.get('product');

      if (this.get('actionTask.isRunning')) {
        return;
      }

      let args = {
        remove_from_type: this.get('fromBundle') ? 'bundle' : 'selection',
        product_id: product.get('id')
      };

      if (this.get('specificVariation')) {
        args['product_variation_id'] = this.get('order_selection.product_variation.id');
      } // If we're removing from a bundle, pass along the bundle id.
      // Otherwise, make sure we pass along the line_type.


      if (this.get('fromBundle')) {
        args['bundle_id'] = this.get('bundle.id');
      } else {
        args['line_type'] = this.get('line_type');
      }

      this.get('actionTask').perform(args);
    }),
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform();
      },

      setSpecificVariationBool(value) {
        this.set('specificVariation', value);

        if (!value) {
          this.set('order_selection.product_variation', null);
        }
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});