define("manage/pods/user/signup/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    passwordStrength: Ember.inject.service(),

    beforeModel() {
      const passwordStrength = this.get('passwordStrength');
      return passwordStrength.load();
    },

    model() {
      return Ember.RSVP.hash({
        user: this.get('store').createRecord('user'),
        account: this.get('store').createRecord('account')
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
        controller.set('account_submitted', false);
        controller.set('account_status', 'UNKNOWN');
        controller.set('error', false);
        controller.set('accept_agreements', false);
      }
    }

  });

  _exports.default = _default;
});