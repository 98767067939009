define("manage/pods/companies/company/projects/project/orders/view/components/edit-address-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "shared/mixins/address-state-selection"], function (_exports, _ssModal, _emberConcurrency, _addressStateSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _addressStateSelection.default, {
    current_user: Ember.inject.service('user'),
    // address: gets set in initiateAddressTask
    order: null,
    orderTasks: Ember.inject.service(),
    had_company_name: false,

    closing() {
      let address = this.get('address');
      address.rollbackAttributes();
      address.rollbackRelationships();
    },

    init() {
      this._super(...arguments);

      this.get('initiateAddressTask').perform();
    },

    initiateAddressTask: (0, _emberConcurrency.task)(function* () {
      let order = yield this.get('order');
      let shipping_address = yield order.get('shipping_address'); // need to set this up for both rewards and shipping

      if (Ember.isBlank(shipping_address)) {
        shipping_address = yield this.get('orderTasks.generateOrderShippingAddress').perform(order); // must set inverse here explicitly, inverse is set to null on shipping address

        order.set('shipping_address', shipping_address);
      }

      this.set('address', shipping_address);
      this.set('had_company_name', Ember.isPresent(this.get('address.company_name')));
      yield this.get('verifyStateTask').perform(this.get('address'));
    }),
    updateAddressTask: (0, _emberConcurrency.task)(function* () {
      let order = yield this.get('order');
      let shipping_address = yield this.get('address');
      shipping_address.set('country_only', true);
      this.set('submitted', true);
      this.set('has_error', false);
      let {
        validations: validations
      } = yield shipping_address.validate();

      if (!validations.get('isValid')) {
        return; // Invalid show error
      }

      let wasNew = shipping_address.get('isNew');

      try {
        yield shipping_address.save();

        if (wasNew) {
          yield order.save();
        }
      } catch (e) {
        this.set('has_error', true);
        return;
      }

      this.closeModal();
    }).drop(),
    actions: {
      countryChanged() {
        this.get('verifyStateTask').perform(this.get('address'));
      }

    }
  });

  _exports.default = _default;
});