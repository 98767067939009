define("manage/components/bread-crumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5LTYyF/d",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@activeClass\"],[[22,\"path\"],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"text\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"translatedText\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/bread-crumb/template.hbs"
    }
  });

  _exports.default = _default;
});