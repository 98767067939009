define("manage/components/froala-editor/component", ["exports", "ember-froala-editor/components/froala-editor", "shared/utils/logger"], function (_exports, _froalaEditor, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _froalaEditor.default.extend({
    session: Ember.inject.service(),

    init() {
      if (Ember.isPresent(this.get('project'))) {
        let config = Ember.getOwner(this).resolveRegistration('config:environment');

        if (!config.urls.api) {
          (0, _logger.logger_error)("missing required url for api");
        }

        let host = config.urls.api;
        this.set('imageUploadParams', {
          type: 'story',
          froala: true
        });
        this.set('imageUploadURL', `${host}/images/project/${this.get('project.id')}`); // I gathered this from the sample size of all stories in project_sources that had custom properties
        // I removed any properties that were always default or zero

        this.set('pasteAllowedStyleProps', ['color', 'background-color', 'list-style', 'list-style-type', 'text-align', 'margin', 'padding', 'font-weight', 'font-style', 'font-size', 'max-width', 'text-decoration']);
      } else {
        // Currently project is ONLY set on story edit, everywhere else should have limited styling
        this.set('pasteAllowedStyleProps', ['color', 'background-color', 'list-style', 'list-style-type', 'font-style', 'font-weight']);
      }

      this._super(...arguments);
    },

    didUpdateAttrs() {
      // HACK: The editor was being called in tests before being initialized. Only proceed it its initialized
      if (this.get('_editorInitialized') === false) {
        return;
      }

      this._super(...arguments);
    },

    'on-image.beforeUpload': function () {
      let editor = this.get('editor');

      if (Ember.isPresent(editor)) {
        if (editor.opts.requestHeaders == null) {
          editor.opts.requestHeaders = {};
        }

        let {
          token,
          type,
          order_id
        } = this.get('session.data.authenticated') || {};

        if (Ember.isPresent(token)) {
          if (type === 'authentication_token' && Ember.isPresent(order_id)) {
            editor.opts.requestHeaders['Authorization'] = `Token token="${token}", order_id="${order_id}"`;
          } else if (type === 'token') {
            editor.opts.requestHeaders['Authorization'] = `Bearer ${token}`;
          }
        }
      }
    },
    // Clearing some local storage parts, to try to fix issue with cached plain and formatted text
    // https://github.com/froala/wysiwyg-editor/issues/3806
    // Ticket showed window.copy, but this should clear it before any paste.
    'on-paste.before': function () {
      window.localStorage.removeItem("fr-copied-html");
      window.localStorage.removeItem("fr-copied-text");
    },
    options: {
      attribution: false,
      imageResize: false,
      imageDefaultWidth: 0,
      imageDefaultAlign: 'center',
      imageMaxSize: 1024 * 1024 * 30,
      // 30 megs, matching backend
      imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt'],
      linkAlwaysBlank: true,
      paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4' // PRE: 'Code'

      },
      toolbarButtons: [['bold', 'italic', 'underline', 'textColor', 'backgroundColor'], ['insertLink', 'formatOL', 'formatUL'], ['selectAll', 'clearFormatting']],
      toolbarStickyOffset: 78,
      videoInsertButtons: ['videoBack', '|', 'videoByURL'],
      videoResponsive: true // Probably won't use: insertVideo, embedly, inlineStyle, strikeThrough, subscript, superscript, fontFamily, insertTable, emoticons, html, spellChecker, specialCharacters
      // Might use: paragraphFormat, paragraphStyle, outdent, indent, undo, redo, help, fontSize

    }
  });

  _exports.default = _default;
});