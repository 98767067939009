define("manage/pods/admin/companies/components/create-company-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ezxSMG9T",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"create header\"],[8],[0,\"\\n  Create a Company\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form content \",[28,\"if\",[[24,[\"saving\"]],\"loading\"],null],\" \",[28,\"if\",[[28,\"and\",[[24,[\"submitted\"]],[28,\"get\",[[28,\"get\",[[24,[\"company\"]],\"validations\"],null],\"isInvalid\"],null]],null],\"error\"],null]]]],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"company\",\"validations\",\"messages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"company\"]],\"name\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"company\",\"name\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button negative\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.close\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[1,[28,\"t\",[\"phrase.create\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/admin/companies/components/create-company-modal/template.hbs"
    }
  });

  _exports.default = _default;
});