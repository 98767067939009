define("manage/pods/companies/company/projects/project/sources/extras/components/mapped-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YFBu+IU5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\",true],[10,\"class\",\"header\"],[10,\"colspan\",\"3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n      \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"product\",\"description\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n          \"],[1,[24,[\"product\",\"description\"]],true],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"i\",false],[12,\"class\",\"edit icon\"],[12,\"title\",[28,\"t-component\",[[23,0,[]],\"content.edit_product\"],null]],[3,\"action\",[[23,0,[]],\"open_modal\",\"companies/company/projects/project/sources/extras/components/section-modal\",[28,\"hash\",null,[[\"product\"],[[24,[\"product\"]]]]]]],[8],[9],[0,\"\\n  \"],[7,\"i\",false],[12,\"class\",\"remove icon blue\"],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/extras/components/mapped-section/template.hbs"
    }
  });

  _exports.default = _default;
});