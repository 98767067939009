define("manage/pods/companies/company/projects/project/questions/components/edit-product-question-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AV2dPqUM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui horizontal divider\"],[8],[0,\"Optional Text Configurations\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fields\"],[8],[0,\"\\n\"],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"class\",\"submitted\"],[[24,[\"question\"]],\"min_length\",\"four wide\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[28,\"t-component\",[[23,0,[]],\"content.minimum_length.label\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"min\",\"step\"],[\"number\",[24,[\"question\",\"min_length\"]],\"0\",\"1\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"class\",\"submitted\"],[[24,[\"question\"]],\"max_length\",\"four wide\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[28,\"t-component\",[[23,0,[]],\"content.maximum_length.label\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"min\",\"step\"],[\"number\",[24,[\"question\",\"max_length\"]],\"0\",\"1\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/questions/components/edit-product-question-text/template.hbs"
    }
  });

  _exports.default = _default;
});