define("manage/resolver", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberResolver.default.extend({
    moduleNameLookupPatterns: Ember.computed(function () {
      var defaults = this._super();

      return defaults.concat([this.podBasedComponentsInResource]);
    }),

    podBasedComponentsInResource(parsedName) {
      var podPrefix = this.namespace.podModulePrefix || this.namespace.modulePrefix;

      if (parsedName.type === 'component' || parsedName.fullNameWithoutType.match(/^components/)) {
        var nameWithoutComponent = parsedName.fullNameWithoutType.replace(/components\//, '');
        var slash = nameWithoutComponent.lastIndexOf('/');

        if (slash > 0) {
          podPrefix = podPrefix + '/' + nameWithoutComponent.substr(0, slash) + '/components';
          parsedName.fullNameWithoutType = nameWithoutComponent.substr(slash + 1);
          return this.podBasedLookupWithPrefix(podPrefix, parsedName);
        }
      }

      return;
    },

    podBasedComponentsInSubdir(parsedName) {
      var podPrefix = this.namespace.modulePrefix; // @namespace.podModulePrefix || @namespace.modulePrefix

      podPrefix = podPrefix + '/components';

      if (parsedName.type === 'component' || parsedName.fullNameWithoutType.match(/^components/)) {
        return this.podBasedLookupWithPrefix(podPrefix, parsedName);
      }
    }

  });

  _exports.default = _default;
});