define("manage/pods/companies/company/projects/project/welcome/location/controller", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/pods/companies/company/projects/project/welcome/wizard"], function (_exports, _emberConcurrency, _catchReal, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_wizard.default, {
    company: Ember.computed.readOnly('model.company'),
    project: Ember.computed.readOnly('model.project'),
    intl: Ember.inject.service(),
    submitted: false,
    stepName: 'location',
    submitTask: (0, _emberConcurrency.task)(function* (company) {
      company = yield company;
      company.set('shouldSetCountry', true);
      let {
        validations: validations
      } = yield company.validate();

      if (!validations.get('isValid')) {
        return false;
      }

      yield company.save();
      yield this.get('advanceTask').perform(this.get('project'));
      return true;
    }),
    actions: {
      submit() {
        this.set('submitted', true);

        if (this.get('submitTask.isRunning')) {
          return;
        }

        let intl = this.get('intl');
        this.get('submitTask').perform(this.get('company')).then(result => {
          if (result) {
            this.transitionToRoute(this.get('nextRoute')(this.get('project')));
          }
        }).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t('route.companies.company.projects.project.welcome.error.unable_to_save')
          });
        }));
      },

      onCountryChange(country) {
        let company = this.get('company');
        company.set('country', country);
        company.set('state', null);
      }

    }
  });

  _exports.default = _default;
});