define("manage/components/menu-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MenuSelectionComponent = Ember.Component.extend({
    classNames: ['item', 'nested', 'menu-selection', 'component'],
    targetRouteName: null,
    rootRouteName: "index",
    activeClass: 'active',
    models: null,
    targetRouteNameIndex: Ember.computed('targetRouteName', 'rootRouteName', function () {
      if (Ember.isPresent(this.get('rootRouteName'))) {
        return this.get('targetRouteName') + '.' + this.get('rootRouteName');
      }

      return this.get('targetRouteName');
    }),

    didReceiveAttrs() {
      let params = Ember.get(this, 'params');

      if (params) {
        // Do not mutate params in place
        params = params.slice();
      }

      (false && !((() => {
        if (!params) {
          return false;
        }

        return params.length;
      })()) && Ember.assert('You must provide one or more parameters to the link-to component.', (() => {
        if (!params) {
          return false;
        }

        return params.length;
      })())); // 1. `targetRouteName` is now always at index 0.

      this.set('targetRouteName', params[0]); // 2. Any remaining indices (excepting `targetRouteName` at 0) are `models`.

      if (params.length > 1) {
        this.set('models', this._getModels(params));
      } else {
        this.set('models', []);
      }
    },

    _getModels(params) {
      let modelCount = params.length - 1;
      let models = new Array(modelCount);

      for (let i = 0; i < modelCount; i++) {
        let value = params[i + 1];
        models[i] = value;
      }

      return models;
    }

  });
  MenuSelectionComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = MenuSelectionComponent;
  _exports.default = _default;
});