define("manage/models/project-zone-location", ["exports", "manage/utils/generated-model", "manage/mixins/zone-location"], function (_exports, _generatedModel, _zoneLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-zone-location').extend(_zoneLocation.default, {
    percent: Ember.computed.readOnly('zone.percent'),
    is_shipping_weight_based: Ember.computed.equal('cost_type', 'shipping_weight_based'),
    is_sales_tax: Ember.computed.equal('cost_type', 'sales_tax'),
    is_vat: Ember.computed.equal('cost_type', 'vat'),
    is_customs: Ember.computed.equal('cost_type', 'customs')
  });

  _exports.default = _default;
});