define("manage/pods/companies/company/projects/project/launch-guide/components/lg-local-pickup/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('loadAddressesTask').perform();
    },

    store: Ember.inject.service(),
    completed: Ember.computed('project.addresses.[]', 'project.settings.wizard.skip_local_pickup', 'project.{is_live,is_complete}', function () {
      if (this.get('project.addresses.length') > 0) {
        return true;
      }

      if (this.get('project.settings.wizard.skip_local_pickup')) {
        return true;
      }

      if (this.get('project.is_live') || this.get('project.is_complete')) {
        return true;
      }

      return false;
    }),
    isRunning: Ember.computed.or('loadAddressesTask.isRunning', 'skipLocalPickupTask.isRunning'),
    loadAddressesTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      yield this.get('store').findRecord('project', project.get('id'), {
        include: ['addresses'].join(',')
      });
    }),
    skipLocalPickupTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      project.set('settings.wizard.skip_local_pickup', true);
      yield project.save();
    }).drop()
  });

  _exports.default = _default;
});