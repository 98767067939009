define("manage/pods/companies/company/projects/project/questions/components/edit-product-question-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    attributeBindings: ['modalName:data-test-modal'],
    modalName: 'edit-product-question-modal',
    submitted: false,
    closable: false,
    actions: {
      approve() {
        this.set('submitted', true);

        if (this.get('question.validations.isInvalid')) {
          return;
        }

        if (this.get('question.isNew')) {
          // Set a fake flag to know if they open back up its been created previously
          this.set('question.created', true);
        }

        this.closeModal();
      },

      close() {
        if (!this.get('question.isNew') || this.get('question.isNew') && !this.get('question.created')) {
          this.get('question').rollbackAttributes();
        }

        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});