define("manage/pods/user/activate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),

    model() {
      let current_user = this.get('current_user');
      return Ember.RSVP.hash({
        company: current_user.get('companies').objectAt(0) || this.get('store').createRecord('company', {
          name: current_user.get('full_name'),
          purpose: 'ecommerce'
        })
      });
    },

    redirect(model) {
      this._super(...arguments);

      if (!model.company.get('isNew')) {
        this.transitionTo('companies');
        return;
      }
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    }

  });

  _exports.default = _default;
});