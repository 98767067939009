define("manage/pods/companies/company/projects/project/products/choices/edit/route", ["exports", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return this.store.findRecord('product', params.product_id, {
        include: ['mappings', 'mappings.child', 'mappings.child.questions', 'zones', 'zones.locations'].join(',')
      }).then(product => {
        let product_types = ['physical', 'digital', 'unknown'];

        if (product.get('is_bundle')) {
          product_types.push('question');
          product_types.push('option');
        }

        return Ember.RSVP.hash({
          project: project.reload(),
          // Update create if you update this
          all_products: this.store.query('product', {
            filter: {
              project: project.get('id'),
              product_type: product_types
            },
            include: ['questions'].join(','),
            page: {
              size: _environment.default['max-page-size']
            }
          }),
          product: product
        });
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product) {
          let product = record.product;
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(product).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: product,
                  modelName: "Product Option",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});