define("manage/components/icon-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['svg', 'icon'],
    width: "22px",
    height: "23px"
  });

  _exports.default = _default;
});