define("manage/components/ui-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XVobHObX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t\",[[28,\"concat\",[[24,[\"path\"]],\".label\"],null]],null],false],[0,\"\\n\"],[4,\"if\",[[28,\"t-exists\",[[28,\"concat\",[[24,[\"path\"]],\".help\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[28,\"t\",[[28,\"concat\",[[24,[\"path\"]],\".help\"],null]],null]],[10,\"target\",\"_blank\"],[10,\"data-inverted\",\"\"],[11,\"data-tooltip\",[28,\"if\",[[28,\"t-exists\",[[28,\"concat\",[[24,[\"path\"]],\".tooltip\"],null]],null],[28,\"t\",[[28,\"concat\",[[24,[\"path\"]],\".tooltip\"],null]],null],[28,\"t\",[\"phrase.external_help\"],[[\"subject\"],[[28,\"t\",[[28,\"concat\",[[24,[\"path\"]],\".subject\"],null]],null]]]]],null]],[10,\"data-size\",\"medium\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"question circle icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"t-exists\",[[28,\"concat\",[[24,[\"path\"]],\".tooltip\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"medium\"],[11,\"data-tooltip\",[28,\"t\",[[28,\"concat\",[[24,[\"path\"]],\".tooltip\"],null]],null]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"question circle icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/ui-label/template.hbs"
    }
  });

  _exports.default = _default;
});