define("manage/components/delete-confirmation-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/mixins/error-from-xhr"], function (_exports, _ssModal, _emberConcurrency, _errorFromXhr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  Delete Confirmation Modal
  Valid arguments:
    title: (string) Title for the modal. Default value is "Confirm"
    question: (string) Question for the modal.
    description: (string) Description for the modal. Optional.
    approve_label: (string) Text for the "approve" button. Defaults to "Yes".
    cancel_label: (string) Text for the "cancel" button. Defaults to "No".
    i18nKey: (string) If set, uses it as a key underneath `delete-confirmation-modal`
             in the i18n dictionary to find the title, question,
             errors, and optionally the description for this modal. If set and these
             keys are found in the translation, they take precedence over
             any values that may be set in +title+, +question+, and +description+.
    i18nParams: (object) If set, values will be provided to all i18n lookups
                used with the i18nKey.
    approve: (function) The function to call when the user clicks the approve button.
             The function should return a resolved promise.
    cancel: (function) The function to call when the user clicks the cancel button.
            Also executed when the user closes the dialog with the Close button
            after an error.
  */
  var _default = Ember.Component.extend(_ssModal.default, (0, _errorFromXhr.default)('delete-confirmation-modal'), {
    classNames: ['delete-confirmation-modal', 'component'],
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    displayedTitle: Ember.computed('i18nKey', function () {
      return this.intlWithDefault('title');
    }),
    displayedQuestion: Ember.computed('i18nKey', function () {
      return this.intlWithDefault('question');
    }),
    entityName: Ember.computed('i18nKey', function () {
      return this.intlWithDefault('entity-name', 'Entity');
    }),
    deletable: Ember.computed('result.deletable', function () {
      return this.get('result.deletable') || false;
    }),
    messages: Ember.computed('result.summary', 'deletable', 'i18nParams', 'entityName', function () {
      const params = this.i18nParams || {};

      if (Ember.isBlank(this.get('result.summary'))) {
        if (this.get('deletable')) {
          return Ember.A();
        } // If its not deletable and we don't have a summary, return generic error message


        return Ember.A([{
          key: 'generic',
          level: 'error',
          text: this.intl.t('delete-confirmation-modal.errors.default', {
            'entity-name': this.get('entityName')
          })
        }]);
      }

      let messages = Ember.A();
      let keys = Object.keys(this.result.summary);

      for (let key of keys) {
        let item = this.result.summary[key];
        messages.push({
          key: key,
          level: item.level,
          text: this.intlWithDefault('errors.' + key, item.description, Object.assign({}, params, item)),
          related_entity_names: item.related_entity_names
        });
      }

      return messages;
    }),

    intlWithDefault(property, default_text) {
      let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.i18nParams;

      if (this.i18nKey) {
        let intlKey = `delete-confirmation-modal.${this.i18nKey}.${property}`;

        if (this.intl.exists(intlKey)) {
          return this.intl.t(intlKey, params);
        }
      } // fallback


      return default_text;
    },

    error: null,
    entity: null,

    approve() {},

    init() {
      this._super(...arguments);

      this.set('related', Ember.Object.create({}));
      this.get('checkEntityForDeletion').perform();
    },

    checkEntityForDeletion: (0, _emberConcurrency.task)(function* () {
      const entity = yield this.get('entity');

      if (Ember.isBlank(entity)) {
        return;
      } // If its new, just show simple removal dialog, nothing to delete on the backend


      if (entity.get('isNew')) {
        this.set('result', {
          deletable: true
        });
        return;
      }

      try {
        let result = yield this.get('ajax').requestModel(entity, 'deletable');
        this.set('result', result);
      } catch (xhr) {
        this.set('error', this.errorFromXhr(xhr, {
          'entity-name': this.get('entityName')
        }));
      }
    }),
    actions: {
      cancel() {
        this.closeModal();
      },

      approve() {
        this.set('loading', true);
        Ember.RSVP.resolve(this.get('approve')()).then(() => this.closeModal()).catch(xhr => {
          this.set('error', this.errorFromXhr(xhr, {
            'entity-name': this.get('entityName')
          }));
        }).finally(() => this.set('loading', false));
      },

      toggle_related(key) {
        this.get('related').set(key, !(this.get('related').get(key) || false));
      }

    }
  });

  _exports.default = _default;
});