define("manage/pods/unauthenticated/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    user: Ember.inject.service(),

    model() {
      return this.get('user.refreshAndPersistSessionTask').perform();
    },

    redirect() {
      if (this.get('user.session.isAuthenticated')) {
        let transition = this.get('user.session.attemptedTransition');

        if (transition) {
          transition.retry();
        } else {
          this.transitionTo('index');
        }
      } else {
        this.get('user.session').invalidate();
      }
    }

  });

  _exports.default = _default;
});