define("manage/pods/companies/company/projects/project/digital/components/assign-all-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    fulfillment: null,
    jobId: null,
    isRunning: Ember.computed.or('assignKeysTask.isRunning', 'jobId'),
    assignKeysTask: (0, _emberConcurrency.task)(function* (fulfillment) {
      this.set('isComplete', false);
      fulfillment = yield fulfillment;
      let result = yield this.get('ajax').postModel(fulfillment, 'assign_all');
      this.set('jobId', result.data.id);
    }),
    actions: {
      close() {
        this.closeModal();
      },

      assign() {
        this.set('error', null);
        this.get('assignKeysTask').perform(this.get('fulfillment'));
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});