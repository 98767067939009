define("manage/pods/companies/company/projects/project/launch-guide/components/lg-source-authorized/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('checkSourceTask').perform();
    },

    completed: Ember.computed.not('source.needs_to_authorize'),
    isRunning: Ember.computed.or('checkSourceTask.isRunning', 'updateAccessRequestedOnTask.isRunning'),
    checkSourceTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let sources = yield project.get('sources');
      let external_source = sources.filterBy('is_external_sync', true).sortBy('id').objectAt(0);

      if (Ember.isBlank(external_source)) {
        this.set('visible', false);
        return;
      }

      if (external_source.get('can_authorize') === false) {
        this.set('visible', false);
        return;
      }

      this.set('source', external_source);
    }),
    updateAccessRequestedOnTask: (0, _emberConcurrency.task)(function* () {
      let source = yield this.get('source');
      source.set('settings.wizard.access_requested_on', new Date());
      yield source.save();
    }).drop()
  });

  _exports.default = _default;
});