define("manage/pods/companies/company/projects/project/segments/components/save-segment-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/mixins/order-filters"], function (_exports, _ssModal, _emberConcurrency, _orderFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _orderFilters.default, {
    classNames: ['save-segment-modal', 'segment-modal', 'component'],
    closable: false,
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    project_segment: null,
    behavior: null,
    isRunning: Ember.computed.alias('saveTask.isRunning'),
    filter_data: Ember.computed.alias('project_segment.filter'),

    init() {
      this._super(...arguments);

      this.set('filters', Ember.A());

      if (!this.get('project_segment')) {
        this.set('project_segment', this.get('store').createRecord('project-segment', {
          project: this.get('project'),
          user: this.get('user'),
          category: this.get('behavior') === 'dynamic' ? 'filter' : 'user',
          behavior: this.get('behavior'),
          hidden: false,
          closed: false,
          count: this.get('count') || 0,
          filter: this.get('filter')
        }));
      }

      this.build_filter_selections(this.get('filter_data'));
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.process_filters_from_data();
      this.process_friendly_text();
    },

    update_friendly(friendly_text) {
      this.set('project_segment.name', friendly_text || 'All Orders');
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      this.set('project_segment.hidden', false);
      let {
        validations: validations
      } = yield this.get('project_segment').validate();
      if (!validations.get('isValid')) return;
      yield this.get('project_segment').save();
      this.closeModal();

      if (this.get('saved')) {
        this.get('saved')(this.get('project_segment'));
      }
    }),
    actions: {
      perform() {
        this.set('submitted', true);
        this.get('saveTask').perform();
      }

    }
  });

  _exports.default = _default;
});