define("manage/pods/companies/company/projects/project/shipping/local-pickups/create/route", ["exports", "manage/mixins/handle-transition", "ember-concurrency"], function (_exports, _handleTransition, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    controllerName: 'companies.company.projects.project.shipping.local-pickups.edit',
    templateName: 'companies.company.projects.project.shipping.local-pickups.edit',

    model() {
      let project = this.modelFor('companies.company.projects.project');
      return this.get('store').createRecord('project-address', {
        project: project
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    rollbackModelsTask: (0, _emberConcurrency.task)(function* (project_address) {
      project_address = yield project_address;
      project_address.rollbackAttributes();
    }),
    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && !record.get('isDeleted')) {
          this.handleTransition(transition, resolve => {
            if (record.get('hasDirtyAttributes')) {
              this.send('open_modal', 'cancel-modal', {
                model: record,
                modelName: "Local Pickup",
                serviceTaskAction: this.get('rollbackModelsTask'),
                promiseResolve: resolve
              });
            } else {
              // We don't have a dirty model, so mark as handled and retry
              resolve('retry');
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});