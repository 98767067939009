define("manage/pods/companies/company/projects/project/orders/view/components/unlock-order-modal/component", ["exports", "manage/mixins/ss-modal", "ember-ajax/errors"], function (_exports, _ssModal, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    intl: Ember.inject.service(),
    has_error: false,
    is_processing: false,
    order: null,

    init() {
      this._super(...arguments);

      this.set('process_options', [Ember.Object.create({
        id: 'all',
        title: this.get('intl').t('locks.all.title')
      })].concat(this.get('order.locked').map(item => {
        return Ember.Object.create({
          id: item.process,
          title: this.get('intl').t(`locks.${item.process}.title`)
        });
      })));
    },

    process: 'all',
    export_lock: Ember.computed.filterBy('order.locked', 'process', 'export'),
    lock_level: 'order_selections',
    lock_level_options: [Ember.Object.create({
      id: 'orders',
      title: 'Orders'
    }), Ember.Object.create({
      id: 'order_selections',
      title: 'Order Selections'
    })],
    actions: {
      approve() {
        if (this.get('is_processing')) {
          return;
        }

        this.set('is_processing', true); // Only set confirmed to true if we are exported at the moment we try to unlock

        return this.get('order').unlock(this.get('process'), this.get('lock_level')).then(() => {
          return this.closeModal();
        }).catch(e => {
          if (e instanceof _errors.InvalidError) {
            this.get('order').reload();
          }

          return this.set('has_error', true);
        }).finally(() => {
          return this.set('is_processing', false);
        });
      }

    }
  });

  _exports.default = _default;
});