define("manage/pods/admin/companies/company/components/edit-purpose-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    submitted: false,
    // Passed into component
    project: null,
    purposes: ["pledge_management", "pre_order", "ecommerce"],
    saveTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      yield project.change_purpose(this.get('project.purpose'));
      yield project.reload();
    }),
    actions: {
      approve() {
        this.set('submitted', true);
        this.get('saveTask').perform(this.get('project')).then(() => this.closeModal()).catch(() => alert('Unable to save!'));
      }

    }
  });

  _exports.default = _default;
});