define("manage/models/project-order-stat", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-order-stat').reopen({
    total_transactions: Ember.computed('total_external_transactions', 'total_crowdox_transactions', function () {
      return this.get('total_external_transactions') + this.get('total_crowdox_transactions');
    }),
    total_orders_not_invited_percent: Ember.computed('total_orders', 'total_orders_not_invited', function () {
      if (this.get('total_orders') === 0 && this.get('total_orders_not_invited') > 0) {
        return 1;
      }

      if (this.get('total_orders_not_invited') === 0) {
        return 0;
      }

      return this.get('total_orders_not_invited') / this.get('total_orders');
    }),
    total_orders_incomplete_percent: Ember.computed('total_orders', 'total_orders_incomplete', function () {
      if (this.get('total_orders') === 0 && this.get('total_orders_incomplete') > 0) {
        return 1;
      }

      if (this.get('total_orders_incomplete') === 0) {
        return 0;
      }

      return this.get('total_orders_incomplete') / this.get('total_orders');
    }),
    total_orders_at_confirmed_percent: Ember.computed('total_orders_confirmed', 'total_orders', function () {
      if (this.get('total_orders') === 0 && this.get('total_orders_confirmed') > 0) {
        return 1;
      }

      if (this.get('total_orders_confirmed') === 0) {
        return 0;
      }

      return this.get('total_orders_confirmed') / this.get('total_orders');
    })
  });

  _exports.default = _default;
});