define("manage/models/project-segment", ["exports", "ember-cp-validations", "manage/utils/generated-model"], function (_exports, _emberCpValidations, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 200
      })]
    }
  });

  var _default = (0, _generatedModel.default)('project-segment').reopen(Validations, {
    is_fixed: Ember.computed.equal('behavior', 'fixed'),
    is_dynamic: Ember.computed.equal('behavior', 'dynamic'),
    is_automated: Ember.computed.equal('category', 'automated'),
    has_filter: Ember.computed('filter', function () {
      return Object.keys(this.get('filter')).reject(key => key === 'project_id').any(key => {
        return typeof this.get(`filter.${key}`) === 'object' && Object.keys(this.get(`filter.${key}`)) > 0 || typeof this.get(`filter.${key}`) !== 'object' && Ember.isPresent(this.get(`filter.${key}`));
      });
    })
  });

  _exports.default = _default;
});