define("manage/pods/companies/company/projects/project/sources/configurations/components/authorize-source-modal/component", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/mixins/ss-modal"], function (_exports, _emberConcurrency, _catchReal, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    authorizeTask: (0, _emberConcurrency.task)(function* (source) {
      source = yield source;
      let result = yield source.authorize();

      if (result.result === true) {
        yield source.reload();
      }

      return result;
    }),
    actions: {
      authorize() {
        if (this.get('authorizeTask.isRunning')) {
          return;
        }

        let source = this.get('source');
        this.set('message', null);
        this.set('message_color', null);
        this.get('authorizeTask').perform(source).then(result => {
          if (result.result) {
            this.set('message', 'Successfully authorized project source.');
            this.set('message_color', 'green');
          } else {
            this.set('message', 'Project source was unable to successfully authorize.');
            this.set('message_color', 'yellow');
          }
        }).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to authorize source."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});