define("manage/models/project-segment-action", ["exports", "manage/utils/generated-model", "ember-cp-validations"], function (_exports, _generatedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    user: {
      description: "User",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    project_segment: {
      description: "Project Segment",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = (0, _generatedModel.default)('project-segment-action').reopen(Validations, {
    ajax: Ember.inject.service(),
    is_new: Ember.computed.equal('state', 'new'),
    is_working: Ember.computed.equal('state', 'working'),
    is_in_error: Ember.computed.equal('state', 'in_error'),
    is_active: Ember.computed.or('is_new', 'is_working', 'is_in_error'),
    is_errored: Ember.computed.equal('state', 'errored'),
    is_canceled: Ember.computed.equal('state', 'canceled'),
    can_restart: Ember.computed.or('is_canceled', 'is_errored'),

    cancel() {
      return this.get('ajax').postModel(this, 'cancel');
    },

    restart() {
      return this.get('ajax').postModel(this, 'restart');
    }

  });

  _exports.default = _default;
});