define("manage/models/order-sync", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Validations are here in stead of on shared, because it should be a manage only concern
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    order: {
      description: "Order",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  }); // Not included in schema.json since it's polymorphic

  var _default = _model.default.extend(Validations, {
    project: (0, _model.belongsTo)('project'),
    order: (0, _model.belongsTo)('order', {
      inverse: 'syncs'
    }),
    order_selections: (0, _model.hasMany)('order-selection', {
      inverse: null
    }),
    provider: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    synced_at: (0, _model.attr)('date'),
    state: (0, _model.attr)('string'),
    external_id: (0, _model.attr)('string'),
    external_key: (0, _model.attr)('string'),
    external_number: (0, _model.attr)('string')
  });

  _exports.default = _default;
});