define("manage/pods/companies/company/projects/project/questions/components/edit-product-question-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-product-question-text'],
    text_types: [Ember.Object.create({
      name: 'Any Text',
      value: 'any'
    }), Ember.Object.create({
      name: 'Email',
      value: 'email'
    }), Ember.Object.create({
      name: 'Phone Number',
      value: 'phone'
    }), Ember.Object.create({
      name: 'Number Only',
      value: 'numeric'
    }), Ember.Object.create({
      name: 'Money',
      value: 'money'
    })]
  });

  _exports.default = _default;
});