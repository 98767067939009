define("manage/pods/companies/company/projects/project/components/links-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['links-modal', 'component', 'large'],
    copied_retrieve: "blue",
    copied_preorder: "blue",
    closable: true,

    init() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('project'))) {
        this.get('project.sources').forEach(source => {
          if (source.get('show_external')) {
            // Set a color up for each site button
            this.set(source.get('site'), 'blue');
          }
        });
      }
    },

    clear_property(property_name) {
      this.set(property_name, 'blue');
    },

    actions: {
      copied(property_name) {
        this.set(property_name, 'green');
        Ember.run.later(this, this.clear_property, property_name, 1000);
      }

    }
  });

  _exports.default = _default;
});