define("manage/pods/companies/company/projects/project/digital/components/bulk-status-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/pods/companies/company/projects/project/digital/constants"], function (_exports, _ssModal, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _constants.default, {
    classNames: ['bulk-digital-status-modal', 'component'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    closable: false,
    configurations: null,
    processed: 0,
    send_emails: false,
    user: Ember.inject.service(),
    progress: Ember.computed('processed', 'configurations.[]', function () {
      return this.get('processed') / this.get('configurations.length') * 100;
    }),
    statusUpgradeOptions: Ember.computed('fulfillments.[]', 'fulfillments.@each.status', 'statusUpdatePaths', function () {
      if (this.fulfillments.length === 0) {
        return [];
      } // Find the appropriate upgrade paths for each fulfillment, then find the intersection of all
      // to get the appropriate upgrade paths for all.


      var statuses = this.fulfillments.map(f => [...this.statusUpdatePaths[f.status]]).reduce((x, y) => x.filter(item => y.includes(item))); // If all selected fulfillments have the same status, then don't include that status in the upgrade options.

      var firstStatus = this.fulfillments[0].status;

      if (this.fulfillments.every(f => f.status === firstStatus)) {
        statuses.removeObject(firstStatus);
      }

      return statuses;
    }),
    // Returns the sub_type if all fulfillments have the same sub_type.
    // Otherwise returns 'generic'.
    statusUpgradeSubType: Ember.computed('fulfillments.[]', function () {
      var fLength = this.fulfillments.length;

      if (fLength == 0) {
        return 'generic';
      }

      var type = this.fulfillments[0].sub_type;

      if (fLength == 1) {
        return type;
      }

      for (var i = 1; i < fLength; ++i) {
        if (this.fulfillments[i].sub_type != type) {
          return 'generic';
        }
      }

      return type;
    }),
    updateTask: (0, _emberConcurrency.task)(function* () {
      this.set('processed', 0);

      for (let fulfillment of this.fulfillments.toArray()) {
        fulfillment.set('status', this.newStatus);

        try {
          yield fulfillment.save();
        } catch (e) {
          fulfillment.rollbackAttributes();
          throw e;
        }

        this.set('processed', this.processed + 1);
      }
    }),

    performUpdateTask() {
      return this.get('updateTask').perform().then(() => this.closeModal()).catch(error => {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "There was an error while trying to update the statuses. Some may have been updated."
        });
      });
    },

    actions: {
      update() {
        if (this.get('updateTask.isRunning')) {
          return;
        } // If there's a message present in i18n, display it and get verification from the user
        // before continuing.


        let intlKey = `digital-fulfillment-status-update.${this.newStatus}`;

        if (this.intl.exists(`confirm-modal.${intlKey}.title`)) {
          this.send('open_modal', 'confirm-modal', {
            i18nKey: intlKey,
            approve: () => {
              return this.performUpdateTask();
            }
          });
        } else {
          this.performUpdateTask();
        }
      }

    }
  });

  _exports.default = _default;
});