define("manage/pods/companies/company/projects/project/questions/index/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'question',
    componentPrefix: 'companies/company/projects/project/questions/index',
    i18nPrefix: 'questions.index',
    colDefs: function
      /* model */
    () {
      return [{
        id: 'id',
        sort: 'id'
      }, {
        id: 'name',
        sort: 'question.text',
        default_visible: true
      }, {
        id: 'type',
        sort: 'question.question_type',
        default_visible: true
      }];
    }
  });

  _exports.default = _default;
});