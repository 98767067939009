define("manage/pods/companies/company/projects/project/sources/upgrades/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    source: Ember.computed.readOnly('model.source'),
    configurations: Ember.computed.readOnly('model.configurations'),
    sorted_configurations: Ember.computed('configurations.@each.{product_reward_name,product_reward_price,id,status}', function () {
      let configurations = this.get('configurations').toArray();
      return configurations.sort(function (a, b) {
        if (a.get('product_reward_price') !== b.get('product_reward_price')) {
          return a.get('product_reward_price') - b.get('product_reward_price');
        }

        if (a.get('product_reward_name') !== b.get('product_reward_name')) {
          return a.get('product_reward_name') - b.get('product_reward_name');
        }

        return a.get('id') - b.get('id');
      });
    }),
    // current configuration
    selected: null,
    saveTask: (0, _emberConcurrency.task)(function* () {
      let configurations = yield this.get('configurations');
      let to_save = [];

      for (let configuration of configurations.toArray()) {
        if (configuration.get('hasDirtyAttributes')) {
          to_save.push(configuration.save());
        }
      }

      yield Ember.RSVP.all(to_save);
    }),
    actions: {
      set_selected(selected) {
        this.set('selected', selected);
      }

    }
  });

  _exports.default = _default;
});