define("manage/mixins/launch-guide-component", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['ui', 'attached', 'segment', 'lg-step', 'component'],
    classNameBindings: ['completed:completed'],
    attributeBindings: ['style'],

    init() {
      this._super(...arguments);

      let progress = this.get('progress');

      if (Ember.isBlank(progress)) {
        (0, _logger.logger_warn)('progress element was not passed in');
      }
    },

    completed: false,
    visible: true,

    didInsertElement() {
      this._super(...arguments);

      let progress = this.get('progress');

      if (Ember.isPresent(progress)) {
        progress.register(this);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      let progress = this.get('progress');

      if (Ember.isPresent(progress)) {
        progress.unregister(this);
      }
    },

    style: Ember.computed('visible', function () {
      if (this.get('visible') === true) {
        return Ember.String.htmlSafe();
      }

      return Ember.String.htmlSafe("display: none;");
    })
  });

  _exports.default = _default;
});