define("manage/pods/companies/company/projects/project/orders/tags/components/new-tag-modal/component", ["exports", "manage/mixins/ss-modal", "manage/utils/catch-real", "ember-concurrency", "manage/models/order-tag", "ember-cp-validations"], function (_exports, _ssModal, _catchReal, _emberConcurrency, _orderTag, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Tag Name",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, Validations, {
    title: "New tag",
    approve_label: "Create",
    closable: false,
    submitted: false,
    store: Ember.inject.service(),
    colors: _orderTag.colors,
    saveTask: (0, _emberConcurrency.task)(function* (name, color) {
      let project = yield this.get('project');
      let tag = this.get('store').createRecord('order-tag', {
        project: project,
        name: name,
        color: color
      });
      yield tag.save();

      if (this.get('create')) {
        this.get('create')(tag);
      }
    }),
    actions: {
      save() {
        this.validateSync();

        if (this.get('validations.isInvalid')) {
          this.set('submitted', true);
          return;
        }

        this.get('saveTask').perform(this.get('name'), this.get('color')).then(() => this.closeModal()).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: `Unable to create tag.`
          });
        }));
      }

    }
  });

  _exports.default = _default;
});