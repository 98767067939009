define("manage/components/sortable-column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'th',
    classNameBindings: ['sortClass'],
    // Passed into the component
    direction: null,
    property: null,

    action() {},

    sortClass: Ember.computed('property', 'direction', 'name', function () {
      if (this.get('property') === this.get('name')) {
        if (this.get('direction') === null || this.get('direction') === 'asc') {
          return "sorted ascending";
        } else {
          return "sorted descending";
        }
      } else {
        return "sorted";
      }
    }),

    click() {
      var direction;

      if (this.get('direction') === null || this.get('direction') === 'desc') {
        direction = 'asc';
      } else {
        direction = 'desc';
      }

      return this.get('action')(this.get('name'), direction);
    }

  });

  _exports.default = _default;
});