define("manage/pods/companies/company/projects/project/orders/view/components/view-product-keys/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pZz1vSNz",
    "block": "{\"symbols\":[\"digital_key\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  Product Keys\\n  \"],[7,\"div\",true],[10,\"class\",\"floated right\"],[8],[0,\"\\n    \"],[2,\" No action \"],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"table\",true],[10,\"class\",\"ui very basic striped table\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"order\",\"digital_keys\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"companies/company/projects/project/orders/view/view-product-key-row\",null,[[\"digital_key\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"No product keys\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/view-product-keys/template.hbs"
    }
  });

  _exports.default = _default;
});