define("manage/pods/companies/company/projects/project/settings/payments/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    queryParams: ['error'],
    project: Ember.computed.readOnly('model.project'),
    projects: Ember.computed.readOnly('model.projects'),
    apps: Ember.computed.readOnly('model.apps'),
    payment_gateways: Ember.computed.readOnly('model.payment_gateways'),
    companies_user: Ember.computed.readOnly('model.companies_user'),
    stripe_app: Ember.computed('apps.@each.slug', function () {
      return this.get('apps').filter(item => {
        return item.get('slug') === 'payment-stripe';
      }).objectAt(0);
    }),
    stripe_payment_gateways: Ember.computed('payment_gateways.@each.app_id', 'stripe_app.app_id', function () {
      return this.get('payment_gateways').filter(item => {
        return item.get('app_id') === this.get('stripe_app.app_id');
      });
    }),
    actions: {
      refresh_route() {
        // Refresh the route
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});