define("manage/pods/companies/company/projects/project/digital/view/downloads/route", ["exports", "manage/pods/companies/company/projects/project/digital/view/downloads/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      sortProperty: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let fulfillment = this.modelFor('companies.company.projects.project.digital.view');
      let page = params.page || 1;
      let page_size = params.page_size || 50;
      return Ember.RSVP.hash({
        project: project,
        fulfillment: fulfillment,
        downloads: this.get('store').query('digital-download', {
          filter: {
            fulfillment: fulfillment.get('id')
          },
          page: {
            number: page,
            size: page_size
          }
        })
      });
    }

  });

  _exports.default = _default;
});