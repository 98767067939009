define("manage/pods/companies/company/projects/project/digital/view/keys/components/column-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7i5wpN/2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"key\",\"assigned\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"companies.company.projects.project.orders.view\",[24,[\"key\",\"assigned\",\"order\",\"id\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[24,[\"key\",\"assigned\",\"order\",\"id\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  --\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/digital/view/keys/components/column-order/template.hbs"
    }
  });

  _exports.default = _default;
});