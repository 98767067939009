define("manage/mixins/models/order/selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),

    lock(process) {
      return this.get('ajax').postModel(this, 'lock', {
        data: {
          order_id: this.get('order.id'),
          process: process
        }
      }).then(() => {
        return this.get('store').findRecord('order', this.get('order.id'), {
          include: 'selections'
        });
      });
    },

    unlock() {
      let process = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.get('ajax').postModel(this, "unlock", {
        data: {
          order_id: this.get('order.id'),
          process: process
        }
      }).then(() => {
        return this.get('store').findRecord('order', this.get('order.id'), {
          include: 'selections'
        });
      });
    },

    unlockAll() {
      return this.get('ajax').postModel(this, "unlock", {
        data: {
          order_id: this.get('order.id'),
          clear_all: true,
          process: ''
        }
      }).then(() => {
        return this.get('store').findRecord('order', this.get('order.id'), {
          include: 'selections'
        });
      });
    },

    priceData(type, product_amount_per_quantity, shipping_amount_per_quantity) {
      return this.get('ajax').postModel(this, "price_data", {
        data: {
          price_data: {
            type,
            product_amount_per_quantity,
            shipping_amount_per_quantity
          }
        }
      });
    }

  });

  _exports.default = _default;
});