define("manage/pods/companies/company/account/users/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('model'),
    current_user: Ember.inject.service('user'),
    ajax: Ember.inject.service(),
    queryParams: ['sort', 'sortDirection'],
    sort: 'user.email',
    sortDirection: 'asc',
    sorted_by: ['user.email:asc'],
    companyUsersSorted: Ember.computed.sort('company.companies_users', 'sorted_by'),
    userIsOwner: Ember.computed('company.companies_users.@each.{level,user}', 'current_user.{is_admin,id}', function () {
      if (this.get('current_user.is_admin')) return true;
      let currentUserId = this.get('current_user.id'); // Return true if we can find a companies-users record for the current user and company with a level of 'owner'.

      return this.get('company.companies_users').any(function (companies_user) {
        return companies_user.get('level') == 'owner' && companies_user.get('user.id') == currentUserId;
      });
    }),
    setOwnerTask: (0, _emberConcurrency.task)(function* (user) {
      let company = yield this.get('company');
      return this.get('ajax').postModel(company, 'set-owner', {
        data: {
          new_owner_id: user.get('id')
        }
      });
    }).drop(),
    resendInviteTask: (0, _emberConcurrency.task)(function* (user) {
      user = yield user;
      return user.resend_invite().then(() => user.reload());
    }),
    removeUserTask: (0, _emberConcurrency.task)(function* (companiesUser) {
      companiesUser = yield companiesUser;
      companiesUser.deleteRecord();
      yield companiesUser.save();
    }).drop(),
    actions: {
      sort(name, direction) {
        let sortDirection = this.get('sort') !== name ? 'asc' : direction;
        this.set('sort', name);
        this.set('sortDirection', sortDirection);
        this.set('sorted_by', [`${name}:${sortDirection}`]);
      },

      invite_user() {
        var user = this.store.createRecord('user', {
          role: 'creator'
        });
        user.get('companies').pushObject(this.get('company'));
        this.send('open_modal', 'companies/company/account/components/invite-user-modal', {
          user: user,
          company: this.get('company')
        });
      },

      resend_invite(user) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'resend-invite',
          i18nParams: {
            name: user.get('full_name')
          },
          approve: () => this.get('resendInviteTask').perform(user)
        });
      },

      set_owner(user) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'set-company-owner',
          i18nParams: {
            name: user.get('full_name')
          },
          approve: () => this.get('setOwnerTask').perform(user).then(() => this.send('refreshUsers'))
        });
      },

      remove_user(companiesUser) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'remove-user',
          i18nParams: {
            user_name: companiesUser.user.get('full_name'),
            user_email: companiesUser.user.get('email'),
            company_name: companiesUser.company.get('name')
          },
          approve: () => this.get('removeUserTask').perform(companiesUser)
        });
      }

    }
  });

  _exports.default = _default;
});