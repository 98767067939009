define("manage/pods/companies/company/projects/create/route", ["exports", "ember-concurrency", "manage/mixins/handle-transition"], function (_exports, _emberConcurrency, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    current_user: Ember.inject.service('user'),

    model() {
      let company = this.modelFor('companies.company');
      let current_user = this.get('current_user');
      return Ember.RSVP.hash({
        company: company,
        project: this.get('store').createRecord('project', {
          company: company,
          country: company.get('country'),
          currency_country: company.get('country'),
          currency: company.get('country.currency_code'),
          currency_symbol: company.get('country.currency_symbol'),
          purpose: current_user.get('is_admin') ? 'pledge_management' : 'pre_order'
        }),
        categories: this.store.query('category', {
          page: {
            size: 100
          }
        })
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('createOrImport', 'import');
        controller.set('submitted', null);
        controller.set('image_file', null);
        controller.set('image_src', null);
      }
    },

    rollbackModelsTask: (0, _emberConcurrency.task)(function* (model) {
      let project = yield model;
      project.rollbackAttributes();
    }),
    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);
        this.handleTransition(transition, resolve => {
          if (record.project.get('isNew')) {
            this.send('open_modal', 'rollback-modal', {
              model: record.project,
              modelName: "Project",
              serviceTaskAction: this.get('rollbackModelsTask'),
              promiseResolve: resolve
            });
          } else {
            // We don't have a dirty model, so mark as handled and retry
            resolve('retry');
          }
        });
      }

    }
  });

  _exports.default = _default;
});