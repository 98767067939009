define("manage/mixins/analytics", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pageView: Ember.on('didTransition', function () {
      this.googlePageView();
    }),

    googlePageView() {
      if (Ember.get(_environment.default, 'googleAnalytics.webPropertyId') == null) {
        return;
      }

      window.ga('send', 'pageview');
    }

  });

  _exports.default = _default;
});