define("manage/models/company-invoice", ["exports", "manage/utils/generated-model", "shared/utils/monetize"], function (_exports, _generatedModel, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('company-invoice').reopen({
    currency: 'USD',
    starting_balance: (0, _monetize.default)('starting_balance_cents', 'currency'),
    amount_due: (0, _monetize.default)('amount_due_cents', 'currency'),
    subtotal: (0, _monetize.default)('subtotal_cents', 'currency'),
    tax: (0, _monetize.default)('tax_cents', 'currency'),
    total: (0, _monetize.default)('total_cents', 'currency')
  });

  _exports.default = _default;
});