define("manage/pods/companies/company/projects/project/orders/view/components/order-history-change/component", ["exports", "manage/utils/monetize"], function (_exports, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['valueClass'],
    valueClass: Ember.computed('value', function () {
      if (Ember.isBlank(this.get('value'))) {
        return 'is-blank';
      } else {
        return 'has-value';
      }
    }),
    is_date: Ember.computed('key', function () {
      let key = this.get('key');

      if (key.endsWith('_on')) {
        return true;
      }

      if (key.endsWith('_at')) {
        return true;
      }

      return false;
    }),
    is_currency: Ember.computed('key', function () {
      let key = this.get('key');

      if (key.endsWith('_cents')) {
        return true;
      }

      return false;
    }),
    value_amount: Ember.computed('is_currency', 'value', 'currency', function () {
      let is_currency = this.get('is_currency');

      if (!is_currency) {
        return null;
      }

      let currency = this.get('currency');
      let value = this.get('value');
      let subunit_conversion = (0, _monetize.subunit_to_unit)(currency);
      let place = (0, _monetize.get_place_from_subunit)(subunit_conversion);
      return (0, _monetize.convert_to_whole)(value, place);
    })
  });

  _exports.default = _default;
});