define("manage/pods/companies/company/projects/project/sources/configurations/components/authorize-source-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fcovoQoe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Authorize Source\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui basic segment form content\"],[8],[0,\"\\n\"],[4,\"ss-dimmer\",null,[[\"class\",\"isActive\"],[\"inverted\",[24,[\"authorizeTask\",\"isRunning\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui small text loader\"],[8],[0,\"Running\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  Manually trigger authorization for project source.\\n  If authorization is successful, you'll need to let the customer know it worked and to have them refresh their page.\\n\\n\"],[4,\"if\",[[24,[\"message\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[22,\"message_color\"],\" message\"]]],[8],[0,\"\\n      \"],[1,[22,\"message\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"authorizeTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.close\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"authorize\"]],[8],[0,\"Authorize\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/configurations/components/authorize-source-modal/template.hbs"
    }
  });

  _exports.default = _default;
});