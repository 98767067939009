define("manage/components/show-modals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKIjK+vs",
    "block": "{\"symbols\":[\"modal\",\"index\"],\"statements\":[[4,\"ss-dimmer\",null,[[\"class\",\"isActive\"],[\"modals page\",[28,\"gt\",[[24,[\"modals\",\"length\"]],0],null]]],{\"statements\":[[4,\"each\",[[24,[\"modals\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[\"name\"]]],[[\"options\",\"isActiveModal\"],[[23,1,[\"options\"]],[28,\"eq\",[[24,[\"modals\",\"length\"]],[28,\"add\",[1,[23,2,[]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/show-modals/template.hbs"
    }
  });

  _exports.default = _default;
});