define("manage/initializers/generate-models", ["exports", "ember-cli-uncharted-describe-models/utils/load-models", "manage/config/environment", "manage/utils/model-definitions"], function (_exports, _loadModels, _environment, _modelDefinitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    (0, _modelDefinitions.loadDefinitions)((0, _loadModels.loadModels)(_environment.default['model-schema'], _environment.default['describe-models-manage']));
  }

  var _default = {
    name: 'generate-models',
    before: 'ember-data',
    initialize
  };
  _exports.default = _default;
});