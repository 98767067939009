define("manage/pods/companies/company/projects/project/orders/view/components/remove-order-line-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    can_remove: Ember.computed('order_line.selections.@each.is_locked', function () {
      let order_line = this.get('order_line');

      if (order_line == null) {
        return true;
      }

      if (order_line.get('selections.length') === 0) {
        return true;
      }

      return !order_line.get('selections').isAny('is_locked', true);
    }),
    removeTask: (0, _emberConcurrency.task)(function* (order_line) {
      order_line = yield order_line;
      let order = yield order_line.get('order');
      let order_selections = yield order_line.get('selections');

      for (let order_selection of order_selections.toArray()) {
        yield this.get('orderTasks.removeOrderSelection').perform(order_selection);
      }

      yield order_line.deleteRecord();
      yield this.get('orderTasks.save').perform(order, {
        validate: false,
        save_shipping_address: false
      });
      yield this.get('store').findRecord('order', order.get('id'), {
        include: ['lines', 'selections'].join(',')
      });
    }),
    actions: {
      approve() {
        if (this.get('removeTask.isRunning')) {
          return;
        }

        this.get('removeTask').perform(this.get('order_line')).then(() => {
          this.closeModal();
        }).catch(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to remove Order Line."
          });
        });
      }

    }
  });

  _exports.default = _default;
});