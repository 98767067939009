define("manage/helpers/order-query-params", ["exports", "manage/mixins/order-filters"], function (_exports, _orderFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.orderQueryParams = orderQueryParams;

  function orderQueryParams(values, options) {
    let params = {};
    Object.keys(_orderFilters.filters).forEach(key => {
      params[key] = key in options ? options[key] : null;
    });
    return params;
  }

  var _default = Ember.Helper.helper(orderQueryParams);

  _exports.default = _default;
});