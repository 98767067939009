define("manage/mixins/copy-text-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const copyMessage = "Copy to your clipboard";

  var _default = Ember.Mixin.create({
    copyMessage: copyMessage,

    setMessage(message, messageProperty) {
      if (typeof messageProperty !== "string") {
        messageProperty = 'copyMessage';
      }

      var originalMessage = this.get(messageProperty);
      this.set(messageProperty, message);
      Ember.run.later(this, () => this.set(messageProperty, originalMessage), 1000);
    },

    actions: {
      copied(messageProperty) {
        this.setMessage('Copied to clipboard', messageProperty);
      },

      copyError(messageProperty) {
        this.setMessage('There was an error copying to clipboard', messageProperty);
      }

    }
  });

  _exports.default = _default;
});