define("manage/pods/companies/company/projects/project/sources/import/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        apps: this.store.query('project-app', {
          filter: {
            project: project.get('id'),
            installed: false,
            type_slug: 'sales-sources'
          },
          page: {
            number: 1,
            size: 25
          }
        })
      });
    }

  });

  _exports.default = _default;
});