define("manage/mixins/restrict-by-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(role) {
    return Ember.Mixin.create({
      current_user: Ember.inject.service('user'),

      beforeModel(transition) {
        this._super(transition);

        if (Ember.isArray(role)) {
          var matching_role = (() => {
            var result = [];

            for (var j = 0, i; j < role.length; j++) {
              i = role[j];

              if (i === this.get('current_user.role')) {
                result.push(i);
              }
            }

            return result;
          })();

          if (matching_role.length === 0) {
            this.transitionTo('user.login');
            return;
          }
        } else {
          if (this.get('current_user.role') !== role) {
            this.transitionTo('user.login');
            return;
          }
        }
      }

    });
  }
});