define("manage/components/edit-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generic Edit Field
   *
   ** Required
   * @property {object}   model          - The model that the field is editing.
   * @property {string}   propertyName   - The name of the property.
   * @property {string}   fieldType      - One of 'currency', 'checkbox' or 'option', or any value accepted
   *                                       as an input type for the <input> element
   *                                       (e.g., 'text', 'number', 'email')
   * @property {boolean}  submitted      - Determines if we display validation errors (defaults to true)
   ** Optional
   * @property {string}   label          - Label to add to the field
   * @property {function} onChange       - Callback to change the value instead of setting within code (newValue)
   * If fieldtype is 'currency',
   * @property {string}   currencySymbol - Symbol to use for currency field; defaults to '$'.
   * @property {string}   currency       - Currency abbreviation to show in currency field; defaults to 'USD'.
   * If fieldType is 'option',
   * @property {array}    options        - List of options to choose from.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    currency: 'USD',
    currencySymbol: '$',
    submitted: true,
    id: Ember.computed('model', 'propertyName', function () {
      if (this.model && this.model.constructor && this.model.constructor.modelName) {
        return `input-${this.model.constructor.modelName}-${this.propertyName}`;
      } else {
        return `input-${this.propertyName}`;
      }
    }),
    value: Ember.computed('model', 'propertyName', {
      get() {
        return this.get(`model.${this.propertyName}`);
      },

      set(_key, newValue) {
        if (newValue !== this.value) {
          if (this.onChange != null) {
            this.onChange(newValue);
          } else {
            this.set(`model.${this.propertyName}`, newValue);
          }
        }

        return this.value;
      }

    })
  });

  _exports.default = _default;
});