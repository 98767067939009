define("manage/pods/companies/company/projects/project/products/choices/components/product-mapping/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['product-mapping-bundle', 'item', 'component'],
    name: Ember.computed('product.is_question', 'product.name', 'product.question.text', function () {
      if (this.get('product.is_question')) {
        return this.get('product.question.text');
      }

      return this.get('product.name');
    }),
    description: Ember.computed('product.is_question', 'product.description', 'product.question.description', function () {
      if (this.get('product.is_question')) {
        return this.get('product.question.description');
      }

      return this.get('product.description');
    })
  });

  _exports.default = _default;
});