define("manage/pods/companies/company/projects/project/sources/configurations/components/indiegogo-confirm-payment-modal/component", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/mixins/ss-modal"], function (_exports, _emberConcurrency, _catchReal, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    saveTask: (0, _emberConcurrency.task)(function* (source) {
      source = yield source;
      let {
        validations: validations
      } = yield source.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      yield source.save();
    }),

    closing() {
      let record = this.get('source');
      record.rollbackAttributes();
    },

    actions: {
      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        let source = this.get('source');
        this.set('submitted', true);
        this.get('saveTask').perform(source).then(() => this.closeModal()).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to save settings."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});