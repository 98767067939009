define("manage/pods/companies/company/projects/project/sources/configurations/index/components/column-status/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    current_user: Ember.inject.service('user'),
    intl: Ember.inject.service(),
    status_options: Ember.computed(function () {
      let intl = this.get('intl');
      return [{
        id: 'setup',
        icon: 'setting'
      }, {
        id: 'live',
        icon: 'video play'
      }, {
        id: 'complete',
        icon: 'stop'
      }].map(function (obj) {
        obj['title'] = intl.t(`route.companies.company.projects.project.sources.configurations.index.status_option.${obj.id}.title`);
        obj['description'] = intl.t(`route.companies.company.projects.project.sources.configurations.index.status_option.${obj.id}.description`);
        return Ember.Object.create(obj);
      });
    }),
    updateStatusConfiguration: (0, _emberConcurrency.task)(function* (configuration, status) {
      configuration.set('status', status);

      try {
        yield configuration.save();
      } catch (e) {
        // Revert the change
        configuration.rollbackAttributes();
        throw e;
      }
    }),
    actions: {
      update_status(configuration, text_status) {
        if (configuration.get('status') === text_status) {
          return;
        }

        if (configuration.get('source.in_setup')) {
          this.send('open_modal', 'confirm-modal', {
            i18nKey: 'unable-to-launch',
            i18nParams: {
              source_name: configuration.get('source.name')
            },
            approve_label: 'Ok'
          });
          return;
        }

        let status = this.get('status_options').findBy('id', text_status);
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'update-configuration',
          i18nParams: {
            status: status.get('title')
          },
          description: status.get('description'),
          approve: () => {
            return this.get('updateStatusConfiguration').perform(configuration, text_status);
          }
        });
      }

    }
  });

  _exports.default = _default;
});