define("manage/mixins/filters/local-pickup-addresses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    local_pickup_addresses: Ember.computed('project.addresses.@each.{id,name,address_1,address_2,city,state_name,postal_code,country}', function () {
      return this.get('project.addresses').map(function (address) {
        return Ember.Object.create({
          id: address.get('id'),
          title: address.get('detail'),
          filter_title: address.get('summary')
        });
      });
    })
  });

  _exports.default = _default;
});