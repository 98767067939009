define("manage/models/account", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    username: {
      description: "Username",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    password: {
      description: "Password",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    password_confirmation: {
      description: "Password confirmation",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  }); // Not included in schema.json since it's polymorphic

  var _default = _model.default.extend(Validations, {
    username: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    password_confirmation: (0, _model.attr)('string')
  });

  _exports.default = _default;
});