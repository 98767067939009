define("manage/mixins/smart-adapter-cache", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(cacheObject) {
    return Ember.Mixin.create({
      _getCacheModelName(snapshot) {
        let modelName = snapshot.modelName || snapshot._recordArray.modelName;
        return modelName.toLowerCase().trim();
      },

      _getCacheId(snapshot) {
        let id = snapshot.id || "collection";
        return id.trim();
      },

      _getCacheKey(snapshot) {
        let keys = [];

        if (Ember.isPresent(snapshot.include)) {
          let includes = snapshot.include.split(",");

          for (let i = 0; i < includes.length; i++) {
            includes[i] = includes[i].toLowerCase().trim();
          }

          includes = includes.sort();
          let include = includes.join(",");
          keys.push(`:includes:${include}`);
        }

        if (Ember.isPresent(snapshot.adapterOptions)) {
          (0, _logger.logger_info)("Adapter options found on snapshot. Need to do something with this.");
        }

        return keys.join(":==:");
      },

      _shouldReloadCache(snapshot) {
        let modelName = this._getCacheModelName(snapshot);

        if (!cacheObject.hasOwnProperty(modelName)) {
          cacheObject[modelName] = {};
        }

        let modelCache = cacheObject[modelName];

        let id = this._getCacheId(snapshot);

        if (!modelCache.hasOwnProperty(id)) {
          modelCache[id] = [];
        }

        let keyCache = modelCache[id];

        let key = this._getCacheKey(snapshot);

        if (keyCache.includes(key)) {
          // Don't reload the cache, we have already loaded this specific request.
          // Let background reload handle it
          return false;
        }

        keyCache.push(key); // We haven't hit this key before, so make the UI wait for it

        return true;
      },

      shouldReloadRecord(store, snapshot) {
        return this._shouldReloadCache(snapshot);
      },

      shouldReloadAll(store, snapshotRecordArray) {
        return this._shouldReloadCache(snapshotRecordArray);
      }

    });
  }
});