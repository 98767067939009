define("manage/pods/companies/company/projects/project/settings/feature-flags/route", ["exports", "manage/mixins/restrict-by-role", "manage/config/environment"], function (_exports, _restrictByRole, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend((0, _restrictByRole.default)('admin'), {
    model() {
      return Ember.RSVP.hash({
        project: this.modelFor('companies.company.projects.project').reload(),
        feature_flags: this.store.query('feature-flag', {
          page: {
            size: _environment.default['max-page-size']
          },
          filter: {
            role: 'admin'
          }
        })
      });
    }

  });

  _exports.default = _default;
});