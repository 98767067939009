define("manage/models/project-settings-analytics", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    google: (0, _model.attr)('object', {
      defaultValue: () => ({})
    }),
    facebook: (0, _model.attr)('object', {
      defaultValue: () => ({})
    })
  });

  _exports.default = _default;
});