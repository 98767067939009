define("manage/mixins/filters/product-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    product_types: [Ember.Object.create({
      id: 'physical',
      title: 'Physical'
    }), Ember.Object.create({
      id: 'digital',
      title: 'Digital'
    })]
  });

  _exports.default = _default;
});