define("manage/pods/companies/company/projects/project/orders/index/components/column-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nPQFNhiD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"icon \",[24,[\"order\",\"source\",\"site\"]]]]],[11,\"title\",[29,[\"Order from \",[28,\"titleize\",[[24,[\"order\",\"source\",\"site\"]]],null]]]],[8],[9],[0,\"\\n\"],[1,[24,[\"order\",\"customer\",\"full_name\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/index/components/column-name/template.hbs"
    }
  });

  _exports.default = _default;
});