define("manage/components/info-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    title: "Notice",
    approve_label: "Ok",
    closable: true,

    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('information'))) {
        throw new Error("You must specify information to use the info modal");
      }
    }

  });

  _exports.default = _default;
});