define("manage/pods/companies/company/projects/project/products/index/components/column-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D4Vz3ZAx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"product\",\"product_type\"]],\"option\"],null]],null,{\"statements\":[[0,\"  --\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"format-number\",[[24,[\"product\",\"price\"]]],[[\"currency\"],[[24,[\"product\",\"project\",\"currency\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/index/components/column-price/template.hbs"
    }
  });

  _exports.default = _default;
});