define("manage/helpers/decode-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decodeString = decodeString;
  _exports.default = void 0;

  function decodeString(value) {
    return decodeURIComponent(window.atob(decodeURIComponent(value)));
  }

  var _default = Ember.Helper.helper(function (_ref) {
    let [value] = _ref;
    return decodeString(value);
  });

  _exports.default = _default;
});