define("manage/pods/companies/company/projects/project/segments/components/complete-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action", "ember-cp-validations", "ember-concurrency"], function (_exports, _ssModal, _segmentAction, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    defaultAny: {
      description: "Default settings",
      validators: [(0, _emberCpValidations.validator)('json-valid', true)]
    }
  });

  var _default = Ember.Component.extend(Validations, _ssModal.default, _segmentAction.default, {
    classNames: ['complete-modal', 'segment-modal', 'component'],
    closable: false,
    type: 'complete',
    ignoreAddress: false,
    allowPartial: false,
    defaultAny: null,
    performTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);

      if (this.get('actionTask.isRunning')) {
        return;
      }

      this.set('error', null);
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let defaultAnyJson = '';

      if (Ember.isPresent(this.get('defaultAny'))) {
        defaultAnyJson = JSON.parse(this.get('defaultAny'));
      }

      this.get('actionTask').perform({
        require_address: !this.get('ignoreAddress'),
        allow_partial: this.get('allowPartial'),
        defaults: {
          any: defaultAnyJson
        }
      });
    }),
    actions: {
      perform() {
        this.get('performTask').perform();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});