define("manage/pods/companies/company/account/components/authorized-source/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    password: {
      description: "Password",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    classNames: 'ui segment',
    classNameBindings: ['loading'],
    current_user: Ember.inject.service('user'),
    type: null,
    company: null,
    loading: false,
    error: null,
    email: null,
    password: null,
    has_authorized: Ember.computed('type', 'company.authorizations', function () {
      return (this.get('company.authorizations') || {})[this.get('type')] === 'authorized';
    }),
    actions: {
      submit() {
        this.set('submitted', true);

        if (this.get('validations.isInvalid')) {
          return;
        }

        if (this.get('loading')) {
          return;
        }

        this.set('error', null);
        this.set('loading', true);
        return this.get('company').authorize(this.get('type'), this.get('email'), this.get('password')).then(() => {
          return this.get('company').reload();
        }).catch(result => {
          if (Ember.isPresent(result.payload) && Ember.isPresent(result.payload.errors)) {
            return this.set('error', result.payload.errors[0].detail || result.payload.errors[0].title);
          } else {
            return this.set('error', "An unknown authorization error occurred. Please check username and password");
          }
        }).finally(() => {
          return this.set('loading', false);
        });
      }

    }
  });

  _exports.default = _default;
});