define("manage/pods/application/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application_updated: false,
    showAuthenticationStatus: Ember.computed(function () {
      return _environment.default.showAuthenticationStatus && _environment.default.environment == 'development';
    }),

    init() {
      this._super(...arguments);

      if (Ember.testing) {
        return;
      }

      if (this.expected_maintenance) {
        window.setInterval(() => {
          this.notifyPropertyChange('maintenance_date');
        }, 1000 * 60); // Run every minute
      }
    },

    actions: {
      reload() {
        return window.location.reload();
      }

    },
    expected_maintenance: false,
    maintenance_date: Ember.computed(function () {
      return new Date('March 17, 2020 12:00:00 PM GMT-07:00');
    }),
    maintenance_is_now: Ember.computed('maintenance_date', function () {
      return new Date() > this.maintenance_date;
    }),
    // Temp message
    current_holiday: Ember.computed('model.holidays.[]', function () {
      let holidays = this.get('model.holidays');

      if (Ember.isEmpty(holidays)) {
        return;
      }

      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return holidays.sortBy('ends_at_date').find(h => {
        let notice_day = new Date();
        notice_day.setDate(today.getDate() + h.notice_days);
        return h.starts_at_date <= notice_day && h.ends_at_next_date > today;
      });
    }),
    before_holiday: Ember.computed('hidden', 'current_holiday.starts_at_date', function () {
      if (this.get('hidden') === true) {
        return false;
      }

      if (!this.get('current_holiday')) {
        return false;
      }

      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return today < this.get('current_holiday.starts_at_date');
    }),
    during_holiday: Ember.computed('hidden', 'current_holiday.{starts_at_date,ends_at_next_date}', function () {
      if (this.get('hidden') === true) {
        return false;
      }

      if (!this.get('current_holiday')) {
        return false;
      }

      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return today >= this.get('current_holiday.starts_at_date') && today < this.get('current_holiday.ends_at_next_date');
    })
  });

  _exports.default = _default;
});