define("manage/pods/companies/company/projects/project/products/choices/components/edit-product-bundle-mappings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // making more unique to avoid common work issues
    classNames: ['edit-product-bundle-mappings', 'component'],
    current_user: Ember.inject.service('user'),
    store: Ember.inject.service(),
    active_section: "products",
    // Aliases to help out in view
    products: Ember.computed.filterBy('all_products', 'is_physical_or_digital', true),
    choices: Ember.computed.filterBy('all_products', 'is_option', true),
    questions: Ember.computed.filterBy('all_products', 'is_question', true),
    actual_product_mappings: Ember.computed.filterBy('product.mappings', 'isDeleted', false),
    // Filters on the left
    remaining_products: Ember.computed('products.@each.name', 'actual_product_mappings.@each.child', function () {
      let product_mappings_children = this.get('actual_product_mappings').mapBy('child.content');
      let products = this.get('products').filter(product => {
        return !product_mappings_children.includes(product);
      });
      return products.sortBy('name');
    }),
    remaining_choices: Ember.computed('choices.@each.name', 'actual_product_mappings.@each.child', function () {
      let product_mappings_children = this.get('actual_product_mappings').mapBy('child.content');
      let choices = this.get('choices').filter(choice => {
        return !product_mappings_children.includes(choice);
      });
      return choices.sortBy('name');
    }),
    remaining_questions: Ember.computed('questions.@each.question', 'actual_product_mappings.@each.child', function () {
      let product_mappings_children = this.get('actual_product_mappings').mapBy('child.content');
      let questions = this.get('questions').filter(question => {
        return !product_mappings_children.includes(question);
      });
      return questions.sortBy('question.text');
    }),
    // Mappings, we need to separate them because of how they are shown in the ui
    products_mapped: Ember.computed.filter('actual_product_mappings.@each.{is_child_is_physical_or_digital,is_child_option,is_child_unknown}', function (mapping) {
      return mapping.get('is_child_is_physical_or_digital') || mapping.get('is_child_option') || mapping.get('is_child_unknown');
    }),
    products_mapped_sorted: Ember.computed('products_mapped.@each.sort_order', function () {
      return this.get('products_mapped').sortBy('sort_order');
    }),
    questions_mapped: Ember.computed.filter('actual_product_mappings.@each.is_child_question', function (mapping) {
      return mapping.get('is_child_question');
    }),
    questions_mapped_sorted: Ember.computed('questions_mapped.@each.sort_order', function () {
      return this.get('questions_mapped').sortBy('sort_order');
    }),
    actions: {
      order_mappings(mappings) {
        mappings = mappings.filterBy('isDeleted', false);
        let products = mappings.filterBy('child.is_question', false);
        products.forEach((product, index) => product.set('sort_order', index + 1));
        let questions = mappings.filterBy('child.is_question', true);
        questions.forEach((question, index) => question.set('sort_order', index + 1000));
      },

      map_product(child_product) {
        let mapping_name = 'products_mapped_sorted';

        if (child_product.get('is_question') === true) {
          mapping_name = 'questions_mapped_sorted';
        }

        let sort_orders = this.get(mapping_name).mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        let mapping = this.get('store').createRecord('product-mapping', {
          enabled: true,
          is_default: false,
          project: this.get('project'),
          parent: this.get('product'),
          child: child_product,
          sort_order: sort_order
        });

        if (this.get('product.is_bundle')) {
          mapping.set('required_quantity', 1);
        }

        this.send('order_mappings', this.get(mapping_name).toArray());
      }

    }
  });

  _exports.default = _default;
});