define("manage/pods/companies/company/projects/project/settings/index/route", ["exports", "manage/mixins/handle-transition", "ember-concurrency", "manage/config/environment"], function (_exports, _handleTransition, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project.reload(),
        feature_flags: this.store.query('feature-flag', {
          page: {
            size: _environment.default['max-page-size']
          },
          filter: {
            role: 'creator',
            purpose: project.get('purpose')
          }
        })
      });
    },

    rollbackModelsTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      project.rollbackAttributes();
    }),

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('color_index', 0);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.project) {
          this.handleTransition(transition, resolve => {
            if (record.project.get('hasDirtyAttributes')) {
              this.send('open_modal', 'rollback-modal', {
                model: record.project,
                modelName: "Project Settings",
                serviceTaskAction: this.get('rollbackModelsTask'),
                promiseResolve: resolve
              });
            } else {
              // We don't have a dirty model, so mark as handled and retry
              resolve('retry');
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});