define("manage/pods/companies/company/projects/project/orders/groups/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', // Location
    'shipping_country', // Order
    'source', // Selections
    'configuration', 'product_type'],
    project: Ember.computed.readOnly('model.project'),
    project_fulfillment_groups: Ember.computed.readOnly('model.project_fulfillment_groups'),
    friendly_text: null,
    current_user: Ember.inject.service('user'),
    api_url: _environment.default.urls.api,
    mark_exported: true,
    // this should be true always
    page: 1,
    page_size: 10,
    // Filters
    // Location
    shipping_country: '',
    // Order
    source: '',
    step: 'completed',
    status: 'not_exported',
    address: '',
    // Selections
    configuration: '',
    product: '',
    product_variation: '',
    product_type: '',
    filter_data: Ember.computed('shipping_country', 'source', 'step', 'status', 'address', 'configuration', 'product', 'product_variation', 'product_type', function () {
      return {
        // Location
        shipping_country: this.get('shipping_country'),
        // Order
        source: this.get('source'),
        step: this.get('step'),
        status: this.get('status'),
        address: this.get('address'),
        // Selections
        configuration: this.get('configuration'),
        product: this.get('product'),
        product_variation: this.get('product_variation'),
        product_type: this.get('product_type')
      };
    }),
    actions: {
      build_params(filter_data, key) {
        let extra_params = {
          fulfillment_group: key
        };
        return Object.assign(filter_data, extra_params);
      },

      update_query_params(values) {
        this.setProperties(values); // Also update page to 1

        this.set('page', 1);
      }

    }
  });

  _exports.default = _default;
});