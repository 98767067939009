define("manage/pods/companies/company/projects/project/theme/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    current_user: Ember.inject.service('user'),
    preview_size: 'desktop',
    is_desktop: Ember.computed.equal('preview_size', 'desktop'),
    is_tablet: Ember.computed.equal('preview_size', 'tablet'),
    is_mobile: Ember.computed.equal('preview_size', 'mobile'),
    themes: ['light', 'dark'],
    submitted: false,
    saveTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      let result = yield project.get('theme').validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      yield project.save();
    }),
    actions: {
      save() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform(this.get('project'));
      }

    }
  });

  _exports.default = _default;
});