define("manage/pods/companies/company/projects/project/segments/components/send-emails-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/mixins/segment-action", "ember-cp-validations"], function (_exports, _ssModal, _emberConcurrency, _segmentAction, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    emailTemplate: {
      description: "Template",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, Validations, {
    classNames: ['send-emails-modal', 'segment-modal', 'component'],
    closable: false,
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    submitted: false,
    type: 'email',
    emailTemplate: null,
    errorCode: null,
    errorParams: {},
    remainingOrders: 0,

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
      this.set('emailsToSend', this.get('count'));
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let store = this.get('store');
      let emails = yield store.query('project-email', {
        filter: {
          project: this.get('project.id')
        },
        page: {
          size: 50
        }
      });
      this.set('emailTemplates', emails);
    }),

    processSentIn24HoursError(params) {
      let count = params.count;
      let outOf = params.out_of;
      let remainingHours = params.remaining_hours;
      let remainingOrders = outOf - count;
      let errorMsg = this.intl.t('component.companies.company.projects.project.segments.send-emails-modal.emails_sent_in_past_24_hours.part1', {
        outOf: outOf,
        count: count
      }) + " ";

      if (count < outOf) {
        errorMsg += this.intl.t('component.companies.company.projects.project.segments.send-emails-modal.emails_sent_in_past_24_hours.part2-partial', {
          remainingOrders: remainingOrders,
          remainingHours: remainingHours
        });
      } else {
        errorMsg += this.intl.t('component.companies.company.projects.project.segments.send-emails-modal.emails_sent_in_past_24_hours.part2-total', {
          remainingHours: remainingHours
        });
      }

      this.set('error', errorMsg);
      this.set('remainingOrders', remainingOrders);
    },

    processNotAllOrdersInvitable(params) {
      let count = params.count;
      let outOf = params.out_of;
      let remainingOrders = outOf - count;
      let errorMsg;

      if (remainingOrders == 0) {
        errorMsg = this.intl.t('component.companies.company.projects.project.segments.send-emails-modal.not_all_orders_invitable.none');
      } else {
        errorMsg = this.intl.t('component.companies.company.projects.project.segments.send-emails-modal.not_all_orders_invitable.some', {
          outOf: outOf,
          count: count
        });
      }

      errorMsg += " " + this.intl.t('component.companies.company.projects.project.segments.send-emails-modal.not_all_orders_invitable.reason');
      this.set('error', errorMsg);
      this.set('remainingOrders', remainingOrders);
    },

    actions: {
      perform() {
        let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        this.set('submitted', true);
        let result = this.validateSync();

        if (result.validations.get('isInvalid')) {
          return;
        }

        if (this.get('actionTask.isRunning')) {
          return;
        }

        if (options.sendToRemaining) {
          // If sending to a subset of the orders, update the count
          this.set('emailsToSend', this.get('errorParams.out_of') - this.get('errorParams.count')); // Don't pass this option to the send email process

          delete options.sendToRemaining;

          switch (this.errorCode) {
            case 'emails_sent_in_past_24_hours':
              options.send_unsent_emails = true;
              break;

            case 'not_all_orders_invitable':
              options.only_send_invitable = true;
              break;
          }
        }

        this.get('actionTask').perform(Object.assign({
          project_email_id: this.get('emailTemplate.id')
        }, options));
      },

      jobCompleted() {
        this.set('errorCode', null);
        this.set('errorParams', null);
        this.set('remainingOrders', 0);

        if (this.get('complete')) {
          this.get('complete')();
        }
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);

        if (error.extra) {
          this.set('errorCode', error.extra.error_code);
          this.set('errorParams', error.extra.params);

          switch (error.extra.error_code) {
            case 'emails_sent_in_past_24_hours':
              this.processSentIn24HoursError(error.extra.params);
              break;

            case 'not_all_orders_invitable':
              this.processNotAllOrdersInvitable(error.extra.params);
              break;
          }
        }
      }

    }
  });

  _exports.default = _default;
});