define("manage/models/product-variant", ["exports", "shared/utils/fallback-sort", "manage/utils/generated-model"], function (_exports, _fallbackSort, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product-variant').extend({
    values_sorted: (0, _fallbackSort.default)('values', 'name', 'id'),
    // This is used to determine if we should show the variant name in the dropdown or outside
    use_inline_name: Ember.computed('name', function () {
      return this.get('name').length <= 20;
    })
  });

  _exports.default = _default;
});