define("manage/models/job", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('job').reopen({
    // Scheduled
    is_scheduled: Ember.computed.equal('status', 'scheduled'),
    // Queued
    is_queued: Ember.computed.equal('status', 'queued'),
    // Processing
    is_working: Ember.computed.equal('status', 'working'),
    is_processing: Ember.computed('is_working', 'total', 'processed', function () {
      return this.get('is_working') && this.get('total') > this.get('processed');
    }),
    progress: Ember.computed('is_complete', 'total', 'processed', function () {
      if (this.get('is_complete')) {
        return 100;
      } else {
        if (this.get('processed')) {
          return Math.floor(this.get('processed') / this.get('total') * 100);
        } else {
          return 0;
        }
      }
    }),
    // Completions
    is_complete: Ember.computed('status', function () {
      return this.get('status') === 'complete' || this.get('status') === 'finished';
    }),
    is_failed: Ember.computed('status', function () {
      return this.get('status') === 'failed' || this.get('status') === 'errored' || this.get('status') === 'discarded' || this.get('status') === 'canceled';
    }),
    is_done: Ember.computed.or('is_complete', 'is_failed'),
    is_not_done: Ember.computed.not('is_done')
  });

  _exports.default = _default;
});