define("manage/components/confirm-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/error-from-xhr"], function (_exports, _ssModal, _errorFromXhr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Confirm Modal
   *
   * @property {string} title         - Title for the modal. Default value is "Confirm"
   * @property {string} question      - Question for the modal.
   * @property {string} description   - Description for the modal. Optional.
   * @property {string} approve_label - Text for the "approve" button. Defaults to "Yes".
   * @property {string} cancel_label  - Text for the "cancel" button. Defaults to "No".
   * @property {string} i18nKey       - If set, uses it as a key underneath `confirm-modal`
   *                                    in the i18n dictionary to find the title, question,
   *                                    errors, and optionally the description for this modal. If set and these
   *                                    keys are found in the translation, they take precedence over
   *                                    any values that may be set in +title+, +question+, and +description+.
   * @property {object} i18nParams    - If set, values will be provided to all i18n lookups
   *                                    used with the i18nKey.
   * @property {function} approve     - The function to call when the user clicks the approve button.
   *                                    The function should return a resolved promise.
   * @property {function} cancel      - The function to call when the user clicks the cancel button.
   *                                    Also executed when the user closes the dialog with the Close button
   *                                    after an error.
   */
  var _default = Ember.Component.extend(_ssModal.default, (0, _errorFromXhr.default)(), {
    classNames: ['confirm-modal', 'component'],
    intl: Ember.inject.service(),
    title: "Confirm",
    approve_label: "Yes",
    cancel_label: "No",
    displayedTitle: Ember.computed('title', 'i18nKey', function () {
      return this.intlIfPresent('title');
    }),
    displayedQuestion: Ember.computed('question', 'i18nKey', function () {
      return this.intlIfPresent('question');
    }),
    displayedDescription: Ember.computed('description', 'i18nKey', function () {
      return this.intlIfPresent('description');
    }),
    displayedApproveLabel: Ember.computed('approve_label', 'i18nKey', function () {
      return this.intlIfPresent('approve_label');
    }),
    displayedCancelLabel: Ember.computed('cancel_label', 'i18nKey', function () {
      return this.intlIfPresent('cancel_label');
    }),

    intlIfPresent(property) {
      if (this.i18nPrefix) {
        let intlKey = `${this.i18nPrefix}.${property}`;

        if (this.intl.exists(intlKey)) {
          return this.intl.t(intlKey, this.i18nParams);
        }
      } // fallback


      return this.get(property);
    },

    // Used by ErrorFromXhrMixin
    i18nPrefix: Ember.computed('i18nKey', function () {
      if (this.i18nKey) {
        return 'confirm-modal.' + this.i18nKey;
      }

      return null;
    }),
    actions: {
      cancel() {
        this.set('loading', true);

        if (typeof this.get('cancel') === "function") {
          Ember.RSVP.resolve(this.get('cancel')()).then(() => this.closeModal()).finally(() => this.set('loading', false));
        } else {
          this.closeModal();
        }
      },

      approve() {
        this.set('loading', true);

        if (typeof this.get('approve') === "function") {
          Ember.RSVP.resolve(this.get('approve')()).then(() => this.closeModal()).catch(xhr => {
            this.set('error', this.errorFromXhr(xhr));
          }).finally(() => this.set('loading', false));
        } else {
          this.closeModal();
        }
      }

    }
  });

  _exports.default = _default;
});