define("manage/pods/companies/company/projects/project/digital/index/components/column-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k7QbpRO9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"fulfillment\",\"sub_type\"]],\"key\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"data-tooltip\",[28,\"t-route\",[\"column.count.keys-tooltip\"],[[\"unassigned_keys\"],[[24,[\"fulfillment\",\"product\",\"stats\",\"unassigned_keys\"]]]]]],[10,\"data-inverted\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"t-route\",[\"column.count.keys\"],[[\"unassigned_keys\",\"keys\"],[[24,[\"fulfillment\",\"product\",\"stats\",\"unassigned_keys\"]],[24,[\"fulfillment\",\"product\",\"stats\",\"keys\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"fulfillment\",\"sub_type\"]],\"download\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t-route\",[\"column.count.downloads\"],[[\"downloads\"],[[24,[\"fulfillment\",\"product\",\"stats\",\"downloads\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/digital/index/components/column-count/template.hbs"
    }
  });

  _exports.default = _default;
});