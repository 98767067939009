define("manage/mixins/filters/statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    status_options: Ember.computed('project', function () {
      let statuses = Ember.A(); // Imported Payments

      statuses.push({
        groupName: 'Imported Payments Status',
        options: Ember.A([Ember.Object.create({
          id: 'external_sources_unconfirmed',
          title: 'Unconfirmed'
        }), Ember.Object.create({
          id: 'external_sources_dropped',
          title: 'Dropped'
        })])
      }); // All Payments

      statuses.push({
        groupName: 'All Payments Status',
        options: Ember.A([Ember.Object.create({
          id: 'refunded_transactions',
          title: 'Refunded'
        }), Ember.Object.create({
          id: 'confirmed_transactions',
          title: 'Confirmed'
        })])
      }); // Extra Payments

      statuses.push({
        groupName: 'Configuration Status',
        options: Ember.A([Ember.Object.create({
          id: 'upgraded',
          title: 'Upgraded'
        })])
      }); // Email

      statuses.push({
        groupName: 'Email',
        options: Ember.A([Ember.Object.create({
          id: 'sent',
          title: 'Email: Sent'
        }), Ember.Object.create({
          id: 'undeliverable',
          title: 'Email: Undeliverable'
        }), Ember.Object.create({
          id: 'hard_bounce',
          title: 'Email: Hard Bounce'
        }), Ember.Object.create({
          id: 'spam',
          title: 'Email: Spam'
        })])
      });
      let libs = this.get('project.libs');

      if (libs != null) {
        libs.forEach(function (item) {
          if (item.get('app').order_filters) {
            let order_filters = item.get('app').order_filters();
            statuses.push(order_filters);
          }
        });
      }

      return statuses;
    })
  });

  _exports.default = _default;
});