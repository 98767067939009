define("manage/services/analytics", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Analytics = Ember.Mixin.create({
    initAnalytics(user) {
      if (Ember.isBlank(user)) {
        return false;
      }

      this.googleInit(user);
    },

    googleInit(user) {
      let google_analytics_id = Ember.get(_environment.default, 'googleAnalytics.webPropertyId');

      if (google_analytics_id == null) {
        return false;
      }

      let params = {
        'userId': user.get('id')
      };
      window.ga('create', google_analytics_id, 'auto', params);
    } // pageEvent(order, page) {
    //   if (isBlank(order)) {
    //     return false;
    //   }
    //   this.googleEvent(order, page);
    // },
    // googleEvent(order, event) {
    //   return this.doGoogleTrackers(order.get('project'), (tracker) => {
    //     window.ga(
    //       `${tracker.namePrefix}send`,
    //       {
    //         hitType: 'event',
    //         eventCategory: `${order.get('project.name')}: ${order.get('source.friendly_name')}`,
    //         eventAction: event
    //       }
    //     );
    //   });
    // },


  });

  var _default = Ember.Service.extend(Analytics, {
    initialize(order) {
      return this.initAnalytics(order);
    }

  });

  _exports.default = _default;
});