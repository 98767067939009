define("manage/pods/companies/company/projects/project/info/shipping/components/product-rate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    classNameBindings: ['is_zero:zero'],
    location: null,
    // This is a calcualted location from the controller
    zone_locations: null,
    // This is all the zone locations for the product
    shipping_rates: Ember.computed.filter('zone_locations.@each.{country,state}', function (item) {
      return item.get('country.id') == this.get('location.country.id') && item.get('state.id') == this.get('location.state.id');
    }),
    price: Ember.computed('shipping_rates.[]', function () {
      if (this.get('shipping_rates.length') === 1) {
        return this.get('shipping_rates.firstObject.shipping');
      }

      return null;
    }),
    is_null: Ember.computed.equal('price', null),
    is_zero: Ember.computed.equal('price', 0)
  });

  _exports.default = _default;
});