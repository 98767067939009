define("manage/pods/companies/company/projects/project/theme/components/theme-picker-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['theme-pickter-modal', 'component'],
    closable: true,
    actions: {
      set_dark() {
        let theme = this.get('theme');
        theme.setProperties({
          hero_background_color: '#0B090A',
          branding_background_color: '#0B090A',
          branding_border_color: '#3B3B3B',
          header_text_color: '#FFFFFF',
          header_subtext_color: '#D7A88D',
          stats_background_color: '#FFFFFF',
          stats_text_color: '#FFFFFF',
          stats_subtext_color: '#B44F16',
          link_text_color: '#7D7D7D',
          navigation_inactive_background_color: '#3B3B3B',
          navigation_previous_background_color: '#546979',
          navigation_active_background_color: '#7AAC4E',
          navigation_divider_color: '#000000',
          navigation_text_color: '#FFFFFF',
          navigation_text_inactive_color: '#666666',
          navigation_text_completed_color: '#90CC46'
        });
        this.closeModal();
      },

      set_light() {
        let theme = this.get('theme');
        theme.setProperties({
          hero_background_color: "#FFFFFF",
          branding_background_color: "#FFFFFF",
          branding_border_color: "#D9D9D9",
          header_text_color: "#4A4A4A",
          header_subtext_color: "#90CD10",
          stats_background_color: "#929292",
          stats_text_color: "#828282",
          stats_subtext_color: "#828282",
          link_text_color: "#828282",
          navigation_inactive_background_color: '#3B3B3B',
          navigation_previous_background_color: '#546979',
          navigation_active_background_color: '#7AAC4E',
          navigation_divider_color: '#000000',
          navigation_text_color: '#FFFFFF',
          navigation_text_inactive_color: '#666666',
          navigation_text_completed_color: '#90CC46'
        });
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});