define("manage/helpers/t-component", ["exports", "manage/helpers/t-component-path"], function (_exports, _tComponentPath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [component, path, params] = _ref;
      // Clone params to not modify them
      let parameters = Object.assign({}, params); // If args is present, merge with parameters

      if (Ember.isPresent(args)) {
        parameters = Object.assign({}, parameters, args);
      }

      let fullPath = (0, _tComponentPath.componentPath)([component, path]);
      const startPath = 'component.'; // Try to find the translation from a direct file

      if (fullPath.startsWith(startPath)) {
        let namedFile = fullPath.substr(startPath.length, fullPath.length - startPath.length);

        if (this.intl.exists(namedFile)) {
          return this.get('intl').t(namedFile, parameters);
        }
      }

      if (this.intl.exists(fullPath)) {
        return this.get('intl').t(fullPath, parameters);
      }

      const subComponent = '.components.';

      if (fullPath.includes(subComponent)) {
        const lastIndex = fullPath.lastIndexOf(subComponent);
        fullPath = fullPath.substr(0, lastIndex + 1) + fullPath.substr(lastIndex + subComponent.length);
      }

      return this.get('intl').t(fullPath, parameters);
    }

  });

  _exports.default = _default;
});