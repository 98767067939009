define("manage/pods/companies/company/projects/project/orders/view/components/cancel-order-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ucT/pXuZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Cancel Customer Order Confirmation\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic segment content \",[28,\"if\",[[24,[\"is_processing\"]],\"loading\"],null],\" \",[28,\"if\",[[24,[\"has_error\"]],\"error\"],null]]]],[8],[0,\"\\n  By cancelling this customers order you are cancelling their\\n  rewards, extras and any other involvement in this project.\\n\\n  This will zero out their balance but it WILL NOT refund their pledges.\\n  If they purchased anything on Crowd Ox, please refund that first and then cancel.\\n  Any External pledges will still need to be refunded.\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small orange header\"],[8],[0,\"\\n    Would you like cancel \"],[1,[24,[\"order\",\"customer\",\"full_name\"]],false],[0,\"?\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n    An error occurred while cancelling this customers order. If this continues please contact support.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button cancel\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"No\"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"Yes\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/cancel-order-modal/template.hbs"
    }
  });

  _exports.default = _default;
});