define("manage/pods/admin/projects/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      this.replaceWith('admin.projects.status');
    }

  });

  _exports.default = _default;
});