define("manage/utils/array-intersect", ["exports", "shared/utils/array-intersect"], function (_exports, _arrayIntersect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _arrayIntersect.default;
    }
  });
});