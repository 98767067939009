define("manage/pods/admin/projects/status/controller", ["exports", "ember-concurrency", "shared/utils/logger"], function (_exports, _emberConcurrency, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['launched', 'launched_year', 'fulfillment_status', 'refreshed_at', 'sort', 'sortDirection', 'page'],
    page_size: 15,
    // Sorting
    sortProperty: Ember.computed('sort', function () {
      return [this.get('sort')];
    }),
    // Filters
    launched: '',
    launched_year: '',
    refreshed_at: '',
    sort: 'launched_at',
    sortDirection: 'asc',
    page: 1,
    projects: Ember.computed.readOnly('model'),
    current_user: Ember.inject.service('user'),
    launched_statuses: Ember.A([Ember.Object.create({
      value: "true",
      name: "Launched Only"
    }), Ember.Object.create({
      value: "false",
      name: "Unlaunched Only"
    })]),
    launched_years: Ember.A([Ember.Object.create({
      value: "2015",
      name: "2015"
    }), Ember.Object.create({
      value: "2016",
      name: "2016"
    }), Ember.Object.create({
      value: "2017",
      name: "2017"
    }), Ember.Object.create({
      value: "2018",
      name: "2018"
    }), Ember.Object.create({
      value: "2019",
      name: "2019"
    }), Ember.Object.create({
      value: "2020",
      name: "2020"
    }), Ember.Object.create({
      value: "2021",
      name: "2021"
    })]),
    fulfillment_filters: Ember.A([Ember.Object.create({
      value: "any",
      name: "Has Any Status"
    }), Ember.Object.create({
      value: "empty",
      name: "Has No Status"
    }), Ember.Object.create({
      value: "fulfilled",
      name: "Majority fulfilled"
    }), Ember.Object.create({
      value: "partially_fulfilled",
      name: "Started fulfillment"
    }), Ember.Object.create({
      value: "not_fulfilled",
      name: "Not fulfilled"
    }), Ember.Object.create({
      value: "unable_to_fulfill",
      name: "Wiill not be fulfilling"
    })]),
    fulfillment_statuses: Ember.A([{
      groupName: "Fulfillment Status",
      options: Ember.A([Ember.Object.create({
        value: "fulfilled",
        name: "Majority fulfilled (60% fulfilled or more)"
      }), Ember.Object.create({
        value: "partially_fulfilled",
        name: "Started fulfillment"
      }), Ember.Object.create({
        value: "not_fulfilled",
        name: "Not yet (gathering information or preparing)"
      })])
    }, {
      groupName: "Other",
      options: Ember.A([Ember.Object.create({
        value: "unable_to_fulfill",
        name: "Will not be fulfilling"
      })])
    }]),
    saveProjectTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      project.set('taskStatus', 'saving');

      try {
        project.set('fulfillment_status_updated_by', 'crowdox');
        yield project.save();
        project.set('taskStatus', 'saved');
      } catch (e) {
        (0, _logger.logger_error)(e.message);
        project.set('taskStatus', 'error');
      }
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      refresh() {
        this.set('refreshed_at', new Date());
      }

    }
  });

  _exports.default = _default;
});