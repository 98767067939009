define("manage/pods/companies/company/projects/project/settings/payments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fB1VpIdf",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t-title\",[[23,0,[]]],null],false],[0,\"\\n\"],[4,\"page-banner\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui text menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n        \"],[1,[28,\"t-route\",[\"header\"],null],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n          \"],[1,[28,\"bread-crumb\",[\"companies.company.projects.project.settings\"],null],false],[0,\"\\n          \"],[1,[28,\"bread-crumb\",[\"companies.company.projects.project.settings.payments\"],[[\"text\"],[[28,\"t-route\",[\"overview\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right menu\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"inner layout\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"companies/company/projects/project/settings/payments/connect-stripe\",null,[[\"project\",\"projects\",\"app\",\"payment_gateways\",\"current_user\",\"companies_user\",\"afterInstall\"],[[24,[\"project\"]],[24,[\"projects\"]],[24,[\"stripe_app\"]],[24,[\"stripe_payment_gateways\"]],[24,[\"current_user\"]],[24,[\"companies_user\"]],[28,\"action\",[[23,0,[]],\"refresh_route\"],null]]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/settings/payments/template.hbs"
    }
  });

  _exports.default = _default;
});