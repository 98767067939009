define("manage/helpers/error-messages", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.errorMessages = errorMessages;
  _exports.friendly = friendly;

  function friendly(text) {
    return text.replace(/_/, ' ').replace(/\w\S*/g, function (text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    });
  }

  function errorMessages(_ref) {
    let [errors] = _ref;

    if (Ember.isBlank(errors)) {
      return;
    }

    let html = "<ul class='ui list'>";

    if (typeof errors === "string") {
      html += "<li>" + errors + "</li>";
    } else if (errors.constructor === _emberData.default.Errors || !Ember.isArray(errors) && errors.forEach != null) {
      errors.forEach(function (item) {
        html += "<li><b>" + friendly(item.attribute) + ":</b> " + item.message + "</li>";
      });
    } else if (Ember.isArray(errors)) {
      let i, len, item;

      for (i = 0, len = errors.length; i < len; i++) {
        item = errors[i];
        html += "<li>" + item + "</li>";
      }
    } else {
      let key, value;

      for (key in errors) {
        value = errors[key];

        if (Ember.isArray(value)) {
          let j, len1, item;

          for (j = 0, len1 = value.length; j < len1; j++) {
            item = value[j];
            html += "<li><b>" + friendly(key) + ":</b> " + item + "</li>";
          }
        } else {
          html += "<li><b>" + friendly(key) + ":</b> " + value + "</li>";
        }
      }
    }

    html += "</ul>";
    return new Ember.String.htmlSafe(html);
  }

  var _default = Ember.Helper.helper(errorMessages);

  _exports.default = _default;
});