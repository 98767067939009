define("manage/pods/companies/company/projects/project/sources/extras/components/edit-project-extras-mappings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // making more unique to avoid common work issues
    classNames: ['edit-project-extras-mappings', 'component'],
    current_user: Ember.inject.service('user'),
    store: Ember.inject.service(),
    productTasks: Ember.inject.service(),
    active_section: "products",
    // Aliases to help out in view
    products: Ember.computed.filterBy('all_products', 'is_physical_or_digital', true),
    sections: Ember.computed.filterBy('all_products', 'is_section', true),
    bundles: Ember.computed.filterBy('all_products', 'is_bundle', true),
    actual_product_mappings: Ember.computed.filterBy('product.mappings', 'isDeleted', false),
    // Filters on the left
    remaining_products: Ember.computed('products.@each.name', 'actual_product_mappings.@each.child', function () {
      let product_mappings_children = this.get('actual_product_mappings').mapBy('child.content');
      let products = this.get('products').filter(product => {
        return !product_mappings_children.includes(product);
      });
      return products.sortBy('name');
    }),
    remaining_bundles: Ember.computed('bundles.@each.name', 'actual_product_mappings.@each.child', function () {
      let product_mappings_children = this.get('actual_product_mappings').mapBy('child.content');
      let products = this.get('bundles').filter(product => {
        return !product_mappings_children.includes(product);
      });
      return products.sortBy('name');
    }),
    remaining_sections: Ember.computed('sections.@each.name', 'actual_product_mappings.@each.child', function () {
      let product_mappings_children = this.get('actual_product_mappings').mapBy('child.content');
      let sections = this.get('sections').filter(section => {
        return !product_mappings_children.includes(section);
      });
      return sections.sortBy('name');
    }),
    // Mappings, we need to separate them because of how they are shown in the ui
    products_mapped_sorted: Ember.computed('actual_product_mappings.@each.{is_child_is_physical_or_digital,is_child_section,is_child_bundle,sort_order}', function () {
      let mappings = this.get('actual_product_mappings').filter(mapping => {
        return mapping.get('is_child_is_physical_or_digital') || mapping.get('is_child_section') || mapping.get('is_child_bundle');
      });
      return mappings.sortBy('sort_order');
    }),
    actions: {
      order_mappings(mappings) {
        mappings = mappings.filterBy('isDeleted', false);
        let products = mappings.filterBy('child.is_question', false);
        products.forEach((product, index) => product.set('sort_order', index + 1));
      },

      map_product(product) {
        let sort_orders = this.get('actual_product_mappings').mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        this.get('store').createRecord('product-mapping', {
          enabled: true,
          is_default: false,
          project: this.get('project'),
          parent: this.get('product'),
          child: product,
          sort_order: sort_order
        });
        this.send('order_mappings', this.get('products_mapped_sorted').toArray());
      },

      add_section() {
        let project = this.get('project');
        let all_products = this.get('all_products');
        this.get('productTasks.create').perform(project, {
          product_type: 'section'
        }).then(product => {
          this.send('open_modal', 'companies/company/projects/project/sources/extras/components/section-modal', {
            product: product,
            all_products: all_products
          });
        });
      }

    }
  });

  _exports.default = _default;
});