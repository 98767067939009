define("manage/mixins/filters/product-waves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    product_waves: Ember.computed('project.features.[]', function () {
      let waves = [Ember.Object.create({
        id: 'one',
        title: 'One'
      }), Ember.Object.create({
        id: 'two',
        title: 'Two'
      })];

      if ((this.get('project.features') || Ember.A()).any(f => f.name === "wave-shipping" && f.settings.wave_shipments === "three")) {
        waves.push(Ember.Object.create({
          id: 'three',
          title: 'Three'
        }));
      }

      return waves;
    })
  });

  _exports.default = _default;
});