define("manage/pods/companies/company/projects/project/products/index/components/column-dimensions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kgmy11q7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"product\",\"dimensions\"]],[24,[\"product\",\"dimensions\",\"length\"]],[24,[\"product\",\"dimensions\",\"width\"]],[24,[\"product\",\"dimensions\",\"height\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"ruby\",true],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"dimensions\",\"length\"]],false],[0,\" \"],[7,\"rt\",true],[8],[0,\"L\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  x\\n  \"],[7,\"ruby\",true],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"dimensions\",\"width\"]],false],[0,\" \"],[7,\"rt\",true],[8],[0,\"W\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  x\\n  \"],[7,\"ruby\",true],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"dimensions\",\"height\"]],false],[0,\" \"],[7,\"rt\",true],[8],[0,\"H\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/index/components/column-dimensions/template.hbs"
    }
  });

  _exports.default = _default;
});