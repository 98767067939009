define("manage/helpers/t-format", ["exports", "shared/helpers/t-format"], function (_exports, _tFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tFormat.default;
    }
  });
});