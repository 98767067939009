define("manage/components/icon-kickstarter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BS1Ohatn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",[29,[[22,\"width\"]]]],[11,\"height\",[29,[[22,\"height\"]]]],[10,\"viewBox\",\"0 0 12 14\"],[10,\"version\",\"1.1\"],[8],[7,\"path\",true],[10,\"d\",\"M4.59186555 4.8 L7.18359664 1.1 C7.67327731 0.4 8.3 0 9.1 0 C9.71428571 0 10.3 0.2 10.7 0.7 C11.1868235 1.1 11.4 1.6 11.4 2.3 C11.4183529 2.7 11.3 3.1 11.1 3.5 L8.71421849 6.8 L11.5713613 10.4 C11.8569412 10.8 12 11.2 12 11.7 C12 12.3 11.8 12.9 11.3 13.3 C10.8773109 13.8 10.3 14 9.7 14 C8.99307563 14 8.5 13.8 8.1 13.3 L4.59186555 9 L4.59186555 11.4 C4.59186555 12.1 4.5 12.6 4.2 13 C3.79912605 13.7 3.2 14 2.3 14 C1.58171429 14 1 13.7 0.6 13.2 C0.193882353 12.8 0 12.2 0 11.4 L0 2.5 C0 1.8 0.2 1.2 0.6 0.7 C1.00678992 0.2 1.6 0 2.3 0 C2.99993277 0 3.6 0.2 4 0.7 C4.29929412 1 4.5 1.3 4.5 1.6 C4.57115966 1.8 4.6 2.1 4.6 2.6 L4.59186555 4.8 L4.59186555 4.8 Z\"],[10,\"id\",\"Fill-2\"],[10,\"fill\",\"#55B267\"],[10,\"sketch:type\",\"MSShapeGroup\"],[8],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/icon-kickstarter/template.hbs"
    }
  });

  _exports.default = _default;
});