define("manage/components/country-dropdown/component", ["exports", "shared/mixins/load-countries", "ember-concurrency", "shared/utils/logger"], function (_exports, _loadCountries, _emberConcurrency, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_loadCountries.default, {
    classNames: ['country-dropdown', 'component'],
    label: 'Country',
    store: Ember.inject.service(),
    renderInPlace: false,
    tabindex: 0,

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('loadGroupedCountriesTask').perform();
    },

    loadGroupedCountriesTask: (0, _emberConcurrency.task)(function* () {
      let configuration = yield this.get('configuration');

      if (Ember.isBlank(configuration)) {
        let countries = this.get('store').peekAll('country');
        return this.groupCountries(countries);
      } // Ember loads a ton, try to avoid it


      if (this.get('last_configuration_id') === configuration.get('id')) {
        return;
      }

      this.set('last_configuration_id', configuration.get('id'));
      (0, _logger.logger_warn)("Loading countries for configuration");
      let product_bundle = yield configuration.get('product_reward'); // Ensure we load the location rates

      yield this.get('store').findRecord('product', product_bundle.get('id'), {
        include: ['zones', 'zones.locations'].join(',')
      });
      let result = yield this.get('loadCountriesTask').perform({
        product_bundle
      });
      let countries = result.countries;
      return this.groupCountries(countries);
    }),

    groupCountries(countries) {
      let frequently_used = countries.filter(c => c.get('sort_order') < 100).sortBy('sort_order');
      let everywhere = countries.sortBy('name');
      let grouped_countries = [];

      if (Ember.isPresent(frequently_used)) {
        grouped_countries.push({
          groupName: "Frequently Used",
          options: frequently_used
        });
      }

      grouped_countries.push({
        groupName: "Everywhere",
        options: everywhere
      });
      this.set('grouped_countries', grouped_countries);
    }

  });

  _exports.default = _default;
});