define("manage/pods/companies/company/projects/project/orders/exports/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      // Filters
      search: {
        refreshModel: true
      },
      behavior: {
        refreshModel: true
      },
      hidden: {
        refreshModel: true
      },
      closed: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        segments: this.search(project, params),
        countries: this.store.peekAll('country')
      });
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let search = params.search;
      let behavior = params.behavior;
      let hidden = params.hidden;
      let closed = params.closed;
      let filters = {
        project: project.get('id'),
        category: 'export',
        downloads: true,
        search: search,
        hidden: hidden
      };

      if (closed !== 'all') {
        filters['closed'] = closed;
      }

      if (behavior !== 'all') {
        filters['behavior'] = behavior;
      }

      return this.store.query('project-segment', {
        include: ['actions'].join(','),
        filter: filters,
        page: {
          number: page,
          size: pageSize
        },
        sort: "-created_at"
      });
    }

  });

  _exports.default = _default;
});