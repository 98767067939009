define("manage/pods/companies/company/projects/project/segments/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      // Filters
      search: {
        refreshModel: true
      },
      behavior: {
        refreshModel: true
      },
      hidden: {
        refreshModel: true
      },
      closed: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        segments: this.search(project, params),
        countries: this.store.peekAll('country')
      });
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let search = params.search;
      let behavior = params.behavior;
      let hidden = params.hidden;
      let closed = params.closed;
      let filters = {
        project: project.get('id'),
        search: search,
        hidden: hidden
      };

      if (closed !== 'all') {
        filters['closed'] = closed;
      }

      switch (behavior) {
        case 'all':
          break;

        case 'fixed':
          filters['behavior'] = behavior;
          break;

        case 'dynamic':
          filters['behavior'] = behavior;
          filters['category'] = 'filter';
          break;

        case 'dynamic_filter':
        case 'dynamic_automated':
          filters['behavior'] = behavior.split('_')[0];
          filters['category'] = behavior.split('_')[1];
          break;

        default:
          filters['behavior'] = 'none';
          break;
      }

      return this.store.query('project-segment', {
        include: ['actions'].join(','),
        filter: filters,
        page: {
          number: page,
          size: pageSize
        },
        sort: "-created_at"
      });
    }

  });

  _exports.default = _default;
});