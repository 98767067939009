define("manage/pods/companies/company/projects/project/digital/components/edit-fulfillment-modal/component", ["exports", "ember-concurrency", "manage/mixins/ss-modal", "manage/config/environment"], function (_exports, _emberConcurrency, _ssModal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    project: Ember.computed.alias('fulfillment.project'),
    closable: false,
    isRunning: Ember.computed.or('saveTask.isRunning', 'loadEmailsTask.isRunning'),

    init() {
      this._super(...arguments);

      this.get('loadEmailsTask').perform();
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      let fulfillment = yield this.get('fulfillment');
      yield fulfillment.save();
    }),
    loadEmailsTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let emails = yield this.get('store').query('project-email', {
        filter: {
          project: project.get('id'),
          template: 'digital-fulfillment,none'
        },
        page: {
          size: _environment.default['max-page-size']
        }
      });
      this.set('emails', emails);
    }),
    actions: {
      close() {
        this.closeModal();
      },

      save() {
        this.get('saveTask').perform().then(() => {
          return this.send('close');
        });
      }

    }
  });

  _exports.default = _default;
});