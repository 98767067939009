define("manage/pods/companies/company/projects/project/products/choices/create/route", ["exports", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    controllerName: 'companies.company.projects.project.products.choices.edit',
    templateName: 'companies.company.projects.project.products.choices.edit',
    productTasks: Ember.inject.service(),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let type = params.type;
      let product_types = ['physical', 'digital'];
      let product_data = {
        product_type: 'option'
      };

      if (type === 'bundle') {
        product_types.push('question');
        product_types.push('option');
        product_data.product_type = 'bundle';
        product_data.custom_bundle = true;
      }

      return this.get('productTasks.create').perform(project, product_data).then(product => {
        return Ember.RSVP.hash({
          project: project.reload(),
          // Update edit if you update this
          all_products: this.store.query('product', {
            filter: {
              project: project.get('id'),
              product_type: product_types
            },
            include: ['questions'].join(','),
            page: {
              size: _environment.default['max-page-size']
            }
          }),
          product: product
        });
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product) {
          let product = record.product;
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(product).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'cancel-modal', {
                  model: product,
                  modelName: "Product",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});