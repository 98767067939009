define("manage/models/zone-shipping", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-cp-validations", "@ember-data/model"], function (_exports, _fragment, _attributes, _emberCpValidations, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    type: {
      description: "Type",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        in: ['weight']
      })]
    },
    prices: {
      description: "Prices",
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 1
      }), (0, _emberCpValidations.validator)('has-many')]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    type: (0, _model.attr)('string'),
    prices: (0, _attributes.fragmentArray)('weight-shipping-rate'),
    parent: Ember.computed.readOnly('_internalModel._recordData._owner'),
    project: Ember.computed.readOnly('parent.project')
  });

  _exports.default = _default;
});