define("manage/mixins/restrict-by-feature", ["exports", "shared/helpers/can-access-feature"], function (_exports, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(feature) {
    return Ember.Mixin.create({
      redirect(model) {
        this._super(...arguments);

        let controller = this.controllerFor(this.routeName);

        if (!(0, _canAccessFeature.canAccessFeature)([Ember.get(model, 'project'), feature, Ember.get(controller, 'current_user')])) {
          this.transitionTo('user.login');
          return;
        }
      }

    });
  }
});