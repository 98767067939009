define("manage/pods/companies/company/projects/project/orders/view/components/invite-email-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    has_error: false,
    can_invite: Ember.computed('order.current_configuration.is_live', function () {
      if (this.get('order.current_configuration.is_live')) {
        return true;
      }

      return false;
    }),
    inviteTask: (0, _emberConcurrency.task)(function* () {
      this.set('has_error', false);

      if (!this.get('can_invite')) {
        return;
      }

      let order = yield this.get('order');
      let project = yield order.get('project'); // We need to allow a filter by template on api

      let emails = yield this.get('store').query('project-email', {
        filter: {
          project: project.get('id')
        },
        page: {
          size: 50
        }
      });
      let email = emails.findBy('template', 'claim-rewards');

      if (Ember.isBlank(email)) {
        this.set('has_error', true);
        return;
      }

      try {
        yield this.get('ajax').postModel(order, `email`, {
          data: {
            project_email_id: email.get('id')
          }
        });
      } catch (e) {
        this.set('has_error', true);
        return;
      }

      this.closeModal();
      this.onCompletion();
    }).drop()
  });

  _exports.default = _default;
});