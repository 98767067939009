define("manage/models/project-theme", ["exports", "ember-data-model-fragments/fragment", "shared/utils/computed-default-value", "shared/mixins/common-theme", "@ember-data/model"], function (_exports, _fragment, _computedDefaultValue, _commonTheme, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This should mostly match the api and project layout for easy reference
  var _default = _fragment.default.extend(_commonTheme.default, {
    // Colors Navigation
    navigation_inactive_background_color: (0, _model.attr)('string'),
    navigation_previous_background_color: (0, _model.attr)('string'),
    navigation_active_background_color: (0, _model.attr)('string'),
    navigation_divider_color: (0, _model.attr)('string'),
    navigation_text_color: (0, _model.attr)('string'),
    navigation_text_inactive_color: (0, _model.attr)('string'),
    navigation_text_completed_color: (0, _model.attr)('string'),
    // Colors Navigation Default
    default_navigation_inactive_background_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_inactive_background_color', '#3B3B3B'),
    default_navigation_previous_background_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_previous_background_color', '#546979'),
    default_navigation_active_background_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_active_background_color', '#7AAC4E'),
    default_navigation_divider_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_divider_color', '#000000'),
    default_navigation_text_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_text_color', '#FFFFFF'),
    default_navigation_text_inactive_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_text_inactive_color', '#666666'),
    default_navigation_text_completed_color: (0, _computedDefaultValue.computedDefaultSafeString)('navigation_text_completed_color', '#90CC46'),
    // Custom
    custom_js: (0, _model.attr)('string'),
    // Messages (Bools)
    extras_message_replace: (0, _model.attr)('boolean'),

    hex_to_decimal(hex) {
      // Remove safe string
      hex = hex.string || hex;
      return [parseInt(hex.slice(1, 3), 16), parseInt(hex.slice(3, 5), 16), parseInt(hex.slice(5, 7), 16)].join(', ');
    },

    navigation_css: Ember.computed('default_navigation_inactive_background_color', 'default_navigation_previous_background_color', 'default_navigation_active_background_color', 'default_navigation_divider_color', 'default_navigation_text_color', 'default_navigation_text_inactive_color', 'default_navigation_text_completed_color', function () {
      return [`.ui.confirmation.menu {
        background: rgba(${this.hex_to_decimal(this.get('default_navigation_inactive_background_color'))}, 0.9);
      }`, `.ui.confirmation.menu  > .item {
        border-right: solid 1px ${this.get('default_navigation_divider_color')};
        color: ${this.get('default_navigation_text_color')};
        background: ${this.get('default_navigation_previous_background_color')};
      }`, `.ui.confirmation.menu > .item:hover {
        background: rgba(${this.hex_to_decimal(this.get('default_navigation_previous_background_color'))}, 0.8);
        color: rgba(${this.hex_to_decimal(this.get('default_navigation_text_color'))}, 0.8);
      }`, `.ui.confirmation.menu > .item:last-child {
        border-right-color: rgba(${this.hex_to_decimal(this.get('default_navigation_inactive_background_color'))}, 0.9);
      }`, `.ui.confirmation.menu > .item.active {
        background: ${this.get('default_navigation_active_background_color')};
        color: ${this.get('default_navigation_text_color')};
      }`, `.ui.confirmation.menu > .item.active span.circled {
        border-color: ${this.get('default_navigation_text_color')};
        background: ${this.get('default_navigation_text_color')};
        color: ${this.get('default_navigation_active_background_color')};
      }`, `.ui.confirmation.menu > .item.active:after {
        background-color: ${this.get('default_navigation_active_background_color')};
      }`, `.ui.confirmation.menu  > .item span.circled {
        border-color: ${this.get('default_navigation_text_completed_color')};
        background: ${this.get('default_navigation_text_completed_color')};
      }`, `.ui.confirmation.menu  > .item.active ~ .item {
        color: ${this.get('default_navigation_text_color')} !important;
      }`, `.ui.confirmation.menu  > .item.active ~ .item span.circled {
        border-color: ${this.get('default_navigation_text_inactive_color')};
        color: ${this.get('default_navigation_text_inactive_color')};
      }`];
    })
  });

  _exports.default = _default;
});