define("manage/pods/companies/company/projects/project/orders/view/components/add-order-selection-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "ember-cp-validations", "manage/config/environment"], function (_exports, _ssModal, _emberConcurrency, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    item_type: {
      description: "Item Type",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.isNew', function () {
          if (this.get('model.isNew') === true) {
            return false;
          }

          return true; // disabled by default, unless its new
        })
      })]
    },
    product_price_custom: {
      description: "Custom Product Price",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        gte: 0,
        disabled: Ember.computed.not('model.use_price_info')
      })]
    },
    shipping_price_custom: {
      description: "Custom Shipping Price",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        gte: 0,
        disabled: Ember.computed.not('model.use_price_info')
      })]
    }
  });

  const parseNumber = function (property, isFloat) {
    const parse = function (value) {
      if (isFloat) {
        return parseFloat(value);
      }

      return parseInt(value, 10);
    };

    return Ember.computed(property, {
      get() {
        let number = parse(this.get(property));

        if (isNaN(number)) {
          return null;
        }

        return number;
      },

      set(key, value) {
        let number = parse(value);

        if (isNaN(number)) {
          this.set(property, null);
          return null;
        }

        this.set(property, number);
        return number;
      }

    });
  };

  const totalPrice = function (price_quantity, quantity) {
    return Ember.computed(price_quantity, quantity, function () {
      let total = this.get(price_quantity) * this.get(quantity);

      if (isNaN(total)) {
        return null;
      }

      return total;
    });
  };

  var _default = Ember.Component.extend(_ssModal.default, validations, {
    classNames: ['add-order-selection-modal', 'component'],
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    item_types: [Ember.Object.create({
      product_type: "physical",
      title: "Physical"
    }), Ember.Object.create({
      product_type: "digital",
      title: "Digital"
    }) // Add shipping types in future?
    ],
    line_types: [Ember.Object.create({
      id: "extra",
      title: "Extra"
    }), Ember.Object.create({
      id: "manual",
      title: "Reward"
    }), Ember.Object.create({
      id: "external",
      title: "External Purchase"
    })],
    // Internal variables
    isNew: false,
    item_type: null,
    showing_price_info: false,
    product_price_selection: 'default',
    shipping_price_selection: 'default',
    closable: false,

    ///////////////
    // Init
    ///////////////
    init() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('order_selection'))) {
        this.get('initTaskEdit').perform(this.get('order_selection'));
      } else {
        this.get('initTaskNew').perform(this.get('order'));
      }
    },

    initTaskNew: (0, _emberConcurrency.task)(function* (order) {
      this.set('isNew', true);
      order = yield order; // Setup order

      let order_line = yield this.get('orderTasks.generateOrderLine').perform(order, 'extra');
      this.set('order_line', order_line);
      let order_selection = yield this.get('orderTasks.generateOrderSelection').perform(order_line, null, 1);
      this.set('order_selection', order_selection);
    }),
    initTaskEdit: (0, _emberConcurrency.task)(function* (order_selection) {
      this.set('isNew', false);
      order_selection = yield order_selection;
      let order = yield order_selection.get('order');
      let order_line = yield order_selection.get('line');
      this.set('order_line', order_line);
      this.set('order', order);

      if (order_line.get('is_bundle')) {
        let product_option = yield order_selection.get('product_option');

        if (Ember.isPresent(product_option)) {
          yield this.get('store').findRecord('product', product_option.get('id'), {
            include: ['mappings', 'mappings.child', 'mappings.child.questions', 'mappings.child.questions.choices', 'mappings.child.variants', 'mappings.child.variants.values', 'mappings.child.variations'].join(',')
          });
        }
      } // Load any questions


      let product = yield order_selection.get('product');

      if (Ember.isPresent(product)) {
        yield this.get('store').findRecord('product', product.get('id'), {
          include: ['zones', 'zones.locations', 'questions', 'questions.choices', 'variants', 'variants.values', 'variations'].join(',')
        });
      } // This also updates questions


      yield this.get('orderTasks.updateOrderSelection').perform(order_selection); // Check and initialize price data points

      if (this.get('order_selection.price_data.is_fixed')) {
        if (this.get('order_selection.price_data.settings.product') === 'fixed') {
          this.set('product_price_selection', 'custom');

          if (this.get('order_selection.prices.product.amount_per_quantity') > 0) {
            this.set('_product_price_custom_per_quantity', this.get('order_selection.prices.product.amount_per_quantity'));
          }
        }

        if (this.get('order_selection.price_data.settings.shipping') === 'fixed') {
          this.set('shipping_price_selection', 'custom');

          if (this.get('order_selection.prices.shipping.amount_per_quantity') > 0) {
            this.set('_shipping_price_custom_per_quantity', this.get('order_selection.prices.shipping.amount_per_quantity'));
          }
        }
      }
    }),
    ///////////////
    // End Init
    ///////////////
    rollbackTask: (0, _emberConcurrency.task)(function* () {
      let order_selection = yield this.get('order_selection');

      try {
        // New
        if (order_selection.get('isNew')) {
          yield this.get('orderTasks.removeOrderSelection').perform(order_selection, true);
        } else {
          // already exists
          let order_selection_questions = yield order_selection.get('questions');

          for (let order_selection_question of order_selection_questions.toArray()) {
            order_selection_question.rollbackRelationships();
            order_selection_question.rollbackAttributes();
          }

          order_selection.rollbackRelationships();
          order_selection.rollbackAttributes();
        }

        this.closeModal();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to revert changes on close."
        });
      }
    }).drop(),
    loadItemTypeTask: (0, _emberConcurrency.task)(function* (item_type) {
      try {
        // This is the full ember object
        this.set('item_type', item_type);
        let order_selection = yield this.get('order_selection'); // clear product

        order_selection.set('product', null);
        order_selection.set('product_variation', null);
        let order = yield this.get('order');
        return yield this.get('store').query('product', {
          filter: {
            project: order.get('project.id'),
            product_type: item_type.get('product_type')
          },
          page: {
            number: 1,
            size: _environment.default['max-page-size']
          },
          sort: 'name'
        });
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to load selected items for type."
        });
        return [];
      }
    }).drop(),
    setProductTask: (0, _emberConcurrency.task)(function* (product) {
      try {
        product = yield product; // Load full product details

        product = yield this.get('store').findRecord('product', product.get('id'), {
          include: ['zones', 'zones.locations', 'questions', 'questions.choices', 'variants', 'variants.values', 'variations'].join(',')
        });
        let order_selection = yield this.get('order_selection');
        order_selection.set('product', product);
        order_selection.set('product_variation', null); // This also updates questions

        yield this.get('orderTasks.updateOrderSelection').perform(order_selection);
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to update product selection."
        });
      }
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('submitted', true);
        let {
          validations: modal_validations
        } = yield this.validate();

        if (!modal_validations.get('isValid')) {
          return;
        }

        let order = yield this.get('order');
        let order_line = yield this.get('order_line');
        let order_selection = yield this.get('order_selection'); // Order line

        let {
          validations: line_validations
        } = yield order_line.validate({
          excludes: ['selections']
        });

        if (!line_validations.get('isValid')) {
          return;
        } // Order selection


        let excludes = []; // If order isn't completed, we can let the customer finish it later
        // TODO: Update backend to allow creating as a creator without information
        // if (!order.get('is_completed')) {
        //   excludes.push('product_variation');
        //   excludes.push('questions');
        // }

        let {
          validations: selection_validations
        } = yield order_selection.validate({
          excludes: excludes
        });

        if (!selection_validations.get('isValid')) {
          return;
        }

        yield this.get('orderTasks.save').perform(order, {
          validate: false,
          save_shipping_address: false
        });

        if (this.get('use_price_info')) {
          // Calculate price data
          let product_amount_per_quantity = null;
          let shipping_amount_per_quantity = null;

          if (this.get('product_price_selection') === 'custom') {
            if (Ember.isBlank(this.get('product_price_custom_per_quantity'))) {
              product_amount_per_quantity = 0;
            } else {
              product_amount_per_quantity = this.get('product_price_custom_per_quantity');
            }
          }

          if (this.get('shipping_price_selection') === 'custom') {
            if (Ember.isBlank(this.get('shipping_price_custom_per_quantity'))) {
              shipping_amount_per_quantity = 0;
            } else {
              shipping_amount_per_quantity = this.get('shipping_price_custom_per_quantity');
            }
          }

          yield order_selection.priceData('fixed', product_amount_per_quantity, shipping_amount_per_quantity);
        }

        yield this.get('store').findRecord('order', order.get('id'), {
          include: ['lines', 'selections'].join(',')
        });
        this.closeModal();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to add Order Selection."
        });
      }
    }).drop(),
    isRunning: Ember.computed.or('initTaskNew.isRunning', 'initTaskEdit.isRunning', 'rollbackTask.isRunning', 'setProductTask.isRunning', 'saveTask.isRunning'),
    form_invalid: Ember.computed('submitted', 'validations.isInvalid', 'order_selection.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      if (this.get('validations.isInvalid')) {
        return true;
      }

      return this.get('order_selection.validations.isInvalid');
    }),
    show_selection_error_message: Ember.computed('submitted', 'order_selection.in_bundle', 'order.is_completed', 'order_selection.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      if (this.get('order_selection.in_bundle')) {
        return this.get('order_selection.validations.isInvalid');
      } // TODO: Update backend to allow creating as a creator with partial information
      // if (!this.get('order.is_completed')) {
      //   return false;
      // }


      return this.get('order_selection.validations.isInvalid');
    }),
    order_selection_quantity: parseNumber('order_selection.quantity', false),
    _product_price_custom_per_quantity: null,
    product_price_custom_per_quantity: parseNumber('_product_price_custom_per_quantity', true),
    product_price_custom: totalPrice('product_price_custom_per_quantity', 'order_selection.quantity'),
    _shipping_price_custom_per_quantity: null,
    shipping_price_custom_per_quantity: parseNumber('_shipping_price_custom_per_quantity', true),
    shipping_price_custom: totalPrice('shipping_price_custom_per_quantity', 'order_selection.quantity'),
    show_price_toggle: Ember.computed('isNew', 'order_selection.in_bundle', 'order_selection.price_data.is_fixed', function () {
      if (this.get('isNew')) {
        return false;
      }

      if (this.get('order_selection.in_bundle')) {
        return false;
      }

      if (this.get('order_selection.price_data.is_fixed')) {
        return false;
      }

      return true;
    }),
    use_price_info: Ember.computed('isNew', 'order_selection.in_bundle', 'order_selection.price_data.is_fixed', 'showing_price_info', function () {
      if (this.get('isNew')) {
        return true;
      }

      if (this.get('order_selection.in_bundle')) {
        return false;
      }

      if (this.get('order_selection.price_data.is_fixed')) {
        return true;
      }

      return this.get('showing_price_info');
    })
  });

  _exports.default = _default;
});