define("manage/pods/companies/company/projects/project/components/app-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    access: false,
    session: Ember.inject.service(),
    name: Ember.computed('app.name', function () {
      return Ember.String.capitalize(this.get('app.name').replace(/-/g, ' '));
    }),

    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('type'))) {
        throw new Error("You must specify a type");
      }

      let action_type = `${this.get('type')}_actions`;

      if (typeof this.get('app')[action_type] === 'function') {
        Ember.defineProperty(this, 'app_actions', this.get('app')[action_type](this.get('session')));
      }
    },

    actions: {
      click_action(action) {
        if (this.get('dropdown') && typeof this.get('dropdown.actions.close') == 'function') {
          this.get('dropdown.actions.close')();
        }

        action.click.call(this);
      }

    }
  });

  _exports.default = _default;
});