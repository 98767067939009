define("manage/mixins/columns", ["exports", "manage/mixins/column"], function (_exports, _column) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),

    /* When extending this mixin, you need to declare four variables.
     columnType: the column type, e.g., 'order', 'product', 'configuration'
     colDefs: an array containing the traits for each column.
    At the very least, each entry should contain an id attribute.
    Other common attributes include sort and default_visible.
     componentPrefix: the directory prefix where we can find the column components.
    We expect to find them all directly underneath this titled "column-${id}",
    where id corresponds to the id attributes in colDefs, above.
     i18nPrefix: the prefix underneath 'columns' in the i18n dirctionary where
    all of the ids are. There shoud be at least one i18n entry for each id, and optionally,
    another entry titled '${id}-help', for an additional description regarding the column.
     See the mixin at app/pods/companies/company/projects/project/orders/index/columns.js
    for an example of this in action.
    */
    setupController(controller, model) {
      this._super(...arguments);

      var column = (0, _column.default)(this.columnType);
      controller.set('columns', this.colDefs(model).map(data => {
        let columnI18nPrefix = `columns.${this.i18nPrefix}.${data['id']}`;
        return column.create(Object.assign({
          session: this.get('session'),
          name: Ember.isPresent(data['name']) ? data['name'] : this.intl.t(...[columnI18nPrefix].concat(data['params'] || {})),
          component: `${this.componentPrefix}/column-${Ember.isPresent(data['component_id']) ? data['component_id'] : Ember.String.dasherize(data['id'])}`,
          component_args: data['args'],
          help: this.intl.exists(`${columnI18nPrefix}-help`) ? this.intl.t(`${columnI18nPrefix}-help`) : null,
          default_visible: false
        }, data));
      }));
    }

  });

  _exports.default = _default;
});