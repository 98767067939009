define("manage/pods/companies/company/projects/project/orders/tags/index/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'order-tag',
    componentPrefix: 'companies/company/projects/project/orders/tags/index',
    i18nPrefix: 'tags.index',
    colDefs: function
      /* model */
    () {
      return [{
        id: 'name',
        sort: 'name',
        default_visible: true
      }, {
        id: 'created',
        sort: 'created_at',
        default_visible: true
      }, {
        id: 'created_by',
        sort: 'user',
        default_visible: true
      }, {
        id: 'orders',
        default_visible: true
      }];
    }
  });

  _exports.default = _default;
});