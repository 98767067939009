define("manage/pods/companies/company/projects/project/sources/extras/components/mapped-product/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mapped-product-extras', 'component'],
    tagName: 'tr',
    current_user: Ember.inject.service('user'),
    disable_delete: Ember.computed('source.configurations_in_setup', 'mapping.isNew', function () {
      if (this.get('mapping.isNew')) {
        return false;
      }

      if (!this.get('source.configurations_in_setup')) {
        return true;
      }

      return false;
    }),
    actions: {
      remove() {
        Ember.RSVP.resolve(this.get('mapping')).then(mapping => {
          mapping.deleteRecord();
        });
      }

    }
  });

  _exports.default = _default;
});