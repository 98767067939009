define("manage/models/project-app", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-app').reopen({
    ajax: Ember.inject.service(),
    app_id: Ember.computed.alias('app.id'),
    type_id: Ember.computed.alias('app.type_id'),
    type_slug: Ember.computed.alias('app.type_slug'),
    slugWithoutPrefix: Ember.computed('slug', function () {
      let slug = this.get('slug');
      let slugIndex = this.get('slug').indexOf('-');

      if (slugIndex > 0) {
        return slug.substring(slugIndex + 1);
      }

      return slug;
    }),

    install(project) {
      let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.get('ajax').postModel(this, 'install', {
        data: {
          'project_id': project.get('id'),
          'settings': settings
        }
      }).then(() => {
        return this.reload();
      });
    },

    uninstall(project) {
      return this.get('ajax').postModel(this, 'uninstall', {
        data: {
          'project_id': project.get('id')
        }
      }).then(() => this.set('installed', false));
    },

    update_settings(project, data) {
      return this.get('ajax').patchModel(this, 'settings', {
        data: {
          'project_id': project.get('id'),
          'data': data
        }
      }).then(() => this.set('settings', data));
    }

  });

  _exports.default = _default;
});