define("manage/components/ss-dimmer", ["exports", "manage/mixins/ss-transition"], function (_exports, _ssTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Relative path works since both survey and manage are in lib/...
  var _default = Ember.Component.extend(_ssTransition.default, {
    classNames: ['ui', 'dimmer'],
    isActive: false,
    // Transition Defaults
    transitionMode: 'fade',
    transitionDuration: 500,

    didInsertElement() {
      this._super(...arguments);

      if (!this.get('active')) {
        this._hide();
      }

      this.doTransition();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.doTransition();
    },

    doTransition() {
      if (this.get('isActive')) {
        this.transitionIn();
      } else {
        this.transitionOut();
      }
    }

  });

  _exports.default = _default;
});