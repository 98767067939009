define("manage/helpers/is-child-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    router: Ember.inject.service(),

    compute(_ref, hash) {
      let [routeName, ...models] = _ref;
      let allModels;

      if (hash.models) {
        allModels = models.concat(hash.models);
      } else {
        allModels = models;
      }

      if (allModels.length === 1 && Ember.isArray(allModels[0])) {
        allModels = models[0];
      }

      if (Ember.isPresent(hash.queryParams)) {
        return this.get('router').isActive(routeName, ...allModels, hash.queryParams);
      } else {
        return this.get('router').isActive(routeName, ...allModels);
      }
    },

    didTransition: Ember.observer('router.currentURL', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});