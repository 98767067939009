define("manage/pods/companies/company/projects/project/sources/extras/components/mapped-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mapped-section-extras', 'component'],
    tagName: 'tr',
    actions: {
      remove() {
        Ember.RSVP.resolve(this.get('mapping')).then(mapping => {
          mapping.deleteRecord();
        });
      }

    }
  });

  _exports.default = _default;
});