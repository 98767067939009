define("manage/mirage/fixtures/apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Kickstarter',
    slug: 'source-kickstarter',
    description: 'Kickstarter maintains a global crowdfunding platform focused on creativity and merchandising.',
    role: 'admin',
    enabled: true,
    default: false,
    icon: 'clipboard',
    appTypeId: 1
  }, {
    id: 2,
    name: 'Indiegogo',
    slug: 'source-indiegogo',
    description: 'Indiegogo maintains allows people to solicit funds for an idea, charity, or start-up business.',
    role: 'admin',
    enabled: true,
    default: false,
    icon: 'clipboard',
    appTypeId: 1
  }, {
    id: 3,
    name: 'Crowd Ox',
    slug: 'source-crowdox',
    description: 'Continue your momentum by accepting pre-orders directly here on Crowd Ox!',
    role: 'creator',
    enabled: true,
    default: false,
    icon: 'crowdox',
    appTypeId: 1
  }, {
    id: 4,
    name: 'Checkout',
    slug: 'checkout',
    role: 'creator',
    enabled: true,
    default: true,
    icon: 'bullseye',
    appTypeId: 2
  }, {
    id: 5,
    name: 'Payment Recovery',
    slug: 'payment-recovery',
    role: 'creator',
    enabled: true,
    default: false,
    icon: 'calendar',
    appTypeId: 2
  }, {
    id: 8,
    name: 'Stripe',
    slug: 'payment-stripe',
    role: 'creator',
    enabled: true,
    default: false,
    icon: 'money bill',
    appTypeId: 3
  }, {
    id: 9,
    name: 'Shipment Tracking',
    slug: 'shipment-tracking',
    description: 'Mark orders as shipped as well as upload tracking numbers and notify customers.',
    role: 'creator',
    enabled: true,
    default: false,
    icon: 'truck',
    appTypeId: 4
  }, {
    id: 16,
    name: 'Amplifier',
    slug: 'export-amplifier',
    description: 'Enjoy total control of your distribution by partnering with Amplifier. Integrate your suppliers, use our warehousing, have us ship your goods, and also rely on us to make products for you as well.',
    role: 'creator',
    enabled: true,
    default: false,
    icon: 'cloud download',
    appTypeId: 5
  }];
  _exports.default = _default;
});