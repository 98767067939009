define("manage/pods/companies/company/projects/project/segments/view/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    model(params) {
      let {
        project,
        segment
      } = this.modelFor('companies.company.projects.project.segments.view');
      return Ember.RSVP.hash({
        project: project,
        segment: segment,
        segment_actions: this.search(segment, params)
      });
    },

    search(project_segment, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let sort = controller.get('sortProperty');

      if (controller.get('sortDirection') == 'desc') {
        sort = `-${sort}`;
      }

      let filters = {
        project_segment_id: project_segment.get('id')
      };
      return this.get('store').query('project-segment-action', {
        filter: filters,
        page: {
          number: page,
          size: pageSize
        },
        sort: sort,
        include: 'segment'
      });
    }

  });

  _exports.default = _default;
});