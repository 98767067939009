define("manage/components/tag-input", ["exports", "ember-tag-input/components/tag-input"], function (_exports, _tagInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tagInput.default;
    }
  });
});