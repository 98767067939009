define("manage/pods/companies/company/projects/project/products/edit/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['type'],
    product: Ember.computed.readOnly('model.product'),
    project: Ember.computed.readOnly('model.project'),
    intl: Ember.inject.service(),
    productTasks: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    // Come back to this when everything else is complete
    submitted: false,
    show_extra: false,
    // Manually force show
    // Variations (move to sort-by template)
    variation_sort_property: 'details_named',
    variation_sort_direction: 'asc',
    variation_sorted_by: 'details_named',
    filtered_variations: Ember.computed.filter('product.variations.@each.{isDeleted,has_variants}', function (variation) {
      return variation.get('isDeleted') === false && variation.get('has_variants') === true;
    }),
    wave_selections: Ember.computed('project.features.[]', function () {
      let options = [Ember.Object.create({
        name: this.intl.t('models.product.attributes.wave_shipping.one'),
        value: 'one'
      }), Ember.Object.create({
        name: this.intl.t('models.product.attributes.wave_shipping.two'),
        value: 'two'
      })];
      let wave_shipping = this.get('project.features').findBy('name', 'wave-shipping');

      if (Ember.isPresent(wave_shipping) && wave_shipping.settings['wave_shipments'] === "three") {
        options.push(Ember.Object.create({
          name: this.intl.t('models.product.attributes.wave_shipping.three'),
          value: 'three'
        }));
      }

      return options;
    }),
    has_variation_external_ids: Ember.computed('product.variations.@each.external_id', function () {
      return this.get('product.variations').isAny('external_id');
    }),
    // Error checking for specific areas
    variations_invalid: Ember.computed('submitted', 'product.variations.@each.{isDeleted,isCpInvalid}', 'product.variants.@each.{isDeleted,isCpInvalid}', 'product.validations.attrs.variations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      return this.get('product.variations').filterBy('isDeleted', false).isAny('isCpInvalid') || this.get('product.variants').filterBy('isDeleted', false).isAny('isCpInvalid') || this.get('product.validations.attrs.variations.isInvalid');
    }),
    questions_invalid: Ember.computed('submitted', 'product.questions.@each.isCpInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      return this.get('product.questions').isAny('isCpInvalid');
    }),
    shipping_invalid: Ember.computed('submitted', 'product.shipping_product_based_zone_locations.@each.isCpInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      return this.get('product.shipping_product_based_zone_locations').isAny('isCpInvalid');
    }),
    // Sections to display or not
    show_variations: Ember.computed('product.is_shipping', function () {
      if (this.get('product.is_shipping') === true) {
        return false;
      }

      return true;
    }),
    show_questions: Ember.computed('product.is_shipping', function () {
      if (this.get('product.is_shipping') === true) {
        return false;
      }

      return true;
    }),
    show_shipping: Ember.computed('product.is_digital', function () {
      if (this.get('product.is_digital') === true) {
        return false;
      }

      return true;
    }),
    // Sub shipping menu
    show_shipping_configuration: Ember.computed('product.is_shipping', function () {
      if (this.get('product.is_shipping') === true) {
        return false;
      }

      return true;
    }),
    actions: {
      save() {
        if (this.get('productTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true); // If we have any missing skus, show generation modal

        let variations = this.get('product.variations').filterBy('isDeleted', false).filterBy('has_variants', true);

        if (variations.any(v => Ember.isBlank(v.sku))) {
          this.send('open_modal', 'companies/company/projects/project/products/generate-variation-skus-modal', {
            product: this.get('product'),
            missing_skus: true
          });
          return;
        }

        if (this.get('product.validations.isInvalid')) {
          return;
        }

        this.get('productTasks.save').perform(this.get('product')).then(() => this.transitionToRoute('companies.company.projects.project.products.index')).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: this.intl.t('models.product.errors.save.unknown')
          });
        }));
      },

      // Variants
      delete_variant(variant) {
        if (this.get('productTasks.deleteVariant.isRunning')) {
          return;
        }

        if (variant.get('isNew')) {
          this.get('productTasks.deleteVariant').perform(this.get('product'), variant).catch(e => {
            this.send('open_modal', 'error-modal', {
              description: e.message
            });
          });
        } else {
          this.send('open_modal', 'delete-confirmation-modal', {
            entity: variant,
            i18nKey: 'delete-product-variant',
            i18nParams: {
              name: variant.get('name')
            },
            approve: () => {
              return this.get('productTasks.deleteVariant').perform(this.get('product'), variant);
            }
          });
        }
      },

      order_variants(variants) {
        variants.forEach((variant, index) => variant.set('sort_order', index + 1));
      },

      // Variations
      sort_variations(name, direction) {
        if (this.get('variation_sort_property') !== name) {
          this.set('variation_sort_property', name);
          this.set('variation_sort_direction', 'asc');
        } else {
          this.set('variation_sort_property', name);
          this.set('variation_sort_direction', direction);
        }

        this.set('variation_sorted_by', [`${this.get('variation_sort_property')}:${this.get('variation_sort_direction')}`]);
      },

      update_variation_default(variation, is_default) {
        if (is_default === true) {
          const default_variations = this.get('product.variations').filterBy('isDeleted', false).filterBy('is_default', true);

          for (const default_variation of default_variations.toArray()) {
            default_variation.set('is_default', false);
          }
        }

        variation.set('is_default', is_default);
      },

      // Questions
      create_question(type) {
        if (this.get('productTasks.createQuestion.isRunning')) {
          return;
        }

        let project = this.get('project');
        let product = this.get('product');
        this.get('productTasks.createQuestion').perform(project, product, type, true).then(question => this.send('open_modal', 'companies/company/projects/project/questions/edit-product-question-modal', {
          question: question
        })).catch(() => alert(this.intl.t('models.question.errors.save.unknown')));
      },

      delete_question(question) {
        if (this.get('productTasks.deleteQuestion.isRunning')) {
          return;
        }

        if (question.get('isNew')) {
          this.get('productTasks.deleteQuestion').perform(question).catch(e => {
            this.send('open_modal', 'error-modal', {
              description: e.message
            });
          });
        } else {
          this.send('open_modal', 'delete-confirmation-modal', {
            entity: question,
            i18nKey: 'delete-product-question',
            i18nParams: {
              name: question.get('text'),
              nameIsBlank: Ember.isBlank(question.get('text'))
            },
            approve: () => {
              return this.get('productTasks.deleteQuestion').perform(question);
            }
          });
        }
      },

      toggle_shipping() {
        if (Ember.isPresent(this.get('product.shipping_type'))) {
          this.set('product.shipping_type', null);
        } else {
          this.set('product.shipping_type', 'product_price');
        }
      }

    }
  });

  _exports.default = _default;
});