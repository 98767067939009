define("manage/pods/admin/companies/company/projects/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let company = this.modelFor('admin.companies.company');
      return Ember.RSVP.hash({
        projects: this.store.query('project', {
          filter: {
            company: company.get('id')
          },
          page: {
            size: 100
          },
          include: 'company'
        }),
        categories: this.store.query('category', {
          page: {
            size: 100
          }
        }),
        company: company
      });
    },

    actions: {
      sessionChanged: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});