define("manage/pods/companies/company/projects/project/settings/payments/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),

    model() {
      let current_user = this.get('current_user');
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        projects: this.store.query('project', {
          filter: {
            company: project.get('company.id')
          },
          page: {
            size: 100
          }
        }),
        companies_user: this.store.query('companies-user', {
          filter: {
            user: current_user.get('id'),
            company: project.get('company.id')
          }
        }).then(result => result.objectAt(0)),
        apps: this.store.query('project-app', {
          filter: {
            project: project.get('id'),
            type_slug: 'payment-processors',
            installed: 'true,false'
          }
        }),
        payment_gateways: this.store.query('project-payment-gateway', {
          filter: {
            project: project.get('id')
          },
          include: ['app'].join(',')
        })
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('error', null);
      }
    }

  });

  _exports.default = _default;
});