define("manage/pods/companies/company/projects/project/settings/emails/create/route", ["exports", "manage/mixins/handle-transition", "ember-concurrency"], function (_exports, _handleTransition, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    controllerName: 'companies.company.projects.project.settings.emails.edit',
    templateName: 'companies.company.projects.project.settings.emails.edit',

    model() {
      let project = this.modelFor('companies.company.projects.project');
      let email = this.get('store').createRecord('project-email', {
        project: project,
        name: 'New Template'
      });
      return Ember.RSVP.hash({
        project: project,
        email: email
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        let email = this.controller.get('email');

        if (email.get('isDirty')) {
          this.handleTransition(transition, resolve => {
            this.send('open_modal', 'cancel-modal', {
              model: email,
              modelName: 'Email Template',
              encapsulatedTaskAction: (0, _emberConcurrency.task)(function* (email) {
                email = yield email;
                email.rollbackAttributes();
                email.rollbackRelationships();
              }),
              promiseResolve: resolve
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});