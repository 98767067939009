define("manage/mirage/fixtures/states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: "Alabama",
    abbr: "AL",
    countryId: 1
  }, {
    id: 2,
    name: "Alaska",
    abbr: "AK",
    countryId: 1
  }, {
    id: 4,
    name: "Arizona",
    abbr: "AZ",
    countryId: 1
  }, {
    id: 67,
    name: "Alberta",
    abbr: "AB",
    countryId: 2
  }, {
    id: 68,
    name: "British Columbia",
    abbr: "BC",
    countryId: 2
  }];
  _exports.default = _default;
});