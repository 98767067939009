define("manage/utils/computed-default-properties", ["exports", "shared/utils/computed-default-properties"], function (_exports, _computedDefaultProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _computedDefaultProperties.default;
    }
  });
});