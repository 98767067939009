define("manage/pods/export/download/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return {
        project_segment_id: params.project_segment_id,
        token: params.token
      };
    }

  });

  _exports.default = _default;
});