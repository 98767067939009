define("manage/pods/companies/company/projects/project/customers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'page_size', 'sort', 'sort_direction', 'search'],
    // Paging
    page: 1,
    page_size: 15,
    // Sorting
    sort: 'email',
    sort_direction: 'asc',
    project: Ember.computed.readOnly('model.project'),
    customers: Ember.computed.readOnly('model.customers'),
    resultsLoadSuccess: true,
    has_filters: Ember.computed('search', function () {
      return Ember.isPresent(this.get('search'));
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      }

    }
  });

  _exports.default = _default;
});