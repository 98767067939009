define("manage/pods/companies/company/projects/project/sources/caps/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    source: Ember.computed.readOnly('model.source'),
    product_extra_global: Ember.computed.readOnly('model.product_extra_global'),
    productTasks: Ember.inject.service(),
    submitted: false,
    actions: {
      save() {
        if (this.get('productTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);

        if (this.get('product_extra_global.validations.isInvalid')) {
          return;
        }

        let intl = this.get('intl');
        this.get('productTasks.save').perform(this.get('product_extra_global')).then(() => this.set('saved', true)).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t("route.companies.company.projects.project.sources.caps.error.no_save")
          });
        }));
      }

    }
  });

  _exports.default = _default;
});