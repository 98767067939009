define("manage/pods/companies/company/projects/project/orders/groups/components/column-group-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oRAfY3Ai",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui horizontal list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"group\",\"group_items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui mini image label\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"product\",\"image_src\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"product\",\"image_src\"]]],[10,\"alt\",\"project\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"product\",\"name\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,1,[\"product\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          PRODUCT-\"],[1,[23,1,[\"product\",\"id\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,1,[\"product_variation\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[11,\"data-tooltip\",[23,1,[\"product_variation\",\"details_named\"]]],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"medium\"],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"icon info letter\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"detail\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"remove icon\"],[8],[9],[0,\" \"],[1,[23,1,[\"quantity\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/groups/components/column-group-products/template.hbs"
    }
  });

  _exports.default = _default;
});