define("manage/pods/companies/company/projects/project/sources/configurations/edit/extras/route", ["exports", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model() {
      let project = this.modelFor('companies.company.projects.project');
      let configuration = this.modelFor('companies.company.projects.project.sources.configurations.edit');
      return Ember.RSVP.hash({
        project: project,
        all_products: this.store.query('product', {
          filter: {
            project: project.get('id'),
            // Include both extra bundles and custom bundles. Extra bundles are configurations that can be sold as extras
            // Custom bundles were created outside of configuration
            product_type: ['physical', 'digital', 'shipping', 'extra_bundles', 'custom_bundles', 'section']
          },
          page: {
            size: _environment.default['max-page-size']
          }
        }),
        configuration: this.store.findRecord('project-configuration', configuration.get('id'), {
          include: ['product-reward', 'product-extra'].join(',')
        })
      }).then(_ref => {
        let {
          project,
          all_products,
          configuration
        } = _ref;
        let model = {
          project,
          all_products,
          configuration,
          product_extra: null
        };

        if (Ember.isPresent(configuration.get('product_extra.id'))) {
          model.product_extra = this.store.findRecord('product', configuration.get('product_extra.id'), {
            include: ['mappings', 'mappings.child'].join(',')
          });
        }

        return Ember.RSVP.hash(model);
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        let record = this.modelFor(this.routeName);

        if (record && Ember.isPresent(record.configuration.get('product_extra.id'))) {
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(record.configuration.get('product_extra')).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: record.configuration.get('product_extra'),
                  modelName: "Extra",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});