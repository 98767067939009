define("manage/pods/companies/company/projects/project/sources/extras/route", ["exports", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model() {
      let project = this.modelFor('companies.company.projects.project');
      let source = this.modelFor('companies.company.projects.project.sources');
      return Ember.RSVP.hash({
        project: project.reload(),
        all_products: this.store.query('product', {
          filter: {
            project: project.get('id'),
            // Include both extra bundles and custom bundles. Extra bundles are configurations that can be sold as extras
            // Custom bundles were created outside of configuration
            product_type: ['physical', 'digital', 'shipping', 'extra_bundles', 'custom_bundles', 'section']
          },
          page: {
            size: _environment.default['max-page-size']
          }
        }),
        source: this.store.findRecord('project-source', source.get('id'), {
          include: ['product-extra-global'].join(',')
        })
      }).then(_ref => {
        let {
          project,
          all_products,
          source
        } = _ref;
        return Ember.RSVP.hash({
          project,
          source,
          all_products: all_products.toArray(),
          product_extra_global: this.store.findRecord('product', source.get('product_extra_global.id'), {
            include: ['mappings', 'mappings.child'].join(',')
          })
        });
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product_extra_global) {
          let product_extra_global = record.product_extra_global;
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(product_extra_global).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: product_extra_global,
                  modelName: "Extra",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});