define("manage/mirage/fixtures/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: "1",
    sort_order: 1,
    name: "United States",
    iso3: "USA",
    iso2: "US",
    currency_code: "USD",
    street_required: true,
    city_required: true,
    state_required: true,
    postal_code_required: true,
    postal_code_format: "^\\d{5}([ \\-]\\d{4})?$",
    stateIds: [1, 2, 4]
  }, {
    id: "2",
    sort_order: 2,
    name: "Canada",
    iso3: "CAN",
    iso2: "CA",
    currency_code: "CAD",
    street_required: true,
    city_required: true,
    state_required: true,
    postal_code_required: true,
    postal_code_format: "^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d$",
    stateIds: [67, 68]
  }, {
    id: "48",
    sort_order: 100,
    name: "China",
    iso3: "CHN",
    iso2: "CN",
    currency_code: "CNY",
    street_required: true,
    city_required: true,
    state_required: true,
    postal_code_required: true,
    postal_code_format: "^\\d{6}$"
  }];
  _exports.default = _default;
});