define("manage/pods/companies/company/projects/project/info/shipping/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('companies.company.projects.project');
      var include = [// Project items
      'sources', 'sources.product-extra-global', 'sources.product-extra-global.mappings', 'sources.product-extra-global.mappings.child', 'sources.product-extra-global.mappings.child.zones', 'sources.product-extra-global.mappings.child.zones.locations', 'configurations', 'configurations.source', 'configurations.product-reward', 'configurations.product-reward.zones', 'configurations.product-reward.zones.locations', 'configurations.product-extra', 'configurations.product-extra.mappings', 'configurations.product-extra.mappings.child', 'configurations.product-extra.mappings.child.zones', 'configurations.product-extra.mappings.child.zones.locations'].join(',');
      return this.store.findRecord('project', project.get('id'), {
        include: include
      });
    }

  });

  _exports.default = _default;
});