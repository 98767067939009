define("manage/pods/index/route", ["exports", "manage/mixins/restrict-by-session"], function (_exports, _restrictBySession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictBySession.default, {
    current_user: Ember.inject.service('user'),

    redirect(model, transition) {
      if (transition.targetName !== 'index') {
        return;
      }

      if (this.get('current_user.is_admin')) {
        this.replaceWith('admin.companies');
        return;
      }

      this.replaceWith('companies');
    },

    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('index', {
        into: 'shared/main'
      });
    }

  });

  _exports.default = _default;
});