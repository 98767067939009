define("manage/mixins/models/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    actual_created_at: Ember.computed('created_at', 'external_created_at', function () {
      if (Ember.isPresent(this.get('external_created_at'))) {
        return this.get('external_created_at');
      }

      return this.get('created_at');
    }),
    confirm_url: Ember.computed('config.urls.survey', 'project.slug', 'customer.email', 'authentication_token', 'id', 'project.settings.survey_revision', function () {
      var token = encodeURIComponent(this.get('authentication_token'));
      var email = encodeURIComponent(this.get('customer.email'));
      var domain = this.get('config.urls.survey');

      if (Ember.isPresent(this.get('project.settings.survey_revision'))) {
        let domainParts = domain.split('.');

        if (domainParts[2].indexOf('test') === -1) {
          domainParts[0] = `${domainParts[0]}-${this.get('project.settings.survey_revision')}`;
          domain = domainParts.join('.');
        }
      }

      return `${domain}/confirm/${this.get('project.slug')}?token=${token}&email=${email}&order_id=${this.get('id')}`;
    }),
    status_key: Ember.computed('furthest_step', 'invite_sent_on', 'is_canceled', 'is_refused', 'is_locked', 'is_completed', 'external_unconfirmed', 'dropped', function () {
      if (this.get('is_locked')) {
        if (this.get('is_canceled')) {
          return "canceled_previously_locked";
        }

        return "locked";
      }

      if (this.get('is_canceled')) {
        if (this.get('is_refused')) {
          return "refused";
        }

        return "canceled";
      }

      if (this.get('is_completed')) {
        return "completed";
      }

      if (this.get('invite_sent_on')) {
        if (this.get('furthest_step')) {
          return "in_progress";
        } else {
          return "invited";
        }
      }

      if (this.get('external_unconfirmed')) {
        return "unconfirmed";
      }

      if (this.get('dropped')) {
        return "dropped";
      }

      return "not_invited";
    }),
    status: Ember.computed('status_key', function () {
      let status_key = this.get('status_key');

      switch (status_key) {
        case 'canceled_previously_locked':
          return 'Canceled';

        case 'locked':
          return "Locked";

        case 'refused':
          return 'Refused';

        case 'canceled':
          return 'Canceled';

        case 'completed':
          return 'Completed';

        case 'in_progress':
          return 'In Progress';

        case 'invited':
          return 'Invited';

        case 'unconfirmed':
          return 'Unconfirmed';

        case 'dropped':
          return 'Dropped';

        default:
          // not_invited
          return 'Not Invited';
      }
    }),
    status_description: Ember.computed('status_key', function () {
      let status_key = this.get('status_key');

      switch (status_key) {
        case 'canceled_previously_locked':
          return 'Order was previously exported, and then after it was exported it was canceled. This order could very likely exist in an external system. Make sure to remove it from any other software you might have.';

        case 'locked':
          return "Order was completed and locked.";

        case 'refused':
          return 'This customer has explicitely refused their order. If they had already paid they willingly forfeited that amount.';

        case 'canceled':
          return 'Order was canceled directly in Manage on Crowd Ox.';

        case 'completed':
          return 'The order survey has been confirmed.';

        case 'in_progress':
          return 'The order survey has been started, but is not completed.';

        case 'invited':
          return 'The customer has been emailed to take the survey, but has not started.';

        case 'unconfirmed':
          return 'The order has been imported into Crowd Ox, but is waiting to be confirmed. Either payment is pending, or we are waiting for the external provider to lock the order.';

        case 'dropped':
          return "The order was dropped. Either payment didn't clear, or the external provider never provided final confirmation.";

        default:
          // not_invited
          return "The order is ready to email, but hasn't been invited yet. This could be because the configuration is still being setup, or the system is in the process of emailing orders.";
      }
    }),
    is_local_pickup: Ember.computed.notEmpty('local_pickup_address.id'),
    external_url: Ember.computed('external_id', 'source.external_id', 'source.{external_url,site}', function () {
      if (this.get('source.site') === 'kickstarter') {
        return `${this.get('source.external_url')}/backers/${this.get('external_id')}`;
      }

      if (this.get('source.site') === 'indiegogo') {
        return `${this.get('source.external_url')}/edit#/perks/form/${this.get('external_id')}`;
      }

      return null;
    }),

    // Unlocks the order.
    // The confirm_unlock flag indicates we want to unlock the order
    // even if the order has been exported previously.
    uncomplete() {
      let confirm_unlock = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return this.get('ajax').postModel(this, 'uncomplete', {
        data: {
          confirm_unlock: confirm_unlock
        }
      }).then(() => {
        return this.reload();
      });
    },

    resetWaveSelection() {
      return this.get('ajax').postModel(this, 'wave/reset');
    },

    lock(process) {
      return this.get('ajax').postModel(this, 'lock', {
        data: {
          process: process
        }
      }).then(() => {
        return this.get('store').findRecord('order', this.get('id'), {
          include: 'selections'
        });
      });
    },

    unlock() {
      let process = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let lock_level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'order_selections';

      if (process === 'all') {
        return this.unlockAll(lock_level);
      }

      return this.get('ajax').postModel(this, "unlock", {
        data: {
          process: process,
          lock_level: lock_level
        }
      }).then(() => {
        return this.get('store').findRecord('order', this.get('id'), {
          include: 'selections'
        });
      });
    },

    unlockAll() {
      let lock_level = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'order_selections';
      return this.get('ajax').postModel(this, "unlock", {
        data: {
          clear_all: true,
          process: '',
          lock_level: lock_level
        }
      }).then(() => {
        return this.get('store').findRecord('order', this.get('id'), {
          include: 'selections'
        });
      });
    }

  });

  _exports.default = _default;
});