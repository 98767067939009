define("manage/pods/companies/company/projects/project/digital/index/components/column-thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3FT0MZvw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"sized-image\",null,[[\"image_path\",\"title\",\"width\",\"height\"],[[24,[\"fulfillment\",\"product\",\"image_path\"]],[24,[\"fulfillment\",\"product\",\"name\"]],60,60]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/digital/index/components/column-thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});