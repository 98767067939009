define("manage/pods/companies/company/projects/project/products/components/edit-product-variant-modal/component", ["exports", "manage/mixins/ss-modal", "jquery"], function (_exports, _ssModal, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['edit-product-variant-modal', 'component'],
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    productTasks: Ember.inject.service(),
    closable: false,
    submitted: false,
    lastField: false,
    max_variations: 100,
    // We need to start higher, to not interfere with non modal tab indexes
    base_tabindex: 1000,
    project: null,
    product: null,
    variant: null,
    isCreated: false,

    init() {
      this._super(...arguments);

      if (!this.get('variant')) {
        this.set('isCreated', true);
        let sort_orders = this.get('product.variants').mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        let variant = this.get('store').createRecord('product-variant', {
          project: this.get('project'),
          product: this.get('product'),
          sort_order: sort_order
        });
        this.set('variant', variant);
      }

      let total = this.get('variant.isNew') ? 2 : 1;

      while (this.get('variant.values.length') < total) {
        this.send('add_variant_value');
      }
    },

    // We don't let them delete variations that are saved if new ones exist. Otherwise we get into a weird loop
    has_unsaved_new_variations: Ember.computed('variant.isNew', 'variant.values.@each.isNew', function () {
      if (this.get('variant.isNew')) {
        return false; // Everything is new if the variant is new. ignore
      }

      if (this.get('variant.values').isAny('isNew')) {
        return true; // If we have any new variations, then we need to save before deleting old ones
      }

      return false;
    }),
    total_product_variations: Ember.computed('product.variants.[]', 'product.variant_values.[]', function () {
      let lengths = this.get('product.variants').mapBy('values.length');
      return lengths.reduce((a, b) => a === 0 ? b : a * b, 0);
    }),
    default_value: Ember.computed('variant.values_sorted.@each.is_default', function () {
      return this.get('variant.values_sorted').findBy('is_default', true) || this.get('variant.values_sorted.firstObject');
    }),
    actions: {
      approve() {
        this.set('submitted', true);
        this.get('variant').validate().then(() => {
          if (this.get('variant.validations.isInvalid')) {
            return;
          }

          if (this.get('total_product_variations') > this.get('max_variations')) {
            return;
          }

          if (this.get('variant.isNew')) {
            // Set a fake flag to know if they open back up its been created previously
            this.set('variant.created', true);
          }

          this.get('variant.values').forEach(value => {
            if (value.get('isNew')) {
              value.set('created', true);
            }
          });
          this.get('productTasks.processVariations').perform(this.get('product')).then(() => this.closeModal()).catch(() => alert(this.intl.t('models.product_variation.errors.save.unknown')));
        });
      },

      close() {
        if (!this.get('variant.isNew') || this.get('variant.isNew') && !this.get('variant.created')) {
          this.get('variant').rollbackAttributes();
          this.get('variant.values').toArray().forEach(value => value.rollbackAttributes());
        }

        this.closeModal();
      },

      add_variant_value() {
        let sort_orders = this.get('variant.values').mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        this.get('store').createRecord('product-variant-value', {
          project: this.get('project'),
          product: this.get('product'),
          variant: this.get('variant'),
          sort_order: sort_order,
          is_default: false // Temporary property used on variation processing

        });
      },

      order_variant_values(variant_values) {
        variant_values.forEach((variant_value, index) => variant_value.set('sort_order', index + 1));
      },

      delete_variant_value(variant_value) {
        if (this.get('productTasks.deleteVariantValue.isRunning')) {
          return;
        } // If its new, don't pop open a modal so we don't skip anything


        if (variant_value.get('isNew')) {
          this.get('productTasks.deleteVariantValue').perform(this.get('product'), variant_value).catch(e => {
            this.send('open_modal', 'error-modal', {
              description: e.message
            });
          });
        } else {
          this.send('open_modal', 'delete-confirmation-modal', {
            entity: variant_value,
            i18nKey: 'delete-product-variant-value',
            i18nParams: {
              name: variant_value.get('name')
            },
            approve: () => {
              return this.get('productTasks.deleteVariantValue').perform(this.get('product'), variant_value);
            }
          });
        }
      },

      update_variant_value_default(variant_value, is_default) {
        if (is_default === true) {
          const default_variant_values = this.get('variant.values').filterBy('isDeleted', false).filterBy('is_default', true);

          for (const default_variant_value of default_variant_values.toArray()) {
            default_variant_value.set('is_default', false);
          }
        }

        variant_value.set('is_default', is_default);
      },

      // Last input checks
      enterPressed(index) {
        if (index + 1 === this.get('variant.values.length')) {
          this.send('add_variant_value');
          Ember.run.schedule('afterRender', this, function () {
            (0, _jquery.default)('input', this.element).last().focus();
          });
        } else {
          (0, _jquery.default)(`input[tabindex=${this.get('base_tabindex') + index + 1}]`, this.element).focus();
        }
      },

      focusIn(index) {
        if (index + 1 === this.get('variant.values.length')) {
          this.set('lastField', true);
        }
      },

      focusOut() {
        this.set('lastField', false);
      }

    }
  });

  _exports.default = _default;
});