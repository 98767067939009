define("manage/pods/admin/companies/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      search: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    model(params) {
      return this.search(params);
    },

    search(params) {
      var controller = this.controllerFor(this.routeName);
      var status = params.status;
      var search = params.search;
      var sortProperty = params.sort;
      var sortDirection = params.sortDirection;
      var page = params.page;
      var pageSize = controller.get('page_size');
      var sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      return this.store.query('company', {
        include: ['projects', 'projects.stats', 'users'].join(','),
        filter: {
          status: status,
          search: search
        },
        sort: sort,
        page: {
          number: page,
          size: pageSize
        }
      });
    }

  });

  _exports.default = _default;
});