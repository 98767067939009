define("manage/components/file-upload/component", ["exports", "shared/utils/logger", "ember-ajax/errors", "ember-concurrency", "jquery"], function (_exports, _logger, _errors, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: "form",
    accept_charset: "UTF-8",
    enctype: "multipart/form-data",
    method: "post",
    action_type: "upload",
    drop_enabled: true,
    drag_in_element: false,
    upload_type: null,
    // passed in
    type_match: null,
    // computed from upload_type
    classNameBindings: ['drag_in_element:entered', 'uploading:uploading'],
    attributeBindings: ['url:action', 'accept_charset:accept-charset', 'enctype', 'method'],
    UPLOAD_TYPE_TO_MATCH: {
      image: /image.*/
    },

    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('url')) && this.get('action_type') === "upload") {
        (0, _logger.logger_warn)("missing required url parameter for file upload component");
      }

      let match = this.UPLOAD_TYPE_TO_MATCH[this.get('upload_type')];

      if (match) {
        this.set('type_match', match);
      }
    },

    actions: {
      upload() {
        if (this.get('uploading')) {
          return;
        }

        (0, _jquery.default)("[name=file]", this.element).click();
      }

    },

    didInsertElement() {
      (0, _jquery.default)("[name=file]", this.element).on(`change.${this.get('elementId')}`, Ember.run.bind(this, this._submit));

      if (this.get("drop_enabled")) {
        (0, _jquery.default)(this.element).on(`dragenter.${this.get('elementId')}`, Ember.run.bind(this, this._dragenter));
        (0, _jquery.default)(this.element).on(`dragover.${this.get('elementId')}`, Ember.run.bind(this, this._dragenter));
        (0, _jquery.default)(this.element).on(`drop.${this.get('elementId')}`, Ember.run.bind(this, this._drop));
        (0, _jquery.default)(this.element).on(`dragexit.${this.get('elementId')}`, Ember.run.bind(this, this._outside));
        (0, _jquery.default)(this.element).on(`dragleave.${this.get('elementId')}`, Ember.run.bind(this, this._outside));
        (0, _jquery.default)(document).on(`dragend.${this.get('elementId')}`, Ember.run.bind(this, this._outside));
        (0, _jquery.default)(document).on(`drop.${this.get('elementId')}`, Ember.run.bind(this, this._outside));
      }
    },

    willDestroyElement() {
      (0, _jquery.default)("[name=file]", this.element).off(`.${this.get('elementId')}`);
      (0, _jquery.default)(this.element).off(`.${this.get('elementId')}`);
      (0, _jquery.default)(document).off(`.${this.get('elementId')}`);
    },

    _submitSuccess(data) {
      (0, _jquery.default)("[name=file]", this.element).val("");

      this._onSuccess(data);
    },

    _submitError(XHR) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (XHR instanceof _errors.InvalidError) {
        // The error is ember ajax. This is a hack to ensure all the events that look for response text still work for now.
        // TODO: Come back and update these actions to look for ember ajax error
        if (Ember.isPresent(XHR.errors) && Ember.isArray(XHR.errors)) {
          XHR.responseText = XHR.errors[0].detail || XHR.errors[0].title;
        } else if (Ember.isPresent(XHR.payload)) {
          if (Ember.isArray(XHR.payload)) {
            XHR.responseText = XHR.payload[0];
          } else {
            XHR.responseText = XHR.payload;
          }
        }
      }

      (0, _jquery.default)("[name=file]", this.element).val("");

      this._onError(XHR);
    },

    _ignoreEvent(event) {
      event.stopPropagation();
      event.preventDefault();
    },

    // Drag helper
    _outside(event) {
      this._ignoreEvent(event);

      this.set('drag_in_element', false);
    },

    // Drag helper
    _dragenter(event) {
      this._ignoreEvent(event);

      this.set('drag_in_element', true);
    },

    // Submit from the input
    _submit() {
      if (this.get('uploading')) {
        return;
      }

      let input = (0, _jquery.default)("[name=file]", this.element).get(0);
      let files = input.files;

      this._process(files);
    },

    // Drop an image on the space
    _drop(event) {
      if (this.get('uploading')) {
        return;
      }

      this._ignoreEvent(event);

      this.set('drag_in_element', false);
      let files = (event.originalEvent || event).dataTransfer.files;

      this._process(files);
    },

    // Process the file
    _process(files) {
      if (!files.length) {
        return;
      }

      let file = files[0];

      if (file.size <= 0) {
        this.displayError('Unable to upload an empty file');
        return;
      }

      if (this.get('type_match') && !file.type.match(this.get('type_match')) && file.type != "") {
        this.displayError(this.get('intl').t(`component.file-upload.required-type.${this.get('upload_type')}`));
        return;
      }

      (0, _jquery.default)("[name=file]", this.element).val("");
      let action_type = this.get('action_type');

      if (action_type === "upload") {
        this.get('uploadTask').perform(file);
      } else if (action_type === "action") {
        this.get('uploaded')(file);
      }
    },

    displayError(error) {
      alert(error);
    },

    uploading: Ember.computed.readOnly('uploadTask.isRunning'),
    uploadTask: (0, _emberConcurrency.task)(function* (file) {
      this._onStart();

      let reader = new FileReader();
      let loaded = false;

      reader.onload = function () {
        loaded = true;
      }; // Other callbacks to look at in the future for errors
      // reader.onabort = () => {};
      // reader.onerror = () => {};
      // reader.onloadstart = () => {};
      // reader.onloadend = () => {};
      // reader.onprogress = () => {};


      reader.readAsArrayBuffer(file);

      while (loaded === false) {
        yield (0, _emberConcurrency.timeout)(250);

        if (reader.readyState === 2) {
          loaded = true;
        }
      }

      yield this.get('processFileTask').perform(file);
    }).drop(),
    processFileTask: (0, _emberConcurrency.task)(function* (file) {
      try {
        let form_data = new FormData((0, _jquery.default)(this.element)[0]); // Safari hack, if adding a key that already exists, it dosen't remove it, it adds twice then dies

        form_data.delete('file');
        form_data.append('file', file);
        let data = yield this.get('ajax').post(this.get('url'), {
          processData: false,
          contentType: false,
          cache: false,
          data: form_data
        });

        this._submitSuccess(data);
      } catch (e) {
        this._submitError(e);
      }
    }),

    // Internal callback events
    _onStart() {
      if (typeof this.get('start') === 'function') {
        this.get('start')();
      }
    },

    _onSuccess(data) {
      if (typeof this.get('success') === 'function') {
        this.get('success')(data);
      }
    },

    _onError(XHR, text, error) {
      if (typeof this.get('error') === 'function') {
        this.get('error')(XHR, text, error);
      }
    }

  });

  _exports.default = _default;
});