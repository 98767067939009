define("manage/helpers/render-linebreaks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.renderLinebreaks = renderLinebreaks;

  function renderLinebreaks(_ref) {
    let [value] = _ref;
    value = Ember.Handlebars.Utils.escapeExpression(value);
    value = value.replace(/(\r\n|\n|\r)/gm, '<br>');
    return Ember.String.htmlSafe(value);
  }

  var _default = Ember.Helper.helper(renderLinebreaks);

  _exports.default = _default;
});