define("manage/pods/companies/company/projects/project/sources/upgrades/route", ["exports", "ember-concurrency", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _emberConcurrency, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      let source = this.modelFor('companies.company.projects.project.sources');
      return Ember.RSVP.hash({
        project: project,
        source: source,
        configurations: this.store.query('project-configuration', {
          filter: {
            source: source.get('id')
          },
          include: ['product-reward', 'source'].join(','),
          page: {
            size: _environment.default['max-page-size']
          }
        })
      });
    },

    anyConfigurationisDirty: (0, _emberConcurrency.task)(function* (configurations) {
      configurations = yield configurations;

      for (let configuration of configurations.toArray()) {
        if (configuration.get('hasDirtyAttributes')) {
          return true;
        }
      }

      return false;
    }),
    rollbackConfigurations: (0, _emberConcurrency.task)(function* (configurations) {
      configurations = yield configurations;

      for (let configuration of configurations.toArray()) {
        if (configuration.get('hasDirtyAttributes')) {
          return configuration.rollbackAttributes();
        }
      }
    }),

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('selected', null);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.configurations) {
          let configurations = record.configurations;
          this.handleTransition(transition, resolve => {
            this.get('anyConfigurationisDirty').perform(configurations).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: configurations,
                  modelName: "Configuration",
                  serviceTaskAction: this.get('rollbackConfigurations'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});