define("manage/mixins/models/customer", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Validations are here in stead of on shared, because it should be a manage only concern
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    full_name: {
      description: "Full Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    ajax: Ember.inject.service(),

    merge(email) {
      return this.get('ajax').postModel(this, 'merge', {
        data: {
          'email': email,
          'id': this.get('id')
        }
      });
    }

  });

  _exports.default = _default;
});