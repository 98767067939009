define("manage/models/project-settings-wizard", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    last_completed_step: {
      description: "Last Completed Step",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        allowBlank: true,
        in: ['authorization', 'settings']
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    completed_on: (0, _model.attr)('date'),
    last_completed_step: (0, _model.attr)('string'),
    skip_questions: (0, _model.attr)('boolean'),
    skip_theme: (0, _model.attr)('boolean'),
    reviewed_email: (0, _model.attr)('boolean'),
    skip_local_pickup: (0, _model.attr)('boolean'),
    reviewed_shipping: (0, _model.attr)('boolean'),
    skip_preorder: (0, _model.attr)('boolean'),
    reviewed_project_settings: (0, _model.attr)('boolean'),

    step_to_number(step) {
      if (step === 'authorization' || Ember.isBlank(step)) {
        return 0;
      }

      if (step === 'location') {
        return 1;
      }

      if (step === 'settings') {
        return 2;
      }

      return -1; // unknown take lowest precedence
    },

    advance_step(step) {
      var step_number = this.step_to_number(step);
      var last_completed_step_number = this.step_to_number(this.get('last_completed_step'));

      if (step_number >= last_completed_step_number) {
        this.set('last_completed_step', step);
      }

      if (step === 'settings' && Ember.isBlank(this.get('completed_on'))) {
        this.set('completed_on', new Date());
      }
    }

  });

  _exports.default = _default;
});