define("manage/mirage/factories/order", ["exports", "shared/mirage/factories/order", "ember-cli-mirage"], function (_exports, _order, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _order.default.extend({
    withCustomer: (0, _emberCliMirage.trait)({
      afterCreate(order, server) {
        let customer = server.create('customer', {
          projectId: order.projectId,
          orderIds: [order.id]
        });
        order.update({
          customerId: customer.id
        });
      }

    })
  });

  _exports.default = _default;
});