define("manage/pods/companies/company/projects/project/sources/configurations/components/edit-source-name-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xHalispC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Edit Source Name\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui basic content \",[28,\"if\",[[24,[\"loading\"]],\"loading\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n    \"],[1,[28,\"edit-field\",null,[[\"model\",\"propertyName\",\"fieldType\",\"submitted\"],[[24,[\"source\"]],\"name\",\"text\",[24,[\"submitted\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",\"ui floated left button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",[29,[\"ui green button \",[28,\"if\",[[24,[\"isProcessing\"]],\"loading\"],null]]]],[3,\"action\",[[23,0,[]],\"save\"]],[8],[1,[28,\"t\",[\"phrase.save\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/configurations/components/edit-source-name-modal/template.hbs"
    }
  });

  _exports.default = _default;
});