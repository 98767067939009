define("manage/pods/admin/companies/company/projects/index/controller", ["exports", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    projects: Ember.computed.readOnly('model.projects'),
    categories: Ember.computed.readOnly('model.categories'),
    company: Ember.computed.readOnly('model.company'),
    parent_categories: Ember.computed('categories.@each.parent_category', function () {
      return this.get('categories').filter(category => category.get('parent_category.id') == null);
    }),
    deleteProjectTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;

      try {
        yield project.destroyRecord();
      } catch (e) {
        project.rollbackAttributes();
        throw e;
      }

      this.transitionToRoute('admin.companies.company');
    }),
    saveProjectTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;

      try {
        yield project.save();
      } catch (e) {
        // Unwrap the exception so it displays using catch real
        if (e.errors) {
          let errors = e.errors.map(function (item) {
            return item.detail || item.title;
          });
          throw errors;
        }

        throw e;
      }
    }),
    actions: {
      delete(project) {
        if (this.get('deleteProjectTask.isRunning')) {
          return;
        }

        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-project',
          i18nParams: {
            project_name: project.name
          },
          approve: () => {
            return this.get('deleteProjectTask').perform(project);
          }
        });
      },

      refresh() {
        this.send('sessionChanged');
      },

      rename_project(project) {
        if (this.get('saveProjectTask.isRunning')) {
          return;
        }

        this.get('saveProjectTask').perform(project).then(() => project.set('isRenaming', false)).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to save project name"
          });
        }));
      }

    }
  });

  _exports.default = _default;
});