define("manage/pods/companies/company/projects/project/sources/extras/components/section-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uMR+TwjI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t-component\",[[23,0,[]],\"header\"],[[\"isNew\"],[[24,[\"productIsNew\"]]]]],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui form content\"],[8],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"product\"]],\"name\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[28,\"t-component\",[[23,0,[]],\"field.name\"],null],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"product\",\"name\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"product\"]],\"description\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[28,\"t-component\",[[23,0,[]],\"field.description\"],null],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"product\",\"description\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui button positive \",[28,\"if\",[[24,[\"product\",\"validations\",\"isInvalid\"]],\"disabled\"],null],\"\\n    \",[28,\"if\",[[24,[\"productTasks\",\"save\",\"isRunning\"]],\"loading\"],null]]]],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"action.save\"],[[\"isNew\"],[[24,[\"productIsNew\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/extras/components/section-modal/template.hbs"
    }
  });

  _exports.default = _default;
});