define("manage/pods/companies/company/projects/project/settings/emails/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    project: Ember.computed.alias('model.project'),
    emails: Ember.computed.alias('model.emails'),
    emails_not_deleted: Ember.computed.filterBy('emails', 'isDeleted', false),
    queryParams: ['page', 'page_size', 'sort', 'sort_direction', 'search', 'app'],
    // Paging
    page: 1,
    page_size: 10,
    // Sorting
    sort: 'name',
    sort_direction: 'asc',
    // Filters
    search: '',
    app: null,
    app_options: Ember.computed('model.apps', function () {
      var all = Ember.A();
      all.pushObjects(this.get('model.apps').map(app => {
        return Ember.Object.create({
          id: app.get('id'),
          title: app.get('name')
        });
      }));
      all.pushObject(Ember.Object.create({
        id: 'user',
        title: 'Custom'
      }));
      all.pushObject(Ember.Object.create({
        id: 'archived',
        title: 'Archived'
      }));
      return all;
    }),
    isRunning: Ember.computed.alias('archiveTask.isRunning'),
    archiveTask: (0, _emberConcurrency.task)(function* (email) {
      email = yield email;
      email.set('archived_at', new Date());
      yield email.save();
      let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

      if (currentRoute) {
        yield currentRoute.refresh();
      }
    }),
    unarchiveTask: (0, _emberConcurrency.task)(function* (email) {
      email = yield email;
      email.set('archived_at', null);
      yield email.save();
      let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

      if (currentRoute) {
        yield currentRoute.refresh();
      }
    }),
    actions: {
      reset_page() {
        this.set('page', 1);
      },

      view_email(email) {
        return this.transitionToRoute('companies.company.projects.project.settings.emails.edit', email.get('id'));
      },

      archive_email(email) {
        if (this.get('archiveTask.isRunning')) {
          return;
        }

        this.get('archiveTask').perform(email);
      },

      unarchive_email(email) {
        if (this.get('unarchiveTask.isRunning')) {
          return;
        }

        this.get('unarchiveTask').perform(email);
      }

    }
  });

  _exports.default = _default;
});