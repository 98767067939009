define("manage/pods/companies/company/projects/project/segments/components/segment-downloads/component", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api_url: _environment.default.urls.api,
    session: Ember.inject.service(),
    // Passed in
    segment: null,
    // Computed
    segment_actions_with_downloads: Ember.computed.filter('segment.actions.@each.file', function (action) {
      if (action.get('file')) {
        return true;
      }

      return false;
    }),
    has_segment_downloads: Ember.computed.gt('segment_actions_with_downloads.length', 0)
  });

  _exports.default = _default;
});