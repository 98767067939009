define("manage/pods/companies/company/account/components/disconnect-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    disconnectTask: (0, _emberConcurrency.task)(function* () {
      let company = yield this.get('company');
      let type = this.get('type');

      try {
        yield this.get('ajax').postModel(company, `disconnect/${type}`);
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: `Unable to disconnect ${type} from company.`
        });
        return;
      }

      yield company.reload();
      this.closeModal();
    }).drop()
  });

  _exports.default = _default;
});