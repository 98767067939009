define("manage/models/product-detail", ["exports", "ember-data-model-fragments/fragment", "shared/utils/logger", "@ember-data/model"], function (_exports, _fragment, _logger, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const loadRelationship = function (relationship) {
    let id = `${relationship}_id`;
    let reference = `_${relationship}`;
    return Ember.computed(id, reference, relationship, function () {
      if (Ember.isPresent(this.get(id))) {
        // Hack, if we don't peekRecord first, the reference sometimes doesn't load
        let modelNamePart = Ember.String.dasherize(relationship);
        let peekModel = this.store.peekRecord('product-' + modelNamePart, this.get(id));

        if (peekModel != null) {
          if (peekModel.get('isLoaded')) {
            return peekModel;
          }
        } // Model either doesn't exist, or not loaded


        let storeReference = this.store.getReference(`product-${modelNamePart}`, this.get(id));
        let referenceModel = storeReference.value();

        if (referenceModel == null) {
          storeReference.load().then(() => this.notifyPropertyChange(relationship)).catch(() => (0, _logger.logger_warn)(`Unable to find id:${id} for product-${modelNamePart}`));
        } else {
          if (referenceModel.get('isLoaded')) {
            return referenceModel;
          } else {
            referenceModel.one('didLoad', this, () => this.notifyPropertyChange(relationship));
          }
        }

        return null;
      }

      return this.get(reference);
    });
  };

  var _default = _fragment.default.extend({
    variant_id: (0, _model.attr)('number'),
    variant_value_id: (0, _model.attr)('number'),
    // Internal references incase we don't have an id yet (new records)
    // Manage only see product-tasks
    _variant: null,
    _variant_value: null,
    // Computed
    variant_sort_order: Ember.computed.readOnly('variant.sort_order'),
    // Fake relationship properties
    variant: loadRelationship('variant'),
    variant_value: loadRelationship('variant_value') // Don't dasherize, it will do it above where needed, has to match property name

  });

  _exports.default = _default;
});