define("manage/pods/companies/company/projects/project/products/index/components/column-fulfillment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jRQpq8QU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"product\",\"is_bundle\"]],[24,[\"product\",\"imported_by\"]]],null]],null,{\"statements\":[[0,\"  Imported Addon\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"product\",\"reviewed\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"needs-review\"],[8],[0,\"Needs to be Reviewed\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"t\",[[28,\"concat\",[\"models.product.attributes.product_type.\",[24,[\"product\",\"product_type\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/index/components/column-fulfillment/template.hbs"
    }
  });

  _exports.default = _default;
});