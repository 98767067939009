define("manage/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MY6YlbMM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"name\",\"value\"],[\"hidden\",\"utf8\",\"✓\"]]],false],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"class\",\"name\"],[\"file\",\"hidden\",\"file\"]]],false],[0,\"\\n\"],[14,1,[[24,[\"uploading\"]],[28,\"action\",[[23,0,[]],\"upload\"],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});