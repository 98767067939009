define("manage/mixins/models/project/source", ["exports", "manage/mixins/project-source-object", "ember-cp-validations", "shared/utils/fallback-sort", "ember-data-model-fragments/attributes", "manage/config/environment", "manage/utils/monetize", "manage/utils/dates", "ember-inflector"], function (_exports, _projectSourceObject, _emberCpValidations, _fallbackSort, _attributes, _environment, _monetize, _dates, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    site: {
      description: "Site",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['kickstarter', 'indiegogo', 'celery', 'other', 'crowdox', 'bgg']
      })]
    },
    status: {
      description: "Status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['setup', 'live', 'complete']
      })]
    },
    external_id: {
      description: "External Id",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 200
      })]
    },
    url: {
      description: "URL",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 200
      })]
    },
    image_url: {
      description: "Image URL",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 510
      })]
    },
    start_date: {
      description: "Start Date",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    end_date: {
      description: "End Date",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    location: {
      description: "Location",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 100
      })]
    },
    category: {
      description: "Category",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 100
      })]
    },
    order_count: {
      description: "Order Count",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gte: 0
      })]
    },
    total_ordered: {
      description: "Total Ordered",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gte: 0
      })]
    },
    settings: {
      description: "Settings",
      validators: [(0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = Ember.Mixin.create(Validations, _projectSourceObject.default, {
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: (0, _attributes.fragment)('project-source-settings', {
      defaultValue: {}
    }),
    currency: Ember.computed.readOnly('project.currency'),
    total_ordered: (0, _monetize.default)('total_ordered_cents', 'currency'),
    // Status tracking
    in_setup: Ember.computed.equal('status', 'setup'),
    is_live: Ember.computed.equal('status', 'live'),
    is_complete: Ember.computed.equal('status', 'complete'),

    get is_ended() {
      const endDate = this.get('end_date');
      const oneHourFromNow = new Date();
      oneHourFromNow.setHours(oneHourFromNow.getHours() + 1); // this matches the validation check in `OrderSync::External`

      return endDate && endDate <= oneHourFromNow;
    },

    external_url: Ember.computed('site', 'url', 'project.slug', function () {
      var url;

      if (this.get('site') === 'crowdox') {
        url = `${_environment.default.urls.preorder}/projects/${this.get('project.slug')}`;
      } else if (this.get('site') === 'bgg') {
        url = `${_environment.default.urls.bgg}/projects/${this.get('project.slug')}`;
      } else {
        url = this.get('url');
      }

      return url;
    }),
    external_url_preview: Ember.computed('external_url', 'status', 'token', function () {
      let url = this.get('external_url');

      if (['setup', 'complete'].includes(this.get('status'))) {
        return `${url}?token=${this.get('token')}`;
      }

      return url;
    }),
    friendly_status_description: Ember.computed('site', 'in_setup', 'is_internal', 'is_live', 'is_complete', function () {
      let ordersName = (0, _emberInflector.pluralize)(this.get('intl').t(`source.${this.get('site')}.order`));

      if (this.get('is_internal')) {
        if (this.get('in_setup')) {
          return `No ${ordersName} are being accepted.`;
        } else if (this.get('is_live')) {
          return `${ordersName} are being accepted.`;
        } else if (this.get('is_complete')) {
          return `${ordersName} are closed.`;
        }
      } else if (this.get('in_setup')) {
        return `Survey is in Setup`;
      }

      return "";
    }),
    configurations_sorted: (0, _fallbackSort.default)('configurations', 'product_reward_price_cents', 'id'),
    configurations_in_setup: Ember.computed('configurations.@each.in_setup', function () {
      if (this.get('configurations').isEvery('in_setup', true)) {
        return true;
      }

      return false;
    }),
    any_configuration_in_setup: Ember.computed('configurations.@each.in_setup', function () {
      if (this.get('configurations').isAny('in_setup', true)) {
        return true;
      }

      return false;
    }),
    any_configuration_in_setup_or_live: Ember.computed('configurations.@each.{in_setup,is_live}', function () {
      if (this.get('configurations').isAny('in_setup', true) || this.get('configurations').isAny('is_live', true)) {
        return true;
      }

      return false;
    }),
    can_authorize: Ember.computed('is_kickstarter', 'is_indiegogo', 'start_date', function () {
      if (this.get('is_indiegogo')) {
        return true;
      }

      if (this.get('is_kickstarter')) {
        // If they are after the new terms, they can invite
        // Months start at 0, so July is 6. New terms started June 16 2016
        let minimum_date = new Date(2016, 6, 1);

        if (Ember.isPresent(this.get('start_date')) && this.get('start_date') > minimum_date) {
          return true;
        }
      }

      return false;
    }),
    authorized_recently: Ember.computed('is_kickstarter', 'settings.wizard.access_requested_on', function () {
      if (this.get('is_kickstarter') && Ember.isPresent(this.get('settings.wizard.access_requested_on'))) {
        let access_requested_on = new Date(this.get('settings.wizard.access_requested_on'));
        let yesterday = (0, _dates.addDays)(new Date(), -1);
        return access_requested_on > yesterday;
      }

      return false;
    }),
    pending_authorization: Ember.computed('can_authorize', 'authorized', 'authorized_recently', function () {
      if (this.get('can_authorize')) {
        if (this.get('authorized') === false && this.get('authorized_recently') === true) {
          return true;
        }
      }

      return false;
    }),
    // Authorization notification
    needs_to_authorize: Ember.computed('can_authorize', 'authorized', 'pending_authorization', function () {
      if (this.get('can_authorize')) {
        if (this.get('authorized') === true || this.get('pending_authorization') === true) {
          return false;
        }

        return this.get('authorized') !== true;
      }

      return false;
    }),
    is_import_from_api: Ember.computed.equal('import_type', 'api'),
    is_import_from_file: Ember.computed.equal('import_type', 'file'),
    is_fig: Ember.computed.equal('site', 'fig'),

    authorize() {
      return this.get('ajax').postModel(this, 'authorize');
    },

    transaction_fee_as_percent: Ember.computed('intl.locale', 'transaction_fee', function () {
      return this.get('intl').formatNumber(parseInt(this.get('transaction_fee'), 10) / 1000, {
        style: 'percent',
        minimumFractionDigits: 2
      });
    }),
    is_external_sync: Ember.computed.or('is_kickstarter', 'is_indiegogo'),
    can_external_sync: Ember.computed('is_external_sync', 'is_ended', function () {
      return this.get('is_external_sync') && this.get('is_ended');
    }),
    // Do we pull the project information from the source
    is_external_import: Ember.computed.or('is_kickstarter', 'is_indiegogo', 'is_fig'),

    sync(syncType) {
      return this.get('ajax').postModel(this, 'sync', {
        data: {
          sync_type: syncType
        }
      });
    },

    review() {
      return this.get('ajax').requestModel(this, 'review');
    }

  });

  _exports.default = _default;
});