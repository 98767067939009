define("manage/pods/companies/company/projects/project/stats/index/components/stat-configurations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FEVEm/iy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ss-dimmer\",null,[[\"class\",\"isActive\"],[\"inverted\",[24,[\"loadTask\",\"isRunning\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui small text loader\"],[8],[1,[28,\"t\",[\"phrase.loading\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1,[[28,\"hash\",null,[[\"order_configurations_largest\",\"order_configurations_chart_data\",\"empty\"],[[24,[\"order_configurations_largest\"]],[24,[\"order_configurations_chart_data\"]],[24,[\"empty\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/stats/index/components/stat-configurations/template.hbs"
    }
  });

  _exports.default = _default;
});