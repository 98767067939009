define("manage/components/edit-custom-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onValueChanged(newValue) {
        let model = this.get('entity.content') || this.get('entity');

        if (model != null) {
          // We have to replace the entire custom object so that ember data properly tracks it.
          // If we don't, it won't mark as dirty properly
          model.set('custom', Object.assign({}, model.get('custom'), {
            [this.custom_field.public_id]: newValue
          }));
        }
      }

    }
  });

  _exports.default = _default;
});