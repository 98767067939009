define("manage/pods/companies/company/projects/project/orders/components/sync-source-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    syncType: "project",
    actions: {
      sync() {
        if (this.get('isProcessing')) {
          return;
        }

        this.set('isProcessing', true);
        return this.get('source').sync(this.get('syncType')).then(result => {
          this.set('jobId', result.id || result.data.id);
        });
      }

    }
  });

  _exports.default = _default;
});