define("manage/pods/companies/company/projects/project/orders/exports/controller", ["exports", "manage/config/environment", "manage/mixins/order-exports"], function (_exports, _environment, _orderExports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_orderExports.default, {
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    segments: Ember.computed.readOnly('model.segments'),
    countries: Ember.computed.readOnly('model.countries'),
    api_url: _environment.default.urls.api,
    queryParams: ['page', 'page_size', 'search', 'behavior', 'hidden', 'closed'],
    // Paging
    page: 1,
    page_size: 5,
    // Filters
    search: '',
    behavior: 'fixed',
    hidden: 'false',
    closed: 'true',
    actions: {
      refresh_project_segment() {},

      reset_page() {
        this.set('page', 1);
      },

      view_segment(segment) {
        return this.transitionToRoute('companies.company.projects.project.segments.view', segment.get('id'));
      },

      segment_created() {
        // Reset all filters so this can be seen
        this.set('hidden', 'false');
        this.set('behavior', 'all');
        this.set('closed', 'all'); // Refresh the route

        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});