define("manage/helpers/has-installed-app-ever", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasInstalledAppEver = hasInstalledAppEver;

  function hasInstalledAppEver(project, slugName) {
    if (!project || !project.get('apps')) {
      (0, _logger.logger_warn)(`Invalid project object provided`);
      return false;
    }

    return project.get('apps').toArray().some(app => app.get('slug') == slugName && app.get('installed_at') != null);
  }

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [project, slugName] = _ref;
      this.set('project', project);
      return hasInstalledAppEver(project, slugName);
    },

    appsDidChange: Ember.observer('project.apps.@each.{slug,installed_at}', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});