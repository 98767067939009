define("manage/pods/companies/company/projects/project/products/index/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'product',
    componentPrefix: 'companies/company/projects/project/products/index',
    i18nPrefix: 'products.index',
    colDefs: function (model) {
      let columnInfo = [{
        id: 'id',
        sort: 'id'
      }, {
        id: 'thumbnail',
        header: '',
        default_visible: true
      }, {
        id: 'name',
        sort: 'name',
        default_visible: true
      }, {
        id: 'price',
        sort: 'price_cents',
        default_visible: true
      }, {
        id: 'fulfillment',
        sort: 'type',
        default_visible: true
      }, {
        id: 'sku',
        sort: 'sku',
        default_visible: true
      }, {
        id: 'weight',
        params: {
          unit_of_mass: model.project.get('unit_of_mass')
        }
      }, {
        id: 'dimensions',
        params: {
          unit_of_length: model.project.get('unit_of_length')
        }
      }, {
        id: 'variations'
      }, {
        id: 'questions'
      }, {
        id: 'products'
      }];
      let custom_fields = model.project.get('custom_fields').filterBy('entity', 'product');

      for (let index = 0; index < custom_fields.length; index++) {
        const custom_field = custom_fields[index];
        columnInfo.push({
          id: `custom-${custom_field.id}`,
          component_id: 'custom',
          name: custom_field.name,
          args: {
            public_id: custom_field.public_id,
            field_type: custom_field.field_type
          }
        });
      }

      return columnInfo;
    }
  });

  _exports.default = _default;
});