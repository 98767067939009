define("manage/pods/companies/company/projects/project/orders/view/components/view-product-keys/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'product-keys', 'segment']
  });

  _exports.default = _default;
});