define("manage/pods/companies/company/projects/project/digital/index/components/column-status/component", ["exports", "manage/pods/companies/company/projects/project/digital/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_constants.default, {});

  _exports.default = _default;
});