define("manage/pods/companies/company/projects/project/stats/transactions/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return {
        project: project
      };
    }

  });

  _exports.default = _default;
});