define("manage/pods/companies/company/account/components/authorization-revoked/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aCU7SPvy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"is_revoked\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui red message\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[1,[28,\"titleize\",[[24,[\"type\"]]],null],false],[0,\" Access Expired\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Access to your \"],[1,[28,\"titleize\",[[24,[\"type\"]]],null],false],[0,\" account has expired. You will need to reconnect to continue utilizing the connection.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/account/components/authorization-revoked/template.hbs"
    }
  });

  _exports.default = _default;
});