define("manage/helpers/has-installed-app", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasInstalledApp = hasInstalledApp;

  function hasInstalledApp(project, slugName) {
    if (!project || !project.get('installed_apps')) {
      (0, _logger.logger_warn)(`Invalid project object provided`);
      return false;
    }

    return project.get('installed_apps').some(app => app.get('slug') == slugName);
  }

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [project, slugName] = _ref;
      this.set('project', project);
      return hasInstalledApp(project, slugName);
    },

    installed_appsDidChange: Ember.observer('project.installed_apps.@each.slug', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});