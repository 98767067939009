define("manage/mixins/product-variation-selection", ["exports", "shared/mixins/product-variation-selection"], function (_exports, _productVariationSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "available_variations", {
    enumerable: true,
    get: function () {
      return _productVariationSelection.available_variations;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _productVariationSelection.default;
    }
  });
  Object.defineProperty(_exports, "product_variation_filter", {
    enumerable: true,
    get: function () {
      return _productVariationSelection.product_variation_filter;
    }
  });
});