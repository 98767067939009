define("manage/pods/companies/company/projects/project/segments/components/segment-downloads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WbIeSwy6",
    "block": "{\"symbols\":[\"action\"],\"statements\":[[4,\"if\",[[24,[\"has_segment_downloads\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    Downloads\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"created_at:asc\",[24,[\"segment_actions_with_downloads\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"class\",\"item\"],[11,\"href\",[29,[[22,\"api_url\"],\"/export/\",[23,1,[\"id\"]],\".csv?token=\",[24,[\"session\",\"data\",\"authenticated\",\"token\"]]]]],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/segments/components/segment-downloads/template.hbs"
    }
  });

  _exports.default = _default;
});