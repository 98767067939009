define("manage/pods/companies/company/projects/project/orders/view/components/view-download-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MviY+4HS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"text\"],[8],[0,\"\\n  \"],[1,[24,[\"download\",\"digital_download\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"downloads\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"data-tooltip\",[28,\"concat\",[\"This has been downloaded \",[24,[\"download\",\"downloads\"]],[28,\"if\",[[28,\"eq\",[[24,[\"download\",\"downloads\"]],1],null],\" time.\",\" times.\"],null]],null]],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"left center\"],[8],[0,\"\\n    \"],[1,[28,\"format-number\",[[24,[\"download\",\"downloads\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"actions large\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"data-tooltip\",[22,\"copyMessage\"]],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"left center\"],[8],[0,\"\\n\"],[4,\"copy-button\",null,[[\"class\",\"clipboardText\",\"success\",\"error\"],[\"digital-product\",[24,[\"download\",\"url\"]],[28,\"action\",[[23,0,[]],\"copied\"],null],[28,\"action\",[[23,0,[]],\"copyError\"],null]]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"icon linkify\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"data-tooltip\",\"Reset the download count\"],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"left center\"],[8],[0,\"\\n    \"],[7,\"i\",false],[12,\"class\",[29,[[28,\"if\",[[24,[\"resetDownloadTask\",\"isRunning\"]],\"notched circle loading\",\"repeat\"],null],\" icon\"]]],[3,\"action\",[[23,0,[]],\"resetDownloads\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/view-download-row/template.hbs"
    }
  });

  _exports.default = _default;
});