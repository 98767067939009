define("manage/pods/companies/company/account/billing-info/controller", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    company: Ember.computed.readOnly('model.company'),
    invoices: Ember.computed.readOnly('model.invoices'),
    payment: Ember.computed.readOnly('model.payment'),
    countries: Ember.computed.readOnly('model.countries'),
    api_url: _environment.default.urls.api,
    session: Ember.inject.service(),
    error_message: null,
    editMode: false,
    setupIntentSecret: null,
    addingNewCard: Ember.computed('company.is_card_on_file', function () {
      return Ember.isPresent(this.company) && !this.company.is_card_on_file;
    }),
    currentlyEditing: Ember.computed.or('editMode', 'addingNewCard'),

    init() {
      this._super();

      Ember.run.scheduleOnce('afterRender', this, this.updateSetupIntentSecret);
    },

    updateSetupIntentSecret() {
      if (this.currentlyEditing) {
        if (this.setupIntentSecret === null) {
          this.get('ajax').postModel(this.company, 'setup-intent').then(result => this.set('setupIntentSecret', result.setup_intent_secret));
        }
      } else {
        this.set('setupIntentSecret', null);
      }
    },

    onCurrentlyEditingChanged: Ember.observer('currentlyEditing', function () {
      this.updateSetupIntentSecret();
    }),
    updateCardTask: (0, _emberConcurrency.task)(function* (company, payment) {
      company = yield company;
      payment = yield payment;
      let {
        setupIntent,
        error
      } = yield window.stripe.handleCardSetup(this.setupIntentSecret, this.cardElement, {
        payment_method_data: {
          billing_details: {
            name: payment.name,
            address: {
              country: payment.country.iso2,
              state: payment.state ? payment.state.abbr : null
            }
          }
        }
      });

      if (error) {
        this.set('error_message', error.message);
        throw error.message;
      } else {
        yield this.get('ajax').postModel(company, 'update-payment-method', {
          data: {
            payment_method: setupIntent.payment_method
          }
        });
        yield company.reload();
      }
    }),
    actions: {
      // found in subscribe
      update_card() {
        if (this.get('updateCardTask.isRunning')) {
          return;
        }

        this.set('error_message', null);
        let company = this.get('company');
        let payment = this.get('payment');
        this.get('updateCardTask').perform(company, payment).then(() => {
          this.set('editMode', false);

          if (this.addingNewCard) {
            this.transitionToRoute('companies.company.account.stripe');
          }
        }).catch(result => {
          // Model is invalid
          if (result === ":invalid") {
            return;
          }

          if (Ember.isPresent(result.payload) && Ember.isPresent(result.payload.errors)) {
            this.set('error_message', result.payload.errors[0].detail || result.payload.errors[0].title);
          }

          if (Ember.isBlank(this.get('error_message'))) {
            return this.set('error_message', 'An unknown error has occurred');
          }
        });
      },

      // found on view and subscribe
      toggle_billing(value) {
        this.set('editMode', value);

        if (!value) {
          // rollback is throwing an error, hack for now
          return this.get('payment').setProperties({
            name: null,
            authorization_token: null
          });
        }
      },

      setCardElement(cardElement) {
        this.set('cardElement', cardElement);
      }

    }
  });

  _exports.default = _default;
});