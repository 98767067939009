define("manage/mixins/error-from-xhr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Ex:
  //   {
  //     code: 'payment_gateway_not_connected',
  //     message: "Project payment gateway for project is not connected"
  //   }
  // OR
  //   {
  //     "title": "Unable to delete a company when projects exist"
  //   }
  // OR
  //   "Could not process the order"
  //
  function messageFromError(error, intl, i18nKey, i18nParams) {
    // Ignore showing the title for Internal Server Errors.
    if (error.code != "500" || error.title != "Internal Server Error") {
      // First try to get the i18n translation for the error code if present
      // if (error.meta.code && i18nKey) {
      //   let intlKey = `${i18nKey}.errors.${error.meta.code}`;
      //   if(intl.exists(intlKey)) {
      //     return intl.t(intlKey, i18nParams);
      //   }
      // }
      if (error.code && i18nKey) {
        let intlKey = `${i18nKey}.errors.${error.code}`;

        if (intl.exists(intlKey)) {
          return intl.t(intlKey, i18nParams);
        }
      } // Next try the message and the title


      if (error.message) {
        return error.message;
      }

      if (error.title) {
        return error.title;
      }

      if (error.detail) {
        return error.detail;
      }
    } // If there's a default set in the i18n, time to use it


    if (i18nKey) {
      let intlKey = `${i18nKey}.errors.default`;

      if (intl.exists(intlKey)) {
        return intl.t(intlKey, i18nParams);
      }
    } // Next, see if the error itself has a string representation.


    if (error.toString() !== '[object Object]') {
      return error.toString();
    }

    return "Unknown error";
  }

  function _default(i18nPrefix) {
    return Ember.Mixin.create({
      intl: Ember.inject.service(),
      i18nPrefix: i18nPrefix,

      errorFromXhr(xhr) {
        let i18nParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        if (typeof xhr === 'object') {
          // Ember.ajax returns the JSON response in the payload property,
          // most other handlers just return the response in the returned object.
          let errorJson = xhr.payload ? xhr.payload : xhr; // Ex:
          //   errors: [
          //     {{error}}, ..., {{error}}
          //   ]

          if (errorJson.errors) {
            return errorJson.errors.map(error => messageFromError(error, this.intl, this.i18nPrefix, i18nParams)).join("\n");
          } // Ex:
          //   error: {{error}}


          if (errorJson.error) {
            return messageFromError(errorJson.error, this.intl, this.i18nPrefix, i18nParams);
          }
        }

        return messageFromError(xhr, this.intl, this.i18nPrefix, i18nParams);
      }

    });
  }
});