define("manage/initializers/environment", ["exports", "jquery", "shared/components/ui-field/component", "manage/components/country-dropdown/component"], function (_exports, _jquery, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _jquery.default.support.cors = true;

    _jquery.default.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      options.xhrFields = {
        crossDomain: true,
        withCredentials: true
      }; // Forcing this header so we can do request.xhr? in rails

      return jqXHR.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    }); // Only attribute bind to specific components
    // Bindings to all, causes tagless components to throw an error


    Ember.TextField.reopen({
      attributeBindings: ['data-test', 'data-test-name']
    });

    _component.default.reopen({
      attributeBindings: ['data-test', 'data-test-name']
    });

    _component2.default.reopen({
      attributeBindings: ['data-test', 'data-test-name']
    });

    Ember.LinkComponent.reopen({
      attributeBindings: ['data-tooltip', 'data-inverted'],

      _getModels(params) {
        // Since we don't have a spread operator, we need to mimic it
        // First param is route name, 2nd is models
        if (params.length === 2 && Ember.isArray(params[1])) {
          // If its an empty array, we need to return
          if (params[1].length === 0) {
            return Ember.A();
          }

          return this._super(params[1]);
        }

        return this._super(...arguments);
      }

    });
  }

  var _default = {
    name: 'environment',
    initialize: initialize
  };
  _exports.default = _default;
});