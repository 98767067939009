define("manage/pods/companies/company/projects/project/segments/components/custom-field-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action", "ember-cp-validations"], function (_exports, _ssModal, _segmentAction, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    custom_field: {
      description: "Custom Field",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, Validations, {
    classNames: ['custom-field-modal', 'segment-modal', 'component'],
    closable: false,
    type: 'custom-field',
    submitted: false,
    order_custom_fields: Ember.computed.filterBy('project.custom_fields', 'entity', 'order'),
    custom_field: null,
    custom: {},
    actions: {
      perform() {
        this.set('submitted', true);
        let result = this.validateSync();

        if (result.validations.get('isInvalid')) {
          return;
        }

        if (this.get('actionTask.isRunning')) {
          return;
        }

        let options = {
          key: this.get('custom_field.public_id'),
          value: this.get('custom')[this.get('custom_field.public_id')]
        };
        this.get('actionTask').perform(options);
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});