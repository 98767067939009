define("manage/pods/companies/company/projects/project/segments/components/change-product-variation-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/filters/products", "manage/mixins/segment-action", "ember-cp-validations", "ember-concurrency"], function (_exports, _ssModal, _products, _segmentAction, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, _products.default, Validations, {
    classNames: ['change-product-variation-modal', 'segment-modal', 'component'],
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    closable: false,
    type: 'change-product-variation',
    product: Ember.computed.readOnly('order_selection.product'),

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let filters = yield this.get('filters');

      if (!filters.product) {
        this.set('filter_invalid', true);
        this.set('error', "You must have a product filter to use this action");
        return;
      }

      let product = this.get('store').peekRecord('product', filters.product);

      if (!product) {
        this.set('filter_invalid', true);
        this.set('error', "The product you are filtered on cannot be found. Ensure that you are filtering on a single product at a time.");
        return;
      }

      if (!filters.product_variation) {
        this.set('filter_invalid', true);
        this.set('error', "You must have a product variation filter to use this action");
        return;
      }

      let product_variation = this.get('store').peekRecord('product_variation', filters.product_variation);

      if (!product_variation) {
        this.set('filter_invalid', true);
        this.set('error', "The product variation you are filtered on cannot be found. Ensure that you are filtering on a single product variation at a time.");
        return;
      }

      this.set('from_product_variation', product_variation);
      this.set('filter_invalid', false);
      let order = this.get('store').createRecord('order', {});
      let order_line = this.get('store').createRecord('order-line', {});
      let order_selection = this.get('store').createRecord('order-selection', {
        project: project,
        order: order,
        line: order_line
      });
      this.set('order_selection', order_selection);
      yield this.get('setProductTask').perform(product, product_variation);
    }),
    setProductTask: (0, _emberConcurrency.task)(function* (product, product_variation) {
      try {
        this.set('error', null);
        product = yield product;
        product = yield this.get('store').findRecord('product', product.get('id'), {
          include: 'variations'
        });
        let order_selection = yield this.get('order_selection');
        order_selection.set('product', product);
        order_selection.set('product_variation', product_variation); // This also updates questions

        yield this.get('orderTasks.updateOrderSelection').perform(order_selection);
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to update product selection."
        });
      }
    }).drop(),
    show_selection_error_message: Ember.computed('submitted', 'order_selection.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      return this.get('order_selection.validations.isInvalid');
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let order_selection = yield this.get('order_selection');
      let from_product_variation = yield this.get('from_product_variation');

      if (from_product_variation.get('id') === order_selection.get('product_variation.id')) {
        this.set('error', 'You must choose a different product variation than the one you are filtering on.');
        return;
      }

      let {
        validations: selection_validations
      } = yield order_selection.validate();

      if (!selection_validations.get('isValid')) {
        return;
      }

      let product = yield order_selection.get('product');
      let to_product_variation = yield order_selection.get('product_variation');

      if (this.get('actionTask.isRunning')) {
        return;
      }

      let args = {
        product_id: product.get('id'),
        from_product_variation_id: from_product_variation.get('id'),
        to_product_variation_id: to_product_variation.get('id')
      };
      this.get('actionTask').perform(args);
    }),
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});