define("manage/helpers/t-component-path", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentPath = componentPath;
  _exports.default = void 0;

  function componentPath(_ref
  /* , args */
  ) {
    let [component, path] = _ref;

    let component_path = component._debugContainerKey.replace(/:/g, '.').replace(/\//g, '.');

    if (Ember.isPresent(path)) {
      return `${component_path}.${path}`;
    } else {
      (0, _logger.logger_warn)(`Unable to component path for ${component}`);
      return component_path;
    }
  }

  var _default = Ember.Helper.helper(componentPath);

  _exports.default = _default;
});