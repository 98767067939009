define("manage/pods/companies/company/projects/project/segments/components/refund-modal/component", ["exports", "manage/mixins/segment-action", "manage/mixins/ss-modal"], function (_exports, _segmentAction, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, {
    classNames: ['refund-modal', 'segment-modal', 'component'],
    closable: false,
    type: 'refund',
    refund_application_fee: false,
    skip_fee: Ember.computed('refund_application_fee', function () {
      if (this.get('refund_application_fee') === false) {
        return true;
      }

      return false;
    }),
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('actionTask.isRunning')) {
          return;
        }

        this.get('actionTask').perform({
          skip_fee: this.get('skip_fee')
        });
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});