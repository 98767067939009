define("manage/models/app", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('app').reopen({
    type_id: Ember.computed.alias('app_type.id'),
    type_slug: Ember.computed.alias('app_type.slug'),
    has_options: Ember.computed('options.[]', function () {
      return this.get('options').length > 0;
    }),
    viewComponent: Ember.computed('slug', function () {
      switch (this.get('slug')) {
        case 'api-funagain_logistics':
        case 'api-ship_station':
          return 'segmentation/for-app';

        default:
          return null;
      }
    })
  });

  _exports.default = _default;
});