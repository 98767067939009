define("manage/pods/companies/company/projects/project/components/location-zone-vat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MKod5GRM",
    "block": "{\"symbols\":[\"vat_category\"],\"statements\":[[4,\"if\",[[28,\"can-access-feature\",[[24,[\"project\"]],\"vat-categories\",[24,[\"current_user\"]]],null]],null,{\"statements\":[[0,\"  Default: \"],[1,[28,\"format-number\",[[28,\"div\",[[24,[\"zone\",\"percent\"]],10000],null]],[[\"style\"],[\"percent\"]]],false],[4,\"each\",[[24,[\"project\",\"vat_categories\"]]],null,{\"statements\":[[4,\"if\",[[28,\"gte\",[[28,\"get\",[[24,[\"zone\",\"vat\",\"prices\"]],[23,1,[\"public_id\"]]],null],0],null]],null,{\"statements\":[[0,\", \"],[1,[23,1,[\"name\"]],false],[0,\": \"],[1,[28,\"format-number\",[[28,\"div\",[[28,\"get\",[[24,[\"zone\",\"vat\",\"prices\"]],[23,1,[\"public_id\"]]],null],10000],null]],[[\"style\"],[\"percent\"]]],false]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"format-number\",[[24,[\"zone\",\"percent_decimal\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/components/location-zone-vat/template.hbs"
    }
  });

  _exports.default = _default;
});