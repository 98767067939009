define("manage/pods/companies/company/projects/project/stats/index/components/stat-totals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bYjlZlgs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isLoading\",\"stats\"],[[24,[\"loadTask\",\"isRunning\"]],[24,[\"stats\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/stats/index/components/stat-totals/template.hbs"
    }
  });

  _exports.default = _default;
});