define("manage/helpers/t-raw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref
    /* , args */
    ) {
      let [key] = _ref;
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.get('intl').lookup(key, options.locale);
    }

  });

  _exports.default = _default;
});