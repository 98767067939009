define("manage/pods/companies/company/projects/project/sources/configurations/edit/index/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    projectConfigurationTasks: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    all_products: Ember.computed.readOnly('model.all_products'),
    source: Ember.computed.readOnly('model.source'),
    configuration: Ember.computed.readOnly('model.configuration'),
    actions: {
      disable_external_shipping(product) {
        if (!product.get('configuration')) {
          return;
        }

        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'save-configuration',
          approve: () => {
            product.set('configuration.external_shipping', false);
            this.send('save', false);
          }
        });
      },

      save() {
        let transitionToIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (this.get('projectConfigurationTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);

        if (!this.get('configuration.isNew') && this.get('configuration.validations.isInvalid')) {
          return;
        }

        if (this.get('configuration.product_reward.validations.isInvalid')) {
          return;
        }

        this.get('projectConfigurationTasks.save').perform(this.get('configuration')).then(() => {
          if (transitionToIndex) {
            this.transitionToRoute('companies.company.projects.project.sources.configurations.index');
          }
        }).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "The configuration wasn't able to be saved."
          });
        }));
      },

      toggle_shipping() {
        if (Ember.isPresent(this.get('configuration.product_reward.shipping_type'))) {
          this.set('configuration.product_reward.shipping_type', null);
        } else {
          this.set('configuration.product_reward.shipping_type', 'product_price');
        }
      }

    }
  });

  _exports.default = _default;
});