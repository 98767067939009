define("manage/mixins/filters/line-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    line_types: [Ember.Object.create({
      id: 'reward',
      title: 'Rewards'
    }), Ember.Object.create({
      id: 'extra',
      title: 'Extras'
    }), Ember.Object.create({
      id: 'external',
      title: 'External'
    }), Ember.Object.create({
      id: 'manual',
      title: 'Manually Added'
    })]
  });

  _exports.default = _default;
});