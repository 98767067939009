define("manage/components/project-embedded-download/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BcqFhzTU",
    "block": "{\"symbols\":[\"dropdown\",\"option\"],\"statements\":[[4,\"if\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[5,\"basic-dropdown\",[[12,\"class\",\"item\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"Trigger\"]],[[12,\"class\",\"ui right labeled icon basic button\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[1,[28,\"t\",[\"phrase.download\"],null],false],[9],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"chevron down icon\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n      \"],[6,[23,1,[\"Content\"]],[[12,\"class\",\"ui vertical menu\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"phrase.full_results\"],null],false],[0,\"\\n\\n          \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"              \"],[7,\"a\",false],[12,\"class\",\"item\"],[3,\"action\",[[23,0,[]],[28,\"pipe\",[[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"close\"]]],null],[28,\"action\",[[23,0,[]],\"download_file\",[23,2,[]]],null]],null]]],[8],[1,[23,2,[\"title\"]],false],[0,\" \"],[7,\"i\",true],[11,\"class\",[29,[[23,2,[\"icon\"]],\" icon\"]]],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/project-embedded-download/template.hbs"
    }
  });

  _exports.default = _default;
});