define("manage/pods/companies/company/projects/project/products/components/row-product-variation-upload/component", ["exports", "manage/components/file-upload/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['variation.isCpInvalid:error'],
    url: '/images',
    upload_type: 'image',

    _onSuccess(data) {
      this.set('variation.image_path', data.image_path);
    },

    _onError(XHR) {
      alert(`We're sorry, but we were unable to upload the image. Error: ${XHR.responseText}`);
    },

    actions: {
      clear_image() {
        this.set('variation.image_path', null);
      }

    }
  });

  _exports.default = _default;
});