define("manage/router", ["exports", "manage/config/environment", "manage/mixins/route-css", "manage/mixins/ss-transition", "manage/mixins/analytics", "jquery"], function (_exports, _environment, _routeCss, _ssTransition, _analytics, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router.extend(_analytics.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Ember.Route.reopen(_routeCss.default, _ssTransition.default, {
    transitionMode: 'fade',
    transitionDuration: 500,
    transitionScope: '#page-dimmer',

    activate() {
      this._super(...arguments);

      this.transitionOut();
      return Ember.run.scheduleOnce('afterRender', this, this.scroll_to_top);
    },

    scroll_to_top() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      return (0, _jquery.default)('html, body').scrollTop(0);
    },

    actions: {
      loading() {
        this.transitionIn();
        return true;
      },

      didTransition() {
        this.transitionOut();
        return true;
      },

      error() {
        this.transitionOut();
        return true;
      }

    },

    afterModel() {
      this._super(...arguments);

      this.transitionOut();
    }

  });
  Router.map(function () {
    // 3RD PARTY LINKING
    this.route('confirm', function () {
      this.route('stripe');
    }); // ADMIN ROUTES

    this.route('admin', {
      path: '/admin'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('companies', {
        path: '/companies'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('company', {
          path: '/:company_id'
        }, function () {
          this.route('edit', {
            path: '/'
          });
          this.route('projects', {
            path: '/projects'
          }, function () {
            this.route('index', {
              path: '/'
            });
            this.route('import', {
              path: '/import'
            });
            this.route('create');
          });
          this.route('invoices', {
            path: '/invoices'
          });
        });
        this.route('create', {
          path: '/create'
        });
      });
      this.route('projects', function () {
        this.route('status');
      });
      this.route('holidays', function () {
        this.route('index', {
          path: '/'
        });
      });
    }); // ALL USER ROUTES
    // USER LOGIN ROUTES

    this.route('user', function () {
      this.route('login');
      this.route('logout');
      this.route('reset');
      this.route('reset_confirmation');
      this.route('signup');
      this.route('activate');
    });
    this.route('users', function () {
      this.route('password', function () {
        this.route('edit');
      }); // USER CREATOR ROUTE

      this.route('invitation', function () {
        this.route('accept');
        this.route('accepted');
      });
    }); // Unauthenticated download route

    this.route('export', {
      path: '/export'
    }, function () {
      this.route('download', {
        path: '/:project_segment_id'
      });
    });
    this.route('unauthenticated'); // CREATOR ROUTES
    // COMPANIES ROUTES

    this.route('companies', {
      path: '/c'
    }, function () {
      // COMPANY ROUTES
      this.route('company', {
        path: '/:company_id'
      }, function () {
        this.route('account', function () {
          this.route('info', {
            path: '/'
          });
          this.route('billing-info');
          this.route('stripe');
          this.route('authorizations');
          this.route('invoices');
          this.route('users');
        }); // PROJECTS ROUTES

        this.route('projects', function () {
          this.route('index', {
            path: '/'
          });
          this.route('create', {
            path: '/create'
          });
          this.route('status', {
            path: '/status'
          }); // PROJECT ROUTES

          this.route('project', {
            path: '/:project_id'
          }, function () {
            this.route('launch-guide'); // USER LINKS

            this.route('stats', {
              path: '/stats'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('products', function () {
                this.route('projected');
                this.route('completed');
              });
              this.route('transactions', function () {
                this.route('index', {
                  path: '/'
                });
              });
            });
            this.route('orders', {
              path: '/orders'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('view', {
                path: '/:order_id'
              });
              this.route('not-found');
              this.route('create-export');
              this.route('exports');
              this.route('tags', {
                path: '/tags'
              }, function () {
                this.route('index', {
                  path: '/'
                });
              });
              this.route('groups', {
                path: '/groups'
              });
            });
            this.route('customers', function () {
              this.route('view', {
                path: '/:customer_id'
              });
            }); // PRODUCT BASED ROUTES

            this.route('products', {
              path: '/products'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('create');
              this.route('edit', {
                path: '/:product_id'
              });
              this.route('choices', {
                path: '/choices'
              }, function () {
                this.route('create');
                this.route('edit', {
                  path: '/:product_id'
                });
              });
            });
            this.route('questions', {
              path: '/questions'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('create');
              this.route('edit', {
                path: '/:product_id'
              });
            });
            this.route('settings', function () {
              this.route('feature-flags');
              this.route('emails', {
                path: '/emails'
              }, function () {
                this.route('index', {
                  path: '/'
                });
                this.route('create');
                this.route('edit', {
                  path: '/:project_email_id'
                });
              });
              this.route('payments');
              this.route('fields');
            });
            this.route('shipping', function () {
              this.route('weight');
              this.route('local-pickups', {
                path: '/local-pickups'
              }, function () {
                this.route('index', {
                  path: '/'
                });
                this.route('create');
                this.route('edit', {
                  path: '/:project_address_id'
                });
              });
              this.route('tax');
              this.route('vat');
              this.route('customs');
            });
            this.route('theme', function () {
              this.route('messages', function () {
                this.route('edit', {
                  path: '/:locale'
                });
              });
            });
            this.route('sources/pre-order'); // (Admin for now)

            this.route('sources/import'); // SOURCE BASED ROUTES

            this.route('sources', {
              path: '/sources/:project_source_id'
            }, function () {
              this.route('configurations', function () {
                this.route('index', {
                  path: '/'
                });
                this.route('create');
                this.route('edit', {
                  path: '/:project_configuration_id'
                }, function () {
                  this.route('extras');
                  this.route('caps');
                });
                this.route('review');
                this.route('settings');
              });
              this.route('extras');
              this.route('caps');
              this.route('email');
              this.route('theme');
              this.route('story');
              this.route('upgrades');
            }); // HIDDEN LAUNCH ROUTES

            this.route('info', {
              path: '/info'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('configurations', {
                path: '/configurations'
              });
              this.route('extras', {
                path: '/extras'
              });
              this.route('products', {
                path: '/products'
              });
              this.route('shipping', {
                path: '/shipping'
              });
            }); // GENERIC PROJECT ROUTE

            this.route('redirect', {
              path: '/*wildcard'
            });
            this.route('welcome', function () {
              this.route('authorization', function () {
                this.route('indiegogo');
                this.route('kickstarter');
              });
              this.route('location');
              this.route('settings');
            });
            this.route('digital', function () {
              this.route('view', {
                path: '/:fulfillment_id'
              }, function () {
                this.route('downloads');
                this.route('keys');
              });
            });
            this.route('segments', function () {
              this.route('view', {
                path: '/:project_segment_id'
              }, function () {
                this.route('index', {
                  path: '/'
                });
                this.route('actions', function () {
                  this.route('send-emails');
                });
              });
              this.route('actions', function () {
                this.route('index', {
                  path: '/'
                });
                this.route('view', {
                  path: '/segment/:project_segment_id/action/:project_segment_action_id'
                });
              });
            });
            this.route('apps', function () {
              this.route('view', {
                path: '/:app_id'
              });
              this.route('install');
            });
          }); // Has to exist outside of project so we don't load a parent model

          this.route('project/not-found');
        });
        this.route('disabled');
      });
    }); // GENERIC ROUTES

    this.route('not-found');
    this.route('redirect', {
      path: '/r/:entity/:entity_id'
    });
    this.route('redirect', {
      path: '/*wildcard'
    });
  });
});