define("manage/pods/companies/company/projects/project/settings/payments/components/import-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/error-from-xhr", "ember-concurrency"], function (_exports, _ssModal, _errorFromXhr, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, (0, _errorFromXhr.default)('payment_gateways.import-modal'), {
    current_user: Ember.inject.service('user'),
    ajax: Ember.inject.service(),
    // Passed in
    type: null,
    companies_user: null,
    payment_gateways: null,
    project: null,
    projects: null,
    onImport: () => {},
    projectsWithoutCurrent: Ember.computed('projects.@each.id', 'project.id', function () {
      let project_id = this.get('project.id');
      return this.get('projects').filter(function (item) {
        return item.get('id') !== project_id;
      });
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let selected_project = yield this.get('selected_project');
      let type = this.get('type');

      try {
        yield this.get('ajax').post(`/api/v2/project-payment-gateways/import/${type}?from_project_id=${selected_project.get('id')}&to_project_id=${project.get('id')}`);
        this.get('onImport')();
        this.closeModal();
      } catch (xhr) {
        this.set('error', this.errorFromXhr(xhr));
      }
    })
  });

  _exports.default = _default;
});