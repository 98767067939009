define("manage/pods/companies/company/projects/project/components/edit-location-zones/component", ["exports", "ember-concurrency", "shared/utils/logger"], function (_exports, _emberConcurrency, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createLocation = createLocation;
  _exports.createZone = createZone;
  _exports.default = void 0;

  // Self assumes there are variables available that are needed
  function createZone(self, location_type, cost_type) {
    let properties = {
      project: self.get('project'),
      location_type: location_type
    };
    properties.cost_type = cost_type;
    properties[self.get('parentModelName')] = self.get('parent');
    let zone = self.get('store').createRecord(`${self.get('parentModelName')}-zone`, properties);
    zone.initValue();
    return zone;
  } // Self assumes there are variables available that are needed


  function createLocation(self, zone) {
    let country = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let state = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    let properties = {
      project: self.get('project'),
      country: country,
      state: state
    };
    properties.cost_type = zone.get('cost_type');
    properties.zone = zone;
    properties[self.get('parentModelName')] = self.get('parent');
    let location = self.get('store').createRecord(`${self.get('parentModelName')}-zone-location`, properties);
    return location;
  }

  var _default = Ember.Component.extend({
    classNames: ['edit-location-zones', 'component'],
    store: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    project: null,
    parent: null,
    parentModelName: 'product',
    costType: null,
    zoneNameRelations: null,
    ships_to: 'noshipping',
    // Nowhere, Everywhere, Specific
    specific_shipping: false,
    disable_shipping: false,
    showing: Ember.computed('ships_to', function () {
      return this.get('ships_to') === 'everywhere' || this.get("ships_to") === 'specific';
    }),
    // Dynamic pieces to work with product caps
    header: "Location Zones",
    subHeader: null,
    priceText: "Price",
    wrapperClass: "ui product segment navigation",
    shippingPriceComponent: "companies/company/projects/project/edit-location-zone-price",
    noShippingText: "Shipping isn't involved",
    dropdown_class: "shipping",
    // Used on both tables
    sortProperty: 'name',
    sortDirection: 'asc',
    sorted_by: ['id:asc'],
    sorted_zones: Ember.computed.sort('specific_zones', 'sorted_by'),
    // Set in init
    shipping_types: null,

    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('costType'))) {
        (0, _logger.logger_warn)("costType is required and was not passed in");
      }

      this.set('zoneNameRelations', this.get('costType') + '_zone');
      Ember.defineProperty(this, 'zones', Ember.computed.filterBy(`parent.${this.get('zoneNameRelations')}s`, 'isDeleted', false));
      this.refresh_ships_to();
      let shipping_types = [Ember.Object.create({
        value: "noshipping",
        title: this.get('noShippingText')
      }), Ember.Object.create({
        value: "everywhere",
        title: "Everywhere"
      })];

      if (this.get('specific_shipping')) {
        shipping_types.push(Ember.Object.create({
          value: "specific",
          title: "Specific Countries"
        }));
      }

      this.set('shipping_types', shipping_types);
    },

    // Also called from clone shipping modal
    refresh_ships_to() {
      // Set ships to based on locations
      if (this.get('zones.length') > 0) {
        // Get shipping status
        if (this.get('zones').findBy('is_everywhere_else', true)) {
          this.set('ships_to', 'everywhere');
        } else {
          this.set('ships_to', 'specific');
        }
      }
    },

    everywhere_zone: Ember.computed('zones.@each.is_everywhere_else', function () {
      return this.get('zones').findBy('is_everywhere_else', true);
    }),
    specific_zones: Ember.computed('zones.@each.{is_countries,is_states}', function () {
      return this.get('zones').filter(zone => zone.get('is_countries') || zone.get('is_states'));
    }),
    deleteAllZonesTask: (0, _emberConcurrency.task)(function* () {
      let zones = yield this.get('zones');

      for (let zone of zones.toArray()) {
        yield this.get('deleteZoneTask').perform(zone);
      }
    }),
    deleteZoneTask: (0, _emberConcurrency.task)(function* (zone) {
      zone = yield zone;
      let locations = yield zone.get('locations');
      locations = locations.filterBy('isDeleted', false);

      for (let location of locations.toArray()) {
        location.deleteRecord();
      }

      zone.deleteRecord();
    }),
    shipsToChanged: Ember.observer('ships_to', function () {
      this.get('element').scrollIntoView();
    }),

    generateDescription(zone) {
      let description = null;

      if (zone.get('is_countries')) {
        description = `The following countries are located within this zone. ${zone.get('generated_name')}`;
        let zones = this.get('zones').filterBy('is_states', true).toArray();

        if (zones.length > 0) {
          let countries_with_state_rates = Ember.A();
          let locations = zone.get('locations').toArray();

          for (const location of locations) {
            if (location.get('states.length') !== 0) {
              for (const other_zone of zones) {
                if (other_zone.get('locations').isAny('country.id', location.get('country.id'))) {
                  countries_with_state_rates.addObject(location.get('country.content') || location.get('country'));
                }
              }
            }
          }

          if (countries_with_state_rates.length > 0) {
            description += '<br/><br/>Deleting this zone will remove the fall through country rate for the following countries with state rates.<br/><br/>';

            for (const country of countries_with_state_rates) {
              description += `<b>${country.get('name')}</b><br/>`;
            }
          }
        }
      } else {
        description = `The following states are located within this zone. ${zone.get('generated_name')}`;
      }

      return description;
    },

    actions: {
      sort(name, direction) {
        if (this.get('sortProperty') !== name) {
          this.set('sortProperty', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortProperty', name);
          this.set('sortDirection', direction);
        }

        return this.set('sorted_by', [`${this.get('sortProperty')}:${this.get('sortDirection')}`]);
      },

      add_zone(location_type) {
        this.send('open_modal', 'companies/company/projects/project/components/edit-location-zone-modal', {
          project: this.get('project'),
          parent: this.get('parent'),
          parentModelName: this.get('parentModelName'),
          zoneNameRelations: this.get('zoneNameRelations'),
          costType: this.get('costType'),
          shippingPriceComponent: this.get('shippingPriceComponent'),
          disable_shipping: this.get('disable_shipping'),
          location_type: location_type
        });
      },

      delete_zone(zone) {
        if (this.get('deleteZoneTask.isRunning')) {
          return;
        }

        let description = Ember.String.htmlSafe(this.generateDescription(zone));
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-zone',
          i18nParams: {
            zone_name: zone.name,
            location_count: zone.locations.length
          },
          description: description,
          approve: () => {
            return this.get('deleteZoneTask').perform(zone);
          }
        });
      },

      update_ship_to(ships_to_object) {
        let ships_to = ships_to_object.get('value');

        if (ships_to === 'specific') {
          let everywhere_zone = this.get('everywhere_zone');

          if (Ember.isPresent(everywhere_zone)) {
            this.get('deleteZoneTask').perform(everywhere_zone);
          }

          this.set('ships_to', 'specific');
          return;
        }

        if (ships_to === 'everywhere') {
          let zone = createZone(this, 'everywhere_else', this.get('costType'));
          zone.normalize();
          createLocation(this, zone);
          this.set('ships_to', 'everywhere');
          return;
        }

        if (ships_to === 'noshipping') {
          if (this.get('zones.length') > 0) {
            this.send('open_modal', 'confirm-modal', {
              i18nKey: 'switch-to-no-shipping',
              cancel: () => {
                this.notifyPropertyChange('ships_to');
              },
              approve: () => {
                return this.get('deleteAllZonesTask').perform().then(() => this.set('ships_to', 'noshipping'));
              }
            });
          } else {
            this.set('ships_to', 'noshipping');
          }

          return;
        }
      }

    }
  });

  _exports.default = _default;
});