define("manage/models/price-data-reference", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    date_added: (0, _model.attr)('date'),
    quantity: (0, _model.attr)('number'),
    shipping_country_id: (0, _model.attr)('number'),
    is_empty: Ember.computed('date_added', 'quantity', 'shipping_country_id', function () {
      if (Ember.isPresent(this.get('date_added'))) {
        return false;
      }

      if (Ember.isPresent(this.get('quantity'))) {
        return false;
      }

      if (Ember.isPresent(this.get('shipping_country_id'))) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});