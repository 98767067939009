define("manage/pods/companies/company/projects/project/digital/components/fulfillment-status-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwFfZ6e0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[28,\"concat\",[\"ui button basic compact \",[28,\"get\",[[24,[\"classForNextStatus\"]],[24,[\"fulfillment\",\"status\"]]],null]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"open_modal\",\"companies/company/projects/project/digital/change-status-modal\",[28,\"hash\",null,[[\"fulfillment\"],[[24,[\"fulfillment\"]]]]]],null]],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],[28,\"concat\",[\"next-status.\",[24,[\"fulfillment\",\"status\"]]],null]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/digital/components/fulfillment-status-button/template.hbs"
    }
  });

  _exports.default = _default;
});