define("manage/components/edit-custom-fields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQA9sqWh",
    "block": "{\"symbols\":[\"custom_field\"],\"statements\":[[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"project\",\"custom_fields\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],[23,1,[\"entity\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"description\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"data-inverted\",\"\"],[11,\"data-tooltip\",[23,1,[\"description\"]]],[8],[0,\"\\n              \"],[7,\"i\",true],[10,\"class\",\"circle help icon\"],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[28,\"edit-custom-field\",null,[[\"project\",\"custom_field\",\"entity\"],[[24,[\"project\"]],[23,1,[]],[24,[\"entity\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/edit-custom-fields/template.hbs"
    }
  });

  _exports.default = _default;
});