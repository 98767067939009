define("manage/mixins/filters/info", ["exports", "manage/utils/fallback-sort"], function (_exports, _fallbackSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['configuration'],
    // Default options
    configuration: '',
    // Option lists
    // Probably should make this dynamic eventually
    configuration_options: [Ember.Object.create({
      id: 'kickstarter',
      title: 'Kickstarter'
    }), Ember.Object.create({
      id: 'indiegogo',
      title: 'Indiegogo'
    }), Ember.Object.create({
      id: 'crowdox',
      title: 'Crowd Ox'
    }), Ember.Object.create({
      id: 'hidden',
      title: 'Hidden'
    }), Ember.Object.create({
      id: 'other',
      title: 'Other'
    }), Ember.Object.create({
      id: 'celery',
      title: 'Celery'
    }), Ember.Object.create({
      id: 'fix',
      title: 'Fig'
    }), Ember.Object.create({
      id: 'squarespace',
      title: 'SquareSpace'
    })],
    configurations_filtered: Ember.computed('project.configurations_sorted.@each.{source_site,can_select}', 'configuration', function () {
      return this.get('project.configurations_sorted').filter(item => {
        if (Ember.isBlank(this.get('configuration'))) {
          return item;
        } else if (this.get('configuration') === item.get('source_site')) {
          return item;
        } else if (this.get('configuration') === 'crowdox' && item.get('can_select')) {
          return item;
        } else if (this.get('configuration') === 'hidden' && !item.get('can_select')) {
          return item;
        }
      });
    }),
    configurations_sorted: (0, _fallbackSort.default)('configurations_filtered', 'product_reward_price', 'id')
  });

  _exports.default = _default;
});