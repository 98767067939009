define("manage/mixins/bulk-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    bulkActions: [Ember.Object.create({
      id: 'add_tag',
      name: 'tag',
      icon: 'tag',
      modal: 'tag-modal'
    }), Ember.Object.create({
      id: 'credit',
      name: 'credit',
      icon: 'money bill alternate',
      modal: 'credit-modal'
    }), Ember.Object.create({
      id: 'custom_field',
      name: 'custom-field',
      icon: 'edit',
      modal: 'custom-field-modal'
    }), Ember.Object.create({
      id: 'lock',
      name: 'lock',
      icon: 'lock',
      modal: 'lock-modal'
    }), Ember.Object.create({
      id: 'unlock',
      name: 'unlock',
      icon: 'unlock',
      modal: 'unlock-modal'
    }), Ember.Object.create({
      id: 'notes',
      name: 'notes',
      icon: 'pencil alternate',
      modal: 'notes-modal'
    }), Ember.Object.create({
      id: 'cancel',
      name: 'cancel',
      icon: 'ban',
      modal: 'bulk-action-modal'
    }), Ember.Object.create({
      id: 'digital_assign',
      name: 'digital-assign',
      icon: null,
      modal: null
    }), Ember.Object.create({
      id: 'remove_tag',
      name: 'remove-tag',
      icon: null,
      modal: null
    }), Ember.Object.create({
      id: 'api_push',
      name: 'api-push',
      icon: null,
      modal: null
    }), Ember.Object.create({
      id: 'export',
      name: 'export',
      icon: null,
      modal: null
    }), Ember.Object.create({
      id: 'import',
      name: 'import',
      icon: null,
      modal: null
    }), Ember.Object.create({
      id: 'import_selections',
      name: 'import-selections',
      icon: null,
      modal: null
    }), Ember.Object.create({
      id: 'add_product',
      name: 'add-product',
      icon: 'cart plus',
      modal: 'add-product-modal',
      admin: true
    }), Ember.Object.create({
      id: 'remove_product',
      name: 'remove-product',
      icon: 'minus',
      modal: 'remove-product-modal',
      admin: true
    }), Ember.Object.create({
      id: 'change_product_variation',
      name: 'change-product-variation',
      icon: 'exchange',
      modal: 'change-product-variation-modal',
      admin: true
    }), Ember.Object.create({
      id: 'change_configuration',
      name: 'change-configuration',
      icon: 'cart arrow down',
      modal: 'change-configuration-modal',
      admin: true
    }), Ember.Object.create({
      id: 'complete',
      name: 'complete',
      icon: 'circle',
      modal: 'complete-modal',
      admin: true
    }), Ember.Object.create({
      id: 'delete_selections',
      name: 'delete-selections',
      icon: 'trash',
      modal: 'bulk-action-modal',
      admin: true
    }), Ember.Object.create({
      id: 'delete',
      name: 'delete',
      icon: 'trash',
      modal: 'bulk-action-modal',
      admin: true
    }), Ember.Object.create({
      id: 'email',
      name: 'email',
      icon: 'envelope',
      modal: 'send-emails-modal',
      admin: true
    }), Ember.Object.create({
      id: 'reopen',
      name: 'reopen',
      icon: 'redo',
      modal: 'bulk-action-modal',
      admin: true
    }), Ember.Object.create({
      id: 'uncancel',
      name: 'uncancel',
      icon: 'undo',
      modal: 'bulk-action-modal',
      admin: true
    }), Ember.Object.create({
      id: 'refund',
      name: 'refund',
      icon: 'angle double left',
      modal: 'refund-modal',
      admin: true
    })]
  });

  _exports.default = _default;
});