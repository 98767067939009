define("manage/pods/companies/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PzTmKCdF",
    "block": "{\"symbols\":[\"company\"],\"statements\":[[1,[28,\"page-title\",[\"Companies\"],null],false],[0,\"\\n\"],[4,\"page-banner\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui text menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n        All Companies\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"inner layout\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui result basic segment\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inner\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui cards\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"user\",\"companies\"]]],null,{\"statements\":[[0,\"          \"],[5,\"link-to\",[[12,\"class\",\"ui card\"]],[[\"@route\",\"@model\"],[\"companies.company\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n                \"],[1,[23,1,[\"name\"]],false],[0,\"\\n              \"],[9],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"meta\"],[8],[9],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[0,\"I'm sorry but we weren't able to find any companies for your user\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/index/template.hbs"
    }
  });

  _exports.default = _default;
});