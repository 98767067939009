define("manage/pods/companies/company/projects/project/sources/pre-order/controller", ["exports", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    crowdox_app: Ember.computed.readOnly('model.crowdox_app'),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Getting started variables
    has_started: false,
    clonable_sources: Ember.computed.filter('project.sources.@each.site', function (item) {
      return item.get('id') != 'crowdox';
    }),
    setupConfigurationsTask: (0, _emberConcurrency.task)(function* (source) {
      source = yield source;
      let product_extra_global = yield source.get('product_extra_global');
      let configurations = yield this.store.query('project-configuration', {
        filter: {
          source: source.get('id')
        },
        page: {
          size: 50
        },
        include: ['product-reward', 'product-extra'].join(',')
      });
      let setup_configurations = [];
      let proxy_configuration = Ember.ObjectProxy.extend({
        is_none: Ember.computed.equal('extra_type', 'none'),
        is_global: Ember.computed.equal('extra_type', 'global'),
        is_custom: Ember.computed.equal('extra_type', 'custom')
      });

      for (let configuration of configurations.toArray()) {
        let product_extra = yield configuration.get('product_extra');
        let extra_type = 'global';

        if (Ember.isBlank(product_extra)) {
          extra_type = 'none';
        } else if (product_extra.get('id') !== product_extra_global.get('id')) {
          extra_type = 'custom';
        }

        setup_configurations.push(proxy_configuration.create({
          selected: true,
          extra_type: extra_type,
          has_custom: extra_type === "custom",
          content: configuration
        }));
      }

      this.set('configurations', setup_configurations);
    }),
    // The rest are do-setup
    selected_source: null,
    configurations: null,
    extra_selection: 'global',
    all_checked: Ember.computed('configurations.@each.selected', function () {
      return this.get('configurations').isEvery('selected', true);
    }),
    none_checked: Ember.computed('configurations.@each.selected', function () {
      return this.get('configurations').isEvery('selected', false);
    }),
    createSourceTask: (0, _emberConcurrency.task)(function* (project, selected_source, extra_selection, configurations) {
      project = yield project;
      selected_source = yield selected_source;
      let app = yield this.get('crowdox_app');
      let cloned_product_extra_global = null;

      if (extra_selection === 'global') {
        let product_extra_global = yield selected_source.get('product_extra_global');
        let product_clone_url = this.get('ajax').buildModelUrl(product_extra_global, 'clone');
        let result = yield this.get('ajax').post(product_clone_url);
        this.store.pushPayload(result); // refresh the product information, so it loads when you click it

        cloned_product_extra_global = yield this.store.findRecord('product', result.data.id, {
          include: ['mappings'].join(',')
        });
      }

      let data = {
        url: `https://app.crowdox.com/projects/${project.get('slug')}`,
        product_extra_global_id: cloned_product_extra_global.get('id')
      }; // Install the app

      yield app.install(project, data); // Reload the project

      yield this.store.findRecord('project', project.get('id'), {
        include: 'sources'
      });
      let crowdox_source = yield this.store.query('project-source', {
        filter: {
          project: project.get('id'),
          site: 'crowdox'
        }
      }).then(results => {
        return results.objectAt(0);
      });
      let last_configuration_error = null; // Configurations are the proxy object array

      for (let configuration of configurations.filterBy('selected', true).toArray()) {
        try {
          let configuration_clone_url = this.get('ajax').buildModelUrl(configuration.get('content'), 'clone'); // Default to global

          let extra_action = 'set_extra_to_source';

          if (configuration.get('is_none')) {
            extra_action = 'set_extra_to_none';
          } else if (configuration.get('is_custom')) {
            extra_action = 'clone_extra_from_configuration';
          }

          yield this.get('ajax').post(configuration_clone_url, {
            data: {
              project_source_id: crowdox_source.get('id'),
              extra_action: extra_action
            }
          });
        } catch (e) {
          last_configuration_error = e;
        }
      }

      return {
        crowdox_source,
        last_configuration_error
      };
    }),
    actions: {
      start_configuration() {
        if (this.get('setupConfigurationsTask.isRunning')) {
          return;
        }

        let selected_source = this.get('selected_source'); // If they didn't pick a source, pick the first one like in the UI

        if (Ember.isBlank(selected_source)) {
          selected_source = this.get('clonable_sources.firstObject');
          this.set('selected_source', selected_source);
        } // Setup configurations


        let intl = this.get('intl');
        this.get('setupConfigurationsTask').perform(selected_source).then(() => this.set('has_started', true)).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t('route.companies.company.projects.project.sources/pre-order.error.pre_order_setup', {
              crowdox: this.get('project.t.source.crowdox')
            })
          });
        }));
      },

      check_all() {
        let selected = false;

        if (this.get('none_checked')) {
          selected = true;
        }

        this.get('configurations').setEach('selected', selected);
      },

      add_pre_order() {
        if (this.get('createSourceTask.isRunning')) {
          return;
        }

        let project = this.get('project');
        let source = this.get('selected_source');
        let extra_selection = this.get('extra_selection');
        let configurations = this.get('configurations');
        let intl = this.get('intl');
        this.get('createSourceTask').perform(project, source, extra_selection, configurations).then(_ref => {
          let {
            crowdox_source,
            last_configuration_error
          } = _ref;

          if (last_configuration_error) {
            // Source cloned, but we had one or more errors cloning a configuration, transition anyway.
            this.send('open_modal', 'error-modal', {
              error: last_configuration_error,
              description: intl.t('route.companies.company.projects.project.sources/pre-order.error.configuration_error', {
                crowdox: this.get('project.t.source.crowdox')
              })
            });
          }

          this.transitionToRoute('companies.company.projects.project.sources.configurations', crowdox_source.get('id'));
        }).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t('route.companies.company.projects.project.sources/pre-order.error.create_error', {
              crowdox: this.get('project.t.source.crowdox')
            })
          });
        }));
      }

    }
  });

  _exports.default = _default;
});