define("manage/pods/companies/company/projects/project/stats/products/completed/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      type: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sort_direction: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      shipping_country: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        product_stats: this.search(params)
      });
    },

    search(params) {
      let project = this.modelFor('companies.company.projects.project');
      let controller = this.controllerFor(this.routeName);
      let filters = controller.build_filters(project, Ember.Object.create(params));
      let type = params.type;
      let include = [];

      if (type === 'product_stat' || type === 'product_variation_stat') {
        include.push('product');
      }

      if (type === 'product_variation_stat') {
        include.push('product.variants');
        include.push('product.variants.values');
        include.push('product.variations');
      }

      if (Ember.isPresent(include)) {
        filters.include = include.join(',');
      }

      return this.store.query(`project-${Ember.String.dasherize(type)}`, filters);
    }

  });

  _exports.default = _default;
});