define("manage/pods/companies/company/projects/project/welcome/authorization/indiegogo/controller", ["exports", "ember-concurrency", "ember-cp-validations", "manage/pods/companies/company/projects/project/welcome/wizard"], function (_exports, _emberConcurrency, _emberCpValidations, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    password: {
      description: "Password",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Controller.extend(_wizard.default, Validations, {
    project: Ember.computed.readOnly('model'),
    intl: Ember.inject.service(),
    email: null,
    password: null,
    stepName: 'authorization',
    authorizeTask: (0, _emberConcurrency.task)(function* (project, email, password) {
      let company = yield project.get('company');
      yield company.authorize('indiegogo', email, password);
      yield company.reload();
    }),
    has_authorized: Ember.computed.equal('project.company.authorizations.indiegogo', 'authorized'),
    actions: {
      submit() {
        if (this.get('authorizeTask.isRunning')) {
          return;
        }

        this.set('submitted', true);
        let result = this.validateSync();

        if (result.validations.get('isInvalid')) {
          return;
        }

        this.set('error', null);
        let project = this.get('project');
        let email = this.get('email');
        let password = this.get('password');
        let intl = this.get('intl');
        this.get('authorizeTask').perform(project, email, password).catch(result => {
          if (Ember.isPresent(result.payload) && Ember.isPresent(result.payload.errors)) {
            return this.set('error', result.payload.errors[0].detail || result.payload.errors[0].title);
          } else {
            return this.set('error', intl.t('route.companies.company.projects.project.welcome.error.unknown_authorization'));
          }
        });
      },

      next() {
        this.send('advance');
      }

    }
  });

  _exports.default = _default;
});