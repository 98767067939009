define("manage/serializers/application", ["exports", "ember-data/serializers/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _jsonApi.default.extend({
    // JSON API on the backend, only passes through a { data: } key if we included the relationship.
    // If we didn't include the relationship, the api returns an empty hash {}
    // What we are looking for here is, if the property on the relationship is an empty hash, remove it from loading data
    // Otherwise, ember clears all associations with that, even though we didn't side laod it
    extractRelationships() {
      let relationships = this._super(...arguments); // Now only pass through relationships that have actual data
      // Any data, just not a total blank {}


      let keys = Object.keys(relationships);

      for (let key of keys) {
        let prop = relationships[key];

        if (relationships.hasOwnProperty(key)) {
          if (Object.getOwnPropertyNames(prop).length === 0) {
            delete relationships[key];
          }
        }
      }

      return relationships;
    },

    extractErrors() {
      let error_hash = this._super(...arguments);

      for (let key of Object.keys(error_hash)) {
        let errors = error_hash[key];
        let dashed_name = Ember.String.dasherize(key);
        let format = `${dashed_name} - `;

        for (let i = 0; i < errors.length; i++) {
          let error = errors[i];

          if (error.startsWith(format)) {
            errors.splice(i, 1, error.substring(format.length));
          }
        }
      }

      return error_hash;
    }

  });

  _exports.default = _default;
});