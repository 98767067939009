define("manage/mixins/models/product/variation", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    enabled: {
      description: "Enabled",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    },
    details_data: {
      description: "Variation Data",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    sku: {
      description: "SKU",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },
    is_default: {
      description: "Is Default",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    details_named: Ember.computed('details_sorted.@each.{variant,variant_value}', function () {
      return this.get('details_sorted').map(function (detail) {
        return `${detail.get('variant.name')}: ${detail.get('variant_value.name')}`;
      }).join(', ');
    }),
    variant_names: Ember.computed('details_sorted.@each.variant_value', function () {
      var variant_values = this.get('details_sorted').map(function (item) {
        return item.get('variant_value.name');
      }).compact();

      if (variant_values.length > 1) {
        return variant_values.join(' - ');
      } else {
        return variant_values[0];
      }
    }),
    full_name_with_product: Ember.computed('details_sorted.@each.{variant,variant_value}', 'product.name', function () {
      return this.get('product.name') + ' - ' + this.get('details_sorted').map(function (detail) {
        return `${detail.get('variant.name')}: ${detail.get('variant_value.name')}`;
      }).join(',');
    }),

    save() {
      for (let detail of this.get('details').toArray()) {
        if (Ember.isBlank(detail.get('variant_id'))) {
          detail.set('variant_id', this._parseInt(detail.get('variant.id')));
        }

        if (Ember.isBlank(detail.get('variant_value_id'))) {
          detail.set('variant_value_id', this._parseInt(detail.get('variant_value.id')));
        }
      }

      return this._super(...arguments);
    },

    _parseInt(value) {
      value = parseInt(value, 10);

      if (isNaN(value)) {
        return null;
      }

      return value;
    }

  });

  _exports.default = _default;
});