define("manage/pods/companies/company/projects/project/orders/groups/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'order',
    componentPrefix: 'companies/company/projects/project/orders/groups',
    i18nPrefix: 'orders.groups',
    colDefs: function
      /* model */
    () {
      return [{
        id: 'group-products',
        default_visible: true
      }, {
        id: 'count',
        default_visible: true
      }];
    }
  });

  _exports.default = _default;
});