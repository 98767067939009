define("manage/pods/companies/company/projects/project/sources/configurations/settings/route", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let source = this.modelFor('companies.company.projects.project.sources');
      return Ember.RSVP.hash({
        project: project,
        source: source,
        configurations: this.store.query('project-configuration', {
          filter: {
            id: (params.configurations || '').split(',')
          },
          include: ['product-reward'].join(','),
          page: {
            size: _environment.default['max-page-size']
          }
        })
      });
    }

  });

  _exports.default = _default;
});