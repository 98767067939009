define("manage/pods/admin/holidays/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      var controller = this.controllerFor(this.routeName);
      var sortProperty = controller.get('sort');
      var sortDirection = controller.get('sortDirection');
      var sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      var page = params.page;
      var pageSize = controller.get('pageSize');
      return this.get('store').query('holiday', {
        sort: sort,
        page: {
          number: page,
          size: pageSize
        }
      });
    }

  });

  _exports.default = _default;
});