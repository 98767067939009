define("manage/mixins/location-controller", ["exports", "ember-concurrency", "manage/utils/catch-real", "shared/tasks/save-task"], function (_exports, _emberConcurrency, _catchReal, _saveTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(zone_relation_name, model_friendly_name) {
    return Ember.Mixin.create(_saveTask.default, {
      project: Ember.computed.readOnly('model.project'),
      flashMessages: Ember.inject.service(),
      submitted: false,
      isInvalid: Ember.computed(`project.${zone_relation_name}.@each.{isDeleted,validationsIsInvalid}`, function () {
        return this.get(`project.${zone_relation_name}`).filterBy('isDeleted', false).isAny('validations.isInvalid', true);
      }),
      // Fix this
      saveTask: (0, _emberConcurrency.task)(function* () {
        this.set('submitted', true);
        let project = yield this.get('project');
        let zones = yield project.get(`${zone_relation_name}`); // Validation location zones

        let isInvalid = false;

        for (let zone of zones.filterBy('isDeleted', false).toArray()) {
          let {
            validations: validations
          } = yield zone.validate();

          if (!validations.get('isValid')) {
            isInvalid = true;
          }
        }

        if (isInvalid) {
          return;
        }

        let deleteTasks = [this.get('_saveInOrder').perform(project, [{
          name: 'zones'
        }], true)];
        let deletedResults = yield (0, _emberConcurrency.allSettled)(deleteTasks); // If any errors were returned, throw the first one to stop the progress

        let deleteErrors = deletedResults.filter(result => result.state !== 'fulfilled').mapBy('reason'); // if there was an error somewhere, throw it, so it goes to catch instead of then

        if (deleteErrors.length > 0) {
          throw deleteErrors[0];
        } // _saveInOrder calls _saveCollection which automatically saves nested has many relations


        let tasks = [this.get('_saveInOrder').perform(project, [{
          name: 'zones'
        }])];
        let results = yield (0, _emberConcurrency.allSettled)(tasks); // If any errors were returned, throw the first one to stop the progress

        let errors = results.filter(result => result.state !== 'fulfilled').mapBy('reason'); // if there was an error somewhere, throw it, so it goes to catch instead of then

        if (errors.length > 0) {
          throw errors[0];
        }

        this.get('flashMessages').success('Successfully saved!');
      }).drop(),
      actions: {
        save() {
          this.get('saveTask').perform().catch((0, _catchReal.default)(error => {
            this.send('open_modal', 'error-modal', {
              error: error,
              description: `${model_friendly_name} was unable to be saved.`
            });
          }));
        }

      }
    });
  }
});