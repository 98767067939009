define("manage/pods/companies/company/projects/project/orders/view/components/view-order-history-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pXr18f1x",
    "block": "{\"symbols\":[\"history\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Order History\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    We received the following message\"],[1,[28,\"if\",[[28,\"gt\",[[24,[\"order\",\"history\",\"length\"]],1],null],\"s\"],null],false],[0,\" while importing the customer information into Crowd Ox\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui bulleted list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"order\",\"history\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n        \"],[1,[28,\"render-linebreaks\",[[28,\"compute\",[[28,\"action\",[[23,0,[]],\"message_or_text\"],null],[23,1,[]]],null]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"Ok\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/view-order-history-modal/template.hbs"
    }
  });

  _exports.default = _default;
});