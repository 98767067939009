define("manage/pods/companies/company/projects/project/orders/view/components/order-history-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8P6YItq",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n  The formatting here is not like other components. The reason here is that\\n  we don't want to render any whitespace before or after the text.\\n\\n  When we get whitespace it adds extra space to the HTML and you get spaces\\n  before and after the text.\\n\"],[4,\"if\",[[28,\"is-empty\",[[24,[\"value\"]]],null]],null,{\"statements\":[[0,\"blank\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"is_date\"]]],null,{\"statements\":[[1,[28,\"format-date\",[[24,[\"value\"]]],[[\"month\",\"day\",\"year\"],[\"short\",\"2-digit\",\"numeric\"]]],false]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"is_currency\"]]],null,{\"statements\":[[1,[28,\"format-number\",[[24,[\"value_amount\"]]],[[\"currency\"],[[24,[\"currency\"]]]]],false]],\"parameters\":[]},{\"statements\":[[1,[22,\"value\"],false]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/order-history-change/template.hbs"
    }
  });

  _exports.default = _default;
});