define("manage/pods/companies/company/projects/project/orders/components/status-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5G7vbqFq",
    "block": "{\"symbols\":[\"item\",\"lock\",\"index\"],\"statements\":[[4,\"if\",[[24,[\"order\",\"is_locked\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"lock icon\"],[8],[9],[0,\" by\\n  \"],[4,\"each\",[[24,[\"order\",\"locked\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"eq\",[[23,3,[]],0],null]],null,{\"statements\":[[0,\", \"]],\"parameters\":[]},null],[1,[28,\"capitalize\",[[23,2,[\"process\"]]],null],false]],\"parameters\":[2,3]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"order\",\"is_canceled\"]]],null,{\"statements\":[[0,\"    (\"],[1,[24,[\"order\",\"status\"]],false],[0,\")\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[24,[\"order\",\"status\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"each\",[[24,[\"project\",\"libs\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"app\",\"order_status\"]]],null,{\"statements\":[[1,[28,\"companies/company/projects/project/orders/app-status\",null,[[\"access\",\"app\",\"order\"],[[23,1,[\"access\"]],[23,1,[\"app\"]],[24,[\"order\"]]]]],false]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/components/status-text/template.hbs"
    }
  });

  _exports.default = _default;
});