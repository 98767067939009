define("manage/mixins/modal-task", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    model: null,
    modelName: null,
    serviceTaskAction: null,
    encapsulatedTaskAction: null,
    promiseResolve: null,
    error: null,
    // modal properties
    closable: false,

    init() {
      this._super(...arguments);

      if (this.serviceTaskAction == null && this.encapsulatedTaskAction == null) {
        (0, _logger.logger_error)("A serviceTaskAction or encapsulatedTaskAction must be passed in");
      }

      if (this.serviceTaskAction != null && this.encapsulatedTaskAction != null) {
        (0, _logger.logger_error)("A serviceTaskAction and encapsulatedTaskAction were both passed in and only one was expected");
      } // If an encapsulatedTaskAction is passed in instead of a serviceTaskAction,
      // then call definedProperty to pre-compute the computed property.


      if (this.encapsulatedTaskAction) {
        Ember.defineProperty(this, 'serviceTaskAction', this.encapsulatedTaskAction);
      }
    },

    actions: {
      perform() {
        if (this.get('serviceTaskAction.isRunning')) {
          return;
        }

        this.get('serviceTaskAction').perform(this.get('model')).then(() => {
          this.closeModal(); // Mark the promise as handled and retry

          this.get('promiseResolve')('retry');
        }).catch(error => this.set('error', error));
      },

      close() {
        // If they hit cancel, we need to reject so the route state is correct
        this.closeModal(); // don't mark as handled or retry

        this.get('promiseResolve')('aborted');
      }

    }
  });

  _exports.default = _default;
});