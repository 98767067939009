define("manage/pods/companies/company/projects/project/orders/view/controller", ["exports", "manage/utils/fallback-sort", "ember-concurrency", "manage/utils/address-lines", "shared/helpers/can-access-feature", "manage/config/environment"], function (_exports, _fallbackSort, _emberConcurrency, _addressLines, _canAccessFeature, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    order: Ember.computed.readOnly('model.order'),
    project: Ember.computed.readOnly('model.order.project'),
    apiApps: Ember.computed.readOnly('model.apiApps'),
    saving_email: false,
    saving_order: false,
    show_all_emails: false,
    show_only_confirmed_transactions: true,
    current_user: Ember.inject.service('user'),
    flashMessages: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    session: Ember.inject.service(),
    editing_tags: false,
    can_reactivate_email: Ember.computed('sorted_emails.@each.id', 'order.customer.last_email_status', 'order.extra.email_reactivates', function () {
      const last_email_id = this.get('sorted_emails.lastObject.id') || '';
      return this.get('order.customer.last_email_status') === 'hard_bounce' && !(this.get('order.extra.email_reactivates') || []).find(i => i.email_id.toString() === last_email_id.toString());
    }),
    date_format: Ember.computed('current_user', 'order.project', function () {
      if ((0, _canAccessFeature.canAccessFeature)([this.get('order.project'), 'extra-order-info', this.get('current_user')])) {
        return 'shortWithTime';
      }

      return 'short';
    }),
    can_delete_order: Ember.computed('order.transactions.[]', 'order.is_external', function () {
      if (this.get('order.is_external')) {
        return false;
      }

      if (this.get('order.transactions.length') !== 0) {
        return false;
      }

      return true;
    }),
    can_unlock_order: Ember.computed('order.{is_locked,is_canceled}', function () {
      if (this.get('order.is_canceled')) {
        return false;
      }

      if (this.get('order.is_locked')) {
        return true;
      }

      return false;
    }),
    can_reopen_order: Ember.computed('order.{is_completed,is_locked,is_canceled,current_configuration.is_complete}', function () {
      if (this.get('order.is_canceled')) {
        return false;
      }

      if (this.get('order.is_locked')) {
        return false;
      }

      if (this.get('order.is_completed') && !this.get('order.current_configuration.is_complete')) {
        return true;
      }

      return false;
    }),
    can_cancel_order: Ember.computed('order.transactions.[]', 'order.{is_external,is_canceled,is_locked}', function () {
      if (this.get('order.is_canceled')) {
        return false;
      }

      if (this.get('order.is_locked')) {
        return false;
      }

      if (!this.get('order.is_external') && this.get('order.transactions.length') === 0) {
        return false;
      }

      return true;
    }),
    can_uncancel_order: Ember.computed('order.{is_locked,is_canceled}', function () {
      if (this.get('order.is_locked')) {
        return false;
      }

      if (!this.get('order.is_canceled')) {
        return false;
      }

      return true;
    }),
    can_edit_address: Ember.computed('order.{is_canceled,is_locked,local_pickup_address.id}', function () {
      if (this.get('order.is_canceled')) {
        return false;
      }

      if (this.get('order.is_locked')) {
        return false;
      }

      if (Ember.isPresent(this.get('order.local_pickup_address.id'))) {
        return false;
      }

      return true;
    }),
    can_add_item_to_order: Ember.computed('order.{is_canceled,is_locked}', function () {
      if (this.get('order.is_canceled')) {
        return false;
      }

      if (this.get('order.is_locked')) {
        return false;
      }

      return true;
    }),
    can_change_configuration: Ember.computed('order.is_locked', 'order.selections.@each.is_locked', function () {
      return !this.get('order.is_locked') && this.get('order.selections').every(selection => !selection.get('is_locked'));
    }),
    has_sent_one_email: Ember.computed.or('order.invite_sent_on', 'order.approved_on'),
    sorted_emails: (0, _fallbackSort.default)('order.emails', 'sent_on_or_created_at'),
    sorted_emails_limited: Ember.computed('sorted_emails.[]', 'show_all_emails', function () {
      let sorted_emails = this.get('sorted_emails').slice(0).reverseObjects();

      if (this.get('show_all_emails')) {
        return sorted_emails;
      }

      return sorted_emails.slice(0, 6);
    }),
    unconfirmed_transactions: Ember.computed.filterBy('order.transactions', 'confirmed', false),
    transactions: Ember.computed('show_only_confirmed_transactions', 'order.transactions.@each.confirmed', function () {
      if (!this.get('show_only_confirmed_transactions')) {
        return this.get('order.transactions');
      }

      return this.get('order.transactions').filterBy('confirmed');
    }),
    transactions_sorted: (0, _fallbackSort.default)('transactions', 'actual_created_at'),
    logical_furthest_step: Ember.computed('order.{furthest_step,is_completed}', function () {
      if (this.get('order.is_completed')) {
        return 'confirm';
      }

      if (this.get('order.furthest_step') === 'confirm') {
        // If its confirm but not complete, show shipping
        return 'shipping';
      }

      return this.get('order.furthest_step');
    }),
    address_copy: Ember.computed('order.shipping_address.{name,company_name,address_1,address_2,city,state_name,postal_code,country.iso3}', function () {
      return (0, _addressLines.default)(this.get('order.shipping_address'));
    }),
    local_pickup_address_copy: Ember.computed('order.local_pickup_address.{name,address_1,address_2,city,state_name,postal_code,country.iso3}', function () {
      return (0, _addressLines.default)(this.get('order.local_pickup_address'));
    }),
    saveCustomerTask: (0, _emberConcurrency.task)(function* () {
      let order = yield this.get('order');
      let customer = yield this.get('order.customer');
      let errorMessage = '';
      let duplicateEmail = null;

      try {
        yield customer.save();
      } catch (error) {
        if (error && error.errors) {
          for (var i = 0, e; i < error.errors.length; i++) {
            e = error.errors[i];

            if (e.meta && e.meta.code === ':duplicate_email') {
              duplicateEmail = customer.get('email');
            }

            if (e.source) {
              let property = Ember.String.capitalize(e.source.pointer.replace('/data/attributes/', ''));
              errorMessage += property + ' ' + e.title + '\r\n';
            } else {
              errorMessage += e.title + '\r\n';
            }
          }
        }

        customer.rollbackAttributes();
      }

      if (duplicateEmail) {
        if (this.get('current_user.is_admin')) {
          let project = yield order.get('project');
          let otherCustomers = yield this.get('store').query('customer', {
            filter: {
              email: duplicateEmail,
              project: project.get('id')
            }
          });

          if (otherCustomers.get('length') === 1) {
            this.send('open_modal', 'companies/company/projects/project/orders/merge-users-modal', {
              order: order,
              customer: customer,
              otherCustomer: otherCustomers.objectAt(0),
              reload: () => {
                window.location.reload();
              }
            });
          } else {
            this.send('open_modal', 'companies/company/projects/project/orders/duplicate-email-modal', {
              order: order,
              customer: customer,
              email: duplicateEmail
            });
          }
        } else {
          this.send('open_modal', 'companies/company/projects/project/orders/duplicate-email-modal', {
            order: order,
            customer: customer,
            email: duplicateEmail
          });
        }
      } else if (errorMessage) {
        this.send('open_modal', 'error-modal', {
          description: `There was an error updating the email. ${errorMessage}`
        });
      }

      try {
        yield customer.reload();
        yield order.reload();
      } catch (e) {
        this.send('open_modal', 'error-modal', {
          error: e,
          description: "There was an error while trying to update the email."
        });
      }
    }).drop(),
    saveOrderTask: (0, _emberConcurrency.task)(function* () {
      let order = this.get('order');

      try {
        yield order.save();
        yield order.reload();
      } catch (e) {
        this.send('open_modal', 'error-modal', {
          error: e,
          description: "The order was unable to get updated."
        });
      }
    }).drop(),
    getEmailTask: (0, _emberConcurrency.task)(function* (email) {
      email = yield email;
      let url = `/api/v2/orders/${email.get('order.id')}/email?order_email_id=${email.get('id')}`;

      try {
        let data = yield this.get('ajax').request(url);
        this.send('open_modal', 'companies/company/projects/project/view-email-modal', {
          email: Ember.Object.create(data),
          email_type: email.get('name'),
          showTextAndHtml: false
        });
      } catch (e) {
        if (Ember.isPresent(e.payload) && Ember.isPresent(e.payload.errors) && Ember.isPresent(e.payload.errors[0])) {
          this.send('open_modal', 'error-modal', {
            error: e,
            description: `There was an error while trying to view the email. Error: ${e.payload.errors[0].title}`
          });
        } else {
          this.send('open_modal', 'error-modal', {
            error: e,
            description: "Message is not available. If it was recently sent there is usually a 3-10 minute delay before it can be viewed."
          });
        }
      }
    }).drop(),
    reactivateEmail: (0, _emberConcurrency.task)(function* (order) {
      order = yield order;

      try {
        yield this.get('ajax').postModel(order, 'reactivate_email');
        yield order.reload();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error,
          description: 'Unable to reactivate email.'
        });
      }
    }).drop(),
    addTagTask: (0, _emberConcurrency.task)(function* (order, name) {
      order = yield order;
      let tag = this.get('store').createRecord('order-tag', {
        project: order.get('project'),
        name: name
      });

      try {
        yield tag.save();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to create tag."
        });
        return false;
      }

      try {
        order.get('tags').pushObject(tag);
        yield order.save();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to add tag to order."
        });
        return false;
      }
    }),
    changeTagTask: (0, _emberConcurrency.task)(function* (order, tags) {
      order = yield order;
      order.set('tags', tags);
      yield order.save();
    }),
    tagIsRunning: Ember.computed.or('addTagTask.isRunning', 'changeTagTask.isRunning'),
    actions: {
      transition_to_orders() {
        this.transitionToRoute('companies.company.projects.project.orders');
      },

      copied(text) {
        this.get('flashMessages').success(text);
      },

      refreshEmails() {
        this.send('reloadEmails');
      },

      searchTags(value) {
        return this.store.query('order-tag', {
          page: {
            size: 25
          },
          filter: {
            project: this.get('project.id'),
            search: `${value}*`
          }
        });
      },

      createTag(order, select, event) {
        if (this.get('addTagTask.isRunning')) {
          return;
        }

        if (event.keyCode === 13 && select.isOpen && !select.highlighted && Ember.isPresent(select.searchText)) {
          if (!order.tags.includes(select.searchText)) {
            return this.get('addTagTask').perform(order, select.searchText);
          }
        }
      },

      reloadRoute() {
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});