define("manage/pods/companies/company/projects/project/sources/extras/components/section-mapping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qylXVx4c",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"product\",\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n        \"],[1,[24,[\"product\",\"description\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui basic button icon\"],[8],[0,\"\\n    \"],[7,\"i\",false],[12,\"class\",\"edit icon\"],[12,\"title\",\"Edit Section\"],[3,\"action\",[[23,0,[]],\"open_modal\",\"companies/company/projects/project/sources/extras/components/section-modal\",[28,\"hash\",null,[[\"product\"],[[24,[\"product\"]]]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"class\",\"ui green icon button\"],[12,\"data-tooltip\",[28,\"t\",[\"phrase.add_to_bottom\"],null]],[12,\"data-position\",\"right center\"],[3,\"action\",[[23,0,[]],[24,[\"mapItem\"]],[24,[\"product\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/extras/components/section-mapping/template.hbs"
    }
  });

  _exports.default = _default;
});