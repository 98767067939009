define("manage/pods/companies/company/projects/project/orders/view/components/rectify-currency-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/utils/monetize", "manage/mixins/error-from-xhr"], function (_exports, _ssModal, _emberConcurrency, _monetize, _errorFromXhr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, (0, _errorFromXhr.default)(), {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    closable: true,
    amount: null,
    error: null,
    saveTask: (0, _emberConcurrency.task)(function* () {
      let amount = this.get('amount');

      if (Ember.isBlank(amount)) {
        throw new Error('Must set an amount');
      }

      let currency = this.get('transaction.project.currency');
      let data = {
        amount_cents: (0, _monetize.convert_dollars_to_cents)(amount, currency)
      };
      let rectifyResult = yield this.get('ajax').post(`/api/v2/order-transactions/${this.get("transaction.id")}/rectify_currency`, {
        data: data
      });
      this.get('store').pushPayload('order-transaction', rectifyResult);
    }),
    actions: {
      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform().then(() => this.closeModal()).catch(xhr => {
          this.set('error', this.errorFromXhr(xhr));
        });
      }

    }
  });

  _exports.default = _default;
});