define("manage/mirage/config", ["exports", "shared/mirage/helpers/crud", "ember-inflector", "manage/config/environment"], function (_exports, _crud, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.testConfig = testConfig;

  function _default() {// shared between development + testing
  }

  function testConfig() {
    // testing only configuration
    this.passthrough(`${_environment.default.urls.api}/write-coverage`);
    this.passthrough(`/write-coverage`);
    this.post('https://auth.crowdox.test/sessions', function () {// do nothing
    });
    this.urlPrefix = _environment.default.urls.api;
    this.post('/bayeux', function () {// do nothing
    });
    this.namespace = '/api/v2/';
    this.get('/project-payment-gateways/redirect/:project_payment_type', function
      /* schema, request */
    () {
      return {
        redirect: 'https://example.com'
      };
    });

    let _this = this;

    this.post('companies/:id/authorize', function (schema, request) {
      let params = JSON.parse(request.requestBody); // If the password valid is passed in, update the database.

      if (params.password === 'valid') {
        let company = schema.db.companies.find(request.params.id);
        let authorizations = company.authorizations || {};
        authorizations[params.type] = 'authorized';
        schema.db.companies.update(request.params.id, {
          authorizations
        });
      }
    });
    this.post('companies/:id/update-card', function
      /* schema, request */
    () {// accept
    });
    this.post('users/accept', function
      /* db, request*/
    () {// Not much to do here as long as it returns a valid status
    }); // Project Apps is a view on the backend that will show
    // each app for every project. So we have to render a record if the app
    // itself exists.
    //
    // In this function we'll create the project-app record, mark it as not installed.

    this.get('project-apps', function (schema, request) {
      let filters = (0, _crud.getFilters)(request.queryParams);
      let appFilters = filters.reject(filter => ['project', 'installed'].indexOf(filter.key) >= 0);
      let apps = schema.apps.where(model => {
        return appFilters.every(filter => {
          if (Ember.isEmpty(filter.value)) {
            return true;
          }

          return model[filter.key] == filter.value;
        });
      });
      let project = schema.projects.find(request.queryParams['filter[project]']);

      for (let index = 0; index < apps.length; index++) {
        const app = apps.models[index];
        let projectApp = schema.projectApps.where(model => model.project == project && model.app == app);

        if (projectApp.length === 0) {
          schema.projectApps.create({
            projectId: project.id,
            appId: app.id,
            installed: false,
            app_slug: app.app_slug,
            type_slug: app.type_slug
          });
        }
      }

      return (0, _crud.getRequest)('project-apps', schema, request);
    });
    this.get('project-apps/:id');
    this.post('project-apps/:id/install', function (schema, request) {
      schema.db.projectApps.update(request.params.id, {
        installed: true
      });
    });

    _crud.COMMON_ENDPOINTS.concat([// Manage
    'app-email', 'app-setting', 'app-type', 'app', 'audited-order', 'category', 'companies-user', 'company-invoice', 'digital-assigned-download', 'digital-assigned-key', 'digital-download', 'digital-fulfillment', 'digital-fulfillment-provider', 'digital-key', 'export-processing-project-fulfillment-group', 'feature-flag', 'job', 'order-email', // 'pre-order-theme', // Fragment
    // 'product-dimensions', // Fragment
    // 'project-app', // See above we are using a custom function for it
    'project-email', 'project-estimated-product-sku-stat', 'project-estimated-product-stat', 'project-estimated-product-variation-stat', 'project-order-activity', 'project-order-configuration', 'project-order-location', 'project-order-stat', 'project-payment-gateway', 'project-product-sku-stat', 'project-product-stat', 'project-product-variation-stat', 'project-segment-action', 'project-segment', // 'project-settings-analytics', // Fragment
    // 'project-settings-wizard', // Fragment
    // 'project-settings', // Fragment
    // 'project-source-settings-email', // Fragment
    // 'project-source-settings-import', // Fragment
    // 'project-source-settings-wizard', // Fragment
    // 'project-source-settings', // Fragment
    'project-stats', // 'project-support', // Fragment
    'project-translations', // 'project-urls', // Fragment
    'report', 'user', 'holidays']).forEach(function (type) {
      let plural_type = (0, _emberInflector.pluralize)(type); // Adds standard CRUD operations

      _this.get(plural_type, function (schema, request) {
        return (0, _crud.getRequest)(plural_type, schema, request);
      });

      _this.get(`${plural_type}/:id`);

      _this.post(plural_type);

      _this.put(`${plural_type}/:id`);

      _this.patch(`${plural_type}/:id`);

      _this.delete(`${plural_type}/:id`);
    });
  }
});