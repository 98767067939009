define("manage/utils/product-mapping-order-selection-filter", ["exports", "shared/utils/product-mapping-order-selection-filter"], function (_exports, _productMappingOrderSelectionFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _productMappingOrderSelectionFilter.default;
    }
  });
});