define("manage/pods/companies/company/account/components/credit-card/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      const card = window.stripe.elements().create('card');
      card.mount((0, _jquery.default)('#card-element', this.element)[0]);
      this.get('payment').initializeCard(card);

      if (this.get('onCardElementChanged')) {
        this.get('onCardElementChanged')(card);
      }
    },

    submittedChanged: Ember.observer('payment.submitted', 'payment.cardComplete', function () {
      const el = (0, _jquery.default)('#card-element', this.element)[0];
      const submitted = this.get('payment.submitted');
      const complete = this.get('payment.cardComplete');

      if (submitted && !complete) {
        el.classList.add('invalid');
      } else {
        el.classList.remove('invalid');
      }
    }),
    actions: {
      onCountryChange(country) {
        this.get('payment').set('country', country);
        this.get('payment').set('state', null);
      }

    }
  });

  _exports.default = _default;
});