define("manage/pods/companies/company/projects/project/orders/components/create-order-modal/component", ["exports", "manage/mixins/ss-modal", "ember-cp-validations", "ember-concurrency", "manage/mixins/project-total", "@sentry/browser"], function (_exports, _ssModal, _emberCpValidations, _emberConcurrency, _projectTotal, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    customer: {
      description: "Customer",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    order: {
      description: "Order",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    order_line: {
      description: "Order Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    transaction: {
      description: "Order Transaction",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to', {
        disabled: Ember.computed('model.transaction.amount_cents', function () {
          if (this.get('model.transaction.amount_cents') == null || this.get('model.transaction.amount_cents') === 0) {
            return true;
          }

          return false;
        })
      })]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, Validations, {
    classNames: ['create-order-modal'],
    store: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    orderTasks: Ember.inject.service(),
    router: Ember.inject.service(),
    // Form bound properties
    email: '',
    // Passed in
    project: null,
    // Created and found
    order: null,
    customer: null,
    country: null,
    estimated_costs: null,
    transaction: null,
    isRunning: Ember.computed.or('resetTask.isRunning', 'changeSourceTask.isRunning', 'changeConfigurationTask.isRunning', 'saveOrderTask.isRunning', 'loadConfigurationRates.isRunning'),
    isIdle: Ember.computed.not('isRunning'),
    isRunningText: Ember.computed('resetTask.isRunning', 'changeSourceTask.isRunning', 'changeConfigurationTask.isRunning', 'saveOrderTask.isRunning', 'loadConfigurationRates.isRunning', function () {
      if (this.get('resetTask.isRunning')) {
        return "Initializing";
      }

      if (this.get('changeSourceTask.isRunning')) {
        return "Updating Order Source";
      }

      if (this.get('changeConfigurationTask.isRunning')) {
        return "Updating Order Configuration";
      }

      if (this.get('loadConfigurationRates.isRunning')) {
        return "Updating Shipping Rates";
      }

      if (this.get('saveOrderTask.isRunning')) {
        return "Saving Order";
      }

      return null;
    }),

    init() {
      this._super(...arguments);

      this.reset();
      Ember.run.scheduleOnce('afterRender', this, () => this.element.querySelector('input[type=text]').focus());
    },

    reset() {
      // Clear these out if they already exist
      this.set('email', '');
      this.set('submitted', false);
      this.set('customer', null);
      this.set('order', null);
      this.set('country', null);
      this.set('estimated_costs', null);
      this.set('transaction', null); // Create the customer

      this.createOrUpdateCustomer(); // Create the order

      let store = this.get('store');
      let order = store.createRecord('order', {
        project: this.get('project'),
        source: this.get('project.sources').filterBy('is_external', true).get('firstObject')
      });
      this.set('order', order); // Initialize

      this.get('resetTask').perform(order);
    },

    resetTask: (0, _emberConcurrency.task)(function* (order) {
      order = yield order;
      let store = this.get('store'); // Create the address

      let address = yield this.get('orderTasks.generateOrderShippingAddress').perform(order, store.peekAll('country').findBy('iso3', 'USA'));
      order.set('shipping_address', address);
      address.set('country_only', true);
      this.set('address', address); // Create the reward line

      let line = yield this.get('orderTasks.generateOrderLine').perform(order, 'reward', null // product_bundle
      );
      line.set('skip_order_selections', true);
      this.set('order_line', line); // Create the transaction

      let transaction = store.createRecord('order-transaction', {
        provider: 'Manual',
        project: this.get('project'),
        order: order,
        amount_cents: 0,
        currency: this.get('project.currency'),
        shipping_amount_cents: 0,
        is_refund: false,
        confirmed: true
      });
      this.set('transaction', transaction);
      yield this.get('searchForCustomerTask').perform(this.get('project'));
    }),
    filtered_configurations: Ember.computed('order.source.id', 'project.configurations_sorted.@each.source', function () {
      if (!this.get('project.configurations_sorted')) {
        return [];
      }

      return this.get('project.configurations_sorted').filter(item => {
        return item.get('source.id') === this.get('order.source.id');
      });
    }),
    fullNameDisabled: Ember.computed('searchForCustomerTask.isRunning', 'customer.isNew', function () {
      return this.get('searchForCustomerTask.isRunning') || this.get('customer.isNew') === false;
    }),

    createOrUpdateCustomer(customer) {
      if (this.get('customer.isNew')) {
        this.get('customer').deleteRecord();
      }

      if (customer) {
        this.set('customer', customer);
      } else {
        this.set('customer', this.get('store').createRecord('customer', {
          email: this.get('email'),
          project: this.get('project')
        }));
      }

      if (Ember.isPresent(this.get('order'))) {
        this.set('order.customer', this.get('customer'));
      }
    },

    searchForCustomerTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      let store = this.get('store');
      let filter = {
        filter: {
          email: this.get('email'),
          project: project.get('id')
        }
      };
      let data = yield store.query('customer', filter);

      if (data.get('length') === 1) {
        let customer = data.objectAt(0);
        this.createOrUpdateCustomer(customer);
      } else {
        this.createOrUpdateCustomer();
      }
    }),
    changeSourceTask: (0, _emberConcurrency.task)(function* (order, source) {
      order = yield order;
      source = yield source; // Update the source on the order

      order.set('source', source); // Clear out the configuration references

      order.set('current_configuration', null);
      order.set('original_configuration', null); // Clear out the reward line references

      let lines = yield order.get('reward_lines');
      let line = lines.objectAt(0);
      line.set('product_bundle', null);
    }),
    changeConfigurationTask: (0, _emberConcurrency.task)(function* (order, configuration) {
      order = yield order;
      configuration = yield configuration;
      let product_reward = yield configuration.get('product_reward'); // Update the configurations

      order.set('current_configuration', configuration);
      order.set('original_configuration', configuration); // Set the reward line references

      let lines = yield order.get('reward_lines');
      let line = lines.objectAt(0);
      line.set('product_bundle', product_reward);
      yield this.get('loadConfigurationRates').perform();
    }),
    loadConfigurationRates: (0, _emberConcurrency.task)(function* () {
      let order = yield this.get('order');
      let configuration = yield order.get('current_configuration');

      if (Ember.isBlank(configuration)) {
        return;
      }

      let product_reward = yield configuration.get('product_reward');
      let estimated_costs = 0;
      estimated_costs += product_reward.get('price'); // load location rates

      yield this.get('store').findRecord('product', product_reward.get('id'), {
        include: ['zones', 'zones.locations'].join(',')
      });
      let country = yield this.get('country');

      if (Ember.isPresent(country)) {
        yield product_reward.get('zone_locations');
        let zone_location = (0, _projectTotal.find_zone_location)(product_reward, 'shipping_product_based_zone_locations', country);

        if (zone_location != null) {
          estimated_costs += zone_location.get('shipping') || 0;
        } else {
          // If country doesn't have a rate, clear it.
          this.set('country', null);
        }
      }

      this.set('estimated_costs', estimated_costs);
    }),
    saveOrderTask: (0, _emberConcurrency.task)(function* (customer, order, address, transaction, country) {
      customer = yield customer;
      order = yield order;
      address = yield address;
      transaction = yield transaction;
      country = yield country;
      yield customer.save(); // Save the customer

      if (Ember.isPresent(country)) {
        address.set('country', country);
      }

      yield this.get('orderTasks.save').perform(order, {
        validate: false,
        shipping_address_country_only: true
      }); // Save the transaction if it has a positive balance

      if (transaction.get('amount') > 0) {
        // Make sure we set the right provider
        // We need to update the controller to allow provider creation
        // if (order.get('source.site') === 'crowdox') {
        transaction.set('provider', 'Manual'); // } else {
        //   transaction.set('provider', Ember.String.capitalize(order.get('source.site')));
        // }

        yield transaction.save();
      } else {
        transaction.deleteRecord();
      }

      return order;
    }),

    closing() {
      var order = this.get('order');
      var address = this.get('address');
      var line = this.get('order_line');
      var customer = this.get('customer');
      var transaction = this.get('transaction'); // We aren't handling relationship dirty tracking here since these are
      // all new or just saved.

      if (transaction && transaction.get('hasDirtyAttributes')) {
        transaction.rollbackAttributes();
      }

      if (order && order.get('hasDirtyAttributes')) {
        order.rollbackAttributes();
      }

      if (address && address.get('hasDirtyAttributes')) {
        address.rollbackAttributes();
      }

      if (line && line.get('hasDirtyAttributes')) {
        line.rollbackAttributes();
      }

      if (customer && customer.get('hasDirtyAttributes')) {
        customer.rollbackAttributes();
      }
    },

    actions: {
      update_country(country) {
        this.set('country', country);
        this.get('loadConfigurationRates').perform();
      },

      approve(hide) {
        if (this.get('isRunning')) {
          return false;
        }

        this.set('submitted', true);
        return this.validate().then(result => {
          if (result.validations.get('isInvalid')) {
            return false;
          }

          this.get('saveOrderTask').perform(this.get('customer'), this.get('order'), this.get('address'), this.get('transaction'), this.get('country')).then(order => {
            let order_id = order.get('id');

            if (hide) {
              this.router.transitionTo('companies.company.projects.project.orders.view', order_id);
              this.closeModal();
            } else {
              this.reset();
            }
          }).catch(error => {
            Sentry.captureException(error, {
              extra: {
                email: this.get('email')
              }
            });
            alert('There was an unknown error when creating the order. It has been reported.');
          });
        });
      }

    }
  });

  _exports.default = _default;
});