define("manage/pods/companies/company/projects/project/orders/view/components/rectify-currency-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LRezijsU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"header\"],null],false],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui form basic content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"content.description\"],[[\"transaction_currency\",\"project_currency\"],[[24,[\"transaction\",\"currency\"]],[24,[\"transaction\",\"project\",\"currency\"]]]]],false],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"content.description2\"],[[\"project_currency\"],[[24,[\"transaction\",\"project\",\"currency\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"edit-field\",null,[[\"model\",\"propertyName\",\"fieldType\",\"currencySymbol\",\"currency\",\"submitted\"],[[23,0,[]],\"amount\",\"currency\",[24,[\"transaction\",\"project\",\"currency_symbol\"]],[24,[\"transaction\",\"project\",\"currency\"]],[24,[\"saveTask\",\"isRunning\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",\"ui button left floated\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.close\"],null],false],[9],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",[29,[\"ui green button \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"loading\"],null]]]],[3,\"action\",[[23,0,[]],\"save\"]],[8],[1,[28,\"t\",[\"phrase.save\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/rectify-currency-modal/template.hbs"
    }
  });

  _exports.default = _default;
});