define("manage/pods/companies/company/projects/project/theme/components/theme-layout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['theme-layout', 'component'],
    classNameBindings: ['size'],
    size: 'desktop'
  });

  _exports.default = _default;
});