define("manage/pods/companies/company/projects/project/orders/view/components/order-tracking-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUYsacDi",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui message\"],[8],[0,\"\\n  These are the parameters that were recorded at the time the order was created.\\n  They can be used to identify and investigate who referred the customer to Crowd Ox.\\n\"],[9],[0,\"\\n\\n\"],[7,\"table\",true],[10,\"class\",\"ui stripped table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"tracking_parameter.parameter.sub_type.title\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"tracking_parameter.parameter.value.title\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"tracking_parameter.parameter_value.value.title\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[[28,\"concat\",[\"tracking_parameter.parameter.sub_type.\",[23,1,[\"parameter\",\"sub_type\"]],\".title\"],null]],null],false],[0,\"\\n          \"],[7,\"span\",true],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"right center\"],[11,\"data-tooltip\",[28,\"t\",[[28,\"concat\",[\"tracking_parameter.parameter.sub_type.\",[23,1,[\"parameter\",\"sub_type\"]],\".tooltip\"],null]],[[\"source\"],[[24,[\"order\",\"source\",\"friendly_name\"]]]]]],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"circle help icon\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"parameter\",\"value\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"parameter_value\",\"value\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/order-tracking-data/template.hbs"
    }
  });

  _exports.default = _default;
});