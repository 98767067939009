define("manage/pods/companies/company/projects/project/orders/components/view-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W+A0YsIG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Filtering By\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"style\",\"word-wrap: break-word;\"],[8],[0,\"\\n  \"],[1,[28,\"order-export-name\",null,[[\"project\",\"filter_data\"],[[24,[\"project\"]],[24,[\"filter_data\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[24,[\"count\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"count\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n      \"],[7,\"em\",true],[8],[0,\"Found \"],[7,\"b\",true],[8],[1,[28,\"format-number\",[[24,[\"count\"]]],null],false],[9],[0,\" \"],[1,[28,\"pluralize\",[[24,[\"count\"]],\"order\"],[[\"without-count\"],[true]]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/components/view-filter/template.hbs"
    }
  });

  _exports.default = _default;
});