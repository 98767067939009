define("manage/pods/companies/company/projects/project/products/index/components/column-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "obDW2hf2",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"product\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"data-tooltip\",[28,\"strip-tags\",[[24,[\"product\",\"description\"]]],null]],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"medium\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon link info letter large\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"product\",\"imported_by\"]]],null,{\"statements\":[[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"imported-by\"],[8],[0,\"\\n    \"],[7,\"b\",true],[8],[0,\"Imported By:\"],[9],[0,\" \"],[1,[24,[\"product\",\"imported_by\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/index/components/column-name/template.hbs"
    }
  });

  _exports.default = _default;
});