define("manage/components/ss-radio", ["exports", "ember-promise-tools/utils/is-promise", "ember-promise-tools/utils/is-fulfilled", "ember-promise-tools/utils/get-promise-content", "ember-promise-tools/mixins/promise-resolver"], function (_exports, _isPromise, _isFulfilled, _getPromiseContent, _promiseResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_promiseResolver.default, {
    classNames: ['ui', 'checkbox', 'radio'],
    classNameBindings: ['checked:checked', 'readonly:read-only', 'disabled:disabled'],

    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('name'))) {
        this.set('name', 'default');

        if (window.console != null && window.console.warn != null) {
          window.console.warn("The required component parameter of 'name' was not passed into the ss-radio component");
        }
      }
    },

    checked: Ember.computed('value', 'current', function () {
      let value = this._getValue('value');

      let current = this._getValue('current');

      if (value === current) {
        return true;
      }

      this._scheduleValue(value, 'value');

      this._scheduleValue(current, 'current');

      return false;
    }),

    click() {
      if (this.get('disabled') || this.get('readonly')) {
        return;
      }

      let action = this.get('onChange');

      if (typeof action === 'function') {
        let value = this.get('value');
        action(value);
      }
    },

    _getValue(name) {
      let value = this.get(name);

      if ((0, _isPromise.default)(value) && (0, _isFulfilled.default)(value)) {
        return (0, _getPromiseContent.default)(value);
      }

      return value;
    },

    _scheduleValue(value, name) {
      if ((0, _isPromise.default)(value)) {
        if (!(0, _isFulfilled.default)(value)) {
          return this.resolvePromise(value, () => this.notifyPropertyChange(name));
        }
      }

      return true;
    }

  });

  _exports.default = _default;
});