define("manage/pods/companies/company/projects/project/digital/components/edit-download-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/models/digital-download", "jquery"], function (_exports, _ssModal, _emberConcurrency, _digitalDownload, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    isRunning: Ember.computed.alias('saveTask.isRunning'),

    created() {},

    valid_filename_extension: Ember.computed('filename', function () {
      let filename = this.get('filename');

      if (Ember.isEmpty(filename)) {
        return true;
      }

      let match = /[^.]+$/.exec(filename);

      if (match == null) {
        return false;
      } else {
        return _digitalDownload.AllowedExtensions.includes(match[0].toLowerCase());
      }
    }),
    saveTask: (0, _emberConcurrency.task)(function* (download) {
      let input = (0, _jquery.default)("[name=attachment]", this.element).get(0);
      let files = input.files;
      var file = null; // Sanity checking for attachments before saving the download

      if (files.length) {
        file = files[0];

        if (file.size <= 0) {
          throw new Error('Unable to upload an empty file');
        } else if (file.size > 419430400) {
          throw new Error('File size cannot be greater than 400 MB');
        }

        let reader = new FileReader();
        reader.readAsArrayBuffer(file);

        while (reader.readyState === 1) {
          // loading
          yield (0, _emberConcurrency.timeout)(500);
        }
      } else if (download.get('isNew')) {
        throw new Error('Unable to create download without a file');
      }

      this.set('file-error', null);
      var creatingNewDownload = download.get('isNew');
      download = yield download;
      yield download.save();

      if (file != null) {
        let form_data = new FormData();
        form_data.append('attachment', file);
        yield this.get('ajax').post(download.get('upload_url'), {
          processData: false,
          contentType: false,
          data: form_data
        }).catch(error => {
          if (creatingNewDownload) {
            // If the attachment save was unsuccessful and the record was just created, delete the newly created record.
            download.deleteRecord();
            download.save();

            if (error.payload && Ember.isPresent(error.payload.errors)) {
              throw new Error(error.payload.errors.map(error => error.detail || error.title).join(', '));
            } else {
              throw new Error(error.message);
            }
          }
        });
        yield download.reload();
        (0, _jquery.default)("[name=attachment]", this.element).val("");
        this.get('created')();
      }
    }),

    closing() {
      if (this.get('download.isNew')) {
        this.get('download').rollbackAttributes();
      }
    },

    actions: {
      close() {
        this.closeModal();
      },

      approve() {
        if (this.get("download.validations.isInvalid")) {
          return;
        }

        this.get('saveTask').perform(this.get('download')).then(() => this.closeModal()).catch(error => {
          this.set('file-error', error.message);
        });
      },

      change_file(event) {
        this.set('file-error', null);
        let file = event.target.files[0].name;
        this.set('filename', file);

        if (Ember.isEmpty(this.get('download.name'))) {
          this.set('download.name', file.split(/(\\|\/)/g).pop());
        }
      }

    }
  });

  _exports.default = _default;
});