define("manage/pods/companies/company/projects/project/stats/submenu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "503wl3aZ",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"companies.company.projects.project.stats.transactions\"]],{\"statements\":[[0,\"\\n  Transactions\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/stats/submenu/template.hbs"
    }
  });

  _exports.default = _default;
});