define("manage/mixins/filters/product-bundles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filter_product_bundles: Ember.computed('project.products.@each.{is_bundle,name}', function () {
      return this.get('project.products').filterBy('is_bundle', true).sortBy('name');
    })
  });

  _exports.default = _default;
});