define("manage/services/product-tasks", ["exports", "ember-concurrency", "shared/tasks/save-task"], function (_exports, _emberConcurrency, _saveTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_saveTask.default, {
    store: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* (product) {
      product = yield product; // Do we need to validate everything?

      let {
        validations: validations
      } = yield product.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      let wasNew = false;

      if (product.get('isNew')) {
        // If its new, we need to save it before doing anything else
        wasNew = true; // We need to save again below for default trait

        yield product.save();
      } // Check variation lengths
      // Only changed variations are updated.


      yield this.get('processVariations').perform(product);
      let mapping_operations = [// Need to save traits in this order
      {
        name: 'mappings'
      }];
      let variation_operations = [// Need to save variations in this order
      {
        name: 'variants'
      }, {
        name: 'variations'
      }]; // Since locations are unique, if we have any that are deleted, remove all those before trying to save
      // Otherwise, we could try to save a new location before deleting the old one with the same country

      let deleteTasks = [this.get('_saveInOrder').perform(product, [{
        name: 'zones'
      }], true)];
      let deletedResults = yield (0, _emberConcurrency.allSettled)(deleteTasks); // If any errors were returned, throw the first one to stop the progress

      let deleteErrors = deletedResults.filter(result => result.state !== 'fulfilled').mapBy('reason'); // if there was an error somewhere, throw it, so it goes to catch instead of then

      if (deleteErrors.length > 0) {
        throw deleteErrors[0];
      } // _saveInOrder calls _saveCollection which automatically saves nested has many relations


      let tasks = [this.get('_saveInOrder').perform(product, mapping_operations), this.get('_saveInOrder').perform(product, variation_operations), // No group, save in any order, reusing the same helper for convenience
      this.get('_saveInOrder').perform(product, [{
        name: 'zones'
      }]), this.get('_saveInOrder').perform(product, [{
        name: 'questions'
      }])];
      let results = yield (0, _emberConcurrency.allSettled)(tasks); // Attempt to save any remaining order data

      if (wasNew || product.get('isDirty')) {
        yield product.save();
      } // If any errors were returned, throw the first one to stop the progress


      let errors = results.filter(result => result.state !== 'fulfilled').mapBy('reason'); // if there was an error somewhere, throw it, so it goes to catch instead of then

      if (errors.length > 0) {
        throw errors[0];
      }
    }),
    rollback: (0, _emberConcurrency.task)(function* (product) {
      product = yield product;
      let relations = ['mappings', 'variations', ['variants', 'values'], ['zones', 'locations'], ['questions', 'choices']];

      for (let relation of relations) {
        let [name, child] = Ember.A(Ember.isArray(relation) ? relation : [relation]);
        yield this.get('_rollbackCollection').perform(product, name, child);
      }

      product.rollbackAttributes();
      product.rollbackRelationships();
    }),
    isDirty: (0, _emberConcurrency.task)(function* (product) {
      product = yield product;

      if (product.get('isDirty')) {
        return true;
      }

      let relations = ['mappings', 'variations', ['variants', 'values'], ['zones', 'locations'], ['questions', 'choices']];

      for (let relation of relations) {
        let [name, child] = Ember.A(Ember.isArray(relation) ? relation : [relation]);
        let isDirty = yield this.get('_isCollectionDirtry').perform(product, name, child);

        if (isDirty) {
          return true; // If we hit anything thats dirty return
        }
      }

      return false;
    }),
    delete: (0, _emberConcurrency.task)(function* (product) {
      product = yield product; // Get a reference to all hasMany relationships

      let promises = yield Ember.RSVP.all([product.get('downloads'), product.get('zone_locations'), product.get('zones'), product.get('mappings'), product.get('question_choices'), product.get('questions'), product.get('variant_values'), product.get('variants'), product.get('variations')]); // Make sure we get them in an array (otherwise they won't return after the save)

      let destroyed = [].concat.apply([], promises.map(relationship => relationship.toArray())); // Now destroy

      product.deleteRecord();

      try {
        yield product.save();
      } catch (e) {
        // Revert the change
        product.rollbackAttributes(); // Notify the user, backend is only exposing friendly messages

        throw new Error(e.errors[0].detail || e.errors[0].title);
      }

      for (let i = 0; i < destroyed.length; i++) {
        destroyed[i].unloadRecord();
      }
    }),
    create: (0, _emberConcurrency.task)(function* (project) {
      let product_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      project = yield project;

      if (Ember.isBlank(project)) {
        throw new Error("You must pass in a project");
      }

      if (Ember.isBlank(product_data)) {
        product_data = {};
      }

      product_data = Object.assign({
        project: project
      }, product_data);
      let product = this.get('store').createRecord('product', product_data); // Create preliminary variation or ensure updated variations

      yield this.get('processVariations').perform(product);
      return product;
    }),
    createQuestion: (0, _emberConcurrency.task)(function* (project, product, type) {
      let required = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      project = yield project;
      product = yield product;

      if (Ember.isBlank(project)) {
        throw new Error("You must pass in a project");
      }

      if (Ember.isBlank(type)) {
        throw new Error("You must specifc question type");
      } // This is optional


      if (Ember.isBlank(product)) {
        // If we don't pass in a product, then create a new question type
        product = this.get('store').createRecord('product', {
          project: project,
          product_type: 'question'
        });
      }

      let questions = yield product.get('questions');
      let sort_orders = questions.filterBy('isDeleted', false).mapBy('sort_order');
      sort_orders.push(0);
      let sort_order = Math.max(...sort_orders) + 1;
      let question = this.get('store').createRecord('product-question', {
        project: project,
        product: product,
        required: required,
        question_type: type,
        sort_order: sort_order
      });

      if (type === 'text') {
        question.set('sub_type', 'any');
      }

      if (type === 'multiple') {
        question.set('sub_type', 'single');
        question.set('quantity', 1); // Create two question choices

        yield this.get('createQuestionChoice').perform(question);
        yield this.get('createQuestionChoice').perform(question);
      }

      return question;
    }),
    createQuestionChoice: (0, _emberConcurrency.task)(function* (question) {
      question = yield question;
      let project = yield question.get('project');
      let product = yield question.get('product');
      let choices = yield question.get('choices');
      let sort_orders = choices.filterBy('isDeleted', false).mapBy('sort_order');
      sort_orders.push(0);
      let sort_order = Math.max(...sort_orders) + 1;
      return this.get('store').createRecord('product-question-choice', {
        project: project,
        product: product,
        question: question,
        sort_order: sort_order
      });
    }),
    deleteQuestion: (0, _emberConcurrency.task)(function* (question) {
      question = yield question;
      let choices = yield question.get('choices');
      choices = choices.toArray();
      question.deleteRecord();

      try {
        yield question.save();
      } catch (e) {
        // Revert the change
        question.rollbackAttributes(); // Throw an error

        throw new Error(e.errors[0].detail || e.errors[0].title);
      } // Not sure if this is necessary since the relationship no longer
      // have anything after this is done (which is why we need the toArray)


      for (let question_choice of choices) {
        question_choice.unloadRecord();
      }
    }),
    deleteQuestionChoice: (0, _emberConcurrency.task)(function* (question_choice) {
      question_choice = yield question_choice;
      question_choice.deleteRecord();

      try {
        yield question_choice.save();
      } catch (e) {
        // Revert the change
        question_choice.rollbackAttributes(); // Throw an error

        throw new Error(e.errors[0].detail || e.errors[0].title);
      }
    }),
    deleteVariant: (0, _emberConcurrency.task)(function* (product, variant) {
      product = yield product;
      let wasNew = variant.get('isNew');
      variant.deleteRecord();
      let variant_values = yield variant.get('values');
      variant_values = variant_values.toArray();

      try {
        if (!wasNew) {
          yield variant.save();
        } // Not sure if this is necessary since the relationship no longer
        // have anything after this is done (which is why we need the toArray)


        for (let variant_value of variant_values) {
          variant_value.unloadRecord();
        } // Re-create variations


        yield this.get('processVariations').perform(product); // Now save variations, also have to save variants to ensure nothing is missing

        if (!wasNew) {
          yield this.get('_saveInOrder').perform(product, [{
            name: 'variants'
          }]);
          yield this.get('_saveInOrder').perform(product, [{
            name: 'variations'
          }]);
        }
      } catch (e) {
        // Revert the change
        variant.rollbackAttributes(); // Throw an error

        throw new Error(e.errors[0].detail || e.errors[0].title);
      }
    }),
    deleteVariantValue: (0, _emberConcurrency.task)(function* (product, variant_value) {
      product = yield product;
      let wasNew = variant_value.get('isNew');
      variant_value.deleteRecord();

      try {
        if (!wasNew) {
          yield variant_value.save();
        } // Re-create variations


        yield this.get('processVariations').perform(product); // Now save variations

        if (!wasNew) {
          yield this.get('_saveInOrder').perform(product, [{
            name: 'variants'
          }]);
          yield this.get('_saveInOrder').perform(product, [{
            name: 'variations'
          }]);
        }
      } catch (e) {
        // Revert the change
        variant_value.rollbackAttributes(); // Throw an error

        throw new Error(e.errors[0].detail || e.errors[0].title);
      }
    }),
    // Variation processing
    processVariations: (0, _emberConcurrency.task)(function* (product) {
      // Variations only exist for these types
      if (['physical', 'digital', 'shipping'].includes(product.product_type) === false) {
        return;
      } // Null always comes after an id


      let idSort = function (a, b) {
        let aId = a.get('id') == null ? Number.MAX_VALUE : a.get('id');
        let bId = b.get('id') == null ? Number.MAX_VALUE : b.get('id');
        return aId - bId;
      };

      product = yield product;
      let variants = yield product.get('variants'); // Sort by their order in the modal

      variants = variants.filterBy('isDeleted', false).toArray().sortBy('sort_order');
      let variations = yield product.get('variations'); // Use ones with ids first

      variations = variations.filterBy('isDeleted', false).toArray().sort(idSort);
      let preliminary_variation = variations.findBy('has_variants', false);

      if (preliminary_variation != null) {
        variations.removeObject(preliminary_variation);
      } // Mark all the variations and details as not used for delete tracking later.


      variations.setEach('_used_', false);
      let variant_value_arrays = Ember.A(); /////////////////////
      // Get all the values
      /////////////////////

      let has_at_least_one = false; // I need to manually sort and order so that the combinations always have a unique number.
      // Otherwise there is a situation where we can have a the same sort order and its unpredictable

      let internal_sort = 1;

      for (let variant of variants) {
        variant.set('_internal_sort_order_', internal_sort++);
        let variant_values = yield variant.get('values'); // Sort by their order in the modal

        variant_values = variant_values.filterBy('isDeleted', false).toArray().sort(function (a, b) {
          if (a.get('is_default') === b.get('is_default')) {
            return a.get('sort_order') - b.get('sort_order');
          }

          if (a.get('is_default')) {
            return -1; // true comes first
          }

          return 1; // false comes last
        }); // Manuall change sort to look at default first then the rest

        if (variant_values.length > 0) {
          has_at_least_one = true;
        }

        variant_value_arrays.push(variant_values);
      }

      if (has_at_least_one === false) {
        // Pull the preliminary back from default if only one has an id, use that
        /////////////////////
        // We don't have any variations, make sure preliminary is created and return
        /////////////////////
        if (preliminary_variation == null) {
          let variation = variations.findBy('is_default', true) || variations.sort(idSort).find(v => v.get('id') != null);

          if (variation != null) {
            variations.removeObject(variation); // Reset properties

            let details_data = this.get('store').createFragment('product-details');
            variation.setProperties({
              enabled: true,
              is_default: true,
              has_variants: false,
              sku: null,
              details_data: details_data
            });
          } else {
            this.get('store').createRecord('product-variation', {
              project: product.get('project'),
              product: product,
              enabled: true,
              is_default: true,
              has_variants: false
            });
          }
        } /////////////////////
        // Remove any other variations
        /////////////////////


        for (let variation of variations) {
          variation.deleteRecord();
        }

        return;
      } /////////////////////
      // generate all the combinations
      /////////////////////


      let new_variations = Ember.A();
      new_variations = variant_value_arrays.reduce((a, b) => {
        let combinations = Ember.A();
        a.forEach(item_a => {
          b.forEach(item_b => {
            combinations.push(item_a.concat([item_b]));
          });
        });
        return combinations;
      }, [[]]); /////////////////////
      // Generate objects for each for tracking
      /////////////////////

      new_variations = new_variations.map(variant_values => {
        return {
          variant_values: variant_values,
          variation: null,
          matched_quantity: 0,
          detail_quantity: 0,
          // We take the sum of the variant (sort_order) + variant values (sort_order) and that is the sort order
          // Lowest sort order will be the priority or default to keep
          sort_order: variant_values.reduce((total, variant_value) => {
            return total + variant_value.get('_internal_sort_order_');
          }, 0)
        };
      }); // Now that we have all the variations generated, try to match to the existing variations
      // Try to get best variation match
      // If we have 3 variations, try to match on 3 first
      // then try to match on 2, then 1
      // We match based on quality and not variations, since we want to force highest match first.
      // If we flipped it around and matched on variations and then quantity looped inside, we might
      // Get a match that wasn't the highest first.
      // If short loop through all variations on highest quality, if that doesn't match then attempt next quality and so on

      let match_quality = variants.length;
      let quality_matches = Ember.A();

      for (let quality = match_quality; quality > 0; quality--) {
        quality_matches.push(quality);
      }

      for (let quality of quality_matches) {
        // Only go to the next quality level once all current new variations and variations have been checked
        // Get all "new variations" without a variation assigned
        let remaining_new_variations = new_variations.rejectBy('variation');

        for (let new_variation of remaining_new_variations) {
          // Perform variation quantity checking
          // Loop through any existing variations
          let remaining_variations = variations.rejectBy('_used_');

          for (let variation of remaining_variations) {
            let variation_details = variation.get('details').toArray();
            let matched_quantity = 0;

            for (let variation_detail of variation_details) {
              let variant_value = yield variation_detail.get('variant_value');

              if (new_variation.variant_values.includes(variant_value)) {
                matched_quantity++;
              }
            } // We have the required match quality, then mark as used


            if (matched_quantity === quality) {
              // We have to reloop through details
              new_variation.variation = variation;
              new_variation.matched_quantity = matched_quantity;
              new_variation.detail_quantity = variation_details.length;
              variation.set('_used_', true);
              break;
            }
          }
        }
      } /////////////////////
      // Now that we've matched everything above we can, sort by sort order
      // Mark the first one as the preliminary if we have it.
      /////////////////////


      let used_order = 0; // Used for tracking in tests

      new_variations = new_variations.sortBy('sort_order');

      if (preliminary_variation != null) {
        let new_variation = new_variations[0];

        if (new_variation.variation != null) {
          throw "Unable to have existing variations with a preliminary variation";
        } // Reset preliminary variation to new variation


        new_variation.variation = preliminary_variation;
        new_variation.variation.setProperties({
          enabled: true,
          is_default: true,
          has_variants: true,
          sku: null // Clear the sku, so it calculates with default

        });
        new_variation.variation.set('_used_', true);
      } /////////////////////
      // Now create any missing variations and details
      /////////////////////


      for (let new_variation of new_variations) {
        // If we have 100% match on quality, then continue.
        // No changes need to be made
        // We only take this step so we don't have to save variations every time
        if (match_quality === new_variation.matched_quantity && new_variation.matched_quantity === new_variation.detail_quantity) {
          new_variation.variation.set('_used_order_', used_order++);
          continue;
        } // Create variation if it was never found


        if (new_variation.variation == null) {
          // If we don't have a variation, then create it
          new_variation.variation = this.get('store').createRecord('product-variation', {
            project: product.get('project'),
            product: product,
            enabled: true,
            is_default: false,
            has_variants: true
          });
          new_variation.variation.set('_used_', true);
        }

        new_variation.variation.set('_used_order_', used_order++); // If we hit this point, then we either didn't have a variation,
        // or we have an invalid match.
        // Replace details today since it gets in a weird delete state
        // Then recreate all the fragments

        let details_data = this.get('store').createFragment('product-details');
        new_variation.variation.set('details_data', details_data);

        for (let variant_value of new_variation.variant_values) {
          let variant = yield variant_value.get('variant');
          details_data.get('details').createFragment({
            _variant: variant,
            variant_id: variant.get('id'),
            _variant_value: variant_value,
            variant_value_id: variant_value.get('id')
          });
        }
      } /////////////////////
      // Finally remove any we didn't use
      /////////////////////


      for (let variation of variations) {
        if (!variation.get('_used_')) {
          variation.deleteRecord();
        }
      } // All done return

    }),
    deleteLocations: (0, _emberConcurrency.task)(function* (product) {
      product = yield product; // Delete all existing locations

      let zones = yield product.get('zones');

      for (let zone of zones.filterBy('isDeleted', false).toArray()) {
        let locations = yield zone.get('locations');

        for (let zone_location of locations.filterBy('isDeleted', false).toArray()) {
          zone_location.deleteRecord();
        }

        zone.deleteRecord();
      }
    }),
    cloneLocations: (0, _emberConcurrency.task)(function* (product, from_product) {
      product = yield product;
      let project = yield product.get('project');
      from_product = yield from_product;
      yield this.get('deleteLocations').perform(product); // Get all existing locations

      let from_zones = yield from_product.get('zones');

      for (let from_zone of from_zones.filterBy('isDeleted', false).toArray()) {
        // Clone
        let zone = this.get('store').createRecord('product-zone', {
          project: project,
          product: product,
          shipping_cents: from_zone.get('shipping_cents'),
          name: from_zone.get('name'),
          location_type: from_zone.get('location_type'),
          cost_type: from_zone.get('cost_type')
        });
        let from_zone_locations = yield from_zone.get('locations');

        for (let from_zone_location of from_zone_locations.filterBy('isDeleted', false).toArray()) {
          this.get('store').createRecord('product-zone-location', {
            project: project,
            product: product,
            zone: zone,
            country: from_zone_location.get('country'),
            state: from_zone_location.get('state'),
            cost_type: from_zone_location.get('cost_type')
          });
        }
      }
    })
  });

  _exports.default = _default;
});