define("manage/models/project-source-settings-import", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    confirm_days_after: {
      description: "Confirm Days After",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        allowNone: false,
        integer: true,
        gte: 0,
        lte: 30
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    confirm_days_after: (0, _model.attr)('number')
  });

  _exports.default = _default;
});