define("manage/mixins/models/product/question", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    choices: {
      description: "Total Choices",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
        min: 2,
        disabled: Ember.computed.not('model.is_multiple')
      })]
    },
    sort_order: {
      description: "Sort Order",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        gte: 0
      })]
    },
    text: {
      description: "Text",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 150
      })]
    },
    description: {
      description: "Description",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 500
      })]
    },
    question_type: {
      description: "Question Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['text', 'multiple']
      })]
    },
    sub_type: {
      description: "Sub Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: Ember.computed('model.is_text', 'model.is_multiple', function () {
          if (this.get('model.is_text')) {
            return ['any', 'email', 'phone', 'numeric', 'money'];
          }

          if (this.get('model.is_multiple')) {
            return ['single', 'quantity', 'select'];
          }

          return [];
        })
      })]
    },
    required: {
      description: "Required",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    },
    min_length: {
      description: "Min Length",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gte: 0,
        lte: 20
      })]
    },
    max_length: {
      description: "Max Length",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gt: 1,
        lte: 2500
      })]
    },
    quantity: {
      description: "Quantity",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gte: 1,
        lte: 50
      })]
    }
  });

  _exports.default = _default;
});