define("manage/pods/companies/company/projects/project/orders/index/components/column-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kBel9zHO",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"order\",\"customer\",\"email\"]],false],[0,\" \"],[1,[28,\"icon-email-status\",null,[[\"status\",\"prefix\"],[[24,[\"order\",\"customer\",\"last_email_status\"]],\"last \"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/index/components/column-email/template.hbs"
    }
  });

  _exports.default = _default;
});