define("manage/pods/companies/company/projects/project/digital/view/keys/route", ["exports", "manage/pods/companies/company/projects/project/digital/view/keys/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    ajax: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      sortProperty: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      provider: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let fulfillment = this.modelFor('companies.company.projects.project.digital.view');
      let page = params.page || 1;
      let page_size = params.page_size || 25;
      let search = params.search;
      let status = params.status;
      return Ember.RSVP.hash({
        project: project,
        fulfillment: fulfillment,
        fulfillment_providers: this.store.query('digital-fulfillment-provider', {
          filter: {
            fulfillment: fulfillment.id
          }
        }),
        keys: this.store.query('digital-key', {
          include: ['assigned', 'assigned.order'].join(','),
          filter: {
            fulfillment: fulfillment.id,
            provider_slug: params.provider,
            search: search,
            status: status
          },
          page: {
            number: page,
            size: page_size
          }
        })
      });
    }

  });

  _exports.default = _default;
});