define("manage/components/project-embedded-download/component", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      this._super(...arguments);

      this.set('options', [{
        extension: 'csv',
        title: 'CSV',
        icon: 'file csv outline'
      }, {
        extension: 'excel',
        title: 'Excel',
        icon: 'file excel outline'
      }]);
    },

    filterString: Ember.computed('filters', function () {
      let queryString = '';
      let hash = this.get('filters');
      Object.keys(hash).forEach(key => {
        let value = hash[key];
        queryString += `filters[${key}]=${value}&`;
      });
      queryString = queryString.slice(0, -1);
      return queryString;
    }),
    actions: {
      download_file(option) {
        window.location = `${_environment.default.urls.api}/export/project?project_id=${this.get('project.id')}&format_type=${option.extension}&export_type=${this.get('export_type')}&token=${this.get('token')}&${this.get('filterString')}`;
      }

    }
  });

  _exports.default = _default;
});