define("manage/pods/companies/company/projects/project/products/components/generate-variation-skus-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['generate-variation-skus-modal', 'component'],
    // Passed in
    product: null,
    missing_skus: false,
    // If they go to save and we don't have a sku yet, this is true
    // Modal properties
    prefix: null,
    regenerate_all: false,
    total_to_update: Ember.computed('product.variations.@each.{isDeleted,sku}', 'regenerate_all', function () {
      let variations = this.get('product.variations').filterBy('isDeleted', false);

      if (this.get('regenerate_all') === true) {
        return variations.get('length');
      }

      return variations.filter(v => Ember.isBlank(v.get('sku'))).get('length');
    }),
    first_variation: Ember.computed('product.variations_sorted.@each.isDeleted', function () {
      let variations = this.get('product.variations_sorted').filterBy('isDeleted', false);
      let variation = variations.find(v => Ember.isBlank(v.get('sku')));

      if (variation != null) {
        return variation;
      }

      return variations.objectAt(0);
    }),
    example_sku: Ember.computed('first_variation', 'prefix', function () {
      if (this.get('first_variation') == null) {
        return null;
      }

      return this.generatedSKU(this.get('first_variation'), this.get('prefix'));
    }),
    generateSkus: (0, _emberConcurrency.task)(function* () {
      let variations = yield this.get('product.variations');
      variations = variations.filterBy('isDeleted', false);

      if (this.get('regenerate_all') !== true) {
        variations = variations.filter(v => Ember.isBlank(v.get('sku')));
      }

      variations = variations.toArray();

      for (let variation of variations) {
        variation.set('sku', this.generatedSKU(variation, this.get('prefix')));
      }

      this.closeModal();
    }).drop(),

    generatedSKU(variation, prefix) {
      if (Ember.isBlank(prefix)) {
        prefix = null;
      }

      let details_values_for_sku = variation.get('details_sorted').map(detail => detail.get('variant_value'));
      let variation_name = details_values_for_sku.map(detail => Ember.isBlank(detail.get('name')) ? null : detail.get('name').trim()).compact().join('-').replace(/'/g, '') // remove apostrophes (MEN'S -> MENS)
      .replace(/\W+/g, ' ') // turn all non-alphanumeric chars into spaces
      .trim() // trim start and end
      .toUpperCase() // uppercase all the things
      .replace(/\s+/g, '-'); // turn all whitespace into dashes

      return [prefix, variation_name].compact().join('');
    }

  });

  _exports.default = _default;
});