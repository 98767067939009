define("manage/pods/companies/company/projects/project/customers/index/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'customer',
    componentPrefix: 'companies/company/projects/project/customers/index',
    i18nPrefix: 'customers.index',
    colDefs: function
      /* model */
    () {
      return [{
        id: 'id',
        sort: 'id'
      }, {
        id: 'email',
        sort: 'email',
        default_visible: true
      }, {
        id: 'name',
        sort: 'name',
        default_visible: true
      }];
    }
  });

  _exports.default = _default;
});