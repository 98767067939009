define("manage/pods/companies/company/projects/project/settings/emails/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sort_direction: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      app: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project.reload(),
        apps: this.store.query('app', {
          filter: {
            includes_email: true
          }
        }),
        emails: this.search(project, params)
      });
    },

    search(project, params) {
      // Filters
      let filters = {
        project: project.get('id'),
        search: params.search
      };

      if (params.app) {
        filters['app'] = params.app;
      }

      if (params.app === 'archived') {
        filters['archived'] = true;
        filters['app'] = 0;
      } else {
        filters['archived'] = false;
      }

      if (params.app === 'user') {
        filters['app'] = 0;
      } // Paging


      let page = params.page;
      let pageSize = params.page_size; // Sorting

      let sortProperty = params.sort;
      let sortDirection = params.sort_direction;
      let sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      return this.store.query('project-email', {
        filter: filters,
        includes: ['app'].join(','),
        page: {
          number: page,
          size: pageSize
        },
        sort: sort
      });
    }

  });

  _exports.default = _default;
});