define("manage/pods/companies/company/projects/status/route", ["exports", "manage/mixins/restrict-by-session"], function (_exports, _restrictBySession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictBySession.default, {
    queryParams: {
      project_id: {
        refreshModel: false
      },
      fulfillment_status: {
        refreshModel: false
      }
    },

    model() {
      let company = this.modelFor('companies.company');
      return Ember.RSVP.hash({
        company: company,
        projects: this.store.query('project', {
          filter: {
            company: company.get('id')
          },
          include: ['sources'].join(','),
          page: {
            number: 1,
            size: 100
          }
        })
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let params = this.paramsFor(this.routeName);

      if (Ember.isPresent(params.project_id) && Ember.isPresent(params.fulfillment_status)) {
        let project = model.projects.findBy('id', params.project_id);
        let all_statuses = [];

        for (let group of controller.get('fulfillment_statuses')) {
          for (let status of group.options) {
            all_statuses.push(status);
          }
        }

        let status = all_statuses.findBy('value', params.fulfillment_status); // Only update the status once

        if (Ember.isPresent(project) && Ember.isPresent(status) && Ember.isBlank(project.get('fulfillment_status'))) {
          project.set('fulfillment_status', params.fulfillment_status);
          controller.get('saveProjectTask').perform(project);
        }
      }
    }

  });

  _exports.default = _default;
});