define("manage/models/order-tag", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.colors = void 0;
  let colors = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']; // Validations are here in stead of on shared, because it should be a manage only concern

  _exports.colors = colors;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    user: {
      description: "User",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    color: {
      description: "Color",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    name: {
      description: "Tag Name",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  }); // Not included in schema.json since it's polymorphic

  var _default = _model.default.extend(Validations, {
    project: (0, _model.belongsTo)('project'),
    user: (0, _model.belongsTo)('user'),
    name: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    color: (0, _model.attr)('string'),
    stats: (0, _model.belongsTo)('order-tag-stat')
  });

  _exports.default = _default;
});