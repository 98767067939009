define("manage/components/ss-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'progress'],
    classNameBindings: ['status'],
    ms: 300,
    barComponentName: 'ss-progress-bar',
    progressValue: Ember.computed('progress', function () {
      let progress = this.get('progress');

      if (Ember.isBlank(progress)) {
        return 0;
      }

      progress = parseInt(progress, 10);

      if (isNaN(progress)) {
        return 0;
      }

      if (progress <= 0) {
        return 0;
      }

      if (progress >= 100) {
        return 100;
      }

      return progress;
    }),
    status: Ember.computed('progressValue', {
      get() {
        if (this._status) {
          return this._status;
        }

        let progress = this.get('progressValue');

        if (progress <= 0) {
          return "";
        }

        if (progress >= 100) {
          return "success";
        }

        return "active";
      },

      set(key, value) {
        return this._status = value;
      }

    }),
    barStyle: Ember.computed('ms', 'progressValue', function () {
      let ms = this.get('ms');
      let progress = this.get('progressValue');
      return Ember.String.htmlSafe(`transition-duration: ${ms}ms; width: ${progress}%;`);
    })
  });

  _exports.default = _default;
});