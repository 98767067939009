define("manage/models/order-email", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('order-email').reopen({
    name: Ember.computed('email.name', 'subject', 'project.name', function () {
      const project_name = this.get('project.name');

      if (this.get('subject')) {
        return this.get('subject').replace(project_name, '').replace(' - ', '');
      } else if (this.get('email.name')) {
        return this.get('email.name');
      } else {
        return 'Custom';
      }
    }),
    sent_on_or_created_at: Ember.computed('sent_on', 'created_at', function () {
      if (Ember.isBlank(this.get('sent_on'))) {
        return this.get('created_at');
      }

      return this.get('sent_on');
    })
  });

  _exports.default = _default;
});