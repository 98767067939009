define("manage/components/ss-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'bottom', 'attached', 'tab', 'segment'],
    classNameBindings: ['isActive:active'],
    attributeBindings: ['data-tab:elementId'],
    title: null,

    didInsertElement() {
      this._super(...arguments);

      this.perform();
    },

    perform() {
      this.get('menu').perform(this);

      if (this.get('isActive')) {
        this.send('activate');
      }
    },

    toggle(state) {
      this.set('isActive', state);

      if (state === true) {
        this.send('activate');
      }
    },

    actions: {
      activate() {
        if (this.get('onActivate')) {
          this.get('onActivate')();
        }
      }

    }
  });

  _exports.default = _default;
});