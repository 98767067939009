define("manage/pods/companies/company/projects/project/products/components/unknown-finalization-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ["unknown-finalization-modal", "component", "large"],
    store: Ember.inject.service(),
    product: null,
    productFinalization: null,
    productType: null,
    otherProduct: null,
    loadedProducts: null,
    error: null,
    physicalProducts: Ember.computed.filterBy('loadedProducts', 'is_physical', true),
    digitalProducts: Ember.computed.filterBy('loadedProducts', 'is_digital', true),
    choiceProducts: Ember.computed.filterBy('loadedProducts', 'is_option', true),
    loadProductsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200); // short visual delay

      let unknown = yield this.get('product');
      let project = yield unknown.get('project');
      let products = yield this.get('store').query('product', {
        filter: {
          project: project.get('id'),
          product_type: ['digital', 'physical', 'option']
        },
        page: {
          size: 200
        }
      });
      products = products.filter(product => Ember.isBlank(product.get('external_id'))).sortBy('name');
      this.set('loadedProducts', products);
    }).drop(),
    finalizeUnknownTask: (0, _emberConcurrency.task)(function* (action, product_type, other_product) {
      let unknown = yield this.get('product');
      other_product = yield other_product;

      try {
        let result = yield unknown.update_unknown(action, product_type, other_product);

        if (result.success !== true) {
          this.set('error', 'An unknown error occurred, please try again');
          return;
        }
      } catch (xhr) {
        this.set('error', 'An unknown error occurred, please try again');
        return;
      } // Unload this, its type changed. We need to reload it


      try {
        unknown.unloadRecord();
      } catch (xhr) {// Ignore
      }

      this.closeModal();
    }),

    doFinalize(action, product_type, other_product) {
      if (this.get('finalizeUnknownTask.isRunning')) {
        return;
      }

      if (Ember.isBlank(action)) {
        this.set('error', "Please select an option");
        return;
      }

      this.set('error', null);
      this.get('finalizeUnknownTask').perform(action, product_type, other_product);
    },

    actions: {
      cast() {
        this.doFinalize('cast', this.get('productType'), null);
      },

      hide() {
        this.doFinalize('hide', null, null);
      },

      merge() {
        this.doFinalize('merge', null, this.get('otherProduct'));
      }

    }
  });

  _exports.default = _default;
});