define("manage/pods/companies/company/projects/project/sources/upgrades/components/upgrade-configuration/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['upgrade-configuration', 'component'],
    intl: Ember.inject.service(),
    project: Ember.computed.readOnly('configuration.project'),
    source: Ember.computed.readOnly('configuration.source'),
    // Passed in
    configuration: null,
    selected: null,

    onSelected() {},

    is_selected: Ember.computed('configuration.id', 'selected.id', function () {
      return this.get('configuration.id') === this.get('selected.id');
    }),
    actions: {
      set_selected() {
        if (this.get('is_selected')) {
          return;
        }

        this.onSelected(this.get('configuration'));
      },

      set_can_select() {
        this.set('configuration.can_select', !this.get('configuration.can_select'));
      }

    },
    change_reason: Ember.computed('is_selected', 'configuration.can_select', 'configuration.product_reward.price_cents', 'selected.upgrade_type', 'selected.product_reward.price_cents', function () {
      if (this.get('selected') == null) {
        return 'nothing-selected';
      }

      if (this.get('is_selected')) {
        return 'is-selected';
      }

      if (this.get('configuration.can_select') === false) {
        return 'no-cannot-upgrade-to';
      }

      if (this.get('selected.upgrade_type') === 'none') {
        return 'no-upgrade-type-none';
      } // Needs to be before price checks


      if (this.get('selected.upgrade_type') === 'any') {
        return 'yes-upgrade-type-any';
      }

      if (this.get('selected.product_reward.price_cents') > this.get('configuration.product_reward.price_cents')) {
        return 'no-lower-price';
      }

      if (this.get('selected.product_reward.price_cents') <= this.get('configuration.product_reward.price_cents')) {
        return 'yes-same-or-higher-price';
      }

      return '';
    }),
    // Helper methods for icons
    can_select: Ember.computed('change_reason', function () {
      return this.change_reason.startsWith('yes-');
    }),
    cannot_select: Ember.computed('change_reason', function () {
      return this.change_reason.startsWith('no-');
    }),
    change_class: Ember.computed('change_reason', 'can_select', 'cannot_select', function () {
      if (this.change_reason === 'is-selected') {
        return 'current';
      }

      if (this.cannot_select) {
        return 'not-available';
      }

      if (this.can_select) {
        return 'available';
      }

      return '';
    }),
    change_reason_description: Ember.computed('change_reason', 'configuration.source.site', 'project.t.lines.reward', function () {
      let type_name = this.get('project.t.lines.reward'); // Handle nos first

      if (this.change_reason === 'no-upgrade-type-none') {
        return `This is not available to upgrade to because the selected ${type_name}s Upgrade Workflow is set to 'None'.`;
      }

      if (this.change_reason === 'no-lower-price') {
        return `This is not available to upgrade to because the selected ${type_name}s Upgrade Workflow is set to 'Only Equal or Higher'.`;
      } // Special case, if we have this as true, we return nothing as a separate icon handles it.
      // Don't show yes answers


      if (this.change_reason === 'no-cannot-upgrade-to') {
        return '';
      } // Yes answers


      if (this.change_reason === 'yes-upgrade-type-any') {
        return `This is available to upgrade to because the selected ${type_name}s Upgrade Workflow is set to 'Any'.`;
      }

      if (this.change_reason === 'yes-same-or-higher-price') {
        return `This is available to upgrade to because the selected ${type_name}s Upgrade Workflow is set to 'Only Equal or Higher'.`;
      } // Assume its not available if it doesn't return above


      return '';
    })
  });

  _exports.default = _default;
});