define("manage/pods/companies/company/projects/project/components/order-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZHB0iVE0",
    "block": "{\"symbols\":[\"dropdown\",\"&default\"],\"statements\":[[5,\"basic-dropdown\",[],[[\"@calculatePosition\",\"@onOpen\",\"@onClose\"],[[22,\"calculatePosition\"],[28,\"action\",[[23,0,[]],\"onOpen\"],null],[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"Trigger\"]],[[12,\"class\",\"ui compact blue right labeled icon button\"]],[[],[]],{\"statements\":[[0,\"\\n    More Filters\\n    \"],[7,\"i\",true],[10,\"class\",\"options icon\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"active_filters\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"ui circular green label\"],[8],[1,[22,\"active_filters\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"Content\"]],[[12,\"class\",\"order-filters-popup\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"companies/company/projects/project/components/order-filters-content\",null,[[\"project\",\"filters\",\"total_filters\",\"filter_selections\",\"select_filter\",\"remove_filter\",\"remove_all_filters\",\"addTag\",\"removeTagAtIndex\",\"cancel\",\"apply\"],[[24,[\"project\"]],[24,[\"filters\"]],[24,[\"total_filters\"]],[24,[\"filter_selections\"]],[28,\"action\",[[23,0,[]],\"select_filter\"],null],[28,\"action\",[[23,0,[]],\"remove_filter\"],null],[28,\"action\",[[23,0,[]],\"remove_all_filters\"],null],[28,\"action\",[[23,0,[]],\"addTag\"],null],[28,\"action\",[[23,0,[]],\"removeTagAtIndex\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"apply\"],null]]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/components/order-filters/template.hbs"
    }
  });

  _exports.default = _default;
});