define("manage/pods/companies/company/projects/project/digital/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLASS_FOR_STATUS_LABEL = {
    live: 'green',
    paused: 'yellow',
    setup: 'orange',
    complete: 'grey'
  };
  const STATUS_UPDATE_PATHS = {
    setup: ['setup', 'live'],
    live: ['live', 'paused', 'complete'],
    paused: ['paused', 'live', 'complete'],
    complete: ['complete', 'live', 'paused']
  };

  var _default = Ember.Mixin.create({
    classForStatusLabel: CLASS_FOR_STATUS_LABEL,
    statusUpdatePaths: STATUS_UPDATE_PATHS
  });

  _exports.default = _default;
});