define("manage/pods/companies/company/projects/project/theme/components/theme-hero-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['hero', 'header'],
    attributeBindings: ['style'],
    is_desktop: Ember.computed.equal('size', 'desktop'),
    is_tablet: Ember.computed.equal('size', 'tablet'),
    is_mobile: Ember.computed.equal('size', 'mobile'),
    style: Ember.computed('theme.{hero_image_src,default_hero_background_color,default_hero_background_size,default_hero_background_mobile_offset,default_hero_background_tablet_offset,default_hero_background_offset}', 'is_mobile', 'is_tablet', function () {
      let styles = [];

      if (Ember.isPresent(this.get('theme.hero_image_src'))) {
        styles.push(`background-image: url(${this.get('theme.hero_image_src')})`);
      }

      styles.push(`background-color: ${this.get('theme.default_hero_background_color')}`);
      styles.push(`background-size: ${this.get('theme.default_hero_background_size')}`);

      if (String(this.get('theme.default_hero_background_size')) === 'cover') {
        styles.push("background-position: inherit");
      } else if (this.get('is_mobile')) {
        let background_offset = parseInt(this.get('theme.default_hero_background_mobile_offset'), 10);
        styles.push(`background-position: calc(50% + ${background_offset}px) bottom`);
      } else if (this.get('is_tablet')) {
        let background_offset = parseInt(this.get('theme.default_hero_background_tablet_offset'), 10);
        styles.push(`background-position: calc(50% + ${background_offset}px)`);
      } else {
        let background_offset = parseInt(this.get('theme.default_hero_background_offset'), 10);
        styles.push(`background-position: calc(50% + ${background_offset}px)`);
      }

      return Ember.String.htmlSafe(styles.join(';'));
    })
  });

  _exports.default = _default;
});