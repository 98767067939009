define("manage/pods/companies/company/projects/project/products/components/edit-vat-category-price/component", ["exports", "manage/utils/monetize"], function (_exports, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    product: null,
    // set in template
    vat_category: null,
    // set in template
    project: Ember.computed.readOnly('product.project'),
    currency: Ember.computed.readOnly('project.currency'),
    tagName: "",
    cents: Ember.computed('vat_category.public_id', 'product.vat_price_cents', {
      get() {
        let public_id = this.get('vat_category.public_id');
        return this.get(`product.vat_price_cents.${public_id}`);
      },

      set(_key, value) {
        let public_id = this.get('vat_category.public_id'); // We have to replace the entire prices object so that ember model fragments properly tracks it.
        // If we don't, it won't mark as dirty properly

        this.set('product.vat_price_cents', Object.assign({}, this.get('product.vat_price_cents'), {
          [public_id]: value
        }));
        return this.get(`product.vat_price_cents.${public_id}`);
      }

    }),
    money: (0, _monetize.default)('cents', 'currency')
  });

  _exports.default = _default;
});