define("manage/components/ss-accordion-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['title'],
    classNameBindings: ['accordion.isActive:active'],
    onclick: function () {},

    init() {
      this._super(...arguments);

      this.get('accordion').registerTitle(this);
    },

    click() {
      this.get('accordion').perform();
      this.get('onclick')();
    }

  });

  _exports.default = _default;
});