define("manage/pods/companies/company/projects/project/sources/configurations/review/components/warning-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["ui", "icon", "yellow", "message", "warning-item", "component"],
    view_links: false
  });

  _exports.default = _default;
});