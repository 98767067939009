define("manage/mixins/models/project/zone", ["exports", "ember-cp-validations", "big.js"], function (_exports, _emberCpValidations, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    shipping: {
      description: "Shipping",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to', {
        disabled: Ember.computed.not('model.is_shipping_weight_based')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.readOnly('model.is_shipping_weight_based')
      })]
    },
    percent: {
      description: "Percent",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: false,
        disabled: Ember.computed.not('model.is_shipping_weight_based')
      }), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        gte: 0,
        lte: 10000,
        disabled: Ember.computed.readOnly('model.is_shipping_weight_based')
      })]
    },
    // Don't validate name, it should generate it automatically. Only return backend errors
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    location_type: {
      description: "Location Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['everywhere_else', 'countries', 'states']
      })]
    },
    cost_type: {
      description: "Cost Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['shipping_weight_based', 'sales_tax', 'vat', 'customs']
      })]
    },
    locations: {
      description: "Locations",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Must have at least one location selected'
      })]
    }
  });
  const shippingWeightBasedMixin = Ember.Mixin.create({
    normalizeWeightBased() {
      let prices = this.get('shipping.prices');

      for (let price of prices.toArray()) {
        let ending_weight = price.get('ending_weight'); // If we have an ending weight, normalize weights

        if (Ember.isPresent(ending_weight) && !isNaN(parseFloat(ending_weight))) {
          price.set('ending_weight', parseFloat((0, _big.default)(ending_weight).toFixed(2)));
        }

        price.set('starting_weight', price.get('starting_weight_calculated'));

        if (Ember.isBlank(price.get('amount_cents'))) {
          price.set('amount_cents', 0);
        }
      }
    },

    initValueWeightBased() {
      // Init fragment to default
      this.setDefaultsWeightBased();
      let shipping = this.get('shipping');
      let prices = shipping.get('prices');

      if (prices.get('length') !== 0) {
        // We have initial prices
        return;
      }

      this.addDefaultPrice();
    },

    setDefaultsWeightBased() {
      let shipping = this.get('shipping');

      if (Ember.isBlank(shipping.get('type'))) {
        shipping.set('type', 'weight');
      }

      let prices = shipping.get('prices');

      if (Ember.isBlank(prices)) {
        shipping.set('prices', Ember.A());
      }
    },

    addDefaultPrice() {
      let shipping = this.get('shipping');
      let prices = shipping.get('prices');
      let ids = prices.filterBy('isDeleted', false).mapBy('sort_order').compact(); // Needs to start at 0 for weight-shipping-rates expectation

      ids.push(-1);
      let sort_order = Math.max(...ids) + 1; // Add default

      return prices.createFragment({
        sort_order // starting_weight: 0,
        // ending_weight
        // amount_cents: 0

      });
    }

  });
  const vatMixin = Ember.Mixin.create({
    initValueVat() {
      let vat = this.get('vat');

      if (Ember.isBlank(vat.get('type'))) {
        vat.set('type', 'vat');
      }

      if (Ember.isBlank(vat.get('prices'))) {
        vat.set('prices', {});
      }

      if (Ember.isBlank(this.get('percent'))) {
        this.set('percent', 0);
      }
    }

  });
  const percentMixin = Ember.Mixin.create({
    normalizePercent() {// Nothing do to here
    },

    initValuePercent() {
      this.set('percent', 0);
    }

  });

  var _default = Ember.Mixin.create(Validations, shippingWeightBasedMixin, vatMixin, percentMixin, {
    intl: Ember.inject.service(),
    shipping_display: Ember.computed('is_shipping_weight_based', 'shipping_display_weight_based', 'shipping_display_vat', 'shipping_display_percent_based', function () {
      if (this.get('is_shipping_weight_based')) {
        return "companies/company/projects/project/location-zone-weight-based";
      }

      if (this.get('is_vat')) {
        return "companies/company/projects/project/location-zone-vat";
      }

      return "companies/company/projects/project/location-zone-percent";
    }),

    normalize() {
      if (Ember.isBlank(this.get('name'))) {
        this.set('name', this.get('generated_name'));
      }

      if (this.get('is_shipping_weight_based')) {
        this.normalizeWeightBased();
      } else {
        this.normalizePercent();
      }
    },

    initValue() {
      if (Ember.isBlank(this.get('shipping'))) {
        this.set('shipping', this.store.createFragment('zone-shipping'));
      }

      if (Ember.isBlank(this.get('vat'))) {
        this.set('vat', this.store.createFragment('zone-vat'));
      }

      if (this.get('is_shipping_weight_based')) {
        this.initValueWeightBased();
      } else if (this.get('is_vat')) {
        this.initValueVat();
      } else {
        this.initValuePercent();
      }
    }

  });

  _exports.default = _default;
});