define("manage/pods/companies/company/projects/project/products/index/route", ["exports", "manage/pods/companies/company/projects/project/products/index/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      },
      product_type: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    ajax: Ember.inject.service(),
    current_user: Ember.inject.service('user'),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let filter = {
        project_id: project.id
      };

      if (params.search) {
        filter.search = params.search;
      }

      if (params.product_type) {
        filter.product_type = params.product_type;
      }

      return Ember.RSVP.hash({
        project: project,
        products: this.search(project, params)
      }).then(_ref => {
        let {
          project,
          products
        } = _ref;
        let data = {
          project,
          products
        };

        if (products.get('length') === 0) {
          return data;
        }

        data.stats = this.store.query('product-stat', {
          filter: {
            id: products.mapBy("id")
          },
          page: {
            size: products.get('length')
          }
        });
        return Ember.RSVP.hash(data);
      });
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let sortProperty = params.sort;
      let sortDirection = params.sortDirection;
      let product_type = params.product_type;
      let search = params.search;
      let sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      if (Ember.isBlank(product_type)) {
        product_type = ['digital', 'physical', 'option', 'shipping', 'custom_bundles'];
      } else if (product_type === 'physical') {
        product_type = ['physical', 'shipping'];
      } else if (product_type === 'digital') {
        product_type = ['digital'];
      } else if (product_type === 'choice') {
        product_type = ['option'];
      } else if (product_type === 'unknown') {
        product_type = ['unknown'];
      } else if (product_type === 'imported_addons_to_review') {
        product_type = ['imported_addons_to_review'];
      }

      return this.store.query('product', {
        filter: {
          project: project.get('id'),
          product_type: product_type,
          search: search
        },
        page: {
          number: page,
          size: pageSize
        },
        sort: sort
      });
    }

  });

  _exports.default = _default;
});