define("manage/pods/companies/company/projects/project/questions/components/edit-product-question-multiple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-product-question-multiple'],
    store: Ember.inject.service(),
    productTasks: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    base_tabindex: 1000,
    multiple_types: [Ember.Object.create({
      name: 'Single',
      value: 'single'
    }), Ember.Object.create({
      name: 'Quanity',
      value: 'quantity'
    }), Ember.Object.create({
      name: 'Select',
      value: 'select'
    })],
    actions: {
      add_question_choice() {
        let question = this.get('question');
        this.get('productTasks.createQuestionChoice').perform(question);
      },

      delete_question_choice(question_choice) {
        if (this.get('productTasks.deleteQuestionChoice.isRunning')) {
          return;
        }

        if (question_choice.get('isNew')) {
          this.get('productTasks.deleteQuestionChoice').perform(question_choice).catch(e => {
            this.send('open_modal', 'error-modal', {
              description: e.message
            });
          });
        } else {
          this.send('open_modal', 'delete-confirmation-modal', {
            entity: question_choice,
            i18nKey: 'delete-product-question-choice',
            i18nParams: {
              name: question_choice.get('text'),
              nameIsBlank: Ember.isBlank(question_choice.get('text'))
            },
            approve: () => {
              return this.get('productTasks.deleteQuestionChoice').perform(question_choice);
            }
          });
        }
      },

      order_choices(choices) {
        choices.forEach((choice, index) => choice.set('sort_order', index + 1));
      }

    }
  });

  _exports.default = _default;
});