define("manage/pods/companies/company/projects/project/products/components/clone-product-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['clone-product-modal', 'component'],
    ajax: Ember.inject.service(),
    product: null,
    name: null,
    description: null,
    clone_url: Ember.computed('product', function () {
      return this.get('ajax').buildModelUrl(this.get('product'), 'clone');
    }),

    init() {
      this._super(...arguments);

      this.set('name', this.get('product.name'));
      this.set('description', this.get('product.description'));
    },

    cloneTask: (0, _emberConcurrency.task)(function* () {
      let data = {};

      if (this.get('name') !== this.get('product.name')) {
        data.name = this.get('name');
      }

      if (this.get('description') !== this.get('product.description')) {
        data.description = this.get('description');
      }

      try {
        let result = yield this.get('ajax').post(this.get('clone_url'), {
          data: data
        });
        this.closeModal();
        this.get('onSuccess')(result);
      } catch (error) {
        this.get('onFailure')(error);
      }
    }).drop()
  });

  _exports.default = _default;
});