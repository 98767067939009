define("manage/pods/companies/company/projects/project/sources/configurations/edit/caps/route", ["exports", "manage/mixins/handle-transition"], function (_exports, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model() {
      let project = this.modelFor('companies.company.projects.project');
      let configuration = this.modelFor('companies.company.projects.project.sources.configurations.edit');
      return Ember.RSVP.hash({
        project: project,
        configuration: this.store.findRecord('project-configuration', configuration.get('id'), {
          include: ['product-reward', 'product-extra'].join(',')
        })
      }).then(_ref => {
        let {
          project,
          configuration
        } = _ref;
        let data = {
          project,
          configuration
        };

        if (Ember.isPresent(configuration.get('product_extra.id'))) {
          data.product_extra = this.store.findRecord('product', configuration.get('product_extra.id'), {
            include: ['zones', 'zones.locations'].join(',')
          });
        }

        return Ember.RSVP.hash(data);
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product_extra) {
          let product_extra = record.product_extra;
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(product_extra).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: product_extra,
                  modelName: "Cap",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});