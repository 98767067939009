define("manage/pods/companies/company/projects/project/orders/view/components/sync-history/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sync-history', 'component'],
    store: Ember.inject.service(),
    order: null,
    // Passed into the component
    project: Ember.computed.readOnly('order.project'),

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let order = yield this.get('order');
      yield this.get('store').findRecord('order', order.get('id'), {
        include: ['syncs', 'syncs.order-selections'].join(',')
      });
    }),
    removeSyncTask: (0, _emberConcurrency.task)(function* (order, order_sync) {
      order = yield order;
      order_sync = yield order_sync;

      try {
        yield order_sync.destroyRecord();
      } catch (e) {
        order_sync.rollbackAttributes();
        throw e;
      }
    }),
    actions: {
      removeFromSync(order, order_sync) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'resync-order',
          i18nParams: {
            provider: order_sync.get('provider')
          },
          approve: () => {
            return this.get('removeSyncTask').perform(order, order_sync);
          }
        });
      }

    }
  });

  _exports.default = _default;
});