define("manage/components/ss-accordion-tagless", ["exports", "manage/mixins/ss-accordion-base"], function (_exports, _ssAccordionBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Relative path works since both survey and manage are in lib/...
  var _default = Ember.Component.extend(_ssAccordionBase.default, {
    tagName: '',
    group: null,

    // accordion group
    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('name'))) {
        this.set('name', `accordion-${Ember.guidFor(this)}`);
      }
    },

    perform() {
      this.get('group').perform(this);
    }

  });

  _exports.default = _default;
});