define("manage/pods/companies/company/projects/project/settings/fields/columns", ["exports", "manage/mixins/columns", "manage/config/environment"], function (_exports, _columns, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'project-custom-field',
    componentPrefix: 'companies/company/projects/project/settings/fields',
    i18nPrefix: 'custom-fields.index',
    colDefs: function
      /* model */
    () {
      let columns = [{
        id: 'id',
        sort: 'id'
      }];

      if (_environment.default.environment === 'development') {
        columns.push({
          id: 'sort',
          sort: 'sort_order',
          class: 'admin'
        });
      }

      columns.push({
        id: 'entity',
        sort: 'entity',
        default_visible: true
      }, {
        id: 'name',
        sort: 'name',
        default_visible: true
      }, {
        id: 'description',
        sort: 'description',
        default_visible: true
      }, {
        id: 'type',
        sort: 'field_type',
        default_visible: true
      }, {
        id: 'visibility',
        sort: 'visibility',
        default_visible: true
      });
      return columns;
    }
  });

  _exports.default = _default;
});