define("manage/pods/export/download/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['token'],
    downloadTriggered: false,

    init() {
      this._super(...arguments);

      Ember.run.later(this, this.startDownload, 1500);
    },

    startDownload() {
      window.location = (0, _jquery.default)('#download-button').attr('href');
      this.set('downloadTriggered', true);
    }

  });

  _exports.default = _default;
});