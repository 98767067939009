define("manage/pods/companies/company/projects/project/sources/extras/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    source: Ember.computed.readOnly('model.source'),
    all_products: Ember.computed.readOnly('model.all_products'),
    product_extra_global: Ember.computed.readOnly('model.product_extra_global'),
    current_user: Ember.inject.service('user'),
    productTasks: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      save() {
        if (this.get('productTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);
        let intl = this.get('intl'); // We validate in the save task

        this.get('productTasks.save').perform(this.get('product_extra_global')).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t("route.companies.company.projects.project.extras.error.no_save")
          });
        }));
      }

    }
  });

  _exports.default = _default;
});