define("manage/pods/admin/companies/company/components/salesforce-billing-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    closable: true,
    classNames: ['salesforce-billing-modal'],

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    isRunning: Ember.computed.alias('initTask.isRunning'),
    initTask: (0, _emberConcurrency.task)(function* () {
      let ajax = this.get('ajax');
      let project = yield this.get('project');

      try {
        let results = yield ajax.postModel(project, 'salesforce-billing');
        this.setProperties({
          plan: results.salesforce.plan,
          charge: results.salesforce.charge,
          transaction_fee: results.salesforce.transaction_fee,
          backer_count: results.salesforce.backer_count,
          backer_rate: results.salesforce.backer_rate,
          setup_fee: results.salesforce.setup_fee
        });
      } catch (error) {
        this.set('error', 'Unable to find Salesforce information');
      }
    })
  });

  _exports.default = _default;
});