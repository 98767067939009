define("manage/models/product-zone-location", ["exports", "manage/utils/generated-model", "manage/mixins/zone-location"], function (_exports, _generatedModel, _zoneLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product-zone-location').extend(_zoneLocation.default, {
    is_shipping_product_based: Ember.computed.equal('cost_type', 'shipping_product_based'),
    is_extra_cap: Ember.computed.equal('cost_type', 'extra_cap')
  });

  _exports.default = _default;
});