define("manage/components/icon-crowdox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r3JxJXaT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",[29,[[22,\"width\"]]]],[11,\"height\",[29,[[22,\"width\"]]]],[10,\"viewBox\",\"0 0 13 13\"],[10,\"version\",\"1.1\"],[8],[7,\"path\",true],[10,\"d\",\"M7.157 12.4 C10.307 12.4 11.9 10.5 12.6 8.9 L9.479 7.5 C9.173 8.4 8.2 9.2 7.2 9.2 C5.411 9.2 4.2 7.9 4.2 6.2 C4.241 4.5 5.4 3.2 7.2 3.2 C8.237 3.2 9.2 4 9.5 5 L12.611 3.5 C11.891 1.8 10.3 0 7.2 0 C3.395 0 0.6 2.5 0.6 6.2 C0.551 9.9 3.4 12.4 7.2 12.4 L7.157 12.4 Z\"],[10,\"id\",\"C-4\"],[10,\"fill\",\"#FC7335\"],[10,\"sketch:type\",\"MSShapeGroup\"],[8],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/icon-crowdox/template.hbs"
    }
  });

  _exports.default = _default;
});