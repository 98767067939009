define("manage/pods/companies/company/projects/project/shipping/local-pickups/edit/controller", ["exports", "manage/utils/catch-real", "ember-concurrency", "shared/mixins/address-state-selection"], function (_exports, _catchReal, _emberConcurrency, _addressStateSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_addressStateSelection.default, {
    project_address: Ember.computed.readOnly('model'),
    submitted: false,
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    saveTask: (0, _emberConcurrency.task)(function* (project_address) {
      project_address = yield project_address;
      let {
        validations: validations
      } = yield project_address.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      yield project_address.save();
    }),
    actions: {
      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.set('submitted', true);
        return this.get('saveTask').perform(this.get('project_address')).then(() => this.transitionToRoute('companies.company.projects.project.shipping.local-pickups.index')).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: this.get('intl').t('route.companies.company.projects.project.shipping.local-pickups.edit.error-modal.description')
          });
        }));
      },

      countryChanged() {
        this.get('verifyStateTask').perform(this.get('project_address'));
      }

    }
  });

  _exports.default = _default;
});