define("manage/pods/companies/company/projects/project/welcome/location/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        company: this.modelFor('companies.company'),
        project: this.modelFor('companies.company.projects.project')
      };
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    }

  });

  _exports.default = _default;
});