define("manage/pods/companies/company/projects/project/settings/fields/components/column-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MxPv94dw",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"custom_field\",\"name\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/settings/fields/components/column-name/template.hbs"
    }
  });

  _exports.default = _default;
});