define("manage/pods/companies/company/projects/project/digital/index/controller", ["exports", "ember-concurrency", "manage/mixins/tabular-checkboxes"], function (_exports, _emberConcurrency, _tabularCheckboxes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tabularCheckboxes.default, {
    current_user: Ember.inject.service('user'),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    fulfillments: Ember.computed.readOnly('model.fulfillments'),
    // for the tabular checkboxes
    tabularCheckboxItems: Ember.computed.alias('fulfillments'),
    filtered_fulfillments: Ember.computed.filterBy('fulfillments', 'isDeleted', false),
    queryParams: ['page', 'page_size', 'sortProperty', 'sortDirection', 'search', 'fulfillment'],
    // Paging
    page: 1,
    page_size: 25,
    // Sorting
    sortProperty: 'id',
    sortDirection: 'asc',
    sorted_by: ['id:asc'],
    sorted_fulfillments: Ember.computed.sort('filtered_fulfillments', 'sorted_by'),
    // Filters
    fulfillment: '',
    fulfillment_options: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'download'
      }), Ember.Object.create({
        id: 'key'
      })];
    }),
    deleteFulfillmentTask: (0, _emberConcurrency.task)(function* (fulfillment) {
      fulfillment = yield fulfillment;

      try {
        yield fulfillment.destroyRecord();
        this.refresh();
      } catch (error) {
        fulfillment.rollbackAttributes();
        throw error;
      }
    }),

    refresh() {
      let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

      if (currentRoute) {
        currentRoute.refresh();
      }
    },

    actions: {
      sort(name, direction) {
        if (this.get('sortProperty') !== name) {
          this.set('sortProperty', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortProperty', name);
          this.set('sortDirection', direction);
        }

        return this.set('sorted_by', [`${this.get('sortProperty')}:${this.get('sortDirection')}`]);
      },

      refresh() {
        this.refresh();
      },

      toggle_check_all() {
        this.toggle_check_all();
      },

      toggle_fulfillment(fulfillment) {
        this.toggle_item(fulfillment);
      },

      importProduct(product, sub_type, project_email) {
        let fulfillment = this.store.createRecord('digital-fulfillment', {
          project: this.get('project'),
          product: product,
          sub_type: sub_type,
          project_email: project_email
        });
        fulfillment.save();
      },

      removeFulfillment(fulfillment) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'remove-digital-fulfillment',
          i18nParams: {
            name: fulfillment.product.get('name')
          },
          approve: () => {
            return this.get('deleteFulfillmentTask').perform(fulfillment);
          }
        });
      }

    }
  });

  _exports.default = _default;
});