define("manage/mixins/location-route", ["exports", "manage/mixins/handle-transition", "shared/tasks/save-task", "ember-concurrency"], function (_exports, _handleTransition, _saveTask, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(model_friendly_name) {
    return Ember.Mixin.create(_handleTransition.default, _saveTask.default, {
      model() {
        let project = this.modelFor('companies.company.projects.project');
        return Ember.RSVP.hash({
          project: this.store.findRecord('project', project.get('id'), {
            include: ['zones', 'zones.locations'].join(',')
          })
        });
      },

      resetController(controller, isExiting) {
        this._super(...arguments);

        if (isExiting) {
          controller.set('submitted', false);
        }
      },

      isDirtyTask: (0, _emberConcurrency.task)(function* (project) {
        project = yield project;
        return yield this.get('_isCollectionDirtry').perform(project, 'zones', 'locations');
      }),
      rollbackTask: (0, _emberConcurrency.task)(function* (project) {
        project = yield project;
        return yield this.get('_rollbackCollection').perform(project, 'zones', 'locations');
      }),
      actions: {
        willTransition(transition) {
          var record = this.modelFor(this.routeName);

          if (record && record.project) {
            let project = record.project;
            this.handleTransition(transition, resolve => {
              this.get('isDirtyTask').perform(project).then(isDirty => {
                if (isDirty) {
                  this.send('open_modal', 'rollback-modal', {
                    model: project,
                    modelName: model_friendly_name,
                    serviceTaskAction: this.get('rollbackTask'),
                    promiseResolve: resolve
                  });
                } else {
                  // We don't have a dirty model, so mark as handled and retry
                  resolve('retry');
                }
              });
            });
          }
        }

      }
    });
  }
});