define("manage/pods/companies/company/projects/project/info/configurations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('companies.company.projects.project');
      var include = [// Project items
      'configurations', 'configurations.source', 'configurations.product-reward', 'configurations.product-reward.mappings', 'configurations.product-reward.mappings.child'].join(',');
      return this.store.findRecord('project', project.get('id'), {
        include: include
      });
    }

  });

  _exports.default = _default;
});