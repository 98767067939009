define("manage/pods/companies/company/projects/project/orders/index/controller", ["exports", "manage/helpers/encode-string", "manage/mixins/order-filters", "manage/utils/strip-non-printable", "manage/mixins/bulk-actions", "manage/config/environment", "jquery", "manage/mixins/order-exports"], function (_exports, _encodeString, _orderFilters, _stripNonPrintable, _bulkActions, _environment, _jquery, _orderExports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let defaultFilterValues = Object.keys(_orderFilters.filters).reduce((map, item) => {
    map[item] = '';
    return map;
  }, {});

  var _default = Ember.Controller.extend(defaultFilterValues, _orderExports.default, _bulkActions.default, {
    queryParams: Object.keys(_orderFilters.filters).concat(['page', 'sort', 'sortDirection']),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    orders: Ember.computed.readOnly('model.orders'),
    segments: Ember.computed.readOnly('model.segments'),
    currentSegment: Ember.computed.readOnly('model.currentSegment'),
    exportApps: Ember.computed.readOnly('model.exportApps'),
    friendly_text: null,
    current_user: Ember.inject.service('user'),
    api_url: _environment.default.urls.api,
    resultsLoadSuccess: true,
    // Paging
    page: 1,
    page_size: 15,
    // Sorting
    sort: 'customer.email',
    sortDirection: 'asc',
    filter_data: Ember.computed(...Object.keys(_orderFilters.filters).concat([function () {
      return Object.keys(_orderFilters.filters).reduce((map, item) => {
        map[item] = this.get(item);
        return map;
      }, {});
    }])),
    // The parameters are slightly different when sent to the backend unfortunately
    // search
    // project_id
    //
    filter_data_with_project: Ember.computed('filter_data', 'project.id', function () {
      let filters = this.get('filter_data');
      return Object.assign(filters, {
        project_id: this.get('project.id')
      });
    }),
    filter: Ember.computed(...Object.keys(_orderFilters.filters).concat([function () {
      let decoded_filters = Object.keys(_orderFilters.filters).filter(key => {
        return Ember.isPresent(this.get(key));
      }).reduce((map, key) => {
        map[key] = this.get(key);
        return map;
      }, {});
      return (0, _encodeString.encodeString)(JSON.stringify(decoded_filters));
    }])),
    has_filter_other_than_project_segment: Ember.computed(...Object.keys(_orderFilters.filters).concat([function () {
      let other_filters = Object.keys(_orderFilters.filters).filter(key => {
        return Ember.isPresent(this.get(key)) && ['project_id', 'project_segment'].indexOf(key) < 0;
      });
      return other_filters.length > 0;
    }])),

    start_export(type) {
      this.transitionToRoute('companies.company.projects.project.orders.create-export', {
        queryParams: {
          format: type,
          filter: this.get('filter'),
          originalFilterStep: this.get('step')
        }
      });
    },

    actions: {
      build_params() {
        let extra_params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var params = Object.assign(extra_params, this.get('filter_data_with_project'));
        return _jquery.default.param(params);
      },

      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      refresh_route() {
        // Refresh the route
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      },

      update_query_params(values) {
        let keys = Object.keys(values);

        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];

          if (key === 'custom') {
            if (Ember.isPresent(values[key])) {
              if (Object.keys(values[key]).any(sub_key => Ember.isPresent(values[key][sub_key]))) {
                let customValues = Object.keys(values[key]).reject(subKey => Ember.isBlank(values[key][subKey])).reduce((newValues, subKey) => {
                  newValues[subKey] = values[key][subKey];
                  return newValues;
                }, {});
                this.set(key, (0, _encodeString.encodeString)(JSON.stringify(customValues)));
              } else {
                this.set(key, '');
              }
            } else {
              this.set(key, '');
            }
          } else if (key.indexOf('custom.') === 0) {//
          } else {
            this.set(key, values[key]);
          }
        } // Also update page to 1


        this.set('page', 1);
      },

      apply_filter(filter) {
        Object.keys(_orderFilters.filters).forEach(key => {
          if (key.indexOf('custom.') === 0) {// Do Nothing
          } else if (Ember.isPresent(filter[key])) {
            this.set(key, filter[key]);
          } else {
            this.set(key, "");
          }
        });
      },

      segment_loaded(project_segment) {
        if (project_segment) {
          this.send("apply_filter", {
            project_segment: project_segment.get('id')
          });
        } // Refreshes the filters after editing a segment.


        this.notifyPropertyChange('project_segment');
      },

      render_sql() {
        let page = this.get('page');
        let pageSize = this.get('page_size');
        let sortProperty = this.get('sort');
        let sortDirection = this.get('sortDirection');
        let sort;

        if (sortDirection === 'desc') {
          sort = `-${sortProperty}`;
        } else {
          sort = sortProperty;
        }

        let filter = Object.assign(Object.keys(_orderFilters.filters).filter(item => Ember.isPresent(this.get(item))).reduce((map, item) => {
          map[_orderFilters.filters[item].key || item] = (0, _stripNonPrintable.default)(this.get(item));
          return map;
        }, {}), {
          project: this.get('project.id')
        });
        this.get('ajax').post('/api/v2/orders/sql', {
          data: {
            filter: filter,
            page: {
              number: page,
              size: pageSize
            },
            sort: sort
          }
        }).then(xhr => {
          this.send('open_modal', 'info-modal', {
            title: "SQL Query",
            information: xhr.sql
          });
        });
      }

    }
  });

  _exports.default = _default;
});