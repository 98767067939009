define("manage/pods/companies/company/projects/project/sources/configurations/components/create-test-customer-simple-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/mixins/project-total", "manage/config/environment"], function (_exports, _ssModal, _emberConcurrency, _projectTotal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['create-test-customer-simple-modal', 'component'],
    store: Ember.inject.service(),
    country: null,
    show_configurations: false,
    configuration_amount: 0,
    configuration_shipping: 0,
    transaction: null,

    init() {
      this._super(...arguments); // Set defaults


      this.set('configuration_amount', 0);
      this.set('configuration_shipping', 0);
      this.set('transaction', this.get('configuration_amount') + this.get('configuration_shipping')); // Setup form based on input params

      if (Ember.isPresent(this.get('configuration'))) {
        this.set('project', this.get('configuration.project')); // Default the country, after setting project

        this.set('country', this.get('project.country'));
        this.get('loadConfigurationRates').perform();
      } else {
        this.set('project', this.get('source.project')); // Default the country, after setting project

        this.set('country', this.get('project.country'));
        this.set('show_configurations', true);
      }
    },

    loadConfigurationRates: (0, _emberConcurrency.task)(function* () {
      let configuration = yield this.get('configuration');
      let source = yield configuration.get('source'); // If the source is crowdox, don't update anything

      if (source.get('is_crowdox')) {
        return;
      }

      let product_reward = yield configuration.get('product_reward'); // load location rates

      yield this.get('store').findRecord('product', product_reward.get('id'), {
        include: ['zones', 'zones.locations'].join(',')
      });
      let country = yield this.get('country');
      this.set('configuration_amount', product_reward.get('price'));
      this.set('configuration_shipping', 0);
      let zone_location = (0, _projectTotal.find_zone_location)(product_reward, 'shipping_product_based_zone_locations', country);

      if (zone_location != null) {
        this.set('configuration_shipping', zone_location.get('shipping') || 0);
      } else {
        this.set('configuration_shipping', 0);
        this.set('country', null);
      }

      this.set('transaction', this.get('configuration_amount') + this.get('configuration_shipping'));
    }),
    url: Ember.computed('country.iso3', 'transaction', 'configuration.{id,source.token}', 'project.{slug,settings.survey_revision}', function () {
      let domain = _environment.default.urls.survey;

      if (Ember.isPresent(this.get('project.settings.survey_revision'))) {
        let domainParts = domain.split('.');

        if (domainParts[0].indexOf('local') === -1) {
          domainParts[0] = `${domainParts[0]}-${this.get('project.settings.survey_revision')}`;
          domain = domainParts.join('.');
        }
      }

      let token = this.get('configuration.source.token');
      let country = this.get('country.iso3') || '';
      let transaction = this.get('transaction');

      if (Ember.isBlank(transaction)) {
        transaction = 0;
      }

      let configuration = this.get('configuration.id');
      return `${domain}/project/${this.get('project.slug')}/test?token=${token}&country=${country}&transaction=${transaction}&configurations=${configuration}`;
    }),
    actions: {
      select_configuration(configuration) {
        this.set('configuration', configuration);
        this.get('loadConfigurationRates').perform();
      },

      update_country(country) {
        this.set('country', country);
        this.get('loadConfigurationRates').perform();
      },

      approve() {
        this.set('submitted', true);

        if (Ember.isBlank(this.get('configuration'))) {
          return;
        }

        let win = window.open('', '_blank'); // Update the URL

        win.location = this.get('url');
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});