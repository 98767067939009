define("manage/pods/companies/company/projects/project/questions/edit/route", ["exports", "manage/mixins/handle-transition"], function (_exports, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model(params) {
      return this.store.findRecord('product', params.product_id, {
        include: ['questions', 'question-choices'].join(',')
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record) {
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(record).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: record,
                  modelName: "Question",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});