define("manage/pods/companies/company/projects/project/orders/view/components/order-selection-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m2yf42+J",
    "block": "{\"symbols\":[\"dropdown\",\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui mini basic buttons\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"can_edit\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"edit\"]],[8],[1,[28,\"t\",[\"phrase.edit\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"basic-dropdown\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"Trigger\"]],[[12,\"class\",\"ui icon button order-selection-actions\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"mini ellipsis horizontal icon\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"Content\"]],[[12,\"class\",\"ui grey vertical menu\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"can_remove\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[12,\"class\",\"item\"],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[1,[28,\"t\",[\"phrase.remove\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"project\",\"libs\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[\"app\",\"order_selection_actions\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"companies/company/projects/project/app-actions\",null,[[\"type\",\"access\",\"app\",\"dropdown\",\"order_selection\"],[\"order_selection\",[23,2,[\"access\"]],[23,2,[\"app\"]],[23,1,[]],[24,[\"order_selection\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/order-selection-extension/template.hbs"
    }
  });

  _exports.default = _default;
});