define("manage/pods/companies/company/projects/project/products/choices/components/mapped-product/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mapped-product-bundle', 'component'],
    tagName: 'tr',
    current_user: Ember.inject.service('user'),
    name: Ember.computed('product.is_question', 'product.name', 'product.question.text', function () {
      if (this.get('product.is_question')) {
        return this.get('product.question.text');
      }

      return this.get('product.name');
    }),
    description: Ember.computed('product.is_question', 'product.description', 'product.question.description', function () {
      if (this.get('product.is_question')) {
        return this.get('product.question.description');
      }

      return this.get('product.description');
    }),
    disable_delete: Ember.computed('mapping.parent.is_bundle', 'mapping.project.in_setup', 'configuration.in_setup', 'mapping.isNew', function () {
      if (this.get('mapping.isNew')) {
        return false;
      }

      if (this.get('mapping.parent.is_bundle') && this.get('configuration') != null && this.get('configuration.in_setup') === false) {
        return true;
      } else if (this.get('mapping.project.in_setup') === false) {
        return true;
      }

      return false;
    }),
    actions: {
      remove() {
        Ember.RSVP.resolve(this.get('mapping')).then(mapping => {
          mapping.deleteRecord();
        });
      },

      update_mapping_default(mapping, is_default) {
        if (is_default === true) {
          const default_mappings = mapping.get('parent.mappings').filterBy('isDeleted', false).filterBy('is_default', true);

          for (const default_mapping of default_mappings.toArray()) {
            default_mapping.set('is_default', false);
          }
        }

        mapping.set('is_default', is_default);
      }

    }
  });

  _exports.default = _default;
});