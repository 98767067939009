define("manage/pods/companies/company/projects/project/products/edit/route", ["exports", "manage/mixins/handle-transition"], function (_exports, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project.reload(),
        product: this.store.findRecord('product', params.product_id, {
          include: [// 'downloads',
          // 'keys', // I don't think we load keys
          'zones', 'zones.locations', // 'mappings', // don't need mappings for normal products
          'questions', 'questions.choices', 'variants', 'variants.values', 'variations'].join(',')
        })
      });
    },

    redirect(model) {
      if (['bundle', 'option'].includes(model.product.get('product_type'))) {
        this.transitionTo('companies.company.projects.project.products.choices.edit', model.product.get('id'));
      }

      if (['unknown'].includes(model.product.get('product_type'))) {
        this.transitionTo('companies.company.projects.project.products.index');
      }
    },

    afterModel(model) {
      // Create preliminary variation or ensure updated variations
      return this.get('productTasks.processVariations').perform(model.product);
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product) {
          let product = record.product;
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(product).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: product,
                  modelName: "Product",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});