define("manage/instance-initializers/stripe", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    window.stripe = window.Stripe(_environment.default.stripe.publishable_key);
  }

  var _default = {
    name: 'stripe',
    initialize: initialize
  };
  _exports.default = _default;
});