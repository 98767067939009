define("manage/pods/companies/company/projects/project/welcome/route", ["exports", "manage/pods/companies/company/projects/project/welcome/wizard"], function (_exports, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_wizard.default, {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      let include = [// Project items
      'sources'].join(',');
      return this.store.findRecord('project', project.get('id'), {
        include: include
      });
    },

    redirect(project) {
      this.replaceWith(this.get('nextRoute')(project));
    }

  });

  _exports.default = _default;
});