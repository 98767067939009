define("manage/pods/companies/company/projects/project/orders/view/components/remove-order-selection-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    removeTask: (0, _emberConcurrency.task)(function* (order_selection) {
      order_selection = yield order_selection;
      let order = yield order_selection.get('order');
      yield this.get('orderTasks.removeOrderSelection').perform(this.get('order_selection'), true);
      yield this.get('orderTasks.save').perform(order, {
        validate: false,
        save_shipping_address: false
      });
      yield this.get('store').findRecord('order', order.get('id'), {
        include: ['lines', 'selections'].join(',')
      });
    }),
    actions: {
      approve() {
        if (this.get('removeTask.isRunning')) {
          return;
        }

        this.get('removeTask').perform(this.get('order_selection')).then(() => {
          this.closeModal();
        }).catch(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to remove Order Selection."
          });
        });
      }

    }
  });

  _exports.default = _default;
});