define("manage/pods/admin/companies/company/invoices/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let company = this.modelFor('admin.companies.company');
      return Ember.RSVP.hash({
        invoices: this.store.query('company-invoice', {
          filter: {
            company: company.get('id')
          },
          page: {
            size: 100
          },
          sort: '-invoice-date'
        }),
        company: company
      });
    }

  });

  _exports.default = _default;
});