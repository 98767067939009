define("manage/pods/companies/company/projects/project/launch-guide/components/lg-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['lg-progress', 'component'],
    show_progress: true,

    init() {
      this._super(...arguments);

      this.set('components', Ember.A());
    },

    components: null,
    steps: Ember.computed('components.@each.visible', function () {
      return this.get('components').filterBy('visible', true).get('length') || 0;
    }),
    completed: Ember.computed('components.@each.{completed,visible}', function () {
      return this.get('components').filter(component => component.get('completed') === true && component.get('visible') === true).get('length') || 0;
    }),
    progress: Ember.computed('steps', 'completed', function () {
      if (this.get('steps') === 0) {
        return 0;
      }

      return this.get('completed') / this.get('steps') * 100;
    }),
    actions: {
      register(component) {
        this.get('components').addObject(component);
      },

      unregister(component) {
        this.get('components').removeObject(component);
      }

    }
  });

  _exports.default = _default;
});