define("manage/pods/companies/company/projects/project/orders/components/app-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KXDhc8w6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"order_status\"]]],null,{\"statements\":[[0,\", \"],[1,[22,\"order_status\"],false]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/components/app-status/template.hbs"
    }
  });

  _exports.default = _default;
});