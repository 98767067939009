define("manage/components/rollback-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/modal-task"], function (_exports, _ssModal, _modalTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _modalTask.default, {
    closable: true
  });

  _exports.default = _default;
});