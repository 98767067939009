define("manage/components/country-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YernH/5a",
    "block": "{\"symbols\":[\"country\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"country-dropdown\"],[8],[0,\"\\n  \"],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"loadCountriesTask\",\"last\",\"value\",\"is_limited\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"limited\"],[8],[0,\"Only ships to certain countries\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[5,\"power-select\",[],[[\"@triggerId\",\"@placeholder\",\"@renderInPlace\",\"@searchField\",\"@searchEnabled\",\"@loadingMessage\",\"@selected\",\"@options\",\"@onChange\",\"@tabindex\"],[\"country-dropdown\",\"Select Country\",[22,\"renderInPlace\"],\"name\",true,\"Loading Countries\",[22,\"country\"],[22,\"grouped_countries\"],[22,\"onChange\"],[22,\"tabindex\"]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/country-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});