define("manage/pods/admin/companies/company/components/edit-category-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    submitted: false,
    // Passed into component
    project: null,

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let categories = yield this.store.query('category', {
        page: {
          size: 100
        }
      });
      this.set('categories', categories);
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      yield project.save();
    }),
    actions: {
      clear_child_category(project) {
        project.set('child_category', null);
      },

      approve() {
        this.set('submitted', true);
        this.get('saveTask').perform(this.get('project')).then(() => this.closeModal()).catch(() => alert('Unable to save!'));
      }

    }
  });

  _exports.default = _default;
});