define("manage/pods/companies/company/projects/project/segments/actions/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      state: {
        refreshModel: true
      },
      operation: {
        refreshModel: true
      },
      project_segment: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        project_segment_actions: this.search(project, params)
      });
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let state = params.state;
      let operation = params.operation;
      let filters = {
        project: project.get('id')
      };

      if (Ember.isPresent(state)) {
        filters['state'] = state;
      }

      if (Ember.isPresent(operation)) {
        filters['operation'] = operation;
      }

      return this.store.query('project-segment-action', {
        include: ['segment'].join(','),
        filter: filters,
        page: {
          number: page,
          size: pageSize
        },
        sort: "-created_at"
      });
    }

  });

  _exports.default = _default;
});