define("manage/models/project-translation", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-translation').extend({
    initializeEmptyProperties() {
      ["rewards", "extras", "address", "confirm", "review", "refuse", "phrase", "summary"].forEach(key => {
        if (this.get(`checkout.${key}`) == null) {
          this.set(`checkout.${key}`, {});
        }
      });
    }

  });

  _exports.default = _default;
});