define("manage/pods/companies/company/projects/project/segments/components/add-product-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/filters/products", "manage/mixins/segment-action", "ember-cp-validations", "ember-concurrency"], function (_exports, _ssModal, _products, _segmentAction, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    custom_price: {
      description: "Custom Price",
      disabled: Ember.computed('model.price_type', function () {
        return this.model.get('price_type') != 'custom';
      }),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        allowNone: false,
        gte: 0
      })]
    },
    custom_shipping_price: {
      description: "Custom Shipping Price",
      disabled: Ember.computed('model.shipping_price_type', function () {
        return this.model.get('shipping_price_type') != 'custom';
      }),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        allowNone: false,
        gte: 0
      })]
    }
  });
  const lineTypeOptions = [Ember.Object.create({
    id: 'manual',
    title: 'Manual'
  }), Ember.Object.create({
    id: 'reward',
    title: 'Reward'
  }), Ember.Object.create({
    id: 'extra',
    title: 'Extra'
  }), Ember.Object.create({
    id: 'external',
    title: 'External'
  })];
  const priceTypeOptions = [Ember.Object.create({
    id: 'live',
    title: 'Live'
  }), Ember.Object.create({
    id: 'custom',
    title: 'Custom'
  }), Ember.Object.create({
    id: 'free',
    title: 'Free'
  })];

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, _products.default, Validations, {
    classNames: ['add-product-modal', 'segment-modal', 'component'],
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    closable: false,
    type: 'add-product',
    product: Ember.computed.readOnly('order_selection.product'),
    line_type: 'manual',
    line_type_options: lineTypeOptions,
    price_type: 'live',
    price_type_options: priceTypeOptions,
    custom_price: null,
    custom_price_shown: false,
    shipping_price_type: 'live',
    shipping_price_type_options: priceTypeOptions,
    custom_shipping_price: null,
    custom_shipping_price_shown: false,

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let order = this.get('store').createRecord('order', {});
      let order_line = this.get('store').createRecord('order-line', {});
      let order_selection = this.get('store').createRecord('order-selection', {
        project: project,
        order: order,
        line: order_line
      });
      this.set('order_selection', order_selection);
    }),
    setProductTask: (0, _emberConcurrency.task)(function* (product) {
      try {
        product = yield product;
        product = yield this.get('store').findRecord('product', product.get('id'), {
          include: ['variations', 'questions', 'questions.choices'].join(',')
        });
        let order_selection = yield this.get('order_selection');
        order_selection.set('product', product);
        order_selection.set('product_variation', null); // This also updates questions

        yield this.get('orderTasks.updateOrderSelection').perform(order_selection);
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to update product selection."
        });
      }
    }).drop(),
    show_selection_error_message: Ember.computed('submitted', 'order_selection.in_bundle', 'order.is_completed', 'order_selection.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      if (this.get('order_selection.in_bundle')) {
        return this.get('order_selection.validations.isInvalid');
      } // TODO: Update backend to allow creating as a creator with partial information
      // if (!this.get('order.is_completed')) {
      //   return false;
      // }


      return this.get('order_selection.validations.isInvalid');
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let order_selection = yield this.get('order_selection');
      let {
        validations: selection_validations
      } = yield order_selection.validate();

      if (!selection_validations.get('isValid')) {
        return;
      }

      let product = yield order_selection.get('product');
      let product_variation = yield order_selection.get('product_variation');
      let questions = yield order_selection.get('questions'); // Map into an object with id's as property names and answers as property values

      let item_questions = questions.reduce((map, obj) => {
        map[obj.get('product_question.id')] = obj.get('text') || obj.get('product_question_choice.id');
        return map;
      }, {});

      if (this.get('actionTask.isRunning')) {
        return;
      }

      let args = {
        product_id: product.get('id'),
        product_variation_id: product_variation ? product_variation.get('id') : null,
        line_type: this.get('line_type'),
        price_type: this.get('price_type'),
        shipping_price_type: this.get('shipping_price_type'),
        item_questions: item_questions
      };
      if (args['price_type'] == 'custom') args['custom_price'] = this.get('custom_price');
      if (args['shipping_price_type'] == 'custom') args['custom_shipping_price'] = this.get('custom_shipping_price');
      this.get('actionTask').perform(args);
    }),
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      setPriceType(price_type) {
        this.set('price_type', price_type.id);
        this.set('custom_price_shown', price_type.id == 'custom');
      },

      setShippingPriceType(price_type) {
        this.set('shipping_price_type', price_type.id);
        this.set('custom_shipping_price_shown', price_type.id == 'custom');
      }

    }
  });

  _exports.default = _default;
});