define("manage/pods/companies/company/projects/project/segments/view/index/controller", ["exports", "manage/config/environment", "manage/mixins/bulk-actions"], function (_exports, _environment, _bulkActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bulkActions.default, {
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: ['page', 'page_size'],
    project: Ember.computed.readOnly('model.project'),
    segment: Ember.computed.readOnly('model.segment'),
    segment_actions: Ember.computed.readOnly('model.segment_actions'),
    api_url: _environment.default.urls.api,
    // Paging
    page: 1,
    page_size: 10,
    // Sorting
    sortProperty: 'created_at',
    sortDirection: 'desc',
    sorted_by: Ember.computed('sortProperty', 'sortDirection', function () {
      return [`${this.get('sortProperty')}:${this.get('sortDirection')}`];
    }),
    actions: {
      refresh_route() {
        // Refresh the route
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      },

      view_action(project_segment_action) {
        return this.transitionToRoute('companies.company.projects.project.segments.actions.view', project_segment_action.get('segment.id'), project_segment_action.get('id'));
      }

    }
  });

  _exports.default = _default;
});