define("manage/components/authentication-status/component", ["exports", "ember-concurrency", "authentication/services/jwt_session"], function (_exports, _emberConcurrency, _jwt_session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 1000;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'menu', 'authentication-status', 'component'],
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('jobLastUpdate', new Date().getTime());
      this.get('refreshTask').perform();
    },

    expiresAt: Ember.computed('session.data.authenticated.token', 'session.data.authenticated.type', function () {
      const data = this.get('session.data');

      if (Ember.isEmpty(data.authenticated) || data.authenticated.type !== 'token' || Ember.isBlank(data.authenticated.token)) {
        return Infinity;
      }

      const session = _jwt_session.default.create({
        token: data.authenticated.token
      });

      return new Date(session.exp());
    }),
    refreshIn: Ember.computed('expiresAt', 'jobLastUpdate', function () {
      const expiresAt = this.get('expiresAt');

      if (expiresAt === Infinity) {
        return null;
      }

      const now = new Date().getTime();
      return Math.round((expiresAt.getTime() - now) / 1000);
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      do {
        let jobLastUpdate = this.get('jobLastUpdate');
        let currentTime = new Date().getTime();

        if (currentTime - jobLastUpdate > DELAY + DELAY / 50) {
          try {
            if (!this.get('isDestroyed')) {
              this.set('jobLastUpdate', new Date().getTime());
            }
          } catch (e) {// Continue
          }
        }

        if (!this.get('isDestroyed')) {
          yield (0, _emberConcurrency.timeout)(DELAY);
        }
      } while (!this.get('isDestroyed') && !Ember.testing);
    }).drop(),
    actions: {
      enableRefresh() {
        this.get('user').maintainTokenSession();
      },

      disableRefresh() {
        this.set('user.maintainSession', false);
      }

    }
  });

  _exports.default = _default;
});