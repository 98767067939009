define("manage/pods/admin/companies/company/components/edit-transaction-fee-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    submitted: false,
    // Passed into component
    entity: null,
    fee: Ember.computed('entity.transaction_fee', {
      get() {
        return parseInt(this.get('entity.transaction_fee'), 10) / 10;
      },

      set(key, value) {
        if (Ember.isBlank(value)) {
          this.set('entity.transaction_fee', 0);
        } else {
          this.set('entity.transaction_fee', Math.floor(parseFloat(value) * 10));
        }

        return parseInt(this.get('entity.transaction_fee'), 10) / 10;
      }

    }),
    saveTask: (0, _emberConcurrency.task)(function* (entity) {
      entity = yield entity; // let { validations: validations } = yield entity.validate();
      // if (!validations.get('isValid')) {
      //   throw validations;
      // }

      yield entity.save();
    }),
    actions: {
      approve() {
        this.set('submitted', true);
        this.get('saveTask').perform(this.get('entity')).then(() => this.closeModal()).catch(() => alert('Unable to save!'));
      }

    }
  });

  _exports.default = _default;
});