define("manage/components/edit-custom-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8tJKE4r7",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"edit-field\",null,[[\"model\",\"propertyName\",\"fieldType\",\"currencySymbol\",\"currency\",\"options\",\"onChange\"],[[24,[\"entity\",\"custom\"]],[24,[\"custom_field\",\"public_id\"]],[24,[\"custom_field\",\"field_type\"]],[24,[\"project\",\"currency_symbol\"]],[24,[\"project\",\"currency\"]],[24,[\"custom_field\",\"values_text\"]],[28,\"action\",[[23,0,[]],\"onValueChanged\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/edit-custom-field/template.hbs"
    }
  });

  _exports.default = _default;
});