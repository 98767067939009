define("manage/pods/companies/company/projects/project/welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pExjys5X",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t-title\",[[23,0,[]]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"inner layout\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wizard container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"logo\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"crowdox logo\"],[8],[0,\"\\n          \"],[1,[22,\"icon-ox\"],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[1,[28,\"t\",[\"phrase.crowdox\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n          \"],[1,[28,\"t-component\",[[23,0,[]],\"header\"],null],false],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[1,[28,\"t-component\",[[23,0,[]],\"sub_header\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui basic segment content\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n      \"],[1,[28,\"t-component\",[[23,0,[]],\"footer\"],[[\"email\",\"htmlSafe\"],[\"hello@crowdox.com\",true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/welcome/template.hbs"
    }
  });

  _exports.default = _default;
});