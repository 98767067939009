define("manage/pods/companies/company/projects/project/questions/index/components/column-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "44dn4bbE",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"product_question\",\"question\",\"text\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"product_question\",\"question\",\"description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[18,\"data-tooltip\",[24,[\"product_question\",\"question\",\"description\"]]],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon link info letter large\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/questions/index/components/column-name/template.hbs"
    }
  });

  _exports.default = _default;
});