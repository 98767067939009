define("manage/pods/companies/company/projects/project/sources/configurations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        project: this.modelFor('companies.company.projects.project'),
        source: this.modelFor('companies.company.projects.project.sources')
      });
    }

  });

  _exports.default = _default;
});