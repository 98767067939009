define("manage/pods/companies/company/projects/project/components/order-filters/component", ["exports", "manage/mixins/order-filters", "jquery"], function (_exports, _orderFilters, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_orderFilters.default, {
    classNames: ['order-filters', 'component'],
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.build_filter_selections(this.get('filter_data'));
      this.set('filters', Ember.A());
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (Object.keys(this.get('filter_data') || {}) != this.get('filter_ids')) {
        this.build_filter_selections(this.get('filter_data'));
        this.set('filters', Ember.A());
      }

      this.process_filters_from_data();
      this.process_friendly_text();
    },

    // One observer for linked products
    product_changed: Ember.observer('product_filter.value.[]', function () {
      let product_variation_filter = this.get('filters').findBy('id', 'product_variation');

      if (Ember.isBlank(product_variation_filter) || Ember.isBlank(product_variation_filter.get('value'))) {
        // If we don't have anything in the variation filter, return. No work to do
        return;
      } // If the product_filter is blank, clear the variation filter


      if (Ember.isBlank(this.get('product_filter'))) {
        product_variation_filter.get('value').clear();
        return;
      } // We have products and variations, ensure its all legit


      let product_ids = this.get('product_filter.value').map(product => product.get('id').toString());
      let to_remove = Ember.A();

      for (let product_variation of product_variation_filter.get('value')) {
        if (!product_ids.includes(product_variation.get('product.id').toString())) {
          to_remove.push(product_variation);
        }
      }

      if (Ember.isPresent(to_remove)) {
        product_variation_filter.get('value').removeObjects(to_remove);
      }
    }),
    active_filters: Ember.computed('filters.@each.{operator,value}', function () {
      let active_filters = this.get('filters').filter(filter => {
        return ['none', 'any'].indexOf(filter.get('operator')) >= 0 || Ember.isPresent(filter.get('value'));
      });
      return active_filters.get('length');
    }),

    // Power select callbacks (If you need access to "this", you have to make it an action)
    calculatePosition(trigger
    /*, content*/
    ) {
      // If the screen is < 1000 px take 90% of the screen width
      // otherwise, take 900px
      let screen_width = (0, _jquery.default)(window).width();
      let width = 900;

      if (screen_width < 1000) {
        width = screen_width * 0.9;
      }

      let {
        top,
        height
      } = trigger.getBoundingClientRect();
      let style = {
        left: (screen_width - width) / 2,
        top: top + window.pageYOffset + height + 10,
        width: width
      };
      return {
        style
      };
    },

    actions: {
      onOpen(dropdownApi) {
        this.set('dropdownApi', dropdownApi);
      },

      // On close we assume the following
      // If they clicked "Apply" or off the filter dropdown (onto the general screen), then apply the filters
      // If they click cancel, reset any changed filters to the original default values
      onClose() {
        // If we specifically hit cancel or apply
        if (this.get('already_processed')) {
          this.set('already_processed', false);
          return;
        }

        this.update_values_from_filters();
      },

      cancel() {
        // Reset all filters back to their data value
        this.process_filters_from_data();
        this.set('already_processed', true);
        this.get('dropdownApi').actions.close();
      },

      apply() {
        // Handle the applying of the filters in the onClose action
        this.update_values_from_filters();
        this.set('already_processed', true);
        this.get('dropdownApi').actions.close();
      }

    }
  });

  _exports.default = _default;
});