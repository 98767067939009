define("manage/initializers/cookie-consent", ["exports", "manage/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    window.addEventListener("load", function () {
      // Let's only load this javascript in the event that the user hasn't opted into our cookie usage.
      if (document.cookie.split('; ').indexOf('cookieconsent_status=dismiss') == -1) {
        _jquery.default.getScript("//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js", function () {
          window.cookieconsent.initialise({
            "cookie": {
              "domain": `.${_environment.default.domain}`
            },
            "palette": {
              "popup": {
                "background": "#6a6666"
              },
              "button": {
                "background": "#fc7335",
                "text": "#fafafa"
              }
            },
            "theme": "classic",
            "content": {
              "message": "Crowd Ox uses cookies to ensure you get the best experience on our website.",
              "href": "https://crowdox.com/legal/cookies/"
            }
          });
        });
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});