define("manage/pods/companies/company/projects/project/settings/fields/route", ["exports", "manage/pods/companies/company/projects/project/settings/fields/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let controller = this.controllerFor(this.routeName);
      let filter = {
        project: project.get('id')
      };
      let page = {
        size: params.page_size || controller.get('page_size'),
        number: params.page || controller.get('page')
      };
      return Ember.RSVP.hash({
        project: project,
        custom_fields: this.store.query('project-custom-field', {
          include: 'values',
          page: page,
          filter: filter
        })
      });
    }

  });

  _exports.default = _default;
});