define("manage/pods/companies/company/projects/project/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),
    intl: Ember.inject.service(),

    model(params) {
      let include = ['payment-gateways', 'addresses', 'sources', // Used for menu bar
      'configurations', // Used for project status
      // 'zone-locations',
      // 'zones',
      // 'emails',
      // Load stats
      'stats', // Used on project
      'configuration-stats', // 'product-stats',
      // Loading these for now, remove this as we better load data in components and on each page
      // Product items
      'products', // Used for filters
      // 'product-mappings',
      'product-questions', // 'product-question-choices',
      'product-variants', // Used for variant filters
      'product-variant-values', // Used for variant filters
      'product-variations', // Used for filters
      // 'product-downloads',
      // 'product-zone-locations',
      // 'product-zones',
      'translations', 'apps', // Used for menu bar
      'apps.app.app-type', 'digital-fulfillments', 'custom-fields', 'custom-fields.values', 'vat-categories'].join(',');
      return this.store.findRecord('project', params.project_id, {
        include: include
      }).catch(() => this.replaceWith('companies.company.projects.project/not-found'));
    },

    redirect(project) {
      // Don't do any redirecting for admins
      if (this.get('current_user.is_admin')) {
        return;
      }

      if (Ember.isBlank(project.get('settings.wizard.completed_on'))) {
        this.replaceWith('companies.company.projects.project.welcome');
        return;
      }
    },

    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('companies/company/projects/project', {
        into: 'shared/main'
      });
      this.render('companies/company/projects/project/menu', {
        into: 'shared/main',
        outlet: 'menu'
      });
    }

  });

  _exports.default = _default;
});