define("manage/models/project-source-settings", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _fragment, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('belongs-to')]
    },
    wizard: {
      description: "Wizard",
      validators: [(0, _emberCpValidations.validator)('belongs-to')]
    },
    import: {
      description: "Import",
      validators: [(0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    email: (0, _attributes.fragment)('project-source-settings-email', {
      defaultValue: {}
    }),
    wizard: (0, _attributes.fragment)('project-source-settings-wizard', {
      defaultValue: {}
    }),
    import: (0, _attributes.fragment)('project-source-settings-import', {
      defaultValue: {}
    })
  });

  _exports.default = _default;
});