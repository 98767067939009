define("manage/components/icon-people/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nkit+ww5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",[29,[[22,\"width\"]]]],[11,\"height\",[29,[[22,\"height\"]]]],[10,\"viewBox\",\"0 0 24 22\"],[10,\"version\",\"1.1\"],[8],[7,\"path\",true],[10,\"d\",\"M23.9750834 16.7 C23.6250697 15 22.6 13.5 21 12.6 C20.6499531 12.4 20.2 12.2 19.7 12 C18.8498825 11.6 17.9 11.2 17.7 10.6 C17.5498316 10.1 17.9 9.4 18.3 8.9 C19.8999237 6.3 20.2 4.1 19.1 2.2 C18.27486 0.8 16.8 0 15.2 0 C13.6996806 0 12.9 0.7 12 1.8 C13.249663 2.4 14 3.2 14.7 4.4 C15.72476 6.1 16.1 9.1 13.7 13.2 C13.8246855 13.2 14 13.3 14.1 13.4 C14.6247169 13.6 15.2 13.8 15.7 14.1 C17.1998178 15.1 18.3 16.4 19 17.9 C22.050008 17.8 23.3 17.6 23.4 17.6 C23.5500668 17.6 23.8 17.5 23.9 17.3 C23.9750834 17.1 24 16.9 24 16.7 L23.9750834 16.7 Z M8.79948856 21.2 C14.6747189 21.2 16.9 20.9 17 20.9 C17.1998178 20.9 17.4 20.8 17.5 20.6 C17.6248345 20.4 17.7 20.2 17.6 20 C17.2498198 18.3 16.2 16.8 14.7 15.9 C14.2747032 15.7 13.8 15.5 13.3 15.3 C12.4996336 14.9 11.5 14.5 11.3 13.9 C11.1995826 13.4 11.6 12.7 11.9 12.2 C13.5246738 9.6 13.8 7.4 12.7 5.5 C11.9246111 4.1 10.4 3.3 8.8 3.3 C7.17442486 3.3 5.7 4.1 4.9 5.5 C3.79929256 7.4 4.1 9.6 5.7 12.2 C6.04938076 12.7 6.4 13.4 6.3 13.9 C6.09938272 14.5 5.1 14.9 4.3 15.3 C3.79929256 15.5 3.3 15.7 2.9 15.9 C1.44920044 16.8 0.4 18.3 0 20 C-0.0258573789 20.2 -0 20.4 0.1 20.6 C0.249153401 20.8 0.4 20.9 0.6 20.9 C0.724172021 20.9 2.9 21.2 8.8 21.2 L8.79948856 21.2 Z\"],[10,\"id\",\"Type-something-2\"],[10,\"fill\",\"#5A5A5A\"],[10,\"sketch:type\",\"MSShapeGroup\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/icon-people/template.hbs"
    }
  });

  _exports.default = _default;
});