define("manage/mixins/ss-modal", ["exports", "ember-spread", "manage/mixins/ss-transition", "jquery"], function (_exports, _emberSpread, _ssTransition, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Relative path works since both survey and manage are in lib/...
  const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
    setTimeout(callback, 0);
  };

  var _default = Ember.Mixin.create(_emberSpread.default, _ssTransition.default, {
    classNames: ['ui', 'modal'],
    // Defaults
    padding: 50,
    offset: 0,
    closable: false,
    isActiveModal: true,
    _isShown: false,
    // Transition Defaults
    transitionMode: 'scale',
    transitionDuration: 500,
    isActiveChanged: Ember.observer('isActiveModal', function () {
      Ember.run.scheduleOnce('afterRender', () => {
        if (this.get('isActiveModal')) {
          (0, _jquery.default)(this.element).removeClass('secondary');
        } else {
          (0, _jquery.default)(this.element).addClass('secondary');
        }
      });
    }),

    // Setup and destroy
    didInsertElement() {
      this._super(...arguments); // Ensure scrolling is gone for initial render


      this.removeScrolling(); // Add body classes

      (0, _jquery.default)('body').addClass('dimmable dimmed'); // Set values

      this.doRefresh();
      this.transitionIn();
      (0, _jquery.default)(window).on('resize.ss-modal-' + this.get('elementId'), Ember.run.bind(this, this.doRefreshWithAnimation));

      if (this.get('closable')) {
        (0, _jquery.default)(document).on('click.ss-modal-' + this.get('elementId'), Ember.run.bind(this, this.checkClick));
      }

      this.observeChanges();
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(window).off('resize.ss-modal-' + this.get('elementId'));
      (0, _jquery.default)(document).off('click.ss-modal-' + this.get('elementId'));

      if (this.get('observer') != null) {
        this.get('observer').disconnect();
      }

      (0, _jquery.default)('body').removeClass('dimmable dimmed');
    },

    // Events
    checkClick(event) {
      if (!this.get('_isShown')) {
        return;
      }

      let target = (0, _jquery.default)(event.target);
      let isInModal = target.closest((0, _jquery.default)(this.element)).length > 0;

      let isInDOM = _jquery.default.contains(window.document.documentElement, event.target);

      if (!isInModal && isInDOM) {
        this.closeModal();
      }
    },

    observeChanges() {
      if ('MutationObserver' in window) {
        let observer = new MutationObserver(() => this.doRefresh());
        observer.observe(this.get('element'), {
          childList: true,
          subtree: true
        });
        this.set('observer', observer);
      }
    },

    // Public method to close
    closing() {},

    // callback hook
    closeModal() {
      this.closing();
      this.transitionOut();
    },

    opened() {},

    // callback hook
    // Overwrite transition callback
    shown() {
      this.set('_isShown', true);
      this.opened();
    },

    // Overwrite transition callback
    hidden() {
      let modalClosed = this.get('modal_closed');

      if (typeof modalClosed === "function") {
        modalClosed();
      }
    },

    // Functions
    doRefresh(sizes) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (Ember.isBlank(sizes)) {
        sizes = this.getSizes();
      }

      this.setScreenHeight(sizes);
      this.setType(sizes);
      this.setPosition(sizes);
    },

    doRefreshWithAnimation() {
      requestAnimationFrame(Ember.run.bind(this, this.doRefresh, null));
    },

    // Method functions
    getSizes() {
      let modalHeight = (0, _jquery.default)(this.element).outerHeight();
      return {
        pageHeight: (0, _jquery.default)(document).outerHeight(),
        height: modalHeight + this.get('offset'),
        contextHeight: (0, _jquery.default)(window).height()
      };
    },

    canFit(sizes) {
      return sizes.height + this.get('padding') * 2 < sizes.contextHeight;
    },

    removeScrolling() {
      (0, _jquery.default)('body').removeClass('scrolling');
      (0, _jquery.default)(this.element).parent().css({
        overflow: ''
      });
      (0, _jquery.default)(this.element).removeClass('scrolling');
    },

    setScrolling() {
      (0, _jquery.default)('body').addClass('scrolling');
      (0, _jquery.default)(this.element).parent().css({
        overflow: 'auto'
      });
      (0, _jquery.default)(this.element).addClass('scrolling');
    },

    setPosition(sizes) {
      if (this.canFit(sizes)) {
        (0, _jquery.default)(this.element).css({
          top: '',
          marginTop: -(sizes.height / 2)
        });
      } else {
        (0, _jquery.default)(this.element).css({
          marginTop: '',
          top: (0, _jquery.default)(document).scrollTop()
        });
      }
    },

    setScreenHeight(sizes) {
      if (this.canFit(sizes)) {
        (0, _jquery.default)('body').css('height', '');
      } else {
        (0, _jquery.default)('body').css('height', sizes.height + this.get('padding') * 2);
      }
    },

    setType(sizes) {
      if (this.canFit(sizes)) {
        this.removeScrolling();
      } else {
        this.setScrolling();
      }
    },

    actions: {
      close() {
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});