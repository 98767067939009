define("manage/helpers/json-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringify = stringify;

  function stringify(_ref, args) {
    let [json] = _ref;

    if (args.prettyPrint === true) {
      return JSON.stringify(json, null, '  ');
    } else {
      return JSON.stringify(json);
    }
  }

  var _default = Ember.Helper.helper(stringify);

  _exports.default = _default;
});