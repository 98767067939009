define("manage/pods/companies/company/projects/project/digital/view/keys/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'digital_key',
    componentPrefix: 'companies/company/projects/project/digital/view/keys',
    i18nPrefix: 'digital.view.keys',
    colDefs: function (model) {
      let columnInfo = [{
        id: 'id',
        sort: 'id'
      }, {
        id: 'key',
        default_visible: true
      }]; // Show the Provider column if at least one of the fulfillment providers is not the default.

      if (model.fulfillment_providers.any(provider => provider.slug !== 'default')) {
        columnInfo.push({
          id: 'provider',
          default_visible: true
        });
      }

      columnInfo.push({
        id: 'order',
        default_visible: true
      });
      return columnInfo;
    }
  });

  _exports.default = _default;
});