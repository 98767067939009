define("manage/pods/companies/company/projects/project/segments/components/delete-export-action-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['delete-segment-action-modal', 'component'],
    closable: false,
    isRunning: Ember.computed.alias('deleteRecordTask.isRunning'),
    deleteRecordTask: (0, _emberConcurrency.task)(function* () {
      let segment_action = yield this.get('segment_action');
      segment_action.deleteRecord();
      yield segment_action.save();
    }),
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('deleteRecordTask.isRunning')) {
          return;
        }

        this.get('deleteRecordTask').perform().then(() => {
          this.closeModal();
        });
      }

    }
  });

  _exports.default = _default;
});