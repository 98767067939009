define("manage/pods/companies/company/projects/project/components/edit-location-zone-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/pods/companies/company/projects/project/components/edit-location-zones/component"], function (_exports, _ssModal, _emberConcurrency, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['edit-location-zone-modal', 'component'],
    store: Ember.inject.service(),
    closable: false,
    // Required parameters to be passed in (might be null)
    project: null,
    parent: null,
    parentModelName: 'product',
    zoneNameRelations: null,
    costType: null,
    shippingPriceComponent: 'companies/company/projects/project/edit-location-zone-price',
    disable_shipping: false,
    // Optional depending on condition
    zone: null,
    location_type: 'countries',
    // Selection can be "everywhere_else", "countries", "states"
    // Internal flag
    isNew: false,
    // Is new is only true if zone is null, since we can't save until its all done
    submitted: false,

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let countries = yield this.get('store').peekAll('country');
      this.set('countries', countries.toArray());
      let states = yield this.get('store').peekAll('state');
      this.set('states', states.toArray());
      let zone = yield this.get('zone'); // Initial zone

      if (zone == null) {
        this.set('isNew', true);
        let zone = (0, _component.createZone)(this, this.get('location_type'), this.get('costType'));
        this.set('zone', zone);
      } else if (zone.get('name') === zone.get('generated_name') && this.get('disable_shipping') !== true) {
        // If we are using a generated name, null out the existing one, so any new names are update automatically
        zone.set('name', null);
      } // Now get existing countries


      let all_other_countries_locations = yield this.get('allOtherTask').perform(zone, 'countries');
      this.set('all_other_countries_locations', all_other_countries_locations); // Now get existing states

      let all_other_states_locations = yield this.get('allOtherTask').perform(zone, 'states');
      this.set('all_other_states_locations', all_other_states_locations); ////////////
      // Everywhere selection type configuration
      ////////////
      // Nothing specific to do for everywhere else
      ////////////
      // Countries selection type configuration
      ////////////

      if (this.get('zone.is_countries')) {
        for (let continent of this.get('continents')) {
          // If all countries in a continent are selected, then the continent is checked
          Ember.defineProperty(this, `c_${continent}_checked`, Ember.computed('countries_locations.@each.country', 'available_countries.@each.continent', function () {
            let selected = this.get('countries_locations').filterBy('country.continent', continent);
            let available = this.get('available_countries').filterBy('continent', continent);

            if (available.get('length') === 0) {
              return false;
            }

            return selected.get('length') === available.get('length');
          })); // If partial countries in the continent are selected, then continent is indeterminate

          Ember.defineProperty(this, `c_${continent}_indeterminate`, Ember.computed('countries_locations.@each.country', 'available_countries.@each.continent', function () {
            let selected = this.get('countries_locations').filterBy('country.continent', continent);
            let available = this.get('available_countries').filterBy('continent', continent);

            if (available.get('length') === 0) {
              return false;
            }

            if (selected.get('length') === available.get('length')) {
              return false;
            }

            return selected.get('length') > 0;
          }));
          Ember.defineProperty(this, `c_${continent}_countries`, Ember.computed('countries.@each.continent', function () {
            return this.get('countries').filterBy('continent', continent).sortBy('name');
          }));
          this.set(`c_${continent}_expanded`, false);
        }

        for (let country of this.get('available_countries')) {
          Ember.defineProperty(this, `${country.get('id')}_selected`, Ember.computed('countries_locations.@each.country', function () {
            return this.get('countries_locations').findBy('country.id', country.get('id')) != null;
          }));
        }
      } ////////////
      // States selection type configuration
      ////////////


      if (this.get('zone.is_states')) {
        // If its not new, lock the country in
        if (this.get('isNew') === false) {
          let country = yield this.get('locations.firstObject.country');
          this.send('set_country', country);
        }
      }
    }),
    allOtherTask: (0, _emberConcurrency.task)(function* (zone, location_type) {
      let existing_zones = yield this.get('parent').get(`${this.get('zoneNameRelations')}s`);
      existing_zones = existing_zones.filterBy('isDeleted', false).toArray();
      let all_other_locations = Ember.A();

      for (let existing_zone of existing_zones) {
        existing_zone = yield existing_zone;

        if (zone !== existing_zone) {
          // We filter by the type passed in to ensure we are only looking at a segment of data
          let existing_locations = yield existing_zone.get(`${location_type}_locations`);
          existing_locations = existing_locations.filterBy('isDeleted', false).toArray();
          all_other_locations = all_other_locations.concat(existing_locations);
        }
      }

      return all_other_locations;
    }),
    locations: Ember.computed.filterBy('zone.locations', 'isDeleted', false),
    // These names match the {location_type}_locations
    everywhere_else_locations: Ember.computed.filterBy('zone.everywhere_else_locations', 'isDeleted', false),
    countries_locations: Ember.computed.filterBy('zone.countries_locations', 'isDeleted', false),
    states_locations: Ember.computed.filterBy('zone.states_locations', 'isDeleted', false),
    ////////////
    // For countries selection type
    ////////////
    continents: Ember.computed('countries.@each.continent', function () {
      return (this.get('countries') || Ember.A()).mapBy('continent').filter((value, index, self) => self.indexOf(value) === index) // Magic filtering to get distinct list of continents
      .sort();
    }),
    available_countries: Ember.computed('countries.[]', 'all_other_countries_locations.@each.country', function () {
      let available_countries = Ember.A();

      for (let country of this.get('countries')) {
        if (this.get('all_other_countries_locations').findBy('country.id', country.get('id')) == null) {
          available_countries.push(country);
        }
      }

      return available_countries;
    }),
    ////////////
    // For states selection type
    ////////////
    countries_with_states: Ember.computed.filterBy('countries', 'has_states'),
    available_states: Ember.computed('country.states.[]', 'all_other_states_locations.@each.{country,state}', function () {
      let country = this.get('country');

      if (Ember.isBlank(country)) {
        return Ember.A();
      }

      let available_states = Ember.A();
      let country_locations = this.get('all_other_states_locations').filterBy('country.id', country.get('id'));

      for (let state of country.get('states').toArray()) {
        if (country_locations.findBy('state.id', state.get('id')) == null) {
          available_states.push(state);
        }
      }

      return available_states;
    }),
    country_has_rate: Ember.computed('country.id', 'all_other_countries_locations.@each.country', function () {
      let country = this.get('country');

      if (Ember.isBlank(country)) {
        return false;
      }

      return this.get('all_other_countries_locations').isAny('country.id', country.get('id'));
    }),

    ////////////
    // Helper methods common operations
    ////////////
    get_location_by_value(country, state) {
      // One of the few places we look at the zone.locations instead of alias
      // We specifically want all locations here, including deleted
      if (state != null) {
        return this.get('zone.locations').find(location => {
          return location.get('country.id') === country.get('id') && location.get('state.id') === state.get('id');
        });
      } // Ignore locations with states, including deleted


      return this.get('zone.locations').find(location => {
        return location.get('country.id') === country.get('id') && location.get('state.id') == null;
      });
    },

    create_or_rollback_location(country) {
      let state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let location_rate = this.get_location_by_value(country, state);

      if (location_rate == null) {
        location_rate = (0, _component.createLocation)(this, this.get('zone'), country, state);
      } else if (location_rate.get('isDeleted')) {
        location_rate.rollbackAttributes();
      }

      return location_rate;
    },

    delete_location(country) {
      let state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let location_rate = this.get_location_by_value(country, state);

      if (location_rate != null && !location_rate.get('isDeleted')) {
        location_rate.deleteRecord();
      }
    },

    updateTask: (0, _emberConcurrency.task)(function* () {
      let zone = yield this.get('zone');
      zone.normalize();
      this.set('submitted', true);
      let {
        validations: validations
      } = yield zone.validate();

      if (!validations.get('isValid')) {
        return;
      }

      this.closeModal();
    }),
    actions: {
      // Countries actions
      toggle_continent(continent) {
        if (this.get('disable_shipping') === true) {
          return;
        } // The 3 stages are as follows
        // unchecked -> partially checked (if not partially checked, skip) -> all checked -> unchecked


        let countries = this.get('available_countries').filterBy('continent', continent).toArray(); // If everything is checked, remove all

        if (this.get(`c_${continent}_checked`) === true) {
          for (let country of countries) {
            this.delete_location(country);
          }

          return;
        } // We know its not fully checked at this point, so check remaining
        // Works for indeterminate and not checked


        for (let country of countries) {
          this.create_or_rollback_location(country);
        }

        return;
      },

      continent_already_selected(continent) {
        return this.get('available_countries').findBy('continent', continent) == null;
      },

      country_already_selected(country) {
        return this.get('available_countries').findBy('id', country.get('id')) == null;
      },

      country_has_states_selected(country) {
        if (country.get('states.length') === 0) {
          return false;
        }

        return this.get('all_other_states_locations').isAny('country.id', country.get('id'));
      },

      // States actions
      set_country(country) {
        if (this.get('country') != null) {
          // Clear existing properties
          let locations = this.get('states_locations');

          for (let location of locations.toArray()) {
            location.deleteRecord();
          }
        }

        this.set('country', country);

        for (let state of country.get('states').toArray()) {
          Ember.defineProperty(this, `s_${state.get('id')}_selected`, Ember.computed('states_locations.@each.{country,state}', function () {
            return this.get('states_locations').filterBy('country.id', country.get('id')).findBy('state.id', state.get('id')) != null;
          }));
        }
      },

      state_already_selected(state) {
        return this.get('available_states').findBy('id', state.get('id')) == null;
      },

      // Generic actions
      toggle_location(country, state, selected) {
        if (this.get('disable_shipping') === true) {
          return;
        }

        if (selected === true) {
          this.create_or_rollback_location(country, state);
        } else {
          this.delete_location(country, state);
        }
      },

      cancel() {
        // This is only called if its New. Its only new the first time its created.
        // Re-opening the modal with a zone doesn't count as new even if the model isNew
        let zone = this.get('zone');
        let locations = zone.get('locations');

        for (let location of locations.toArray()) {
          location.rollbackAttributes();
        }

        zone.rollbackAttributes();
        this.closeModal();
      },

      update() {
        this.get('updateTask').perform();
      }

    }
  });

  _exports.default = _default;
});