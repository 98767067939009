define("manage/mirage/factories/project", ["exports", "shared/mirage/factories/project", "shared/mirage/translations"], function (_exports, _project, _translations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _project.default.extend({
    purpose: 'pledge_management',
    currency: 'USD',
    support: {
      email: 'email@support.com'
    },
    locales: ['en-us'],

    afterCreate(project, server) {
      let existingSettings = project.settings || {};
      let settings = Object.assign({
        wizard: {
          completed_on: new Date(),
          last_completed_step: 'settings'
        }
      }, existingSettings);
      project.update({
        settings
      });

      if (server.db.countries.length == 0) {
        server.loadFixtures('countries', 'states');
      }

      project.update({
        countryId: 1
      });
      server.create('project-translation', {
        projectId: project.id,
        locale: 'en-us',
        phrase: _translations.default.purpose[project.purpose]['phrase'],
        lines: _translations.default.purpose[project.purpose]['lines'],
        source: _translations.default.purpose[project.purpose]['source'],
        checkout: {}
      });
    }

  });

  _exports.default = _default;
});