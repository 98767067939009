define("manage/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "bulk-action": {
      "add-product": {
        "subheader": "This will add a product to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Add Product",
        "title": "Add Product"
      },
      "add-tag": {
        "subheader": "This will add tags on the {count, plural, one {order} other {{count} orders}} in this segment.",
        "title": "Add Tags"
      },
      "cancel": {
        "subheader": "This will cancel the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Cancel {count, plural, one {Order} other {Orders}}",
        "title": "Cancel Orders"
      },
      "change-configuration": {
        "subheader": "This will change the {reward} on the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Change {rewards}",
        "title": "Change {reward}"
      },
      "change-product-variation": {
        "subheader": "This will swap one product variation with another",
        "submit-button": "Change Product Variation",
        "title": "Change Product Variation"
      },
      "complete": {
        "subheader": "This will attempt to complete the {count, plural, one {order} other {{count} orders}} in this segment. <em>This is irreversible.</em>",
        "submit-button": "Complete",
        "title": "Complete Orders"
      },
      "credit": {
        "subheader": "This will add a credit to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Add Credits",
        "title": "Add Credits"
      },
      "custom-field": {
        "subheader": "This will update the custom fields on the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Update",
        "title": "Update Custom Fields"
      },
      "delete": {
        "subheader": "This will delete the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Delete",
        "title": "Delete Orders"
      },
      "delete-selections": {
        "subheader": "This will delete the selections from {count, plural, one {1 order} other {{count} orders}} in this segment.",
        "submit-button": "Delete Selections",
        "title": "Delete Order Selections"
      },
      "email": {
        "subheader": "This will send emails to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Send",
        "title": "Send Emails"
      },
      "lock": {
        "subheader": "This will add the <strong>{process}</strong> lock to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Lock",
        "title": "Lock Orders"
      },
      "notes": {
        "subheader": "This will add a note to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Add Note",
        "title": "Add Note"
      },
      "refund": {
        "subheader": "This will refund any negative balance for the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Refund",
        "title": "Refund Orders"
      },
      "remove-product": {
        "subheader": "This will remove all quantities of a product matching the criteria specified below to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Remove Product",
        "title": "Remove Product"
      },
      "remove-tag": {
        "subheader": "This will remove tags on the {count, plural, one {order} other {{count} orders}} in this segment.",
        "title": "Remove Tags"
      },
      "reopen": {
        "subheader": "This will reopen the eligible orders in this segment.",
        "submit-button": "Reopen {count, plural, one {Order} other {Orders}}",
        "title": "Reopen Orders"
      },
      "tag": {
        "subheader": "This will add or remove tags on the {count, plural, one {order} other {{count} orders}} in this segment.",
        "title": "Add or Remove Tags"
      },
      "uncancel": {
        "subheader": "This will uncancel the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Uncancel {count, plural, one {Order} other {Orders}}",
        "title": "Uncancel Orders"
      },
      "unlock": {
        "subheader": "This will remove the <strong>{process}</strong> lock to the {count, plural, one {order} other {{count} orders}} in this segment.",
        "submit-button": "Unlock",
        "title": "Unlock Orders"
      }
    },
    "columns": {
      "custom-fields": {
        "index": {
          "description": "Description",
          "entity": "Entity",
          "id": "Id",
          "name": "Name",
          "sort": "Sort Order",
          "type": "Type",
          "visibility": "Visibility"
        }
      },
      "customers": {
        "index": {
          "email": "Email",
          "id": "Id",
          "name": "Name"
        }
      },
      "digital": {
        "index": {
          "count": "Count",
          "email": "Email",
          "id": "Id",
          "name": "Name",
          "product_id": "Product Id",
          "status": "Status",
          "thumbnail": "Image",
          "type": "Type"
        },
        "view": {
          "downloads": {
            "content_type": "Content Type",
            "download_limit": "Download Limit",
            "file_size": "File Size",
            "id": "Id",
            "name": "Name"
          },
          "keys": {
            "id": "Id",
            "key": "Key",
            "order": "Order",
            "provider": "Provider"
          }
        }
      },
      "orders": {
        "groups": {
          "count": "Count",
          "group-products": "Group Products"
        },
        "index": {
          "completed": "Completed",
          "country": "Country",
          "current-configuration": "Reward",
          "customer-id": "Customer Id",
          "email": "Email",
          "external-id": "External Id",
          "external-sequence": "External Seq",
          "id": "Id",
          "invited": "Invited",
          "last-synced": "Last Sync",
          "last-synced-help": "The last time this order was pulled and updated externally",
          "name": "Name",
          "status": "Status",
          "tags": "Tags",
          "wave-shipping": "Wave"
        }
      },
      "products": {
        "index": {
          "dimensions": "Dimensions (in {unit_of_length}s)",
          "fulfillment": "Fulfillment",
          "id": "Id",
          "name": "Name",
          "price": "Price",
          "products": "Products",
          "questions": "Questions",
          "sku": "SKU",
          "thumbnail": "Image",
          "variations": "Variations",
          "weight": "Weight (in {unit_of_mass}s)"
        }
      },
      "questions": {
        "index": {
          "id": "Id",
          "name": "Name",
          "type": "Question Type"
        }
      },
      "sources": {
        "configurations": {
          "index": {
            "bundle_can_be_extra": "Can be Extra",
            "email": "Email",
            "external_id": "External Id",
            "externals": "Externals",
            "extras": "Extras",
            "id": "Id",
            "locations": "Locations",
            "name": "Name",
            "orders": "Orders",
            "price": "Price",
            "products": "Products",
            "reward_id": "Reward Id",
            "status": "Status"
          }
        }
      },
      "tags": {
        "index": {
          "created": "Created",
          "created_by": "Created by",
          "name": "Name",
          "orders": "Orders"
        }
      }
    },
    "component": {
      "companies": {
        "company": {
          "account": {
            "credit-card": {
              "content": {
                "billing_address": "Billing Address",
                "name_on_card": "Name on card"
              }
            }
          },
          "projects": {
            "project": {
              "apps": {
                "install-app-modal": {
                  "actions": {
                    "approve": "Install"
                  },
                  "content": {
                    "description": "This will install <b>{name}</b>. Are you sure?"
                  },
                  "header": "Install {name}",
                  "sub_header": ""
                },
                "uninstall-app-modal": {
                  "actions": {
                    "approve": "Uninstall"
                  },
                  "content": {
                    "completed": "The app has been removed.",
                    "description": "Are you sure you want to uninstall <b>{name}</b>?"
                  },
                  "header": "Uninstall {name}",
                  "sub_header": ""
                },
                "update-settings-modal": {
                  "actions": {
                    "approve": "Save"
                  },
                  "header": "Settings for {name}",
                  "sub_header": ""
                }
              },
              "clone-shipping-modal": {
                "actions": {
                  "save": "Copy Shipping"
                },
                "content": {
                  "bundle_header": "Configurations or Bundles",
                  "no_shipping_warning": "No products were found that have shipping.",
                  "product_header": "Products",
                  "rates": "Rates",
                  "replace_warning": "Please be aware that the product you select will import and replace any existing locations.",
                  "table": {
                    "amount": "Amount",
                    "locations": "Locations",
                    "name": "Name"
                  },
                  "text": "Please select a product below to import shipping for this product."
                },
                "header": "Copy Shipping from another Product"
              },
              "digital": {
                "assign-all-modal": {
                  "actions": {
                    "approve": "Send Now"
                  },
                  "content": {
                    "body": "This will assign any {type} to completed orders that haven't already received it.",
                    "complete": "{assigned, plural, =0 {Nothing was distributed!} other {{assigned} {type} have been distributed!}}",
                    "download": "If you have uploaded a new revision of the download then those who previously didn't get that revision will get sent a new email.",
                    "email": "Each order will receive a \"Digital Distribution\" email with the {type}.",
                    "failed": "Looks like we failed to distribute {failed} {type}. {type, select, key {The most likely reason is there aren't enough keys. Please check the count, upload more, and try again} other {}}",
                    "in_progress": "The {type} are currently getting assigned to orders. Emails will be sent shortly after. It is safe to close this dialog."
                  },
                  "header": "Distribute {type}",
                  "sub_header": ""
                },
                "bulk-status-modal": {
                  "header": "Bulk Status Change for Digital Products",
                  "no-update-path": {
                    "title": "There is no update path that applies to all products selected"
                  },
                  "subheader": "Choose New Status"
                },
                "change-status-modal": {
                  "header": {
                    "download": "Update Download Status",
                    "key": "Update Key Status"
                  }
                },
                "edit-download-modal": {
                  "content": {
                    "download_limit": {
                      "label": "Download Limit"
                    },
                    "file": {
                      "invalid_extension": "This doesn't appear to be a valid file type, but we'll validate it after you upload it.",
                      "label": "File",
                      "tooltip": "Allowed file types: Zip, PDF, JPEG, PNG, GIF, MPEG, MP3 or Quicktime"
                    },
                    "name": {
                      "label": "Name"
                    }
                  },
                  "header": "{isNew, select, true {Upload} other {Edit}} File",
                  "sub_header": ""
                },
                "edit-fulfillment-modal": {
                  "content": {
                    "email": {
                      "label": "Email Template"
                    },
                    "instructions": {
                      "label": "Digital Instructions",
                      "tooltip": "These instructions will be inserted into the email that is sent. It is only compatible with our default Digital Fulfillment email template."
                    }
                  },
                  "header": "Edit Digital Fulfillment",
                  "sub_header": ""
                },
                "fulfillment-status-button": {
                  "next-status": {
                    "complete": "Fulfillment Complete",
                    "live": "Pause Fulfillment",
                    "paused": "Restart Fulfillment",
                    "setup": "Start Fulfillment"
                  }
                },
                "import-product-modal": {
                  "actions": {
                    "approve": "Import"
                  },
                  "content": {
                    "download": {
                      "header": "Download",
                      "sub_header": "Distribute files such as PDFs or ZIPs."
                    },
                    "key": {
                      "header": "Product Key",
                      "sub_header": "Distribute unique keys such as Steam, GOG, or even coupon codes."
                    },
                    "no_products": {
                      "header": "There are no digital products",
                      "sub_header": "Looks like there were no digital products found. Prior to setting up digital fulfillment you will need to create a new digital product."
                    },
                    "select_email": "Select the email template",
                    "select_fulfillment": "Select the fulfillment type",
                    "select_products": "Select the product(s)"
                  },
                  "header": "Add Digital Fulfillment",
                  "sub_header": ""
                },
                "upload-keys-modal": {
                  "actions": {
                    "approve": "Upload Keys"
                  },
                  "content": {
                    "file": {
                      "label": "File"
                    }
                  },
                  "header": "Upload Keys",
                  "sub_header": ""
                }
              },
              "orders": {
                "create-order-modal": {
                  "actions": {
                    "approve": "Create",
                    "approve_and_stay": "Create and Add Another"
                  },
                  "content": {
                    "amount": {
                      "estimated_costs": "Estimated costs",
                      "header": "Amount",
                      "tooltip": "Amount in whole dollars"
                    },
                    "configuration": {
                      "label": "Configuration",
                      "none": "None Found. Try a different source",
                      "placeholder": "Select Configuration"
                    },
                    "customer": {
                      "header": "Customer",
                      "sub_header": "Enter the email address for the customer. If we don't have them already in the system you will need to enter their Full Name as well (as much as you have.)"
                    },
                    "email_address": "Email Address",
                    "full_name": "Full Name",
                    "location": {
                      "header": "Order Location (Optional)",
                      "sub_header": "If you are adding a transaction for this customer, selecting the country will give you the configuration price with shipping rates."
                    },
                    "order_source": {
                      "header": "Order Source",
                      "label": "Source",
                      "placeholder": "Select Source",
                      "sub_header": "We need to link every order with an original source that they came from. If you would like us to add an \"Other\" source please <a href=\"mailto:hello@crowdox.com\">send us an email.</a>"
                    },
                    "transaction": {
                      "header": "Transaction",
                      "sub_header": "Has this customer already paid somewhere else? If so put a value below and we will automatically add a credit to their order."
                    }
                  },
                  "header": "New Order"
                },
                "duplicate-email-modal": {
                  "content": {
                    "description": "Another customer already exists in the system with the same email address.",
                    "help": "If you need these customers merged please contact us at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>."
                  },
                  "header": "Duplicate Email"
                },
                "view": {
                  "order-history": {
                    "actions": {
                      "create": "Created",
                      "delete": "Deleted",
                      "destroy": "Deleted",
                      "update": "Updated"
                    },
                    "audited": "{user_type} ({user})",
                    "before-history": "No History Recorded. We started recording history on February 22nd, 2020.",
                    "entities": {
                      "order-addresses": "Address",
                      "order-emails": "Email",
                      "order-lines": "Line Item",
                      "order-selection-questions": "Question",
                      "order-selections": "Selection",
                      "order-transactions": "Transaction",
                      "orders": "Order"
                    },
                    "no-history": "No History Recorded."
                  },
                  "rectify-currency-modal": {
                    "content": {
                      "description": "This transaction is recorded in {transaction_currency}, but the project uses the {project_currency} currency.",
                      "description2": "If you know what the value of the currency should be in {project_currency}, update it below."
                    },
                    "header": "Rectify Transaction Currency"
                  }
                }
              },
              "products": {
                "choices": {
                  "edit-product-bundle-mappings": {
                    "content": {
                      "is_default": "Is Default",
                      "items": "Items",
                      "mapped_items": "Mapped Items",
                      "no_items": "{hasItems, select, true {All {type} have been mapped.} other {No {type} have been created yet.}}",
                      "no_mapped_items": "No items have been mapped",
                      "quantity": "{isBundle, select, true {Quantity} other {Additional Price}}",
                      "type": "Type"
                    },
                    "section": {
                      "choices": "Choices",
                      "products": "Products",
                      "questions": "Questions"
                    }
                  },
                  "edit-product-trait": {
                    "content": {
                      "image_recommendations": "Recommended minimum size is 500 width by 500 height or an aspect ratio of 1:1."
                    },
                    "field": {
                      "description": "Description",
                      "image": "Image",
                      "name": "Name",
                      "price": "Price in {currency}"
                    },
                    "header": "Details",
                    "inventory_total_quantity": {
                      "text": "Total quantity available",
                      "tooltip": "The total quantity available to sell as extras to all customers."
                    },
                    "order_max_quantity": {
                      "text": "Maximum quantity per customer",
                      "tooltip": "The maximum number that a single customer will be allowed to purchase as extras."
                    },
                    "placeholder": {
                      "name": "Enter Product Name"
                    }
                  },
                  "mapped-product": {
                    "content": {
                      "choice": "Choice",
                      "product": "Product",
                      "question": "Question"
                    },
                    "tooltip": {
                      "required_quantity": "The customer will be able to pick up to {quantity, plural, one {one product} other {{quantity} products}} from the option choices"
                    }
                  }
                },
                "edit-product-variant-modal": {
                  "action": {
                    "add-value": "Add Value"
                  },
                  "error": {
                    "unknown": "There was an unknown error while trying to create variations"
                  },
                  "field": {
                    "name": "Variant Name"
                  },
                  "header": "{isNew, select, true {Add} other {Edit}} Variant and Values",
                  "placeholder": {
                    "name": "Example: Size"
                  },
                  "subhead": "A variant and its values are the choices that a product has. For example, a shirt, might have a variant name of \"Size\" and values of \"Small\", \"Medium\", and \"Large\".\n",
                  "tooltip": {
                    "values-cannot-delete": "Save the product to save the new variants, then you can delete existing variant values"
                  },
                  "values-subhead": "Values",
                  "values-subhead-with-name": "Values for {name}",
                  "warning": {
                    "too-many-combinations": "The total combinations of all of your variant and values is currently <b>{total}</b>. The maximum number of combinations allowed is <b>{max}</b>. Please reduce your combinations or break them it into separate products.\n"
                  }
                }
              },
              "questions": {
                "create-product-question-modal": {
                  "content": {
                    "description": "Please select the type of question you would like to create below.",
                    "multiple_choice_question": {
                      "header": "Allow users to select from a list of pre-defined choices.",
                      "name": "Multiple Choice",
                      "sub_header": "Multiple choice questions are best when there is an exact list of choices to choose from. If you need to ask users about attributes for a product, like size or color, it's best to use product variations instead."
                    },
                    "text_question": {
                      "header": "Allow users to enter an answer based on the question.",
                      "name": "Text Question",
                      "sub_header": "Text questions are best for answers that can't be defined to a list of specific choices."
                    }
                  },
                  "header": "Add Question",
                  "sub_header": ""
                },
                "edit-product-question": {
                  "content": {
                    "help_text": {
                      "example": "Example: Please do not include special characters.",
                      "label": "Help Text"
                    },
                    "question": {
                      "example": "Example: What would you like your engraving to say?",
                      "label": "Question"
                    },
                    "required": {
                      "label": "An Answer is Required"
                    },
                    "types": {
                      "multiple": "Multiple Choice",
                      "text": "Text Entry"
                    }
                  }
                },
                "edit-product-question-multiple": {
                  "actions": {
                    "add": "Add"
                  }
                },
                "edit-product-question-text": {
                  "content": {
                    "maximum_length": {
                      "label": "Maximum Length"
                    },
                    "minimum_length": {
                      "label": "Minimum Length"
                    }
                  }
                }
              },
              "segments": {
                "create-segment": {
                  "header_create": "Create {behavior} Segment",
                  "header_edit": "Edit {behavior} Segment"
                },
                "send-emails-modal": {
                  "emails_sent_in_past_24_hours": {
                    "part1": "{outOf, plural, one {This order} other {{count} out of {outOf} orders in this segment}} have been sent this email within the last 24 hours.",
                    "part2-partial": "You can send to the remaining {remainingOrders, plural, one {order} other {{remainingOrders} orders}}, or retry in {remainingHours} {remainingHours, plural, one {hour} other {hours}} to send the email to all orders in this segment.",
                    "part2-total": "You can retry in {remainingHours} {remainingHours, plural, one {hour} other {hours}} to send the email to all orders in this segment."
                  },
                  "not_all_orders_invitable": {
                    "none": "None of the orders in this segment can be sent an invitation.",
                    "reason": "This is usually because the orders are either completed, cancelled, dropped or unconfirmed.",
                    "some": "{outOf, plural, one {This order} other {{count} out of {outOf} orders in this segment}} cannot be sent an invitation."
                  },
                  "send-to-remaining-button": "Send to remaining {remainingOrders, plural, one {order} other {{remainingOrders} orders}}"
                }
              },
              "settings": {
                "fields": {
                  "create-custom-field-modal": {
                    "actions": {
                      "add-value": "Add Value",
                      "save": "Create"
                    },
                    "description": "Create an {entity} custom field on this project. Once created you will be able to add and edit this property on any {entity}.",
                    "title": "{isNew, select, true {Add} other {Edit}} {field_type} {entity} Field",
                    "values-subhead": "{name} Values"
                  },
                  "edit-custom-field-modal": {
                    "actions": {
                      "save": "Save"
                    },
                    "description": "Edit a custom field on this project",
                    "title": "Edit Custom Field"
                  }
                },
                "payments": {
                  "connect-stripe": {
                    "content": {
                      "connect": {
                        "header": "Connect Your Stripe Account",
                        "sub_header": "The Stripe Account that you connect will be used to collect payments from your customers for both rewards and extras."
                      },
                      "connected": {
                        "action": "Connect {payment_gateways, select, 0 {New} other {Another}} Stripe Account",
                        "header": "Your Stripe Account is Connected",
                        "sub_header": "The Stripe Account that you connect will be used to collect payments from your customers for both rewards and extras."
                      },
                      "description": "Use your Stripe account to collect payments from your Customers",
                      "errors": {
                        "expired": "Your Stripe account credentials have expired. Please log into your Stripe account and resolve any alerts.",
                        "nonchargeable": "Your Stripe account is unable to receive payments. Please log into your Stripe account and resolve any alerts.",
                        "not_working": "Something isn't right with this connection"
                      },
                      "import": {
                        "action": "Import Account"
                      },
                      "not_connected": {
                        "header": "There is a problem with your Stripe Account",
                        "sub_header": "The Stripe Payment Gateway is installed, but you do not have any active connections. This is a problem that needs to be resolved before it can be used to collect payments. Please connect or activate a connection below."
                      },
                      "not_installed": {
                        "action": "Install Stripe"
                      }
                    }
                  },
                  "deactivate-modal": {
                    "actions": {
                      "save": "Deactivate"
                    },
                    "description": "This will deactivate the {provider} account with an ID of {account_id}",
                    "live_warning": "The project is currently live. This will prevent payments from being made with this account",
                    "title": "Deactivate {provider}"
                  },
                  "disable-modal": {
                    "actions": {
                      "save": "Disable"
                    },
                    "description": "This will disable the {provider} account with an ID of {account_id}",
                    "live_warning": "The project is currently live. This will prevent refunds from being made with this account",
                    "title": "Disable {provider}"
                  },
                  "enable-modal": {
                    "actions": {
                      "save": "Enable"
                    },
                    "description": "This will enable the {provider} account with an ID of {account_id}",
                    "live_warning": "The project is currently live. This will allow refunds to be made with this account",
                    "title": "Enable {provider}"
                  },
                  "import-modal": {
                    "actions": {
                      "save": "Import"
                    },
                    "stripe": {
                      "description": "Choose a project below to import the Stripe account from",
                      "title": "Import Stripe Account"
                    }
                  },
                  "reactivate-modal": {
                    "actions": {
                      "save": "Activate"
                    },
                    "description": "This will reconnect the {provider} account with an ID of {account_id} and deactivate any other {provider} accounts",
                    "live_warning": "The project is currently live. This will allow payments to be made with this account",
                    "title": "Activate {provider}"
                  },
                  "reconnect-modal": {
                    "actions": {
                      "save": "Reconnect"
                    },
                    "description": "This will redirect you to {provider} so you can reconnect this account",
                    "live_warning": "The project is currently live. This will allow charges to be made with this account",
                    "title": "Reconnect {provider}"
                  }
                }
              },
              "shipping": {
                "vat": {
                  "edit-categories-modal": {
                    "action": {
                      "add-value": "Add"
                    }
                  }
                }
              },
              "sources": {
                "configurations": {
                  "edit-project-configuration-trait": {
                    "action": {
                      "select_image": "select image to upload"
                    },
                    "content": {
                      "image_recommendations": "Recommended minimum size is 750 width by 500 height or an aspect ratio of 3:2.",
                      "options": "Options",
                      "sell_as_extra": "Sell as Extra"
                    },
                    "field": {
                      "bundle_can_be_extra": "Make available as extra bundle",
                      "can_select": "Allow Customers to Sign up",
                      "description": "Description",
                      "estimated_delivery": "Estimated Delivery",
                      "external_id": "External ID",
                      "image": "Image",
                      "inventory_total_quantity": "Maximum Allowed Orders",
                      "name": "Name",
                      "price": "Price in {currency}",
                      "source": "Source"
                    },
                    "placeholder": {
                      "name": "Enter Product Name",
                      "source": "Select Configuration"
                    },
                    "tooltip": {
                      "bundle_can_be_extra": "This only allows the bundle to be sold as an extra. You still need to map it on the extras screen.",
                      "can_select": "This allows customers to sign up on your Crowd Ox {order} page."
                    }
                  },
                  "index": {
                    "column-email": {
                      "email": "Email",
                      "number_emailed": "{count} Emailed"
                    }
                  },
                  "review": {
                    "review-group": {
                      "content": {
                        "error_header": "Error",
                        "no_warnings": "No warnings were found.",
                        "warning": "{count, plural, =1 {1 Warning} other {# Warnings}}"
                      },
                      "error": {
                        "unknown": "There was an unknown error while getting review results."
                      },
                      "title": "{groupName} Review: {name}"
                    },
                    "warning-item": {
                      "action": {
                        "hide_related": "Hide Related Items",
                        "view_page": "View Page",
                        "view_related": "View Related Items"
                      },
                      "content": {
                        "with_total_links": "There were a total of {total_count} items with this warning, but only displaying a small subset of {count}.\n",
                        "wo_total_links": "{count, plural, =1 {There is only one item} other {There are a total of # items}} with this warning.\n"
                      },
                      "tooltip": {
                        "backend_rule": "The backend rule that generated this warning is: {code}"
                      }
                    }
                  }
                },
                "extras": {
                  "edit-project-extras-mappings": {
                    "action": {
                      "add": "Add new Section"
                    },
                    "content": {
                      "all_mapped": "All {section} have been mapped.",
                      "available_header": "Extras Available",
                      "no_mapped_items": "No items have been mapped",
                      "none_mapped": "No {section} have been created yet."
                    },
                    "field": {
                      "bundles": "Bundles",
                      "items": "Items",
                      "price": "Price",
                      "products": "Products",
                      "sections": "Sections",
                      "type": "Type"
                    }
                  },
                  "mapped-section": {
                    "content": {
                      "edit_product": "Edit Product"
                    }
                  },
                  "section-modal": {
                    "action": {
                      "save": "{isNew, select, true {Create Section} other {Save Changes}}"
                    },
                    "error": {
                      "cannot_save": "Unable to save"
                    },
                    "field": {
                      "description": "Section Description",
                      "name": "Section Header"
                    },
                    "header": "{isNew, select, true {Create} other {Edit}} Section"
                  }
                }
              },
              "stats": {
                "index": {
                  "stat-locations": {
                    "domestic": "Domestic",
                    "international": "International"
                  }
                }
              },
              "theme": {
                "theme-hero-preview": {
                  "tooltip": {
                    "header_subtext_color": "Update the tagline text color",
                    "header_text_color": "Update the header text color",
                    "hero_background_color": "Update the background color"
                  }
                },
                "theme-layout": {
                  "website": "Website"
                },
                "theme-picker-modal": {
                  "dark_theme": "Dark Theme",
                  "dark_theme_desc": "This is a darker theme based on black colors. This is the default theme on a new project.",
                  "header": "Apply Default Theme",
                  "instructions1": "You can apply a new color theme by selecting the light or dark theme below.",
                  "instructions2": "<b>Warning:</b> If you select on the of themes below, all of the current color settings will be reset.",
                  "light_theme": "Light Theme",
                  "light_theme_desc": "This is a lighter theme based on white colors."
                },
                "theme-settings": {
                  "header": {
                    "company_logo": "Company Logo Image",
                    "image": "Image Settings",
                    "text": "Text Settings",
                    "title_image": "Title Replacement Image"
                  },
                  "instructions": {
                    "cover_image": "Cover Image provides a full width centered image for your theme. We recommend an image thats around 500px tall and 800-1300px wide.",
                    "no_image_background": "No image background image has been added.",
                    "offset_image": "Offset Image provides an image positioned on the theme. We recommend an image thats around 500px tall and 500-1000px wide. <br> <b>Please Note:</b> The offset configurations are set per viewing size (Desktop, Tablet, and Mobile).\n"
                  },
                  "label": {
                    "cover_image": "Cover Image",
                    "desktop_image_offset": "Desktop Image Offset",
                    "mobile_image_offset": "Mobile Image Offset",
                    "offset_image": "Offset Image",
                    "pre_order_button_text": {
                      "label": "{order} Button Text"
                    },
                    "tablet_image_offset": "Tablet Image Offset",
                    "tagline": "Tagline",
                    "title": "Title"
                  },
                  "link": {
                    "clear_background_image": "Clear Background Image",
                    "clear_image": "Clear Image"
                  }
                }
              }
            }
          }
        }
      },
      "file-upload": {
        "required-type": {
          "image": "File must be an image"
        }
      },
      "job-summary": {
        "lines": "{number, plural, =1 {Line} other {Lines}}",
        "messages": {
          "change_configuration": {
            "description": {
              "preview": {
                "configuration_changed": "These orders will have their configuration changed",
                "original_configuration_changed": "These orders will have their original configuration changed"
              }
            }
          },
          "credit": {
            "description": {
              "preview": {
                "credit_added": "These orders will have a credit added to them"
              }
            },
            "title": {
              "preview": {
                "credit_added": "Ok"
              }
            }
          },
          "import": {
            "description": {
              "preview": {
                "invalid_record": "Record will not be imported due to validation errors",
                "success": "These orders will be imported"
              }
            },
            "title": {
              "doesnt_match_currency_symbol": "Doesn't match currency symbol"
            }
          },
          "import_keys": {
            "description": {
              "preview": {
                "key_imported": "The key will be imported",
                "order_selection_assigned": "The key will be assigned to an order"
              }
            }
          },
          "import_selections": {
            "description": {
              "preview": {
                "success": "These order selections will be imported"
              }
            }
          },
          "notes": {
            "description": {
              "preview": {
                "note_added": "These orders will have a note added to them"
              }
            }
          },
          "segment": {
            "description": {
              "preview": {
                "order_added": "These orders will be added"
              }
            }
          },
          "shipment_tracking_order_selections": {
            "description": {
              "preview": {
                "already_shipped": "Will be skipped because the order has already been marked as shipped",
                "marked_as_shipped": "These order selections will be marked as shipped"
              }
            }
          },
          "shipment_tracking_orders": {
            "description": {
              "preview": {
                "already_shipped": "Will be skipped because the order has already been marked as shipped",
                "marked_as_shipped": "These orders will be marked as shipped"
              }
            }
          }
        },
        "orders": "{number, plural, =1 {Order} other {Orders}}",
        "title": {
          "bulk-action": "Summary",
          "live": "Import Summary",
          "preview": "Preview Summary"
        }
      },
      "segmentation": {
        "for-app": {
          "content": {
            "description": "Below is a list of orders that have been pushed to {app_name}.",
            "disabled": "Not currently enabled",
            "disabled_instructions": "Contact your Customer Success representative to get started.",
            "no_actions": "Nothing has been pushed",
            "no_actions_instructions": "Contact your Customer Success representative if you feel this is in error."
          },
          "field": {
            "action_id": "Action Id",
            "completed": "Completed",
            "created": "Created",
            "orders": "Orders",
            "segment_id": "Segment Id",
            "status": "Status"
          }
        },
        "resync-modal": {
          "actions": {
            "approve": "Re-Sync"
          },
          "content": {
            "description": "<div class=\"ui warning message\"><i class=\"warning icon\"></i>Rarely needed</div>\n\n<p>\n  This will reset the synced status of all the pushed orders in the system. It will effectively re-push all of them {app_name} again. If you feel like that's what you need, great.\n</p>\n\n<p>\n  If you have questions please contact the Customer Success team.\n</p>\n"
          },
          "header": "Re-Sync {app_name}",
          "sub_header": "This will reset the status of {app_name} and start over"
        }
      }
    },
    "confirm-modal": {
      "copy-survey-theme": {
        "question": "Are you sure you want to copy the survey theme settings into and replace your pre-order theme?",
        "title": "Copy Survey Theme"
      },
      "delete-company": {
        "errors": {
          "default": "Unable to delete"
        },
        "question": "Are you sure you want to delete <b>{company_name}</b>?",
        "title": "Delete Company"
      },
      "delete-custom-field-value": {
        "description": "If there are any {entities} with this value it WILL BE REMOVED. Make sure that is what you want.\n",
        "errors": {
          "default": "Unable to delete"
        },
        "question": "Are you sure you want to delete <b>{name}</b>?",
        "title": "Delete Custom Field Value"
      },
      "delete-export": {
        "question": "Are you sure you want to delete the file <b>{name}</b>?",
        "title": "Delete Export File"
      },
      "delete-export-segment": {
        "approve_label": "Delete Export and Unlock Orders",
        "cancel_label": "Cancel",
        "description": "<div class=\"ui warning message\">This will permanently remove the export.</div>",
        "question": "The file will be removed from <span class=\"crowdox text\"><em>Crowd</em> Ox</span> and any orders for this segment will be be unlocked.",
        "title": "Delete <i>{name}</i>?"
      },
      "delete-key": {
        "question": "Are you sure you want to delete the key <b>{key}</b>?",
        "title": "Delete Product Key"
      },
      "delete-local-pickup": {
        "errors": {
          "default": "Unable to delete. If any orders have selected to use this pickup address, you will not be able to delete it."
        },
        "question": "Are you sure you want to delete <b>{name}</b>?",
        "title": "Delete Local Pickup"
      },
      "delete-project": {
        "errors": {
          "default": "Unable to delete"
        },
        "question": "Are you sure you want to delete <b>{project_name}</b>?",
        "title": "Delete Project"
      },
      "delete-project-custom-field": {
        "description": "This will permanently delete this field along with any data that was previously stored on {entities}.\n",
        "errors": {
          "default": "Unable to delete the custom field"
        },
        "question": "Are you sure you want to delete <b>{name}</b>?",
        "title": "Delete Custom Field"
      },
      "delete-segment": {
        "approve_label": "Delete Segment",
        "cancel_label": "Cancel",
        "question": "This will permanently remove the segment.",
        "title": "Delete <i>{name}</i>?"
      },
      "delete-tag": {
        "errors": {
          "default": "Unable to delete"
        },
        "question": "Are you sure you want to delete the tag <span class=\"ui {color} label\">{name}</span>? <br><br> {total_orders} {total_orders, plural, one {order is} other {orders are}} associated with this tag.\n",
        "title": "Delete Tag"
      },
      "delete-zone": {
        "errors": {
          "default": "Unable to delete zone"
        },
        "question": "Are you sure you want to delete this zone {location_count, plural, =0 {} one { and its location} other { and its {location_count} locations}}?",
        "title": "Delete Zone: {zone_name}"
      },
      "digital-fulfillment-status-update": {
        "live": {
          "approve_label": "Continue",
          "cancel_label": "Cancel",
          "question": "By setting the status to Live, any completed orders with this product will get their digital assets assigned to them, and emails will be sent to customers informing them about their digital assets.\n",
          "title": "Update Status to Live"
        }
      },
      "lock-selection": {
        "question": "Are you sure you want to lock <b>{product}</b>?",
        "title": "Lock Selection"
      },
      "mark-as-shipped": {
        "question": "Are you sure you want to mark <b>{product}</b> as Shipped?",
        "title": "Mark as Shipped"
      },
      "mark-order-as-shipped": {
        "errors": {
          "default": "Unable to mark as shipped"
        },
        "question": "Are you sure you want to mark <b>Order #{order_id}</b> as Shipped?",
        "title": "Mark as Shipped"
      },
      "need-information": {
        "title": "Need additional information"
      },
      "remove-all-locks": {
        "description": "This action is irreversible.",
        "question": "Are you sure you want to remove all locks from <b>{product}</b>?",
        "title": "Removing all locks"
      },
      "remove-digital-fulfillment": {
        "errors": {
          "default": "Unable to remove. Make sure you remove any keys or downloads associated with this fulfillment first."
        },
        "question": "Are you sure you want to remove <b>{name}</b> from using digital fulfillment?",
        "title": "Remove Digital Fulfillment"
      },
      "remove-lock": {
        "question": "Are you sure you want to remove the {process} lock from <b>{product}</b>?",
        "title": "Removing {process} lock"
      },
      "remove-user": {
        "approve_label": "Remove from Company",
        "cancel_label": "Cancel",
        "question": "This will remove {user_name} ({user_email}) from the company {company_name}.",
        "title": "Remove User"
      },
      "resend-invite": {
        "question": "Are you sure you want to invite {name} again? Any previous invitations will no longer work.",
        "title": "Resend Invitation"
      },
      "reset-download": {
        "question": "This will reset their downloaded count. They will be able to download it {limit} {limit, plural, one {time} other {times}} after this. Is that OK?",
        "title": "Reset Download Count"
      },
      "resync-order": {
        "errors": {
          "default": "Unable to delete"
        },
        "question": "This will remove the synced status for {provider} and it will sync again within an hour. <br/><br/> Are you sure you want to do this?\n",
        "title": "ReSync Order"
      },
      "save-configuration": {
        "question": "We need to save this configuration. Is that OK?",
        "title": "Save Configuration"
      },
      "segment-action-cancel": {
        "question": "Do you want to cancel this segment action?",
        "title": "Cancel Segment Action"
      },
      "segment-action-restart": {
        "question": "Do you want to restart this segment action?",
        "title": "Restart Segment Action"
      },
      "set-company-owner": {
        "question": "Set {name} as the sole owner for the company?",
        "title": "Set Owner"
      },
      "set-configuration-extras-to-global": {
        "question": "This will remove the custom extra mappings and update the configuration to use global extras for the customer to purchase during the survey. Are you sure?",
        "title": "Set Configuration Extras to Global Extras"
      },
      "set-configuration-extras-to-none": {
        "question": "This will remove the custom extra mappings and update the configuration so that no extras will be available for the customer to purchase during the survey. Are you sure?",
        "title": "Set Configuration Extras to None"
      },
      "switch-to-no-shipping": {
        "errors": {
          "default": "Unable to delete all zones"
        },
        "question": "This will delete all zones and their locations. Are you sure you want to proceed?",
        "title": "Switch to <i>Shipping isn't involved</i>?"
      },
      "test-email-sent": {
        "question": "You have been sent a test email",
        "title": "Email Sent"
      },
      "unable-to-launch": {
        "question": "You cannot launch this configuration while <b>{source_name}</b> is still in setup. If you feel this is in error, please contact us at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>\n",
        "title": "Unable to Launch"
      },
      "unknown-error": "An unknown error has occurred",
      "unlock-selections": {
        "description": "This action is irreversible.",
        "question": "Are you sure you want to unlock all selections?",
        "title": "Unlock Selections"
      },
      "update-configuration": {
        "errors": {
          "default": "Unable to update the configuration status"
        },
        "question": "Do you want to set this configuration to: <b>{status}</b>?",
        "title": "Update Configuration Status"
      }
    },
    "controller": {
      "companies": {
        "company": {
          "account": {
            "billing-info": {
              "action": {
                "save": "Activate My Account",
                "update": "Update My Account",
                "update-card-on-file": "Update Card on File"
              },
              "current-payment-ending": "ending in {last4}",
              "current-payment-method": "{brand} {funding, select, debit {(Debit)} other {}}",
              "footer": "Please contact us if you need anything. <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>",
              "header_setup": "Set Up Billing Info",
              "header_update": "Update Billing Info",
              "payment-method": "Current Payment Method:",
              "sub_header": "The credit card that you enter below will be used for per account fees and any other custom request charges.",
              "view-header": "My Billing Info",
              "view-subheader": "The card on file is used to pay for per account charges and any other custom request charges."
            },
            "info": {
              "content": {
                "locale": "Location",
                "locale_subheader": "Your company's primary physical location"
              }
            }
          },
          "projects": {
            "project": {
              "products": {
                "choices": {
                  "edit": {
                    "content": {
                      "basic-info": "Basic Info",
                      "locations": "Locations",
                      "mapped": "Mapped",
                      "mapped-products": "Mapped Products{is_bundle, select, true {, Choices and Questions} other {}}",
                      "max-allowed": "Max Allowed",
                      "max-order-quantity": "Max Order Quantity",
                      "name": "Name",
                      "shipping": "Shipping"
                    }
                  }
                },
                "edit": {
                  "basic-info": {
                    "name": "Name",
                    "price": "Price",
                    "section-header": "Basic Info",
                    "sku": "SKU",
                    "type": "Type"
                  },
                  "custom_fields": {
                    "fields": "Fields",
                    "section-header": "Custom Fields"
                  },
                  "questions": {
                    "action": {
                      "add": "Add Question"
                    },
                    "content": {
                      "no-questions": "No Questions Added"
                    },
                    "header": "Questions About this Product",
                    "questions": "Questions",
                    "saving": "Saving Questions",
                    "section-header": "Questions"
                  },
                  "shipping": {
                    "dimensions": "Dimensions",
                    "field": {
                      "dimensions": {
                        "label": "Dimensions (in {unit_of_length}s)"
                      },
                      "wave_shipping": {
                        "label": "Wave Shipping",
                        "placeholder": "Select Option",
                        "tooltip": "Select the earliest that this product can be shipped in a wave shipping scenario"
                      },
                      "weight": {
                        "label": "Weight (in {unit_of_mass}s)"
                      }
                    },
                    "section-header": "Shipping",
                    "wave-shipping": "Wave Shipping",
                    "weight": "Weight"
                  },
                  "variations": {
                    "action": {
                      "add": "Add Variant",
                      "remove-image": "Remove the image from this Variation",
                      "upload-image": "Click here to upload an image or drag the image from onto the Variation row"
                    },
                    "content": {
                      "no-variants": "No Variants Added",
                      "no-variations": "No Variations Exist"
                    },
                    "saving": "Saving Variations",
                    "section-header": "Variations",
                    "values": "Values",
                    "variants": "Variants",
                    "variants-subhead": "These are Variants for your product. For example if your product was a shirt, the Variants that make up that shirt would be Size and Color. The Values under a Variant are the options for the customer to select. If you were editing the variant Size, the values could be Small, Medium, or Large.\n",
                    "variations": "Variations",
                    "variations-subhead": "These are the combinations of all of the Variants and their Values above. This allows you to assign specific SKUs to a product attribute combination. If you don't want a specific combination available to the customer, then disable it on the right.\n"
                  }
                }
              },
              "shipping": {
                "local-pickups": {
                  "edit": {
                    "field": {
                      "address": "Address",
                      "city": "City",
                      "locality_or_city": "Locality or City",
                      "name": "Name",
                      "phone_number": "Phone Number",
                      "postal_code": "Postal Code",
                      "province_or_state": "Province or State",
                      "state": "State",
                      "zip_code": "Zip Code"
                    }
                  }
                }
              },
              "sources": {
                "configurations": {
                  "edit": {
                    "index": {
                      "action": {
                        "disable": "Disable"
                      },
                      "content": {
                        "basic_info": "Basic Info",
                        "custom_fields": "Custom Fields",
                        "external_shipping": "The shipping rates are currently being imported and managed from {source}. If you would like to modify these rates, you can disable the import on the right. Once the import has been disabled, you must manually update any changes in Crowd Ox that you make on {source}.\n",
                        "fields": "Fields",
                        "map_products": "Map Products, Choices, & Questions",
                        "mappings": "Mappings",
                        "shipping": "Shipping"
                      },
                      "field": {
                        "inventory_total_quantity": "Max Allowed",
                        "name": "Name",
                        "price": "Price"
                      }
                    }
                  }
                }
              },
              "welcome": {
                "footer": "If you have any questions or need help, please reach out to us at <a href=\"mailto:{email}\">{email}</a>",
                "header": "Welcome to Crowd Ox",
                "sub_header": "We need a few pieces of information to help us set up your project."
              }
            }
          }
        }
      }
    },
    "delete-confirmation-modal": {
      "delete-configuration": {
        "entity-name": {
          "reward": null
        },
        "not-deletable": "This {reward} cannot be deleted.",
        "question": "Are you sure you want to delete <b>{name}</b>?",
        "title": "Delete {reward}"
      },
      "delete-download": {
        "entity-name": "Download",
        "errors": {
          "download_has_been_assigned": "Unable to delete this Download because it has already been assigned to {count, plural, one {an Order} other {{count} Orders}}."
        },
        "not-deletable": "This Download cannot be deleted.",
        "question": "Are you sure you want to delete the file <b>{name}</b>?",
        "title": "Delete Download"
      },
      "delete-holiday": {
        "entity-name": "Holiday",
        "not-deletable": "The holiday cannot be deleted",
        "question": "Are you sure you want to delete this holiday?",
        "title": "Delete Holiday"
      },
      "delete-immediately": "This deletion will be effective immediately if you decide to proceed.",
      "delete-product": {
        "entity-name": "{isQuestion, select, true {Question} other {Product}}",
        "errors": {
          "orders_with_product": "Unable to delete this {isQuestion, select, true {Question} other {Product}} as {count, plural, one {one Order exists} other {{count} Orders exist}} with it selected",
          "product_mapped_to_bundles": "Unable to delete this {isQuestion, select, true {Question} other {Product}} as {count, plural, one {one Product Bundle exists} other {{count} Product Bundles exist}} with it mapped",
          "product_mapped_to_configurations": "Unable to delete this {isQuestion, select, true {Question} other {Product}} as {count, plural, one {one Configuration mapping exists} other {{count} Configuration mappings exist}} with it mapped",
          "product_mapped_to_extras": "Unable to delete this {isQuestion, select, true {Question} other {Product}} as {count, plural, one {one Configuration extra exists} other {{count} Configuration extras exist}} with it mapped",
          "product_mapped_to_options": "Unable to delete this {isQuestion, select, true {Question} other {Product}} as {count, plural, one {one Product Choice exists} other {{count} Product Choices exist}} with it mapped"
        },
        "not-deletable": "The {isQuestion, select, true {Question} other {Product}} cannot be deleted.",
        "question": "Are you sure you want to delete <b>{nameIsBlank, select, true {(Empty Name)} other {{name}}}</b>?",
        "title": "Delete {isQuestion, select, true {Question} other {Product}}"
      },
      "delete-product-question": {
        "entity-name": "Product Question",
        "errors": {
          "orders_with_question": "Unable to delete this Product Question as {count, plural, one {one Order exists} other {{count} Orders exist}} with it answered",
          "question_mapped_to_bundles": "Unable to delete this Product Question as {count, plural, one {one Product Bundle exists} other {{count} Product Bundles exist}} with it mapped",
          "question_mapped_to_configurations": "Unable to delete this Product Question as {count, plural, one {one Configuration exists} other {{count} Configurations exist}} with it mapped"
        },
        "not-deletable": "The Question cannot be deleted.",
        "question": "Are you sure you want to delete the Product Question <b>{nameIsBlank, select, true {(Empty Question)} other {{name}}}</b>?",
        "title": "Delete Product Question"
      },
      "delete-product-question-choice": {
        "entity-name": "Question Choice",
        "errors": {
          "orders_with_question_choice": "Unable to delete this Product Question Choice as {count, plural, one {one Order exists} other {{count} Orders exist}} with it selected"
        },
        "not-deletable": "The Question Choice cannot be deleted.",
        "question": "Are you sure you want to delete the Question Choice <b>{nameIsBlank, select, true {(Empty Choice)} other {{name}}}</b>?",
        "title": "Delete Question Choice"
      },
      "delete-product-variant": {
        "entity-name": "Product Variant",
        "errors": {
          "orders_with_variant": "Unable to delete this Product Variant as {count, plural, one {one Order exists} other {{count} Orders exist}} with it selected with an associated Product Variation"
        },
        "not-deletable": "The Product Variant cannot be deleted.",
        "question": "Are you sure you want to delete the Product Variant <b>{name}</b>?",
        "title": "Delete Product Variant"
      },
      "delete-product-variant-value": {
        "entity-name": "Product Variant Value",
        "errors": {
          "orders_with_variant_value": "Unable to delete this Product Variant Value as {count, plural, one {one Order exists} other {{count} Orders exist}} with it selected with an associated Product Variation"
        },
        "not-deletable": "The Product Variant Value cannot be deleted.",
        "question": "Are you sure you want to delete the Product Variant Value <b>{name}</b>?",
        "title": "Delete Product Variant Value"
      },
      "errors": {
        "default": "We were unable to determine why this {entity-name} can not be deleted at this time. Please try again later."
      },
      "states": {
        "loading": "Checking if {entity-name} can be deleted"
      },
      "support-message": "If you need to make changes or need additional help, please reach out to Customer Support at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a> for further assistance."
    },
    "languages": {
      "de-de": "German",
      "en-us": "English",
      "es-es": "Spanish",
      "fr-fr": "French",
      "ja-jp": "Japanese",
      "nl-nl": "Dutch",
      "sv-se": "Swedish",
      "zh-cn": "Chinese"
    },
    "lock_levels": {
      "order_selections": {
        "description": "<b>This is the default</b> and will unlock the order selections along with the orders if all of the selections get unlocked.",
        "title": "Order Selections"
      },
      "orders": {
        "description": "<b>This will unlock the order only.</b> If there are locks on order selections they will be left alone. This is typically used if you want to allow changes to the order itself, such as updating the address, but do not want existing items to change.",
        "title": "Orders"
      }
    },
    "locks": {
      "all": {
        "description": "This will remove all locks from the order.",
        "title": "All"
      },
      "digital": {
        "description": "The <b>Digital</b> lock type is used when a digital file or key has been distributed.",
        "title": "Digital"
      },
      "export": {
        "description": "The <b>Export</b> lock type is used when you export orders and choose to lock them at the same time. This is a typical way to track which orders you've exported previously and which you have not.",
        "title": "Exported"
      },
      "limited": {
        "description": "The <b>Limited</b> lock type is used when a purchase has been made on a limited quantity item.",
        "title": "Limited"
      },
      "manual": {
        "description": "The <b>Manual</b> lock type is used when someone is manually locking orders.",
        "title": "Manual"
      },
      "ship": {
        "description": "The <b>Shipped</b> lock type is used when you upload shipment tracking information. It can also be added manually from the Lock dialog.",
        "title": "Shipped"
      }
    },
    "messages": {
      "bundle_price": "<p>\n  Bundles or {rewards} usually have a price associated with them.\n  If this is truly free, then entering zero is acceptable.\n  We wanted to make you aware that the price is zero and would be free to customers.\n</p>\n",
      "product_price": "<p>The <em>Product Price</em> has one of two purposes:</p>\n<ol>\n  <li>\n    <b>When the product is sold by itself or as an extra</b>, the <em>Product Price</em> entered is the purchase price.\n    The <em>Product Price</em> will also be the item price of the product within exports in the future.\n  </li>\n  <li>\n    <b>When the product is only included within a bundle</b>, the <em>Product Price</em> entered is only used for export purposes.\n    It has no effect on the price of the bundle it is included in.\n    Adding the <em>Product Price</em> will help provide a more accurate item price calculation within exports in the future.\n  </li>\n</ol>\n<p>Products that are truly free should have their <em>Product Price</em> set to 0.<p>\n"
    },
    "models": {
      "digital-fulfillment": {
        "status": {
          "description": {
            "complete": "<ul class=\"ui list\">\n  <li>No additional {assets} can be added to this product</li>\n  <li>No more orders will be assigned with {assets} from this product</li>\n</ul>\n",
            "live": "As new orders complete with this product,<br> <ul class=\"ui list\">\n  <li>{assets} for this product will be assigned to them</li>\n  <li>customers will receive emails with instructions on how to claim their {asset}</li>\n</ul>\n",
            "paused": "Product {assets} are temporarily paused from going out.<br> <ul class=\"ui list\">\n  <li>Newly completed orders will not receive {assets} or emails until the status is changed to Live</li>\n</ul>\n",
            "setup": "<ul class=\"ui list\">\n  <li>You may add and remove {assets} for this product</li>\n  <li>Emails will not be sent to customers with completed orders until the status is changed to Live</li>\n</ul>\n"
          },
          "title": {
            "complete": "Complete",
            "live": "Live",
            "paused": "Paused",
            "setup": "In Setup"
          }
        },
        "sub_type": {
          "download": {
            "one": "download",
            "other": "downloads"
          },
          "generic": {
            "one": "digital asset",
            "other": "digital assets"
          },
          "key": {
            "one": "key",
            "other": "keys"
          }
        }
      },
      "product": {
        "attributes": {
          "product_type": {
            "bundle": "Bundle",
            "digital": "Digital",
            "option": "Choice",
            "physical": "Physical",
            "shipping": "Shipping",
            "unknown": "Imported Product"
          },
          "wave_shipping": {
            "one": "Wave One",
            "three": "Wave Three",
            "two": "Wave Two"
          }
        },
        "errors": {
          "save": {
            "unknown": "The product wasn't able to be saved."
          }
        }
      },
      "product_variant": {
        "attributes": {
          "id": "Id",
          "name": "Name",
          "values": "Values"
        }
      },
      "product_variation": {
        "attributes": {
          "enabled": "Enabled",
          "external_id": "External ID",
          "id": "Id",
          "is_default": "Is Default",
          "sku": "SKU"
        },
        "errors": {
          "save": {
            "unknown": "There was an unknown error while trying to create Variations"
          }
        },
        "name": "Variation"
      },
      "question": {
        "attributes": {
          "id": "Id",
          "question_type": "Type",
          "question_type-options": {
            "multiple": "Multiple",
            "text": "Text"
          }
        },
        "errors": {
          "save": {
            "unknown": "There was an unknown error while trying to create the Question"
          }
        },
        "name": "Question"
      }
    },
    "order-exports": {
      "amplifier": {
        "description": "Enjoy total control of your distribution by partnering with Amplifier. Integrate your suppliers, use our warehousing, have us ship your goods, and also rely on us to make products for you as well.",
        "name": "Amplifier"
      },
      "blackbox": {
        "description": "Blackbox is a new shipping company from the creators of Cards Against Humanity.",
        "name": "Blackbox"
      },
      "bonver": {
        "description": "Bonver Logistics, more than a logistics partner!",
        "name": "Bonver"
      },
      "capabilities": {
        "address": "Address",
        "amount-paid": "Amount Paid",
        "amount-paid-and-balance": "Amount Paid and Balance",
        "answer": "Answer",
        "answer-one": "Answer to 1 Product Question",
        "answers-multiple": "Answers to Multiple Product Questions",
        "answers-product": "Answers to Product Questions",
        "country-iso3": "Country ISO3",
        "country-name": "Country Name",
        "custom": "This is a custom export",
        "email": "Email",
        "email-state": "Email State",
        "email-text": "Email Text",
        "fee-amount": "Fee Amount",
        "from-email": "From Email",
        "from-name": "From Name",
        "full-name": "Full Name",
        "invite-approved-canceled-date": "Invite, Approved or Canceled Date",
        "line-type": "Line Type",
        "manage-url": "Manage URL",
        "name-and-email": "Name & Email",
        "notes": "Notes",
        "order-date": "Order Date",
        "order-number": "Order Number",
        "order-source": "Order Source",
        "order-source-number": "Order Source Number",
        "order-status": "Order Status",
        "payment-amount": "Payment Amount",
        "payment-date": "Payment Date",
        "phone-number": "Phone Number",
        "price": "Price",
        "product-dimensions": "Product Dimensions",
        "product-image": "Product Image",
        "product-name": "Product Name",
        "product-price": "Product Price",
        "product-question-text": "Product Question Text",
        "product-questions": "Product Questions",
        "product-skus": "Product SKUs",
        "product-titles": "Product Titles",
        "product-unit-price": "Product Unit Price",
        "product-weight": "Product Weight",
        "quantity": "Quantity",
        "question-text": "Question Text",
        "sent-on": "Email Sent On",
        "shipping-amount": "Shipping Amount",
        "sku": "SKU",
        "source": "Source",
        "state": "State",
        "status": "Status",
        "subject": "Email Subject",
        "survey-url": "Survey URL",
        "to-email": "To Email",
        "to-name": "To Name"
      },
      "collext": {
        "description": "Shipping Made Easy",
        "name": "Collext"
      },
      "crosstab": {
        "alt-name": "Row per Order",
        "description": "This will generate an export of all orders and include a column for each product.",
        "name": "CrossTab"
      },
      "crowdox": {
        "alt-name": "Row per Order and SKU",
        "description": "This will generate an export of all orders and products with a row for every order and SKU.",
        "name": "Crowd Ox"
      },
      "easy_ship": {
        "description": "The all in one shipping platform for global eCommerce. Connect your store, ship with 100+ shipping solutions and save up to 70%.",
        "name": "EasyShip"
      },
      "emails": {
        "description": "Export an excel sheet of all emails sent from Crowd Ox",
        "name": "Order Email"
      },
      "file-format": "{format, select, csv {CSV} pdf {PDF} xml {XML} excel {Excel} other {{format}} }",
      "floship": {
        "description": "Floship takes care of your international shipments by providing end-to-end shipping and logistics solutions for your e-commerce.",
        "name": "Floship"
      },
      "format-message": "This format exports in the {format, select, csv {CSV} pdf {PDF} xml {XML} excel {Excel} other {{format}} } format.",
      "fulfillment_by_amazon": {
        "description": "Send your products to Amazon’s fulfillment centers, and they pick, pack, ship, and provide customer service for those products.",
        "name": "Fulfillment By Amazon"
      },
      "funagain_logistics": {
        "description": "Funagain Logistics provides comprehensive fulfillment solutions and specializes in handling Kickstarter campaigns and tabletop games.",
        "name": "Funagain Logistics"
      },
      "james_and_james": {
        "description": "eCommerce fulfilment that grows with you.",
        "name": "James and James",
        "warning": "Crowd Ox accepts foreign characters (umlaut eg. ä, ö), This export only supports English characters. We suggest discussing with them and potentially exporting groups of users by country."
      },
      "options": {
        "with_question": {
          "label": "Include first question and answers in export",
          "tooltip": "If your orders have at most one question, you may include it in the export."
        }
      },
      "order_invoice": {
        "description": "Printable invoices for each order with the SKU, Quantity and Price.",
        "name": "Order Invoice"
      },
      "order_picklist": {
        "description": "Printable picklist for each order with each SKU and Quantity.",
        "name": "Order Picklist"
      },
      "quartermaster": {
        "description": "Your Premier Provider of Crowdfunding Fulfillment, Shipping & Warehousing.",
        "name": "Quartermaster"
      },
      "questions": {
        "description": "Answers to questions from customers.",
        "name": "Questions"
      },
      "send_from_china": {
        "description": "Send from China",
        "name": "Send from China"
      },
      "ship_3pl": {
        "description": "3PL Central provides easy-to-use, Cloud-based warehouse management software (WMS) for 3PLs, public warehouses and warehouse operations of all kinds.",
        "name": "3PL Central"
      },
      "ship_bob": {
        "description": "ShipBob empowers you to run your e-commerce business with fulfillment centers near your customers and the tools to maintain control over inventory, orders, and shipments.",
        "name": "ShipBob"
      },
      "ship_station": {
        "description": "Import, manage, and ship your orders with ShipStation, the #1 shipping software of online sellers.",
        "name": "ShipStation"
      },
      "shipwire": {
        "description": "The cloud-based platform to power your global logistics network.",
        "name": "Shipwire"
      },
      "stamps": {
        "description": "Stamps",
        "name": "Stamps"
      },
      "summary": {
        "description": "Overview of information on each order in the system.",
        "name": "Order Summary"
      },
      "summary_legacy": {
        "description": "Overview of information on each order in the system.",
        "name": "Order Summary Legacy"
      },
      "transactions_by_order": {
        "description": "All transactions made by customers with a column for each payment provider.",
        "name": "Transactions By Order"
      },
      "transactions_by_order_and_date": {
        "description": "All transactions made by customers grouped by date and payment provider.",
        "name": "Transactions By Order & Date"
      },
      "whiplash": {
        "description": "Whiplash fulfillment will store and ship your products on demand.",
        "name": "Whiplash"
      }
    },
    "order-history": {
      "address_not_imported": "The address was incomplete when imported.\nAs a result, the address was blank on import.\n",
      "canceled_for_full_refund": "This order was previously approved, but was canceled afterward because of a full refund.\n",
      "customer_name_missing": "The customer's name was missing when imported.\nAs a result, their name was defaulted to 'Customer'.\n",
      "external_id_missing": "The external identifier we use to determine unique orders was missing when imported.\nThis is usually provided from the import source to help prevent duplicate orders, but wasn't present.\n",
      "full_refund_locked": "The initial external charge was initially received and then at a later time marked as fully refunded.\nThis order wasn't automatically canceled because either it or one of its order selections were locked.\nPlease determine if anything was shipped or fulfilled, then cancel the order.\n",
      "full_refund_mixed_transactions": "The initial external charge was initially received and then at a later time marked as fully refunded.\nThis order wasn't marked as canceled because additional transactions are on the account.\nPlease cancel or refund the other transactions and then cancel the order.\n",
      "later_unconfirmed_transaction_locked": "This order was completed and locked, but was imported afterward with an unconfirmed transaction.\n",
      "later_unconfirmed_transaction_reopened": "This order was completed, but was imported afterward with an unconfirmed transaction.\nIt was automatically unapproved so it won't be exported.\n",
      "order_amount_invalid": "The amount pledged to purchase this selection was less than the expected selection price amount when importing this customer.\nThis can occur if the customer purchased this selection and then later the selection purchase price amount was changed.\nThis may cause a balance difference on the customers account and would need to be manually adjusted to correct.\n",
      "partial_refund_received": "This order received a partial refund. Partial refunds aren't expected.\n",
      "private_amount": "The customer has elected to make their pledge amount private.\nAs a result, we are unable to import a transaction for this order, even if one has been made.\n",
      "reward_amount_invalid": "The purchase price amount was different than we expected when importing this customer.\nThis can occur if the customer purchased this selection and then later the selection purchase price amount was changed.\nThis may cause a balance difference on the customers account and would need to be manually adjusted to correct.\n"
    },
    "payment_gateways": {
      "actions": {
        "activate": {
          "not_owner_tooltip": "Only the company owner can activate a payment gateway",
          "title": "Activate"
        },
        "connect": {
          "not_owner_tooltip": "Only the company owner can connect a payment gateway",
          "title": "Connect"
        },
        "deactivate": {
          "not_owner_tooltip": "Only the company owner can deactivate a payment gateway",
          "title": "Deactivate"
        },
        "disable": {
          "not_owner_tooltip": "Only the company owner can disable a payment gateway",
          "title": "Disable"
        },
        "enable": {
          "not_owner_tooltip": "Only the company owner can enable a payment gateway",
          "title": "Enable"
        },
        "import": {
          "not_owner_tooltip": "Only the company owner can import a payment gateway",
          "title": "Import"
        },
        "reconnect": {
          "not_owner_tooltip": "Only the company owner can reconnect a payment gateway",
          "title": "Reconnect"
        }
      },
      "import-modal": {
        "errors": {
          "payment_gateway_not_connected": "The payment gateway for the specified project has either been deactivated or disabled.",
          "payment_gateway_not_found": "A payment gateway for the specified project does not exist."
        }
      },
      "state": {
        "connected": {
          "description": "Active payment gateway for new transactions",
          "title": "Connected"
        },
        "deactivated": {
          "description": "This account is connected, but not the primary account for new transactions.",
          "title": "Deactivated"
        },
        "disabled": {
          "description": "No longer in use for new transactions or for processing refunds",
          "title": "Disabled"
        },
        "expired": {
          "description": "Our access to this account has expired",
          "title": "Expired"
        },
        "needs_confirmed_email": {
          "description": "You must log into your {provider} account and confirm the email address associated with it before payments can be processed.",
          "title": "Needs Email Confirmation"
        },
        "needs_permissions": {
          "description": "The permissions to allow us to process on your behalf have been revoked. Please Reconnect your account.",
          "title": "Needs Permissions"
        },
        "nonchargeable": {
          "description": "Your {provider} account is unable to receive payments. Please log into that account and resolve any alerts.",
          "title": "Unable to Accept Live Charges"
        },
        "not_working": {
          "description": "There seems to be an unknown problem with this provider",
          "title": "Something isn't working"
        }
      }
    },
    "phrase": {
      "actions": "Actions",
      "add_to_bottom": "Add to bottom",
      "admin_actions": "Admin Actions",
      "approve": "Ok",
      "built_by": "Built by {source}",
      "cancel": "Cancel",
      "change": "Change",
      "clear": "Clear",
      "click_here": "Click Here",
      "clone": "Clone",
      "close": "Close",
      "column_headers_may_be": "The column headers may be the exact names above, or <em>snake_case</em>, or <em>CamelCase</em>.",
      "completed": "Completed",
      "copied": "Copied",
      "copied_to_clipboard": "{item} has been copied to the clipboard",
      "copy": "Copy",
      "copyright": "{year} Crowd Ox, LLC. All rights reserved.",
      "create": "Create",
      "create_or_edit": "{isNew, select, true {Create} other {Edit}}",
      "create_or_save": "{isNew, select, true {Create} other {Save}}",
      "crowdox": "<span class=\"crowdox text\"><em>Crowd</em> Ox</span>",
      "csv_instructions": "You will need to provide a CSV file with the following column headers:",
      "csv_or_excel_instructions": "You will need to provide a CSV or Excel (.xlsx) file with the following column headers:",
      "delete": "Delete",
      "deleting": "Deleting",
      "done": "Done",
      "download": "Download",
      "download_sample": "Download Sample",
      "drag_image_or": "Drag your image here or",
      "edit": "Edit",
      "email_address": "Email Address",
      "enabled": "Enabled",
      "enter_placeholder": "Enter {item}",
      "estimated": "Estimated",
      "external_help": "Click for more information about {subject}",
      "filtering_by": "<i>Filtering By:</i> {filter}",
      "full_results": "Full Results",
      "go_back": "Go Back",
      "in_progress": "In Progress",
      "loading": "Loading",
      "location": "Location",
      "locked_by": "The order has been locked by {process}",
      "new": "New",
      "next": "Next",
      "not_enabled": "Not Enabled",
      "ok": "Ok",
      "opened": "Opened",
      "orders": "Orders",
      "password": "Password",
      "powered_by": "Powered by",
      "preview": "Preview",
      "previous": "Previous",
      "remove": "Remove",
      "save": "Save",
      "save_error": "The server returned the following errors while trying to save. Please fix and try again.",
      "saving": "Saving",
      "select_image": "select image to upload",
      "selected": "Selected",
      "showing_results": "Showing {count, plural, =0 {{count}} =1 {{count}} other {{page_range}}} {count, plural, =0 {results} =1 {result} other {of {count} results}}",
      "steps": {
        "completed": "Completed",
        "extras": "Extras",
        "rewards": "Rewards",
        "shipping": "Shipping"
      },
      "update": "Update",
      "uploading": "Uploading",
      "validation_issue": "Looks like we're unable to save due to a validation issue. Please view and fix the errors shown.",
      "view": "View",
      "visible_attributes": "Visible {entity} Attributes"
    },
    "price_banner": "The {product} costs {price, number, USD}",
    "purpose": {
      "ecommerce": {
        "stage": {
          "checkout": {
            "address": {
              "address": "Address",
              "address_line_1": "Line 1",
              "address_line_2": "Line 2",
              "address_missing": "Looks like we are missing some information for your address above. Please double check your address to ensure your shipment isn't delayed.\n",
              "address_override": "My address above is correct",
              "city": "{is_usa, select, true {City} other {Locality or City}}",
              "company_name": "Company Name",
              "country": "Country",
              "country_completed": "Country can't be updated once your selections have been completed.",
              "country_locked": "The country selection has been locked. Please contact the project creator if you need to change it.",
              "first_name": "First Name",
              "full_name": "Full Name",
              "header": "Shipping Address",
              "last_name": "Last Name",
              "middle_name": "Middle {is_split_full, select, true {Name} other {Initial}}",
              "minimum_message": "We don't have enough information to be able to ship your items. {is_usa, select,\n  true {Please fill in all of the required fields.}\n  other {Please fill in at a minimum your Name, Address Line 1, City, and Country information.}\n}\n",
              "navigation": "Shipping",
              "phone_number": "Phone Number",
              "phone_number_missing": "Looks like we are missing your phone number above. A phone number helps to ensure your shipment isn't delayed.",
              "phone_number_override": "I don't have a Phone Number",
              "phone_number_required": "Shipping providers require a phone number{is_international, select, true { for international shipments} other {}}.",
              "postal_code": "{is_usa, select, true {Zip Code} other {Postal Code}}",
              "save_action": "Save Address & Continue to Confirm",
              "state": "{is_usa, select, true {State} other {Province or State}}",
              "sub_header": "Enter the address where you'd like your items shipped."
            },
            "confirm": {
              "agreement_acceptance": "Completion of this page will be taken as acceptance of the <a href=\"https://crowdox.com/legal/pba\" target=\"_blank\">Project Customers Agreement</a> and our <a href=\"https://crowdox.com/legal/terms\" target=\"_blank\">Terms of Use</a>.",
              "credit_card_charged": "Your credit card will be charged when you click submit.",
              "credit_card_label": "Name on card",
              "credit_card_name": "Name",
              "excess_funds": "Extra donated to project",
              "header": "Almost Done, Confirm Your Selections",
              "navigation": "Confirm",
              "newsletter_subscription": "I want to discover more amazing projects and signup for the BackerKit Newsletters.",
              "pay_with_credit_card": "Pay with Credit Card",
              "project_currency_is_different": "(The project currency is different than your selected country. Conversion rates may apply.)",
              "save_action": "Complete & {has_balance, select,\n  true {Pay {amount} Balance}\n  other {Finalize Selections}\n}\n",
              "sub_header": "Please review your items below. {can_reopen, select,\n  true {}\n  other {After confirmation, your selections are final and cannot be updated.}\n}\n"
            },
            "extras": {
              "add_product": "Add {has_reward_selections, select, true {Another} other {To Purchase}}",
              "bundle_products": "Products In Your Bundle",
              "customer_quantity_left": "{count} Left",
              "customer_quantity_max": "{count} per customer",
              "excess_funds": "You have additional funds available that can be used to purchase extras below.",
              "header": "Extras",
              "maximum_allowed": "Maximum Allowed Added",
              "navigation": "Extras",
              "next_action": "Continue to the shipping page",
              "no_extras": "Don't want any extras?",
              "quantity": "Quantity",
              "remove_product": "Remove",
              "reward_selections": "You have {count} in your reward",
              "save_action": "{has_extras, select, true {Save Extras & Continue} other {Continue}}",
              "sold_out": "Sold Out",
              "sub_header": "{extra_mappings, selectordinal,\n  =0 {There are no extras available at the {configuration_name} level.}\n  other {{extra_funds, selectordinal,\n    =0 {Want to purchase extras? Add additional items to your order below.}\n    other {If you pledged for any extras during the campaign, please mark them now.}\n  }}\n}\n",
              "total_quantity_left": "Only {count} left",
              "view_below": "View Below"
            },
            "phrase": {
              "back_to_section": "Back to {name}",
              "bundle": "Bundle",
              "click_thumbnails": "Click thumbnails to zoom",
              "contact": "Contact",
              "country": "Country",
              "country_limited": "Only ships to certain countries",
              "includes": "Includes",
              "powered_by": "<span class=\"powered\">Powered by</span> <span class=\"logo\"><em>Crowd</em> Ox</span>\n",
              "product_questions": "Product Questions?",
              "site_issues": "Site Issues?",
              "we_use_cookies": "We use cookies"
            },
            "review": {
              "header": "Congratulations!",
              "navigation": "Congratulations!",
              "payment_details": "Payment Details",
              "review_experience": "Review Your Experience",
              "sub_header": "Thank you for ordering from {project_name}. Here's a summary of your order information."
            },
            "rewards": {
              "additional_funds": "Any additional funds you pledged for extras can be selected on the next page.",
              "all_configurations_locked": "All of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "any_configuration_locked": "Some of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "choices": "Selections & Choices",
              "choose_action": "{is_external, select, true {Confirm} other {Select}} & Choose Options",
              "estimated_delivery": "Est. delivery: {estimated_delivery}",
              "excess_funds": "You have {excess_funds} in excess funds available. You can apply the excess to extras on the next page{can_upgrade, select, true { or to upgrade your selection below} other {}}.",
              "header": "Confirm Your Selection",
              "manual_choices": "Manually Added Selections & Choices",
              "manual_selection_includes": "Manually Added Selections Include",
              "manual_selection_includes_description": "These are selections that were added manually by the project creator",
              "navigation": "Bundle",
              "original_configuration": "Pledged on {site}",
              "other_choices": "Other Selections & Choices",
              "other_selection_includes": "Other Selections Include",
              "other_selection_includes_description": "These are selections previously purchased outside of Crowd Ox",
              "save_action": "Save & Continue",
              "select_configuration": "Select this Bundle",
              "selection_includes": "Selection Includes",
              "selection_none": "No Items Included in Selection",
              "sub_header": "Below you'll find what you {is_external, select, true {backed} other {selected}} {site}. Please review and choose your selections to proceed.",
              "title": "Bundle Selection",
              "upgrade_configuration": "Or Choose a Different Selection Below",
              "upgraded_configuration": "You have chosen a different selection below.",
              "your_info": "Your Info"
            },
            "summary": {
              "balance": "Balance",
              "bundle": "{name} Total",
              "customs_duty": "Customs Duty",
              "external": "Other Purchase Total",
              "extras": "Extras Total",
              "manual": "Manual Added Total",
              "sales_tax": "Sales Tax",
              "value_added_tax": "Value Added Tax (VAT)"
            }
          }
        }
      },
      "pledge_management": {
        "stage": {
          "checkout": {
            "address": {
              "address": "Address",
              "address_line_1": "Line 1",
              "address_line_2": "Line 2",
              "address_missing": "Looks like we are missing some information for your address above. Please double check your address to ensure your shipment isn't delayed.\n",
              "address_override": "My address above is correct",
              "city": "{is_usa, select, true {City} other {Locality or City}}",
              "company_name": "Company Name",
              "country": "Country",
              "country_completed": "Country can't be updated once your selections have been completed.",
              "country_locked": "The country selection has been locked. Please contact the project creator if you need to change it.",
              "first_name": "First Name",
              "full_name": "Full Name",
              "header": "Shipping Address",
              "last_name": "Last Name",
              "middle_name": "Middle {is_split_full, select, true {Name} other {Initial}}",
              "minimum_message": "We don't have enough information to be able to ship your items. {is_usa, select,\n  true {Please fill in all of the required fields.}\n  other {Please fill in at a minimum your Name, Address Line 1, City, and Country information.}\n}\n",
              "navigation": "Shipping",
              "phone_number": "Phone Number",
              "phone_number_missing": "Looks like we are missing your phone number above. A phone number helps to ensure your shipment isn't delayed.",
              "phone_number_override": "I don't have a Phone Number",
              "phone_number_required": "Shipping providers require a phone number{is_international, select, true { for international shipments} other {}}.",
              "postal_code": "{is_usa, select, true {Zip Code} other {Postal Code}}",
              "save_action": "Save Address & Continue to Confirm",
              "state": "{is_usa, select, true {State} other {Province or State}}",
              "sub_header": "Enter the address where you'd like your items shipped."
            },
            "confirm": {
              "agreement_acceptance": "Completion of this page will be taken as acceptance of the <a href=\"https://crowdox.com/legal/pba\" target=\"_blank\">Project Customers Agreement</a> and our <a href=\"https://crowdox.com/legal/terms\" target=\"_blank\">Terms of Use</a>.",
              "credit_card_charged": "Your credit card will be charged when you click submit.",
              "credit_card_label": "Name on card",
              "credit_card_name": "Name",
              "excess_funds": "Extra donated to project",
              "header": "Almost Done, Confirm Your Selections",
              "navigation": "Confirm",
              "newsletter_subscription": "I want to discover more amazing projects and signup for the BackerKit Newsletters.",
              "pay_with_credit_card": "Pay with Credit Card",
              "project_currency_is_different": "(The project currency is different than your selected country. Conversion rates may apply.)",
              "save_action": "Complete & {has_balance, select,\n  true {Pay {amount} Balance}\n  other {Finalize Selections}\n}\n",
              "sub_header": "Please review your items below. {can_reopen, select,\n  true {}\n  other {After confirmation, your selections are final and cannot be updated.}\n}\n"
            },
            "extras": {
              "add_product": "Add {has_reward_selections, select, true {Another} other {To Purchase}}",
              "bundle_products": "Products In Your Bundle",
              "customer_quantity_left": "{count} Left",
              "customer_quantity_max": "{count} per customer",
              "excess_funds": "You have additional funds available that can be used to purchase extras below.",
              "header": "Extras",
              "maximum_allowed": "Maximum Allowed Added",
              "navigation": "Extras",
              "next_action": "Continue to the shipping page",
              "no_extras": "Don't want any extras?",
              "quantity": "Quantity",
              "remove_product": "Remove",
              "reward_selections": "You have {count} in your reward",
              "save_action": "{has_extras, select, true {Save Extras & Continue} other {Continue}}",
              "sold_out": "Sold Out",
              "sub_header": "{extra_mappings, selectordinal,\n  =0 {There are no extras available at the {configuration_name} level.}\n  other {{extra_funds, selectordinal,\n    =0 {Want to purchase extras? Add additional items to your order below.}\n    other {If you pledged for any extras during the campaign, please mark them now.}\n  }}\n}\n",
              "total_quantity_left": "Only {count} left",
              "view_below": "View Below"
            },
            "phrase": {
              "back_to_section": "Back to {name}",
              "bundle": "Bundle",
              "click_thumbnails": "Click thumbnails to zoom",
              "contact": "Contact",
              "country": "Country",
              "country_limited": "Only ships to certain countries",
              "includes": "Includes",
              "powered_by": "<span class=\"powered\">Powered by</span> <span class=\"logo\"><em>Crowd</em> Ox</span>\n",
              "product_questions": "Product Questions?",
              "site_issues": "Site Issues?",
              "we_use_cookies": "We use cookies"
            },
            "review": {
              "header": "Congratulations!",
              "navigation": "Congratulations!",
              "payment_details": "Payment Details",
              "review_experience": "Review Your Experience",
              "sub_header": "Thank you for supporting {project_name}. Here's a review of your finalized pledge information."
            },
            "rewards": {
              "additional_funds": "Any additional funds you pledged for extras can be selected on the next page.",
              "all_configurations_locked": "All of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "any_configuration_locked": "Some of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "choices": "Selections & Choices",
              "choose_action": "{is_external, select, true {Confirm} other {Select}} & Choose Options",
              "estimated_delivery": "Est. delivery: {estimated_delivery}",
              "excess_funds": "You have {excess_funds} in excess funds available. You can apply the excess to extras on the next page{can_upgrade, select, true { or to upgrade your selection below} other {}}.",
              "header": "Confirm Your Selection",
              "manual_choices": "Manually Added Selections & Choices",
              "manual_selection_includes": "Manually Added Selections Include",
              "manual_selection_includes_description": "These are selections that were added manually by the project creator",
              "navigation": "Rewards",
              "original_configuration": "Pledged on {site}",
              "other_choices": "Other Selections & Choices",
              "other_selection_includes": "Other Selections Include",
              "other_selection_includes_description": "These are selections previously purchased outside of Crowd Ox",
              "save_action": "Save & Continue",
              "select_configuration": "Select this Reward",
              "selection_includes": "Selection Includes",
              "selection_none": "No Items Included in Selection",
              "sub_header": "Below you'll find what you {is_external, select, true {backed} other {selected}} {site}. Please review and choose your selections to proceed.",
              "title": "Reward Selection",
              "upgrade_configuration": "Or Choose a Different Selection Below",
              "upgraded_configuration": "You have chosen a different selection below.",
              "your_info": "Your Info"
            },
            "summary": {
              "balance": "Balance",
              "bundle": "{name} Total",
              "customs_duty": "Customs Duty",
              "external": "Other Purchase Total",
              "extras": "Extras Total",
              "manual": "Manual Added Total",
              "sales_tax": "Sales Tax",
              "value_added_tax": "Value Added Tax (VAT)"
            }
          }
        }
      },
      "pre_order": {
        "stage": {
          "checkout": {
            "address": {
              "address": "Address",
              "address_line_1": "Line 1",
              "address_line_2": "Line 2",
              "address_missing": "Looks like we are missing some information for your address above. Please double check your address to ensure your shipment isn't delayed.\n",
              "address_override": "My address above is correct",
              "city": "{is_usa, select, true {City} other {Locality or City}}",
              "company_name": "Company Name",
              "country": "Country",
              "country_completed": "Country can't be updated once your selections have been completed.",
              "country_locked": "The country selection has been locked. Please contact the project creator if you need to change it.",
              "first_name": "First Name",
              "full_name": "Full Name",
              "header": "Shipping Address",
              "last_name": "Last Name",
              "middle_name": "Middle {is_split_full, select, true {Name} other {Initial}}",
              "minimum_message": "We don't have enough information to be able to ship your items. {is_usa, select,\n  true {Please fill in all of the required fields.}\n  other {Please fill in at a minimum your Name, Address Line 1, City, and Country information.}\n}\n",
              "navigation": "Shipping",
              "phone_number": "Phone Number",
              "phone_number_missing": "Looks like we are missing your phone number above. A phone number helps to ensure your shipment isn't delayed.",
              "phone_number_override": "I don't have a Phone Number",
              "phone_number_required": "Shipping providers require a phone number{is_international, select, true { for international shipments} other {}}.",
              "postal_code": "{is_usa, select, true {Zip Code} other {Postal Code}}",
              "save_action": "Save Address & Continue to Confirm",
              "state": "{is_usa, select, true {State} other {Province or State}}",
              "sub_header": "Enter the address where you'd like your items shipped."
            },
            "confirm": {
              "agreement_acceptance": "Completion of this page will be taken as acceptance of the <a href=\"https://crowdox.com/legal/pba\" target=\"_blank\">Project Customers Agreement</a> and our <a href=\"https://crowdox.com/legal/terms\" target=\"_blank\">Terms of Use</a>.",
              "credit_card_charged": "Your credit card will be charged when you click submit.",
              "credit_card_label": "Name on card",
              "credit_card_name": "Name",
              "excess_funds": "Extra donated to project",
              "header": "Almost Done, Confirm Your Selections",
              "navigation": "Confirm",
              "newsletter_subscription": "I want to discover more amazing projects and signup for the BackerKit Newsletters.",
              "pay_with_credit_card": "Pay with Credit Card",
              "project_currency_is_different": "(The project currency is different than your selected country. Conversion rates may apply.)",
              "save_action": "Complete & {has_balance, select,\n  true {Pay {amount} Balance}\n  other {Finalize Selections}\n}\n",
              "sub_header": "Please review your items below. {can_reopen, select,\n  true {}\n  other {After confirmation, your selections are final and cannot be updated.}\n}\n"
            },
            "extras": {
              "add_product": "Add {has_reward_selections, select, true {Another} other {To Purchase}}",
              "bundle_products": "Products In Your Bundle",
              "customer_quantity_left": "{count} Left",
              "customer_quantity_max": "{count} per customer",
              "excess_funds": "You have additional funds available that can be used to purchase extras below.",
              "header": "Extras",
              "maximum_allowed": "Maximum Allowed Added",
              "navigation": "Extras",
              "next_action": "Continue to the shipping page",
              "no_extras": "Don't want any extras?",
              "quantity": "Quantity",
              "remove_product": "Remove",
              "reward_selections": "You have {count} in your reward",
              "save_action": "{has_extras, select, true {Save Extras & Continue} other {Continue}}",
              "sold_out": "Sold Out",
              "sub_header": "{extra_mappings, selectordinal,\n  =0 {There are no extras available at the {configuration_name} level.}\n  other {{extra_funds, selectordinal,\n    =0 {Want to purchase extras? Add additional items to your order below.}\n    other {If you pledged for any extras during the campaign, please mark them now.}\n  }}\n}\n",
              "total_quantity_left": "Only {count} left",
              "view_below": "View Below"
            },
            "phrase": {
              "back_to_section": "Back to {name}",
              "bundle": "Bundle",
              "click_thumbnails": "Click thumbnails to zoom",
              "contact": "Contact",
              "country": "Country",
              "country_limited": "Only ships to certain countries",
              "includes": "Includes",
              "powered_by": "<span class=\"powered\">Powered by</span> <span class=\"logo\"><em>Crowd</em> Ox</span>\n",
              "product_questions": "Product Questions?",
              "site_issues": "Site Issues?",
              "we_use_cookies": "We use cookies"
            },
            "review": {
              "header": "Congratulations!",
              "navigation": "Congratulations!",
              "payment_details": "Payment Details",
              "review_experience": "Review Your Experience",
              "sub_header": "Thank you for ordering from {project_name}. Here's a summary of your order information."
            },
            "rewards": {
              "additional_funds": "Any additional funds you pledged for extras can be selected on the next page.",
              "all_configurations_locked": "All of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "any_configuration_locked": "Some of the selections have been marked as locked from the project creator and won't be able to be changed.",
              "choices": "Selections & Choices",
              "choose_action": "{is_external, select, true {Confirm} other {Select}} & Choose Options",
              "estimated_delivery": "Est. delivery: {estimated_delivery}",
              "excess_funds": "You have {excess_funds} in excess funds available. You can apply the excess to extras on the next page{can_upgrade, select, true { or to upgrade your selection below} other {}}.",
              "header": "Confirm Your Selection",
              "manual_choices": "Manually Added Selections & Choices",
              "manual_selection_includes": "Manually Added Selections Include",
              "manual_selection_includes_description": "These are selections that were added manually by the project creator",
              "navigation": "Bundle",
              "original_configuration": "Pledged on {site}",
              "other_choices": "Other Selections & Choices",
              "other_selection_includes": "Other Selections Include",
              "other_selection_includes_description": "These are selections previously purchased outside of Crowd Ox",
              "save_action": "Save & Continue",
              "select_configuration": "Select this Bundle",
              "selection_includes": "Selection Includes",
              "selection_none": "No Items Included in Selection",
              "sub_header": "Below you'll find what you {is_external, select, true {backed} other {selected}} {site}. Please review and choose your selections to proceed.",
              "title": "Bundle Selection",
              "upgrade_configuration": "Or Choose a Different Selection Below",
              "upgraded_configuration": "You have chosen a different selection below.",
              "your_info": "Your Info"
            },
            "summary": {
              "balance": "Balance",
              "bundle": "{name} Total",
              "customs_duty": "Customs Duty",
              "external": "Other Purchase Total",
              "extras": "Extras Total",
              "manual": "Manual Added Total",
              "sales_tax": "Sales Tax",
              "value_added_tax": "Value Added Tax (VAT)"
            }
          }
        }
      }
    },
    "route": {
      "admin": {
        "companies": {
          "company": {
            "projects": {
              "header": "Projects"
            }
          },
          "header": "Companies",
          "index": {
            "status_options": {
              "option": {
                "accepted": "Accepted",
                "activated": "Activated",
                "invited": "Invited",
                "not_invited": "Not Invited",
                "project_launched": "Project Launched",
                "project_not_launched": "Project Not Launched"
              }
            }
          }
        },
        "header": "Admin",
        "projects": {
          "header": "Projects",
          "status": {
            "header": "Fulfillment Status"
          }
        }
      },
      "application": {
        "actions": {
          "refresh": "Click to refresh"
        },
        "content": {
          "application_updated": {
            "header": "A new version of {crowdox} is available.",
            "sub_header": "Please save any changes and refresh."
          }
        }
      },
      "companies": {
        "company": {
          "account": {
            "header": "Account Settings",
            "info": {
              "action": {
                "edit": "Edit"
              },
              "header": "Company Info",
              "title": "Company Information"
            },
            "invoices": {
              "content": {
                "none": {
                  "header": "No Invoices Found",
                  "sub_header": ""
                }
              }
            },
            "users": {
              "content": {
                "none": {
                  "header": "No Users Found",
                  "sub_header": "Invite a user by clicking invite above."
                }
              }
            }
          },
          "header": "Companies",
          "projects": {
            "create": {
              "action": {
                "save": "Create Project"
              },
              "content": {
                "header": "Project Details"
              },
              "header": "Create",
              "title": "Create Project"
            },
            "index": {
              "header": "Projects"
            },
            "project": {
              "apps": {
                "header": "Apps",
                "index": {
                  "actions": {
                    "install_app": "Install App"
                  },
                  "content": {
                    "installed": "Installed",
                    "settings": "Settings",
                    "uninstall": "Uninstall",
                    "view": "View"
                  },
                  "header": "Installed Apps",
                  "title": "Apps"
                },
                "install": {
                  "actions": {
                    "view_installed": "View Installed Apps"
                  },
                  "content": {
                    "install": "Install"
                  },
                  "header": "Install App",
                  "title": "Install App"
                },
                "view": {
                  "header": "{name}",
                  "title": "{name}"
                }
              },
              "customers": {
                "header": "Customers",
                "index": {
                  "content": {
                    "load-error": {
                      "header": "Error while loading results",
                      "sub-header": "Please try your request again. If the problem persists, please contact support at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>."
                    },
                    "none": {
                      "header": "No customers found",
                      "sub_header": "{has_filters, select, true {Please check the filter you are using} other {They will show up here once they are created}}"
                    }
                  },
                  "header": "All Customers"
                },
                "title": "Customers",
                "view": {
                  "actions": {
                    "copy_email": {
                      "success": "Email address has been copied to the clipboard",
                      "tooltip": "Copy email address"
                    }
                  },
                  "header": "Viewing Customer",
                  "title": "Customer: {email_or_name}"
                }
              },
              "digital": {
                "header": "Digital",
                "index": {
                  "actions": {
                    "update-status": "Bulk Update Status"
                  },
                  "column": {
                    "count": {
                      "downloads": "{downloads, plural, one {1 file} other {{downloads} files}}",
                      "keys": "{unassigned_keys} of {keys}",
                      "keys-tooltip": "{unassigned_keys, plural, =1 {1 unassigned key} =0 {No unassigned keys} other {{unassigned_keys} unassigned keys}}"
                    }
                  },
                  "content": {
                    "edit": "Edit",
                    "edit_product": "Edit Product",
                    "fulfillment_instructions": "Fulfillment Instructions",
                    "import_product": "Add Digital Fulfillment",
                    "no_products": {
                      "description": "Import a digital product before getting started. You can do that by going to our product section and add a new product and choosing the digital option.\n",
                      "header": "No Digital Products Found"
                    },
                    "search": {
                      "placeholder": "Search by Name"
                    },
                    "view_downloads": {
                      "tooltip": "View and edit downloads"
                    },
                    "view_keys": {
                      "tooltip": "View and edit product keys"
                    }
                  },
                  "fulfillment": {
                    "option": {
                      "download": "Download",
                      "key": "Key"
                    },
                    "placeholder": "Filter by Type"
                  },
                  "header": "All Digital Products",
                  "title": "All Digital Products"
                },
                "view": {
                  "downloads": {
                    "actions": {
                      "assign": "Assign",
                      "upload": "Upload File"
                    },
                    "content": {
                      "no_downloads": {
                        "examples": {
                          "header": "A few examples of downloads could be:",
                          "one": "PDF eBook",
                          "three": "Manuals",
                          "two": "Print and Play"
                        },
                        "header": "No Downloads Added",
                        "sub_header": "Upload a file above to get started"
                      },
                      "warning": "Changes to downloads are immediate. Updates on existing files do not get resent."
                    },
                    "header": "Downloads",
                    "title": "Downloads"
                  },
                  "keys": {
                    "actions": {
                      "assign": "Assign",
                      "upload": "Upload Keys"
                    },
                    "assigned": {
                      "option": {
                        "assigned": "Assigned",
                        "unassigned": "Unassigned"
                      },
                      "placeholder": "Assigned or Unassigned"
                    },
                    "content": {
                      "no_keys": {
                        "header": "No Keys Found"
                      },
                      "search": {
                        "placeholder": "Filter by Key"
                      }
                    },
                    "header": "Keys",
                    "provider": {
                      "placeholder": "All Providers"
                    },
                    "title": "Keys"
                  }
                }
              },
              "info": {
                "configurations": {
                  "content": {
                    "configuration-reward": "Configuration / Reward",
                    "minimum-pledge": "Minimum Pledge Amount",
                    "none": "None",
                    "pledge-late": "Pledge Late",
                    "question": "Question: {text}",
                    "upgrade_type": {
                      "any": "Any",
                      "equal_or_higher": "Equal to or Higher",
                      "none": "None"
                    },
                    "upgrade_type_header": "Upgrade Type"
                  },
                  "header": "Configurations",
                  "header-expanded": "Review Configurations",
                  "title": "Configurations"
                },
                "content": {
                  "all-sources": "All Sources"
                },
                "extras": {
                  "content": {
                    "configuration_extra": "Configuration / Extra",
                    "global": "Global",
                    "none": "None",
                    "price": "Price",
                    "source_global_extras": "Source Global Extras"
                  },
                  "header": "Extras",
                  "header-expanded": "Review Extras",
                  "title": "Extras"
                },
                "header": "Review",
                "products": {
                  "content": {
                    "default": "Default",
                    "product": "Product",
                    "sku": "SKU",
                    "type": "Type"
                  },
                  "header": "Products",
                  "header-expanded": "Review Products",
                  "title": "Products"
                },
                "shipping": {
                  "content": {
                    "configuration": "Configuration",
                    "configuration-extras": "Configuration Extras",
                    "global": "Global",
                    "none": "None",
                    "rest-of-the-world": "Rest of the World",
                    "sources-extras": "Sources Extras"
                  },
                  "header": "Shipping",
                  "header-expanded": "Review Shipping",
                  "title": "Shipping"
                },
                "title": "Review"
              },
              "launch-guide": {
                "content": {
                  "hero": {
                    "header": "Welcome to {crowdox}",
                    "sub_header": "Now that your project has been created, it's time to get it ready for launch. We’ve prepared a list of items below that will help guide you through the process.\n"
                  },
                  "steps": {
                    "one": {
                      "header": "First Steps",
                      "sub_header": "Below is a list of essential items for your project"
                    },
                    "three": {
                      "header": "Ready, Set, Go",
                      "sub_header": "Let's take the final steps to ensure you're ready to launch"
                    },
                    "two": {
                      "header": "Make It Yours",
                      "sub_header": "Customize your project with these optional items below"
                    }
                  }
                },
                "header": "Launch Guide",
                "title": "Launch Guide"
              },
              "not-found": {
                "title": "Project Not Found"
              },
              "orders": {
                "create-export": {
                  "content": {
                    "back": "Back to Orders",
                    "job": {
                      "is_complete": {
                        "content": "Use the <em>Download File</em> button below to retrieve it. We have also emailed a copy to <b>{email}</b>.",
                        "locked": "The orders have been locked.",
                        "title": "The export is ready!",
                        "view": "You can view them here."
                      },
                      "is_failed": {
                        "content": "Unfortunately there was an error when attempting to export.<br/> <br/> If you continue to get this error, please contact us at <a href=\"mailto:{email}\">{email}</a>.\n",
                        "title": "Failed"
                      },
                      "is_queued": {
                        "content": "This process can sometimes take up to 20 minutes depending on how many others are before you.<br/> <br/> Once it completes an email will be sent to {email}.\n",
                        "title": "{status}"
                      },
                      "is_working": {
                        "content": "This process can sometimes take up to 10 minutes depending on how much data needs to be processed.<br/> <br/> Once it completes an email will be sent to {email}.\n",
                        "title": "{status}"
                      }
                    },
                    "steps": {
                      "fulfillment": {
                        "choice": "Choice",
                        "choices": {
                          "all": "All",
                          "not_in_fulfillment": "Not In Fulfillment"
                        },
                        "number": "2",
                        "recommend": "If you will be using this file to start fulfillment, we recommend to only export those that <b>have not</b> been locked or marked as shipped. This will ensure that the {order} selections cannot be changed by customers. <a href=\"https://help.crowdox.com/article/g7nz8kjfzi-how-to-export-segments\" target=\"_blank\">More information</a>",
                        "title": "{order} Fulfillment"
                      },
                      "level": {
                        "choice": "Choice",
                        "choices": {
                          "order_selections": "Specific Order Items",
                          "orders": "Full Orders"
                        },
                        "number": "4",
                        "recommend": "You can export just the selections that match the",
                        "title": "Full or Partial",
                        "warning": "You currently don't have any filters for order selections (such as a product filter.)"
                      },
                      "locking": {
                        "choice": "Choice",
                        "choices": {
                          "lock": "Lock Order Items",
                          "none": "No Locking"
                        },
                        "not_completed_warning": "Locking is only available when the {survey} Status is Completed.",
                        "not_in_fulfillment_warning": "Locking is only available for {orders} that aren't locked or marked as shipped.",
                        "number": "3",
                        "recommend": "If you will be using this file to start fulfillment, we recommend to lock the {order} selections after the export is completed.",
                        "title": "Selection Locking"
                      },
                      "not_answered": "Not Answered!",
                      "step": {
                        "choice": "Choice",
                        "hint": "Those that are incomplete may not have all the necessary information and may not be fully paid. {orders} where the {survey} hasn't been started won't show up in this export at all.",
                        "number": "1",
                        "recommend": "If you will be using this file to start fulfillment, we recommend exporting only completed {surveys}.",
                        "title": "{survey} Status"
                      }
                    }
                  },
                  "header": "Create Export"
                },
                "groups": {
                  "content": {
                    "none": {
                      "header": "No Results Found",
                      "sub_header": "Please check the filter you are using."
                    }
                  }
                },
                "header": "Orders",
                "index": {
                  "actions": {
                    "actions": "Actions",
                    "add-export": "More...",
                    "add_order": "Add Order",
                    "edit_segment": "Edit Segment Name",
                    "export": "Export",
                    "export_invoices": "Invoices",
                    "export_marketing_questions": "Questions",
                    "export_order_emails": "Order Emails",
                    "export_order_row_per_order": "Crosstab (Column per Product)",
                    "export_order_row_per_order_and_sku": "Standard (Row per Product)",
                    "export_order_selections": "Order Selections",
                    "export_order_summary": "Summary",
                    "export_order_summary_legacy": "Summary Legacy",
                    "export_picklists": "Picklists",
                    "import": "Import",
                    "import_order_selections": "Order Selections",
                    "import_orders": "Orders",
                    "import_shipped_order_selections": "Shipped Order Selections",
                    "import_shipped_orders": "Shipped Orders",
                    "load": "Load",
                    "orders": "Orders",
                    "save_orders": "Save {number, plural, =1 {1 Order} other {# Orders}}",
                    "segment": "Segment",
                    "upload": "Upload",
                    "view_segments": "View All Segments"
                  },
                  "content": {
                    "custom-export-format": "Custom Export Format for {name}",
                    "export": "Export",
                    "load-error": {
                      "header": "Error while loading results",
                      "sub-header": "Please try your request again. If the problem persists, please contact support at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>."
                    },
                    "none": {
                      "header": "No Results Found",
                      "sub_header": "Please check the filter you are using."
                    },
                    "orders": "Orders",
                    "print": "Print",
                    "products": "Products"
                  },
                  "header": "All Orders",
                  "tooltip": {
                    "needs_completed_step": "You must add a 'Step' filter of 'Completed' to use this export."
                  }
                },
                "not-found": {
                  "header": "We are unable to find the order you are looking for.",
                  "sub_header": "Either the order was deleted or the link is incorrect. If you feel that this is an error, please contact us at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>",
                  "title": "Order Not Found"
                },
                "tags": {
                  "index": {
                    "actions": {
                      "add": "Add Tag"
                    },
                    "content": {
                      "edit_tag": {
                        "tooltip": "Edit tag"
                      },
                      "none": {
                        "header": "No tags found",
                        "sub-header": "To create one, click the <em>Add Tag</em> button."
                      }
                    },
                    "header": "Tags",
                    "overview": "Tags",
                    "title": "Tags"
                  }
                },
                "title": "Orders",
                "view": {
                  "actions": {
                    "cancel_order": "Cancel Order",
                    "copy_email": {
                      "success": "Email address has been copied to the clipboard",
                      "tooltip": "Copy email address"
                    },
                    "customer": {
                      "header": "Customer",
                      "view_all_orders": "View All Orders"
                    },
                    "delete_order": "Delete Order",
                    "login_as_customer": "Login as Customer",
                    "order": {
                      "change_configuration": "Change Configuration",
                      "header": "Order",
                      "reopen": "Reopen"
                    },
                    "reactivate_email": "Reactivate Email",
                    "save_order": "Save Order",
                    "survey_link": {
                      "success": "Survey Url has been copied to the clipboard",
                      "tooltip": "Copy survey link"
                    },
                    "uncancel_order": "Un-Cancel Order",
                    "unlock_order": "Unlock Order"
                  },
                  "content": {
                    "address": {
                      "copy_address": {
                        "success": "{local_pickup_address, select, true {Local Pickup Address has been copied to the clipboard} other {Address has been copied to the clipboard}}"
                      },
                      "country": "Customers Country",
                      "fully_validated": "Address Fully Validated",
                      "header": "{local_pickup_address, select, true {Local Pickup Address} other {Address}}",
                      "no_phone_number": "User confirmed they didn't have a phone number",
                      "partially_validated": {
                        "header": "Address Partially Validated",
                        "tooltip": "The address was marked as partially validated by our external validation services. This means most the information could be validated correctly, but some of the information was missing or incorrect."
                      },
                      "phone_number": "Phone Number"
                    },
                    "custom_fields": {
                      "header": "Custom Fields",
                      "not_saved": "(Not saved)",
                      "save": "Save"
                    },
                    "customer_info": {
                      "email": "Email",
                      "header": "Customer Info",
                      "name": "Name"
                    },
                    "emails": {
                      "actions": {
                        "invite": "Invite",
                        "resend": "Resend",
                        "send": "Send"
                      },
                      "all": "Show all emails",
                      "header": "Emails Sent",
                      "none": "No emails sent yet",
                      "tooltip": {
                        "live": "Invited emails are sent regularly until the customer completes their survey or refuses it.",
                        "sent_regularly": "Emails are sent regularly until the customer completes their survey or refuses it."
                      }
                    },
                    "notes": {
                      "header": "Notes",
                      "not_saved": "(Not saved)",
                      "save": "Save"
                    },
                    "order_details": {
                      "add_bundle_to_order": "Add Bundle to Order",
                      "add_item_to_order": "Add Item to Order",
                      "header": "Order Details"
                    },
                    "order_history": {
                      "find_out_more": "Find out more",
                      "message": "When importing this customer into {crowdox}, there was {count} unexpected {count, plural, one {event} other {events}} that occurred."
                    },
                    "order_info": {
                      "configuration": "Configuration",
                      "crowdox_id": {
                        "name": "{crowdox} Id",
                        "tooltip": "This Id is used in shipping exports and to internally identify the customer order"
                      },
                      "current_configuration": "Current Configuration",
                      "customer-id": "Customer Id",
                      "customer-view-orders": {
                        "tooltip": "View all orders by this customer"
                      },
                      "date_confirmed": "Date Confirmed",
                      "date_created": {
                        "header": "Date Created",
                        "tooltip": "{is_import_from_api, select, true {This is the date that the pledge was made on the external site.} other {This is the date that the record was created on Crowd Ox.}}"
                      },
                      "date_invited": "Date Invited",
                      "external_id": "{site} Id",
                      "external_pledge": "External Pledge",
                      "header": "Order Info",
                      "last_sync_date": {
                        "header": "Last Sync Date",
                        "tooltip": "This is the date of the last time this order was synced with the external site."
                      },
                      "original_configuration": "Original Configuration",
                      "source": "Source",
                      "survey_status": "Survey Status",
                      "tracking_number": "Tracking Number"
                    },
                    "other": null,
                    "syncs": {
                      "header": "Pushed to External Services",
                      "none": "Has not been pushed"
                    },
                    "tags": {
                      "header": "Tags"
                    },
                    "transactions": {
                      "actions": {
                        "add": "Add",
                        "show_all": "Show All",
                        "show_only_confirmed": "Show Only Confirmed"
                      },
                      "dispute_resolved": "Dispute Resolved",
                      "disputed": "Disputed",
                      "header": "Transactions",
                      "none": "No Transactions For Order",
                      "payment": "Payment",
                      "refund": "Refund"
                    }
                  },
                  "header": "Viewing Order",
                  "title": "Order: {email_or_name}"
                }
              },
              "products": {
                "choices": {
                  "create": {
                    "action": {
                      "save": "Create {is_bundle, select, true {Product Bundle} other {Product Choice}}"
                    },
                    "header": "Create",
                    "header-expanded": "Create {is_bundle, select, true {Product Bundle} other {Product Choice}}{name}",
                    "title": "Create"
                  },
                  "edit": {
                    "action": {
                      "save": "Save {is_bundle, select, true {Product Bundle} other {Product Choice}}"
                    },
                    "header": "Edit",
                    "header-expanded": "Edit {is_bundle, select, true {Product Bundle} other {Product Choice}}: {name}",
                    "title": "Edit"
                  }
                },
                "create": {
                  "action": {
                    "save": "Create Product"
                  },
                  "header": "Create Product"
                },
                "edit": {
                  "action": {
                    "save": "Save Product"
                  },
                  "header": "Edit Product: {name}"
                },
                "header": "Products",
                "index": {
                  "actions": {
                    "add": "Add Product"
                  },
                  "content": {
                    "edit_product": {
                      "tooltip": "Edit Product"
                    },
                    "none": {
                      "header": "No Products Added",
                      "sub-header": "Add a product above to get started. After a product as been added, you can create choices to combine your products together.\n"
                    },
                    "none-found": "No Products Found",
                    "search": {
                      "placeholder": "Filter by name"
                    }
                  },
                  "header": "All Products",
                  "product_type": {
                    "option": {
                      "custom_bundles": "Bundle",
                      "digital": "Digital",
                      "imported_addons_to_review": "Imported Addon Needing Review",
                      "option": "Choice",
                      "physical": "Physical",
                      "unknown": "Imported Product"
                    },
                    "placeholder": "Fulfillment Type"
                  }
                },
                "title": "Products"
              },
              "questions": {
                "create": {
                  "header": "Create Question",
                  "title": "Create Question"
                },
                "edit": {
                  "header": "Edit",
                  "title": "Edit"
                },
                "header": "Questions",
                "index": {
                  "actions": {
                    "add_question": "Add Question"
                  },
                  "content": {
                    "edit_question": {
                      "tooltip": "Edit Question"
                    },
                    "help": "Add questions to gather additional information for your project. Do not create address or telephone questions. We will automatically collect it on any orders with physical products.\n",
                    "none": {
                      "examples": {
                        "header": "A few examples of marketing questions could be:",
                        "one": "How did you hear about our project?",
                        "three": "Do you have any suggestions for future product ideas?",
                        "two": "Would you like to join our newsletter?"
                      },
                      "header": "No Marketing Questions Added",
                      "sub_header": "Add a question above to get started."
                    }
                  },
                  "header": "All Marketing Questions",
                  "question_type": {
                    "option": {
                      "multiple": "Multiple Choice",
                      "text": "Text Entry"
                    },
                    "placeholder": "Question Type"
                  },
                  "title": "Questions"
                },
                "title": "Questions"
              },
              "segments": {
                "actions": {
                  "index": {
                    "content": {
                      "no_project_segment_actions": "No Segment Actions",
                      "no_project_segment_actions_instructions": "Change your filter or create a bulk action to see something show up here"
                    },
                    "field": {
                      "attempts": "Attempts",
                      "completed": "Completed",
                      "created": "Created",
                      "id": "Id",
                      "name": "Name",
                      "operation": "Operation",
                      "orders": "Orders",
                      "state": "State"
                    },
                    "header": "Actions",
                    "operation": {
                      "placeholder": "Operation"
                    },
                    "state": {
                      "option": {
                        "all": "Any",
                        "canceled": "Canceled",
                        "completed": "Completed",
                        "errored": "Errored",
                        "in_error": "In Error",
                        "new": "New",
                        "timed_out": "Timed Out",
                        "working": "Working"
                      },
                      "placeholder": "State"
                    },
                    "title": "Actions"
                  },
                  "view": {
                    "header": "Action",
                    "title": "View Action"
                  }
                },
                "header": "Segments",
                "index": {
                  "action": {
                    "create": "Create Dynamic Segment",
                    "edit": "Edit segment name",
                    "view_orders": "View orders"
                  },
                  "behavior": {
                    "option": {
                      "all": "Fixed or Dynamic",
                      "automated": "Automated",
                      "dynamic": "Dynamic",
                      "dynamic_automated": "Automated",
                      "dynamic_filter": "Dynamic",
                      "fixed": "Fixed"
                    },
                    "placeholder": "Behavior"
                  },
                  "closed": {
                    "option": {
                      "all": "Open or Closed",
                      "false": "Open",
                      "true": "Closed"
                    }
                  },
                  "content": {
                    "action_menu": {
                      "header": "Segment"
                    },
                    "additional_filter": "Additional Filter",
                    "behavior": {
                      "automated": "This is a dynamic filter with an automated bulk action attached to it.",
                      "dynamic": "This is a saved filter. Changes in order content will change the result.",
                      "fixed": "A static set of orders or order items that doesn't change."
                    },
                    "filter": "Filter",
                    "no_segments": "No Segments Created",
                    "no_segments_instructions": "Create a segment to get started."
                  },
                  "field": {
                    "actions": "Actions",
                    "behavior": "Behavior",
                    "created": "Created",
                    "id": "Id",
                    "name": "Name",
                    "orders": "Orders"
                  },
                  "header": "All Segments",
                  "hidden": {
                    "option": {
                      "false": "Visible",
                      "true": "Hidden"
                    },
                    "placeholder": "Hidden"
                  },
                  "search": {
                    "placeholder": "Filter by name"
                  },
                  "title": "Segments"
                },
                "view": {
                  "header": "Viewing Segment",
                  "index": {
                    "action": {
                      "add_credits": "Add Credits",
                      "change_configuration": "Change Configuration",
                      "complete": "Complete",
                      "create_export": "Create Export",
                      "edit": "Edit Segment Name",
                      "lock": "Lock",
                      "send_emails": "Send Emails",
                      "unlock": "Unlock",
                      "view_orders": "View Orders"
                    },
                    "content": {
                      "actions": "Actions",
                      "export": "Export",
                      "no_actions": "No Actions Created",
                      "no_actions_instructions": "Create an action to get started.",
                      "orders": "Orders",
                      "segment": "Segment"
                    },
                    "field": {
                      "attempts": "Attempts",
                      "completed": "Completed",
                      "created": "Created",
                      "id": "Id",
                      "name": "Name",
                      "operation": "Operation",
                      "orders": "Orders",
                      "state": "State"
                    },
                    "header": "Segment: {name}",
                    "title": "Segments"
                  }
                }
              },
              "settings": {
                "fields": {
                  "actions": {
                    "add_custom_field": "Add {entity} Field",
                    "add_order_custom_field": "Add Order Field",
                    "add_product_custom_field": "Add Product Field"
                  },
                  "content": {
                    "edit_custom_field": {
                      "tooltip": "Edit Custom Field"
                    },
                    "entity": {
                      "option": {
                        "customer": "Customer",
                        "order": "Order",
                        "product": "Product"
                      },
                      "placeholder": "Entity"
                    },
                    "field_type": {
                      "option": {
                        "checkbox": "Checkbox",
                        "currency": "Currency",
                        "date": "Date",
                        "number": "Number",
                        "option": "Single Choice",
                        "text": "Text"
                      },
                      "placeholder": "Field Type"
                    },
                    "none": {
                      "header": "No Results Found",
                      "sub-header": "You can add a new custom field above"
                    },
                    "none-found": {
                      "header": "No Results Found",
                      "sub-header": "Check the filter you are using if this is unexpected"
                    },
                    "search": {
                      "placeholder": "Search by Name"
                    }
                  },
                  "header": "Custom Fields"
                },
                "header": "Settings",
                "index": {
                  "action": {
                    "save": "Save Settings"
                  },
                  "address_name_type": {
                    "option": {
                      "single_name": "Single Field",
                      "split_name": "Multiple Fields with Middle Name <br> <div style=\"margin-top: 5px; font-size: 0.85em;\">( First Name, Middle <u>Name</u>, Last Name )</div>\n",
                      "split_name_initial": "Multiple Fields with Middle Initial <br> <div style=\"margin-top: 5px; font-size: 0.85em;\">( First Name, Middle <u>Initial</u>, Last Name )</div>\n"
                    },
                    "placeholder": "Address Name Type"
                  },
                  "content": {
                    "address_name_entry": "Address Name Entry",
                    "address_name_entry_instructions": "<p>\n  Crowd Ox provides three settings here depending on how you'd like to collect name information. These options are:\n</p>\n<div class=\"ui message\">\n  <u>Default</u> Users will enter their shipping name information into a single field. For MOST shipping providers this is the best way to receive information.\n  Shipping providers don’t typically care what the shipping name consists of, as it could be a full name, company name, or alias. They just need a field the user fills out to address to them.\n</div>\n<p>\n  <b>Single Field:</b> Full Name\n</p>\n<div class=\"ui message\">\n  The following formats will force users to enter their information in multiple fields. For users who are entering a company name or alias, this format might not work the best for them.\n  If it recommended you only use this setting if you have a specific use for it, or your shipping provider requires it.\n</div>\n<p>\n  <b>Multiple Fields with Middle Name:</b> First Name, Middle <u>Name</u> <sup><i class=\"icon small info\" title=\"This will be optional\"></i></sup>, and Last Name.\n</p>\n<p>\n  <b>Multiple Fields with Middle Initial:</b> First Name, Middle <u>Initial</u> <sup><i class=\"icon small info\" title=\"This will be optional\"></i></sup>, and Last Name.\n</p>\n",
                    "admin_only": "Admin Only",
                    "forwarding_support_emails": "Forwarding Support Emails",
                    "forwarding_support_emails_details": "Your project is within the provided support window. All support emails will be forwarded to Crowd Ox.\n",
                    "launched_and_locked": "The project has launched surveys and the prelaunch settings below are now locked in.",
                    "on_off_settings": "On / Off Settings",
                    "prelaunch_settings": "Prelaunch Settings",
                    "prelaunch_warning": "This must be set before your project is live.",
                    "shipping_price_calculation": "Shipping Price Calculation",
                    "shipping_price_calculation_instructions": "<p>\n  Crowd Ox provides two settings here depending on how you would like to charge shipping. These options are:\n</p>\n<p>\n  <b>Shipping per Product (Default):</b> When Shipping per Product is selected, each product or configuration can have shipping charges associated with them.\n  Multiple products in a configuration or bundle, have a single shipping charge at the configuration level (individual products shipping costs under a configuration are ignored).\n  Any individual products, have shipping charges calculated for each product added.\n  Most projects use this setting as its easier to calculate a rough shipping cost per product.\n</p>\n<p>\n  <b>Shipping based on Total Weight:</b> When Shipping based on Total Weight is selected, each physical product can have a weight associated with it.\n  When the order is completed, the total weight (based on products and quantity) is summed up.\n  Then a single shipping table with breakdowns based on weight, calculate the total shipping cost.\n  If you know ahead of time the products weight and the costs to ship to each country based on weight, then you can use this method to calculate shipping.\n</p>\n",
                    "support_email_required": "Support Email Required",
                    "support_email_required_details": "<p>\n  The project support email is used to provide your customers with a way to contact your campaign with any product or shipping related inquiries.\n  Please enter it below so users have a way to get a hold of you for project specific questions.\n</p>\n<p>\n  For technical issues related to the survey, we provide the customers with a way to contact Crowd Ox directly.\n  Our support team will respond to their inquiry as quickly as possible.\n</p>\n"
                  },
                  "field": {
                    "country": "Country",
                    "currency": "Currency",
                    "currency_country": "Currency Country",
                    "currency_symbol": "Currency Symbol",
                    "facebook_pixel_id": "Facebook Pixel ID (Format: All Numbers)\n",
                    "google_analytics_id": "Google Analytics ID (Format: UA-########-#\")\n",
                    "homepage_url": "Website Url",
                    "identifier": "Identifier",
                    "image_src": "Thumbnail Image",
                    "locales": "Languages",
                    "slug": "Slug",
                    "support_days": "Support Days",
                    "support_email": "Support Email",
                    "survey_revision": "Survey Revision",
                    "twitter": "Twitter"
                  },
                  "header": "Project Settings",
                  "overview": "Overview",
                  "shipping_type": {
                    "option": {
                      "product_price": "Prices per Product",
                      "product_weight": "Prices on Total Weight"
                    },
                    "placeholder": "Shipping Type"
                  },
                  "title": "Settings",
                  "tooltip": {
                    "image_src": "This is the project thumbnail visible on manage."
                  },
                  "unit_of_length": {
                    "label": "Unit of Length (for Dimensions)",
                    "option": {
                      "cm": "Centimeters (cm)",
                      "in": "Inches (in)"
                    },
                    "placeholder": "Length Type"
                  },
                  "unit_of_mass": {
                    "label": "Unit of Mass (for Weight)",
                    "option": {
                      "kg": "Kilograms (kg)",
                      "lb": "Pounds (lb)",
                      "oz": "Ounces (oz)"
                    },
                    "placeholder": "Weight Type"
                  }
                },
                "payments": {
                  "header": "Payment Providers",
                  "overview": "Overview",
                  "title": "Payment Providers"
                },
                "tags": {
                  "index": {
                    "actions": {
                      "add": "Add Tag"
                    },
                    "content": {
                      "edit_tag": {
                        "tooltip": "Edit tag"
                      },
                      "none": {
                        "header": "No tags found",
                        "sub-header": "To create one, click the <em>Add Tag</em> button."
                      }
                    },
                    "header": "Tags",
                    "overview": "Tags",
                    "title": "Tags"
                  }
                }
              },
              "shipping": {
                "customs": {
                  "header": "Customs Duty"
                },
                "header": "Shipping & Taxes",
                "index": {
                  "action": {
                    "check_it_out": "Check it out"
                  },
                  "content": {
                    "customs": "Customs Duty",
                    "customs_instructions": "Set up customs duty calculations based on location and total product costs. The total product cost will be multiplied by a percentage amount and show up as a separate line item. Shipping or other taxes aren't included in the calculation.",
                    "local_pickup": "Local Pickup",
                    "local_pickup_instructions": "If you add addresses for Local Pickup, customers will have option of picking up their items from a specific address location. Shipping will not be charged if the customer selects to pickup their items for Local Pickup. Customers will still have the option of checking out through the normal means and having the items shipped to them.",
                    "tax": "Sales Tax",
                    "tax_instructions": "Set up sales tax calculations based on location and total product costs. The total product cost will be multiplied by a percentage amount and show up as a separate line item. Shipping or other taxes aren't included in this calculation.",
                    "vat": "Value Add Tax (VAT)",
                    "vat_instructions": "Set up VAT calculations based on location and total product costs. The total product cost will be multiplied by a percentage amount and show up as a separate line item. Shipping or other taxes aren't included in the calculation.",
                    "weight_based_shipping": "Weight Based Shipping",
                    "weight_based_shipping_instructions": "With weight based shipping, shipping is calculated based on the total weight of all products that a user has selected. This allows more accurate shipping based on actual weight of the customers order. Weight based shipping is a project wide setting, and applies to all configurations and orders."
                  },
                  "header": "Overview",
                  "title": "Shipping & Taxes"
                },
                "local-pickups": {
                  "create": {
                    "action": {
                      "save": "Create Local Pickup"
                    },
                    "header": "Create",
                    "header-expanded": "Create Local Pickup",
                    "title": "Create"
                  },
                  "edit": {
                    "action": {
                      "save": "Save Local Pickup"
                    },
                    "error-modal": {
                      "description": "The local pickup wasn't able to be saved."
                    },
                    "header": "Edit",
                    "header-expanded": "Edit: {name}",
                    "title": "Edit"
                  },
                  "header": "Local Pickups",
                  "index": {
                    "action": {
                      "add_local_pickup": "Add Local Pickup"
                    },
                    "content": {
                      "instructions": "Add local pickup locations for customers to select from. <br/> If a customer selects a local pickup, no shipping charges are collected.\n",
                      "no_local_pickups": {
                        "header": "No Local Pickups Addresses Found",
                        "instructions": "Adding local pickups allows customers to pickup all items locally instead of being shipped",
                        "item1": "The customers address is <b>not collected</b> when local pickup is selected",
                        "item2": "<b>No shipping</b> is charged on local pickup orders",
                        "item3": "Orders <b>cannot</b> be divided between local pickup and shipped",
                        "subhead": "Add a local pickup above to get started"
                      }
                    },
                    "header": "All Local Pickups"
                  },
                  "title": "Local Pickups"
                },
                "tax": {
                  "header": "Sales Tax"
                },
                "vat": {
                  "header": "Value Add Tax (VAT)"
                },
                "weight": {
                  "header": "Weight Based Shipping"
                }
              },
              "sources": {
                "caps": {
                  "action": {
                    "save": "Save Caps"
                  },
                  "error": {
                    "no_save": "The caps weren't able to be saved."
                  },
                  "header": "Caps",
                  "shipping_maximum_zones": {
                    "header": "Shipping Maximum Zones",
                    "price_text": "Max Extra Shipping",
                    "subhead": "These represent the most someone could pay for shipping on extras."
                  },
                  "title": "Caps"
                },
                "configurations": {
                  "create": {
                    "action": {
                      "save": "Create {reward}"
                    },
                    "header": "Create",
                    "title": "Create"
                  },
                  "edit": {
                    "caps": {
                      "content": {
                        "unable_to_apply": "Unable to apply caps, because custom extras aren't selected for this configuration."
                      },
                      "header": "Caps",
                      "title": "Caps"
                    },
                    "extras": {
                      "header": "Extras"
                    },
                    "header": "Edit",
                    "index": {
                      "action": {
                        "save": "Save {reward}"
                      },
                      "header": "Edit: {name}",
                      "title": "Edit"
                    }
                  },
                  "header": "{reward}",
                  "index": {
                    "actions": {
                      "import_orders": "Import Orders",
                      "new_configuration": "New {reward}",
                      "review_conf": "Review Configurations",
                      "update_conf_status": "Update Configuration Status"
                    },
                    "content": {
                      "any_status": "Any Status",
                      "authorize_source": "Authorize Source",
                      "bulk_actions": "Bulk Actions",
                      "change_source_name": "Change Source Name",
                      "edit_configuration": {
                        "tooltip": "Edit {reward}"
                      },
                      "import": "Import",
                      "none": {
                        "header": "No {reward} Found",
                        "sub_header": "Add {reward} above to get started."
                      },
                      "page_links": "Page Links",
                      "payment_confirmation": "Payment Confirmation",
                      "preorder_site": {
                        "complete": "Preview {order} Page",
                        "live": "Visit {order} Page",
                        "setup": "Preview {order} Page"
                      },
                      "preview_survey": "Preview {checkout}",
                      "quick_info": "Quick Info",
                      "settings": "Settings",
                      "start_preorders": "Start Taking {orders}",
                      "stop_preorders": "Stop Taking {orders}",
                      "uninstall_source": "Uninstall Source"
                    },
                    "error": {
                      "cannot_clone": "Unable to clone configuration."
                    },
                    "field": {
                      "category": "Category",
                      "end_date": "Funding End Date",
                      "location": "Location",
                      "order_count": "Order Count",
                      "start_date": "Funding Start Date",
                      "total_ordered": "Total Pledged"
                    },
                    "filter": {
                      "filter": "filter",
                      "search": "search",
                      "status": "status"
                    },
                    "header": "Configurations",
                    "status_option": {
                      "complete": {
                        "description": "By updating the configuration to 'Completed', the configuration will be closed. No customers will be allowed to complete their survey, but they will be able to review any completed information. Invite emails will no longer be sent automatically.\n",
                        "title": "Completed"
                      },
                      "live": {
                        "description": "By updating the configuration to 'Live', customers will be allowed to complete their survey and invite emails will begin to send automatically.\n",
                        "title": "Live"
                      },
                      "setup": {
                        "description": "By updating the configuration to 'In Setup', the survey configuration will be placed into the initial setup status. No customers are allowed to enter the survey or view their information.\n",
                        "title": "In Setup"
                      }
                    },
                    "title": "Configurations",
                    "tooltip": {
                      "start_preorders": "Click to find out more information about taking {orders}.",
                      "stop_preorders": "The page is live. Click to find out more information about stopping new {orders}."
                    }
                  },
                  "review": {
                    "content": {
                      "configurations_header": "Configurations",
                      "group_name": {
                        "project": "Project",
                        "project_configuration": "Project Configuration",
                        "project_source": "Project Source"
                      }
                    },
                    "header": "Launch Review"
                  },
                  "settings": {
                    "action": {
                      "save": "Save Settings"
                    },
                    "content": {
                      "attempt": "Attempt",
                      "attempts": "Attempts",
                      "days": "days",
                      "every": "Every",
                      "invite_and_reminders": "Invite and Reminders",
                      "max_attempts": "Max Attempts",
                      "none": "There are no settings available for this source.",
                      "payment_recovery": "Payment Recovery",
                      "pledge_confirmation": "Pledge Confirmation",
                      "preorder_recovery": "{order} Payment Recovery Emails"
                    },
                    "header": "Settings",
                    "title": "Settings"
                  },
                  "title": "{reward}"
                },
                "extras": {
                  "action": {
                    "save": "Save Extras"
                  },
                  "content": {
                    "instructions": "This page represents what extras will be mapped and available to purchase. It is set up similiar to the survey to give you an idea of layout and representation.\n"
                  },
                  "error": {
                    "no_save": "The extras weren't able to be saved."
                  },
                  "header": "Extras",
                  "title": "Extras"
                },
                "header": "Sources",
                "import": {
                  "title": "Source Import"
                },
                "story": {
                  "action": {
                    "save": "Save Story"
                  },
                  "content": {
                    "desktop_preview": "Desktop Preview",
                    "full_width": "Full Width (Editing)",
                    "instructions": "Craft your own story and information for {orders}. This will help describe to anyone that visits your page what you are offering and details on delivery. Creating a story for {orders} is optional. If you decide not to add a story, just a list of available {rewards} will be shown.\n",
                    "mobile_preview": "Mobile Preview"
                  },
                  "header": "Story",
                  "placeholder": {
                    "story": "Craft your story here"
                  },
                  "title": "{reward} Story"
                },
                "theme": {
                  "action": {
                    "apply_default_theme": "Apply Default Theme",
                    "copy_survey_theme": "Copy {checkout} Theme",
                    "save_theme": "Save Theme"
                  },
                  "clear_image": "Clear Image",
                  "content": {
                    "desktop_preview": "Desktop Preview",
                    "instructions": "This page allows you to adjust and preview the {order} theme that your potential {customers} will see.\n",
                    "mobile_preview": "Mobile Preview",
                    "tablet_preview": "Tablet Preview",
                    "view_on": "View On {name}"
                  },
                  "custom_css_label": "Custom CSS - Only renders on {order} Page",
                  "header": "Theme",
                  "social_sharing_label": "Social Sharing Image",
                  "title": "{reward} Theme Editor"
                }
              },
              "sources/import": {
                "action": {
                  "install": "Install"
                },
                "header": "Add Source"
              },
              "sources/pre-order": {
                "do-setup": {
                  "actions": {
                    "save_and_continue": "Save & Continue"
                  },
                  "content": {
                    "clone_from": "Clone global extras from {source}",
                    "configuration_instructions": "Select the {rewards} from your {source} page you want to clone and make available on your {crowdox} page.\n",
                    "configurations": "{reward}",
                    "extras": "Extras",
                    "extras_instructions": "Select an option below, to bring over existing mapped extras or not for your {order} page.",
                    "no_configurations": "No Configurations Found",
                    "start_fresh": "Start fresh with no extras configured"
                  },
                  "fields": {
                    "mapped_products": "Products",
                    "name": "{reward} Name",
                    "price": "Price",
                    "total_orders": "Orders"
                  },
                  "header": "Clone from {source}"
                },
                "error": {
                  "configuration_error": "The {crowdox} source was created successfully, but one or more configurations received an error while being cloned.",
                  "create_error": "There was an error while trying to create the {crowdox} source.",
                  "pre_order_setup": "There was an unknown error while trying to setup {crowdox} configuration."
                },
                "get-started": {
                  "actions": {
                    "get_started": "Get Started",
                    "starting": "Starting"
                  },
                  "content": {
                    "clone_from": "Clone {reward} and extras from",
                    "description": "Don’t lose your momentum! Continue raising money by activating your {source} page. You’ll set up products, rewards, and extras, just like you’ve done before.\n",
                    "preview_alt": "{crowdox} Preview"
                  },
                  "header": "Activate Your <br>{source} Page",
                  "placeholder": {
                    "select_source": "Select Source"
                  }
                }
              },
              "stats": {
                "header": "Stats",
                "index": {
                  "content": {
                    "crowdox_total_transactions": {
                      "estimated_tooltip": "Once your survey launches, additional revenue raised in Crowd Ox will show here.",
                      "label": "From {crowdox}",
                      "tooltip": "This is the combined total of all charges made through Crowd Ox including extras, upgrades, and shipping."
                    },
                    "external_difference": "Any differences between what this number shows and {source} is because of refunded, failed, or pending transactions. Crowd Ox totals are based on all current confirmed transactions.",
                    "location": {
                      "estimated": "Estimated",
                      "header": "Location"
                    },
                    "no_data": "No data present",
                    "order_activity": {
                      "empty_notice": "When the project has been launched, the daily completion statistics will be displayed here.",
                      "header": "Order Activity",
                      "to_import": "To Import"
                    },
                    "stats_shown_no_order_data": "Stats shown above are based on public data until the order data is imported",
                    "total_orders": {
                      "estimated_tooltip": "Once your campaign data imports, the total order count will show here.",
                      "label": "Total Orders",
                      "tooltip": "This is the total count of all non canceled orders in Crowd Ox."
                    },
                    "total_raised": {
                      "estimated_tooltip": "This is updated once a day based on the campaigns external statistics.",
                      "label": "Total Raised",
                      "tooltip": "This is the total raised based on all orders and their confirmed transactions within Crowd Ox."
                    },
                    "updated_every": "Updated Every 10 Minutes"
                  },
                  "header": "Overview",
                  "title": "Overview",
                  "tooltip": {
                    "total_orders_confirmed": "Number is based on all completed orders. Percent is only calculated based on completed external orders",
                    "total_orders_incomplete": "Number and percent are based on all orders not completed",
                    "total_orders_not_invited": "Number and percent are based on all orders not invited",
                    "total_orders_to_import": "The estimated number of orders to import based on external statistics"
                  }
                },
                "products": {
                  "completed": {
                    "field": {
                      "complete_quantity": "Total",
                      "extra_quantity": "Extra",
                      "product_name": "Product Name",
                      "product_variation_stat": "Product Variation Stat",
                      "sku": "Sku"
                    },
                    "header": "Completed Product Stats",
                    "link": {
                      "view_projected_stats": "View Projected Stats"
                    },
                    "no_stats": "No product stats exist.",
                    "product_counts_disclaimer": "Product counts are only for completed surveys.",
                    "stats_shown_no_order_data": "Stats shown above are based on public data until the order data is imported",
                    "title": "Product Stats",
                    "type": {
                      "option": {
                        "product_sku_stat": "Grouped by Sku",
                        "product_stat": "Grouped by Product",
                        "product_variation_stat": "Grouped by Product Variation"
                      }
                    },
                    "updated_every": "Updated Every 10 Minutes"
                  },
                  "projected": {
                    "description": "<em>This includes incomplete surveys.</em> If you use product choices the count shows chosing every option which will artificially inflate the total.\n",
                    "field": {
                      "complete_quantity": "Completed",
                      "incomplete_quantity": "Pending",
                      "product_name": "Product Name",
                      "product_variation": "Product Variation",
                      "sku": "Sku",
                      "total_quantity": "Total"
                    },
                    "header": "Projected Product Stats",
                    "link": {
                      "view_completed_stats": "View Completed Stats"
                    },
                    "no_stats": "No product stats exist.",
                    "product_counts_disclaimer": "Product counts are only for incomplete and completed surveys.",
                    "title": "Product Stats",
                    "type": {
                      "option": {
                        "estimated_product_sku_stat": "Grouped by Sku",
                        "estimated_product_stat": "Grouped by Product",
                        "estimated_product_variation_stat": "Grouped by Product Variation"
                      }
                    },
                    "updated_every": "Updated Every 10 Minutes"
                  }
                },
                "title": "Stats",
                "transactions": {
                  "by-order": {
                    "description": "View and export a list of all transactions grouped by order.",
                    "header": "Transactions by Order",
                    "title": "By Order"
                  },
                  "by-order-and-date": {
                    "description": "View and export a list of all transactions grouped by order and date.",
                    "header": "Transactions by Order and Date",
                    "title": "By Order and Date"
                  },
                  "by-order-and-provider": {
                    "description": "View and export a list of all transactions grouped by order and provider.",
                    "header": "Transactions by Order and Provider",
                    "title": "By Order and Provider"
                  },
                  "index": {
                    "download_report": "Download Report",
                    "header": "Transactions",
                    "title": "Transactions",
                    "view_report": "View Report"
                  }
                }
              },
              "theme": {
                "header": null,
                "index": {
                  "admin_only_header": "Admin Only",
                  "apply_default_theme": "Apply Default Theme",
                  "custom_css_label": "Custom CSS - Only renders on {checkout}",
                  "custom_js_label": "Custom JS - Only renders on {checkout}",
                  "header": "{checkout} Theme Editor",
                  "preview": {
                    "desktop": "Desktop Preview",
                    "mobile": "Mobile Preview",
                    "tablet": "Tablet Preview"
                  },
                  "save_theme_button": "Save Theme",
                  "sub_header": "This page allows you to adjust and preview the {checkout} theme that your customers will see. The options below apply to all of {checkout}, but you can toggle different views the customer will see.\n",
                  "title": "Theme",
                  "tooltip": {
                    "default_navigation_divider_color": "Update the navigation step divider color",
                    "navigation_active_background_color": "Update the navigation active step background color",
                    "navigation_inactive_background_color": "Update the navigation incomplete step background color",
                    "navigation_previous_background_color": "Update the navigation completed step background color",
                    "navigation_text_color": "Update the navigation text color",
                    "navigation_text_completed_color": "Update the navigation completed text color",
                    "navigation_text_inactive_color": "Update the navigation incomplete text color"
                  }
                },
                "messages": {
                  "edit": {
                    "address": {
                      "address": "Address",
                      "address_description": null,
                      "address_missing": "Missing Information",
                      "address_missing_description": null,
                      "address_override": "Override",
                      "address_override_description": null,
                      "city": "City",
                      "city_description": null,
                      "company_name": "Company Name",
                      "company_name_description": null,
                      "country": "Country",
                      "country_completed": "Country (completed)",
                      "country_completed_description": null,
                      "country_description": null,
                      "country_limited": "Country (limited)",
                      "country_limited_description": null,
                      "country_locked": "Country (locked)",
                      "country_locked_description": null,
                      "first_name": "First Name",
                      "first_name_description": null,
                      "full_name": "Full Name",
                      "full_name_description": null,
                      "header": "Header",
                      "header_description": "Shown at the top of the screen and the title of the browser.",
                      "info_message": "Informational Message",
                      "info_message_description": "Shows up underneath the header and subheader",
                      "last_name": "Last Name",
                      "last_name_description": null,
                      "middle_name": "Middle Name (or Initial)",
                      "middle_name_description": null,
                      "minimum_message": "Minimum Fields",
                      "minimum_message_description": null,
                      "navigation": "Navigation",
                      "navigation_description": "Name as shown in the navigation",
                      "phone_number": "Phone Number",
                      "phone_number_description": null,
                      "phone_number_missing": "Phone Number (missing)",
                      "phone_number_missing_description": null,
                      "phone_number_override": "Phone Number (override)",
                      "phone_number_override_description": null,
                      "phone_number_required": "Phone Number (required)",
                      "phone_number_required_description": null,
                      "postal_code": "Postal Code",
                      "postal_code_description": null,
                      "save_action": "Save Action",
                      "save_action_description": null,
                      "save_message": "Save Message",
                      "save_message_description": "This message is shown at the end of the page, right before the save button",
                      "state": "State",
                      "state_description": null,
                      "sub_header": "Sub Header",
                      "sub_header_description": "Shown below the header and navigation but before any content"
                    },
                    "confirm": {
                      "excess_funds": "Overpaid message",
                      "excess_funds_description": "If the customer has overpaid this text will be shown on their confirmation and review page.",
                      "header": "Header",
                      "header_description": "Shown at the top of the screen and the title of the browser.",
                      "info_message": "Informational Message",
                      "info_message_description": "Shows up underneath the header and subheader",
                      "navigation": "Navigation",
                      "navigation_description": "Name as shown in the navigation",
                      "save_action": "Save Action",
                      "save_action_description": null,
                      "save_message": "Save Message",
                      "save_message_description": "This message is shown at the end of the page, right before the pay button",
                      "sub_header": "Sub Header",
                      "sub_header_description": null
                    },
                    "extras": {
                      "add_product": "Add Product",
                      "add_product_description": "The text for the \"Add Product\" button",
                      "customer_quantity_left": "Quantity Left",
                      "customer_quantity_left_description": null,
                      "customer_quantity_max": "Max Quantity",
                      "customer_quantity_max_description": null,
                      "excess_funds": "Excess Funds",
                      "excess_funds_description": "Text that shows if the customer has excess funds",
                      "header": "Header",
                      "header_description": "Shown at the top of the screen and the title of the browser.",
                      "info_message": "Informational Message",
                      "info_message_description": "Shows up underneath the header and subheader",
                      "maximum_allowed": "Maximum Allowed",
                      "maximum_allowed_description": "The text that is shown when a customer has the max allowed",
                      "navigation": "Navigation",
                      "navigation_description": "Name as shown in the navigation",
                      "next_action": "Save Action",
                      "next_action_description": "Button used to save and move forward",
                      "no_extras": "No Extras",
                      "no_extras_description": "This shows at the top to give the customer a way to skip this page",
                      "reward_selections": "Reward Selections",
                      "reward_selections_description": null,
                      "save_action": "Save Action",
                      "save_action_description": null,
                      "save_message": "Save Message",
                      "save_message_description": "This message is shown at the end of the page, right before the save button",
                      "sold_out": "Sold Out",
                      "sold_out_description": "The text that is shown when a product is sold out",
                      "sub_header": "Sub Header",
                      "sub_header_description": "Shown below the header and navigation but before any content",
                      "total_quantity_left": "Total Quantity Left",
                      "total_quantity_left_description": "The text shown when there a limited quantity of an item",
                      "view_below": "View Below",
                      "view_below_description": null
                    },
                    "header": "{name} Messages Editor",
                    "refuse": {
                      "info_message": "Informational Message",
                      "info_message_description": "Shows up underneath the header and subheader"
                    },
                    "review": {
                      "header": "Header",
                      "header_description": "Shown at the top of the screen and the title of the browser.",
                      "info_message": "Informational Message",
                      "info_message_description": "Shows up underneath the header and subheader",
                      "navigation": "Navigation",
                      "navigation_description": "Name as shown in the navigation"
                    },
                    "rewards": {
                      "additional_funds": "Additional Funds",
                      "additional_funds_description": "Shown when the order has additional funds to spend",
                      "all_configurations_locked": "All Configuration is Locked",
                      "all_configurations_locked_description": "This is shown when all of the selections in their reward have been marked as locked",
                      "any_configuration_locked": "Any Configuration Locked",
                      "any_configuration_locked_description": "This is shown when some of the selections in their reward have been marked as locked",
                      "choices": "Selections & Choices",
                      "choices_description": "After the reward bundle has been expanded out AND if they have choices to make this is their selection header",
                      "choose_action": "Choose Action",
                      "choose_action_description": "Button used to confirm selections",
                      "estimated_delivery": "Estimated Delivery",
                      "estimated_delivery_description": "When you have a estimated delivery date on the configuration this will be shown",
                      "excess_funds": "Excess Funds",
                      "excess_funds_description": "This text shows at the bottom of the selected configuration",
                      "header": "Header",
                      "header_description": "Shown at the top of the screen and the title of the browser.",
                      "info_message": "Informational Message",
                      "info_message_description": "Shows up underneath the header and subheader",
                      "manual_choices": "Manual Choices",
                      "manual_choices_description": "When the backer has manual selections that were added this is the header that is s hown",
                      "navigation": "Navigation",
                      "navigation_description": "Name as shown in the navigation",
                      "original_configuration": "Pledge",
                      "original_configuration_description": "Indicates where the pledge came from",
                      "other_choices": "Other Choices",
                      "other_choices_description": "When the backer has other selections that were imported from an external source this is the header that is shown",
                      "save_action": "Save Action",
                      "save_action_description": "Button used to save and move forward",
                      "save_message": "Save Message",
                      "save_message_description": "This message is shown at the end of the page, right before the save button",
                      "select_configuration": "Select Configuration",
                      "select_configuration_description": "Button text when you are upgrading to different configurations",
                      "selection_includes": "Selection Includes",
                      "selection_includes_description": "Text above the products within the orders configuration bundle",
                      "selection_none": "Selection None",
                      "selection_none_description": "Text above the configuration bundle when there are no products",
                      "sub_header": "Sub Header",
                      "sub_header_description": "Shown below the header and navigation but before any content",
                      "upgrade_configuration": "Upgrade text",
                      "upgrade_configuration_description": "Shown after the main reward as an introduction to upgrade options",
                      "upgraded_configuration": "Upgraded text",
                      "upgraded_configuration_description": "Shown once you've selected a new upgrade option",
                      "your_info": "Order Information",
                      "your_info_description": "This label shows above the country and other required information on the rewards page"
                    },
                    "save_messages_button": "Save Messages",
                    "summary": {
                      "balance": "Balance",
                      "balance_description": null,
                      "bundle": "Bundle",
                      "bundle_description": null,
                      "external": "External",
                      "external_description": null,
                      "extras": "Extras",
                      "extras_description": null,
                      "manual": "Manual",
                      "manual_description": null
                    },
                    "title": "Messages"
                  }
                },
                "messages_link": "Messages",
                "theme_link": "Theme"
              },
              "welcome": {
                "authorization": {
                  "indiegogo": {
                    "authorized_successful": "You have successfully authorized!",
                    "collaborator_tooltip": "We recommend you connect Crowd Ox to your project so that we can start to sync your pledge information. If you would like to skip this step for now you can, but we'll need it before you can launch your project.\n",
                    "connect_button": "Connect to Indiegogo",
                    "connect_instructions": "Enter your Indiegogo login information. We do not save these credentials. They are sent to Indiegogo in order to authorize this application.\n",
                    "errors_header": "We received the following errors while trying to save your information",
                    "errors_not_provided": "Oh no! We were unable to save your changes. Wait a moment, and then try saving again.",
                    "title": "Indiegogo"
                  },
                  "kickstarter": {
                    "content": {
                      "instructions": {
                        "conclusion": "Once this invitation is complete, click the button below to continue setting up your project.",
                        "intro": "Please invite {crowdox} to be a collaborator to your Kickstarter project by taking the following steps",
                        "step1": "Go to your project's collaborators page, or click this link to take you there",
                        "step2-a": "Enter the Email Address",
                        "step2-b": "in the email text box and check the <b>Fulfillment</b> permission",
                        "step3": "Then click <b>Send Invitation</b>"
                      },
                      "invited_as_collaborator": "I've invited Crowd Ox as a collaborator"
                    },
                    "title": "Kickstarter",
                    "tooltip": {
                      "collaborator": "We recommend you invite Crowd Ox to your project so that we can start to sync your backer information. If you would like to skip this step for now you can, but we'll need it before you can launch your project.\n",
                      "copy_email": "Copy email address"
                    }
                  }
                },
                "connect_to": "Connect to",
                "error": {
                  "unable_to_save": "Unable to save project settings.",
                  "unknown_authorization": "An unknown authorization error occurred. Please check username and password"
                },
                "header": "Welcome to Crowd Ox",
                "index": {
                  "title": "Welcome"
                },
                "location": {
                  "content": {
                    "instructions": "<p>For tax purposes, we need to know where your company is physically located.</p> <p><small>If you are lucky enough to be a multi-national company, congratulations! In that event, choose the single country that primarily defines your company's location.</small></p>\n"
                  },
                  "header": "Primary Physical Location",
                  "title": "Location"
                },
                "settings": {
                  "content": {
                    "instructions": "You're almost there! To better support your project, we need a few additional details. The only piece of information that's required is a support email for when your customers have product questions. If you would like to, you can also enter a Twitter username and / or website URL to display on the survey.\n",
                    "instructions_later": "If you'd like to update this information below at a later time, you'll be able to update it in Project Settings.",
                    "optional": "Optional"
                  },
                  "field": {
                    "email": "Support Email",
                    "homepage_url": "Website URL",
                    "twitter": "Twitter Username"
                  },
                  "header": "Project Settings",
                  "title": "Settings",
                  "tooltip": {
                    "skip_for_now": "If you don't have a support email setup yet, you can skip it for now, but you'll need one before your project can launch."
                  }
                },
                "skip_for_now": "Skip for now",
                "title": "Welcome"
              }
            },
            "project/not-found": {
              "header": "Oh no, we are unable to find the project you are looking for.",
              "sub_header": "If you feel that this is an error, please contact us at <a href=\"mailto:hello@crowdox.com\">hello@crowdox.com</a>"
            },
            "status": {
              "header": "Project Fulfillment Statuses"
            }
          }
        }
      }
    },
    "shipping_configuration": {
      "copy": "Copy Shipping",
      "goto_weight_shipping": "Go to Weight Shipping",
      "locations": "Locations",
      "weight_based_read_only": "<b>Please Note:</b> Weight based shipping is currently enabled. All of the <u>prices</u> below are for <b>reference only</b> and <b>are not</b> used in calculating costs. If you've selected to ship to <em>Specific Countries</em>, those countries will still be restricted when completing the survey.",
      "zones": "Zones"
    },
    "shipping_override": {
      "calculation": "Shipping Calculation",
      "is_price_tooltip": "The shipping for the project is set to weight based, and this product is currently set to product based",
      "is_weight_tooltip": "The shipping for the project is set to weight based, but you can override this specific product to be product based shipping",
      "price_based": "Price Based",
      "set_price_based": "Set shipping to product based",
      "set_weight_based": "Set shipping to weight based",
      "weight_based": "Weight Based"
    },
    "source": {
      "authorization_for": "The authorization for",
      "authorization_needed": "{source} Authorization Needed",
      "authorization_needed_description": "The authorization for {source} is not connected. Without this authorization we won't be able to pull in {reward}, {order}, and {customer} information. Please follow these directions to connect your project.",
      "from": "from {source}",
      "is_not_connected": "is not connected.",
      "settings": "{source} Settings",
      "sync": "Sync {source}",
      "view": "View {source} Project"
    },
    "tracking_parameter": {
      "parameter": {
        "sub_type": {
          "named_source": {
            "title": "Named Source",
            "tooltip": "A specific named query parameter identifying a source"
          },
          "query_parameter": {
            "title": "Query Parameter",
            "tooltip": "Any query parameter included on the link to the {source} purchase page"
          },
          "referrer": {
            "title": "Referring Website",
            "tooltip": "The website that referrered the user to the {source} purchase page"
          },
          "title": "Parameter Type"
        },
        "value": {
          "title": "Parameter Name"
        }
      },
      "parameter_value": {
        "value": {
          "title": "Parameter Value"
        }
      }
    }
  };
  _exports.default = _default;
});