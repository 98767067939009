define("manage/pods/companies/company/projects/project/orders/view/components/add-transaction-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let transaction = this.get('store').createRecord('order-transaction', {
        provider: 'Manual',
        project: this.get('order.project'),
        order: this.get('order'),
        amount_cents: 0,
        currency: this.get('order.project.currency'),
        shipping_amount_cents: 0,
        is_refund: false,
        confirmed: true
      });
      this.set('transaction', transaction);
    },

    closing() {
      if (this.get('transaction.hasDirtyAttributes')) {
        this.get('transaction').deleteRecord();
      }
    },

    actions: {
      approve() {
        this.set('submitted', true);
        this.set('transaction.is_refund', this.get('transaction.amount') < 0);
        let result = this.get('transaction').validateSync();

        if (result.validations.get('isInvalid')) {
          return;
        }

        this.get('transaction').save().then(() => {
          this.closeModal();
        }).catch(function (response) {
          alert(response);
        });
      }

    }
  });

  _exports.default = _default;
});