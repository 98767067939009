define("manage/pods/companies/company/projects/project/orders/components/status-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['component', 'status-text', 'status'],
    classNameBindings: ['names'],
    names: Ember.computed('order.status', function () {
      return (this.get('order.status') || '').toLowerCase();
    })
  });

  _exports.default = _default;
});