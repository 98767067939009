define("manage/utils/dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addDays = addDays;

  function addDays(date, days) {
    return new Date(date.valueOf() + days * 24 * 60 * 60 * 1000);
  }
});