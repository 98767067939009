define("manage/pods/companies/company/projects/project/sources/configurations/index/route", ["exports", "manage/pods/companies/company/projects/project/sources/configurations/index/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let source = this.modelFor('companies.company.projects.project.sources');
      return Ember.RSVP.hash({
        project: project,
        source: source,
        configurations: this.search(source, params)
      }).then(_ref => {
        let {
          project,
          source,
          configurations
        } = _ref;
        let data = {
          project,
          source,
          configurations
        };

        if (configurations.get('length') === 0) {
          return data;
        }

        data.stats = this.store.query('project-configuration-stat', {
          filter: {
            id: configurations.mapBy("id")
          },
          page: {
            size: configurations.get('length')
          }
        });
        return Ember.RSVP.hash(data);
      });
    },

    search(source, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let sortProperty = params.sort;
      let sortDirection = params.sortDirection;
      let status = params.status;
      let search = params.search;
      let sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      if (Ember.isBlank(status)) {
        status = ['setup', 'live', 'complete'];
      }

      return this.store.query('project-configuration', {
        filter: {
          source: source.get('id'),
          status: status,
          search: search
        },
        include: ['product-reward', 'product-reward.zones', // Used for the preview survey modal
        'product-reward.zones.locations'].join(','),
        page: {
          number: page,
          size: pageSize
        },
        sort: sort
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('selected_configurations', Ember.A());
      }
    }

  });

  _exports.default = _default;
});