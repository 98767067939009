define("manage/pods/admin/projects/status/route", ["exports", "manage/mixins/restrict-by-session"], function (_exports, _restrictBySession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictBySession.default, {
    queryParams: {
      launched: {
        refreshModel: true
      },
      launched_year: {
        refreshModel: true
      },
      fulfillment_status: {
        refreshModel: true
      },
      refreshed_at: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    model(params) {
      return this.search(params);
    },

    search(params) {
      var controller = this.controllerFor(this.routeName);
      var launched = params.launched;
      var launched_year = params.launched_year;
      var fulfillment_status = params.fulfillment_status;
      var sortProperty = params.sort;
      var sortDirection = params.sortDirection;
      var page = params.page;
      var pageSize = controller.get('page_size');
      var sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      return this.store.query('project', {
        filter: {
          launched: launched,
          launched_year: launched_year,
          fulfillment_status: fulfillment_status
        },
        include: ['company', 'sources'].join(','),
        sort: sort,
        page: {
          number: page,
          size: pageSize
        }
      });
    }

  });

  _exports.default = _default;
});