define("manage/pods/companies/company/projects/project/segments/components/change-configuration-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action", "manage/mixins/filters/configurations", "ember-cp-validations"], function (_exports, _ssModal, _segmentAction, _configurations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    configuration: {
      description: "Configuration",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    change_original_configuration: {
      description: "Change Original Configuration",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, Validations, _configurations.default, {
    classNames: ['change-configuration-modal', 'segment-modal', 'component'],
    current_user: Ember.inject.service('user'),
    closable: false,
    type: 'change-configuration',
    submitted: false,
    configuration: null,
    change_original_configuration: false,
    actions: {
      perform() {
        this.set('submitted', true);
        let result = this.validateSync();

        if (result.validations.get('isInvalid')) {
          return;
        }

        if (this.get('actionTask.isRunning')) {
          return;
        }

        this.get('actionTask').perform({
          configuration_id: this.get('configuration.id'),
          change_original_configuration: this.get('change_original_configuration')
        });
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});