define("manage/pods/user/signup/controller", ["exports", "manage/config/environment", "ember-cp-validations", "ember-concurrency"], function (_exports, _environment, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    accept_agreements: {
      description: "I accept",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        message: "{description} must be checked",
        in: [true]
      })]
    }
  });

  var _default = Ember.Controller.extend(Validations, {
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    ajax: Ember.inject.service(),
    passwordStrength: Ember.inject.service(),
    user: Ember.computed.readOnly('model.user'),
    account: Ember.computed.readOnly('model.account'),
    account_submitted: false,
    account_status: 'UNKNOWN',
    submitted: false,
    error: null,
    accept_agreements: false,
    isRunning: Ember.computed.or('availableTask.isRunning', 'signupTask.isRunning', 'authenticateTask.isRunning'),
    filledIn: Ember.computed('account.{username,password,password_confirmation}', 'user.full_name', 'error', function () {
      return Ember.isPresent(this.get('account.username')) && Ember.isPresent(this.get('account.password')) && Ember.isPresent(this.get('account.password_confirmation')) && Ember.isPresent(this.get('user.full_name')) && Ember.isBlank(this.get('error'));
    }),
    textChanged: Ember.observer('account.{username,password,password_confirmation}', function () {
      this.set('error', null);
    }),
    emailChanged: Ember.observer('account.username', function () {
      this.set('account_status', 'UNKNOWN');
      this.set('account.password', null);
      this.set('account.password_confirmation', null);
    }),
    strength: Ember.computed('account.password', function () {
      const passwordStrength = this.get('passwordStrength');
      return passwordStrength.strengthSync(this.get('account.password') || '');
    }),
    passwordPercent: Ember.computed('strength', function () {
      return parseFloat(this.get('strength.score')) / 2.0 * 100;
    }),
    processAccountErrors: function (account, errors) {
      let accountErrors = account.get('errors');

      for (let index = 0; index < errors.length; index++) {
        const error = errors[index];

        switch (error.field) {
          case 'username':
            switch (error.message) {
              case 'TAKEN':
                this.set('account_status', error.message);
                break;

              default:
                accountErrors.add("username", [error.message]);
                break;
            }

            break;

          case 'password':
            switch (error.message) {
              case 'INSECURE':
                accountErrors.add("password", [[this.get('strength.feedback.warning')].concat(this.get('strength.feedback.suggestions')).join(". ")]);
                break;

              case 'MISSING':
                accountErrors.add("password", ["You must have a password"]);
                break;

              default:
                accountErrors.add("password", [error.message]);
                break;
            }

            break;

          default:
            accountErrors.add(error.field, [error.message]);
            break;
        }
      }
    },
    availableTask: (0, _emberConcurrency.task)(function* () {
      this.set('account_submitted', true);
      let account = yield this.get('account');
      let result = yield account.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let username = account.get('username');

      try {
        let response = yield this.get('ajax').request(`${_environment.default.urls.auth}/accounts/available`, {
          data: {
            username: username
          }
        });

        if (response.result) {
          this.set('account_status', 'AVAILABLE');
        }
      } catch (xhr) {
        this.processAccountErrors(account, xhr.payload.errors);
      }
    }),
    signupTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let account = yield this.get('account');
      result = yield account.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let {
        username,
        password,
        password_confirmation
      } = account.getProperties('username', 'password', 'password_confirmation');
      let identification = (username || "").toLowerCase().trim(); // clean email

      if (password != password_confirmation) {
        account.get('errors').add('password_confirmation', ['Password does not match']);
        return;
      }

      try {
        yield this.get('ajax').post(`${_environment.default.urls.auth}/accounts`, {
          data: {
            username: identification,
            password: password
          }
        });
      } catch (xhr) {
        this.processAccountErrors(account, xhr.payload.errors);
        return;
      }

      yield this.get('authenticateTask').perform();
    }),
    authenticateTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      let account = yield this.get('account');
      let result = yield account.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      let {
        username,
        password
      } = account.getProperties('username', 'password');
      let identification = (username || "").toLowerCase().trim(); // clean email
      // Validate the credentials

      let session = yield this.get('ajax').post(`${_environment.default.urls.auth}/sessions`, {
        data: {
          username: identification,
          password: password
        }
      });

      try {
        yield this.get('ajax').request(`${_environment.default.urls.api}/session/me`, {
          headers: {
            'Authorization': `Bearer ${session.result.id_token}`
          }
        });
      } catch (_error) {
        let full_name = this.get('user.full_name');

        if (Ember.isPresent(full_name)) {
          yield this.get('ajax').post(`${_environment.default.urls.api}/users`, {
            data: {
              user: {
                full_name: this.get('user.full_name') || this.get('account.username'),
                email: identification
              }
            },
            headers: {
              Authorization: `Bearer ${session.result.id_token}`
            }
          });
        } else {
          // We need to get it.
          this.set('account_status', 'CREATED');
          this.set('submitted', false);
          return;
        }
      }

      yield this.get('session').authenticate('authenticator:custom', identification, password); // Then reload the application model along with the current user
      // We're unable to refresh the application route since transitions
      // don't work after that.

      const currentOwner = Ember.getOwner(this);
      const applicationRoute = currentOwner.lookup('route:application');
      this.store.unloadAll();
      yield Ember.RSVP.all([applicationRoute.model(), this.get('userService').load()]);
      this.transitionToRoute('user.activate');
    }),
    actions: {
      signup() {
        let task = null;

        if (this.get('account_status') === 'AVAILABLE') {
          task = 'signupTask';
        } else if (['TAKEN', 'CREATED'].indexOf(this.get('account_status')) >= 0) {
          task = 'authenticateTask';
        } else {
          task = 'availableTask';
        }

        if (this.get(`${task}.isRunning`)) {
          return;
        }

        this.get(task).perform().catch(() => {
          this.set('error', true);
          this.set('errorMessage', "Unable to sign up.");
        });
      }

    }
  });

  _exports.default = _default;
});