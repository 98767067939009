define("manage/components/input-delay/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    debounce_wait: 500,
    fire_at_start: false,
    router: Ember.inject.service(),

    keyUp(event) {
      this._super(...arguments);

      if (event.keyCode === 13) {
        // Return, the value will update in debounce
        // We need the value to update the query params to work properly
        if (this.get('value') !== this.readDOMAttr('value')) {
          return;
        }

        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      }
    },

    _elementValueDidChange: function () {
      Ember.run.debounce(this, this._setValue, this.debounce_wait, this.fire_at_start);
    },
    _setValue: function () {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.set('value', this.readDOMAttr('value'));

      if (this.get('onChange')) {
        this.get('onChange')();
      }
    }
  });

  _exports.default = _default;
});