define("manage/pods/companies/company/projects/project/segments/components/load-segment-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['load-segment-modal', 'component'],
    closable: false,
    project: null,
    behavior: null,
    store: Ember.inject.service(),
    isRunning: Ember.computed.alias('loadTask.isRunning'),
    page: 1,
    page_size: 5,
    search: '',

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      let store = this.get('store');
      let filter = {
        project: this.get('project.id'),
        behavior: this.get('behavior'),
        hidden: false
      };

      if (Ember.isPresent(this.get('search'))) {
        filter.search = this.get('search');
      }

      let results = yield store.query('project-segment', {
        filter: filter,
        page: {
          number: this.get('page'),
          size: this.get('page_size')
        },
        sort: '-created_at'
      });
      this.set('segments', results);
    }).drop(),
    actions: {
      search() {
        this.set('page', 1);
        this.get('loadTask').perform();
      },

      change_page(number) {
        this.set('page', number);
        this.get('loadTask').perform();
      },

      perform(project_segment) {
        if (this.get('loaded')) {
          this.get('loaded')(project_segment);
        }

        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});