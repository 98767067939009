define("manage/pods/companies/company/projects/project/products/choices/edit/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['type'],
    product: Ember.computed.readOnly('model.product'),
    project: Ember.computed.readOnly('model.project'),
    all_products: Ember.computed.readOnly('model.all_products'),
    productTasks: Ember.inject.service(),
    // Come back to this when everything else is complete
    submitted: false,
    actions: {
      save() {
        if (this.get('productTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);

        if (this.get('product.validations.isInvalid')) {
          return;
        }

        this.get('productTasks.save').perform(this.get('product')).then(() => this.transitionToRoute('companies.company.projects.project.products.index')).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "The product option wasn't able to be saved."
          });
        }));
      },

      toggle_shipping() {
        if (Ember.isPresent(this.get('product.shipping_type'))) {
          this.set('product.shipping_type', null);
        } else {
          this.set('product.shipping_type', 'product_price');
        }
      }

    }
  });

  _exports.default = _default;
});