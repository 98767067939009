define("manage/pods/admin/companies/company/components/edit-purpose-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yi7rEdPr",
    "block": "{\"symbols\":[\"purpose\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Change Project Purpose\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form warning content \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"loading\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui warning message\"],[8],[0,\"\\n    This will change the purpose of this project and override any custom phrases and translations that have been set.\\n  \"],[9],[0,\"\\n\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"project\"]],\"purpose\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[0,\"Purpose\"],[9],[0,\"\\n    \"],[5,\"power-select\",[],[[\"@renderInPlace\",\"@searchEnabled\",\"@selected\",\"@onChange\",\"@options\"],[true,false,[24,[\"project\",\"purpose\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"project\",\"purpose\"]]],null]],null],[22,\"purposes\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"titleize\",[[28,\"humanize\",[[23,1,[]]],null]],null],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button negative\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[1,[28,\"t\",[\"phrase.save\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/admin/companies/company/components/edit-purpose-modal/template.hbs"
    }
  });

  _exports.default = _default;
});