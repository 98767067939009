define("manage/pods/companies/company/projects/project/settings/payments/components/reactivate-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let payment_gateway = yield this.get('payment_gateway');

      try {
        yield this.get('ajax').postModel(payment_gateway, 'activate');
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: `Unable to disconnect ${payment_gateway.get('name')}.`
        });
        return;
      }

      if (this.get('afterSave')) {
        this.get('afterSave')();
      }

      this.closeModal();
    }).drop()
  });

  _exports.default = _default;
});