define("manage/pods/companies/company/account/info/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('model.company'),
    editing: false,
    submitted: false,
    saveTask: (0, _emberConcurrency.task)(function* (company) {
      company = yield company;
      company.set('shouldSetCountry', true);
      let {
        validations: validations
      } = yield company.validate();

      if (!validations.get('isValid')) {
        return false;
      }

      yield company.save();
      return true;
    }),
    resetForm: function () {
      this.set('editing', false);
      this.set('submitted', false);
    },
    actions: {
      edit() {
        this.set('editing', true);
      },

      cancel() {
        this.get('company').rollbackRelationships();
        this.resetForm();
      },

      save() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform(this.get('company')).then(result => {
          if (result) {
            this.resetForm();
          }
        });
      },

      onCountryChange(country) {
        let company = this.get('company');
        company.set('country', country);
        company.set('state', null);
      }

    }
  });

  _exports.default = _default;
});