define("manage/pods/companies/company/projects/project/customers/index/route", ["exports", "manage/pods/companies/company/projects/project/customers/index/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sort_direction: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        customers: this.search(project, params)
      });
    },

    setupController(controller) {
      controller.set('resultsLoadSuccess', true);

      this._super(...arguments);
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('pageSize');
      let sortProperty = params.sort;
      let sortDirection = params.sortDirection;
      let sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      let search = params.search;
      return this.store.query('customer', {
        filter: {
          project: project.get('id'),
          search: search
        },
        page: {
          number: page,
          size: pageSize
        },
        sort: sort
      });
    },

    actions: {
      error() {
        this.controller.set('resultsLoadSuccess', false);
        return true;
      }

    }
  });

  _exports.default = _default;
});