define("manage/components/authentication-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AizTW/9H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    Authenticated as \"],[1,[24,[\"user\",\"full_name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Not Authenticated\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"refreshIn\"]],0],null]],null,{\"statements\":[[0,\"      Session expires in \"],[1,[22,\"refreshIn\"],false],[0,\" seconds at \"],[1,[28,\"format-date\",[[24,[\"expiresAt\"]]],[[\"hour\",\"minute\",\"second\"],[\"2-digit\",\"2-digit\",\"2-digit\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Session Expired\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"user\",\"maintainSession\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"disableRefresh\"]],[8],[0,\"Automatically Refreshing Session\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"enableRefresh\"]],[8],[0,\"Refresh Disabled\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/authentication-status/template.hbs"
    }
  });

  _exports.default = _default;
});