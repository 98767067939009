define("manage/components/icon-email-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    showUnknown: false,
    prefix: '',
    status: null,
    is_pending: Ember.computed.equal('status', 'pending'),
    is_queued: Ember.computed.equal('status', 'queued'),
    is_queued_or_pending: Ember.computed.or('is_pending', 'is_queued'),
    was_sent: Ember.computed.equal('status', 'sent'),
    was_hard_bounced: Ember.computed.equal('status', 'hard_bounce'),
    was_soft_bounced: Ember.computed.equal('status', 'soft_bounce'),
    was_spammed: Ember.computed.equal('status', 'spam'),
    rejected: Ember.computed.equal('status', 'rejected'),
    statusMessage: Ember.computed('status', function () {
      if (this.get('was_sent')) {
        return `The ${this.get('prefix')}email was sent successfully`;
      } else if (this.get('was_hard_bounced')) {
        return `The ${this.get('prefix')}email hard bounced off the destination email server. This indicates a permanent issue such as the destination server not existing.`;
      } else if (this.get('was_soft_bounced')) {
        return `The ${this.get('prefix')}email soft bounced off the destination email server. This might be a temporary delivery issue if the mailbox is full.`;
      } else if (this.get('was_spammed')) {
        return `Unfortunately the customer has marked this email as spam and we cannot deliver it to them.`;
      } else if (this.get('is_queued_or_pending')) {
        return `The ${this.get('prefix')}email is sending.`;
      } else if (this.get('rejected')) {
        return "The email was rejected, either because of a hard bounce or a spam complaint.";
      } else {
        return "The status for this email is unknown.";
      }
    })
  });

  _exports.default = _default;
});