define("manage/pods/companies/company/projects/project/stats/index/components/stat-locations/component", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['order', 'location', 'graph', 'ui', 'segment'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    estimated: false,

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      let project = this.get('project');
      let stats = Ember.A();

      if (this.get('show_order_statistics')) {
        stats = yield this.get('store').query('project-order-location', {
          filter: {
            project_id: project.get('id')
          },
          page: {
            size: _environment.default['max-page-size']
          }
        });
      } else {
        stats = yield this.get('generateExternalStatsTask').perform(project);
      }

      this.set('stats', stats);
    }),
    generateExternalStatsTask: (0, _emberConcurrency.task)(function* (project) {
      let sources = yield project.get('sources');
      let total_orders = sources.reduce((value, source) => {
        return value += source.get('order_count') || 0;
      }, 0); // Matching show_order_statistics count of 20

      if (total_orders < 20) {
        return Ember.A();
      }

      let domestic_count = Math.ceil(total_orders * 0.64);
      let international_count = Math.floor(total_orders * 0.36);
      this.set('estimated', true);
      return Ember.A([this.get('store').createRecord('project-order-location', {
        project,
        location: "Domestic",
        count: domestic_count
      }), this.get('store').createRecord('project-order-location', {
        project,
        location: "International",
        count: international_count
      })]);
    }),
    empty: Ember.computed.equal('stats.length', 0),
    order_location_first: Ember.computed('order_location_chart_data', function () {
      let data = this.get('order_location_chart_data');
      return {
        label: data.labels[0],
        value: data.datasets[0].data[0],
        percent: data.datasets[0].percents[0]
      };
    }),
    order_location_last: Ember.computed('order_location_chart_data', function () {
      let data = this.get('order_location_chart_data');
      return {
        label: data.labels[1],
        value: data.datasets[0].data[1],
        percent: data.datasets[0].percents[1]
      };
    }),
    order_location_chart_data: Ember.computed('stats.@each.{count,location}', function () {
      let total = (this.get('stats') || Ember.A()).mapBy('count').reduce(function (a, b) {
        return a + b;
      }, 0);
      let data = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          percents: [],
          borderWidth: []
        }]
      };
      let intl = this.get('intl');
      (this.get('stats') || Ember.A()).sortBy('location').forEach(function (item) {
        let percent = item.get('count') / total;
        data.labels.push(intl.t(`component.companies.company.projects.project.stats.index.stat-locations.${item.get('location').toLowerCase()}`));
        data.datasets[0].data.push(item.get('count'));
        data.datasets[0].backgroundColor.push(item.get('location') === 'Domestic' ? '#29BEF1' : '#F3F6FA');
        data.datasets[0].percents.push(intl.formatNumber(percent, {
          style: 'percent'
        }));
        data.datasets[0].borderWidth.push(0);
      });
      return data;
    })
  });

  _exports.default = _default;
});