define("manage/pods/companies/company/projects/project/sources/configurations/components/bulk-status-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _ssModal, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['bulk-status-modal', 'component'],
    ajax: Ember.inject.service(),
    closable: false,
    configurations: null,
    processed: 0,
    send_emails: false,
    user: Ember.inject.service('user'),
    progress: Ember.computed('processed', 'configurations.[]', function () {
      return this.get('processed') / this.get('configurations.length') * 100;
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let configurations = this.get('configurations');
      let status = this.get('status');
      let send_emails = this.get('send_emails') && status === "live";
      this.set('errors', Ember.A());

      if (configurations.find(i => i.get('source.in_setup'))) {
        this.get('errors').pushObject(`You cannot launch these configurations while ${configurations.get('firstObject.source.name')} is still in setup.`);
        return;
      }

      for (let configuration of configurations.toArray()) {
        configuration.set('status', status);
        yield configuration.save();

        if (send_emails) {
          let data = {
            data: {
              configuration_id: configuration.get('id'),
              type: 'invite'
            }
          };

          try {
            yield this.get('ajax').post('/api/v2/orders/emails', data);
          } catch (xhr) {
            let message = ''; // Initial error message, include backend message or default

            if (xhr.payload && xhr.payload.error) {
              message += ` ${xhr.payload.error}`;
            } else {
              message += 'Unable to send emails';
            }

            message += ` for configuration: '${configuration.get('product_reward.name')}'`; // Include the configuration ID if the user is an admin.

            if (this.get('user.is_admin')) {
              message += ` (${configuration.get('id')}).`;
            } else {
              message += '.';
            } // Include the number of estimated orders that would have been sent.


            message += ` It had an estimated ${configuration.get('stats.total_orders')} orders`;
            this.get('errors').pushObject(message);
          }
        }

        this.set('processed', this.get('processed') + 1);
      }
    }),
    actions: {
      approve() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform().then(() => {
          if (this.get('errors.length') === 0) {
            this.closeModal();
          }
        }).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "There was an error while trying to update the configuration statuses. Some may have been updated."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});