define("manage/pods/companies/company/projects/project/orders/tags/index/route", ["exports", "manage/pods/companies/company/projects/project/orders/tags/index/columns", "manage/config/environment"], function (_exports, _columns, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: this.modelFor('companies.company.projects.project').reload(),
        tags: this.store.query('order-tag', {
          page: {
            size: _environment.default['max-page-size']
          },
          filter: {
            project: project.get('id')
          },
          include: ['stats'].join(',')
        })
      });
    }

  });

  _exports.default = _default;
});