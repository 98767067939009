define("manage/pods/companies/company/projects/project/orders/view/components/order-history/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['order-history', 'component'],
    store: Ember.inject.service(),
    initialized: false,
    activities: null,
    page: 1,
    page_size: 10,
    order: null,
    // Passed into the component
    project: Ember.computed.readOnly('order.project'),
    has_history: Ember.computed('project.created_at', function () {
      return this.get('project.created_at') > new Date('02/22/2020');
    }),

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('pageTask').perform(1);
      this.set('initialized', true);
    }),
    pageTask: (0, _emberConcurrency.task)(function* (number) {
      let order = yield this.get('order');
      let activities = yield this.get('store').query('audited-order', {
        filter: {
          project: order.get('project.id'),
          order: order.get('id')
        },
        page: {
          number: number,
          size: this.get('page_size')
        },
        sort: '-created_at'
      });
      this.set('page', number);
      this.set('activities', activities);
    }),
    actions: {
      page(number) {
        if (!number) {
          return;
        }

        if (this.get('pageTask.isRunning')) {
          return;
        }

        this.get('pageTask').perform(number);
      }

    }
  });

  _exports.default = _default;
});