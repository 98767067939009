define("manage/mixins/restrict-by-session", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function runIfUnauthenticated(owner, transition, callback) {
    const sessionSvc = owner.lookup('service:session');

    if (!sessionSvc.get('isAuthenticated')) {
      if (transition.targetName !== 'index') {
        // Index was most likely a refresh after a session invalidation. No need to route there
        // since we're going to do it by default if there isn't a redirect in place.
        sessionSvc.set('attemptedTransition', transition);
      }

      callback();
      return true;
    }
  }

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    authenticationRoute: 'user.login',

    // Taken directly from ember-simple-auth's source code. No other way I could see to override their
    // logic for runIfUnauthenticated.
    //
    // I believe 3.1 will give us some better options, but it's currently in beta.
    beforeModel(transition) {
      const didRedirect = runIfUnauthenticated(Ember.getOwner(this), transition, () => {
        this.triggerAuthentication();
      });

      if (!didRedirect) {
        return this._super(...arguments);
      }
    }

  });

  _exports.default = _default;
});