define("manage/pods/companies/company/projects/project/products/create/route", ["exports", "manage/mixins/handle-transition"], function (_exports, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    controllerName: 'companies.company.projects.project.products.edit',
    templateName: 'companies.company.projects.project.products.edit',
    productTasks: Ember.inject.service(),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let type = params.type;
      return this.get('productTasks.create').perform(project, {
        product_type: type
      }).then(product => {
        return Ember.RSVP.hash({
          project: project,
          product: product
        });
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product) {
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(record.product).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'cancel-modal', {
                  model: record.product,
                  modelName: "Product",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});