define("manage/pods/companies/company/projects/project/segments/components/credit-file-modal/component", ["exports", "manage/mixins/ss-modal", "manage/config/environment"], function (_exports, _ssModal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['credit-file-modal', 'component'],
    ajax: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    importPreviewUrl: '/api/v2/order-transactions/import?preview=true',
    importUrl: Ember.computed('project.id', 'fileId', function () {
      return `/api/v2/order-transactions/import?preview=false&project_id=${this.get('project.id')}&file_id=${this.get('fileId')}`;
    }),
    mode: 'preview',

    processJobResult(result) {
      this.set('error', null);
      this.set('jobId', result.data.id);

      if (result.meta) {
        this.set('fileId', result.meta.file_id);
        this.set('fileName', result.meta.file_name);
      }
    },

    processError(xhr) {
      this.set('jobId', null);

      if (xhr.payload && xhr.payload.errors) {
        this.set('error', xhr.payload.errors.map(error => error.detail || error.title).join(', '));
      } else {
        this.set('error', xhr.message || 'The upload failed');
      }
    },

    actions: {
      cancel() {
        this.closeModal();
      },

      uploadSuccess(result) {
        this.processJobResult(result);
        this.set('mode', 'preview');
      },

      uploadError(xhr) {
        this.processError(xhr);
      },

      jobFailed(error) {
        this.processError({
          message: error.message
        });
      },

      processNow() {
        this.set('jobId', null);
        this.get('ajax').post(this.get('importUrl')).then(result => {
          this.processJobResult({
            data: {
              id: result.data.id
            }
          });
          this.set('mode', 'live');
        }).catch(xhr => {
          this.processError(xhr);
        });
      }

    }
  });

  _exports.default = _default;
});