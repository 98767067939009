define("manage/pods/companies/company/projects/project/orders/view/components/view-order-history-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    intl: Ember.inject.service(),
    closable: true,
    actions: {
      message_or_text(message) {
        if (message.startsWith(':')) {
          // Strip off the colon when looking up the message in intl
          let intlKey = `order-history.${message.substring(1)}`;

          if (this.intl.exists(intlKey)) {
            return this.intl.t(intlKey);
          }
        }

        return message;
      }

    }
  });

  _exports.default = _default;
});