define("manage/pods/admin/companies/company/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sEDby1It",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"admin.companies.company\"]],{\"statements\":[[0,\"\\n  Account\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"admin.companies.company.edit\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"phrase.edit\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"admin.companies.company.projects\"]],{\"statements\":[[0,\"\\n    Projects\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"admin.companies.company.invoices\"]],{\"statements\":[[0,\"\\n    Invoices\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"item header\"],[8],[0,\"\\n  Creator Pages\\n\"],[9],[0,\"\\n\"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\",\"@model\"],[\"companies.company.account\",[24,[\"company\",\"id\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"icon options\"],[8],[9],[0,\"\\n  Account\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"company\",\"notification_count\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui mini red label\"],[8],[1,[24,[\"company\",\"notification_count\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\",\"@model\"],[\"companies.company.projects\",[24,[\"company\",\"id\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"icon configure\"],[8],[9],[0,\"\\n  Projects\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/admin/companies/company/menu/template.hbs"
    }
  });

  _exports.default = _default;
});