define("manage/initializers/sentry", ["exports", "@sentry/browser", "@sentry/integrations", "manage/config/environment"], function (_exports, Sentry, _integrations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    if (_environment.default.environment === 'test') {
      return;
    }

    if (_environment.default.environment === 'development') {
      return;
    } // config.APP.version is of the form 1.0.0+ac06050a
    // Try to get just the SHA portion of the application version, but use the entire version as a fallback.


    var versionMatch = _environment.default.APP.version.match(/[a-z\d]{8}$/);

    var release = versionMatch ? versionMatch[0] : _environment.default.APP.version;
    Sentry.init({
      dsn: _environment.default.sentry.url,
      integrations: [new _integrations.Ember()],
      whitelistUrls: _environment.default.sentry.whitelist_urls || [],
      environment: _environment.default.environment,
      release: release,

      beforeSend(event, hint) {
        // Filter out transition errors to sentry. Redirect with query params, etc
        // https://github.com/emberjs/ember.js/issues/5566
        if (hint.originalException && hint.originalException.name === "TransitionAborted") {
          return null;
        }

        if (event.exception && event.exception.values && event.exception.values[0]) {
          if (event.exception.values[0].value === "The adapter operation was aborted") {
            return null;
          } // Filter out 401 unauthorized errors
          // if((event.exception.values[0].value || '').match(/returned a 401/)) {
          //   return null;
          // }

        }

        return event;
      }

    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});