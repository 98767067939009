define("manage/pods/companies/company/projects/project/settings/index/controller", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/mixins/image-upload"], function (_exports, _emberConcurrency, _catchReal, _imageUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend((0, _imageUpload.default)('project.image_path'), {
    project: Ember.computed.readOnly('model.project'),
    feature_flags: Ember.computed.readOnly('model.feature_flags'),
    current_user: Ember.inject.service('user'),
    submitted: false,
    google_analytics_id: Ember.computed.alias('model.project.settings.analytics.google.id'),
    facebook_pixel_id: Ember.computed.alias('model.project.settings.analytics.facebook.id'),
    units_of_mass: [Ember.Object.create({
      id: "kg"
    }), Ember.Object.create({
      id: "lb"
    }), Ember.Object.create({
      id: "oz"
    })],
    units_of_length: [Ember.Object.create({
      id: "cm"
    }), Ember.Object.create({
      id: "in"
    })],
    shipping_types: [Ember.Object.create({
      id: "product_price"
    }), Ember.Object.create({
      id: "product_weight"
    })],
    address_name_types: [Ember.Object.create({
      id: "single_name"
    }), Ember.Object.create({
      id: "split_name"
    }), Ember.Object.create({
      id: "split_name_initial"
    })],
    colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey'],
    color_index: 0,
    isRunning: Ember.computed.or('saveTask.isRunning', 'toggleFlagTask.isRunning'),
    saveTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      let {
        validations: validations
      } = yield project.validate();

      if (!validations.get('isValid')) {
        return;
      }

      yield project.save();
    }),
    toggleFlagTask: (0, _emberConcurrency.task)(function* (project, feature_flag) {
      project = yield project;

      if (project.get('settings.feature_flags').includes(feature_flag)) {
        project.get('settings.feature_flags').removeObject(feature_flag);
      } else {
        project.get('settings.feature_flags').addObject(feature_flag);
      }

      yield project.save();
    }).drop(),
    actions: {
      save() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform(this.get('project'));
      },

      update_flag(feature_flag) {
        if (this.get('toggleFlagTask.isRunning')) {
          return;
        }

        let project = this.get('project');
        this.get('toggleFlagTask').perform(project, feature_flag).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to save, please refresh and try again."
          });
        }));
      },

      get_color() {
        if (this.get('colors.length') === this.get('color_index')) {
          this.set('color_index', 0);
        }

        let color = this.get('colors')[this.get('color_index')];
        this.incrementProperty('color_index');
        return color;
      }

    }
  });

  _exports.default = _default;
});