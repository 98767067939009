define("manage/mixins/filters/steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    step_options: [Ember.Object.create({
      id: 'new',
      title: 'New'
    }), Ember.Object.create({
      id: 'invited',
      title: 'Invited'
    }), Ember.Object.create({
      id: 'in_progress',
      title: 'In Progress'
    }), Ember.Object.create({
      id: 'incomplete',
      title: 'Incomplete (Invited and In Progress)'
    }), Ember.Object.create({
      id: 'completed',
      title: 'Complete'
    }), Ember.Object.create({
      id: 'canceled',
      title: 'Canceled'
    })]
  });

  _exports.default = _default;
});