define("manage/pods/companies/route", ["exports", "manage/mixins/restrict-by-session"], function (_exports, _restrictBySession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictBySession.default, {
    current_user: Ember.inject.service('user'),

    model() {
      if (this.get('current_user.is_admin')) {
        return null;
      }

      return this.store.findRecord('user', this.get('current_user.id'), {
        include: 'companies'
      });
    },

    redirect(model, transition) {
      if (this.get('current_user.is_admin')) {
        return;
      }

      if (transition.targetName === "companies.index") {
        if (model.get('companies.length') === 0) {
          this.replaceWith('user.activate');
          return;
        }

        if (model.get('companies.length') === 1) {
          this.replaceWith('companies.company', model.get('companies.firstObject.id'));
          return;
        }
      } // They have more than one company or they are looking for a specific route nested below

    },

    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('companies', {
        into: 'shared/main'
      });
    }

  });

  _exports.default = _default;
});