define("manage/models/customer", ["exports", "manage/utils/generated-model", "ember-cp-validations"], function (_exports, _generatedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = (0, _generatedModel.default)('customer').extend(validations);

  _exports.default = _default;
});