define("manage/pods/companies/company/projects/project/orders/components/upload-order-selections-modal/component", ["exports", "manage/mixins/ss-modal", "manage/config/environment", "manage/helpers/order-query-params"], function (_exports, _ssModal, _environment, _orderQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    importUrl: Ember.computed('project.id', 'fileId', 'fileName', function () {
      let params = new URLSearchParams();
      params.set('preview', false);
      params.set('project_id', this.get('project.id'));
      params.set('file_id', this.fileId);
      params.set('file_name', this.fileName);
      return `/api/v2/orders/import_selections?${params}`;
    }),
    importPreviewUrl: '/api/v2/orders/import_selections?preview=true',

    processJobResult(result) {
      this.set('error', null);
      this.set('jobId', result.data.id);

      if (result.meta) {
        this.set('fileId', result.meta.file_id);
        this.set('fileName', result.meta.file_name);
      }
    },

    actions: {
      cancel() {
        this.closeModal();
      },

      uploadSuccess(result) {
        this.processJobResult(result);
        this.set('mode', 'preview');
      },

      uploadError(xhr) {
        this.send('open_modal', 'error-modal', {
          error: xhr.responseText,
          description: "Unable to process"
        });
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      processNow() {
        this.set('jobId', null);
        this.get('ajax').post(this.get('importUrl')).then(result => {
          this.processJobResult({
            data: {
              id: result.data.id
            }
          });
          this.set('mode', 'live');
        }).catch(xhr => {
          this.processError(xhr);
        });
      },

      viewOrders(project_segment_id) {
        this.router.transitionTo('companies.company.projects.project.orders.index', {
          queryParams: (0, _orderQueryParams.orderQueryParams)(null, {
            project_segment: project_segment_id
          })
        });
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});