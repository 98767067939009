define("manage/pods/companies/company/projects/project/orders/index/components/column-external-sequence/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+yvV174t",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"order\",\"extra\",\"import\",\"external_sequence\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/index/components/column-external-sequence/template.hbs"
    }
  });

  _exports.default = _default;
});