define("manage/pods/users/password/edit/controller", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    queryParams: ['reset_password_token', 'token'],
    password: null,
    password_confirmation: null,
    errors: null,
    changed: false,
    filledIn: Ember.computed('password', 'password_confirmation', 'errors', function () {
      return Ember.isPresent(this.get('password')) && Ember.isPresent(this.get('password_confirmation')) && Ember.isBlank(this.get('errors'));
    }),
    textChanged: Ember.observer('password', 'password_confirmation', function () {
      if (this.get('errors')) {
        return this.set('errors', null);
      }
    }),
    saveTask: (0, _emberConcurrency.task)(function* (password, passwordConfirmation, resetPasswordToken) {
      if (password != passwordConfirmation) {
        throw "Passwords do not match";
      }

      if (this.get('token')) {
        // New Authentication
        try {
          yield this.get('ajax').post(`${_environment.default.urls.auth}/password`, {
            data: {
              password: password,
              token: this.get('token')
            }
          });
        } catch (e) {
          throw e.payload.errors.map(item => {
            let error = null;

            if (item.field === 'credentials' && item.message === 'FAILED') {
              error = `${item.field} are invalid`;
            } else if (item.field === 'token' && item.message === 'INVALID_OR_EXPIRED') {
              error = `${item.field} has expired or is already been used`;
            } else if (item.field === 'account' && item.message === 'NOT_FOUND') {
              error = `${item.field} is not found`;
            } else if (item.field === 'account' && item.message === 'LOCKED') {
              error = `${item.field} has been locked`;
            } else if (item.field === 'password' && item.message === 'MISSING') {
              error = `${item.field} is missing`;
            } else if (item.field === 'password' && item.message === 'INSECURE') {
              error = `${item.field} is not secure enough; try lengthening it with additional characters or words`;
            } else {
              error = `${item.field} is ${item.message}`;
            }

            return error;
          });
        }
      } else {
        try {
          yield this.get('ajax').put('/users/password', {
            data: {
              'user[password]': password,
              'user[password_confirmation]': passwordConfirmation,
              'user[reset_password_token]': resetPasswordToken
            }
          });
        } catch (e) {
          throw e.payload.errors;
        }
      }

      this.set('password', null);
      this.set('password_confirmation', null);
      this.set('errors', null);
      this.set('changed', true);
    }),
    actions: {
      change() {
        this.get('saveTask').perform(this.get('password'), this.get('password_confirmation'), this.get('reset_password_token')).catch(error => {
          this.set('errors', error);
        });
      }

    }
  });

  _exports.default = _default;
});