define("manage/pods/companies/company/projects/index/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('model.company'),
    projects: Ember.computed.readOnly('model.projects'),
    current_user: Ember.inject.service('user'),
    allowSignup: _environment.default.allowSignup,
    sorted_projects: Ember.computed('projects', function () {
      return this.get('projects').sortBy('created_at').reverse();
    }),
    queryParams: ['page', 'search'],
    page_size: 25,
    // Filters
    page: 1,
    search: ''
  });

  _exports.default = _default;
});