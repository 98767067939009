define("manage/utils/computed-default-value", ["exports", "shared/utils/computed-default-value"], function (_exports, _computedDefaultValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "computedDefaultSafeString", {
    enumerable: true,
    get: function () {
      return _computedDefaultValue.computedDefaultSafeString;
    }
  });
  Object.defineProperty(_exports, "computedDefaultValue", {
    enumerable: true,
    get: function () {
      return _computedDefaultValue.computedDefaultValue;
    }
  });
  Object.defineProperty(_exports, "getDefault", {
    enumerable: true,
    get: function () {
      return _computedDefaultValue.getDefault;
    }
  });
});