define("manage/pods/companies/company/projects/project/info/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      return this.replaceWith('companies.company.projects.project.info.configurations');
    }

  });

  _exports.default = _default;
});