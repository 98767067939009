define("manage/pods/companies/company/projects/project/sources/configurations/settings/controller", ["exports", "manage/helpers/has-installed-app", "ember-concurrency"], function (_exports, _hasInstalledApp, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    project: Ember.computed.readOnly('model.project'),
    source: Ember.computed.readOnly('model.source'),
    showPledgeConfirmation: Ember.computed('project.purpose', function () {
      return this.get('project.purpose') === 'pledge_management';
    }),
    showPaymentRecovery: Ember.computed('project', 'source.is_internal', function () {
      return (0, _hasInstalledApp.hasInstalledApp)(this.get('project'), 'payment-recovery') && this.get('source.is_internal') == false;
    }),
    showPreorderRecovery: Ember.computed('project', 'source.site', function () {
      return this.get('source.site') == "crowdox";
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let source = yield this.get('source');

      if (source.get('hasDirtyAttributes')) {
        yield source.save();
      }
    }),
    actions: {
      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform().then(() => this.transitionToRoute('companies.company.projects.project.sources.configurations'));
      }

    }
  });

  _exports.default = _default;
});