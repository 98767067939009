define("manage/mixins/image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(image_path_property) {
    return Ember.Mixin.create({
      actions: {
        // Form Events
        upload_success(data) {
          this.set(image_path_property, data.image_path);
        },

        upload_error(XHR) {
          alert(XHR.responseText);
        },

        clear_image() {
          this.set(image_path_property, null);
        }

      },
      upload_url: '/images'
    });
  }
});