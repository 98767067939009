define("manage/pods/admin/projects/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('admin/projects', {
        into: 'shared/main'
      });
      this.render('admin/projects/menu', {
        into: 'shared/main',
        outlet: 'menu'
      });
    }

  });

  _exports.default = _default;
});