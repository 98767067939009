define("manage/pods/companies/company/projects/project/orders/view/components/delete-order-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    has_error: false,
    is_processing: false,
    order: null,
    actions: {
      approve() {
        if (this.get('is_processing')) {
          return;
        }

        this.set('is_processing', true);
        this.get('order').deleteRecord();
        this.get('order').save().then(() => {
          this.closeModal();
          Ember.run.scheduleOnce('afterRender', this, this.get('transition_to_orders'));
        }).catch(() => {
          this.get('order').rollbackAttributes();
          this.set('has_error', true);
        }).finally(() => {
          this.set('is_processing', false);
        });
      }

    }
  });

  _exports.default = _default;
});