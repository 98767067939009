define("manage/pods/companies/company/projects/project/orders/create-export/route", ["exports", "manage/helpers/decode-string", "manage/utils/strip-non-printable"], function (_exports, _decodeString, _stripNonPrintable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      filter: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        orders: this.search(project, params)
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.reset();
      }
    },

    search(project, params) {
      let decoded_filters = JSON.parse((0, _stripNonPrintable.default)((0, _decodeString.decodeString)(params.filter)));
      return this.store.query('order', {
        filter: Object.assign(decoded_filters, {
          project: project.get('id')
        }),
        include: ['customer', 'current-configuration', 'current-configuration.product-reward'].join(','),
        page: {
          number: 1,
          size: 1
        }
      });
    }

  });

  _exports.default = _default;
});