define("manage/pods/companies/company/projects/project/shipping/vat/components/edit-categories-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['edit-categories-modal', 'component'],
    store: Ember.inject.service(),
    saveTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      let categories = yield project.get('vat_categories').toArray();

      for (let category of categories) {
        yield category.save();
        yield category.reload();
      }
    }),
    actions: {
      add_vat_category() {
        this.get('store').createRecord('vat-category', {
          project: this.get('project')
        });
      },

      delete_vat_category(vat_category) {
        vat_category.destroyRecord();
      },

      save() {
        this.get('saveTask').perform(this.get('project')).then(() => {
          this.closeModal();
        });
      }

    }
  });

  _exports.default = _default;
});