define("manage/mirage/factories/order-address", ["exports", "shared/mirage/factories/order-address"], function (_exports, _orderAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _orderAddress.default;
    }
  });
});