define("manage/pods/companies/company/projects/project/launch-guide/components/lg-pre-orders/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('checkSourceTask').perform();
    },

    completed: Ember.computed('project.settings.wizard.skip_preorder', 'source', function () {
      if (this.get('project.settings.wizard.skip_preorder')) {
        return true;
      }

      if (Ember.isPresent(this.get('source'))) {
        return true;
      }

      return false;
    }),
    pending: Ember.computed('source.in_setup', function () {
      if (this.get('source.in_setup')) {
        return true;
      }

      return false;
    }),
    isRunning: Ember.computed.or('checkSourceTask.isRunning', 'skipPreOrderTask.isRunning'),
    checkSourceTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let sources = yield project.get('sources');
      let crowdox_source = sources.findBy('is_internal', true);
      this.set('source', crowdox_source);
    }),
    skipPreOrderTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      project.set('settings.wizard.skip_preorder', true);
      yield project.save();
    }).drop()
  });

  _exports.default = _default;
});