define("manage/pods/companies/company/projects/project/settings/payments/components/reconnect-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "awKYj4lL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"invite header\"],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"title\"],[[\"provider\",\"account_id\"],[[24,[\"payment_gateway\",\"name\"]],[24,[\"payment_gateway\",\"account_id\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form content \",[28,\"if\",[[28,\"and\",[[24,[\"submitted\"]],[28,\"get\",[[28,\"get\",[[24,[\"company\"]],\"validations\"],null],\"isInvalid\"],null]],null],\"error\"],null]]]],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"description\"],[[\"provider\",\"account_id\",\"project_name\"],[[24,[\"payment_gateway\",\"name\"]],[24,[\"payment_gateway\",\"account_id\"]],[24,[\"project\",\"name\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui floated left button \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"disabled\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"close\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saveTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"phrase.close\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui button positive \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"disabled\"],null]]]],[11,\"onclick\",[28,\"perform\",[[24,[\"saveTask\"]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saveTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"spinner loading icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"t-component\",[[23,0,[]],\"actions.save\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/settings/payments/components/reconnect-modal/template.hbs"
    }
  });

  _exports.default = _default;
});