define("manage/utils/regex-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Pulled from semantic ui's dropdown full text search
  function _default(query, term) {
    if (Ember.isBlank(query)) {
      return false;
    }

    let escapeRegExp = function (text) {
      text = String(text);
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    let escapedTerm = escapeRegExp(term); // starts with the word or has a space in front

    let beginsWithRegExp = new RegExp('(^' + escapedTerm + '| ' + escapedTerm + ')', 'igm');

    if (query.search(beginsWithRegExp) !== -1) {
      return true;
    }

    return false;
  }
});