define("manage/components/confirm-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "whlLSx1b",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[22,\"displayedTitle\"],true],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui basic segment content \",[28,\"if\",[[24,[\"loading\"]],\"loading\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[22,\"displayedQuestion\"],true],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"displayedDescription\"]]],null,{\"statements\":[[0,\"      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[8],[1,[22,\"displayedDescription\"],true],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"phrase.close\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"approve\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"ui floated left button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n        \"],[1,[22,\"displayedCancelLabel\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",false],[12,\"class\",\"ui green button\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"\\n      \"],[1,[22,\"displayedApproveLabel\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/confirm-modal/template.hbs"
    }
  });

  _exports.default = _default;
});