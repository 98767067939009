define("manage/pods/companies/company/projects/project/apps/components/uninstall-app-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    classNames: ['uninstall-app-modal', 'component'],
    closable: false,
    completed: false,
    isRunning: Ember.computed.alias('uninstallTask.isRunning'),
    uninstallTask: (0, _emberConcurrency.task)(function* () {
      let app = yield this.get('app');
      let project = yield this.get('project');

      try {
        yield app.uninstall(project);
      } catch (xhr) {
        if (xhr.payload && xhr.payload.errors) {
          this.set('error', xhr.payload.errors.map(error => error.detail || error.title).join(', '));
        } else {
          this.set('error', xhr.message || 'The uninstall failed');
        }
      }

      if (this.get('uninstalled')) {
        this.get('uninstalled')(app);
      }
    }),
    actions: {
      install() {
        this.set('error', null);
        this.get('uninstallTask').perform().then(() => {
          if (this.get('error') === null) {
            this.set('completed', true);
          }
        });
      },

      complete() {
        this.closeModal();
        this.onCompletion();
      }

    }
  });

  _exports.default = _default;
});