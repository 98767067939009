define("manage/mixins/filters/segmentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filter_synced: Ember.computed('project.installed_apps.@each.type_slug', function () {
      return this.get('project.installed_apps').filterBy('type_slug', 'api-exports').sortBy('name');
    }),
    filter_action_state: [Ember.Object.create({
      id: 'new',
      title: 'New'
    }), Ember.Object.create({
      id: 'working',
      title: 'Working'
    }), Ember.Object.create({
      id: 'in_error',
      title: 'In Error'
    }), Ember.Object.create({
      id: 'errored',
      title: 'Errored'
    }), Ember.Object.create({
      id: 'completed',
      title: 'Completed'
    })],
    filter_action_operation: [Ember.Object.create({
      id: 'add_product',
      title: 'Add Product'
    }), Ember.Object.create({
      id: 'add_tag',
      title: 'Add Tag'
    }), Ember.Object.create({
      id: 'api_push',
      title: 'API Push'
    }), Ember.Object.create({
      id: 'cancel',
      title: 'Cancel'
    }), Ember.Object.create({
      id: 'change_configuration',
      title: 'Change Configuration'
    }), Ember.Object.create({
      id: 'credit',
      title: 'Credit'
    }), Ember.Object.create({
      id: 'delete',
      title: 'Delete'
    }), Ember.Object.create({
      id: 'export',
      title: 'Export'
    }), Ember.Object.create({
      id: 'lock',
      title: 'Lock'
    }), Ember.Object.create({
      id: 'remove_product',
      title: 'Remove Product'
    }), Ember.Object.create({
      id: 'remove_tag',
      title: 'Remove Tag'
    }), Ember.Object.create({
      id: 'uncancel',
      title: 'Uncancel'
    }), Ember.Object.create({
      id: 'unlock',
      title: 'Unlock'
    })],
    filter_order_action_state: [Ember.Object.create({
      id: 'completed',
      title: 'Completed'
    }), Ember.Object.create({
      id: 'errored',
      title: 'Errored'
    }), Ember.Object.create({
      id: 'skipped',
      title: 'Skipped'
    }), Ember.Object.create({
      id: 'incomplete',
      title: 'Incomplete'
    })]
  });

  _exports.default = _default;
});