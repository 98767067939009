define("manage/models/pre-order-theme", ["exports", "ember-data-model-fragments/fragment", "shared/utils/computed-default-value", "shared/mixins/common-theme", "shared/utils/image", "@ember-data/model"], function (_exports, _fragment, _computedDefaultValue, _commonTheme, _image, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This should mostly match the api and project layout for easy reference
  var _default = _fragment.default.extend(_commonTheme.default, {
    intl: Ember.inject.service(),
    social_sharing_image_path: (0, _model.attr)('string'),
    social_sharing_image_src: (0, _image.default)('social_sharing_image_path'),
    pre_order_button_text: (0, _model.attr)('string'),
    stats_background_color: (0, _model.attr)('string'),
    stats_text_color: (0, _model.attr)('string'),
    stats_subtext_color: (0, _model.attr)('string'),
    link_text_color: (0, _model.attr)('string'),
    // Colors Default
    default_stats_background_color: (0, _computedDefaultValue.computedDefaultSafeString)('stats_background_color', '#FFFFFF'),
    default_stats_text_color: (0, _computedDefaultValue.computedDefaultSafeString)('stats_text_color', '#FFFFFF'),
    default_stats_subtext_color: (0, _computedDefaultValue.computedDefaultSafeString)('stats_subtext_color', '#B44F16'),
    default_link_text_color: (0, _computedDefaultValue.computedDefaultSafeString)('link_text_color', '#7D7D7D')
  });

  _exports.default = _default;
});