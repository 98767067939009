define("manage/models/holiday", ["exports", "ember-cp-validations", "@ember-data/model", "manage/utils/generated-model"], function (_exports, _emberCpValidations, _model, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    starts_at: {
      description: "Starts At",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    ends_at: {
      description: "Ends At",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = (0, _generatedModel.default)('holiday').reopen(Validations, {
    intl: Ember.inject.service(),
    starts_at: (0, _model.attr)('string'),
    ends_at: (0, _model.attr)('string'),
    message_before: (0, _model.attr)('string'),
    message_during: (0, _model.attr)('string'),
    notice_days: (0, _model.attr)('number'),
    has_passed: Ember.computed('ends_at_date', function () {
      return this.get('ends_at_date') < new Date();
    }),
    starts_at_date: Ember.computed('starts_at', function () {
      return new Date(`${this.get('starts_at').replace(/-/g, '/')} 00:00:00 -0500`);
    }),
    ends_at_date: Ember.computed('ends_at', function () {
      return new Date(`${this.get('ends_at').replace(/-/g, '/')} 00:00:00 -0500`);
    }),
    ends_at_next_date: Ember.computed('ends_at', function () {
      let date = new Date(`${this.get('ends_at').replace(/-/g, '/')} 00:00:00 -0500`);
      date.setDate(date.getDate() + 1);
      return date;
    }),
    message_before_rendered: Ember.computed('message_before', 'starts_at_date', 'ends_at_date', function () {
      let starts_at = this.get('intl').formatDate(this.get('starts_at_date'), {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
      let ends_at = this.get('intl').formatDate(this.get('ends_at_date'), {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
      return this.get('message_before').replace('{{starts_at}}', starts_at).replace('{{ends_at}}', ends_at);
    }),
    message_during_rendered: Ember.computed('message_during', 'ends_at_date', function () {
      let ends_at = this.get('intl').formatDate(this.get('ends_at_date'), {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
      return this.get('message_during').replace('{{ends_at}}', ends_at);
    })
  });

  _exports.default = _default;
});