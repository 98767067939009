define("manage/mixins/segment-action", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    isRunning: Ember.computed.or('actionTask.isRunning', 'loadSegmentActionTask.isRunning'),
    actionTask: (0, _emberConcurrency.task)(function* (options) {
      let data = {};
      let project = yield this.get('project');
      let segment = yield this.get('segment');
      let filters = yield this.get('filters') || {};

      if (segment) {
        data.project_segment_id = segment.get('id');
      } else {
        let project_segment = filters['project_segment'] || '';

        if (Ember.isPresent(project_segment) && project_segment.split(',').length === 1 && project_segment.startsWith('out') === false && project_segment.startsWith('excludes') === false) {
          data.project_segment_id = project_segment;
        }
      }

      let has_other_than_project_and_segment_filters = false; // Compact

      filters = Object.keys(filters).reduce((map, key) => {
        if (filters[key]) {
          map[key] = filters[key];
        }

        if (key !== 'project_id' && key !== 'project_segment' && Ember.isPresent(filters[key])) {
          has_other_than_project_and_segment_filters = true;
        }

        return map;
      }, {});
      data.project_id = project.get('id');
      data.filters = filters;
      data.options = options;

      if (data.project_segment_id) {
        if (has_other_than_project_and_segment_filters) {
          data.filters['project_segment'] = data.project_segment_id;
          delete data.project_segment_id;
        } else {
          delete data.filters;
        }
      }

      try {
        let url = null;
        let action = Ember.String.underscore(this.type);

        if (data.project_segment_id) {
          url = `${_environment.default.urls.api}/bulk/${data.project_segment_id}/${action}`;
        } else {
          url = `${_environment.default.urls.api}/bulk/${action}`;
        }

        let result = yield this.get('ajax').post(url, {
          contentType: 'application/json; charset=UTF-8',
          dataType: 'json',
          data: data
        });

        if (result.data && result.data.id) {
          this.set('jobId', result.data.id);
        }
      } catch (xhr) {
        this.send('jobFailed', xhr.payload.error);
      }
    }),
    loadSegmentActionTask: (0, _emberConcurrency.task)(function* (project_segment_action_id) {
      return yield this.get('store').findRecord('project-segment-action', project_segment_action_id, {
        include: 'segment'
      });
    }),
    actions: {
      jobCompleted(job) {
        if (job.get('extra.project_segment_action_id')) {
          this.get('loadSegmentActionTask').perform(job.get('extra.project_segment_action_id')).then(project_segment_action => {
            this.set('project_segment_action', project_segment_action);

            if (this.get('complete')) {
              this.get('complete')(project_segment_action);
            }
          });
        } else {
          if (this.get('complete')) {
            this.get('complete')();
          }
        }
      },

      saveSegment() {
        this.closeModal();
        this.send('open_modal', 'companies.company.projects.project.segments.save-segment-modal', {
          project_segment: this.get('project_segment'),
          saved: function (project_segment) {
            this.get('router').transitionTo('companies.company.projects.project.segments.view', project_segment.get('id'));
          }
        });
      }

    }
  });

  _exports.default = _default;
});