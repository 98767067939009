define("manage/mixins/handle-transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      didTransition() {
        this.set('handled', false);
      }

    },

    handleTransition(transition, handler) {
      // Returning a promise doesn't stop the transition,
      // We have to abort and then when we retry check if we internally handled it already or not
      if (!this.get('handled')) {
        transition.abort();
        return new Ember.RSVP.Promise(resolve => {
          handler(resolve);
        }).then(action => {
          if (action === 'retry') {
            this.set('handled', true);
            transition.retry();
          } else if (action === 'aborted') {
            this.set('handled', false);
          }
        });
      } else {
        // reset handled
        this.set('handled', false);
      }
    }

  });

  _exports.default = _default;
});