define("manage/pods/admin/route", ["exports", "manage/mixins/restrict-by-session", "manage/mixins/restrict-by-role"], function (_exports, _restrictBySession, _restrictByRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictBySession.default, (0, _restrictByRole.default)('admin'), {
    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('admin', {
        into: 'shared/main'
      });
    }

  });

  _exports.default = _default;
});