define("manage/pods/companies/company/account/info/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let company = this.modelFor('companies.company');
      return Ember.RSVP.hash({
        company: this.store.findRecord('company', company.get('id'))
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('editing', false);
      }
    }

  });

  _exports.default = _default;
});