define("manage/pods/user/activate/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('model.company'),
    submitted: false,
    activateTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      let company = yield this.get('company');
      let result = yield company.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      yield company.save();
    }),
    actions: {
      onCountryChange(country) {
        let company = this.get('company');
        company.set('country', country);
        company.set('state', null);
      },

      activate() {
        if (this.get('activateTask.isRunning')) {
          return;
        }

        this.get('activateTask').perform().catch(() => {
          this.set('error', true);
          this.set('errorMessage', "Unable to activate.");
        }).then(() => {
          this.transitionToRoute('companies');
        });
      }

    }
  });

  _exports.default = _default;
});