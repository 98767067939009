define("manage/pods/companies/company/projects/project/sources/configurations/review/components/review-group/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      let entity = yield this.get('entity');
      let review = yield entity.review();
      return review.results;
    })
  });

  _exports.default = _default;
});