define("manage/pods/companies/company/projects/project/orders/view/components/add-refund-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "ember-cp-validations"], function (_exports, _ssModal, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    amount: {
      description: "Refund Amount",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        gt: 0,
        // If we are refunding all, don't validate
        disabled: Ember.computed.equal('model.refund_all', true)
      })]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, Validations, {
    store: Ember.inject.service(),
    classNames: 'refund',
    transaction: null,
    refund_selection: 'all',
    refund_all: Ember.computed.equal('refund_selection', 'all'),
    refund_partial: Ember.computed.equal('refund_selection', 'partial'),
    amount: 0,
    all_or_remaining: Ember.computed('transaction.refund_amount', function () {
      if (this.get('transaction.refund_amount') < 0) {
        return "Remaining transaction";
      } else {
        return "Entire transaction";
      }
    }),
    refund_amount: Ember.computed('refund_all', 'max_refund', 'amount', function () {
      if (this.get('refund_all')) {
        return this.get('max_refund');
      }

      let amount = parseFloat(this.get('amount'));

      if (isNaN(amount)) {
        return 0;
      }

      if (amount > this.get('max_refund')) {
        return this.get('max_refund');
      }

      return amount || 0;
    }),
    max_refund: Ember.computed('transaction.{amount,refund_amount}', function () {
      return this.get('transaction.amount') + this.get('transaction.refund_amount');
    }),
    greater_than_max: Ember.computed('max_refund', 'amount', function () {
      let amount = parseFloat(this.get('amount'));

      if (isNaN(amount)) {
        return false;
      }

      return amount > this.get('max_refund');
    }),
    refundTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      this.set('has_error', false);
      let transaction = yield this.get('transaction');
      let {
        validations: validations
      } = yield this.validate();

      if (!validations.get('isValid')) {
        return; // Not valid return so the ui updates
      }

      let amount_cents = null;

      if (this.get('refund_all') !== true) {
        let project = yield transaction.get('project');
        let currency_base = project.get('currency_base');
        let amount = parseFloat(this.get('amount'));

        if (!isNaN(amount)) {
          if (amount > this.get('max_refund')) {
            amount = this.get('max_refund');
          }

          amount_cents = Math.floor(amount * -1 * currency_base);
        }
      }

      try {
        let refund_result = yield transaction.refund(amount_cents);
        this.get('store').pushPayload('order-transaction', refund_result);
      } catch (e) {
        this.set('has_error', true);
        return;
      }

      this.closeModal();
    }).drop()
  });

  _exports.default = _default;
});