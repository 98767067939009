define("manage/pods/companies/company/projects/project/customers/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        customer: this.store.findRecord('customer', params.customer_id, {
          include: ['orders', 'orders.lines', 'orders.transactions'].join(',')
        })
      });
    }

  });

  _exports.default = _default;
});