define("manage/pods/companies/company/projects/project/segments/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        segment: this.get('store').findRecord('project-segment', params.project_segment_id, {
          include: 'actions'
        })
      });
    }

  });

  _exports.default = _default;
});