define("manage/pods/companies/company/projects/project/stats/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: this.store.findRecord('project', project.get('id'), {
          include: 'stats'
        }),
        // Getting orders for meta data
        orders: this.store.query('order', {
          filter: {
            project: project.get('id')
          },
          page: {
            number: 1,
            size: 1
          }
        })
      });
    }

  });

  _exports.default = _default;
});