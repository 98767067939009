define("manage/validators/json-valid", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const JsonValid = _base.default.extend({
    validate(value
    /*, options, model, attribute*/
    ) {
      if (Ember.isBlank(value)) {
        return true;
      }

      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return 'Invalid JSON';
      }
    }

  });

  JsonValid.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor() {
      return [];
    }

  });
  var _default = JsonValid;
  _exports.default = _default;
});