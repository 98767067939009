define("manage/pods/companies/company/projects/project/launch-guide/components/lg-sell-extras/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('checkSourceTask').perform();
    },

    store: Ember.inject.service(),
    completed: Ember.computed('product_extra_global.mappings.[]', 'source.settings.wizard.skip_extras', function () {
      if (this.get('source.settings.wizard.skip_extras') === true) {
        return true;
      }

      return this.get('product_extra_global.mappings.length') > 0;
    }),
    isRunning: Ember.computed.or('checkSourceTask.isRunning', 'setNoExtrasTask.isRunning'),
    checkSourceTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let sources = yield project.get('sources');
      let first_source = sources.sortBy('id').objectAt(0);

      if (Ember.isBlank(first_source)) {
        return;
      }

      let source = yield this.get('store').findRecord('project-source', first_source.get('id'), {
        include: ['product-extra-global'].join(',')
      });
      this.set('source', source);
      let product_extra_global = yield this.get('store').findRecord('product', source.get('product_extra_global.id'), {
        include: ['mappings'].join(',')
      });
      this.set('product_extra_global', product_extra_global);
    }),
    setNoExtrasTask: (0, _emberConcurrency.task)(function* () {
      let source = yield this.get('source');
      source.set('settings.wizard.skip_extras', true);
      yield source.save();
    }).drop()
  });

  _exports.default = _default;
});