define("manage/mixins/filters/sources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    source_options: [Ember.Object.create({
      id: 'kickstarter',
      title: 'Kickstarter'
    }), Ember.Object.create({
      id: 'indiegogo',
      title: 'Indiegogo'
    }), Ember.Object.create({
      id: 'crowdox',
      title: 'Crowd Ox'
    }), Ember.Object.create({
      id: 'other',
      title: 'Other'
    })]
  });

  _exports.default = _default;
});