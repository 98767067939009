define("manage/pods/companies/company/projects/project/apps/install/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.alias('model.project'),
    app_types: Ember.computed.alias('model.app_types'),
    apps: Ember.computed.alias('model.apps'),
    queryParams: ['page', 'page_size', 'sort', 'sort_direction', 'search'],
    // Paging
    page: 1,
    page_size: 25,
    // Sorting
    sort: 'app.name',
    sort_direction: 'asc',
    // Filters
    search: '',
    actions: {
      refresh() {
        this.send('refreshInstalledApps');
      }

    }
  });

  _exports.default = _default;
});