define("manage/pods/companies/company/projects/project/segments/components/export-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action", "manage/config/environment"], function (_exports, _ssModal, _segmentAction, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, {
    classNames: ['export-modal', 'segment-modal', 'component'],
    closable: false,
    type: 'export',
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    format: Ember.computed.or('format_type', 'export.format_type'),

    init() {
      this._super(...arguments);

      if (this.get('format_types') && this.get('format_types.length') === 1) {
        this.set('format_type', this.get('format_types').objectAt(0));
      }
    },

    actions: {
      perform(format_type) {
        this.set('submitted', true);

        if (this.get('actionTask.isRunning')) {
          return;
        }

        this.get('actionTask').perform({
          type: this.get('export.type'),
          format: format_type,
          mark_exported: false
        });
      },

      createExport() {
        this.get('action')(this.get('export.type'));
        this.closeModal();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      viewExports() {
        this.closeModal();
        this.get('router').transitionTo('companies.company.projects.project.orders.exports');
      }

    }
  });

  _exports.default = _default;
});