define("manage/pods/users/invitation/accept/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        // Clear state
        controller.set('password', null);
        controller.set('passwordConfirmation', null);
      }
    }

  });

  _exports.default = _default;
});