define("manage/helpers/get-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.generateMultiple = generateMultiple;
  _exports.generateSingle = generateSingle;
  _exports.getOptions = getOptions;
  _exports.getSelected = getSelected;

  function getOptions(collection) {
    if (Ember.isBlank(collection)) {
      return collection;
    }

    let options = [];

    for (let item of collection.toArray()) {
      if (Ember.isPresent(item.groupName)) {
        options.push(...item.options);
      } else {
        options.push(item);
      }
    }

    return options;
  }

  function generateMultiple(property, collection, key) {
    // Generate multiple
    if (Ember.isBlank(property)) {
      return [];
    }

    let values = (property || '').split(',');
    let collection_values = getOptions(collection);
    return collection_values.filter(c => values.indexOf((Ember.get(c, key) || '').toString()) >= 0);
  }

  function generateSingle(property, collection, key) {
    if (Ember.isBlank(property)) {
      return null;
    }

    let collection_values = getOptions(collection);
    return collection_values.find(c => property === (Ember.get(c, key) || '').toString());
  }

  function getSelected(_ref, options) {
    let [property, collection, key] = _ref;

    if (options.multiple === true) {
      return generateMultiple(property, collection, key);
    } else {
      return generateSingle(property, collection, key);
    }
  }

  var _default = Ember.Helper.helper(getSelected);

  _exports.default = _default;
});