define("manage/pods/companies/company/projects/project/theme/messages/edit/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locale: Ember.computed.readOnly('model.locale'),
    project: Ember.computed.readOnly('model.project'),
    orders: Ember.computed.readOnly('model.orders'),
    translation: Ember.computed.readOnly('model.translation'),
    submitted: false,
    current_user: Ember.inject.service('user'),
    saveTask: (0, _emberConcurrency.task)(function* (translation) {
      translation = yield translation;
      yield translation.save(); // After we save, it strips the empty portions of the hash.
      // We need to re-initialize them so we can set sub properties again.
      //
      // I'm sure there is a better solution to this, but ran into issues with fragments as well.

      translation.initializeEmptyProperties();
    }),
    // Gets set below to keep the info message window open before they've saved the translation or set a value
    rewards_info_message: false,
    extras_info_message: false,
    address_info_message: false,
    confirm_info_message: false,
    review_info_message: false,
    refuse_info_message: false,
    // Gets set below to keep the save message window open before they've saved the translation or set a value
    rewards_save_message: false,
    extras_save_message: false,
    address_save_message: false,
    confirm_save_message: false,
    reward_additional_replacements: {
      sub_header: 'textarea',
      additional_funds: 'text',
      any_configuration_locked: 'text',
      all_configurations_locked: 'text',
      original_configuration: 'text',
      upgrade_configuration: 'text',
      upgraded_configuration: 'text',
      select_configuration: 'text',
      selection_includes: 'text',
      selection_none: 'text',
      other_selection_includes: 'text',
      other_selection_includes_description: 'text',
      manual_selection_includes: 'text',
      manual_selection_includes_description: 'text',
      your_info: 'text',
      estimated_delivery: 'text',
      choices: 'text',
      other_choices: 'text',
      manual_choices: 'text',
      excess_funds: 'text',
      choose_action: 'text',
      save_action: 'text'
    },
    extras_additional_replacements: {
      sub_header: 'textarea',
      no_extras: 'text',
      next_action: 'text',
      excess_funds: 'text',
      sold_out: 'text',
      quantity: 'text',
      maximum_allowed: 'text',
      total_quantity_left: 'text',
      customer_quantity_max: 'text',
      customer_quantity_left: 'text',
      view_below: 'text',
      reward_selections: 'text',
      bundle_products: 'text',
      add_product: 'text',
      remove_product: 'text',
      save_action: 'text'
    },
    address_additional_replacements: {
      sub_header: 'textarea',
      first_name: 'text',
      middle_name: 'text',
      last_name: 'text',
      full_name: 'text',
      company_name: 'text',
      address: 'text',
      address_line_1: 'text',
      address_line_2: 'text',
      city: 'text',
      state: 'text',
      postal_code: 'text',
      phone_number: 'text',
      phone_number_required: 'text',
      phone_number_override: 'text',
      phone_number_missing: 'text',
      country_locked: 'text',
      country_completed: 'text',
      minimum_message: 'textarea',
      address_missing: 'text',
      address_override: 'text',
      save_action: 'text'
    },
    confirm_additional_replacements: {
      sub_header: 'textarea',
      pay_with_credit_card: 'text',
      credit_card_label: 'text',
      credit_card_name: 'text',
      save_action: 'text'
    },
    review_additional_replacements: {
      payment_details: 'text',
      review_experience: 'text'
    },
    summary_additional_replacements: {
      bundle: 'text',
      external: 'text',
      manual: 'text',
      extras: 'text',
      balance: 'text',
      sales_tax: 'text',
      value_added_tax: 'text',
      customs_duty: 'text'
    },
    phrase_additional_replacements: {
      country: 'text',
      country_limited: 'text',
      bundle: 'text',
      includes: 'text',
      click_thumbnails: 'text'
    },
    actions: {
      save() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform(this.get('translation'));
      },

      showMessage(section, type) {
        this.set(`${section}_${type}_message`, true);
      },

      setProperty(model, property, value) {
        // Setting all parents to be an empty hash if they are undefined
        property.split('.').slice(0, -1).map((_key, index) => property.split('.').slice(0, index + 1).join('.')).forEach(key => {
          if (model.get(key) == null) {
            model.set(key, {});
          }
        });
        model.set(property, value);
      },

      setTranslationProperty(property, value) {
        this.send('setProperty', this.get('translation'), property, value);
      },

      copyPlaceholder(event) {
        let input = event.target;

        if (Ember.isPresent(input.value)) {
          return;
        }

        input.value = input.placeholder;
      },

      revertPlaceholder(event) {
        let input = event.target;

        if (input.value === input.placeholder) {
          input.value = null;
        }
      }

    }
  });

  _exports.default = _default;
});