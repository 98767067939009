define("manage/pods/companies/company/projects/project/launch-guide/components/lg-add-questions/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('loadProductInfo').perform();
    },

    store: Ember.inject.service(),
    completed: Ember.computed('products.[]', 'project.settings.wizard.skip_questions', function () {
      if (this.get('project.settings.wizard.skip_questions') === true) {
        return true;
      }

      return this.get('products.length') > 0;
    }),
    products: null,
    text_questions: Ember.computed.filterBy('products', 'question_type', 'text'),
    multiple_questions: Ember.computed.filterBy('products', 'question_type', 'multiple'),
    isRunning: Ember.computed.or('loadProductInfo.isRunning', 'skipQuestionsTask.isRunning'),
    loadProductInfo: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let products = yield this.get('store').query('product', {
        filter: {
          project: project.get('id'),
          product_type: ['question']
        },
        include: ['questions'].join(','),
        page: {
          number: 1,
          size: 50
        }
      });
      this.set('products', products);
    }),
    skipQuestionsTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      project.set('settings.wizard.skip_questions', true);
      yield project.save();
    }).drop()
  });

  _exports.default = _default;
});