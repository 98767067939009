define("manage/pods/companies/company/projects/project/digital/index/components/column-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6WL1OqlC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ui label mini status-label \",[28,\"get\",[[24,[\"classForStatusLabel\"]],[24,[\"fulfillment\",\"status\"]]],null]]]],[8],[0,\"\\n  \"],[1,[28,\"t\",[[28,\"concat\",[\"models.digital-fulfillment.status.title.\",[24,[\"fulfillment\",\"status\"]]],null]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/digital/index/components/column-status/template.hbs"
    }
  });

  _exports.default = _default;
});