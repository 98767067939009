define("manage/pods/companies/company/projects/project/questions/index/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    product_questions: Ember.computed.readOnly('model'),
    productTasks: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    question_type: null,
    question_types: [Ember.Object.create({
      id: "text"
    }), Ember.Object.create({
      id: "multiple"
    })],
    filtered_product_questions: Ember.computed('product_questions.@each.{isDeleted,question_type}', 'question_type', function () {
      let product_questions = this.get('product_questions').filterBy('isDeleted', false);

      if (Ember.isBlank(this.get('question_type'))) {
        return product_questions;
      }

      return product_questions.filterBy('question_type', this.get('question_type'));
    }),
    // Paging
    page: 1,
    page_size: _environment.default['max-page-size'],
    // Sorting
    sortProperty: 'id',
    sortDirection: 'asc',
    sorted_by: ['id:asc'],
    sorted_product_questions: Ember.computed.sort('filtered_product_questions', 'sorted_by'),
    actions: {
      sort(name, direction) {
        if (this.get('sortProperty') !== name) {
          this.set('sortProperty', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortProperty', name);
          this.set('sortDirection', direction);
        }

        return this.set('sorted_by', [`${this.get('sortProperty')}:${this.get('sortDirection')}`]);
      },

      create_question(type) {
        Ember.run.scheduleOnce('afterRender', this, this.transitionToRoute, 'companies.company.projects.project.questions.create', {
          queryParams: {
            type: type
          }
        });
      },

      delete_question(product, question) {
        if (this.get('productTasks.delete.isRunning')) {
          return;
        }

        this.send('open_modal', 'delete-confirmation-modal', {
          entity: product,
          i18nKey: 'delete-product',
          i18nParams: {
            name: question.get('text'),
            nameIsBlank: Ember.isBlank(question.get('text')),
            isQuestion: true
          },
          approve: () => {
            return this.get('productTasks.delete').perform(product);
          }
        });
      }

    }
  });

  _exports.default = _default;
});