define("manage/pods/companies/company/projects/project/orders/view/components/order-line-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/jBo+/wx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"current_user\",\"is_admin\"]],[24,[\"can_change\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[12,\"class\",\"ui mini label admin\"],[3,\"action\",[[23,0,[]],\"change\"]],[8],[0,\"Change\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"current_user\",\"is_admin\"]],[24,[\"can_edit\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[12,\"class\",\"ui mini label admin\"],[3,\"action\",[[23,0,[]],\"edit\"]],[8],[1,[28,\"t\",[\"phrase.edit\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"current_user\",\"is_admin\"]],[24,[\"can_remove\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",\"javascript:\"],[12,\"class\",\"ui mini label admin\"],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[1,[28,\"t\",[\"phrase.remove\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/order-line-extension/template.hbs"
    }
  });

  _exports.default = _default;
});