define("manage/components/page-banner/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['page', 'banner', 'component'],
    sibling: '.inner.layout',

    didInsertElement() {
      this._super(...arguments);

      this.set('offsetFromTop', (0, _jquery.default)(this.element).offset().top);
      (0, _jquery.default)(window).on('scroll.' + this.get('elementId'), Ember.run.bind(this, this.checkFixed));
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(window).off('.' + this.get('elementId'));
    },

    checkFixed() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if ((0, _jquery.default)(window).scrollTop() > this.get('offsetFromTop')) {
        if ((0, _jquery.default)(this.element).hasClass('fixed-header')) {
          return;
        }

        let outerheight = (0, _jquery.default)(this.element).outerHeight();
        (0, _jquery.default)(this.element).addClass('fixed-header');

        if (Ember.isPresent(this.get('sibling'))) {
          let sibling = (0, _jquery.default)(this.element).siblings(this.get('sibling'));
          sibling.css('margin-top', outerheight + 'px');
        }
      } else {
        if (!(0, _jquery.default)(this.element).hasClass('fixed-header')) {
          return;
        }

        (0, _jquery.default)(this.element).removeClass('fixed-header');

        if (Ember.isPresent(this.get('sibling'))) {
          let sibling = (0, _jquery.default)(this.element).siblings(this.get('sibling'));
          sibling.css('margin-top', '0px');
        }
      }
    }

  });

  _exports.default = _default;
});