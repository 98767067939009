define("manage/pods/companies/company/projects/project/launch-guide/components/lg-review-imported-addons/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency", "manage/config/environment"], function (_exports, _launchGuideComponent, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('loadProductInfo').perform();
    },

    store: Ember.inject.service(),
    completed: Ember.computed('products.[]', function () {
      return this.get('products.length') == 0;
    }),
    products: null,
    loadProductInfo: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let products = yield this.get('store').query('product', {
        filter: {
          project: project.get('id'),
          product_type: ['imported_addons_to_review']
        },
        page: {
          number: 1,
          size: _environment.default['max-page-size']
        }
      });
      this.set('products', products);
    })
  });

  _exports.default = _default;
});