define("manage/utils/model-definitions", ["exports", "ember-cli-uncharted-describe-models/utils/model-definitions"], function (_exports, _modelDefinitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modelDefinitions.default;
    }
  });
  Object.defineProperty(_exports, "loadDefinitions", {
    enumerable: true,
    get: function () {
      return _modelDefinitions.loadDefinitions;
    }
  });
  Object.defineProperty(_exports, "loadModelDefinitions", {
    enumerable: true,
    get: function () {
      return _modelDefinitions.loadModelDefinitions;
    }
  });
});