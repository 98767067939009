define("manage/mixins/models/project/theme", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    hero_background_offset: {
      description: "Hero Background Offset",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true
      })]
    },
    hero_background_mobile_offset: {
      description: "Hero Background Mobile Offset",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true
      })]
    },
    hero_background_tablet_offset: {
      description: "Hero Background Tablet Offset",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true
      })]
    },
    extras_message_replace: {
      description: "Extras Message Replace",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        allowBlank: true,
        in: [true, false]
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    has_custom_image: Ember.computed('logo_image_path', 'hero_image_path', 'project_name_image_path', function () {
      return Ember.isPresent(this.get('logo_image_path')) || Ember.isPresent(this.get('hero_image_path')) || Ember.isPresent(this.get('project_name_image_path'));
    }),
    has_custom_colors: Ember.computed('hero_background_color', 'branding_background_color', 'branding_border_color', 'header_text_color', 'header_subtext_color', 'stats_background_color', 'stats_text_color', 'stats_subtext_color', 'link_text_color', 'navigation_inactive_background_color', 'navigation_previous_background_color', 'navigation_active_background_color', 'navigation_divider_color', 'navigation_text_color', 'navigation_text_inactive_color', 'navigation_text_completed_color', function () {
      return Ember.isPresent(this.get('hero_background_color')) || Ember.isPresent(this.get('branding_background_color')) || Ember.isPresent(this.get('branding_border_color')) || Ember.isPresent(this.get('header_text_color')) || Ember.isPresent(this.get('header_subtext_color')) || Ember.isPresent(this.get('stats_background_color')) || Ember.isPresent(this.get('stats_text_color')) || Ember.isPresent(this.get('stats_subtext_color')) || Ember.isPresent(this.get('link_text_color')) || Ember.isPresent(this.get('navigation_inactive_background_color')) || Ember.isPresent(this.get('navigation_previous_background_color')) || Ember.isPresent(this.get('navigation_active_background_color')) || Ember.isPresent(this.get('navigation_divider_color')) || Ember.isPresent(this.get('navigation_text_color')) || Ember.isPresent(this.get('navigation_text_inactive_color')) || Ember.isPresent(this.get('navigation_text_completed_color'));
    }),
    is_customized: Ember.computed.or('has_custom_image', 'has_custom_colors'),

    copy(copy_from_theme) {
      this.setProperties({
        name: copy_from_theme.get('name'),
        tagline: copy_from_theme.get('tagline'),
        logo_image_path: copy_from_theme.get('logo_image_path'),
        hero_image_path: copy_from_theme.get('hero_image_path'),
        project_name_image_path: copy_from_theme.get('project_name_image_path'),
        hero_background_offset: copy_from_theme.get('hero_background_offset'),
        hero_background_mobile_offset: copy_from_theme.get('hero_background_mobile_offset'),
        hero_background_tablet_offset: copy_from_theme.get('hero_background_tablet_offset'),
        hero_background_size: copy_from_theme.get('hero_background_size'),
        hero_background_color: copy_from_theme.get('hero_background_color'),
        branding_background_color: copy_from_theme.get('branding_background_color'),
        branding_border_color: copy_from_theme.get('branding_border_color'),
        header_text_color: copy_from_theme.get('header_text_color'),
        header_subtext_color: copy_from_theme.get('header_subtext_color')
      });
    }

  });

  _exports.default = _default;
});