define("manage/mixins/filters/locking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    locking_options: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'export',
        title: 'Export'
      }), Ember.Object.create({
        id: 'ship',
        title: 'Shipping'
      }), Ember.Object.create({
        id: 'manual',
        title: 'Manual'
      }), Ember.Object.create({
        id: 'digital',
        title: 'Digital'
      }), Ember.Object.create({
        id: 'limited',
        title: 'Limited'
      })];
    }),
    locking_exporting_options: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'true',
        title: 'Exported'
      }), Ember.Object.create({
        id: 'false',
        title: 'Not Exported'
      })];
    })
  });

  _exports.default = _default;
});