define("manage/models/product", ["exports", "ember-data", "shared/utils/fallback-sort", "ember-concurrency", "manage/utils/generated-model", "shared/utils/image", "shared/utils/monetize"], function (_exports, _emberData, _fallbackSort, _emberConcurrency, _generatedModel, _image, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('product').extend({
    // Main product type checks
    is_physical: Ember.computed('product_type', function () {
      return this.get('product_type') === 'physical' || this.get('product_type') === 'shipping';
    }),
    is_digital: Ember.computed.equal('product_type', 'digital'),
    is_question: Ember.computed.equal('product_type', 'question'),
    is_bundle: Ember.computed.equal('product_type', 'bundle'),
    is_option: Ember.computed.equal('product_type', 'option'),
    is_mapping: Ember.computed.equal('product_type', 'mapping'),
    is_section: Ember.computed.equal('product_type', 'section'),
    is_shipping: Ember.computed.equal('product_type', 'shipping'),
    is_unknown: Ember.computed.equal('product_type', 'unknown'),
    shipping_is_price_based: Ember.computed.equal('shipping_type', 'product_price'),
    shipping_is_weight_based: Ember.computed.equal('shipping_type', 'product_weight'),
    has_questions: Ember.computed.gt('questions.length', 0),
    preliminary_variation: Ember.computed('variations.@each.has_variants', function () {
      return (this.get('variations') || Ember.A()).findBy('has_variants', false);
    }),
    // Only use this to check if we need to configure the product, because we could have a "variation" but its the preliminary_variation
    has_variations: Ember.computed('variations.@each.has_variants', function () {
      return (this.get('variations') || Ember.A()).isAny('has_variants', true);
    }),
    has_enabled_mappings: Ember.computed('mappings.@each.enabled', function () {
      return this.get('mappings').any(mapping => mapping.get('enabled'));
    }),
    has_choices: Ember.computed('has_questions', 'has_variations', 'is_option', function () {
      return this.get('has_questions') || this.get('has_variations') || this.get('is_option');
    }),
    shipping_product_based_zones: Ember.computed.filterBy('zones', 'is_shipping_product_based', true),
    extra_cap_zones: Ember.computed.filterBy('zones', 'is_extra_cap', true),
    shipping_product_based_zone_locations: Ember.computed.filterBy('zone_locations', 'is_shipping_product_based', true),
    extra_cap_zone_locations: Ember.computed.filterBy('zone_locations', 'is_extra_cap', true),
    variants_sorted: (0, _fallbackSort.default)('variants', 'name', 'id'),
    questions_sorted: (0, _fallbackSort.default)('questions', 'text', 'id'),
    mappings_sorted: (0, _fallbackSort.default)('mappings', 'price', 'id'),
    // Trait properties
    image_src: (0, _image.default)('image_path'),
    gallery_image_srcs: Ember.computed('gallery_image_paths.[]', function () {
      return (this.get('gallery_image_paths') || Ember.A()).map(image_path => (0, _image.htmlSafeImageSrc)(this, image_path));
    }),
    currency: Ember.computed.readOnly('project.currency'),
    price: (0, _monetize.default)('price_cents', 'currency'),
    inventory_available_quantity: Ember.computed('inventory_total_quantity', 'stats.selected', function () {
      if (this.get('inventory_total_quantity')) {
        return this.get('inventory_total_quantity') - this.get('stats.selected');
      }

      return null;
    }),
    max_available: Ember.computed('inventory_available_quantity', 'order_max_quantity', function () {
      let inventory_available_quantity = this.get('inventory_available_quantity');
      let order_max_quantity = this.get('order_max_quantity');

      if (inventory_available_quantity != null && order_max_quantity != null) {
        return Math.min(inventory_available_quantity, order_max_quantity);
      }

      if (inventory_available_quantity != null) {
        return inventory_available_quantity;
      }

      if (order_max_quantity != null) {
        return order_max_quantity;
      }

      return null;
    }),
    generateVariantsByVariantValue: (0, _emberConcurrency.task)(function* () {
      let items = Ember.A();
      let product_variations = yield this.get('variations');

      for (let product_variation of product_variations.toArray()) {
        for (let product_detail of product_variation.get('details').toArray()) {
          let product_variant_value_id = yield product_detail.get('variant_value.id');
          let group = items.findBy('id', product_variant_value_id);

          if (Ember.isBlank(group)) {
            group = {
              id: product_variant_value_id,
              variations: Ember.A()
            };
            items.push(group);
          }

          group.variations.push(product_variation);
        }
      }

      return items;
    }).restartable(),
    variations_by_variant_value: Ember.computed('variations.@each.details', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get('generateVariantsByVariantValue').perform()
      });
    })
  });

  _exports.default = _default;
});