define("manage/mirage/factories/user", ["exports", "ember-cli-mirage", "ember-simple-auth/test-support"], function (_exports, _emberCliMirage, _testSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    email: 'creator@company.com',
    password: 'abcdefg',

    afterCreate(user, server) {
      let company = user.company;

      if (!company) {
        company = server.create('company', {
          users: [user]
        });
        user.update({
          company
        });
      } else {
        // Push the new user's ID onto the company's links for users
        let companyUsers = company.users.models || [];
        companyUsers.push(user);
        company.update({
          users: companyUsers
        });
      }

      company = server.schema.companies.find(company.id);
      user.update({
        companies: [company]
      });
      company.createCompaniesUser({
        user
      });
    },

    admin: (0, _emberCliMirage.trait)({
      role: 'admin'
    }),
    creator: (0, _emberCliMirage.trait)({
      role: 'creator'
    }),
    owner: (0, _emberCliMirage.trait)({
      role: 'creator',

      afterCreate(user, server) {
        server.db.companiesUsers.forEach(companiesUser => {
          if (companiesUser.userId == user.id && companiesUser.companyId == user.company.id) {
            server.db.companiesUsers.update(companiesUser.id, {
              level: 'owner'
            });
          }
        });
      }

    }),
    authenticate: (0, _emberCliMirage.trait)({
      afterCreate(user
      /* server */
      ) {
        (0, _testSupport.authenticateSession)({
          user_id: user.id,
          user_role: user.role
        });
      }

    })
  });

  _exports.default = _default;
});