define("manage/models/project-source-settings-email", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    claim_rewards_ongoing: {
      description: "Claim Rewards Ongoing",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        allowBlank: false,
        allowNone: false,
        in: [true, false]
      })]
    },
    claim_rewards_ongoing_days_between: {
      description: "Claim Rewards Ongoing Days Between",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: false,
        allowNone: false,
        integer: true,
        gte: 0
      })]
    },
    claim_rewards_ongoing_attempts: {
      description: "Claim Rewards Ongoing Attempts",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: true,
        allowNone: true,
        integer: true,
        gte: 0
      })]
    },
    recover_rewards: {
      description: "Recover Rewards",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        allowBlank: false,
        allowNone: false,
        in: [true, false]
      })]
    },
    recover_rewards_ongoing_days_between: {
      description: "Recover Rewards Ongoing Days Between",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: false,
        allowNone: false,
        integer: true,
        gte: 1
      })]
    },
    recover_rewards_attempts: {
      description: "Recover Rewards Attempts",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: false,
        allowNone: false,
        integer: true,
        gte: 1
      })]
    },
    pre_order_recovery_ongoing: {
      description: "Pre-Order Recovery Ongoing",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        allowBlank: false,
        allowNone: false,
        in: [true, false]
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    claim_rewards_ongoing: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    claim_rewards_ongoing_days_between: (0, _model.attr)('number'),
    claim_rewards_ongoing_attempts: (0, _model.attr)('number'),
    recover_rewards: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    recover_rewards_ongoing_days_between: (0, _model.attr)('number'),
    recover_rewards_attempts: (0, _model.attr)('number'),
    pre_order_recovery_ongoing: (0, _model.attr)('boolean', {
      allowNull: true
    })
  });

  _exports.default = _default;
});