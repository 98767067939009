define("manage/pods/companies/company/route", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),

    model(params) {
      return this.store.findRecord('company', params.company_id);
    },

    redirect(model, transition) {
      if (model.get('enabled') === false && this.get('current_user.is_admin') === false) {
        this.replaceWith('companies.company.disabled');
        return;
      }

      if (transition.targetName === 'companies.company' || transition.targetName === 'companies.company.index') {
        this.replaceWith('companies.company.projects');
        return;
      }
    },

    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('companies/company', {
        into: 'shared/main'
      });
      this.render('companies/company/menu', {
        into: 'shared/main',
        outlet: 'menu'
      });
    },

    actions: {
      error(error
      /*, transition*/
      ) {
        if (error instanceof _error.NotFoundError) {
          this.replaceWith('not-found');
        } else {
          return true;
        }
      }

    }
  });

  _exports.default = _default;
});