define("manage/pods/companies/company/projects/project/launch-guide/components/lg-project-created/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RPxbZKqh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"data-section\",\"step\"],[11,\"class\",[29,[\"step \",[28,\"if\",[[24,[\"completed\"]],\"completed\"],null]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"before\"],[8],[9],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"circular icon\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"after\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"data-section\",\"description\"],[10,\"class\",\"description\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui tiny header\"],[8],[0,\"\\n    Your project \"],[7,\"em\",true],[8],[1,[24,[\"project\",\"name\"]],false],[9],[0,\" has been created\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"data-section\",\"action\"],[10,\"class\",\"action\"],[8],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/launch-guide/components/lg-project-created/template.hbs"
    }
  });

  _exports.default = _default;
});