define("manage/pods/companies/company/projects/project/components/edit-location-zone-vat-category/component", ["exports", "manage/utils/monetize"], function (_exports, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    zone: null,
    // set in template
    vat_category: null,
    // set in template
    placeholder: "Enter Percent 0 - 100",
    project: Ember.computed.readOnly('zone.project'),
    tagName: "",
    percent: Ember.computed('vat_category.public_id', 'zone.vat.prices', {
      get() {
        let public_id = this.get('vat_category.public_id');
        return this.get(`zone.vat.prices.${public_id}`);
      },

      set(_key, value) {
        let public_id = this.get('vat_category.public_id'); // We have to replace the entire prices object so that ember model fragments properly tracks it.
        // If we don't, it won't mark as dirty properly

        this.set('zone.vat.prices', Object.assign({}, this.get('zone.vat.prices'), {
          [public_id]: value
        }));
        return this.get(`zone.vat.prices.${public_id}`);
      }

    }),
    percent_decimal: (0, _monetize.default)('percent', null, 100)
  });

  _exports.default = _default;
});