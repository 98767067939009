define("manage/mixins/order-exports", ["exports", "ember-cli-string-helpers/helpers/humanize", "ember-cli-string-helpers/helpers/titleize"], function (_exports, _humanize, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exportDescriptions = _exports.default = void 0;
  let exportDescriptions = {
    amplifier: {
      format_types: ['csv', 'excel'],
      url: 'https://www.amplifier.com/',
      capability: ['order-date', 'name-and-email', 'address', 'product-titles', 'product-skus'],
      incapability: ['source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
    },
    blackbox: {
      format_types: ['excel'],
      url: 'https://www.blackbox.cool/',
      capability: ['name-and-email', 'address', 'product-skus'],
      incapability: ['order-date', 'product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
    },
    bonver: {
      format_types: ['xml'],
      url: 'https://www.bonver.se/',
      capability: ['name-and-email', 'address', 'product-skus'],
      incapability: ['order-date', 'product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
    },
    crosstab: {
      format_types: ['excel'],
      capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'product-skus', 'answer-one'],
      incapability: ['product-titles', 'answers-multiple', 'product-unit-price'],
      options: [{
        name: 'with_question',
        type: 'checkbox'
      }]
    },
    crowdox: {
      format_types: ['csv', 'excel'],
      capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'line-type', 'product-titles', 'product-skus', 'product-questions', 'product-weight', 'product-dimensions'],
      incapability: ['product-unit-price']
    },
    collext: {
      format_types: ['csv', 'excel'],
      url: 'https://www.collext.com/',
      capability: [],
      incapability: []
    },
    easy_ship: {
      format_types: ['csv'],
      url: 'https://www.easyship.com/',
      capability: ['name-and-email', 'address', 'phone-number', 'product-skus'],
      incapability: ['order-date', 'product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
    },
    emails: {
      format_types: ['excel'],
      capability: ['order-number', 'manage-url', 'sent-on', 'email-state', 'to-name', 'to-email', 'from-name', 'from-email', 'subject', 'email-text']
    },
    floship: {
      format_types: ['excel'],
      url: 'https://www.floship.com/',
      capability: ['name-and-email', 'address', 'product-skus'],
      incapability: ['order-date', 'notes', 'source', 'amount-paid', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles', 'product-unit-price']
    },
    fulfillment_by_amazon: {
      format_types: ['csv'],
      url: 'https://services.amazon.com/fulfillment-by-amazon'
    },
    funagain_logistics: {
      format_types: ['csv'],
      url: 'https://funagain.com/pages/logistics',
      capability: ['name-and-email', 'address', 'product-skus', 'product-titles', 'product-weight'],
      incapability: ['source', 'order-date', 'amount-paid', 'notes', 'answers-product', 'product-question-text', 'product-unit-price']
    },
    james_and_james: {
      format_types: ['csv'],
      url: 'https://www.ecommercefulfilment.com/',
      capability: ['order-date', 'name-and-email', 'address', 'product-skus'],
      incapability: ['source', 'order-date', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles']
    },
    order_invoice: {
      format_types: ['pdf'],
      capability: ['order-number', 'address', 'phone-number', 'product-image', 'product-name', 'sku', 'quantity', 'price']
    },
    order_picklist: {
      format_types: ['pdf'],
      capability: ['order-number', 'address', 'phone-number', 'payment-amount', 'product-name', 'sku', 'quantity'],
      incapability: ['product-price']
    },
    quartermaster: {
      format_types: ['excel'],
      url: 'https://www.qmlogistics.com/',
      capability: ['order-date', 'name-and-email', 'address', 'phone-number', 'product-titles', 'product-skus'],
      incapability: ['source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
    },
    questions: {
      format_types: ['csv'],
      capability: ['order-number', 'order-source-number', 'email', 'full-name', 'state', 'country-name', 'product-name', 'sku', 'question-text', 'answer']
    },
    send_from_china: {
      format_types: ['csv', 'excel']
    },
    ship_3pl: {
      format_types: ['csv'],
      url: 'http://3plcentral.com/',
      capability: ['name-and-email', 'order-date', 'notes', 'address', 'product-skus'],
      incapability: ['source', 'amount-paid', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles']
    },
    ship_bob: {
      format_types: ['excel'],
      url: 'https://www.shipbob.com/',
      capability: ['name-and-email', 'address', 'product-skus'],
      incapability: ['source', 'order-date', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles']
    },
    ship_station: {
      format_types: ['csv'],
      url: 'https://www.shipstation.com/',
      capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'product-titles', 'product-skus', 'answers-product'],
      incapability: ['product-question-text', 'product-unit-price']
    },
    shipwire: {
      format_types: ['csv'],
      url: 'https://www.shipwire.com/',
      capability: ['order-date', 'name-and-email', 'address', 'product-skus'],
      incapability: ['product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
    },
    stamps: {
      format_types: ['csv'],
      capability: [],
      incapability: []
    },
    summary: {
      format_types: ['csv', 'excel'],
      capability: ['order-number', 'order-source', 'status', 'invite-approved-canceled-date', 'manage-url', 'survey-url', 'full-name', 'email', 'phone-number', 'address', 'amount-paid-and-balance', 'notes']
    },
    summary_legacy: {
      format_types: ['csv', 'excel'],
      capability: ['order-number', 'order-source', 'status', 'invite-approved-canceled-date', 'manage-url', 'survey-url', 'full-name', 'email', 'phone-number', 'address', 'amount-paid-and-balance', 'notes']
    },
    transactions_by_order: {
      format_types: ['csv'],
      capability: ['order-number', 'order-source', 'order-status', 'full-name', 'email', 'country-name', 'country-iso3', 'state', 'payment-amount', 'fee-amount', 'shipping-amount'],
      incapability: ['payment-date']
    },
    transactions_by_order_and_date: {
      format_types: ['csv'],
      capability: ['order-number', 'order-source', 'order-status', 'full-name', 'email', 'country-name', 'country-iso3', 'state', 'payment-date', 'payment-amount', 'fee-amount', 'shipping-amount']
    },
    whiplash: {
      format_types: ['csv'],
      url: 'https://www.whiplashmerch.com/',
      capability: ['name-and-email', 'address', 'product-skus', 'answer-one'],
      incapability: ['order-date', 'notes', 'source', 'amount-paid', 'product-unit-price', 'answers-multiple', 'product-question-text', 'product-titles', 'product-unit-price']
    }
  };
  _exports.exportDescriptions = exportDescriptions;

  var _default = Ember.Mixin.create({
    exports: Ember.computed('project.export_type', 'intl.locale', function () {
      var intl = Ember.getOwner(this).lookup('service:intl');
      var exports = {};

      for (var type in exportDescriptions) {
        var e = Ember.Object.create(exportDescriptions[type]);
        e.type = type; // If the alt-name attribute exists, use that one here.
        // Otherwise use the standard name attribute.

        if (intl.exists(`order-exports.${type}.alt-name`)) {
          e.name = intl.t(`order-exports.${type}.alt-name`);
        } else {
          e.name = intl.t(`order-exports.${type}.name`);
        }

        e.description = intl.t(`order-exports.${type}.description`);

        if (intl.exists(`order-exports.${type}.warning`)) {
          e.warning = intl.t(`order-exports.${type}.warning`);
        }

        if (e.capability) {
          e.capability = e.capability.map(key => intl.t(`order-exports.capabilities.${key}`));
        }

        if (e.incapability) {
          e.incapability = e.incapability.map(key => intl.t(`order-exports.capabilities.${key}`));
        }

        exports[type] = e;
      }

      if (this.get('project.export_type') != null) {
        exports[this.get('project.export_type')] = Ember.Object.create({
          type: this.get('project.export_type'),
          format_types: ['csv', 'excel'],
          name: (0, _titleize.titleize)([(0, _humanize.humanize)([this.get('project.export_type')])])
        });
      }

      return exports;
    })
  });

  _exports.default = _default;
});