define("manage/mirage/fixtures/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 17,
    name: "Art"
  }, {
    id: 22,
    name: "Games"
  }, {
    id: 23,
    name: "Accessories",
    parent_category_id: 22
  }, {
    id: 24,
    name: "Board Games",
    description: "Anything with a board",
    parent_category_id: 22
  }];
  _exports.default = _default;
});