define("manage/utils/write-html", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name, content) {
    var iframe = (0, _jquery.default)(`#${name}`, this.element);

    if (iframe.length === 0) {
      return;
    }

    iframe = iframe[0];
    iframe.contentWindow.document.open('text/html', 'replace');

    if (name === 'text') {
      iframe.contentWindow.document.write('<pre>' + Ember.Handlebars.Utils.escapeExpression(content) + '</pre>');
    } else {
      iframe.contentWindow.document.write(content);
    }

    iframe.contentWindow.document.close();
  }
});