define("manage/pods/companies/company/projects/project/orders/view/components/view-product-key-row/component", ["exports", "manage/mixins/copy-text-actions"], function (_exports, _copyTextActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_copyTextActions.default, {
    tagName: 'tr',
    keyCopyMessage: 'Click to copy key',
    claimUrlCopyMessage: 'Click to copy claim URL'
  });

  _exports.default = _default;
});