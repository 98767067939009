define("manage/pods/companies/company/projects/project/orders/groups/route", ["exports", "manage/pods/companies/company/projects/project/orders/groups/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      // Location
      shipping_country: {
        refreshModel: true
      },
      // Order
      source: {
        refreshModel: true
      },
      step: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      address: {
        refreshModel: true
      },
      // Selections
      configuration: {
        refreshModel: true
      },
      product_variation: {
        refreshModel: true
      },
      product_type: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        project_fulfillment_groups: this.search(project, params)
      });
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size'); // Location

      let shipping_country = params.shipping_country; // Order

      let source = params.source;
      let step = controller.get('step');
      let status = controller.get('status'); // Selections

      let configuration = params.configuration;
      let product_variation = params.product_variation;
      let product_type = params.product_type;
      return this.store.query('fulfillment-group-project', {
        filter: {
          project: project.get('id'),
          // Filters
          // Location
          shipping_country: shipping_country,
          // Order
          site: source,
          step: step,
          status: status,
          // Selections
          configuration: configuration,
          product_variation: product_variation,
          product_type: product_type
        },
        page: {
          number: page,
          size: pageSize
        }
      });
    }

  });

  _exports.default = _default;
});