define("manage/pods/companies/company/projects/project/sources/configurations/index/components/column-email/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      email(configuration) {
        this.send('open_modal', 'companies/company/projects/project/sources/configurations/email-customers-modal', {
          configuration: configuration
        });
      }

    }
  });

  _exports.default = _default;
});