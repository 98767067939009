define("manage/pods/companies/company/account/components/invite-user-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _ssModal, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    closable: false,

    closing() {
      var record = this.get('user');

      if (record && record.get('isNew')) {
        record.rollbackAttributes();
      }
    },

    saveUserTask: (0, _emberConcurrency.task)(function* (user, company) {
      user = yield user;
      company = yield company;
      let {
        validations: validations
      } = yield user.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      yield user.save();
      yield this.get('store').findRecord('company', company.get('id'), {
        include: ['projects', 'users'].join(',')
      });
    }),
    actions: {
      approve() {
        if (this.get('saveUserTask.isRunning')) {
          return;
        }

        this.set('submitted', true);
        let user = this.get('user');
        let company = this.get('company');
        this.get('saveUserTask').perform(user, company).then(() => this.closeModal()).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to invite user."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});