define("manage/pods/companies/company/projects/project/stats/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    project: Ember.computed.readOnly('model.project'),
    orders: Ember.computed.readOnly('model.orders'),
    // If project is live, or we have more than 20 orders, show data based on orders
    show_order_statistics: Ember.computed('project.{is_live,is_complete,company.demo}', 'orders.meta.record-count', function () {
      if (this.get('project.company.demo') === true) {
        return true;
      } // If we are live, just use real data


      if (this.get('project.is_live') || this.get('project.is_complete')) {
        return true;
      }

      if (this.get('orders.meta.record-count') >= 20) {
        return true;
      }

      return false;
    }),
    chart_doughnut_options: Ember.computed(function () {
      return {
        defaultFontFamily: 'proxima-nova',
        // Doughnut options
        cutoutPercentage: 70,
        legend: {
          display: false
        }
      };
    }),
    chart_line_options: Ember.computed(function () {
      return {
        defaultFontFamily: 'proxima-nova',
        legend: {
          display: false
        },
        tooltips: {
          mode: 'point'
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawTicks: false
            },
            ticks: {
              min: 0
            }
          }]
        }
      };
    }),
    current_year: Ember.computed(function () {
      return new Date().getFullYear();
    })
  });

  _exports.default = _default;
});