define("manage/models/project-segment-order", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-segment-order').reopen({});

  _exports.default = _default;
});