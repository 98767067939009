define("manage/pods/companies/company/projects/project/shipping/customs/route", ["exports", "manage/mixins/location-route"], function (_exports, _locationRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend((0, _locationRoute.default)('Customs Duty'), {});

  _exports.default = _default;
});