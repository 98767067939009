define("manage/pods/confirm/stripe/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['scope', 'code', 'state']
  });

  _exports.default = _default;
});