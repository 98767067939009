define("manage/pods/companies/company/projects/project/settings/emails/edit/controller", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/helpers/has-installed-app"], function (_exports, _emberConcurrency, _catchReal, _hasInstalledApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    project: Ember.computed.alias('model.project'),
    email: Ember.computed.alias('model.email'),
    submitted: false,

    get variables() {
      var liquidVars = {
        project: {
          name: '',
          support_email: '',
          twitter: '',
          homepage: ''
        },
        source: {
          name: '',
          url: '',
          homepage_url: ''
        },
        configuration: {
          name: '',
          description: '',
          price: ''
        },
        user: {
          full_name: '',
          email: ''
        },
        order: {
          survey_url: '',
          shipping_required: '',
          paid: '',
          balance: ''
        }
      };

      if ((0, _hasInstalledApp.hasInstalledApp)(this.get('project'), 'shipment-tracking')) {
        liquidVars['shipment'] = {
          shipping_method: '',
          tracking_number: '',
          tracking_url: ''
        };
      }

      return liquidVars;
    },

    tags: {
      bold: '',
      italics: '',
      button: '',
      header: '',
      link: '',
      p: ''
    },
    filters: {
      bold: '',
      italics: '',
      button: '',
      link: '',
      mail_to: ''
    },
    isRunning: Ember.computed.or('previewTask.isRunning', 'testTask.isRunning', 'saveTask.isRunning'),
    previewTask: (0, _emberConcurrency.task)(function* (order_id) {
      let email = yield this.get('email');
      let {
        validations: validations
      } = yield email.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      let data = yield this.get('ajax').postModel(this.get('project'), 'preview_email', {
        data: {
          order_id: order_id,
          project_email_id: email.get('id'),
          subject: email.get('subject'),
          body: email.get('body')
        }
      });
      this.send('open_modal', 'companies/company/projects/project/view-email-modal', {
        email: Ember.Object.create(data),
        email_type: email.get('name')
      });
    }),
    testTask: (0, _emberConcurrency.task)(function* (order_id) {
      yield this.get('ajax').postModel(this.get('project'), 'test_email', {
        data: {
          order_id: order_id,
          project_email_id: this.get('email.id'),
          subject: this.get('email.subject'),
          body: this.get('email.body')
        }
      });
      this.send('open_modal', 'confirm-modal', {
        i18nKey: 'test-email-sent',
        approve_label: "Ok"
      });
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('email').save();
    }),
    actions: {
      preview_email(order_id) {
        if (this.get('previewTask.isRunning')) {
          return;
        }

        this.set('submitted', true);
        this.get('previewTask').perform(order_id).catch(xhr => {
          let msg = xhr.payload && xhr.payload.error && xhr.payload.error.message ? xhr.payload.error.message : 'Unable to preview';
          this.send('open_modal', 'info-modal', {
            title: 'Error',
            information: msg
          });

          if (xhr.payload && xhr.payload.errors) {
            let errors = this.store.serializerFor('application').extractErrors(this.store, this.get('email').constructor, xhr.payload);
            Object.entries(errors).forEach(_ref => {
              let [key, value] = _ref;
              this.get('email').get('errors').add(key, value);
            });
          }
        });
      },

      test_email() {
        if (this.get('testTask.isRunning')) {
          return;
        }

        this.set('submitted', true);
        this.get('testTask').perform().catch(() => {
          this.send('open_modal', 'info-modal', {
            title: 'Error',
            information: 'Unable to send test email'
          });
        });
      },

      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.set('submitted', true);
        this.get('saveTask').perform().then(() => {
          this.transitionToRoute('companies.company.projects.project.settings.emails.index');
        }).catch((0, _catchReal.default)(error => this.send('open_modal', 'info-modal', {
          title: 'Error',
          information: error
        })));
      }

    }
  });

  _exports.default = _default;
});