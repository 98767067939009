define("manage/helpers/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.humanize = humanize;

  function humanize(_ref) {
    let [value] = _ref;

    if (value == null) {
      return '';
    }

    return value.replace(/_id$/, '').replace(/_/g, ' ').replace(/^\w/g, function (s) {
      return s.toUpperCase();
    });
  }

  var _default = Ember.Helper.helper(humanize);

  _exports.default = _default;
});