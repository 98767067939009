define("manage/mirage/fixtures/app-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Sales Sources',
    slug: 'sales-sources',
    appIds: [1, 2, 3]
  }, {
    id: 2,
    name: 'Data Collection',
    slug: 'data-collection',
    appIds: [4, 5]
  }, {
    id: 3,
    name: 'Payment Processors',
    slug: 'payment-processors',
    appIds: [8]
  }, {
    id: 4,
    name: 'Shipping & Fulfillment',
    slug: 'shipping-and-fulfillment',
    appIds: [9]
  }, {
    id: 5,
    name: 'Data Exports',
    slug: 'data-exports',
    appIds: [16]
  }];
  _exports.default = _default;
});