define("manage/components/menu-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rpdor2Al",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"is-child-route\",[[24,[\"targetRouteName\"]],[24,[\"models\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"item expanded\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"header item link main\"]],[[\"@route\",\"@model\",\"@activeClass\"],[[22,\"targetRouteNameIndex\"],[24,[\"models\",\"firstObject\"]],[28,\"if\",[[28,\"eq\",[[24,[\"targetRouteName\"]],[24,[\"targetRouteNameIndex\"]]],null],\"\",[24,[\"activeClass\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"routeIcon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[11,\"class\",[29,[\"icon \",[22,\"routeIcon\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[22,\"routeText\"],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\",\"@model\"],[[22,\"targetRouteNameIndex\"],[24,[\"models\",\"firstObject\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"routeIcon\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[\"icon \",[22,\"routeIcon\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[22,\"routeText\"],false],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"angle down icon\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/menu-selection/template.hbs"
    }
  });

  _exports.default = _default;
});