define("manage/pods/companies/company/projects/project/orders/components/view-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'green', 'message', 'view-filter', 'component']
  });

  _exports.default = _default;
});