define("manage/components/date-short-or-long/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJSN+QlU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"date\"]]],null,{\"statements\":[[4,\"if\",[[28,\"gt\",[[28,\"date-age-in-days\",[[24,[\"date\"]]],null],364],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"format-date\",[[24,[\"date\"]]],[[\"month\",\"day\",\"year\"],[\"short\",\"2-digit\",\"numeric\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"data-tooltip\",[28,\"format-date\",[[24,[\"date\"]]],[[\"month\",\"day\",\"year\"],[\"short\",\"2-digit\",\"numeric\"]]]],[10,\"data-inverted\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"format-date\",[[24,[\"date\"]]],[[\"month\",\"day\"],[\"short\",\"2-digit\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/date-short-or-long/template.hbs"
    }
  });

  _exports.default = _default;
});