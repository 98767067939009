define("manage/pods/companies/company/projects/project/sources/configurations/create/route", ["exports", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    controllerName: 'companies.company.projects.project.sources.configurations.edit.index',
    templateName: 'companies.company.projects.project.sources.configurations.edit.index',
    intl: Ember.inject.service(),
    projectConfigurationTasks: Ember.inject.service(),
    productTasks: Ember.inject.service(),

    model() {
      let project = this.modelFor('companies.company.projects.project');
      return this.get('productTasks.create').perform(project, {
        product_type: 'bundle'
      }).then(product_reward => {
        let source = this.modelFor('companies.company.projects.project.sources'); // Create the extra product

        let sort_orders = source.get('configurations').mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        let configuration = this.store.createRecord('project-configuration', {
          project: project,
          status: 'setup',
          product_reward: product_reward,
          // We can't assign an extras mapping until the source has been saved and can't be changed
          source: source,
          upgrade_type: source.get('is_internal') && source.get('project.purpose') !== 'ecommerce' ? 'any' : 'none',
          can_select: source.get('is_internal'),
          sort_order: sort_order
        });
        return Ember.RSVP.hash({
          project: this.store.findRecord('project', project.get('id'), {
            include: ['products'].join(',')
          }),
          all_products: this.store.query('product', {
            filter: {
              project: project.get('id'),
              product_type: ['physical', 'digital', 'question', 'option']
            },
            include: ['questions'].join(','),
            page: {
              size: _environment.default['max-page-size']
            }
          }),
          source: source,
          configuration: configuration
        });
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.configuration) {
          this.handleTransition(transition, resolve => {
            this.get('projectConfigurationTasks.isDirtyProductReward').perform(record.configuration).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: record.configuration,
                  modelName: record.project.get('t.lines.reward'),
                  serviceTaskAction: this.get('projectConfigurationTasks.rollbackProductReward'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});