define("manage/helpers/set-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Doesn't compute correctly unless we use the full helper version
  var _default = Ember.Helper.extend({
    compute(_ref, options) {
      let [property, key] = _ref;
      return function (value) {
        let target = options.target || this;

        if (Ember.isArray(value)) {
          target.set(property, value.mapBy(key).join(','));
        } else {
          if (Ember.isPresent(value)) {
            target.set(property, value.get(key));
          } else {
            let default_value = null;

            if (options.default != null) {
              default_value = options.default;
            }

            target.set(property, default_value);
          }
        }
      };
    }

  });

  _exports.default = _default;
});