define("manage/pods/companies/company/projects/project/theme/components/theme-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    theme: null,
    current_user: Ember.inject.service('user'),
    pre_order_theme: false,
    size: 'desktop',
    is_desktop: Ember.computed.equal('size', 'desktop'),
    is_tablet: Ember.computed.equal('size', 'tablet'),
    is_mobile: Ember.computed.equal('size', 'mobile'),
    actions: {
      upload_error(xhr) {
        return alert(xhr.responseText);
      },

      clear_logo() {
        return this.set('theme.logo_image_path', null);
      },

      upload_logo(data) {
        return this.set('theme.logo_image_path', data.image_path);
      },

      clear_hero() {
        return this.set('theme.hero_image_path', null);
      },

      upload_hero(data) {
        return this.set('theme.hero_image_path', data.image_path);
      },

      clear_project_name() {
        return this.set('theme.project_name_image_path', null);
      },

      upload_project_name(data) {
        return this.set('theme.project_name_image_path', data.image_path);
      },

      copyPlaceholder(placeholder, model, property) {
        if (Ember.isPresent(model.get(property))) {
          return;
        }

        model.set(property, placeholder);
      },

      revertPlaceholder(placeholder, model, property) {
        if (model.get(property) === placeholder) {
          model.set(property, null);
        }
      }

    }
  });

  _exports.default = _default;
});