define("manage/pods/companies/company/account/authorizations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mbfgW2xR",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Authorizations\"],null],false],[0,\"\\n\"],[4,\"page-banner\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui text menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n        Authorizations\\n        \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n          \"],[1,[24,[\"company\",\"name\"]],false],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"angle right icon\"],[8],[9],[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@activeClass\"],[\"companies.company.account\",\"\"]],{\"statements\":[[0,\"\\n            Account Settings\\n          \"]],\"parameters\":[]}],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"angle right icon\"],[8],[9],[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@activeClass\"],[\"companies.company.account.authorizations\",\"\"]],{\"statements\":[[0,\"\\n            Authorizations\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"inner layout\"],[8],[0,\"\\n  \"],[1,[28,\"companies/company/account/authorized-source\",null,[[\"type\",\"company\"],[\"indiegogo\",[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/account/authorizations/template.hbs"
    }
  });

  _exports.default = _default;
});