define("manage/mirage/factories/project-zone-location", ["exports", "shared/mirage/factories/project-zone-location"], function (_exports, _projectZoneLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _projectZoneLocation.default;
    }
  });
});