define("manage/pods/companies/company/projects/project/orders/view/components/invite-email-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ezDda5Gg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Invite\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic segment content \",[28,\"if\",[[24,[\"inviteTask\",\"isRunning\"]],\"loading\"],null],\" \",[28,\"if\",[[24,[\"has_error\"]],\"error\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"can_invite\"]]],null,{\"statements\":[[0,\"    Inviting will send the customer a unique link to get into the software and complete their survey. Once you click \\\"Send Email\\\" it will take a few minutes before the email is sent.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Unable to invite at this time. It may be that the configuration for this order isn't live.\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n    An error occurred while sending the email. If this continues please contact support.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button cancel\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui button send \",[28,\"if\",[[24,[\"can_invite\"]],\"positive\",\"disabled\"],null]]]],[11,\"onclick\",[28,\"perform\",[[24,[\"inviteTask\"]]],null]],[8],[0,\"Send Email\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/invite-email-modal/template.hbs"
    }
  });

  _exports.default = _default;
});