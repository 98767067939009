define("manage/pods/companies/company/projects/project/products/choices/components/product-mapping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0br7yvQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"product\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"is_question\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"question image\"],[8],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"icon \",[28,\"if\",[[24,[\"product\",\"question\",\"is_text\"]],\"text\",\"checkmark box\"],null]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"sized-image\",null,[[\"image_path\",\"title\",\"width\",\"height\"],[[24,[\"product\",\"image_path\"]],[24,[\"name\"]],31,31]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n    \"],[1,[22,\"name\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[11,\"data-tooltip\",[28,\"strip-tags\",[[24,[\"description\"]]],null]],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"medium\"],[10,\"data-position\",\"bottom center\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon link blue info letter\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"class\",\"ui green icon button\"],[12,\"data-tooltip\",[28,\"t\",[\"phrase.add_to_bottom\"],null]],[12,\"data-position\",\"left center\"],[3,\"action\",[[23,0,[]],[24,[\"mapItem\"]],[24,[\"product\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/choices/components/product-mapping/template.hbs"
    }
  });

  _exports.default = _default;
});