define("manage/pods/companies/company/projects/project/orders/view/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    orderTasks: Ember.inject.service(),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        order: this.store.findRecord('order', params.order_id, {
          include: [// Order Info
          'project.zones', 'project.zones.locations', 'project.custom-fields', 'project.custom-fields.values', 'customer', 'original-configuration', 'original-configuration.product-reward', 'current-configuration', 'current-configuration.product-reward', 'current-configuration.product-extra', 'current-configuration.product-extra.zones', 'current-configuration.product-extra.zones.locations', 'shipping-address', 'local-pickup-address', 'source', // Order related
          'transactions', 'emails', 'emails.email', 'digital-downloads', 'digital-downloads.fulfillment', 'digital-downloads.digital-download', 'digital-keys', 'digital-keys.digital-key', 'tags', 'syncs', 'syncs.order-selections', // order line info
          'lines', 'lines.product-bundle', 'lines.product-bundle.zones', 'lines.product-bundle.zones.locations', // Order selection info
          'selections', 'selections.product', 'selections.product.variants', 'selections.product.variant-values', 'selections.product-variation', 'selections.product-option', 'selections.product-option-mapping', 'selections.product.zones', 'selections.product.zones.locations', // Order selection question info
          'selections.questions', 'selections.questions.product', 'selections.questions.product-question', 'selections.questions.product-question-choice', // Tracking
          'tracking-parameter-value-orders', 'tracking-parameter-value-orders.parameter', 'tracking-parameter-value-orders.parameter-value'].join(',')
        }),
        apiApps: this.store.query('project-app', {
          filter: {
            project: project.get('id'),
            type_slug: ['api-exports'],
            installed: true
          },
          include: ['app', 'app.app-type'].join(',')
        })
      });
    },

    initializeOrderTask: (0, _emberConcurrency.task)(function* (order) {
      order = yield order;

      if (Ember.isBlank(order)) {
        return;
      } // Ensure we've loaded all the lines before checking reward


      yield order.get('lines'); // create a default line for display

      if (order.get('reward_lines.length') === 0) {
        yield this.get('orderTasks.generateOrderLine').perform(order, 'reward', order.get('current_configuration.product_reward'));
      }

      if (order.get('is_completed') || order.get('is_canceled')) {
        // Remove any generated lines incase order had them previously
        let order_lines = yield order.get('lines');
        let calculated_lines = order_lines.filterBy('isDeleted', false).filter(order_line => order_line.get('price_data.is_generated') && order_line.get('price_data.live') === true);

        for (let calculated_line of calculated_lines.toArray()) {
          calculated_line.deleteRecord();
        }
      }

      yield this.get('orderTasks.createGeneratedLines').perform(order);
    }),

    afterModel(model) {
      return this.get('initializeOrderTask').perform(model.order);
    },

    redirect(model) {
      if (Ember.isBlank(model.order)) {
        this.replaceWith('companies.company.projects.project.orders.not-found');
        return;
      }
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('show_only_confirmed_transactions', !(model.order.get('external_unconfirmed') || model.order.get('dropped')));
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('show_all_emails', false);
        controller.set('editing_tags', false);
      }
    },

    actions: {
      willTransition(transition) {
        // TODO: Add shared order dirty and rollback
        if (this.controller.get('order.isDirty')) {
          if (confirm('There are unsaved changes to the order. Are you sure you want to abandon it?')) {
            this.controller.get('order').rollbackAttributes();
            this.controller.get('order').rollbackRelationships();
          } else {
            transition.abort();
          }
        }
      },

      reloadEmails() {
        var model = this.modelFor('companies.company.projects.project.orders.view');
        model.order.get('emails').reload();
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});