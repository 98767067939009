define("manage/models/state", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('state').extend({
    // AE states currently have multiple with the same abbr, making it unique for now and future
    abbr_unique: Ember.computed('abbr', 'id', 'country.states.@each.{id,abbr}', function () {
      if (this.get('country.states.length') > 1) {
        let matches = this.get('country.states').filterBy('abbr', this.get('abbr'));

        if (matches.get('length') > 1) {
          return this.get('abbr') + '-' + this.get('id');
        }
      }

      return this.get('abbr');
    })
  });

  _exports.default = _default;
});