define("manage/pods/companies/company/projects/project/sources/configurations/index/components/column-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OAwApDE1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"configuration\",\"in_setup\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui mini \",[28,\"if\",[[28,\"can-access-feature\",[[24,[\"project\"]],\"send-configuration-email\",[24,[\"current_user\"]],false],null],\"\",\"admin\"],null],\" button\"]]],[3,\"action\",[[23,0,[]],\"email\",[24,[\"configuration\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"configuration\",\"stats\",\"emailed_orders\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t-component\",[[23,0,[]],\"number_emailed\"],[[\"count\"],[[24,[\"configuration\",\"stats\",\"emailed_orders\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t-component\",[[23,0,[]],\"email\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"i\",true],[10,\"class\",\"mail icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/configurations/index/components/column-email/template.hbs"
    }
  });

  _exports.default = _default;
});