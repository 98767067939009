define("manage/pods/companies/company/projects/project/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      let project = this.modelFor('companies.company.projects.project'); // If we land on index, then forward them to the right page

      if (project.get('in_setup')) {
        this.replaceWith('companies.company.projects.project.launch-guide');
      } else {
        this.replaceWith('companies.company.projects.project.stats');
      }
    }

  });

  _exports.default = _default;
});