define("manage/helpers/page-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pageRange = pageRange;

  function pageRange(_ref
  /*, hash*/
  ) {
    let [page, page_size, total_results] = _ref;
    let starting_results = (page - 1) * page_size + 1;
    let ending_result = starting_results - 1 + page_size;

    if (ending_result > total_results) {
      ending_result = total_results;
    }

    return `${starting_results}-${ending_result}`;
  }

  var _default = Ember.Helper.helper(pageRange);

  _exports.default = _default;
});