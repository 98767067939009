define("manage/mixins/models/company", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    enabled: {
      description: "Enabled",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.not('model.shouldSetCountry')
      })]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.country.iso2', function () {
          return this.get('model.country.iso2') != "US";
        }),
        message: "State can't be blank while in the US"
      }, {
        dependentKeys: ['model.country.iso2']
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    ajax: Ember.inject.service(),
    signed_up: Ember.computed('users.@each.{invitation_accepted_at,invitation_sent_at}', 'is_card_on_file', function () {
      if (this.get('users').any(user => {
        return Ember.isPresent(user.get('invitation_accepted_at'));
      })) {
        if (this.get('is_card_on_file')) {
          return 'Activated';
        } else {
          return 'Not Activated';
        }
      } else if (this.get('users').any(user => {
        return Ember.isPresent(user.get('invitation_sent_at'));
      })) {
        return 'Invited';
      } else {
        return 'Not Invited';
      }
    }),

    authorize(type, username, password) {
      return this.get('ajax').postModel(this, 'authorize', {
        data: {
          type: type,
          username: username,
          password: password
        }
      });
    },

    clone_products(project_from_id, project_to_id, product_types) {
      return this.get('ajax').postModel(this, 'clone-products', {
        data: {
          project_from_id: project_from_id,
          project_to_id: project_to_id,
          product_types: product_types
        }
      });
    },

    // Notification
    notification_count: Ember.computed('authorization_revoked_count', 'stripe_has_error', function () {
      let count = 0;
      if (this.get('stripe_has_error')) count++;
      count += this.get('authorization_revoked_count');
      return count;
    }),
    authorization_revoked_count: Ember.computed('authorizations', function () {
      return Object.values(this.get('authorizations')).filter(item => item === 'revoked').length;
    })
  });

  _exports.default = _default;
});