define("manage/pods/admin/companies/company/components/edit-transaction-fee-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pKMND7zv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Edit \"],[1,[22,\"name\"],false],[0,\" Fee\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form content \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"loading\"],null]]]],[8],[0,\" \"],[0,\"\\n\"],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"entity\"]],\"fee\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[0,\"Fee (as percent, 20% = 20)\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui right labeled input\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"fee\"]]]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui basic label\"],[8],[0,\"\\n        %\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button negative\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[1,[28,\"t\",[\"phrase.save\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/admin/companies/company/components/edit-transaction-fee-modal/template.hbs"
    }
  });

  _exports.default = _default;
});