define("manage/models/project-payment-gateway", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-payment-gateway').extend({
    app_id: Ember.computed.alias('app.app.id')
  });

  _exports.default = _default;
});