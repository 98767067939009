define("manage/mixins/filters/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('filter_countries', this.get('store').peekAll('country').sortBy('name'));
    }

  });

  _exports.default = _default;
});