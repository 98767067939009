define("manage/pods/companies/company/projects/project/stats/index/components/stat-totals/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let stats = yield this.get('store').query('project-order-stat', {
        filter: {
          project: project.get('id')
        },
        page: {
          size: 1
        }
      });
      let stat = stats.objectAt(0);
      this.set('stats', stat);
    })
  });

  _exports.default = _default;
});