define("manage/pods/companies/company/projects/project/questions/components/edit-product-question/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-product-question', 'component']
  });

  _exports.default = _default;
});