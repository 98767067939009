define("manage/utils/order-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let filters = {
    // Filters
    search: 'search',
    // Location
    shipping_city: 'shipping_city',
    shipping_state: 'shipping_state',
    shipping_postal: 'shipping_postal',
    shipping_country: 'shipping_country',
    // Customer
    customer_id: 'customer_id',
    customer_name: 'customer_name',
    customer_email: 'customer_email',
    // Order
    ids: 'ids',
    external_id: 'external_id',
    external_sequence: 'external_sequence',
    source: 'site',
    step: 'step',
    status: 'status',
    address: 'address',
    configuration: 'configuration',
    original_configuration: 'original_configuration',
    fulfillment_group: 'fulfillment_group',
    tracking_number: 'tracking_number',
    created_at: 'created_at',
    invited_at: 'invited_at',
    completed_at: 'completed_at',
    last_imported_at: 'last_imported_at',
    // Segments
    project_segment: 'project_segment',
    project_segment_parent: 'project_segment_parent',
    project_segment_action: 'project_segment_action',
    project_segment_action_operation: 'project_segment_action_operation',
    project_segment_action_state: 'project_segment_action_state',
    project_segment_order_action_state: 'project_segment_order_action_state',
    tag: 'tag',
    synced: 'synced',
    // Selections
    line_type: 'line_type',
    fulfillment: 'fulfillment',
    product: 'product',
    product_count: 'product_count',
    product_variation: 'product_variation',
    product_type: 'product_type',
    product_sku: 'product_sku',
    product_bundle: 'product_bundle',
    product_wave: 'product_wave',
    product_question_answer: 'product_question_answer',
    locked_selections: 'locked_selections',
    unlocked_selections: 'unlocked_selections',
    selection_created_at: 'selection_created_at',
    // Transactions
    tx_balance: 'tx_balance',
    tx_paid: 'tx_paid',
    tx_total: 'tx_total',
    tx_shipping: 'tx_shipping',
    tx_refund: 'tx_refund',
    tx_provider: 'tx_provider',
    tx_confirmed: 'tx_confirmed',
    // Digital
    digital_assigned_download: 'digital_assigned_download',
    digital_assigned_key: 'digital_assigned_key',
    digital_assignment: 'digital_assignment',
    digital_email_status: 'digital_email_status',
    digital_email: 'digital_email',
    digital_status: 'digital_status',
    digital_type: 'digital_type'
  };
  var _default = filters;
  _exports.default = _default;
});