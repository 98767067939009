define("manage/pods/companies/company/projects/project/products/index/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'page_size', 'sort', 'sortDirection', 'product_type', 'search'],
    project: Ember.computed.readOnly('model.project'),
    products: Ember.computed.readOnly('model.products'),
    productTasks: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    host: _environment.default.urls.api,
    product_types: Ember.computed('product_type', function () {
      let product_types = [Ember.Object.create({
        id: "physical"
      }), Ember.Object.create({
        id: "digital"
      }), Ember.Object.create({
        id: "option"
      }), Ember.Object.create({
        id: "custom_bundles"
      })];

      if (this.get('product_type') === "unknown") {
        product_types.push(Ember.Object.create({
          id: "unknown"
        }));
      }

      if (this.get('product_type') === "imported_addons_to_review") {
        product_types.push(Ember.Object.create({
          id: "imported_addons_to_review"
        }));
      }

      return product_types;
    }),
    // Paging
    page: 1,
    page_size: 15,
    // Sorting
    sort: 'name',
    sortDirection: 'asc',
    // Filtering
    product_type: null,
    search: null,
    attention_items: Ember.computed('project.{has_unknown_products,has_unreviewed_bundles}', function () {
      let items = 0;

      if (this.get('project.has_unknown_products') === true) {
        items += 1;
      }

      if (this.get('project.has_unreviewed_bundles') === true) {
        items += 1;
      }

      return items;
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      create_product(type) {
        if (type === 'option' || type === 'bundle') {
          Ember.run.scheduleOnce('afterRender', this, this.transitionToRoute, 'companies.company.projects.project.products.choices.create', {
            queryParams: {
              type: type
            }
          });
        } else {
          Ember.run.scheduleOnce('afterRender', this, this.transitionToRoute, 'companies.company.projects.project.products.create', {
            queryParams: {
              type: type
            }
          });
        }
      },

      delete_product(product) {
        if (this.get('productTasks.delete.isRunning')) {
          return;
        }

        this.send('open_modal', 'delete-confirmation-modal', {
          entity: product,
          i18nKey: 'delete-product',
          i18nParams: {
            name: product.get('name'),
            nameIsBlank: Ember.isBlank(product.get('name')),
            isQuestion: false
          },
          i18nErrorPrefix: 'models.product.errors.delete',
          approve: () => {
            return this.get('productTasks.delete').perform(product);
          }
        });
      },

      clone_product(product) {
        this.send('open_modal', 'companies/company/projects/project/products/components/clone-product-modal', {
          product: product,
          onSuccess: result => {
            if (result.data.attributes['product-type'] === 'option') {
              this.transitionToRoute('companies.company.projects.project.products.choices.edit', result.data.id);
            } else {
              this.transitionToRoute('companies.company.projects.project.products.edit', result.data.id);
            }
          },
          onFailure: error => {
            this.send('open_modal', 'error-modal', {
              error: error,
              description: "Unable to clone product."
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});