define("manage/pods/companies/company/projects/project/welcome/wizard", ["exports", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    nextRoute: function (project) {
      let nextStep = null;
      let lastStep = project.get('settings.wizard.last_completed_step');

      if (Ember.isBlank(lastStep)) {
        let source = project.get('sources.firstObject');

        if (source && source.get('is_kickstarter')) {
          nextStep = 'authorization.kickstarter';
        } else if (source && source.get('is_indiegogo')) {
          nextStep = 'authorization.indiegogo';
        } else {
          // If the source isn't kickstarter or indiegogo mark setup completed for now
          lastStep = 'authorization';
        }
      }

      if (lastStep === 'authorization') {
        // Only go to this page if the company is not set or if the country is set to US but the state is not set.
        let country = project.get('company.country');
        let state = project.get('company.state');

        if (Ember.isBlank(country.get('id')) || country.get('iso3') === 'USA' && Ember.isBlank(state.get('id'))) {
          nextStep = 'location';
        } else {
          lastStep = 'location';
        }
      }

      if (lastStep === 'location') {
        nextStep = 'settings';
      }

      if (nextStep == null) {
        return 'companies.company.projects.project';
      } else {
        return `companies.company.projects.project.welcome.${nextStep}`;
      }
    },
    advanceTask: (0, _emberConcurrency.task)(function* (project) {
      project.get('settings.wizard').advance_step(this.get('stepName'));
      yield project.save();
    }),
    actions: {
      advance() {
        let project = this.get('project');
        let intl = this.get('intl');
        this.get('advanceTask').perform(project).then(() => this.transitionToRoute(this.get('nextRoute')(project))).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t('route.companies.company.projects.project.welcome.error.unable_to_save')
          });
        }));
      }

    }
  });

  _exports.default = _default;
});