define("manage/components/inline-edit/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    edit_visible: false,
    editing: false,
    original: null,
    classNames: ["inline-editor", "component"],
    classNameBindings: ["edit_visible::hover"],

    save() {},

    init() {
      this._super(...arguments);

      this.set('original', this.get('value'));
    },

    detect_click_away(event) {
      let target = (0, _jquery.default)(event.target);
      let isInElement = target.closest((0, _jquery.default)(this.element)).length > 0;

      let isInDOM = _jquery.default.contains(window.document.documentElement, event.target);

      if (!isInElement && isInDOM) {
        this.send('cancel');
      }
    },

    willDestroyElement() {
      (0, _jquery.default)(document).off('click.inline-edit-' + this.get('elementId'));
    },

    actions: {
      change() {
        this.set('editing', true);
        (0, _jquery.default)(document).on('click.inline-edit-' + this.get('elementId'), event => this.detect_click_away.apply(this, [event]));
      },

      cancel() {
        this.set('value', this.get('original'));
        this.set('editing', false);
        (0, _jquery.default)(document).off('click.inline-edit-' + this.get('elementId'));
      },

      save() {
        this.get('save')(this.get('value'));
        this.set('original', this.get('value'));
        this.set('editing', false);
        (0, _jquery.default)(document).off('click.inline-edit-' + this.get('elementId'));
      }

    }
  });

  _exports.default = _default;
});