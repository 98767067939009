define("manage/pods/companies/company/projects/project/orders/view/components/wave-shipping-reset-modal/component", ["exports", "manage/mixins/ss-modal", "ember-ajax/errors", "ember-concurrency"], function (_exports, _ssModal, _errors, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    has_error: false,
    order: null,
    can_reopen_order: false,
    can_reset: Ember.computed('order.{is_completed,is_locked,is_canceled}', 'can_reopen_order', function () {
      if (this.get('order.is_locked')) {
        return false;
      }

      if (this.get('order.is_canceled')) {
        return false;
      }

      if (this.get('order.is_completed')) {
        return this.get('can_reopen_order');
      }

      return true; // If we hit this, order isn't completed and not locked or canceled
    }),
    resetTask: (0, _emberConcurrency.task)(function* () {
      this.set('has_error', false);
      let order = yield this.get('order');

      try {
        if (order.get('is_completed')) {
          yield order.uncomplete();
        }

        yield order.resetWaveSelection();
        yield order.reload();
        this.closeModal();
      } catch (e) {
        this.set('has_error', true);

        if (e instanceof _errors.InvalidError) {
          yield order.reload();
        }
      }
    }),
    actions: {
      approve() {
        if (this.get('resetTask.isRunning')) {
          return;
        } // Only set confirmed to true if we are exported at the moment we try to unlock


        this.get('resetTask').perform();
      }

    }
  });

  _exports.default = _default;
});