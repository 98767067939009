define("manage/mixins/filters/configurations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filter_configurations: Ember.computed('project.configurations.@each.{source,product_reward_price,product_reward_name}', function () {
      let results = Ember.A();

      for (let configuration of this.get('project.configurations').toArray()) {
        let source = configuration.get('source');
        let site = source.get('friendly_name');
        let result = results.findBy('groupName', site);

        if (!result) {
          result = {
            groupName: site,
            is_external: source.get('is_external:'),
            options: Ember.A()
          };
          results.push(result);
        }

        result.options.push(configuration);
      }

      results = results.sortBy('is_external', 'name');

      for (let result of results) {
        result.options = result.options.sortBy('product_reward_price', 'product_reward_name');
      }

      return results;
    })
  });

  _exports.default = _default;
});