define("manage/pods/companies/company/projects/project/digital/view/keys/controller", ["exports", "ember-concurrency", "manage/pods/companies/company/projects/project/digital/constants"], function (_exports, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_constants.default, {
    ajax: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ['page', 'page_size', 'sortProperty', 'sortDirection', 'search', 'status', 'provider'],
    project: Ember.computed.readOnly('model.project'),
    fulfillment: Ember.computed.readOnly('model.fulfillment'),
    fulfillment_providers: Ember.computed.readOnly('model.fulfillment_providers'),
    report: Ember.computed.readOnly('model.report'),
    keys: Ember.computed.readOnly('model.keys'),
    filtered_keys: Ember.computed.filterBy('keys', 'isDeleted', false),
    // Paging
    page: 1,
    page_size: 10,
    // Sorting
    sortProperty: 'id',
    sortDirection: 'asc',
    sorted_by: ['id:asc'],
    sorted_keys: Ember.computed.sort('filtered_keys', 'sorted_by'),
    // Filters
    search: '',
    status: '',
    provider: '',
    status_options: [Ember.Object.create({
      id: 'assigned'
    }), Ember.Object.create({
      id: 'unassigned'
    })],
    deleteKeyTask: (0, _emberConcurrency.task)(function* (key) {
      key = yield key;

      try {
        yield key.destroyRecord();
        this.refresh();
      } catch (error) {
        key.rollbackAttributes();
        throw error;
      }
    }),

    refresh() {
      let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

      if (currentRoute) {
        currentRoute.refresh();
      }
    },

    actions: {
      sort(name, direction) {
        if (this.get('sortProperty') !== name) {
          this.set('sortProperty', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortProperty', name);
          this.set('sortDirection', direction);
        }

        return this.set('sorted_by', [`${this.get('sortProperty')}:${this.get('sortDirection')}`]);
      },

      refresh() {
        this.refresh();
      },

      deleteKey(key) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-key',
          i18nParams: {
            key: key.get('key')
          },
          approve: () => {
            return this.get('deleteKeyTask').perform(key);
          }
        });
      }

    }
  });

  _exports.default = _default;
});