define("manage/pods/companies/company/projects/project/orders/create-export/controller", ["exports", "manage/mixins/order-filters", "manage/mixins/order-exports", "manage/mixins/segment-action", "manage/config/environment", "ember-cli-string-helpers/helpers/humanize", "ember-cli-string-helpers/helpers/titleize", "manage/helpers/encode-string", "manage/helpers/decode-string", "ember-inflector", "ember-cp-validations"], function (_exports, _orderFilters, _orderExports, _segmentAction, _environment, _humanize, _titleize, _encodeString, _decodeString, _emberInflector, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let defaultFilterValues = Object.keys(_orderFilters.filters).reduce((map, item) => {
    map[item] = '';
    return map;
  }, {});
  const Validations = (0, _emberCpValidations.buildValidations)({
    format_type: {
      description: "Format Type",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Controller.extend(Validations, _orderExports.default, _segmentAction.default, defaultFilterValues, {
    type: 'export',
    api_url: _environment.default.urls.api,
    options: {},
    // Services
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['format', 'filter', 'originalFilterStep'],

    init() {
      this._super(...arguments);

      this.reset();
    },

    reset() {
      this.set('_current_step', null);
      this.set('steps', {
        step: undefined,
        fulfillment: undefined,
        locking: undefined,
        level: undefined
      });
      this.set('jobId', null);
      this.set('error', null);
      this.set('project_segment_action', null);
      this.set('filters', Ember.Object.create({}));

      if (this.get('export.format_types') && this.get('export.format_types.length') === 1) {
        this.set('format_type', this.get('export.format_types').objectAt(0));
      }
    },

    project: Ember.computed.readOnly('model.project'),
    orders: Ember.computed.readOnly('model.orders'),
    export: Ember.computed('format', function () {
      return this.get(`exports.${this.get('format')}`);
    }),
    count: Ember.computed.alias('orders.meta.record-count'),
    step_index: Ember.computed('current_step', function () {
      switch (this.get('current_step')) {
        case 'step':
          return 1;

        case 'fulfillment':
          return 2;

        case 'locking':
          return 3;

        case 'level':
          return 4;

        default:
          return 5;
      }
    }),
    _current_step: null,
    current_step: Ember.computed('filters.step', '_current_step', {
      get() {
        if (this.get('_current_step') === null) {
          if (this.get('filters.step') === 'completed') {
            // This is a hack. I don't know a good event after it has
            // first parse the url. I could use an obvserver maybe but
            // the flow is just a little awkward.
            if (this.get('filters.step') !== this.get('steps.step')) {
              this.set('steps.step', this.get('filters.step')); // eslint-disable-line
            }

            return 'fulfillment';
          } else {
            return 'step';
          }
        } else {
          return this.get('_current_step');
        }
      },

      set(_key, value) {
        this.set('_current_step', value);
        return this.get('_current_step');
      }

    }),
    order_step_options: Ember.computed('originalFilterStep', 'project.purpose', 'project.t.phrase.{order,checkout}', function () {
      let options = Ember.A();
      let filterStep = this.get('originalFilterStep');

      if (Ember.isPresent(filterStep) && filterStep != 'completed') {
        options.push(Ember.Object.create({
          id: filterStep,
          title: `Keep original step filter (${(0, _titleize.titleize)([(0, _humanize.humanize)([filterStep])])})`
        }));
      }

      let orders = (0, _emberInflector.pluralize)(this.get('project.t.phrase.order').toLowerCase());
      let surveys = (0, _emberInflector.pluralize)(this.get('project.t.phrase.checkout').toLowerCase());
      options.push(Ember.Object.create({
        id: 'completed',
        title: `Only export ${orders} with completed ${surveys}`,
        recommended: true
      }));
      options.push(Ember.Object.create({
        id: 'all',
        title: `Export ${orders} in any step`,
        recommended: false
      }));
      return options;
    }),
    export_records_options: Ember.computed('project.purpose', 'project.t.phrase.order', function () {
      let options = Ember.A();
      let order = this.get('project.t.phrase.order').toLowerCase();
      options.push(Ember.Object.create({
        id: 'not_in_fulfillment',
        title: `Only export the ${(0, _emberInflector.pluralize)(order)} that aren't locked or shipped`,
        recommended: true
      }));
      options.push(Ember.Object.create({
        id: 'all',
        title: `Export all ${order} selections`,
        recommended: false
      }));
      return options;
    }),
    // After action
    export_after_action: null,
    export_after_action_options: Ember.computed('filters.step', 'filters.fulfillment', 'project.purpose', 'project.t.phrase.order', function () {
      let options = Ember.A();
      const step = this.get('filters.step');
      const export_records = this.get('filters.fulfillment');
      let order = this.get('project.t.phrase.order').toLowerCase();
      options.push(Ember.Object.create({
        id: 'lock',
        title: `Lock the ${order} selections after the export is complete`,
        recommended: true,
        disabled: step === 'completed' && export_records === 'not_in_fulfillment' ? false : true
      }));
      options.push(Ember.Object.create({
        id: 'none',
        recommended: false,
        title: `Export the data without locking the ${order} selections`
      }));
      return options;
    }),
    // Export Level
    export_level_options: Ember.computed('selection_filters.[]', 'selection_filter_count', 'project.t.phrase.order', function () {
      let options = Ember.A();
      const selection_filters = this.get('selection_filters');
      const selection_filter_count = this.get('selection_filter_count');
      let order = this.get('project.t.phrase.order').toLowerCase();
      options.push(Ember.Object.create({
        id: 'order_selections',
        title: `Only export the selections that match the ${selection_filters.join(', ')} filter`,
        disabled: selection_filter_count > 0 ? false : `You don't currently have any ${order} item filters. The full ${order} will be exported`
      }));
      options.push(Ember.Object.create({
        id: 'orders',
        title: `Export all selections for each ${order}`
      }));
      return options;
    }),
    filters: {},
    filter: Ember.computed(...Object.keys(_orderFilters.filters).map(f => `filters.${f}`).concat([{
      get() {
        let decoded_filters = Object.keys(_orderFilters.filters).filter(key => {
          return Ember.isPresent(this.get(`filters.${key}`));
        }).reduce((map, key) => {
          map[key] = this.get(`filters.${key}`);
          return map;
        }, {});
        return (0, _encodeString.encodeString)(JSON.stringify(decoded_filters));
      },

      set(_key, value) {
        let decoded_filters = JSON.parse((0, _decodeString.decodeString)(value));
        Object.keys(decoded_filters).forEach(key => {
          this.set(`filters.${key}`, decoded_filters[key]);
        });
        return (0, _encodeString.encodeString)(JSON.stringify(decoded_filters));
      }

    }])),
    selection_filter_count: Ember.computed.alias('selection_filters.length'),
    selection_filters: Ember.computed(...Object.keys(_orderFilters.filters).map(f => `filters.${f}`).concat([function () {
      const applied_filters = Object.entries(this.get('filters')).reduce((arr, _ref) => {
        let [key, value] = _ref;
        if (Ember.isPresent(value)) arr.push(key);
        return arr;
      }, []);
      return applied_filters.filter(i => _orderFilters.filters[i] && _orderFilters.filters[i].level === 'selection' && i !== 'fulfillment');
    }])),
    is_ready_to_export: Ember.computed('selection_filter_count', 'current_step', 'steps.step', 'steps.fulfillment', 'steps.locking', 'steps.level', function () {
      if (this.get('selection_filter_count') > 0) {
        return Ember.isPresent(this.get('steps.step')) && Ember.isPresent(this.get('steps.fulfillment')) && Ember.isPresent(this.get('steps.locking')) && Ember.isPresent(this.get('steps.level'));
      } else {
        return Ember.isPresent(this.get('steps.step')) && Ember.isPresent(this.get('steps.fulfillment')) && Ember.isPresent(this.get('steps.locking'));
      }
    }),
    actions: {
      perform(format_type) {
        this.set('submitted', true);

        if (this.get('actionTask.isRunning')) {
          return;
        }

        this.get('actionTask').perform({
          type: this.get('export.type'),
          format: format_type,
          filter_level: this.get('steps.level'),
          mark_exported: this.get('export_after_action') === 'lock',
          exporter_options: this.options
        });
      },

      expandAccordion(step) {
        this.set('current_step', step);
      },

      change_step(value) {
        this.set('steps.step', value);
        this.set('filters.step', value === 'all' ? '' : value);
        this.set('current_step', 'fulfillment');
      },

      change_fulfillment(value) {
        this.set('steps.fulfillment', value);
        this.set('filters.fulfillment', value === 'all' ? '' : value);
        this.set('current_step', 'locking');
      },

      change_locking(value) {
        this.set('steps.locking', value);
        this.set('export_after_action', value === 'none' ? '' : value);

        if (this.get('selection_filter_count') > 0) {
          this.set('current_step', 'level');
        } else {
          this.set('current_step', '');
        }
      },

      change_level(value) {
        this.set('steps.level', value);
        this.set('current_step', '');
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      viewExports() {
        this.get('router').transitionTo('companies.company.projects.project.orders.exports');
      }

    }
  });

  _exports.default = _default;
});