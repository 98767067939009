define("manage/pods/companies/company/projects/project/sources/configurations/edit/extras/controller", ["exports", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    all_products: Ember.computed.readOnly('model.all_products'),
    configuration: Ember.computed.readOnly('model.configuration'),
    product_extra: Ember.computed.readOnly('model.product_extra'),
    current_user: Ember.inject.service('user'),
    productTasks: Ember.inject.service(),
    ajax: Ember.inject.service(),
    is_global: Ember.computed('configuration.product_extra.id', 'configuration.source.product_extra_global.id', function () {
      return this.get('configuration.product_extra.id') === this.get('configuration.source.product_extra_global.id');
    }),
    is_none: Ember.computed.none('configuration.product_extra.id'),
    is_custom: Ember.computed('is_global', 'is_none', function () {
      return !this.get('is_global') && !this.get('is_none');
    }),
    isRunning: Ember.computed.or('processExtrasTask.isRunning', 'changeExtrasTask.isRunning', 'productTasks.save.isRunning'),
    // Verify the process
    processExtrasTask: (0, _emberConcurrency.task)(function* (configuration, option) {
      configuration = yield configuration;
      let product_extra = yield configuration.get('product_extra');
      let source = yield configuration.get('source');
      let product_extra_global = yield source.get('product_extra_global');
      let is_custom = Ember.isPresent(product_extra) && product_extra !== product_extra_global;

      if (option === 'custom') {
        if (is_custom) {
          // Already set to custom
          return;
        }

        return this.get('changeExtrasTask').perform(configuration, option);
      }

      if (option === 'global') {
        if (Ember.isPresent(product_extra) && product_extra === product_extra_global) {
          // Already set to global
          return;
        }

        if (is_custom) {
          return this.send('open_modal', 'confirm-modal', {
            i18nKey: 'set-configuration-extras-to-global',
            approve: () => {
              return this.saveExtras(configuration, option);
            }
          });
        }

        return this.get('changeExtrasTask').perform(configuration, option);
      }

      if (option === 'none') {
        if (Ember.isBlank(product_extra)) {
          // Already set to none
          return;
        }

        if (is_custom) {
          return this.send('open_modal', 'confirm-modal', {
            i18nKey: 'set-configuration-extras-to-none',
            approve: () => {
              return this.saveExtras(configuration, option);
            }
          });
        }

        return this.get('changeExtrasTask').perform(configuration, option);
      }
    }),

    // Helper method to add a catch to all the performs
    saveExtras(configuration, option) {
      this.get('changeExtrasTask').perform(configuration, option).catch((0, _catchReal.default)(error => {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to change configuration extras type."
        });
      }));
    },

    // Finalize the changes
    changeExtrasTask: (0, _emberConcurrency.task)(function* (configuration, option) {
      configuration = yield configuration;

      if (option === 'custom') {
        let source = yield configuration.get('source');
        let product_extra_global = yield source.get('product_extra_global');
        let clone_url = this.get('ajax').buildModelUrl(product_extra_global, 'clone');
        let result = yield this.get('ajax').post(clone_url);
        this.store.pushPayload(result); // We need to load it with the right data

        let cloned_product_extra = yield this.store.findRecord('product', result.data.id, {
          include: ['mappings', 'mappings.child'].join(',')
        });
        configuration.set('product_extra', cloned_product_extra);
      }

      if (option === 'global') {
        let source = yield configuration.get('source');
        let product_extra_global = yield source.get('product_extra_global');
        configuration.set('product_extra', product_extra_global);
      }

      if (option === 'none') {
        configuration.set('product_extra', null);
      }

      yield configuration.save();
    }),
    actions: {
      save() {
        if (this.get('isRunning')) {
          return;
        }

        this.set('submitted', true); // We validate in the save task

        this.get('productTasks.save').perform(this.get('configuration.product_extra')).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "The extra mapping wasn't able to be saved."
          });
        }));
      },

      update_mappings(option) {
        if (this.get('isRunning')) {
          return;
        }

        this.get('processExtrasTask').perform(this.get('configuration'), option).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to change configuration extras type."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});