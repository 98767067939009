define("manage/pods/admin/companies/components/create-company-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    saving: false,

    created() {},

    init() {
      this._super(...arguments);

      this.set('company', this.get('store').createRecord('company'));
    },

    closing() {
      var record = this.get('company');

      if (record.get('isNew')) {
        record.deleteRecord();
      } else {
        record.rollbackAttributes();
      }
    },

    actions: {
      approve() {
        this.set('submitted', true);

        if (this.get('company.validations.isInvalid')) {
          return;
        }

        this.set('saving', true);
        this.get('company').save().then(() => {
          this.set('submitted', false);
          this.get('created')(this.get('company'));
          this.closeModal();
        }).finally(() => {
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});