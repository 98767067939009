define("manage/pods/companies/company/projects/project/welcome/settings/controller", ["exports", "manage/pods/companies/company/projects/project/welcome/wizard"], function (_exports, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_wizard.default, {
    project: Ember.computed.readOnly('model'),
    submitted: false,
    stepName: 'settings',
    is_error: Ember.computed('submitted', 'project.urls.validations.isInvalid', 'project.support.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      return this.get('project.urls.validations.isInvalid') || this.get('project.support.validations.isInvalid');
    }),

    validateAll() {
      this.set('submitted', true);
      this.get('project.urls').validateSync();
      this.get('project.support').validateSync();
    },

    actions: {
      submit() {
        if (this.get('advanceTask.isRunning')) {
          return;
        }

        this.validateAll();

        if (this.get("is_error")) {
          return;
        }

        let project = this.get('project');
        let source = project.get('sources').findBy('is_kickstarter', true);

        if (source) {
          source.set('settings.wizard.access_requested_on', new Date());
        }

        this.send('next');
      },

      next() {
        if (this.get('advanceTask.isRunning')) {
          return;
        }

        this.validateAll();

        if (this.get("is_error")) {
          // If we are skipping, clear any invalid attributes
          if (this.get('project.support.validations.attrs.email.isInvalid')) {
            this.set('project.support.email', null);
          }

          if (this.get('project.urls.validations.attrs.twitter.isInvalid')) {
            this.set('project.urls.twitter', null);
          }

          if (this.get('project.urls.validations.attrs.homepage_url.isInvalid')) {
            this.set('project.urls.homepage_url', null);
          }
        }

        this.send('advance');
      }

    }
  });

  _exports.default = _default;
});