define("manage/pods/companies/company/projects/project/orders/index/components/column-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RAr+10Ee",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"companies/company/projects/project/orders/status-text\",null,[[\"order\"],[[24,[\"order\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/index/components/column-status/template.hbs"
    }
  });

  _exports.default = _default;
});