define("manage/models/audited-order", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Not included in schema.json since it's polymorphic
  var _default = _model.default.extend({
    project: (0, _model.belongsTo)('project'),
    order: (0, _model.belongsTo)('order'),
    entity: (0, _model.attr)('string'),
    action: (0, _model.attr)('string'),
    user_type: (0, _model.attr)('string'),
    user: (0, _model.attr)('string'),
    changes: (0, _model.attr)('object'),
    comment: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date')
  });

  _exports.default = _default;
});