define("manage/pods/companies/company/projects/project/segments/index/controller", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    segments: Ember.computed.readOnly('model.segments'),
    countries: Ember.computed.readOnly('model.countries'),
    api_url: _environment.default.urls.api,
    queryParams: ['page', 'page_size', 'sort', 'sortDirection', 'search', 'behavior', 'hidden', 'closed'],
    // Paging
    page: 1,
    page_size: 10,
    // Sorting
    // Filters
    search: '',
    behavior: 'all',
    behavior_options: Ember.computed('current_user.is_admin', function () {
      let options = Ember.A();
      options.push(Ember.Object.create({
        id: 'all'
      }));
      options.push(Ember.Object.create({
        id: 'fixed'
      }));

      if (this.get('current_user.is_admin')) {
        options.push(Ember.Object.create({
          id: 'dynamic_filter'
        }));
        options.push(Ember.Object.create({
          id: 'dynamic_automated'
        }));
      } else {
        options.push(Ember.Object.create({
          id: 'dynamic'
        }));
      }

      return options;
    }),
    hidden: 'false',
    hidden_options: [Ember.Object.create({
      id: 'true'
    }), Ember.Object.create({
      id: 'false'
    })],
    closed: 'all',
    closed_options: [Ember.Object.create({
      id: 'all'
    }), Ember.Object.create({
      id: 'true'
    }), Ember.Object.create({
      id: 'false'
    })],
    deleteSegmentTask: (0, _emberConcurrency.task)(function* (projectSegment) {
      let segment = yield projectSegment;

      try {
        yield segment.destroyRecord();
      } catch (e) {
        segment.rollbackAttributes();
        throw e;
      }

      this.refresh();
    }),

    refresh() {
      let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

      if (currentRoute) {
        currentRoute.refresh();
      }
    },

    actions: {
      reset_page() {
        this.set('page', 1);
      },

      view_segment(segment) {
        return this.transitionToRoute('companies.company.projects.project.segments.view', segment.get('id'));
      },

      segment_created() {
        // Reset all filters so this can be seen
        this.set('hidden', 'false');
        this.set('behavior', 'all');
        this.set('closed', 'all'); // Refresh the route

        this.refresh();
      },

      delete_segment(segment) {
        let i18nKey = segment.category === 'export' ? 'delete-export-segment' : 'delete-segment';
        this.send('open_modal', 'confirm-modal', {
          i18nKey: i18nKey,
          i18nParams: {
            name: segment.get('name')
          },
          approve: () => {
            return this.get('deleteSegmentTask').perform(segment);
          }
        });
      }

    }
  });

  _exports.default = _default;
});