define("manage/pods/confirm/stripe/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('company', params.state);
    },

    afterModel(company) {
      let params = this.paramsFor(this.routeName);
      company.set('stripe_oauth_code', params.code);
      return company.save().then(() => {
        return this.transitionTo('index');
      });
    }

  });

  _exports.default = _default;
});