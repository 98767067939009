define("manage/pods/companies/company/projects/project/orders/view/components/resend-email-modal/component", ["exports", "manage/mixins/ss-modal", "ember-cp-validations", "ember-concurrency"], function (_exports, _ssModal, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, Validations, {
    ajax: Ember.inject.service(),
    submitted: false,
    order: Ember.computed.readOnly('model'),
    type: null,
    email: null,
    email_types: Ember.computed('order.emails.[]', function () {
      var emails = Ember.A([]);

      if (Ember.isEmpty(this.get('order.emails'))) {
        return emails;
      }

      this.get('order.emails').forEach(item => {
        if (Ember.isEmpty(item.get('email.template'))) {
          if (!emails.isAny('email_id', item.get('email.email_id'))) {
            return emails.push(item.get('email'));
          }
        } else {
          if (!emails.isAny('template', item.get('email.template'))) {
            return emails.push(item.get('email'));
          }
        }
      });
      return emails;
    }),
    sendTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      let {
        validations: validations
      } = yield this.validate();

      if (!validations.get('isValid')) {
        return; // Not valid return so the ui updates
      }

      let order = yield this.get('order');
      let email = yield this.get('email');

      try {
        yield this.get('ajax').postModel(order, 'email', {
          data: {
            project_email_id: email.get('id')
          }
        });
      } catch (e) {
        this.set('has_error', true);
        return;
      }

      this.closeModal();
      this.onCompletion();
    }).drop()
  });

  _exports.default = _default;
});