define("manage/pods/companies/company/projects/project/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IVt/RECT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t-title\",[[23,0,[]]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui header\"],[10,\"style\",\"width: 410px; margin: 50px auto 40px; color: #4a4a4a; font-size: 26px; line-height: 32px; font-weight: 600;\"],[8],[0,\"\\n  \"],[1,[28,\"t-route\",[\"header\"],null],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[10,\"style\",\"margin-top: 25px;\"],[8],[0,\"\\n    \"],[1,[28,\"t-route\",[\"sub_header\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});