define("manage/pods/companies/company/projects/project/orders/components/duplicate-email-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v0vIsoLJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"header\"],null],false],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui form basic segment content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"content.description\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"content.help\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui green button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"Ok\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/components/duplicate-email-modal/template.hbs"
    }
  });

  _exports.default = _default;
});