define("manage/pods/companies/company/projects/project/settings/feature-flags/controller", ["exports", "ember-concurrency", "manage/utils/catch-real"], function (_exports, _emberConcurrency, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    feature_flags: Ember.computed.readOnly('model.feature_flags'),
    toggleFlagTask: (0, _emberConcurrency.task)(function* (project, feature_flag) {
      project = yield project;

      if (project.get('settings.feature_flags').includes(feature_flag)) {
        project.get('settings.feature_flags').removeObject(feature_flag);
      } else {
        project.get('settings.feature_flags').addObject(feature_flag);
      }

      yield project.save();
    }).drop(),
    actions: {
      update_flag(feature_flag) {
        if (this.get('toggleFlagTask.isRunning')) {
          return;
        }

        let project = this.get('project');
        this.get('toggleFlagTask').perform(project, feature_flag).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to save, please refresh and try again."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});