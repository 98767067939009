define("manage/adapters/application", ["exports", "ember-inflector", "manage/config/environment", "ember-data/adapters/json-api", "manage/mixins/smart-adapter-cache"], function (_exports, _emberInflector, _environment, _jsonApi, _smartAdapterCache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clearReloadCache = clearReloadCache;
  _exports.default = void 0;
  const reloadCache = {};

  function clearReloadCache() {
    Object.keys(reloadCache).forEach(function (key) {
      delete reloadCache[key];
    });
  }

  var _default = _jsonApi.default.extend((0, _smartAdapterCache.default)(reloadCache), {
    host: _environment.default.urls.api,
    namespace: 'api/v2',
    session: Ember.inject.service(),

    pathForType(modelName) {
      let decamelized = Ember.String.decamelize(modelName);
      let pluralized = (0, _emberInflector.pluralize)(decamelized);
      let slashed = pluralized.replace(/\//g, '-').replace(/_/g, '-');
      return slashed;
    },

    headers: Ember.computed('session.data.authenticated.{type,token}', function () {
      let headers = {};
      let {
        token,
        type
      } = this.get('session.data.authenticated') || {};

      if (Ember.isPresent(token) && type === 'token') {
        headers['Authorization'] = `Bearer ${token}`;
      }

      return headers;
    })
  });

  _exports.default = _default;
});