define("manage/components/import-source/component", ["exports", "manage/mixins/project-source-object", "manage/models/project-source", "manage/utils/monetize", "ember-cp-validations"], function (_exports, _projectSourceObject, _projectSource, _monetize, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    url: {
      description: "URL",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    category: {
      description: 'Category',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.categories', 'model.project_preview', function () {
          if (this.get('model.project_preview') == null) {
            return true;
          }

          if (this.get('model.categories') == null || this.get('model.categories.length') === 0) {
            return true;
          }

          return false;
        })
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    attributeBindings: ['data-test-component'],
    'data-test-component': 'import-source',
    store: Ember.inject.service(),
    // Component parameters
    company: null,
    project: null,
    // Project id used when creating a source off of an existing project
    categories: null,
    // optional
    parent_categories: Ember.computed('categories.@each.parent_category', function () {
      return this.get('categories').filter(category => category.get('parent_category.id') == null);
    }),
    submitted: false,
    // Preview
    url: null,
    project_preview: null,
    category: null,
    child_category: null,
    error: null,
    intl: Ember.inject.service(),

    created() {},

    actions: {
      clear_errors() {
        this.set('error_preview', null);
        return this.set('error_create', null);
      },

      preview_from_url() {
        this.set('submitted', true);
        this.send('clear_errors');
        this.set('url', (this.get('url') || "").trim());
        this.validateSync();

        if (this.get('validations.isInvalid')) {
          return;
        }

        this.set("submitted_preview", true);
        this.set("project_preview", null);
        return _projectSource.default.preview_from_url(this.get("url")).then(project => {
          let serializer = this.get('store').serializerFor('application');
          project = serializer._normalizeDocumentHelper(project);
          let project_object = Ember.Object.extend({
            currency: project.meta.currency,
            total_ordered: (0, _monetize.default)('total_ordered_cents', 'currency')
          }).extend(_projectSourceObject.default).create(project.data.attributes); // There is a container issue with this, specifically passing in here

          project_object.set('intl', this.get('intl'));
          return this.set("project_preview", project_object);
        }).catch(error => {
          this.set("project_preview", null);

          if (error && error.payload && Ember.isPresent(error.payload.errors)) {
            return this.set("error_preview", error.payload.errors.map(error => error.detail || error.title).join(', '));
          } else {
            return this.set("error_preview", error);
          }
        }).finally(() => {
          return this.set("submitted_preview", false);
        });
      },

      create_from_url() {
        this.send('clear_errors');
        this.set('url', (this.get('url') || "").trim());
        this.set('submitted', true);
        this.validateSync();

        if (this.get('validations.isInvalid')) {
          return;
        }

        this.set("submitted_create", true);
        let store = this.get('store'); // Project id used when importing and existing source

        return _projectSource.default.create_from_url(this.get('company.id'), this.get('url'), this.get('project.id'), this.get('category.id'), this.get('child_category.id')).then(response => {
          this.set("project_preview", null);
          this.set("url", null);
          store.findRecord('project', response.data.relationships.project.data.id, {
            include: 'sources'
          }).then(project => this.get('created')(project));
        }).catch(error => {
          if (error && error.payload && Ember.isPresent(error.payload.errors)) {
            return this.set("error_create", error.payload.errors.map(error => error.detail || error.title).join(', '));
          } else {
            return this.set("error_create", error);
          }
        }).finally(() => {
          return this.set("submitted_create", false);
        });
      },

      clear_child_category() {
        this.set('child_category', null);
      }

    }
  });

  _exports.default = _default;
});