define("manage/mixins/models/order/line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),

    priceData(type, product_amount, shipping_amount, additional_setting) {
      return this.get('ajax').postModel(this, "price_data", {
        data: {
          price_data: {
            type,
            product_amount,
            shipping_amount,
            additional_setting
          }
        }
      });
    }

  });

  _exports.default = _default;
});