define("manage/models/project-product-stat", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-product-stat').reopen({
    default_image_path: Ember.computed.readOnly('product.image_path')
  });

  _exports.default = _default;
});