define("manage/pods/companies/company/projects/project/stats/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    setupController(controller
    /*, model */
    ) {
      let project = this.modelFor('companies.company.projects.project');
      controller.set('project', project);

      this._super(...arguments);
    },

    renderTemplate() {
      this.render();
      this.render('companies/company/projects/project/stats/submenu', {
        into: 'companies/company/projects/project/menu',
        outlet: 'submenu'
      });
    }

  });

  _exports.default = _default;
});