define("manage/pods/companies/company/projects/project/components/app-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AivV75Q+",
    "block": "{\"symbols\":[\"app_action\"],\"statements\":[[4,\"if\",[[24,[\"app_actions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"access\"]],\"\",\"admin\"],null],\" item\"]]],[8],[0,\"\\n    \"],[1,[22,\"name\"],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"app_actions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",\"link item\"],[12,\"data-tooltip\",[29,[[28,\"if\",[[23,1,[\"description\"]],[28,\"strip-tags\",[[23,1,[\"description\"]]],null]],null]]]],[12,\"data-position\",[29,[[28,\"if\",[[28,\"or\",[[23,1,[\"description\"]],[23,1,[\"html_description\"]]],null],\"left center\"],null]]]],[12,\"data-size\",[29,[[28,\"if\",[[28,\"or\",[[23,1,[\"description\"]],[23,1,[\"html_description\"]]],null],\"medium\"],null]]]],[3,\"action\",[[23,0,[]],\"click_action\",[23,1,[]]]],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\",true],[11,\"class\",[29,[[23,1,[\"icon\"]],\" icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/components/app-actions/template.hbs"
    }
  });

  _exports.default = _default;
});