define("manage/pods/companies/company/projects/project/digital/components/fulfillment-status-button/component", ["exports", "manage/pods/companies/company/projects/project/digital/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_constants.default, {
    fulfillment: null,
    classForNextStatus: {
      setup: 'green',
      live: 'yellow',
      paused: 'green',
      complete: 'grey'
    }
  });

  _exports.default = _default;
});