define("manage/helpers/ends-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref
    /*, args */
    ) {
      let [value1, value2] = _ref;

      if (value1 == null) {
        return false;
      }

      return value1.endsWith(value2);
    }

  });

  _exports.default = _default;
});