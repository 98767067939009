define("manage/pods/companies/company/projects/project/components/location-zone-weight-based/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0wQVedx",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"format-number\",[[24,[\"zone\",\"first_amount\"]]],[[\"currency\"],[[24,[\"project\",\"currency\"]]]]],false],[0,\" - \"],[1,[28,\"format-number\",[[24,[\"zone\",\"last_amount\"]]],[[\"currency\"],[[24,[\"project\",\"currency\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/components/location-zone-weight-based/template.hbs"
    }
  });

  _exports.default = _default;
});