define("manage/pods/companies/company/projects/project/sources/configurations/edit/caps/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    configuration: Ember.computed.readOnly('model.configuration'),
    product_extra: Ember.computed.readOnly('model.product_extra'),
    productTasks: Ember.inject.service(),
    submitted: false,
    has_custom_extras: Ember.computed('configuration.source.product_extra_global.id', 'configuration.product_extra.id', function () {
      let product_extra = this.get('configuration.product_extra.id');

      if (Ember.isBlank(product_extra)) {
        return false;
      }

      let global_extra = this.get('configuration.source.product_extra_global.id');
      return product_extra !== global_extra;
    }),
    actions: {
      save() {
        if (this.get('productTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true);

        if (this.get('product_extra.validations.isInvalid')) {
          return;
        }

        this.get('productTasks.save').perform(this.get('product_extra')).then(() => this.set('saved', true)).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "The caps weren't able to be saved."
          });
        }));
      }

    }
  });

  _exports.default = _default;
});