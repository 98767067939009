define("manage/pods/companies/company/projects/project/orders/view/components/reopen-order-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wiJu6Te3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Reopen Order\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic segment content \",[28,\"if\",[[24,[\"is_processing\"]],\"loading\"],null],\" \",[28,\"if\",[[24,[\"has_error\"]],\"error\"],null]]]],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    By reopening this order you are allowing them access to change their\\n    reward, extras, shipping info and/or payment info.\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"order\",\"is_locked\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui yellow header\"],[8],[0,\"\\n      WARNING: Reopening this order will make them eligible for export again.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small orange header\"],[8],[0,\"\\n    Would you like to open the survey for \"],[1,[24,[\"order\",\"customer\",\"full_name\"]],false],[0,\"?\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n    An error occurred while reopening this order. If this continues please contact support.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button cancel\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"No\"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"Yes\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/reopen-order-modal/template.hbs"
    }
  });

  _exports.default = _default;
});