define("manage/instance-initializers/global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(appInstance) {
    window.Manage = appInstance;
  }

  var _default = {
    name: 'global',
    initialize
  };
  _exports.default = _default;
});