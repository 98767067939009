define("manage/pods/companies/company/projects/project/orders/components/merge-users-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['merge-users-modal'],
    customer: null,
    otherCustomer: null,

    reload() {},

    mergeCustomerTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('customer').merge(this.get('otherCustomer.email')); // Hide the modal

        this.get('reload')();
        this.closeModal();
      } catch (e) {
        alert(e.message);
      }
    }),

    closing() {
      // Somehow when we cancel the changed attributes for email get applied to the user object again.
      // Not sure how or why just yet as I haven't been able to track it down.
      //
      // This is only here as a hack to get back the original info.
      this.get('reload')();
    },

    actions: {
      merge() {
        this.get('mergeCustomerTask').perform();
      }

    }
  });

  _exports.default = _default;
});