define("manage/models/product-dimensions", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    length: {
      description: "Length",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        allowNone: true,
        gte: 0
      })]
    },
    width: {
      description: "Width",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        allowNone: true,
        gte: 0
      })]
    },
    height: {
      description: "Height",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        allowNone: true,
        gte: 0
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    length: (0, _model.attr)('number'),
    width: (0, _model.attr)('number'),
    height: (0, _model.attr)('number')
  });

  _exports.default = _default;
});