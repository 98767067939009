define("manage/pods/companies/company/projects/project/segments/actions/index/controller", ["exports", "manage/mixins/bulk-actions"], function (_exports, _bulkActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bulkActions.default, {
    current_user: Ember.inject.service('user'),
    project: Ember.computed.readOnly('model.project'),
    project_segment_actions: Ember.computed.readOnly('model.project_segment_actions'),
    // Paging
    page: 1,
    page_size: 10,
    // Sorting
    // Filters
    state: '',
    state_options: [// EmberObject.create({ id: 'all' }),
    Ember.Object.create({
      id: 'new'
    }), Ember.Object.create({
      id: 'working'
    }), Ember.Object.create({
      id: 'in_error'
    }), Ember.Object.create({
      id: 'errored'
    }), Ember.Object.create({
      id: 'canceled'
    }), Ember.Object.create({
      id: 'timed_out'
    }), Ember.Object.create({
      id: 'completed'
    })],
    operation: '',
    actions: {
      reset_page() {
        this.set('page', 1);
      },

      view_action(project_segment_action) {
        return this.transitionToRoute('companies.company.projects.project.segments.actions.view', project_segment_action.get('segment.id'), project_segment_action.get('id'));
      }

    }
  });

  _exports.default = _default;
});