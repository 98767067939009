define("manage/pods/companies/company/projects/project/apps/components/update-settings-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zdeFPYXY",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"header\"],[[\"name\"],[[24,[\"app\",\"name\"]]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"sub_header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"form\",true],[11,\"class\",[29,[\"ui form \",[28,\"if\",[[24,[\"isRunning\"]],\"loading\",\"\"],null],\" content\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"app\",\"app\",\"options\"]]],null,{\"statements\":[[0,\"    \"],[15,\"companies/company/projects/project/apps/components/app-options\",[1]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"actions \",[28,\"if\",[[24,[\"isRunning\"]],\"loading\",\"\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui floated left button basic button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"class\",\"ui positive button\"],[3,\"action\",[[23,0,[]],\"install\"]],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"actions.approve\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/apps/components/update-settings-modal/template.hbs"
    }
  });

  _exports.default = _default;
});