define("manage/pods/companies/company/projects/project/launch-guide/components/lg-setup-configurations/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    init() {
      this._super(...arguments);

      this.get('checkSourceTask').perform();
    },

    store: Ember.inject.service(),
    showing: false,
    total_configurations: Ember.computed('source.configurations.[]', function () {
      return this.get('source.configurations.length');
    }),
    total_configurations_with_mappings: Ember.computed('source.configurations.@each.stats', function () {
      return (this.get('source.configurations') || Ember.A()).filter(configuration => configuration.get('stats.mapped_products') > 0).get('length');
    }),
    completed: Ember.computed('total_configurations_with_mappings', function () {
      return this.get('total_configurations_with_mappings') > 0;
    }),
    // If less than 80% of configurations
    pending: Ember.computed('completed', 'total_configurations', 'source.configurations.@each.{stats,product_reward}', function () {
      if (!this.get('completed')) {
        return false;
      } // Get all configurations that have mappings or under $5


      let completed = (this.get('source.configurations') || Ember.A()).filter(configuration => configuration.get('stats.mapped_products') > 0 || configuration.get('product_reward.price_cents') <= 500).get('length');
      return completed / this.get('total_configurations') < 0.8;
    }),
    checkSourceTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let sources = yield project.get('sources');
      let first_source = sources.filterBy('is_external').sortBy('id').objectAt(0);

      if (Ember.isBlank(first_source)) {
        first_source = sources.objectAt(0);
      }

      if (Ember.isBlank(first_source)) {
        return;
      }

      let source = yield this.get('store').findRecord('project-source', first_source.get('id'), {
        include: ['configurations', 'configurations.product-reward'].join(',')
      });
      let configurations = yield source.get('configurations');

      if (configurations.get('length') === 0) {
        this.set('visible', false);
        return;
      }

      yield this.get('store').query('project-configuration-stat', {
        filter: {
          id: configurations.mapBy("id")
        },
        page: {
          size: configurations.get('length')
        }
      });
      this.set('source', source);
    }),
    actions: {
      is_complete(configuration) {
        if (configuration.get('stats.mapped_products') > 0) {
          return "green";
        }

        if (configuration.get('product_reward.price_cents') <= 500) {
          return "orange";
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});