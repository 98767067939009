define("manage/pods/companies/company/projects/project/orders/tags/components/new-tag-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fkyYNWaa",
    "block": "{\"symbols\":[\"color\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[23,0,[\"title\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"form\",true],[11,\"class\",[29,[\"ui form content \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"loading\"],null]]]],[8],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[23,0,[]],\"name\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"for\",\"name\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui left icon input\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"tag icon\"],[8],[9],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"value\",\"placeholder\"],[[24,[\"name\"]],\"Enter the name of the tag\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[23,0,[]],\"color\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"for\",\"color\"],[8],[0,\"Color (optional)\"],[9],[0,\"\\n    \"],[5,\"power-select\",[],[[\"@placeholder\",\"@selected\",\"@options\",\"@onChange\"],[\"Select Color\",[22,\"color\"],[22,\"colors\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"color\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[23,1,[]],\" label\"]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui green button\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n    \"],[1,[22,\"approve_label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/tags/components/new-tag-modal/template.hbs"
    }
  });

  _exports.default = _default;
});