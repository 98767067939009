define("manage/pods/companies/company/projects/project/launch-guide/components/lg-shipping-selection/component", ["exports", "manage/mixins/launch-guide-component", "ember-concurrency"], function (_exports, _launchGuideComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_launchGuideComponent.default, {
    completed: Ember.computed('project.settings.wizard.reviewed_shipping', 'project.{is_live,is_complete}', function () {
      if (this.get('project.settings.wizard.reviewed_shipping')) {
        return true;
      }

      if (this.get('project.is_live') || this.get('project.is_complete')) {
        return true;
      }

      return false;
    }),
    reviewedShippingTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      project.set('settings.wizard.reviewed_shipping', true);
      yield project.save();
    }).drop()
  });

  _exports.default = _default;
});