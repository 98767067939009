define("manage/mixins/models/order/transaction", ["exports", "manage/utils/monetize"], function (_exports, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    refund_amount: (0, _monetize.default)('refund_amount_cents', 'currency'),
    refund_amount_cents: Ember.computed('related_transactions.@each.{amount_cents,is_refund,confirmed}', function () {
      return (this.get('related_transactions') || []).reduce(function (previousValue, item) {
        if (item.get('is_refund') && item.get('confirmed')) {
          return previousValue + item.get('amount_cents');
        }

        return previousValue;
      }, 0);
    }),
    is_refunded: Ember.computed('amount', 'refund_amount', function () {
      return this.get('amount') === this.get('refund_amount') * -1;
    }),
    can_refund: Ember.computed('confirmed', 'is_refund', 'is_refunded', 'is_dispute', 'is_manual', 'extra', function () {
      if (!this.get('confirmed')) {
        return false;
      } else if (this.get('is_refund')) {
        return false;
      } else if (this.get('is_refunded')) {
        return false;
      } else if (this.get('is_dispute')) {
        return false;
      } else {
        return !this.get('is_manual');
      }
    }),
    can_delete: Ember.computed.alias('is_manual'),

    refund(amount_cents) {
      return this.get('ajax').postModel(this, 'refund', {
        data: {
          amount_cents: amount_cents
        }
      });
    }

  });

  _exports.default = _default;
});