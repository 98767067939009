define("manage/pods/admin/holidays/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    holidays: Ember.computed.readOnly('model'),
    sort: 'starts_at',
    sortDirection: 'asc',
    pageSize: 100,
    deleteHolidayTask: (0, _emberConcurrency.task)(function* (holiday) {
      holiday = yield holiday;
      holiday.deleteRecord();

      try {
        yield holiday.save();
      } catch (error) {
        holiday.rollbackAttributes();
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to remove."
        });
      }
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }

        this.send('refresh');
      },

      delete_holiday(holiday) {
        this.send('open_modal', 'delete-confirmation-modal', {
          entity: holiday,
          i18nKey: 'delete-holiday',
          i18nParams: {
            name: holiday.get('name')
          },
          approve: () => {
            this.get('deleteHolidayTask').perform(holiday).then(() => {
              this.send('refresh');
            });
          }
        });
      },

      refresh() {
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});