define("manage/pods/companies/company/projects/project/orders/view/components/view-product-key-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Cg8+EVW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"text\"],[8],[0,\"\\n  \"],[1,[24,[\"digital_key\",\"product\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"actions large\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"digital_key\",\"key\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"data-tooltip\",[22,\"keyCopyMessage\"]],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"left center\"],[8],[0,\"\\n\"],[4,\"copy-button\",null,[[\"class\",\"clipboardText\",\"success\",\"error\"],[\"digital-product\",[24,[\"digital_key\",\"key\"]],[28,\"action\",[[23,0,[]],\"copied\",\"keyCopyMessage\"],null],[28,\"action\",[[23,0,[]],\"copyError\",\"keyCopyMessage\"],null]]],{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"icon linkify\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[11,\"data-tooltip\",[22,\"claimUrlCopyMessage\"]],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"left center\"],[8],[0,\"\\n\"],[4,\"copy-button\",null,[[\"class\",\"clipboardText\",\"success\",\"error\"],[\"digital-product\",[24,[\"digital_key\",\"claim_url\"]],[28,\"action\",[[23,0,[]],\"copied\",\"claimUrlCopyMessage\"],null],[28,\"action\",[[23,0,[]],\"copyError\",\"claimUrlCopyMessage\"],null]]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"icon linkify\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/view-product-key-row/template.hbs"
    }
  });

  _exports.default = _default;
});