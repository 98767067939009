define("manage/pods/companies/company/projects/project/sources/configurations/edit/index/route", ["exports", "manage/mixins/handle-transition", "manage/config/environment"], function (_exports, _handleTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    projectConfigurationTasks: Ember.inject.service(),

    model() {
      let project = this.modelFor('companies.company.projects.project');
      let source = this.modelFor('companies.company.projects.project.sources');
      let configuration = this.modelFor('companies.company.projects.project.sources.configurations.edit');
      return Ember.RSVP.hash({
        project: project,
        all_products: this.store.query('product', {
          filter: {
            project: project.get('id'),
            product_type: ['physical', 'digital', 'question', 'option']
          },
          include: ['questions'].join(','),
          page: {
            size: _environment.default['max-page-size']
          }
        }),
        source: source,
        configuration: this.store.findRecord('project-configuration', configuration.get('id'), {
          include: ['product-reward', 'product-reward.mappings', 'product-reward.mappings.child', 'product-reward.mappings.child.questions', 'product-reward.zones', 'product-reward.zones.locations'].join(',')
        })
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.configuration) {
          let configuration = record.configuration;
          this.handleTransition(transition, resolve => {
            this.get('projectConfigurationTasks.isDirtyProductReward').perform(configuration).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: configuration,
                  modelName: "Configuration",
                  serviceTaskAction: this.get('projectConfigurationTasks.rollbackProductReward'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});