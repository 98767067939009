define("manage/pods/companies/company/projects/project/sources/configurations/index/components/column-bundle-can-be-extra/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O0WBjDsA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"configuration\",\"product_reward\",\"bundle_can_be_extra\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"green icon checkmark\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/configurations/index/components/column-bundle-can-be-extra/template.hbs"
    }
  });

  _exports.default = _default;
});