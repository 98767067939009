define("manage/pods/admin/companies/company/projects/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('admin.companies.company');
    }

  });

  _exports.default = _default;
});