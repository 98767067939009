define("manage/pods/companies/company/projects/project/customers/view/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    project: Ember.computed.readOnly('model.project'),
    customer: Ember.computed.readOnly('model.customer'),
    flashMessages: Ember.inject.service(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      let customer = yield this.get('customer');
      let errorMessage = '';
      let duplicateEmail = null;

      try {
        yield customer.save();
      } catch (error) {
        if (error && error.errors) {
          for (var i = 0, e; i < error.errors.length; i++) {
            e = error.errors[i];

            if (e.meta && e.meta.code === ':duplicate_email') {
              duplicateEmail = customer.get('email');
            }

            if (e.source) {
              let property = Ember.String.capitalize(e.source.pointer.replace('/data/attributes/', ''));
              errorMessage += property + ' ' + e.title + '\r\n';
            } else {
              errorMessage += e.title + '\r\n';
            }
          }
        }

        customer.rollbackAttributes();
      }

      if (duplicateEmail) {
        if (this.get('current_user.is_admin')) {
          let project = yield customer.get('project');
          let otherCustomers = yield this.get('store').query('customer', {
            filter: {
              email: duplicateEmail,
              project: project.get('id')
            }
          });

          if (otherCustomers.get('length') === 1) {
            this.send('open_modal', 'companies/company/projects/project/orders/merge-users-modal', {
              customer: customer,
              othercustomer: otherCustomers.objectAt(0),
              reload: () => {
                window.location.reload();
              }
            });
          } else {
            this.send('open_modal', 'companies/company/projects/project/orders/duplicate-email-modal', {
              customer: customer,
              email: duplicateEmail
            });
          }
        } else {
          this.send('open_modal', 'companies/company/projects/project/orders/duplicate-email-modal', {
            customer: customer,
            email: duplicateEmail
          });
        }
      } else if (errorMessage) {
        this.send('open_modal', 'error-modal', {
          description: `There was an error updating the email. ${errorMessage}`
        });
      }

      try {
        yield customer.reload();
      } catch (e) {
        this.send('open_modal', 'error-modal', {
          error: e,
          description: "There was an error while trying to update the email."
        });
      }
    }).drop(),
    actions: {
      copied(text) {
        this.get('flashMessages').success(text);
      }

    }
  });

  _exports.default = _default;
});