define("manage/mixins/filters/order-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    order_address_options: [Ember.Object.create({
      id: 'completed_shipping_address',
      title: 'Completed Shipping Address'
    }), Ember.Object.create({
      id: 'incomplete_shipping_address',
      title: 'Incomplete Shipping Address'
    }), Ember.Object.create({
      id: 'shipping_address_has_phone_number',
      title: 'Shipping Address with Phone Number'
    }), Ember.Object.create({
      id: 'shipping_address_missing_phone_number',
      title: 'Shipping Address without Phone Number'
    }), Ember.Object.create({
      id: 'local_pickup',
      title: 'Order with Local Pickup'
    })]
  });

  _exports.default = _default;
});