define("manage/pods/companies/company/projects/project/stats/transactions/index/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    host: _environment.default.urls.api,
    project: Ember.computed.readOnly('model.project'),
    session: Ember.inject.service()
  });

  _exports.default = _default;
});