define("manage/pods/companies/company/projects/project/apps/view/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.alias('model.project'),
    app: Ember.computed.alias('model.app')
  });

  _exports.default = _default;
});