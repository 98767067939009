define("manage/pods/companies/company/projects/project/sources/configurations/index/columns", ["exports", "manage/mixins/columns", "shared/helpers/can-access-feature"], function (_exports, _columns, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'configuration',
    componentPrefix: 'companies/company/projects/project/sources/configurations/index',
    i18nPrefix: 'sources.configurations.index',
    current_user: Ember.inject.service('user'),
    colDefs: function (model) {
      let current_user = this.get('current_user');
      let columnInfo = [{
        id: 'id',
        sort: 'id'
      }];

      if (current_user.get('is_admin')) {
        columnInfo.push({
          id: 'external_id',
          sort: 'external_id',
          class: 'admin'
        });
        columnInfo.push({
          id: 'reward_id',
          sort: 'product_reward_id',
          class: 'admin'
        });
      }

      columnInfo.push({
        id: 'name',
        sort: 'product_reward.name',
        default_visible: true,
        class: 'name'
      }, {
        id: 'price',
        sort: 'product_reward.price_cents',
        default_visible: true
      }, {
        id: 'products',
        default_visible: true
      }, {
        id: 'orders',
        default_visible: true,
        no_link: true,
        class: 'orders'
      }, {
        id: 'externals',
        no_link: true,
        class: 'externals'
      }, {
        id: 'status',
        sort: 'status',
        default_visible: true,
        no_link: true,
        class: 'status'
      }, {
        id: 'extras',
        class: 'summary'
      }, {
        id: 'locations',
        class: 'summary'
      });

      if ((0, _canAccessFeature.canAccessFeature)([model.project, 'send-configuration-email', current_user])) {
        columnInfo.push({
          id: 'email',
          no_link: true,
          class: 'email'
        });
      }

      if (current_user.get('is_admin')) {
        columnInfo.push({
          id: 'bundle_can_be_extra',
          class: 'admin'
        });
      }

      return columnInfo;
    }
  });

  _exports.default = _default;
});