define("manage/pods/companies/company/projects/project/sources/configurations/index/controller", ["exports", "ember-concurrency", "manage/mixins/tabular-checkboxes", "manage/mixins/error-from-xhr"], function (_exports, _emberConcurrency, _tabularCheckboxes, _errorFromXhr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tabularCheckboxes.default, (0, _errorFromXhr.default)(), {
    queryParams: ['page', 'sort', 'sortDirection', 'status', 'search'],
    project: Ember.computed.readOnly('model.project'),
    source: Ember.computed.readOnly('model.source'),
    configurations: Ember.computed.readOnly('model.configurations'),
    // for the tabular checkboxes
    tabularCheckboxItems: Ember.computed.alias('configurations'),
    current_user: Ember.inject.service('user'),
    intl: Ember.inject.service(),
    hide_error: false,
    status_options: Ember.computed(function () {
      let intl = this.intl;
      return [{
        id: 'setup',
        icon: 'setting'
      }, {
        id: 'live',
        icon: 'video play'
      }, {
        id: 'complete',
        icon: 'stop'
      }].map(function (obj) {
        obj['title'] = intl.t(`route.companies.company.projects.project.sources.configurations.index.status_option.${obj.id}.title`);
        obj['description'] = intl.t(`route.companies.company.projects.project.sources.configurations.index.status_option.${obj.id}.description`);
        return Ember.Object.create(obj);
      });
    }),
    // Paging
    page: 1,
    page_size: 15,
    // Sorting
    sort: 'product_reward.price_cents',
    sortDirection: 'asc',
    // Filtering
    search: null,
    status: '',
    filter_text: Ember.computed('search', 'status', function () {
      if (Ember.isBlank(this.get('search')) && Ember.isBlank(this.get('status'))) {
        return null;
      }

      let filters = [];
      ['search', 'status'].forEach(filter => {
        if (Ember.isPresent(this.get(filter))) {
          let label = this.intl.t(`route.companies.company.projects.project.sources.configurations.index.filter.${filter}`);
          filters.push(`${label}: <b>${this.get(filter)}</b>`);
        }
      });
      return Ember.String.htmlSafe(`${this.get('intl').t('route.companies.company.projects.project.sources.configurations.index.filter.filter')} - ${filters.join(', ')}`);
    }),
    deleteConfigurationTask: (0, _emberConcurrency.task)(function* (configuration) {
      try {
        yield configuration.destroyRecord();
      } catch (e) {
        configuration.rollbackAttributes();
        this.send('open_modal', 'error-modal', {
          error: e,
          description: this.errorFromXhr(e)
        });
      }
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      delete_configuration(configuration) {
        if (this.get('deleteConfigurationTask.isRunning')) {
          return;
        }

        this.send('open_modal', 'delete-confirmation-modal', {
          entity: configuration,
          i18nKey: 'delete-configuration',
          i18nParams: {
            name: configuration.get('product_reward.name'),
            reward: this.get('project.t.lines.reward')
          },
          approve: () => {
            this.get('deleteConfigurationTask').perform(configuration);
          }
        });
      },

      clone_configuration(configuration) {
        this.send('open_modal', 'companies/company/projects/project/sources/configurations/components/clone-configuration-modal', {
          configuration: configuration,
          onSuccess: result => {
            this.transitionToRoute('companies.company.projects.project.sources.configurations.edit', result.data.relationships.source.data.id, result.data.id);
          },
          onFailure: error => {
            this.send('open_modal', 'error-modal', {
              error: error,
              description: this.intl.t('route.companies.company.projects.project.sources.configurations.index.error.cannot_clone')
            });
          }
        });
      },

      toggle_check_all() {
        this.toggle_check_all();
      },

      toggle_configuration(configuration) {
        this.toggle_item(configuration);
      },

      reset_page() {
        this.set('page', 1);
      },

      review_configurations() {
        this.transitionToRoute('companies.company.projects.project.sources.configurations.review', {
          queryParams: {
            configurations: this.get('selectedItems').mapBy('id').join(',')
          }
        });
      }

    }
  });

  _exports.default = _default;
});