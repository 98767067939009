define("manage/pods/users/invitation/accepted/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ASIjwBIU",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Accepted Invitation\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui basic segment centered\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    Your account has been created\\n  \"],[9],[0,\"\\n  Please \"],[5,\"link-to\",[],[[\"@route\"],[\"user.login\"]],{\"statements\":[[0,\"login\"]],\"parameters\":[]}],[0,\" to continue.\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/users/invitation/accepted/template.hbs"
    }
  });

  _exports.default = _default;
});