define("manage/pods/companies/company/projects/project/orders/view/components/delete-transaction-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RaOelQ4M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Delete Credit\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui basic segment form \",[28,\"if\",[[24,[\"loading\"]],\"loading\"],null],\" content\"]]],[8],[0,\"\\n  Removing this credit will permanently remove this credit of \"],[1,[28,\"format-number\",[[24,[\"transaction\",\"amount\"]]],[[\"currency\"],[[24,[\"transaction\",\"project\",\"currency\"]]]]],false],[0,\" from the customers account.\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small orange header\"],[8],[0,\"\\n    Are you sure?\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button negative\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[1,[28,\"t\",[\"phrase.delete\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/delete-transaction-modal/template.hbs"
    }
  });

  _exports.default = _default;
});