define("manage/components/color-lens/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['color-lens', 'component'],
    attributeBindings: ['tooltip:data-tooltip', 'position:data-position'],
    position: "right center",

    didInsertElement() {
      this._super(...arguments);

      let color = this._getValue();

      let _this = this;

      (0, _jquery.default)(this.element).attr('value', color); // There is a bug I can't find a fix for where if you click one colorPicker and
      // then click another without clicking away, it won't close unless you click again.
      // Their api is pretty hacky, we would essentially need to build it in ember to fix the problem

      (0, _jquery.default)(this.element).colorPicker({
        opacity: false,
        doRender: false,
        margin: '4px -2px 0',

        buildCallback($elm) {
          let colorInstance = this.color,
              colorPicker = this;
          $elm.prepend('<div class="cp-panel">' + 'HEX Code: <input type="text" class="cp-HEX" />' + '</div>').on('change.color-picker', 'input', function () {
            colorInstance.setColor(this.value, 'HEX');
            colorPicker.render(); // this.blur();
          });
        },

        cssAddon: // could also be in a css file instead
        '.cp-color-picker{box-sizing:border-box; padding-bottom:21px;}' + '.cp-color-picker .cp-panel {position: absolute; bottom: 3px; right: 5px;}' + '.cp-xy-slider:active {cursor:none;}' + '.cp-panel input {color:#bbb; font-family:monospace,' + '"Courier New",Courier,mono; font-size:14px; font-weight:bold;}' + '.cp-panel input {width:65px; padding:2px 3px 1px;' + 'text-align:right; line-height:15px; background:transparent;' + 'border:1px solid; border-color:#222 #666 #666 #222; letter-spacing: 0.5px; margin-left: 4px;}',

        renderCallback($elm, toggled) {
          // this.color seems to be a shared instance, so if we are opening the reset the input color value
          if (toggled === true) {
            let value = _this._getValue();

            (0, _jquery.default)('.cp-panel input').val(value);
            this.color.setColor(value, 'HEX');
            this.render();
          } else {
            // Otherwise its closing or open, set the input value
            (0, _jquery.default)('.cp-panel input').val(this.color.colors.HEX);
          } // We are already open and changing callbacks


          if (toggled == null) {
            _this.get('onChange')("#" + this.color.colors.HEX);
          }
        },

        positionCallback() {
          let element = (0, _jquery.default)(_this.element);
          let width = element.width() / 2 - 2;
          let height = element.height() / 2 - 2;
          let offset = element.offset();
          return {
            left: offset.left + width + 'px',
            top: offset.top + height + 'px'
          };
        }

      });
    },

    outerStyle: Ember.computed('value', 'defaultValue', function () {
      let value = this._getValue();

      value = this._invert(value);
      return Ember.String.htmlSafe(`background-color: ${value};`);
    }),
    innerStyle: Ember.computed('value', 'defaultValue', function () {
      let value = this._getValue();

      return Ember.String.htmlSafe(`background-color: ${value};`);
    }),

    _invert(value) {
      value = value.toUpperCase();
      let split = value.split("");
      split.shift(); // Remove starting #

      let result = "";
      let simple = "FEDCBA9876".split("");
      let complex = {
        A: "5",
        B: "4",
        C: "3",
        D: "2",
        E: "1",
        F: "0"
      };

      for (let i = 0; i < 6; i++) {
        if (!isNaN(split[i])) {
          result += simple[split[i]];
        } else if (complex[split[i]]) {
          result += complex[split[i]];
        }
      }

      return "#" + result;
    },

    _getValue() {
      let value = this.get('value');

      if (this._isColor(value)) {
        return value;
      }

      let defaultValue = this.get('defaultValue') || "";
      defaultValue = defaultValue.string || defaultValue;

      if (this._isColor(defaultValue)) {
        return defaultValue;
      }

      return "#FFFFFF";
    },

    _isColor(value) {
      return Ember.isPresent(value) && value.indexOf('#') === 0 && value.length === 7;
    }

  });

  _exports.default = _default;
});