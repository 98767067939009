define("manage/models/digital-fulfillment", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('digital-fulfillment').reopen({
    project_email_name: Ember.computed.readOnly('project_email.name'),
    product_name: Ember.computed.readOnly('product.name')
  });

  _exports.default = _default;
});