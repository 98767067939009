define("manage/pods/application/route", ["exports", "shared/utils/logger", "authentication/mixins/application-route", "helpdocs/mixins/application-route", "manage/mixins/modal-actions", "shared/utils/load-geography", "manage/utils/load-holidays", "manage/config/environment", "@sentry/browser"], function (_exports, _logger, _applicationRoute, _applicationRoute2, _modalActions, _loadGeography, _loadHolidays, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRoute.default, _modalActions.default, _applicationRoute2.default, {
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),

    beforeModel() {
      return Ember.RSVP.resolve(this._super(...arguments)).then(() => this.get('intl').setLocale('en-us'));
    },

    model() {
      if (this.get('session.isAuthenticated')) {
        return Ember.RSVP.hash({
          geography: (0, _loadGeography.default)(this.get('store')),
          holidays: (0, _loadHolidays.default)(this.get('store'))
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },

    actions: {
      reportError(error, context, route) {
        if (typeof route !== "undefined" && route !== null) {
          context = Object.assign(context, {
            server_revision: route.get('version.server_version'),
            browser_revision: route.get('version.client_version')
          });
        }

        Sentry.captureException(error, {
          extra: context
        });

        if (_environment.default.environment === 'development') {
          return (0, _logger.logger_error)(error);
        }
      }

    }
  });

  _exports.default = _default;
});