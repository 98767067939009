define("manage/models/project-stats", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-stats').reopen({
    total_orders_to_import: Ember.computed('kickstarter_order_count', 'indiegogo_order_count', 'fig_order_count', function () {
      return this.get('kickstarter_order_count') + this.get('indiegogo_order_count') + this.get('fig_order_count');
    })
  });

  _exports.default = _default;
});