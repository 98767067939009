define("manage/pods/companies/company/projects/project/stats/products/completed/controller", ["exports", "manage/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    config: _environment.default,
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    product_stats: Ember.computed.readOnly('model.product_stats'),
    friendly_text: null,
    current_user: Ember.inject.service('user'),
    queryParams: ['type', 'page', 'page_size', 'sort', 'sort_direction', 'search', 'shipping_country'],
    // Type
    type: 'product_stat',
    type_title: Ember.computed('type', 'types', function () {
      return this.get('intl').t('route.companies.company.projects.project.stats.products.completed.header') + ' - ' + this.get('intl').t('route.companies.company.projects.project.stats.products.completed.type.option.' + this.get('type'));
    }),
    types: [Ember.Object.create({
      id: 'product_stat'
    }), Ember.Object.create({
      id: 'product_variation_stat'
    }), Ember.Object.create({
      id: 'product_sku_stat'
    })],
    // Filters
    page: 1,
    page_size: 10,
    sort: '',
    sort_direction: 'asc',
    search: '',
    shipping_country: '',
    filter_data: Ember.computed('search', 'shipping_country', function () {
      return {
        // Search
        search: this.get('search'),
        // Location
        shipping_country: this.get('shipping_country')
      };
    }),
    export_filter_data: Ember.computed('filter_data', 'type', function () {
      let searchKey;

      if (this.type === "product_sku_stat") {
        searchKey = "product_sku";
      } else if (this.type === "product_variation_stat") {
        searchKey = "product_variation_name";
      } else {
        searchKey = "product_name";
      } // Rename the "search" filter to be what we calculate for searchKey above.
      // Split the search terms by commas, append asterisks to the beginning and end of each search term
      // to perform a substring search during export, then join by commas again.


      let data = Object.assign({}, this.filter_data);
      data[searchKey] = data.search.split(/,\s*/).map(term => `*${term}*`).join(',');
      delete data.search;
      return data;
    }),

    build_filters(project, params) {
      let sort = null;

      if (Ember.isPresent(params.get('sort'))) {
        sort = params.get('sort_direction') === 'desc' ? `-${params.get('sort')}` : params.get('sort');
      }

      return {
        filter: {
          project: project.get('id'),
          search: params.get('search'),
          shipping_country: params.get('shipping_country')
        },
        page: {
          number: params.get('page'),
          size: params.get('page_size')
        },
        sort: sort
      };
    },

    url_params: Ember.computed('project.id', 'type', 'search', 'shipping_country', function () {
      let filters = {
        project_id: this.get('project.id'),
        file_format: this.get('type'),
        search: this.get('search'),
        shipping_country: this.get('shipping_country')
      };
      return _jquery.default.param(filters);
    }),
    actions: {
      sort(field, direction) {
        if (this.get('sort') === field) {
          this.set('sort_direction', direction);
        } else {
          this.set('sort', field);
          this.set('sort_direction', 'asc');
        }
      },

      update_query_params(values) {
        this.setProperties(values); // Also update page to 1

        this.set('page', 1);
      }

    }
  });

  _exports.default = _default;
});