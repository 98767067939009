define("manage/pods/companies/company/projects/project/digital/components/upload-keys-modal/component", ["exports", "manage/mixins/ss-modal", "manage/config/environment"], function (_exports, _ssModal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['upload-keys-modal', 'component'],
    ajax: Ember.inject.service(),
    fulfillment: null,
    jobId: null,
    api_url: _environment.default.urls.api,

    imported() {},

    importUrl: Ember.computed('fulfillment', 'fileId', function () {
      return `${this.get('ajax').buildModelUrl(this.get('fulfillment'), 'upload_keys')}?file_id=${this.get('fileId')}`;
    }),
    importPreviewUrl: Ember.computed('fulfillment', function () {
      return `${this.get('ajax').buildModelUrl(this.get('fulfillment'), 'upload_keys')}?preview=true`;
    }),

    processJobResult(result) {
      this.set('error', null);
      this.set('jobId', result.data.id);

      if (result.meta) {
        this.set('fileId', result.meta.file_id);
      }
    },

    processError(xhr) {
      this.set('jobId', null);

      if (xhr.payload && xhr.payload.errors) {
        this.set('error', xhr.payload.errors.map(error => error.detail || error.title).join(', '));
      } else {
        this.set('error', xhr.message || 'The upload failed');
      }
    },

    actions: {
      cancel() {
        this.closeModal();
      },

      uploadSuccess(result) {
        this.processJobResult(result);
        this.set('mode', 'preview');
      },

      uploadError(xhr) {
        this.set('error', xhr.responseText || xhr.message);
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      processNow() {
        this.set('jobId', null);
        this.get('ajax').post(this.get('importUrl')).then(result => {
          this.processJobResult({
            data: {
              id: result.data.id
            }
          });
          this.set('mode', 'live');
        }).catch(xhr => {
          this.processError(xhr);
        });
      },

      jobCompleted() {
        this.imported();
      }

    }
  });

  _exports.default = _default;
});