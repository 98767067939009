define("manage/mixins/filters/fulfillments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fulfillment_options: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'in_fulfillment',
        title: 'In Fulfillment'
      }), Ember.Object.create({
        id: 'not_in_fulfillment',
        title: 'Not In Fulfillment'
      })];
    })
  });

  _exports.default = _default;
});