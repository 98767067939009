define("manage/pods/companies/company/projects/project/welcome/authorization/kickstarter/controller", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/pods/companies/company/projects/project/welcome/wizard"], function (_exports, _emberConcurrency, _catchReal, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_wizard.default, {
    project: Ember.computed.readOnly('model'),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    stepName: 'authorization',
    source: Ember.computed('project.sources.[]', function () {
      return this.get('project.sources.firstObject');
    }),
    sourceTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      let sources = yield project.get('sources');
      let source = sources.findBy('is_kickstarter', true);
      source.set('settings.wizard.access_requested_on', new Date());
      yield source.save();
      yield this.get('advanceTask').perform(project);
    }),
    actions: {
      copied(text) {
        this.get('flashMessages').success(text);
      },

      submit() {
        if (this.get('sourceTask.isRunning')) {
          return;
        }

        let project = this.get('project');
        let intl = this.get('intl');
        this.get('sourceTask').perform(project).then(() => this.transitionToRoute(this.get('nextRoute')(project))).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: intl.t('route.companies.company.projects.project.welcome.error.unable_to_save')
          });
        }));
      },

      next() {
        this.send('advance');
      }

    }
  });

  _exports.default = _default;
});