define("manage/pods/companies/company/projects/project/digital/view/downloads/components/column-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GP7xM0pd",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"download\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[28,\"not-eq\",[[24,[\"download\",\"status\"]],\"ready\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-size\",\"medium\"],[10,\"data-inverted\",\"\"],[10,\"data-tooltip\",\"There was a problem uploading the complete file. If this doesn't resolve in 15 minutes, try again. If that doesn't work please contact Customer Success.\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"yellow exclamation triangle icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/digital/view/downloads/components/column-name/template.hbs"
    }
  });

  _exports.default = _default;
});