define("manage/helpers/t-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [path] = _ref;
      return this.get('intl').t(`route.${this.get('router.currentRouteName')}.${path}`, args);
    }

  });

  _exports.default = _default;
});