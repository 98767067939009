define("manage/pods/companies/company/projects/project/components/edit-location-zone-vat-category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+vZM+/zN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[1,[24,[\"vat_category\",\"name\"]],false],[9],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"zone\",\"vat\",\"prices\"]],[24,[\"vat_category\",\"public_id\"]],[24,[\"submitted\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui left labeled fluid input\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui basic label\"],[8],[0,\"\\n      %\\n    \"],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"min\",\"max\",\"step\",\"disabled\"],[\"number\",[24,[\"percent_decimal\"]],[24,[\"placeholder\"]],\"0\",\"100\",\"0.01\",[24,[\"disable_shipping\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/components/edit-location-zone-vat-category/template.hbs"
    }
  });

  _exports.default = _default;
});