define("manage/pods/companies/company/projects/project/orders/view/components/delete-transaction-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    actions: {
      approve() {
        this.set('loading', true);
        this.get('transaction').deleteRecord();
        this.get('transaction').save().then(() => {
          this.closeModal();
        }).catch(function (response) {
          alert(response);
        }).finally(() => {
          this.set('loading', false);
        });
      }

    }
  });

  _exports.default = _default;
});