define("manage/pods/companies/company/projects/project/products/choices/components/edit-product-trait/component", ["exports", "manage/mixins/image-upload"], function (_exports, _imageUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend((0, _imageUpload.default)('product.image_path'), {
    // making more unique to avoid common work issues
    classNames: ['bundle-edit-product-trait', 'component']
  });

  _exports.default = _default;
});