define("manage/pods/companies/company/projects/project/questions/components/create-product-question-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    closable: false,
    classNames: ['create-product-question-modal', 'component'],
    attributeBindings: ['modalName:data-test-modal'],
    modalName: 'create-product-question-modal',
    actions: {
      create(type) {
        this.get('create')(type);
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});