define("manage/pods/companies/company/projects/project/sources/pre-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VsTpIZcG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"has_started\"]]],null,{\"statements\":[[0,\"  \"],[15,\"companies/company/projects/project/sources/pre-order/get-started\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,\"companies/company/projects/project/sources/pre-order/do-setup\",[]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/pre-order/template.hbs"
    }
  });

  _exports.default = _default;
});