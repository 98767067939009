define("manage/templates/shared/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CEpAc3gL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"manage outlet\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sidebar\"],[8],[0,\"\\n    \"],[15,\"sidebar\",[]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content dimmable\"],[8],[0,\"\\n\"],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui hidden inverted dimmer\"],[10,\"id\",\"page-dimmer\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui text loader\"],[8],[1,[28,\"t\",[\"phrase.loading\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "manage/templates/shared/main.hbs"
    }
  });

  _exports.default = _default;
});