define("manage/pods/companies/company/projects/project/launch-guide/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model'),
    current_user: Ember.inject.service('user'),
    onboarding: Ember.A(['lg-project-created', 'lg-source-imported', 'lg-source-authorized', 'lg-support-email', 'lg-add-products', 'lg-unknown-products', 'lg-add-questions', 'lg-setup-configurations', 'lg-review-imported-addons', 'lg-sell-extras']),
    personalization: Ember.A(['lg-customize-theme', 'lg-review-email', 'lg-local-pickup', 'lg-shipping-selection']),
    launch: Ember.A(['lg-pre-orders', 'lg-connect-accounts', 'lg-project-settings', 'lg-request-review'])
  });

  _exports.default = _default;
});