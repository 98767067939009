define("manage/pods/companies/company/projects/project/sources/configurations/components/email-customers-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    amount: null,
    send_to_everyone: true,
    set_amount: Ember.observer('configuration.stats.total_orders', 'configuration.stats.emailed_orders', function () {
      var whats_left = this.get('configuration.stats.total_orders') - this.get('configuration.stats.emailed_orders');
      return this.set('amount', whats_left > 0 ? whats_left : 0);
    }),
    isRunning: Ember.computed.or('emailCustomersTask.isRunning', 'jobId'),
    emailCustomersTask: (0, _emberConcurrency.task)(function* (type) {
      let configuration = yield this.get('configuration');
      let data = {
        data: {
          configuration_id: configuration.get('id'),
          type: type
        }
      };

      if (!this.get('send_to_everyone')) {
        data.data.count = parseInt(this.get('amount'), 10);
      }

      try {
        let result = yield this.get('ajax').post('/api/v2/orders/emails', data);
        this.set('jobId', result.data.id);
      } catch (xhr) {
        if (xhr.payload && xhr.payload.error) {
          this.set('error', xhr.payload.error);
        } else {
          this.set('error', xhr.responseText);
        }
      }
    }),
    actions: {
      cancel() {
        this.closeModal();
      },

      send(type) {
        this.set('error', null);
        this.get('emailCustomersTask').perform(type);
      },

      complete() {
        this.get('configuration').reload();
        this.closeModal();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});