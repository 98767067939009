define("manage/pods/companies/company/projects/project/digital/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('digital-fulfillment', params.fulfillment_id, {
        include: ['product'].join(',')
      });
    },

    redirect(model
    /* , transition */
    ) {
      if (model.get('sub_type') === 'key' || model.get('sub_type') === 'steam') {
        this.transitionTo('companies.company.projects.project.digital.view.keys');
      }

      if (model.get('sub_type') === 'download') {
        this.transitionTo('companies.company.projects.project.digital.view.downloads');
      }
    }

  });

  _exports.default = _default;
});