define("manage/initializers/extend-models", ["exports", "manage/mixins/models/customer", "manage/mixins/models/company", "manage/mixins/models/country", "manage/mixins/models/order", "manage/mixins/models/order/address", "manage/mixins/models/order/line", "manage/mixins/models/order/selection", "manage/mixins/models/order/transaction", "manage/mixins/models/product", "manage/mixins/models/product/zone-location", "manage/mixins/models/product/zone", "manage/mixins/models/product/mapping", "manage/mixins/models/product/question", "manage/mixins/models/product/question-choice", "manage/mixins/models/product/variant-value", "manage/mixins/models/product/variant", "manage/mixins/models/product/variation", "manage/mixins/models/project", "manage/mixins/models/project/address", "manage/mixins/models/project/configuration", "manage/mixins/models/project/zone-location", "manage/mixins/models/project/zone", "manage/mixins/models/project/source", "manage/mixins/models/project/theme", "manage/mixins/models/state", "manage/mixins/models/user"], function (_exports, _customer, _company, _country, _order, _address, _line, _selection, _transaction, _product, _zoneLocation, _zone, _mapping, _question, _questionChoice, _variantValue, _variant, _variation, _project, _address2, _configuration, _zoneLocation2, _zone2, _source, _theme, _state, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // Mixins
  function
    /* application */
  initialize() {
    // Models - We have to late load these so they generate properly first
    window.require('manage/models/customer')['default'].reopen(_customer.default); // eslint-disable-line no-undef


    window.require('manage/models/company')['default'].reopen(_company.default); // eslint-disable-line no-undef


    window.require('manage/models/country')['default'].reopen(_country.default); // eslint-disable-line no-undef


    window.require('manage/models/order')['default'].reopen(_order.default); // eslint-disable-line no-undef


    window.require('manage/models/order-address')['default'].reopen(_address.default); // eslint-disable-line no-undef


    window.require('manage/models/order-line')['default'].reopen(_line.default); // eslint-disable-line no-undef


    window.require('manage/models/order-selection')['default'].reopen(_selection.default); // eslint-disable-line no-undef


    window.require('manage/models/order-transaction')['default'].reopen(_transaction.default); // eslint-disable-line no-undef


    window.require('manage/models/product')['default'].reopen(_product.default); // eslint-disable-line no-undef


    window.require('manage/models/product-zone-location')['default'].reopen(_zoneLocation.default); // eslint-disable-line no-undef


    window.require('manage/models/product-zone')['default'].reopen(_zone.default); // eslint-disable-line no-undef


    window.require('manage/models/product-mapping')['default'].reopen(_mapping.default); // eslint-disable-line no-undef


    window.require('manage/models/product-question')['default'].reopen(_question.default); // eslint-disable-line no-undef


    window.require('manage/models/product-question-choice')['default'].reopen(_questionChoice.default); // eslint-disable-line no-undef


    window.require('manage/models/product-variant-value')['default'].reopen(_variantValue.default); // eslint-disable-line no-undef


    window.require('manage/models/product-variant')['default'].reopen(_variant.default); // eslint-disable-line no-undef


    window.require('manage/models/product-variation')['default'].reopen(_variation.default); // eslint-disable-line no-undef


    window.require('manage/models/project')['default'].reopen(_project.default); // eslint-disable-line no-undef


    window.require('manage/models/project-address')['default'].reopen(_address2.default); // eslint-disable-line no-undef


    window.require('manage/models/project-configuration')['default'].reopen(_configuration.default); // eslint-disable-line no-undef


    window.require('manage/models/project-zone-location')['default'].reopen(_zoneLocation2.default); // eslint-disable-line no-undef


    window.require('manage/models/project-zone')['default'].reopen(_zone2.default); // eslint-disable-line no-undef


    window.require('manage/models/project-source')['default'].reopen(_source.default); // eslint-disable-line no-undef


    window.require('manage/models/project-theme')['default'].reopen(_theme.default); // eslint-disable-line no-undef


    window.require('manage/models/pre-order-theme')['default'].reopen(_theme.default); // eslint-disable-line no-undef


    window.require('manage/models/state')['default'].reopen(_state.default); // eslint-disable-line no-undef


    window.require('manage/models/project-source')['default'].reopenClass({
      // eslint-disable-line no-undef
      preview_from_url(url) {
        var key = "project/source";
        var adapter = window.Manage.lookup('adapter:application');
        var url_path = adapter.buildURL(key);
        var ajax = window.Manage.lookup('service:ajax');
        return ajax.request(`${url_path}/preview_from_url`, {
          data: {
            url: url
          }
        });
      },

      create_from_url(company_id, url, project_id, category_id, child_category_id) {
        var key = "project/source";
        var adapter = window.Manage.lookup('adapter:application');
        var url_path = adapter.buildURL(key);
        var ajax = window.Manage.lookup('service:ajax');
        return ajax.post(`${url_path}/create_from_url`, {
          data: {
            company_id,
            // Project id is used when importing from add source
            project_id,
            // These two are used when importing a project for the first time
            category_id,
            child_category_id,
            url
          }
        });
      }

    });

    window.require('manage/models/user')['default'].reopen(_user.default); // eslint-disable-line no-undef

  }

  var _default = {
    name: 'extend-models',
    initialize,
    after: ['generate-models', 'ember-data']
  };
  _exports.default = _default;
});