define("manage/pods/companies/company/projects/project/components/location-zone-percent/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    zone: null,
    // passed in
    project: Ember.computed.readOnly('zone.project')
  });

  _exports.default = _default;
});