define("manage/pods/companies/company/projects/project/segments/actions/view/controller", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    project_segment_action: Ember.computed.readOnly('model.project_segment_action'),
    errored_orders: Ember.computed.readOnly('model.errored_orders'),
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    api_url: _environment.default.urls.api
  });

  _exports.default = _default;
});