define("manage/mixins/models/project/address", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    address_1: {
      description: "Address 1",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    address_2: {
      description: "Address 2",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    city: {
      description: "City",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    state_name: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    postal_code: {
      description: "Postal Code",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    phone_number: {
      description: "Phone Number",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  });

  var _default = Ember.Mixin.create(Validations, {});

  _exports.default = _default;
});