define("manage/pods/companies/company/projects/project/segments/components/credit-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action", "ember-cp-validations"], function (_exports, _ssModal, _segmentAction, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    amount: {
      description: "Amount",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        allowNone: false,
        gte: 0
      })]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, Validations, {
    classNames: ['credit-modal', 'segment-modal', 'component'],
    closable: false,
    type: 'credit',
    submitted: false,
    amount: null,
    note_hidden: true,
    notes: null,
    actions: {
      perform() {
        this.set('submitted', true);
        let result = this.validateSync();

        if (result.validations.get('isInvalid')) {
          return;
        }

        if (this.get('actionTask.isRunning')) {
          return;
        }

        let currency_base = this.get('project.currency_base');
        this.get('actionTask').perform({
          amount_cents: parseFloat(this.get('amount')) * currency_base,
          amount_notes: this.get('notes')
        });
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});