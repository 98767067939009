define("manage/pods/companies/company/projects/project/components/view-email-modal/component", ["exports", "manage/mixins/ss-modal", "manage/utils/write-html", "jquery"], function (_exports, _ssModal, _writeHtml, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: 'fullscreen view email',
    showTextAndHtml: true,
    view: 'html',
    attributeBindings: ['data-test-modal'],
    'data-test-modal': 'view-email',

    opened() {
      this._super(...arguments);

      _writeHtml.default.call(this, 'html', this.get('email.html'));

      _writeHtml.default.call(this, 'text', this.get('email.text'));
    },

    email: null,
    email_type: null,
    email_to: Ember.computed('email.to.{email,name}', function () {
      if (Ember.isBlank(this.get('email.to.name'))) {
        return this.get('email.to.email');
      }

      return `${this.get('email.to.name')} <${this.get('email.to.email')}>`;
    }),
    email_from: Ember.computed('email.from_email', 'email.from_name', function () {
      if (Ember.isBlank(this.get('email.from_name'))) {
        return this.get('email.from_email');
      }

      return `${this.get('email.from_name')} <${this.get('email.from_email')}>`;
    }),
    actions: {
      switch_tab(tab) {
        (0, _jquery.default)(`[data-tab]`, this.element).removeClass('active');
        (0, _jquery.default)(`[data-tab=${tab}]`, this.element).addClass('active');
      }

    }
  });

  _exports.default = _default;
});