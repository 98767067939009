define("manage/pods/companies/company/projects/project/digital/index/route", ["exports", "manage/pods/companies/company/projects/project/digital/index/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      sortProperty: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      fulfillment: {
        refreshModel: true
      }
    },

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        fulfillments: this.search(params)
      }).then(_ref => {
        let {
          project,
          fulfillments
        } = _ref;
        let data = {
          project,
          fulfillments
        };

        if (fulfillments.get('length') === 0) {
          return data;
        } // Refresh the product stats through the project, fastest load


        data.stats = this.store.query('product-stat', {
          filter: {
            id: fulfillments.mapBy("product.id")
          },
          page: {
            size: fulfillments.get('length')
          }
        });
        return Ember.RSVP.hash(data);
      });
    },

    search(params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let search = params.search;
      let pageSize = controller.get('page_size');
      let project = this.modelFor('companies.company.projects.project');
      let filter = {
        project: project.get('id'),
        search: search
      };

      if (params.fulfillment) {
        filter['sub_type'] = params.fulfillment;
      }

      return this.store.query('digital-fulfillment', {
        filter: filter,
        include: ['product'].join(','),
        page: {
          number: page,
          size: pageSize
        }
      });
    }

  });

  _exports.default = _default;
});