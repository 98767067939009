define("manage/pods/companies/company/projects/project/questions/index/route", ["exports", "manage/pods/companies/company/projects/project/questions/index/columns", "manage/config/environment"], function (_exports, _columns, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return this.store.query('product', {
        filter: {
          project: project.get('id'),
          product_type: 'question'
        },
        include: ['questions', 'questions.choices'].join(','),
        page: {
          size: _environment.default['max-page-size']
        }
      });
    }

  });

  _exports.default = _default;
});