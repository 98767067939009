define("manage/pods/companies/company/projects/project/settings/payments/components/connect-stripe/component", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['connect-stripe', 'component'],
    current_user: Ember.inject.service('user'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    api_url: _environment.default.urls.api,

    didInsertElement() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let type = 'stripe';
      let project_app = yield this.get('store').query('project-app', {
        filter: {
          project: project.get('id'),
          installed: 'true,false',
          app_slug: `payment-${type}`
        }
      }).then(results => {
        return results.objectAt(0);
      });
      let app = yield project_app.get('app');
      let response = yield project.payment_gateway_redirect(app, type);
      this.set('link', response.redirect);
    }),
    connected: Ember.computed.filterBy('payment_gateways', 'state', 'connected'),
    not_connected: Ember.computed.filter('payment_gateways', function (item) {
      return item.get('state') !== 'connected';
    }),
    payment_gateways_sorted: Ember.computed.sort('payment_gateways', function (a, b) {
      if (a.get('state') == 'connected') {
        // Connected always comes first
        return -1;
      } else if (a.get('state') < b.get('state')) {
        return -1;
      } else if (a.get('state') > b.get('state')) {
        return 1;
      }

      return 0;
    }),
    actions: {
      refresh() {
        if (this.get('afterInstall')) {
          this.get('afterInstall')();
        }
      }

    }
  });

  _exports.default = _default;
});