define("manage/pods/companies/company/projects/project/orders/view/components/send-email-modal/component", ["exports", "manage/mixins/ss-modal", "ember-cp-validations", "ember-concurrency", "manage/utils/write-html", "manage/config/environment", "jquery"], function (_exports, _ssModal, _emberCpValidations, _emberConcurrency, _writeHtml, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, Validations, {
    classNames: ['fullscreen', 'send', 'email'],
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    submitted: true,
    state: 'preview',
    order: Ember.computed.readOnly('model'),
    project: Ember.computed.readOnly('model.project'),
    type: null,
    email: null,

    init() {
      this._super(...arguments);

      this.set('submitted', false);
      this.set('state', 'start');
      this.get('initTask').perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      let emails = yield this.get('store').query('project-email', {
        filter: {
          project: this.get('order.project.id'),
          version: 2
        },
        page: {
          size: _environment.default['max-page-size']
        },
        sort: 'name'
      });
      this.set('emails', emails);
    }),
    previewTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      let {
        validations: validations
      } = yield this.validate();

      if (!validations.get('isValid')) {
        return; // Not valid return so the ui updates
      }

      let order = yield this.get('order');
      let email = yield this.get('email');
      let data = yield this.get('ajax').postModel(this.get('order.project'), 'preview_email', {
        data: {
          order_id: order.get('id'),
          project_email_id: email.get('id'),
          subject: email.get('subject'),
          body: email.get('body')
        }
      });
      this.set('state', 'preview');
      Ember.run.scheduleOnce('afterRender', this, function () {
        _writeHtml.default.call(this, 'html', data.html);

        _writeHtml.default.call(this, 'text', data.text);
      });
    }),
    sendTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      this.set('state', 'email');
      let {
        validations: validations
      } = yield this.validate();

      if (!validations.get('isValid')) {
        return; // Not valid return so the ui updates
      }

      let order = yield this.get('order');
      let email = yield this.get('email');

      try {
        yield this.get('ajax').postModel(order, 'email', {
          data: {
            project_email_id: email.get('id')
          }
        });
      } catch (e) {
        this.set('has_error', true);
        return;
      }

      this.closeModal();
      this.onCompletion();
    }).drop(),
    actions: {
      switch_tab(tab) {
        (0, _jquery.default)(`[data-tab]`, this.element).removeClass('active');
        (0, _jquery.default)(`[data-tab=${tab}]`, this.element).addClass('active');
      }

    }
  });

  _exports.default = _default;
});