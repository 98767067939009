define("manage/pods/admin/companies/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search', 'status', 'sort', 'sortDirection', 'page'],
    companies: Ember.computed.readOnly('model'),
    page_size: 20,
    // Sorting
    sortProperty: Ember.computed('sort', function () {
      return [this.get('sort')];
    }),
    // Filters
    search: '',
    status: '',
    sort: 'name',
    sortDirection: 'asc',
    page: 1,
    status_options: [{
      groupName: 'Account',
      options: [Ember.Object.create({
        id: 'invited'
      }), Ember.Object.create({
        id: 'not_invited'
      }), Ember.Object.create({
        id: 'accepted'
      }), Ember.Object.create({
        id: 'activated'
      })]
    }, {
      groupName: 'Project',
      options: [Ember.Object.create({
        id: 'project_launched'
      }), Ember.Object.create({
        id: 'project_not_launched'
      })]
    }],
    filtered_companies: Ember.computed.filter('companies.@each.name', function (item) {
      return item.get('name') !== 'Crowd Ox';
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      company_created(company) {
        return this.transitionToRoute('admin.companies.company', company.get('id'));
      }

    }
  });

  _exports.default = _default;
});