define("manage/pods/companies/company/projects/project/theme/messages/edit/route", ["exports", "manage/mixins/handle-transition", "ember-concurrency"], function (_exports, _handleTransition, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let locale = params.locale;
      return Ember.RSVP.hash({
        project: project,
        locale: locale,
        translation: this.store.query('project-translation', {
          filter: {
            project: project.get('id'),
            locale: locale
          }
        }).then(result => result.objectAt(0) || this.store.createRecord('project-translation', {
          project: project,
          locale: locale
        }))
      });
    },

    redirect(model) {
      if (model && model.project && model.project.locales.indexOf(model.locale) < 0) {
        this.transitionTo('companies.company.projects.project.theme.messages');
      }
    },

    setupController(_controller, model) {
      this._super(...arguments);

      model.translation.initializeEmptyProperties();
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    rollbackModelsTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      project.rollbackAttributes();
    }),
    actions: {
      willTransition(transition) {
        var model = this.modelFor(this.routeName);

        if (model.translation) {
          var record = model.translation;
          this.handleTransition(transition, resolve => {
            if (record.get('hasDirtyAttributes')) {
              this.send('open_modal', 'rollback-modal', {
                model: record,
                modelName: "Project Messages",
                serviceTaskAction: this.get('rollbackModelsTask'),
                promiseResolve: resolve
              });
            } else {
              // We don't have a dirty model, so mark as handled and retry
              resolve('retry');
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});