define("manage/pods/companies/company/projects/project/segments/components/lock-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action"], function (_exports, _ssModal, _segmentAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, {
    classNames: ['lock-modal', 'segment-modal', 'component'],
    closable: false,
    type: 'lock',
    process: 'manual',
    process_options: [Ember.Object.create({
      id: 'manual',
      title: 'Manual'
    }), Ember.Object.create({
      id: 'ship',
      title: 'Shipped'
    })],
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('actionTask.isRunning')) {
          return;
        }

        this.get('actionTask').perform({
          process: this.get('process')
        });
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});