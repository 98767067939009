define("manage/pods/companies/company/projects/project/orders/view/components/reopen-order-modal/component", ["exports", "manage/mixins/ss-modal", "ember-ajax/errors"], function (_exports, _ssModal, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    has_error: false,
    is_processing: false,
    order: null,
    actions: {
      approve() {
        if (this.get('is_processing')) {
          return;
        }

        this.set('is_processing', true); // Only set confirmed to true if we are exported at the moment we try to unlock

        this.get('order').uncomplete().then(() => {
          // Don't need to reload order, the uncomplete already does this
          return this.closeModal();
        }).catch(e => {
          if (e instanceof _errors.InvalidError) {
            this.get('order').reload();
          }

          return this.set('has_error', true);
        }).finally(() => {
          return this.set('is_processing', false);
        });
      }

    }
  });

  _exports.default = _default;
});