define("manage/mixins/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.router.on('routeDidChange', transition => {
        let {
          to: toRouteInfo
        } = transition;

        if (Ember.isPresent(toRouteInfo) && Ember.isPresent(toRouteInfo.queryParams.scrollTo)) {
          return Ember.run.scheduleOnce('afterRender', this, this.scrollTo, toRouteInfo.queryParams.scrollTo);
        }
      });
    },

    scrollTo(scrollToId) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      let elt = document.getElementById(scrollToId);

      if (elt !== null) {
        elt.scrollIntoView(true);
      }
    }

  });

  _exports.default = _default;
});