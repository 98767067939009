define("manage/pods/companies/company/projects/project/info/configurations/controller", ["exports", "manage/mixins/filters/info"], function (_exports, _info) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_info.default, {
    project: Ember.computed.readOnly('model')
  });

  _exports.default = _default;
});