define("manage/mixins/models/project", ["exports", "ember-cp-validations", "ember-data-model-fragments/attributes", "manage/config/environment", "manage/utils/fallback-sort", "manage/utils/dates"], function (_exports, _emberCpValidations, _attributes, _environment, _fallbackSort, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 200
      })]
    },
    slug: {
      description: "Slug",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 200
      })]
    },
    company: {
      description: "Company",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    currency_country: {
      description: "Country's Currency",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    currency: {
      description: "Currency",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    currency_symbol: {
      description: "Currency Symbol",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    urls: {
      description: "Urls",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    },
    support: {
      description: "Support",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    },
    settings: {
      description: "Settings",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    },
    unit_of_mass: {
      description: "Unit of Mass",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['kg', 'lb', 'oz']
      })]
    },
    unit_of_length: {
      description: "Unit of Length",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['cm', 'in']
      })]
    },
    shipping_type: {
      description: "Shipping Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['product_price', 'product_weight']
      })]
    },
    address_name_type: {
      description: "Address Name Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['single_name', 'split_name', 'split_name_initial']
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    intl: Ember.inject.service(),
    support: (0, _attributes.fragment)('project-support', {
      defaultValue: {}
    }),
    urls: (0, _attributes.fragment)('project-urls', {
      defaultValue: {}
    }),
    ajax: Ember.inject.service(),
    settings: (0, _attributes.fragment)('project-settings', {
      defaultValue: {}
    }),
    friendly_status: Ember.computed('in_setup', 'is_live', 'is_complete', function () {
      if (this.get('in_setup')) {
        return `The project is in the process of being setup.
              No live pledge management surveys can be completed.`;
      }

      if (this.get('is_live')) {
        return `The project is live.
              Pledge management surveys are going through the project.`;
      }

      if (this.get('is_complete')) {
        return `The project is closed to everyone.
              No pledge management surveys can be completed.`;
      }

      return "";
    }),
    // Statuses
    in_setup: Ember.computed('configurations.@each.in_setup', function () {
      if (this.get('configurations').isEvery('in_setup', true)) {
        return true;
      }

      return false;
    }),
    is_live: Ember.computed('configurations.@each.is_live', function () {
      if (this.get('configurations').isAny('is_live', true)) {
        return true;
      }

      return false;
    }),
    is_complete: Ember.computed('configurations.@each.is_complete', function () {
      if (this.get('configurations.length') === 0) {
        return false;
      }

      if (this.get('configurations').isEvery('is_complete', true)) {
        return true;
      }

      return false;
    }),
    sources_sorted: Ember.computed.sort('sources', function (a, b) {
      if (a.get('is_internal')) {
        // Internal sources always come last
        return 1;
      } else if (b.get('is_internal')) {
        // Internal sources always come last
        return -1;
      } else if (a.get('site') < b.get('site')) {
        return -1;
      } else if (a.get('site') > b.get('site')) {
        return 1;
      }

      return 0;
    }),
    status_from_configurations: Ember.computed('in_setup', 'is_live', 'is_complete', function () {
      if (this.get('in_setup')) {
        return `Setup`;
      } else if (this.get('is_complete')) {
        return `Complete`;
      }

      return `Live`;
    }),
    transaction_fee_as_percent: Ember.computed('intl.locale', 'transaction_fee', function () {
      return this.get('intl').formatNumber(parseInt(this.get('transaction_fee'), 10) / 1000, {
        style: 'percent',
        minimumFractionDigits: 2
      });
    }),
    appRegistry: Ember.inject.service(),
    libs: Ember.computed('appRegistry', 'id', function () {
      return this.get('appRegistry').forProject(this);
    }),
    installed_apps: Ember.computed.filterBy('apps', 'installed', true),
    retrieve_url: Ember.computed('slug', function () {
      let domain = _environment.default.urls.preorder;
      return `${domain}/retrieve/${this.get('slug')}`;
    }),
    // simple notification
    needs_support_email: Ember.computed('support.email', 'support.validations.attrs.email.isInvalid', function () {
      if (Ember.isBlank(this.get('support.email'))) {
        return true;
      }

      return this.get('support.validations.attrs.email.isInvalid');
    }),
    in_support_window: Ember.computed('launched_at', 'settings.support_days', function () {
      const launched_at = this.get('launched_at');
      const support_days = parseInt(this.get('settings.support_days'), 10);

      if (Ember.isBlank(launched_at) || Ember.isBlank(support_days) || isNaN(support_days)) {
        return false;
      }

      const now = new Date();
      const end_of_window = (0, _dates.addDays)(new Date(launched_at), support_days);
      return now < end_of_window;
    }),
    crowdox_source: Ember.computed('sources.@each.is_crowdox', function () {
      return this.get('sources').findBy('is_crowdox', true);
    }),
    percent_funded: Ember.computed('stats.goal', 'stats.total_ordered', function () {
      if (this.get('stats.goal') === 0) {
        return 100;
      } else {
        return this.get('stats.total_ordered') / this.get('stats.goal') * 100;
      }
    }),
    configurations_sorted: (0, _fallbackSort.default)('configurations', 'product_reward_price_cents', 'id'),
    products_sorted: (0, _fallbackSort.default)('products_actual', 'name', 'id'),
    products_actual: Ember.computed.filterBy('products', 'is_physical_or_digital', true),
    // Flags for menu
    has_unknown_products: Ember.computed('products.@each.is_unknown', function () {
      return this.get('products').filter(product => product.get('hidden') !== true).isAny('is_unknown');
    }),
    has_unreviewed_bundles: Ember.computed('products.@each.{is_bundle,reviewed,imported_by}', function () {
      return this.get('products').filter(product => {
        return product.get('is_bundle') && Ember.isPresent(product.get('imported_by')) && product.get('reviewed') !== true;
      }).get('length') > 0;
    }),
    is_launched_at: Ember.computed.notEmpty('launched_at'),
    custom_product_fields: Ember.computed.filterBy('custom_fields', 'entity', 'product'),
    custom_customer_fields: Ember.computed.filterBy('custom_fields', 'entity', 'customer'),
    custom_order_fields: Ember.computed.filterBy('custom_fields', 'entity', 'order'),

    review() {
      return this.get('ajax').requestModel(this, 'review');
    },

    payment_gateway_redirect(app, type) {
      return this.get('ajax').request(`/api/v2/project-payment-gateways/redirect/${type}`, {
        data: {
          'project_id': this.get('id'),
          'app_registry_id': app.get('id')
        }
      });
    },

    change_purpose(purpose) {
      return this.get('ajax').postModel(this, 'change-purpose', {
        data: {
          new_purpose: purpose
        }
      });
    },

    languages: Ember.computed.map('locales', function (locale) {
      return this.intl.t(`languages.${locale}`);
    })
  });

  _exports.default = _default;
});