define("manage/utils/load-models", ["exports", "ember-cli-uncharted-describe-models/utils/load-models"], function (_exports, _loadModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "loadModel", {
    enumerable: true,
    get: function () {
      return _loadModels.loadModel;
    }
  });
  Object.defineProperty(_exports, "loadModels", {
    enumerable: true,
    get: function () {
      return _loadModels.loadModels;
    }
  });
});