define("manage/pods/companies/company/projects/project/shipping/weight/controller", ["exports", "manage/mixins/location-controller"], function (_exports, _locationController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend((0, _locationController.default)('shipping_weight_based_zone_locations', 'Weight Shipping'), {});

  _exports.default = _default;
});