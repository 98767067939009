define("manage/serializers/project-order-stat", ["exports", "manage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (Ember.isPresent(hash) && Ember.isPresent(hash.attributes)) {
        let keys = Object.keys(hash.attributes);

        if (keys.length === 1) {
          let key = keys[0];
          hash.id += '-' + key;
        }
      }

      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});