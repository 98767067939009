define("manage/pods/companies/company/projects/project/launch-guide/components/lg-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wF/+QZOo",
    "block": "{\"symbols\":[\"ss\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui top attached segment\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"left\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui small header\"],[8],[0,\"\\n      \"],[1,[22,\"header\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"sub_header\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n          \"],[1,[22,\"sub_header\"],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"show_progress\"]]],null,{\"statements\":[[4,\"ss-progress\",null,[[\"progress\",\"status\",\"class\"],[[24,[\"progress\"]],\"\",\"small green\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n          \"],[1,[22,\"completed\"],false],[0,\" of \"],[1,[22,\"steps\"],false],[0,\" Steps completed\\n        \"],[9],[0,\"\\n        \"],[1,[23,1,[\"bar\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,2,[[28,\"hash\",null,[[\"register\",\"unregister\"],[[28,\"action\",[[23,0,[]],\"register\"],null],[28,\"action\",[[23,0,[]],\"unregister\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/launch-guide/components/lg-progress/template.hbs"
    }
  });

  _exports.default = _default;
});