define("manage/mixins/column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name) {
    return Ember.Object.extend({
      header: Ember.computed('name', {
        get() {
          if (this._header) {
            return this._header;
          }

          return this.get('name');
        },

        set(key, value) {
          return this._header = value;
        }

      }),
      visible: Ember.computed({
        get() {
          let session = this.get('session');

          if (session.get(`data.column_${name}_${this.get('id')}`) === undefined) {
            return this.get('default_visible');
          } else {
            return session.get(`data.column_${name}_${this.get('id')}`);
          }
        },

        set(key, value) {
          let session = this.get('session');
          session.set(`data.column_${name}_${this.get('id')}`, value);
          return session.get(`data.column_${name}_${this.get('id')}`);
        }

      })
    });
  }
});