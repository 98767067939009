define("manage/mixins/tabular-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Mixin for keeping track of a selected subset of items, with the additional behavior of
  // being able to toggle all items to be selected or not, à la a checkbox in a table
  // where the header checkbox either selects all or none of the items.
  //
  // Besides including this Mixin, you'll want to set the property 'tabularCheckboxItems' to the
  // array of items that are considered to be selectable.
  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.selectedItems = Ember.A();
    },

    checkboxItemsChanged: Ember.observer('tabularCheckboxItems.[]', function () {
      this.clear_selections();
    }),
    all_are_checked: Ember.computed('tabularCheckboxItems.[]', 'selectedItems.[]', function () {
      return this.tabularCheckboxItems.every(item => this.selectedItems.includes(item));
    }),
    some_are_checked: Ember.computed('all_are_checked', 'selectedItems.[]', function () {
      return this.selectedItems.length > 0 && !this.all_are_checked;
    }),

    toggle_check_all() {
      var newList = this.selectedItems.length == this.tabularCheckboxItems.length ? [] : this.tabularCheckboxItems.toArray();
      this.set('selectedItems', newList);
    },

    toggle_item(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems.removeObject(item);
      } else {
        this.selectedItems.addObject(item);
      }
    },

    clear_selections() {
      this.set('selectedItems', []);
    }

  });

  _exports.default = _default;
});