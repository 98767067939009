define("manage/pods/companies/company/projects/project/questions/edit/controller", ["exports", "manage/utils/catch-real"], function (_exports, _catchReal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    product: Ember.computed.readOnly('model'),
    queryParams: ['type'],
    productTasks: Ember.inject.service(),
    submitted: false,
    actions: {
      save() {
        if (this.get('productTasks.save.isRunning')) {
          return;
        }

        this.set('submitted', true); // For some reason it isn't fully validating the question choices
        // If you only validate product. It shows up afterward, but its like
        // All promises haven't resolved. Probably need to open a CP bug after

        this.get('product.question').validate().then(() => {
          if (this.get('product.validations.isInvalid')) {
            return;
          }

          return this.get('productTasks.save').perform(this.get('product')).then(() => this.transitionToRoute('companies.company.projects.project.questions.index')).catch((0, _catchReal.default)(error => {
            this.send('open_modal', 'error-modal', {
              error: error,
              description: "The question wasn't able to be saved."
            });
          }));
        });
      }

    }
  });

  _exports.default = _default;
});