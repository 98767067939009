define("manage/pods/companies/company/projects/project/components/order-filters-content/component", ["exports", "manage/mixins/order-filters"], function (_exports, _orderFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_orderFilters.computedProperties, {
    classNames: ['ui', 'form', 'order-filters-content', 'component'],
    filter_settings: _orderFilters.filters,
    filter_operators: _orderFilters.operators,
    filters_available: Ember.computed('filters.[]', 'total_filters', function () {
      if (this.get('filters.length') < this.get('total_filters')) {
        return true;
      }

      return false;
    }),
    apply_label: 'Apply',
    actions: {
      select_filter() {
        this.get('select_filter')(...arguments);
      },

      remove_filter() {
        this.get('remove_filter')(...arguments);
      },

      remove_all_filters() {
        this.get('remove_all_filters')(...arguments);
      },

      cancel() {
        this.get('cancel')(...arguments);
      },

      apply() {
        this.get('apply')(...arguments);
      },

      addTag() {
        this.get('addTag')(...arguments);
      },

      removeTagAtIndex() {
        this.get('removeTagAtIndex')(...arguments);
      }

    }
  });

  _exports.default = _default;
});