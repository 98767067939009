define("manage/models/project-source", ["exports", "ember-data-model-fragments/attributes", "manage/utils/generated-model"], function (_exports, _attributes, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-source').extend({
    theme: (0, _attributes.fragment)('pre-order-theme', {
      defaultValue: {}
    }),
    // Probably rename friendly_source_name
    friendly_name: Ember.computed('site', 'name', function () {
      if (this.get('site') === 'crowdox') {
        return 'Crowd Ox';
      } else if (this.get('site') === 'bgg') {
        return 'Board Game Geek';
      } else if (this.get('site') === 'other') {
        return this.get('name');
      } else {
        return Ember.String.capitalize(this.get('site'));
      }
    }),
    show_external: Ember.computed('site', function () {
      if (['kickstarter', 'indiegogo', 'fig'].indexOf(this.get('site')) >= 0) {
        return true;
      }

      return false;
    }),
    show_internal: Ember.computed('site', function () {
      if (['crowdox', 'bgg'].indexOf(this.get('site')) >= 0) {
        return true;
      }

      return false;
    }),
    is_internal: Ember.computed.or('is_crowdox', 'is_store', 'is_bgg'),
    is_external: Ember.computed.not('is_internal'),
    is_crowdox: Ember.computed.equal('site', 'crowdox'),
    is_store: Ember.computed.equal('site', 'store'),
    is_kickstarter: Ember.computed.equal('site', 'kickstarter'),
    is_indiegogo: Ember.computed.equal('site', 'indiegogo'),
    is_other: Ember.computed.equal('site', 'other'),
    is_bgg: Ember.computed.equal('site', 'bgg'),
    t: Ember.computed('site', 'project.t', function () {
      return {
        name: this.get(`project.t.source.${this.get('site')}`)
      };
    })
  });

  _exports.default = _default;
});