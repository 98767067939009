define("manage/pods/companies/company/projects/project/launch-guide/components/lg-support-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9sO3XerK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"data-section\",\"step\"],[11,\"class\",[29,[\"step \",[28,\"if\",[[24,[\"completed\"]],\"completed\"],null]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"before\"],[8],[9],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"circular icon\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"after\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"data-section\",\"description\"],[10,\"class\",\"description\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"needs_support_email\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui tiny header\"],[8],[0,\"\\n      Add support email\\n      \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n        Enter an email address for customer questions on this project.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui tiny header\"],[8],[0,\"\\n      You added the support email: \"],[7,\"em\",true],[8],[1,[24,[\"project\",\"support\",\"email\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"data-section\",\"action\"],[10,\"class\",\"action\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"project\",\"needs_support_email\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"ui tiny basic positive button\"]],[[\"@route\"],[\"companies.company.projects.project.settings\"]],{\"statements\":[[0,\"\\n      Do it!\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"link-to\",[],[[\"@route\"],[\"companies.company.projects.project.settings\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"phrase.edit\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/launch-guide/components/lg-support-email/template.hbs"
    }
  });

  _exports.default = _default;
});