define("manage/mixins/filters/digital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    digital_type_options: [Ember.Object.create({
      id: 'key',
      title: 'Key'
    }), Ember.Object.create({
      id: 'download',
      title: 'Download'
    })],
    digital_status_options: [Ember.Object.create({
      id: 'setup',
      title: 'In Setup'
    }), Ember.Object.create({
      id: 'live',
      title: 'Live'
    }), Ember.Object.create({
      id: 'paused',
      title: 'Paused'
    }), Ember.Object.create({
      id: 'complete',
      title: 'Complete'
    })],
    digital_assignment_options: [Ember.Object.create({
      id: 'unassigned',
      title: 'Unassigned'
    })],
    digital_key_products: Ember.computed('project.digital_fulfillments.@each.{sub_type,product_name}', function () {
      return this.get('project.digital_fulfillments').filterBy('sub_type', 'key').sortBy('product_name').map(digital_fulfillment => digital_fulfillment.get('product'));
    }),
    digital_download_products: Ember.computed('project.digital_fulfillments.@each.{sub_type,product_name}', function () {
      return this.get('project.digital_fulfillments').filterBy('sub_type', 'download').sortBy('product_name').map(digital_fulfillment => digital_fulfillment.get('product'));
    }),
    digital_email_options: Ember.computed('project.digital_fulfillments.@each.project_email_name', function () {
      // This brings up duplicates because an email can be assigned to more than one
      // fulfillment at a time. But I didn't have a quick-fix for it.
      //
      // I'm hoping we change fulfillments to have a single email soon-ish so it's not
      // that big of an issue.
      return this.get('project.digital_fulfillments').sortBy('project_email_name').map(digital_fulfillment => digital_fulfillment.get('project_email'));
    }),
    digital_email_status_options: [Ember.Object.create({
      id: 'none',
      title: 'Not Sent'
    })]
  });

  _exports.default = _default;
});