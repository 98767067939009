define("manage/utils/address-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addressLines;
  const formats = {
    // RECIPIENT
    // STREET_TYPE STREET_NAME [, HOUSE_NUMBER] [, FLOOR] [, APARTMENT]
    // POSTAL_CODE-LOCALITY
    // ALBANIA
    'ALB': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}}-{{locality}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER [,] STREET_NAME
    // POSTAL_CODE LOCALITY
    // ALGERIA
    'DZA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER [FLOOR] [APARTMENT] STREET_TYPE STREET_NAME
    // POSTAL_CODE LOCALITY
    // ANDORRA
    'AND': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER [-SUB_BUILDING]
    // LOCALITY
    // ANGOLA
    'AGO': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{country}}'],
    // RECIPIENT
    // [STREET[HOUSENUMBER]]
    // LOCALITY
    // AI-2640 ANGUILLA
    'AIA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', 'AI-2640 {{country}}'],
    // RECIPIENT
    // POST OFFICE BOX
    // PROVINCE|CAPITAL
    // ANTIGUA AND BARBUDA
    'ATG': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}|{{state}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER [FLOOR]
    // [DEPENDENT_LOCALITY]
    // POSTAL_CODE LOCALITY
    // ARGENTINA
    'ARG': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER STREET_NAME [, [FLOOR] [APARTMENT]]
    // POSTAL CODE
    // LOCALITY
    // [PROVINCE]
    // ARMENIA
    'ARM': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}}', '{{locality}}', '{{country}}'],
    // RECIPIENT
    // STREET[HOUSENUMBER]
    // LOCALITY
    // ARUBA
    'ABW': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{country}}'],
    // RECIPIENT
    // [FLOOR] [/] [APARTMENT] HOUSE_NUMBER STREET_NAME STREET_TYPE
    // LOCALITY PROVINCE_ABBREVIATION POSTAL_CODE
    // AUSTRALIA
    'AUS': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}} {{state}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER/[ENTRANCE]/[FLOOR]/[APARTMENT]
    // POSTAL_CODE LOCALITY
    // AUSTRIA
    'AUT': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER STREET_NAME [, [APARTMENT] [FLOOR]]
    // POSTAL_CODE LOCALITY[-SORTING_CODE]
    // AZERBAIJAN
    'AZE': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // [UNIT_NUMBER]-CIVIC_NUMBER [STREET_NUMBER] [STREET_TYPE] STREET_NAME [STREET_DIRECTION]
    // LOCALITY PROVINCE_ABBREVIATION POSTAL_CODE
    // CANADA
    'CAN': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}} {{state}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // [FLOOR] [APARTMENT]
    // [BUILDING]
    // [HOUSE_NUMBER] STREET_NAME
    // [DEPENDENT_LOCALITY]
    // LOCALITY
    // POSTAL_CODE
    // UNITED KINGDOM
    'GBR': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{state}}', '{{postal_code}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER
    // POSTAL_CODE LOCALITY
    // GERMANY
    'DEU': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // BLOCK_NUMBER|HOUSE_NUMBER|BUILDING_NUMBER STREET_NAME
    // [#FLOOR-APARTMENT] [BUILDING]
    // LOCALITY POSTAL_CODE
    // SINGAPORE
    'SGP': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER
    // POSTAL_CODE LOCALITY
    // SWEDEN
    'SWE': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER
    // POSTAL_CODE LOCALITY
    // NETHERLANDS
    'NLD': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // [URBANISATION]
    // STREET_TYPE STREET_NAME, HOUSE_NUMBER [FLOOR] [APARTMENT]
    // POSTAL_CODE LOCALITY
    // SPAIN
    'ESP': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}} {{state}}', '{{country}}'],
    // RECIPIENT
    // [BUILDING] [APARTMENT]
    // CHOME BAN GO
    // SUB_LOCALITY
    // LOCALITY PROVINCE POSTAL_CODE
    // JAPAN
    'JPN': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}} {{state}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // [FLOOR] [APARTMENT]
    // [BUILDING] [URBANISATION]
    // STREET_TYPE STREET_NAME HOUSE_NUMBER
    // POSTAL_CODE LOCALITY
    // BELGIUM
    'BEL': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER
    // POSTAL_CODE LOCALITY [SORTING_CODE]
    // DENMARK
    'DNK': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // [HOUSE/BUILDING NUMBER] [STREET,] [SECTION OF STREET]
    // [DEPENDENT LOCALITY,]
    // [LOCALITY] [POSTAL CODE]
    // [COUNTRY]
    'TWN': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // STREET_TYPE STREET_NAME HOUSE_NUMBER
    // POSTAL_CODE LOCALITY PROVINCE_ABBREVIATION
    // ITALY
    'ITA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}} {{state}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER [[APARTMENT][/][FLOOR]]
    // POSTAL_CODE LOCALITY
    // SWITZERLAND
    'CHE': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // [FLOOR] [APARTMENT]
    // [BUILDING]
    // [HOUSE_NUMBER] [STREET_NAME]
    // LOCALITY
    // PROVINCE
    // HONG KONG
    'HKG': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{state}}', '{{country}}'],
    // RECIPIENT
    // [STREET_NAME HOUSE_NUMBER]
    // POSTAL_CODE LOCALITY
    // NORWAY
    'NOR': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER STREET_NAME
    // DEPENDENT_LOCALITY
    // LOCALITY POSTAL_CODE
    // NEW ZEALAND
    'NZL': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{state}}', '{{locality}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // [FLOOR] [,][BUILDING]
    // [STREET_NUMBER,] STREET_TYPE STREET_NAME [HOUSE_NUMBER]
    // [SECTION] [CITY_DISTRICT|VILLAGE]
    // POSTAL_CODE LOCALITY
    // [PROVINCE]
    // MALAYSIA
    'MYS': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{state}}', '{{country}}'],
    // RECIPIENT
    // STREET_NAME HOUSE_NUMBER [APARTMENT] [FLOOR]
    // POSTAL_CODE LOCALITY
    // FINLAND
    'FIN': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // [SUBBUILDING]
    // [BUILDING]
    // [HOUSE_NUMBER] STREET_NAME
    // [DEPENDENT_LOCALITY]
    // [DOUBLE_DEPENDENT_LOCALITY]
    // LOCALITY
    // PROVINCE
    // Ireland
    'IRL': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{state}}', '{{postal_code}}', '{{country}}'],
    // RECIPIENT
    // STREET HOUSENUMBER
    // LOCALITY[-STATE CODE]
    // POSTALCODE
    // BRAZIL
    'BRA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{postal_code}}', '{{country}}'],
    // RECIPIENT
    // [BUILDING]
    // [STREET_TYPE] STREET_NAME HOUSE_NUMBER[/SUB_BUILDING]
    // POSTAL_CODE LOCALITY
    // POLAND
    'POL': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // [BUILDING]
    // [SUBBUILDING]
    // [HOUSE_NUMBER] STREET_NAME
    // POSTALCODE LOCALITY
    // Israel
    'ISR': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER STREET_NAME
    // LOCALITY
    // PROVINCE
    // POSTAL_CODE
    // THAILAND
    'THA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{state}}', '{{postal_code}}', '{{country}}'],
    // HOUSE_NUMBER STREET
    // WARD LOCALITY POSTAL_CODE
    // REPUBLIC OF KOREA
    'KOR': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{state}} {{locality}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // [STREET_TYPE] STREET_NAME HOUSE_NUMBER [FLOOR] [APARTMENT]
    // [COLONIA] [,] [VILLAGE]
    // POSTAL_CODE LOCALITY, PROVINCE_ABBREVIATION
    // MEXICO
    'MEX': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}} {{state}}', '{{country}}'],
    // RECIPIENT
    // STREET_TYPE STREET_NAME HOUSE_NUMBER[, [FLOOR] [APARTMENT]]
    // LOCALITY
    // [RAYON]
    // [OBLAST|KRAY|RESPUBLIKA]
    // POSTAL_CODE
    // RUSSIAN FEDERATION
    'RUS': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{state}}', '{{postal_code}}', '{{country}}'],
    // RECIPIENT
    // HOUSE_NUMBER STREET_NAME [STREET_TYPE] [STREET_DIRECTION] [BUILDING] [FLOOR] [APARTMENT]
    // LOCALITY PROVINCE_ABBREVIATION POSTAL_CODE
    // UNITED STATES
    'USA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}, {{state}} {{postal_code}}', '{{country}}'],
    'ASM': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}} {{state}} {{postal_code}}', '{{country}}'],
    // RECIPIENT
    // [APARTMENT] [BUILDING]
    // HOUSE_NUMBER [STREET_TYPE] STREET_NAME [DEPENDENT_LOCALITY]
    // POSTAL_CODE LOCALITY
    // FRANCE
    'FRA': ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{postal_code}} {{locality}}', '{{country}}']
  };

  function addressLines(address) {
    let formatLines = formats[address.get('country.iso3')] || ['{{name}}', '{{company_name}}', '{{address_1}}', '{{address_2}}', '{{locality}}', '{{state}}', '{{postal_code}}', '{{country}}'];
    return formatLines.map(function (line) {
      return line.replace(/{{name}}/g, address.get('name') || '').replace(/{{company_name}}/g, address.get('company_name') || '').replace(/{{address_1}}/g, address.get('address_1') || '').replace(/{{address_2}}/g, address.get('address_2') || '').replace(/{{locality}}/g, address.get('city') || '').replace(/{{state}}/g, address.get('state_name') || '').replace(/{{postal_code}}/g, address.get('postal_code') || '').replace(/{{country}}/g, address.get('country.name') || '');
    }).map(function (line) {
      return (line || "").trim();
    }).filter(line => {
      if (Ember.isPresent(line)) {
        // Have to handle the case of just a comma
        return line.trim() !== ",";
      }

      return false;
    }).join("\n");
  }
});