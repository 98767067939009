define("manage/pods/companies/company/projects/project/stats/index/components/stat-activity/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['thirteen', 'wide', 'column', 'graph'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      let project = this.get('project');
      let stats = Ember.A();

      if (this.get('show_order_statistics')) {
        stats = yield this.get('store').query('project-order-activity', {
          filter: {
            project_id: project.get('id')
          },
          page: {
            size: 30
          }
        });
      }

      this.set('stats', stats);
    }),
    empty: Ember.computed.equal('stats.length', 0),
    order_activity_chart_data: Ember.computed('stats.@each.{approved,date,opened}', function () {
      let labels = [];
      let opened = [];
      let approved = [];
      let iterable = (this.get('stats') || Ember.A()).sortBy('date');

      for (let j = 0, row; j < iterable.get('length'); j++) {
        row = iterable.objectAt(j);
        labels.push(this.get('intl').formatDate(row.get('date'), {
          month: 'short',
          day: '2-digit'
        }));
        opened.push(row.get('opened'));
        approved.push(row.get('approved'));
      }

      return {
        labels: labels,
        datasets: [{
          label: this.get('intl').t('phrase.completed'),
          backgroundColor: "rgba(216,216,216,0.1)",
          borderColor: "rgba(125,202,96,1)",
          pointBackgroundColor: "rgba(126,202,96,1)",
          pointBorderColor: "#fff",
          // pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          data: approved,
          lineTension: 0,
          pointRadius: 5,
          pointHitRadius: 10
        }, {
          label: this.get('intl').t('phrase.opened'),
          backgroundColor: "rgba(216,216,216,0.1)",
          borderColor: "rgba(216,216,216,1)",
          pointBackgroundColor: "rgba(216,216,216,1)",
          pointBorderColor: "#fff",
          // pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(151,187,205,1)",
          data: opened,
          lineTension: 0,
          pointRadius: 5,
          pointHitRadius: 10
        }]
      };
    })
  });

  _exports.default = _default;
});