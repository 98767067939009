define("manage/pods/companies/company/projects/project/digital/index/columns", ["exports", "manage/mixins/columns"], function (_exports, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    columnType: 'digital_index',
    componentPrefix: 'companies/company/projects/project/digital/index',
    i18nPrefix: 'digital.index',
    colDefs: function
      /* model */
    () {
      return [{
        id: 'id',
        sort: 'id'
      }, {
        id: 'product_id',
        sort: 'product.id'
      }, {
        id: 'status',
        default_visible: true
      }, {
        id: 'thumbnail',
        header: '',
        default_visible: true
      }, {
        id: 'name',
        default_visible: true
      }, {
        id: 'type',
        default_visible: true
      }, {
        id: 'count',
        default_visible: true
      }, {
        id: 'email'
      }];
    }
  });

  _exports.default = _default;
});