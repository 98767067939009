define("manage/pods/companies/company/projects/project/orders/index/columns", ["exports", "manage/helpers/has-installed-app", "manage/mixins/columns"], function (_exports, _hasInstalledApp, _columns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_columns.default, {
    intl: Ember.inject.service(),
    columnType: 'order',
    componentPrefix: 'companies/company/projects/project/orders/index',
    i18nPrefix: 'orders.index',
    colDefs: function (model) {
      let columns = Ember.A();
      let project = model.project;
      columns.push({
        id: 'id',
        sort: 'id'
      });

      if (project.get('purpose') === 'pledge_management') {
        columns.push({
          id: 'external-id',
          sort: 'external_id'
        });
        columns.push({
          id: 'external-sequence'
        });
        columns.push({
          id: 'last-synced',
          sort: 'last_synced_on'
        });
      }

      columns.push({
        id: 'current-configuration',
        sort: 'current_configuration_id',
        name: project.get('t.lines.reward'),
        default_visible: true
      });
      columns.push({
        id: 'invited',
        sort: 'invite_sent_on',
        default_visible: true
      });
      columns.push({
        id: 'completed',
        sort: 'approved_on',
        default_visible: true
      }); // Customer

      columns.push({
        id: 'customer-id',
        sort: 'customer.id'
      });
      columns.push({
        id: 'name',
        sort: 'customer.full_name',
        default_visible: true
      });
      columns.push({
        id: 'email',
        sort: 'customer.email',
        default_visible: true
      });
      columns.push({
        id: 'country'
      }); // Order

      columns.push({
        id: 'status',
        default_visible: true
      });
      columns.push({
        id: 'tags',
        class: 'tags',
        default_visible: false
      });

      if ((0, _hasInstalledApp.hasInstalledApp)(project, 'wave-shipping')) {
        columns.push({
          id: 'wave-shipping',
          default_visible: false
        });
      }

      let custom_fields = project.get('custom_fields').filterBy('entity', 'order');

      for (let index = 0; index < custom_fields.length; index++) {
        const custom_field = custom_fields[index];
        columns.push({
          id: `custom-${custom_field.id}`,
          component_id: 'custom',
          name: custom_field.name,
          args: {
            public_id: custom_field.public_id,
            field_type: custom_field.field_type
          }
        });
      }

      return columns;
    }
  });

  _exports.default = _default;
});