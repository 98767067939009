define("manage/helpers/page-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function updateDescription(description) {
    Ember.set(this, 'description', description);
  }

  var _default = Ember.Helper.extend({
    headData: Ember.inject.service(),

    compute(params) {
      Ember.run.scheduleOnce('afterRender', Ember.get(this, 'headData'), updateDescription, params);
      return '';
    }

  });

  _exports.default = _default;
});