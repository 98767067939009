define("manage/components/icon-email-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w/IKlK4k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"data-tooltip\",[22,\"statusMessage\"]],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"medium\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"is_queued_or_pending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"ui small loading notch circle icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"was_sent\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"ui small green circle icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"was_hard_bounced\"]],[24,[\"rejected\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"ui small red exclamation circle icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"was_soft_bounced\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"ui small yellow exclamation triangle icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"was_spammed\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"ui small orange flag icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showUnknown\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"ui small red question icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/icon-email-status/template.hbs"
    }
  });

  _exports.default = _default;
});