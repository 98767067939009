define("manage/pods/companies/company/projects/project/sources/configurations/components/edit-source-name-modal/component", ["exports", "ember-concurrency", "manage/mixins/ss-modal", "manage/mixins/error-from-xhr"], function (_exports, _emberConcurrency, _ssModal, _errorFromXhr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, (0, _errorFromXhr.default)(), {
    submitted: false,
    saveTask: (0, _emberConcurrency.task)(function* () {
      let {
        validations: validations
      } = yield this.source.validate();

      if (!validations.get('isValid')) {
        throw "Validation error";
      }

      yield this.source.save();
    }),
    actions: {
      cancel() {
        this.source.rollbackAttributes();
        this.closeModal();
      },

      save() {
        this.set('submitted', true);
        this.set('isProcessing', true);
        this.get('saveTask').perform().then(() => this.closeModal()).catch(xhr => {
          this.set('error', this.errorFromXhr(xhr));
        }).finally(() => this.set('isProcessing', false));
      }

    }
  });

  _exports.default = _default;
});