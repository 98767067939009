define("manage/mixins/project-source-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    start_date_formatted: Ember.computed('intl.locale', 'start_date', function () {
      let start_date = this.get('start_date');

      if (Ember.isBlank(start_date)) {
        return '';
      }

      return this.get('intl').formatDate(start_date, {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    }),
    end_date_formatted: Ember.computed('intl.locale', 'end_date', function () {
      let end_date = this.get('end_date');

      if (Ember.isBlank(end_date)) {
        return '';
      }

      return this.get('intl').formatDate(end_date, {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    }),
    order_count_formatted: Ember.computed('intl.locale', 'order_count', function () {
      return this.get('intl').formatNumber(this.get('order_count'));
    }),
    total_ordered_formatted: Ember.computed('intl.locale', 'total_ordered', function () {
      return this.get('intl').formatNumber(this.get('total_ordered'));
    })
  });

  _exports.default = _default;
});