define("manage/components/inline-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WF8G/MSO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui fluid inline mini action input\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"class\",\"value\",\"name\"],[\"ui input\",[24,[\"value\"]],\"email\"]]],false],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"javascript:\"],[10,\"class\",\"ui positive button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"save\"],null]],[8],[1,[28,\"t\",[\"phrase.save\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"inline edit\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"change\"],null]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"edit text\"],[8],[1,[22,\"value\"],false],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"javascript:\"],[10,\"class\",\"edit button\"],[8],[1,[28,\"t\",[\"phrase.edit\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/inline-edit/template.hbs"
    }
  });

  _exports.default = _default;
});