define("manage/pods/companies/company/projects/index/route", ["exports", "manage/mixins/restrict-by-session"], function (_exports, _restrictBySession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_restrictBySession.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model(params) {
      let company = this.modelFor('companies.company');
      return Ember.RSVP.hash({
        company: company,
        projects: this.search(params)
      });
    },

    search(params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let search = params.search;
      let pageSize = controller.get('page_size');
      let company = this.modelFor('companies.company');
      return this.store.query('project', {
        filter: {
          company: company.get('id'),
          search: search
        },
        include: ['sources'].join(','),
        page: {
          number: page,
          size: pageSize
        }
      });
    }

  });

  _exports.default = _default;
});