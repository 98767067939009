define("manage/components/file-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yAO1dumk",
    "block": "{\"symbols\":[\"ss\"],\"statements\":[[4,\"ss-progress\",null,[[\"progress\",\"class\"],[[24,[\"job\",\"progress\"]],[28,\"if\",[[24,[\"job\",\"is_failed\"]],\"red\"],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"bar\"]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"job\",\"is_complete\"]],[24,[\"job\",\"is_queued\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"titleize\",[[24,[\"job\",\"status\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"job\",\"is_failed\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,[\"job\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[4,\"if\",[[28,\"eq\",[[24,[\"mode\"]],\"preview\"],null]],null,{\"statements\":[[0,\"Analyzing\"]],\"parameters\":[]},{\"statements\":[[0,\"Processing\"]],\"parameters\":[]}],[0,\" \"],[1,[24,[\"job\",\"processed\"]],false],[0,\"%\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/file-progress/template.hbs"
    }
  });

  _exports.default = _default;
});