define("manage/pods/user/reset/controller", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    identification: null,
    error: null,
    filledIn: Ember.computed('identification', 'error', function () {
      return Ember.isPresent(this.get('identification')) && Ember.isBlank(this.get('error'));
    }),
    textChanged: Ember.observer('identification', function () {
      if (this.get('error')) {
        return this.set('error', null);
      }
    }),
    requestTask: (0, _emberConcurrency.task)(function* () {
      this.set('error', null);
      let identification = this.get('identification'); // clean email

      identification = (identification || "").toLowerCase().trim();
      let response = yield this.get('ajax').raw(`${_environment.default.urls.auth}/password/reset`, {
        dataType: 'text',
        data: {
          username: identification
        }
      });

      if (response.jqXHR.status != 200) {
        throw "Unable to reset your password";
      }
    }),
    actions: {
      request() {
        this.set('error', null);
        this.get('requestTask').perform().then(() => this.transitionToRoute('user.reset_confirmation')).catch(error => this.set('error', error));
        return false;
      }

    }
  });

  _exports.default = _default;
});