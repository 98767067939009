define("manage/components/ss-accordion-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'accordion'],
    accordionComponentName: 'ss-accordion-tagless',
    exclusive: true,
    collapsible: true,
    duration: 500,
    transitionMode: 'fade',
    accordions: null,

    init() {
      this._super(...arguments);

      this.set('accordions', Ember.A([]));
    },

    perform(current) {
      let accordions = this.get('accordions');
      accordions.addObject(current);

      for (let accordion of accordions) {
        if (current !== accordion) {
          if (this.get('exclusive') === true && accordion.get('isActive') === true) {
            accordion.toggle();
          }
        }
      }

      if (this.get('collapsible') === false) {
        if (current.get('isActive') === false) {
          current.toggle();
        }
      } else {
        current.toggle();
      }
    }

  });

  _exports.default = _default;
});