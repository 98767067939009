define("manage/pods/companies/company/projects/project/segments/components/tag-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action", "ember-cp-validations", "ember-concurrency"], function (_exports, _ssModal, _segmentAction, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    tags: {
      description: "Tags",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, Validations, {
    classNames: ['tag-modal', 'segment-modal', 'component'],
    store: Ember.inject.service(),
    closable: false,
    router: Ember.inject.service(),
    isRunning: Ember.computed.or('addTask.isRunning', 'removeTask.isRunning'),

    init() {
      this._super(...arguments);

      this.set('tags', []);
      this.set('type', 'tag');
    },

    addTask: (0, _emberConcurrency.task)(function* () {
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      this.set('type', 'add-tag');
      let args = {
        tags: this.get('tags').map(item => item.name)
      };
      this.get('actionTask').perform(args);
    }),
    removeTask: (0, _emberConcurrency.task)(function* () {
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      this.set('type', 'remove-tag');
      let args = {
        tags: this.get('tags').map(item => item.name)
      };
      this.get('actionTask').perform(args);
    }),
    actions: {
      addTags() {
        this.set('submitted', true);

        if (this.get('isRunning')) {
          return;
        }

        this.get('addTask').perform();
      },

      removeTags() {
        this.set('submitted', true);

        if (this.get('isRunning')) {
          return;
        }

        this.get('removeTask').perform();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      createTag(order, select, event) {
        if (event.keyCode === 13 && select.isOpen && !select.highlighted && Ember.isPresent(select.searchText)) {
          if (!this.get('tags').includes(select.searchText)) {
            this.send('addTag', select.searchText);
          }
        }
      },

      addTag(name) {
        let tag = this.get('store').createRecord('order-tag', {
          project: this.get('project'),
          name: name
        });
        return tag.save().then(saved_tag => {
          this.get('tags').pushObject(saved_tag);
        });
      },

      searchTags(value) {
        return this.store.query('order-tag', {
          page: {
            size: 25
          },
          filter: {
            project: this.get('project.id'),
            search: `${value}*`
          }
        });
      },

      manageTags() {
        this.closeModal();
        this.get('router').transitionTo('companies.company.projects.project.orders.tags');
      }

    }
  });

  _exports.default = _default;
});