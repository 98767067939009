define("manage/models/project-product-variation-stat", ["exports", "manage/utils/generated-model", "manage/utils/computed-default-properties"], function (_exports, _generatedModel, _computedDefaultProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-product-variation-stat').reopen({
    default_image_path: (0, _computedDefaultProperties.default)('product_variation.image_path', 'product.image_path')
  });

  _exports.default = _default;
});