define("manage/pods/companies/company/projects/project/products/components/not-deletable-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o8/YKDX6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Unable to Delete Imported Product\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"The product \"],[7,\"b\",true],[8],[1,[24,[\"product\",\"name\"]],false],[9],[0,\", is unable to be deleted because it was imported from \"],[7,\"b\",true],[8],[1,[24,[\"product\",\"imported_by\"]],false],[9],[0,\".\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Deleting this product would cause issues for customers from \"],[1,[24,[\"product\",\"imported_by\"]],false],[0,\".\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"If you need to make changes or need additional help, please reach out to Customer Support at \"],[7,\"a\",true],[10,\"href\",\"mailto:hello@crowdox.com\"],[8],[0,\"hello@crowdox.com\"],[9],[0,\" for further assistance.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.close\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/components/not-deletable-modal/template.hbs"
    }
  });

  _exports.default = _default;
});