define("manage/models/project-source-settings-wizard", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _fragment.default.extend(Validations, {
    access_requested_on: (0, _model.attr)('date'),
    skip_extras: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});