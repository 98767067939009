define("manage/pods/user/login/controller", ["exports", "ember-cp-validations", "ember-concurrency", "manage/config/environment"], function (_exports, _emberCpValidations, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    accept_agreements: {
      description: "I accept",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        message: "{description} must be checked",
        in: [true]
      })]
    }
  });

  var _default = Ember.Controller.extend(Validations, {
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    submitted: false,
    error: false,
    logged_in: false,
    identification: null,
    password: null,
    accept_agreements: true,
    allowSignup: _environment.default.allowSignup,
    filled_in: Ember.computed('identification', 'password', 'error', function () {
      return Ember.isPresent(this.get('identification')) && Ember.isPresent(this.get('password')) && Ember.isBlank(this.get('error'));
    }),
    text_changed: Ember.observer('identification', 'password', function () {
      if (this.get('error')) {
        return this.set('error', null);
      }
    }),
    loading_or_logged_in: Ember.computed.or('logged_in', 'authenticateTask.isRunning'),
    authenticateTask: (0, _emberConcurrency.task)(function* () {
      let result = yield this.validate();

      if (result.validations.get('isInvalid')) {
        let errorMessages = result.validations.get('messages').join('<br> ');
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'need-information',
          question: errorMessages
        });
        return;
      }

      this.set('submitted', true);
      let {
        identification,
        password
      } = this.getProperties('identification', 'password'); // clean email

      identification = (identification || "").toLowerCase().trim();
      yield this.get('session').authenticate('authenticator:custom', identification, password); // Then reload the application model along with the current user
      // We're unable to refresh the application route since transitions
      // don't work after that.

      const currentOwner = Ember.getOwner(this);
      const applicationRoute = currentOwner.lookup('route:application');
      this.store.unloadAll();

      try {
        yield Ember.RSVP.all([applicationRoute.refresh(), this.get('user').load()]);
      } catch (e) {
        if (e instanceof Error && e.message !== 'TransitionAborted') {
          throw e;
        }
      }

      const attemptedTransition = this.get('session.attemptedTransition');
      const cookies = currentOwner.lookup('service:cookies');
      const redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

      if (redirectTarget) {
        this.transitionToRoute(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget', {
          path: '/',
          secure: true
        });
        this.set('session.attemptedTransition', null);
      } else if (attemptedTransition) {
        attemptedTransition.retry();
        cookies.clear('ember_simple_auth-redirectTarget', {
          path: '/',
          secure: true
        });
        this.set('session.attemptedTransition', null);
      } else {
        this.transitionToRoute('index');
      }

      this.set('logged_in', true);
    }),
    actions: {
      authenticate() {
        if (this.get('loading_or_logged_in')) {
          return;
        }

        this.get('authenticateTask').perform().catch(() => {
          this.set('error', true);
          this.set('errorMessage', "The username and password you entered don't match our records. Please check them and try again.");
        });
      }

    }
  });

  _exports.default = _default;
});