define("manage/pods/companies/company/projects/project/settings/fields/controller", ["exports", "ember-inflector", "shared/utils/fallback-sort", "ember-concurrency"], function (_exports, _emberInflector, _fallbackSort, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    custom_fields: Ember.computed.readOnly('model.custom_fields'),
    custom_fields_sorted: (0, _fallbackSort.default)('custom_fields', 'name', 'id'),
    base_tabindex: 1000,
    // We need to start higher, to not interfere with non modal tab indexes
    field_types: ['text', 'number', 'checkbox' // 'date'
    ],
    entities: ['product', // 'customer',
    'order'],
    // Paging
    page: 1,
    page_size: 100,
    orderCustomFields: (0, _emberConcurrency.task)(function* (custom_fields) {
      custom_fields = yield custom_fields;

      for (let index = 0; index < custom_fields.length; index++) {
        const custom_field = custom_fields[index];
        custom_field.set('sort_order', index + 1);
        yield custom_field.save();
      }
    }).restartable(),
    deleteCustomField: (0, _emberConcurrency.task)(function* (custom_field) {
      let field = yield custom_field;

      try {
        yield field.destroyRecord();
      } catch (e) {
        field.rollbackAttributes();
        throw e;
      }
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      refresh() {
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      },

      delete_custom_field(custom_field) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-project-custom-field',
          i18nParams: {
            name: custom_field.get('name'),
            entities: (0, _emberInflector.pluralize)(custom_field.get('entity'))
          },
          approve: () => {
            return this.get('deleteCustomField').perform(custom_field);
          }
        });
      }

    }
  });

  _exports.default = _default;
});