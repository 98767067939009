define("manage/models/weight-shipping-rate", ["exports", "ember-data-model-fragments/fragment", "shared/utils/monetize", "ember-cp-validations", "big.js", "@ember-data/model"], function (_exports, _fragment, _monetize, _emberCpValidations, _big, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    starting_weight_calculated: {
      description: "Starting Weight",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        allowNone: false,
        gte: 0.01
      })]
    },
    ending_weight: {
      description: "Ending Weight",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        allowNone: false,
        gt: Ember.computed.readOnly('model.starting_weight_calculated'),
        disabled: Ember.computed.readOnly('model.is_last')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: false,
        disabled: Ember.computed.not('model.is_last'),
        message: "Last ending weight must be blank"
      })]
    },
    amount: {
      description: "Shipping Amount",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        allowNone: true,
        gte: 0
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    sort_order: (0, _model.attr)('number'),
    starting_weight: (0, _model.attr)('number'),
    ending_weight: (0, _model.attr)('number'),
    ending_weight_with_max: Ember.computed('ending_weight', function () {
      let ending_weight = this.get('ending_weight');

      if (Ember.isBlank(ending_weight)) {
        // 2147483647 Max int is that, easy to use 9 decending down for max weight
        return 1987654321;
      }

      return ending_weight;
    }),
    amount_cents: (0, _model.attr)('number'),
    amount: (0, _monetize.default)('amount_cents', 'currency'),
    parent: Ember.computed.readOnly('_internalModel._recordData._owner'),
    project: Ember.computed.readOnly('parent.project'),
    currency: Ember.computed.readOnly('project.currency'),
    is_last: Ember.computed('sort_order', 'parent.prices.@each.sort_order', function () {
      let prices = this.get('parent.prices');

      if (Ember.isBlank(prices)) {
        return false;
      } // We want to look at all previous weights, so it needs to be <


      let first_price = prices.sortBy('sort_order').get('lastObject');

      if (Ember.isBlank(first_price)) {
        return false;
      }

      return first_price.get('sort_order') === this.get('sort_order');
    }),
    starting_weight_calculated: Ember.computed('sort_order', 'parent.prices.@each.{sort_order,ending_weight}', function () {
      if (this.get('sort_order') === 0) {
        return parseFloat((0, _big.default)(0.01).toFixed(2));
      }

      let prices = this.get('parent.prices');

      if (Ember.isBlank(prices)) {
        return null;
      } // We want to look at all previous weights, so it needs to be <


      let previous_price = prices.filter(price => {
        return price.get('sort_order') < this.get('sort_order');
      }).sortBy('sort_order').get('lastObject');

      if (Ember.isPresent(previous_price)) {
        let ending_weight = previous_price.get('ending_weight'); // Make sure its present and that its a number

        if (Ember.isPresent(ending_weight) && !isNaN(parseFloat(ending_weight))) {
          // Then use big to properly add together
          ending_weight = (0, _big.default)(ending_weight).plus(0.01);
          return parseFloat(ending_weight.toFixed(2));
        }
      }

      return null; // Calculate based on previous selection
    })
  });

  _exports.default = _default;
});