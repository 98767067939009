define("manage/mixins/promise-resolver", ["exports", "ember-promise-tools/mixins/promise-resolver"], function (_exports, _promiseResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _promiseResolver.default;
    }
  });
});