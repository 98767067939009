define("manage/models/order-line", ["exports", "ember-cp-validations", "manage/utils/generated-model", "shared/utils/product-mapping-order-selection-filter", "shared/utils/monetize", "ember-data-model-fragments/attributes", "shared/mixins/prices", "shared/mixins/project-total", "big.js"], function (_exports, _emberCpValidations, _generatedModel, _productMappingOrderSelectionFilter, _monetize, _attributes, _prices, _projectTotal, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    order: {
      description: "Order",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    line_type: {
      description: "Order Line Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['reward', 'extra', 'manual', 'external', 'shipping', 'tax']
      })]
    },
    product_bundle: {
      description: "Product Bundle",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.line_type', function () {
          if (['external', 'manual', 'extra', 'shipping', 'tax'].indexOf(this.get('model.line_type')) >= 0) {
            return true;
          }

          return false;
        })
      })]
    },
    selections: {
      description: "Order Selections",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.skip_order_selections', 'model.{line_type,price_data.is_generated}', 'model.product_bundle.mappings.@each.enabled', function () {
          if (this.get('model.skip_order_selections')) {
            return true;
          } // Don't validate generated lines live or not


          if (this.get('model.price_data.is_generated')) {
            return true;
          } // Don't validate fixed shipping lines


          if (this.get('model.price_data.is_fixed') && this.get('model.is_shipping')) {
            return true;
          }

          if (this.get('model.line_type') === 'reward' && this.get('model.product_bundle.mappings.length') === 0) {
            return true;
          }

          if (this.get('model.line_type') === 'reward' && this.get('model.product_bundle.mappings').isEvery('enabled', false)) {
            return true;
          }

          return false;
        })
      }) // Look at adding check based on total quantity == order selections total quantity
      ]
    }
  });

  const getSelectionSortOrder = function (selection, mappings) {
    let product_id = selection.get('product_option.id') || selection.get('product.id');
    let mapping = mappings.findBy('child.id', product_id);

    if (Ember.isPresent(mapping) && Ember.isPresent(mapping.get('sort_order'))) {
      return mapping.get('sort_order');
    } // If we don't have a mapping, default to category


    if (selection.get('is_question')) {
      // 1000 for question + 99 = max
      return 1099;
    } // 99 for max in product category


    return 99;
  };

  var _default = (0, _generatedModel.default)('order-line').extend(validations, _prices.default, {
    currency: Ember.computed.readOnly('project.currency'),
    intl: Ember.inject.service(),
    selections_not_deleted: Ember.computed.filterBy('selections', 'isDeleted', false),
    selections_sorted: Ember.computed('is_bundle', 'product_bundle.mappings_sorted.@each.{child,sort_order}', 'selections_not_deleted.@each.{product,product_option}', function () {
      if (this.get('is_bundle') !== true) {
        return this.get('selections_not_deleted');
      }

      let mappings = this.get('product_bundle.mappings_sorted');
      let selections = this.get('selections_not_deleted');
      return selections.sort((a, b) => {
        let a_sort_order = getSelectionSortOrder(a, mappings);
        let b_sort_order = getSelectionSortOrder(b, mappings);

        if (a_sort_order < b_sort_order) {
          return -1;
        }

        if (a_sort_order > b_sort_order) {
          return 1;
        }

        return 0;
      });
    }),
    selections_sorted_actual: Ember.computed.filterBy('selections_sorted', 'is_question', false),
    selections_sorted_questions: Ember.computed.filterBy('selections_sorted', 'is_question', true),
    total_selections_count: Ember.computed.readOnly('selections_not_deleted.length'),
    total_selections_with_choices_count: Ember.computed('selections_not_deleted.@each.has_choices', function () {
      return this.get('selections_not_deleted').filterBy('has_choices', true).get('length');
    }),
    // This works only for non bundle type lines. If its a bundle, do not use this.
    // The rule for non bundles is they only have one order selection ever
    selection: Ember.computed.readOnly('selections_not_deleted.firstObject'),
    selection_product: Ember.computed.readOnly('selection.product'),
    selection_quantity: Ember.computed.readOnly('selection.quantity'),
    selection_product_option: Ember.computed.readOnly('selection.product_option'),
    selection_product_option_mapping: Ember.computed.readOnly('selection.product_option_mapping'),
    selection_price_data_type: Ember.computed.readOnly('selection.price_data.type'),
    selection_price_data_is_fixed: Ember.computed.readOnly('selection.price_data.is_fixed'),
    selection_price_data_is_shipping_fixed: Ember.computed.equal('selection.price_data.settings.shipping', 'fixed'),
    selection_shipping_is_price_based: Ember.computed.readOnly('selection.shipping_is_price_based'),
    is_reward: Ember.computed.equal('line_type', 'reward'),
    is_extra: Ember.computed.equal('line_type', 'extra'),
    is_manual: Ember.computed.equal('line_type', 'manual'),
    is_external: Ember.computed.equal('line_type', 'external'),
    is_shipping: Ember.computed.equal('line_type', 'shipping'),
    is_tax: Ember.computed.equal('line_type', 'tax'),
    is_bundle: Ember.computed.notEmpty('product_bundle.id'),
    is_locked: Ember.computed('selections_not_deleted.@each.is_locked', function () {
      let order_selections = this.get('selections_not_deleted');
      return order_selections.any(order_selection => order_selection.get('is_locked'));
    }),
    has_bundle_products: Ember.computed('prices.product.amount_cents', 'product_bundle.mappings.length', 'selections_not_deleted.length', function () {
      if (this.get('prices.product.amount_cents') !== 0 || this.get('product_bundle.mappings.length') > 0 || this.get('selections_not_deleted.length') > 0) {
        return true;
      } else {
        return false;
      }
    }),
    available_mappings_sorted: Ember.computed('product_bundle.id', 'product_bundle.mappings_sorted.@each.{child,enabled}', 'selections_not_deleted.@each.{product,product_option,product_option_mapping}', function () {
      if (Ember.isBlank(this.get('product_bundle.id'))) {
        return Ember.A();
      }

      let order_selections = this.get('selections_not_deleted');
      return this.get('product_bundle.mappings_sorted').filter(product_mapping => {
        // never show questions
        if (product_mapping.get('child.is_question')) {
          return false;
        } // If we have any selections that currently have the mapping, allow it through


        let has_selection = order_selections.any((0, _productMappingOrderSelectionFilter.default)(product_mapping));

        if (has_selection) {
          return true;
        }

        if (product_mapping.get('enabled')) {
          return true;
        }

        return false;
      });
    }),
    // Inverse of with choices property
    available_mappings_with_no_choices_sorted: Ember.computed('product_bundle.id', 'product_bundle.mappings_sorted.@each.{child,enabled}', 'selections_not_deleted.@each.{product,product_option}', function () {
      if (Ember.isBlank(this.get('product_bundle.id'))) {
        return Ember.A();
      }

      let order_selections = this.get('selections_not_deleted');
      return this.get('product_bundle.mappings_sorted').filter(product_mapping => {
        // if we don't have any choices, we can skip it
        if (product_mapping.get('child.has_choices')) {
          return false;
        } // If we have any selections that currently have the mapping, allow it through


        let has_selection = order_selections.any((0, _productMappingOrderSelectionFilter.default)(product_mapping));

        if (has_selection) {
          return true;
        }

        if (product_mapping.get('enabled')) {
          return true;
        }

        return false;
      });
    }),
    available_mappings_with_choices_sorted: Ember.computed('product_bundle.id', 'product_bundle.mappings_sorted.@each.{child,enabled}', 'selections_not_deleted.@each.{product,product_option}', function () {
      if (Ember.isBlank(this.get('product_bundle.id'))) {
        return Ember.A();
      }

      let order_selections = this.get('selections_not_deleted');
      return this.get('product_bundle.mappings_sorted').filter(product_mapping => {
        // if we don't have any choices, we can skip it
        if (!product_mapping.get('child.has_choices')) {
          return false;
        } // If we have any selections that currently have the mapping, allow it through


        let has_selection = order_selections.any((0, _productMappingOrderSelectionFilter.default)(product_mapping));

        if (has_selection) {
          return true;
        }

        if (product_mapping.get('enabled')) {
          return true;
        }

        return false;
      });
    }),
    available_mappings_questions_only_sorted: Ember.computed('product_bundle.id', 'product_bundle.mappings_sorted.@each.{child,enabled}', 'selections_not_deleted.@each.{product,product_option}', function () {
      if (Ember.isBlank(this.get('product_bundle.id'))) {
        return Ember.A();
      }

      let order_selections = this.get('selections_not_deleted');
      return this.get('product_bundle.mappings_sorted').filter(product_mapping => {
        // if we don't have any choices, we can skip it
        if (!product_mapping.get('child.has_choices')) {
          return false;
        }

        if (!product_mapping.get('child.is_question')) {
          return false;
        } // If we have any selections that currently have the mapping, allow it through


        let has_selection = order_selections.any((0, _productMappingOrderSelectionFilter.default)(product_mapping));

        if (has_selection) {
          return true;
        }

        if (product_mapping.get('enabled')) {
          return true;
        }

        return false;
      });
    }),
    shipping_is_price_based: Ember.computed.or('product_bundle.shipping_is_price_based', 'project.shipping_is_price_based'),
    ////////////
    // Price calculations
    ////////////
    price: (0, _monetize.default)('price_cents', 'currency'),
    price_data: (0, _attributes.fragment)('price-data', {
      defaultValue: {}
    }),
    // Alias to help with @each properties
    price_data_type: Ember.computed.readOnly('price_data.type'),
    price_data_is_fixed: Ember.computed.readOnly('price_data.is_fixed'),
    price_data_is_shipping_fixed: Ember.computed.equal('price_data.settings.shipping', 'fixed'),
    price_data_is_generated: Ember.computed.readOnly('price_data.is_generated'),
    price_data_is_calculation_discount: Ember.computed.equal('price_data.settings.calculation_type', 'discount'),
    price_data_settings_symbol: Ember.computed.readOnly('price_data.settings.symbol'),
    // prices: property comes from mixin
    // Price calculations
    live_product_amount_cents: Ember.computed('is_bundle', 'product_bundle.price_cents', 'selections_not_deleted.@each.prices_product_amount_cents', function () {
      if (this.get('is_bundle')) {
        return this.get('product_bundle.price_cents') || 0;
      }

      return this.sum_selections('prices_product_amount_cents');
    }),
    live_product_vat_amount_cents: Ember.computed('is_bundle', 'product_bundle.price_cents', 'selections_not_deleted.@each.prices_product_amount_cents', function () {
      if (this.get('is_bundle')) {
        let that = this;
        let categories = this.get('project.vat_categories').toArray();
        let result = categories.reduce(function (value, category) {
          return (0, _big.default)(value).plus(that.get(`product_bundle.vat_price_cents.${category.get('public_id')}`) || 0);
        }, 0);
        return parseFloat((0, _big.default)(result || 0).toFixed(2));
      }

      return this.sum_selections('prices_product_vat_amount_cents');
    }),

    live_product_vat_category(category) {
      if (this.get('is_bundle')) {
        return this.get(`product_bundle.vat_price_cents.${category}`) || 0;
      }

      let result = this.get('selections_not_deleted').reduce(function (value, order_selection) {
        return (0, _big.default)(value).plus(order_selection.get('live_product_vat_category').call(order_selection, category) || 0);
      }, 0);
      return parseFloat((0, _big.default)(result || 0).toFixed(2));
    },

    live_additional_amount_cents: Ember.computed('is_bundle', 'selections_not_deleted.@each.prices_additional_amount_cents', function () {
      if (this.get('is_bundle')) {
        return this.sum_selections('prices_additional_amount_cents');
      }

      return 0;
    }),
    live_shipping_amount_cents: Ember.computed('is_bundle', 'shipping_is_price_based', 'product_bundle.shipping_product_based_zone_locations.@each.{country,state,shipping_cents}', 'order.shipping_address.{country,state}', 'selections_not_deleted.@each.prices_shipping_amount_cents', function () {
      if (this.get('is_bundle')) {
        if (this.get('shipping_is_price_based') === false) {
          return 0;
        }

        let country = this.get('order.shipping_address.country');
        let state = this.get('order.shipping_address.state');
        let zone_location = (0, _projectTotal.find_zone_location)(this.get('product_bundle'), 'shipping_product_based_zone_locations', country, state);

        if (Ember.isPresent(zone_location)) {
          return zone_location.get('shipping_cents') || 0;
        }

        return 0;
      }

      return this.sum_selections('prices_shipping_amount_cents');
    }),
    live_tax_amount_cents: Ember.computed('selections_not_deleted.@each.prices_tax_amount_cents', function () {
      return this.sum_selections('prices_tax_amount_cents');
    }),
    product_total_weight: Ember.computed('selections_not_deleted.@each.product_total_weight', function () {
      return this.sum_selections('product_total_weight');
    }),

    sum_selections(property) {
      let result = this.get('selections_not_deleted').reduce(function (value, order_selection) {
        return (0, _big.default)(value).plus(order_selection.get(property) || 0);
      }, 0);
      return parseFloat((0, _big.default)(result || 0).toFixed(2));
    },

    // Price aliases to help with project-total reference
    prices_product_amount_cents: Ember.computed.readOnly('prices.product.amount_cents'),
    prices_product_vat_amount_cents: Ember.computed.readOnly('prices.product_vat.amount_cents'),
    prices_additional_amount_cents: Ember.computed.readOnly('prices.additional.amount_cents'),
    prices_shipping_amount_cents: Ember.computed.readOnly('prices.shipping.amount_cents'),
    prices_tax_amount_cents: Ember.computed.readOnly('prices.tax.amount_cents'),
    // Display helpers for generated lines
    display_name: Ember.computed('price_data.{is_generated,is_fixed,settings.symbol,settings.custom_name}', 'live_custom_name', 'is_bundle', 'product_bundle.name', 'selection.display_name', function () {
      // Generated types override everything else
      if (this.get('price_data.is_generated')) {
        if (this.get('price_data.settings.symbol') === 'extra_shipping_cap') {
          return "Extras Max Shipping Limit";
        }

        if (this.get('price_data.settings.symbol') === 'shipping_local_pickup') {
          return "Local Pickup";
        }

        if (Ember.isPresent(this.get('price_data.settings.custom_name'))) {
          return this.get('price_data.settings.custom_name');
        } // put this last as a fall through


        if (Ember.isPresent(this.get('live_custom_name'))) {
          return this.get('live_custom_name');
        }

        return "Custom";
      }

      if (this.get('price_data.is_fixed') && Ember.isPresent(this.get('price_data.settings.custom_name'))) {
        return this.get('price_data.settings.custom_name');
      }

      if (this.get('is_bundle')) {
        return this.get('product_bundle.name');
      }

      return this.get('selection.display_name');
    }),
    display_description: Ember.computed('is_bundle', 'product_bundle.description', 'selection.display_description', function () {
      // Currently we aren't doing anything with generated or calculated lines for description
      if (this.get('is_bundle')) {
        return this.get('product_bundle.description');
      }

      return this.get('selection.display_description');
    }),
    display_quantity: Ember.computed('is_bundle', 'selection.quantity', function () {
      if (this.get('is_bundle')) {
        return false;
      }

      return this.get('selection.quantity') > 1;
    })
  });

  _exports.default = _default;
});