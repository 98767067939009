define("manage/pods/companies/company/projects/project/sources/extras/components/product-mapping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZhmC++az",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"product\"],[8],[0,\"\\n  \"],[1,[28,\"sized-image\",null,[[\"image_path\",\"title\",\"width\",\"height\"],[[24,[\"product\",\"image_path\"]],[24,[\"name\"]],31,31]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"product\",\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[18,\"data-tooltip\",[24,[\"product\",\"description\"]]],[10,\"data-inverted\",\"\"],[10,\"data-size\",\"medium\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon link blue info letter\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"class\",\"ui green icon button\"],[12,\"data-tooltip\",[28,\"t\",[\"phrase.add_to_bottom\"],null]],[12,\"data-position\",\"right center\"],[3,\"action\",[[23,0,[]],[24,[\"mapItem\"]],[24,[\"product\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/extras/components/product-mapping/template.hbs"
    }
  });

  _exports.default = _default;
});