define("manage/models/fulfillment-group-project", ["exports", "@ember-data/model", "manage/utils/generated-model"], function (_exports, _model, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('fulfillment-group-project').reopen({
    exported: (0, _model.attr)('boolean'),
    group_items: Ember.computed.map('group_data', function (group_data) {
      return Ember.Object.create({
        product: this.store.peekRecord('product', group_data.product_id),
        product_variation: group_data.product_variation_id != null ? this.store.peekRecord('product-variation', group_data.product_variation_id) : null,
        quantity: group_data.quantity
      });
    })
  });

  _exports.default = _default;
});