define("manage/pods/companies/company/projects/project/sources/configurations/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    project: Ember.computed.readOnly('model.project'),
    configuration: Ember.computed.readOnly('model')
  });

  _exports.default = _default;
});