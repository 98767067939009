define("manage/components/ss-tab-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // tagName: '',
    tabs: null,
    tabComponentName: 'ss-tab',

    init() {
      this._super(...arguments);

      this.set('tabs', Ember.A([]));
    },

    perform(current) {
      let tabs = this.get('tabs');
      tabs.addObject(current);
    },

    actions: {
      toggle(current) {
        let tabs = this.get('tabs');

        for (let tab of tabs) {
          if (current === tab) {
            tab.toggle(true);
          } else {
            tab.toggle(false);
          }
        }
      }

    }
  });

  _exports.default = _default;
});