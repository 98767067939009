define("manage/pods/companies/company/projects/project/components/clone-shipping-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/utils/catch-real", "shared/utils/fallback-sort", "manage/config/environment"], function (_exports, _ssModal, _emberConcurrency, _catchReal, _fallbackSort, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    closable: false,
    classNames: ['clone-shipping-modal', 'fullscreen', 'component'],
    store: Ember.inject.service(),
    productTasks: Ember.inject.service(),
    project: null,
    product: null,
    bundles: null,
    products: null,
    selectedProduct: null,
    hasProducts: Ember.computed('bundles.[]', 'products.[]', function () {
      return this.get('bundles.length') > 0 || this.get('products.length') > 0;
    }),

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    // place holder for configurations call back
    refresh_ships_to() {},

    initTask: (0, _emberConcurrency.task)(function* () {
      let bundles = yield this.query('bundle');
      let products = yield this.query('physical');
      bundles = this.filter(bundles);
      products = this.filter(products);
      let bundle_sorter = (0, _fallbackSort.fallbackSort)('configuration.source.friendly_name', 'price', 'name');
      this.set('bundles', bundles.sort(bundle_sorter));
      this.set('products', products.sortBy('name'));
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('productTasks.cloneLocations').perform(this.get('product'), this.get('selectedProduct'));
      this.refresh_ships_to();
    }),

    query(filter) {
      return this.get('store').query('product', {
        filter: {
          project: this.get('project.id'),
          product_type: [filter]
        },
        include: ['zones', 'zones.locations'].join(','),
        page: {
          number: 1,
          size: _environment.default['max-page-size']
        }
      });
    },

    filter(products) {
      products = products.filter(p => p.get('shipping_product_based_zones.length') > 0);

      if (this.get('product.id') != null) {
        products = products.filter(p => p.get('id') !== this.get('product.id'));
      }

      return products;
    },

    actions: {
      save() {
        this.get('saveTask').perform().then(() => this.closeModal()).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: `Unable to import shipping.`
          });
        }));
      }

    }
  });

  _exports.default = _default;
});