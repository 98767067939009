define("manage/pods/companies/company/projects/project/sources/import/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    apps: Ember.computed.readOnly('model.apps'),
    // Every once in a blue moon we need to create an "Other" source in order
    // to dump a set of customers that weren't from any of our official providers
    //
    // This will take an external source and copy a few of the properties in
    // order to fulfill the necessary fields and then dummy out the rest.
    createExternalSourceTask: (0, _emberConcurrency.task)(function* (site) {
      let project = yield this.get('project');
      let sources = yield project.get('sources');
      let externalSource = sources.findBy('is_external', true);
      yield this.get('createSourceTask').perform(project, {
        site: site,
        name: Ember.String.capitalize(site),
        status: 'complete',
        url: externalSource.get('url'),
        creator: externalSource.get('creator'),
        external_id: project.get('slug'),
        image_url: externalSource.get('image_url'),
        location: externalSource.get('location'),
        category: externalSource.get('category')
      });
    }),
    createSourceTask: (0, _emberConcurrency.task)(function* (project, data) {
      project = yield project;
      let defaults = {
        order_count: 0,
        start_date: new Date(),
        end_date: new Date(),
        total_ordered_cents: 0,
        total_ordered_currency: project.get('currency'),
        goal_cents: 0,
        goal_currency: project.get('currency'),
        project: project,
        settings: {}
      };
      defaults = Object.assign(defaults, data);
      let source = this.get('store').createRecord('project-source', defaults);
      yield source.save();
      this.get('project.sources').pushObject(source);
      this.send('project_imported');
    }),
    actions: {
      project_imported() {
        return this.transitionToRoute('companies.company.projects.project');
      },

      app_installed(app) {
        // Must refresh for the sidebar
        this.get('store').findRecord('project', this.get('project.id'), {
          include: 'sources'
        }).then(() => {
          this.transitionToRoute('companies.company.projects.project.sources', app.get('settings.project_source_id'));
        });
      }

    }
  });

  _exports.default = _default;
});