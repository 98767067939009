define("manage/components/paging-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Attributes
    model: null,
    classes: null,
    // Set classes on paging menu
    route_name: null,
    page_size: 10,
    page: 1,
    record_count: Ember.computed('model.meta.record-count', function () {
      if (this.model == null || this.model.meta == null || this.model.meta['record-count'] == null) {
        return 0;
      }

      return this.model.meta['record-count'] || 0;
    }),
    page_count: Ember.computed('record_count', 'page_size', function () {
      return Math.ceil(this.record_count / (this.page_size || 10));
    }),
    page_prev: Ember.computed('page', function () {
      if (this.page > 1) {
        return this.page - 1;
      }

      return null;
    }),
    page_next: Ember.computed('page', 'page_count', function () {
      if (this.page < this.page_count) {
        return this.page + 1;
      }

      return null;
    }),
    is_page_first: Ember.computed.equal('page_prev', null),
    is_page_last: Ember.computed.equal('page_next', null),
    has_pages: Ember.computed.gt('page_count', 1),
    visible_pages: Ember.computed('page', 'page_count', function () {
      let page = this.page || 1;
      let page_count = this.page_count;
      let first = page - 2 < 1 ? 1 : page - 2;
      let records = Ember.A();

      for (let i = first; i <= page_count && records.length < 5; i++) {
        records.pushObject(i);
      }

      return records;
    })
  });

  _exports.default = _default;
});