define("manage/components/rollback-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YWaq+wSG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Abandon \"],[1,[22,\"modelName\"],false],[0,\" \"],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Creation\"]],\"parameters\":[]},{\"statements\":[[0,\"Changes\"]],\"parameters\":[]}],[0,\"?\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    You have changes that haven't been saved yet.\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    If you would like to save them, hit \\\"Wait Go Back\\\" and then click \\\"Save\\\" on the page.\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Otherwise, click \\\"Abandon Changes\\\" to reset any unsaved changes.\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n      Oh no! Looks like there was a problem rolling back the changes. You can try again, but if it continues to happen, please let us know.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui button \",[28,\"if\",[[24,[\"serviceTaskAction\",\"isRunning\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n    Wait Go Back\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui negative button \",[28,\"if\",[[24,[\"serviceTaskAction\",\"isRunning\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"perform\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"serviceTaskAction\",\"isIdle\"]]],null,{\"statements\":[[0,\"      Abandon Changes\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"spinner loading icon\"],[8],[9],[0,\" Resetting\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/rollback-modal/template.hbs"
    }
  });

  _exports.default = _default;
});