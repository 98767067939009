define("manage/pods/companies/company/projects/project/products/components/edit-vat-category-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRTuNxo2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\",\"show_error\"],[[24,[\"product\"]],\"vat_price_cents\",true,false]],{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[24,[\"vat_category\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"min\",\"step\"],[\"number\",[24,[\"money\"]],[24,[\"project\",\"currency_symbol\"]],\"0\",\"0.01\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/components/edit-vat-category-price/template.hbs"
    }
  });

  _exports.default = _default;
});