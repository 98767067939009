define("manage/pods/companies/company/projects/project/orders/tags/components/edit-tag-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/utils/catch-real", "manage/models/order-tag"], function (_exports, _ssModal, _emberConcurrency, _catchReal, _orderTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    title: "Edit tag",
    approve_label: "Save",
    closable: false,
    store: Ember.inject.service(),
    colors: _orderTag.colors,
    saveTask: (0, _emberConcurrency.task)(function* (tag) {
      tag = yield tag;
      yield tag.save();

      if (this.get('update')) {
        this.get('update')(tag);
      }
    }),
    actions: {
      save() {
        this.get('saveTask').perform(this.get('tag')).then(() => this.closeModal()).catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: `Unable to edit tag.`
          });
        }));
      }

    }
  });

  _exports.default = _default;
});