define("manage/models/project-settings", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _fragment, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    wizard: {
      description: "Wizard",
      validators: [(0, _emberCpValidations.validator)('belongs-to')]
    },
    support_days: {
      description: "Support Days",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gte: 0
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    survey_revision: (0, _model.attr)('string'),
    feature_flags: (0, _model.attr)('array', {
      defaultValue: function () {
        return [];
      }
    }),
    wizard: (0, _attributes.fragment)('project-settings-wizard', {
      defaultValue: {}
    }),
    analytics: (0, _attributes.fragment)('project-settings-analytics', {
      defaultValue: {}
    }),
    support_days: (0, _model.attr)('number')
  });

  _exports.default = _default;
});