define("manage/mixins/order-filters", ["exports", "manage/helpers/decode-string", "manage/helpers/get-selected", "manage/helpers/has-installed-app", "manage/helpers/has-installed-app-ever", "shared/utils/logger", "manage/mixins/filters/configurations", "manage/mixins/filters/countries", "manage/mixins/filters/digital", "manage/mixins/filters/fulfillments", "manage/mixins/filters/line-types", "manage/mixins/filters/local-pickup-addresses", "manage/mixins/filters/locking", "manage/mixins/filters/order-address", "manage/mixins/filters/order-transactions", "manage/mixins/filters/product-bundles", "manage/mixins/filters/products", "manage/mixins/filters/product-types", "manage/mixins/filters/product-waves", "manage/mixins/filters/segmentation", "manage/mixins/filters/statuses", "manage/mixins/filters/steps"], function (_exports, _decodeString, _getSelected, _hasInstalledApp, _hasInstalledAppEver, _logger, _configurations, _countries, _digital, _fulfillments, _lineTypes, _localPickupAddresses, _locking, _orderAddress, _orderTransactions, _productBundles, _products, _productTypes, _productWaves, _segmentation, _statuses, _steps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.operators = _exports.filters = _exports.default = _exports.computedProperties = void 0;
  let ProductHelpers = Ember.Mixin.create({
    // Product variation helper
    product_filter: Ember.computed('filters.@each.id', function () {
      return this.get('filters').findBy('id', 'product');
    }),
    filter_selected_products: Ember.computed('product_filter.value.[]', function () {
      let product_filter = this.get('product_filter');

      if (Ember.isBlank(product_filter)) {
        return Ember.A();
      }

      if (product_filter.get('operator') !== 'contains') {
        return Ember.A();
      }

      return product_filter.get('value');
    }),
    filter_product_variations: Ember.computed('filter_selected_products.[]', 'project.product_variations', function () {
      let product_variations = [];
      let filter_selected_products = this.get('filter_selected_products');

      if (Ember.isPresent(this.get('filter_selected_products'))) {
        let new_product_variations = this.get('project.product_variations').filter(function (product_variation) {
          return filter_selected_products.isAny('id', product_variation.get('product.id'));
        });
        product_variations.push(...new_product_variations);
      }

      return product_variations;
    }),
    filter_product_questions: Ember.computed('filter_selected_products.[]', 'project.product_questions', function () {
      let product_questions = [];
      let filter_selected_products = this.get('filter_selected_products');

      if (Ember.isPresent(this.get('filter_selected_products'))) {
        let new_product_questions = this.get('project.product_questions').filter(function (product_question) {
          return filter_selected_products.isAny('id', product_question.get('product.id'));
        });
        product_questions.push(...new_product_questions);
      }

      return product_questions;
    })
  });
  let computedProperties = Ember.Mixin.create(_configurations.default, _countries.default, _digital.default, _fulfillments.default, _lineTypes.default, _localPickupAddresses.default, _locking.default, _orderAddress.default, _orderTransactions.default, _productBundles.default, ProductHelpers, _products.default, _productTypes.default, _productWaves.default, _segmentation.default, _statuses.default, _steps.default); // Filter Settings
  //
  //   name: The friendly display name of the filter
  //
  //   type: The type of widget to render: select or text
  //   sub_type: The different types of widgets underneath that. select: multiple, single, text: any, tags
  //
  //   options_name: The options collection to select from
  //   options_key: The key to use from the options
  //   options_title: The options display field. If search is enabled, the field to search on
  //   search_enabled: For select types that have multiple options
  //
  //   operators: What types of operators you can use with this filter: in, out, eq, lt, gt
  //
  //   tooltip: This will show a tooltip when hovering over it
  //   placeholder: This will show a placeholder in the input box
  //

  _exports.computedProperties = computedProperties;
  let filters = {
    // Search
    search: {
      name: 'Search',
      type: 'text',
      sub_type: 'any',
      placeholder: 'Filter by Name',
      level: 'search'
    },
    // Location
    shipping_city: {
      name: 'City',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      tags_allow_spaces: true,
      tooltip: 'Shipping to addresses with these cities',
      level: 'location'
    },
    shipping_state: {
      name: 'State/Province',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      tags_allow_spaces: true,
      tooltip: 'Shipping to addresses with these state or provinces',
      level: 'location'
    },
    shipping_postal: {
      name: 'Postal Code',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      tags_allow_spaces: true,
      tooltip: 'Shipping to addresses with these postal codes',
      level: 'location'
    },
    shipping_country: {
      name: 'Country',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_countries',
      options_key: 'id',
      options_title: 'name',
      tags_allow_spaces: true,
      tooltip: 'Shipping to these countries',
      search_enabled: true,
      level: 'location'
    },
    shipping_phone: {
      name: 'Phone Number',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      tooltip: 'Shipping to addresses with these phone numbers',
      level: 'location'
    },
    // Local Pickup
    local_pickup_address_id: {
      name: 'Location Address',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'local_pickup_addresses',
      options_key: 'id',
      options_title: 'title',
      filtering_by_title: 'filter_title',
      // short attribute to show in 'Filtering by' section
      placeholder: 'Enter Local Pickup Addresses',
      tooltip: 'Local pickup from these addresses',
      search_enabled: false,
      level: 'local_pickup'
    },
    // Customer
    customer_id: {
      name: 'Customer Id',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      placeholder: 'Enter Customer Ids',
      tooltip: 'Unique Crowd Ox Id for this customer',
      search_enabled: false,
      level: 'customer'
    },
    customer_name: {
      name: 'Customer Name',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      placeholder: 'Enter Customer Names',
      tags_allow_spaces: true,
      tooltip: 'Imported name or shipping address name like this',
      search_enabled: false,
      level: 'customer'
    },
    customer_email: {
      name: 'Customer Email',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      placeholder: 'Enter Customer Emails',
      tooltip: 'Customers with these emails',
      search_enabled: false,
      level: 'customer'
    },
    // Order
    ids: {
      name: 'Id',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      placeholder: 'Enter Order Ids',
      tooltip: 'Order Ids',
      search_enabled: false,
      level: 'order'
    },
    external_id: {
      name: 'External Id',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      placeholder: 'Enter External Ids',
      tooltip: 'External ids that come from the original import source',
      search_enabled: false,
      level: 'order'
    },
    external_sequence: {
      name: 'External Sequence',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      placeholder: 'Enter Sequence Numbers',
      tooltip: 'The sequential number used by Kickstarter',
      search_enabled: false,
      level: 'order'
    },
    source: {
      name: 'Source',
      type: 'select',
      sub_type: 'single',
      operators: ['contains', 'excludes'],
      options_name: 'project.sources',
      options_key: 'site',
      options_title: 'friendly_name',
      tooltip: 'The original purchase source',
      search_enabled: false,
      level: 'order',
      // The backend must be searched on the keyword of site instead of source
      // here due to 3rd party restrictions.
      key: 'site'
    },
    step: {
      name: 'Step',
      type: 'select',
      sub_type: 'single',
      options_name: 'step_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Step the order is in the process',
      search_enabled: false,
      level: 'order'
    },
    status: {
      name: 'Status',
      type: 'select',
      sub_type: 'multiple',
      options_name: 'status_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Various different order statuses',
      search_enabled: true,
      level: 'order'
    },
    address: {
      name: 'Address and Shipping',
      type: 'select',
      sub_type: 'multiple',
      options_name: 'order_address_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Address statuses',
      search_enabled: true,
      level: 'order'
    },
    configuration: {
      name: 'Configuration',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_configurations',
      options_key: 'id',
      options_title: 'informational_title_with_amount',
      tooltip: 'Configuration that these orders are on',
      search_enabled: true,
      level: 'order'
    },
    original_configuration: {
      name: 'Original Configuration',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_configurations',
      options_key: 'id',
      options_title: 'informational_title_with_amount',
      tooltip: 'Configuration that these orders are on',
      search_enabled: true,
      level: 'order'
    },
    fulfillment_group: {
      name: 'Fulfillment Group',
      type: 'text',
      sub_type: 'any',
      operators: ['contains', 'excludes'],
      tooltip: 'Fulfillment group these orders belong too (advanced)',
      search_enabled: false,
      level: 'order'
    },
    created_at: {
      name: 'Created At',
      type: 'text',
      sub_type: 'date',
      operators: ['eq', 'gt', 'lt', 'range'],
      tooltip: 'Date the Order was created',
      search_enabled: false,
      level: 'order'
    },
    invited_at: {
      name: 'Invited At',
      type: 'text',
      sub_type: 'date',
      operators: ['any', 'none', 'eq', 'gt', 'lt', 'range'],
      tooltip: 'Date the Order was invited',
      search_enabled: false,
      level: 'order'
    },
    completed_at: {
      name: 'Completed At',
      type: 'text',
      sub_type: 'date',
      operators: ['eq', 'gt', 'lt', 'range'],
      tooltip: 'Date the Survey was completed',
      search_enabled: false,
      level: 'order'
    },
    last_imported_at: {
      name: 'Last Imported At',
      type: 'text',
      sub_type: 'date',
      operators: ['eq', 'gt', 'lt', 'range'],
      tooltip: 'Date the Order was last imported or updated from the Source',
      search_enabled: false,
      level: 'order'
    },
    reopened_at: {
      name: 'Reopened At',
      type: 'text',
      sub_type: 'date',
      operators: ['any', 'none', 'eq', 'gt', 'lt', 'range'],
      tooltip: 'Date an Order was reopened',
      search_enabled: false,
      level: 'order'
    },
    // Segments
    project_segment: {
      name: 'Segment',
      type: 'text',
      sub_type: 'any',
      operators: ['contains', 'in', 'excludes'],
      tooltip: 'Segment these orders belong too (advanced)',
      search_enabled: false,
      level: 'order'
    },
    project_segment_parent: {
      name: 'Segment Parent',
      type: 'text',
      sub_type: 'any',
      operators: ['contains', 'excludes'],
      tooltip: 'Segment parent these orders belong too (advanced)',
      search_enabled: false,
      level: 'order'
    },
    project_segment_action: {
      name: 'Segment Action',
      type: 'text',
      sub_type: 'any',
      operators: ['contains', 'excludes'],
      tooltip: 'Segment action these orders belong too (advanced)',
      search_enabled: false,
      level: 'order'
    },
    project_segment_action_operation: {
      name: 'Segment Action Operation',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_action_operation',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Configuration that these orders are on',
      search_enabled: false,
      level: 'order'
    },
    project_segment_action_state: {
      name: 'Segment Action State',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_action_state',
      options_key: 'id',
      options_title: 'title',
      search_enabled: false,
      level: 'order'
    },
    project_segment_order_action_state: {
      name: 'Segment Order Action State',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_order_action_state',
      options_key: 'id',
      options_title: 'title',
      search_enabled: false,
      level: 'order'
    },
    tag: {
      name: 'Tag',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'excludes'],
      tags_allow_spaces: true,
      level: 'order'
    },
    synced: {
      name: 'Synced',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes', 'in'],
      options_name: 'filter_synced',
      options_key: 'slugWithoutPrefix',
      options_title: 'name',
      level: 'order'
    },
    tracking_number: {
      name: 'Tracking Number',
      type: 'text',
      sub_type: 'tags',
      operators: ['any', 'none', 'contains'],
      search_enabled: false,
      level: 'order'
    },
    // Selections
    product: {
      name: 'Product',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in', 'excludes'],
      options_name: 'filter_products',
      options_key: 'id',
      options_title: 'name',
      tooltip: 'Must include these products',
      search_enabled: true,
      level: 'selection'
    },
    product_bundle: {
      name: 'Product Bundle',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in', 'excludes', 'none', 'any'],
      options_name: 'filter_product_bundles',
      options_key: 'id',
      options_title: 'name',
      tooltip: 'Must include these product bundles',
      search_enabled: true,
      level: 'selection'
    },
    product_count: {
      name: 'Product Count',
      type: 'text',
      sub_type: 'number',
      operators: ['eq', 'gt', 'lt'],
      placeholder: 'Enter Number',
      level: 'order'
    },
    product_variation: {
      name: 'Product Variation',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'filter_product_variations',
      options_key: 'id',
      options_title: 'full_name_with_product',
      tooltip: 'Must include these product variations',
      search_enabled: true,
      not_found_message: "No Product Variations found. Please add a Product Filter with Variations to show results.",
      level: 'selection'
    },
    product_type: {
      name: 'Product Type',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'product_types',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Must include these product types',
      search_enabled: false,
      level: 'selection'
    },
    product_sku: {
      name: 'Product Sku',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains', 'in', 'excludes'],
      placeholder: 'Enter Skus',
      tags_allow_spaces: true,
      tooltip: 'Must include these product skus',
      level: 'selection'
    },
    product_wave: {
      name: 'Product Wave',
      type: 'select',
      sub_type: 'single',
      options_name: 'product_waves',
      options_key: 'id',
      options_title: 'title',
      operators: ['contains'],
      tooltip: 'The shipping wave the order and product are on',
      help: `
      <p>
        This will filter order selections to include <em>Physical</em> products with the <b>Product Wave</b> you choose.
        Each customer chose to have a <em>single</em> shipment or <em>split</em> it and pay the additional shipping costs.
      </p>

      <p>
        <b>One</b>: All order selections that are in Wave One and the order is split <br/>
        OR the entire order contains only Wave One products.
      </p>
      <p>
        <b>Two</b>: All order selections that are in Wave Two and the order is split <br/>
        OR the entire order contains only Wave Two products <br/>
        OR they have Wave One and Wave Two but chose to keep a single shipment.
      </p>
    `,
      search_enabled: false,
      level: 'selection'
    },
    product_question: {
      name: 'Product Question',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains'],
      // Adding excludes means the backend can't join together the product_question and product_question_answer which is a primary usecase
      options_name: 'filter_product_questions',
      options_key: 'id',
      options_title: 'text',
      tooltip: 'Must include these product questions',
      search_enabled: true,
      not_found_message: "No Product Questions found. Please add a Product Filter with Questions to show results.",
      level: 'selection'
    },
    product_question_answer: {
      name: 'Product Question Answer',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains'],
      // Adding excludes means the backend can't join together the product_question and product_question_answer which is a primary usecase
      placeholder: 'Enter Answers',
      tags_allow_spaces: true,
      tooltip: 'Must include order selections with these answers',
      level: 'selection'
    },
    line_type: {
      name: 'Line Type',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'excludes'],
      options_name: 'line_types',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Must include these line types',
      search_enabled: false,
      level: 'selection'
    },
    fulfillment: {
      name: 'Fulfillment',
      type: 'select',
      sub_type: 'single',
      options_name: 'fulfillment_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Fulfillment',
      search_enabled: false,
      level: 'selection'
    },
    selection_created_at: {
      name: 'Selection Created At',
      type: 'text',
      sub_type: 'date',
      operators: ['eq', 'gt', 'lt', 'range'],
      tooltip: 'Date an Order Selection was added',
      search_enabled: false,
      level: 'selection'
    },
    // Locking
    locked_selection: {
      name: 'Locked Selection',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in', 'excludes', 'any', 'none'],
      options_name: 'locking_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Searches for any order with a locked selection of the specified type. Locking occurs when you mark selections as shipped, run a locking export or when digital items get delivered.',
      search_enabled: false,
      level: 'selection'
    },
    unlocked_selection: {
      name: 'Unlocked Selection',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in', 'excludes', 'any', 'none'],
      options_name: 'locking_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Searches for any order with an unlocked selection of the specified type. Locking occurs when you mark selections as shipped, run a locking export or when digital items get delivered.',
      search_enabled: false,
      level: 'selection'
    },
    exported: {
      name: 'Exported Selection',
      type: 'select',
      sub_type: 'single',
      operators: ['contains'],
      options_name: 'locking_exporting_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Searches for any order with a selection that has been exported or not',
      search_enabled: false,
      level: 'selection'
    },
    // Transactions
    tx_balance: {
      name: 'Current Balance',
      type: 'text',
      sub_type: 'number',
      operators: ['eq', 'gt', 'lt'],
      placeholder: 'Enter Amount',
      level: 'transaction'
    },
    tx_paid: {
      name: 'Total Paid',
      type: 'text',
      sub_type: 'number',
      operators: ['eq', 'gt', 'lt'],
      placeholder: 'Enter Amount',
      level: 'transaction'
    },
    tx_total: {
      name: 'Total Cost',
      type: 'text',
      sub_type: 'number',
      operators: ['eq', 'gt', 'lt'],
      placeholder: 'Enter Amount',
      level: 'transaction'
    },
    tx_shipping: {
      name: 'Calculated Shipping',
      type: 'text',
      sub_type: 'number',
      operators: ['eq', 'gt', 'lt'],
      placeholder: 'Enter Amount',
      level: 'transaction'
    },
    tx_refund: {
      name: 'Refunds',
      type: 'text',
      sub_type: 'number',
      operators: ['eq', 'gt', 'lt'],
      placeholder: 'Enter Amount',
      level: 'transaction'
    },
    tx_provider: {
      name: 'Provider',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in'],
      options_name: 'transaction_provider_options',
      options_key: 'id',
      options_title: 'title',
      level: 'transaction'
    },
    tx_confirmed: {
      name: 'Confirmed',
      type: 'text',
      sub_type: 'checkbox',
      label: 'Confirmed',
      level: 'transaction'
    },
    // Digital
    digital_type: {
      name: 'Digital Type',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in'],
      options_name: 'digital_type_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Searches for any order with a digital fulfillment associated with a product on their order that is of this type',
      search_enabled: false,
      level: 'selection'
    },
    digital_status: {
      name: 'Digital Status',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in'],
      options_name: 'digital_status_options',
      options_key: 'id',
      options_title: 'title',
      tooltip: 'Searches for any order with a digital fulfillment associated with a product on their order that has this status',
      search_enabled: false,
      level: 'selection'
    },
    digital_assigned_key: {
      name: 'Assigned Key for Product',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in', 'excludes'],
      options_name: 'digital_key_products',
      options_key: 'id',
      options_title: 'name',
      search_enabled: true,
      level: 'selection'
    },
    digital_assigned_download: {
      name: 'Assigned Download for Product',
      type: 'select',
      sub_type: 'multiple',
      operators: ['contains', 'in', 'excludes'],
      options_name: 'digital_download_products',
      options_key: 'id',
      options_title: 'name',
      search_enabled: true,
      level: 'selection'
    },
    digital_assignment: {
      name: 'Digital Assignment',
      type: 'select',
      sub_type: 'single',
      operators: ['contains'],
      options_name: 'digital_assignment_options',
      options_key: 'id',
      options_title: 'title',
      search_enabled: false,
      level: 'selection'
    },
    digital_email: {
      name: 'Digital Email',
      type: 'select',
      sub_type: 'single',
      operators: ['contains'],
      options_name: 'digital_email_options',
      options_key: 'id',
      options_title: 'name',
      search_enabled: false,
      level: 'selection'
    },
    digital_email_status: {
      name: 'Digital Email Status',
      type: 'select',
      sub_type: 'single',
      operators: ['none'],
      options_name: 'digital_email_status_options',
      options_key: 'id',
      options_title: 'title',
      search_enabled: false,
      level: 'selection'
    },
    // Tracking
    tracking_key: {
      name: 'Tracking Key',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains'],
      // Adding excludes means the backend can't join together the tracking.parameters and tracking.parameter_values which is a primary usecase
      placeholder: 'Enter Parameters',
      tags_allow_spaces: true,
      tooltip: 'Add them in parameter=value format',
      level: 'order'
    },
    tracking_value: {
      name: 'Tracking Value',
      type: 'text',
      sub_type: 'tags',
      operators: ['contains'],
      // Adding excludes means the backend can't join together the tracking.parameters and tracking.parameter_values which is a primary usecase
      placeholder: 'Enter Parameters',
      tags_allow_spaces: true,
      tooltip: 'Add them in parameter=value format',
      level: 'order'
    },
    // Custom
    //   This doesn't have any settings by default. The individual custom fields get populated at runtime based
    //   on how they are configured.
    custom: {}
  };
  _exports.filters = filters;
  let operators = {
    none: 'None',
    any: 'Any',
    contains: 'Includes Any',
    in: 'Includes All',
    excludes: 'Excludes Any',
    out: 'Excludes All',
    gt: 'Greater Than',
    lt: 'Less Than',
    eq: 'Equal To',
    range: 'Between'
  };
  _exports.operators = operators;
  let filterOption = Ember.Object.extend({
    range_from: Ember.computed({
      get() {
        return this.get('_range_from');
      },

      set(_key, value) {
        this.set('value', `${value}..${this.get('range_to')}`);
        this.set('_range_from', value);
        return value;
      }

    }),
    range_to: Ember.computed({
      get() {
        return this.get('_range_to');
      },

      set(_key, value) {
        this.set('value', `${this.get('range_from')}..${value}`);
        this.set('_range_to', value);
        return value;
      }

    })
  });

  var _default = Ember.Mixin.create(computedProperties, {
    store: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    filter_operators: operators,
    filter_settings: filters,
    order_custom_fields: Ember.computed.filterBy('project.custom_fields', 'entity', 'order'),

    build_filter_selections(filter_data) {
      let is_admin = this.get('current_user.is_admin');
      filter_data = filter_data || {};
      let filter_ids = Object.keys(filter_data);
      let filter_settings = filters; // Cache the keys for didReceiveAttrs

      this.set('filter_ids', filter_ids);
      let filter_selections = Ember.A(); // Filters are only built up on init, after they they are updated, but filter choices aren't dynamic

      let build_option = name => {
        let settings = filter_settings[name];
        return Ember.Object.create({
          id: name,
          name: settings.name,
          disabled: false
        });
      };

      let verify_filter_options = (filter_selection, filter_ids) => {
        let options_to_remove = Ember.A();

        for (let option of filter_selection.options) {
          if (!filter_ids.includes(option.get('id')) && option.get('id').indexOf('custom.') != 0) {
            options_to_remove.push(option);
          }
        }

        filter_selection.options.removeObjects(options_to_remove);
      };

      let add_options = selection => {
        selection.options = selection.options.compact();
        verify_filter_options(selection, filter_ids); // If we have selection options, then push as a filter

        if (selection.options.length > 0) {
          if (selection.groupName) {
            filter_selections.push(selection);
          } else {
            filter_selections = filter_selections.concat(selection.options);
          }
        }
      }; // Search


      add_options({
        // No group name because there is a single option
        // with the same name
        options: Ember.A([build_option('search')])
      });
      let shippingGroupName = this.get('project.addresses.length') > 0 ? Ember.String.htmlSafe("Shipping Location<div class='ember-power-select-group-subtitle'>(Excludes Local Pickup)</div>") : 'Shipping Location'; // Location

      add_options({
        groupName: shippingGroupName,
        options: Ember.A([build_option('shipping_city'), build_option('shipping_state'), build_option('shipping_postal'), build_option('shipping_country'), build_option('shipping_phone')])
      });

      if (this.get('project.addresses.length') > 0) {
        add_options({
          groupName: "Local Pickup",
          options: Ember.A([build_option('local_pickup_address_id')])
        });
      } // Customer


      add_options({
        groupName: 'Customer',
        options: Ember.A([build_option('customer_id'), build_option('customer_name'), build_option('customer_email')])
      }); // Order

      add_options({
        groupName: 'Order',
        options: Ember.A([build_option('ids'), build_option('tag'), build_option('synced'), build_option('external_id'), build_option('external_sequence'), build_option('source'), build_option('step'), build_option('status'), build_option('address'), build_option('configuration'), build_option('original_configuration'), build_option('fulfillment_group'), build_option('tracking_number'), build_option('created_at'), build_option('invited_at'), build_option('completed_at'), build_option('reopened_at'), build_option('last_imported_at')])
      });
      add_options({
        groupName: 'Segment',
        options: Ember.A([build_option('project_segment'), is_admin ? build_option('project_segment_parent') : null, is_admin ? build_option('project_segment_action') : null, is_admin ? build_option('project_segment_action_operation') : null, is_admin ? build_option('project_segment_action_state') : null, is_admin ? build_option('project_segment_order_action_state') : null])
      }); // Selection

      add_options({
        groupName: 'Selections',
        options: Ember.A([build_option('product'), build_option('product_bundle'), build_option('product_variation'), build_option('product_type'), build_option('product_sku'), build_option('product_question'), build_option('product_question_answer'), build_option('product_count'), (0, _hasInstalledApp.hasInstalledApp)(this.get('project'), 'wave-shipping') ? build_option('product_wave') : null, build_option('line_type'), build_option('fulfillment'), build_option('selection_created_at')])
      }); // Locking

      add_options({
        groupName: 'Locking',
        options: Ember.A([build_option('locked_selection'), build_option('unlocked_selection'), build_option('exported')])
      }), // Transaction
      add_options({
        groupName: 'Transactions',
        options: Ember.A([build_option('tx_balance'), build_option('tx_paid'), build_option('tx_total'), build_option('tx_shipping'), build_option('tx_refund'), build_option('tx_provider'), build_option('tx_confirmed')])
      }); // Digital

      if ((0, _hasInstalledAppEver.hasInstalledAppEver)(this.get('project'), 'digital-fulfillment')) {
        add_options({
          groupName: 'Digital',
          options: Ember.A([build_option('digital_type'), build_option('digital_assignment'), build_option('digital_email'), build_option('digital_email_status'), build_option('digital_assigned_key'), build_option('digital_assigned_download'), build_option('digital_status')])
        });
      } // Tracking


      add_options({
        groupName: 'Tracking',
        options: Ember.A([build_option('tracking_key'), build_option('tracking_value')])
      });
      let order_custom_fields = this.get('order_custom_fields');

      if (order_custom_fields.get('length')) {
        let that = this; // Custom

        add_options({
          groupName: 'Custom Fields',
          options: order_custom_fields.map(custom_field => {
            let id = `custom.${custom_field.get('public_id')}`;
            that.get('filter_settings')[id] = {
              name: custom_field.get('name'),
              type: 'text',
              sub_type: 'tags',
              operators: ['contains', 'excludes'],
              tags_allow_spaces: true,
              level: 'order'
            };
            that.get('filter_ids').push(id);
            return Ember.Object.create({
              id: id,
              name: custom_field.get('name'),
              disabled: false
            });
          })
        });
      }

      if (filter_selections.length === 0) {
        (0, _logger.logger_error)("No filters were present in initialization of order-filter. Must pass in filter_data as a hash param");
      }

      this.set('filter_selections', filter_selections);
      this.set('total_filters', filter_selections.reduce((value, filter_selection) => {
        if (filter_selection.options) {
          return value + filter_selection.options.length;
        } else {
          return value + 1;
        }
      }, 0));
    },

    get_filter_option(filter_id) {
      for (let filter_selection of this.get('filter_selections')) {
        if (filter_selection.options) {
          for (let filter_option of filter_selection.options) {
            if (filter_option.get('id') === filter_id) {
              return filter_option;
            }
          }
        } else {
          if (filter_selection.get('id') === filter_id) {
            return filter_selection;
          }
        }
      }
    },

    process_filters_from_data() {
      // Modify and update all filter params based on filter_ids
      let filter_data = this.get('filter_data') || {};

      for (let filter_id of this.get('filter_ids')) {
        let filter_custom = filter_id.indexOf('custom.') === 0;
        let filter_setting = this.get('filter_settings')[filter_id];

        if (Ember.isBlank(filter_setting) && filter_id !== 'project' && filter_id !== 'project_id') {
          (0, _logger.logger_error)(`Missing filter_id:${filter_id}`);
          continue;
        }

        let filter_option = this.get_filter_option(filter_id);

        if (filter_option === undefined) {
          continue;
        }

        let value;

        if (filter_custom) {
          let custom_id = filter_id.substr(filter_id.indexOf('.') + 1);
          let custom_data = filter_data['custom'] || {};

          if (typeof custom_data === 'object') {
            value = custom_data[custom_id];
          } else if (typeof custom_data === 'string' && Ember.isPresent(custom_data)) {
            value = JSON.parse((0, _decodeString.decodeString)(custom_data))[custom_id];
          }
        } else {
          value = filter_data[filter_id];
        }

        let filter = this.get('filters').findBy('id', filter_id); // If we have a filter, verify the values match

        if (Ember.isPresent(filter)) {
          if (Ember.isBlank(value)) {
            // The value is blank, remove the filter
            this.get('filters').removeObject(filter);
            filter_option.set('disabled', false);
          } else {
            // If we have a filter, verify all the values match
            this.update_filter(filter, value);
          }
        } else {
          // If we don't have a filter, add one if we have values
          if (Ember.isPresent(value)) {
            let filter = this.create_filter(filter_id, value);
            this.get('filters').pushObject(filter);
            filter_option.set('disabled', true);
          }
        }
      }
    },

    update_filter_option_disabled(filter_id, is_disabled) {
      let filter_option = this.get_filter_option(filter_id);
      filter_option.set('disabled', is_disabled);
    },

    update_values_from_filters() {
      // If we aren't specifically cancelling, then apply the new filter values to the filter keys
      let values = {};

      for (let filter_id of this.get('filter_ids')) {
        if (filter_id === 'custom') {
          values[filter_id] = {};
        } else if (filter_id.indexOf('custom.') === 0) {// We don't do anything with these properties
          // They are handled in other places inside the custom hash
        } else {
          values[filter_id] = '';
        }

        let filter = this.get('filters').findBy('id', filter_id);

        if (Ember.isPresent(filter)) {
          if (filter.get('operator') === 'none') {
            Ember.set(values, filter_id, 'none');
          } else if (filter.get('operator') === 'any') {
            Ember.set(values, filter_id, 'any');
          } else if (Ember.isPresent(filter.get('value'))) {
            let filter_settings = this.get('filter_settings')[filter_id];

            if (filter_settings.type === 'select' && filter_settings.sub_type === 'multiple') {
              Ember.set(values, filter_id, filter.get('value').map(value => value.get(filter_settings.options_key).toString()).join(','));
            } else if (filter_settings.type === 'select' && filter_settings.sub_type === 'single') {
              Ember.set(values, filter_id, filter.get('value').get(filter_settings.options_key).toString());
            } else if (filter_settings.type === 'text') {
              Ember.set(values, filter_id, filter.get('value').toString());
            } else {
              throw new Error(`Unable to get a filter value for filter_settings: ${filter_settings.id}. Unknown type`);
            }

            if (filter.get('operator') && filter.get('operator') !== 'contains') {
              Ember.set(values, filter_id, `${filter.get('operator')}=${Ember.get(values, filter_id)}`);
            }
          }
        }
      }

      this.get('update').call(this, values);
    },

    // The hook back to the controller to update the query params
    update() {// The method to call to update
    },

    create_filter(filter_id, value) {
      let filter_settings = this.get('filter_settings')[filter_id];
      let filter = filterOption.create({
        id: filter_id,
        name: filter_settings.name,
        settings: filter_settings
      });

      if (filter_settings.operators) {
        filter.set('operator', filter_settings.operators[0]);
      }

      if (filter_settings.type === 'select' && filter_settings.sub_type === 'multiple') {
        filter.set('value', Ember.A());
      } else if (filter_settings.type === 'select' && filter_settings.sub_type === 'single') {
        filter.set('value', null);
      } else if (filter_settings.type === 'text') {
        if (filter_settings.sub_type === 'checkbox') {
          filter.set('value', false);
        } else {
          filter.set('value', null);
        }

        if (filter_settings.sub_type === 'tags') {
          filter.set('tags', []);
        }
      } else {
        throw new Error(`Unable to create a filter for filter_settings: ${filter_settings.id}. Unknown type`);
      }

      if (Ember.isPresent(value)) {
        this.update_filter(filter, value);
      }

      return filter;
    },

    // It is assumed that if you are calling update filter, value isn't blank
    update_filter(filter, value) {
      let filter_settings = this.get('filter_settings')[filter.get('id')]; // Parse out an operator if it's part of the value

      let operator = 'contains';
      let operator_matches = value.match(/(?:(contains|in|excludes|out|eq|ne|lt|gt|range)=)?(.*)/);

      if (operator_matches.length == 3) {
        operator = operator_matches[1] || 'contains';
        value = operator_matches[2];
      }

      filter.set('operator', operator);

      if (value === "none") {
        filter.operator = 'none';
        operator = 'none';
        value = '';
      }

      if (value === "any") {
        filter.operator = 'any';
        operator = 'any';
        value = '';
      }

      if (operator === 'range') {
        let [range_from, range_to] = value.split('..');
        filter.set('range_from', range_from);
        filter.set('range_to', range_to);
      }

      if (filter_settings.type === 'select' && filter_settings.sub_type === 'multiple') {
        // Parse values
        let values = value.split(',');
        let collection = (0, _getSelected.getOptions)(this.get(filter_settings.options_name));
        let filter_values = collection.filter(item => values.includes((item.get(filter_settings.options_key) || '').toString()));
        let to_remove = [...filter.get('value')]; // Clone array

        let to_add = Ember.A();

        for (let filter_value of filter_values) {
          // If filter values has the value, remove from the to remove collection
          if (filter.get('value').includes(filter_value)) {
            to_remove.removeObject(filter_value);
          } else {
            // Otherwise we need to add to the collection
            to_add.push(filter_value);
          }
        }

        if (to_remove.length > 0) {
          filter.get('value').removeObjects(to_remove);
        }

        if (to_add.length > 0) {
          filter.get('value').pushObjects(to_add);
        }
      } else if (filter_settings.type === 'select' && filter_settings.sub_type === 'single') {
        // Get value
        let collection = (0, _getSelected.getOptions)(this.get(filter_settings.options_name));
        let filter_value = collection.findBy(filter_settings.options_key, value); // If its null or undefined, always default to null

        if (filter_value == null) {
          filter_value = null;
        }

        if (filter_value !== filter.get('value')) {
          filter.set('value', filter_value);
        }
      } else if (filter_settings.type === 'text') {
        if (filter_settings.sub_type === 'checkbox') {
          filter.set('value', Boolean(value));
        } else {
          filter.set('value', value);
        }

        if (filter_settings.sub_type === 'tags') {
          filter.set('tags', value.split(','));
        }
      } else {
        throw new Error(`Unable to update a filter for filter_settings: ${filter_settings.id}. Unknown type`);
      }
    },

    // The hook back to the controller or component to update the friendly text
    update_friendly(friendly_text) {// eslint-disable-line no-unused-vars
      // The method to call to update
    },

    process_friendly_text() {
      let friendly_texts = [];
      let includes_promise = false;

      for (let filter of this.get('filters')) {
        if (Ember.isBlank(filter.get('value'))) {
          continue;
        }

        let filter_settings = this.get('filter_settings')[filter.get('id')];
        let friendly_operator = this.get('filter_operators')[filter.get('operator')].toLowerCase();

        if (filter_settings.name === 'Segment') {
          filter.get('value').split(',').map(id => {
            let record = this.get('store').peekRecord('project-segment', id);

            if (record) {
              friendly_texts.push(`${filter.get('name')} ${friendly_operator} ${record.get('name')}`);
            } else {
              includes_promise = true;
              friendly_texts.push(this.get('store').findRecord('project-segment', id).then(server_record => {
                return `${filter.get('name')} ${friendly_operator} ${server_record.get('name')}`;
              }).catch(() => {
                return `${filter.get('name')} ${friendly_operator} ${id}`;
              }));
            }
          });
        } else if (filter_settings.type === 'select') {
          let values = filter.get('value');

          if (filter_settings.sub_type === 'single') {
            values = [values];
          }

          friendly_texts.push(`${filter.get('name')} ${friendly_operator} ${values.map(value => value.get(filter_settings.filtering_by_title || filter_settings.options_title)).join(', ')}`);
        } else if (filter_settings.type === 'text') {
          friendly_texts.push(`${filter.get('name')} ${friendly_operator} ${filter.get('value')}`);
        } else {
          throw new Error(`Unable to create friendly filter text for filter_settings: ${filter_settings.id}. Unknown type`);
        }
      }

      if (includes_promise) {
        Ember.RSVP.all(friendly_texts).then(() => {
          let resolved = friendly_texts.map(item => {
            if (typeof item._result === "undefined") {
              return item;
            } else {
              return item._result;
            }
          });
          this.update_friendly(resolved.join('; '));
        });
      } else {
        this.update_friendly(friendly_texts.join('; '));
      }
    },

    actions: {
      select_filter(filter_option) {
        let filter_id = filter_option.get('id');
        this.get('filters').pushObject(this.create_filter(filter_id));
        this.update_filter_option_disabled(filter_id, true);
      },

      remove_filter(filter) {
        this.get('filters').removeObject(filter);
        this.update_filter_option_disabled(filter.get('id'), false);
      },

      remove_all_filters() {
        for (let filter of this.get('filters')) {
          this.update_filter_option_disabled(filter.get('id'), false);
        }

        this.get('filters').clear();
      },

      addTag(filter, tag) {
        filter.tags.pushObject(tag);
        filter.set('value', filter.tags.join(','));
      },

      removeTagAtIndex(filter, index) {
        filter.tags.removeAt(index);
        filter.set('value', filter.tags.join(','));
      }

    }
  });

  _exports.default = _default;
});