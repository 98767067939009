define("manage/models/project-custom-field-value", ["exports", "manage/utils/generated-model", "ember-cp-validations"], function (_exports, _generatedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    },
    custom_field: {
      description: "Custom Field",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    },
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    sort_order: {
      description: "Sort Order",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  });

  var _default = (0, _generatedModel.default)('project-custom-field-value').reopen(Validations, {});

  _exports.default = _default;
});