define("manage/models/project-urls", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/fragment"], function (_exports, _model, _emberCpValidations, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    homepage_url: {
      description: "Homepage Url",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^http(.*)/,
        message: 'Please use the full url starting with http or https'
      })]
    },
    twitter: {
      description: "Twitter Handle",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^@[A-Za-z0-9_]+/,
        message: 'Must start with an @'
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    homepage_url: (0, _model.attr)('string'),
    twitter: (0, _model.attr)('string')
  });

  _exports.default = _default;
});