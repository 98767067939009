define("manage/pods/users/invitation/accept/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      description: "Password",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    passwordConfirmation: {
      description: "Password Confirmation",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    accept_agreements: {
      description: "I accept",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        message: "{description} must be checked",
        in: [true]
      })]
    }
  });

  var _default = Ember.Controller.extend(Validations, {
    ajax: Ember.inject.service(),
    queryParams: ['invitation_token'],
    errors: null,
    server_errors: null,
    password: null,
    passwordConfirmation: null,
    accept_agreements: false,
    isInvalidAfterSubmit: Ember.computed('validations.isInvalid', 'submitted', 'server_errors', function () {
      return (this.get('validations.isInvalid') || Ember.isPresent(this.get('server_errors'))) && this.get('submitted');
    }),
    filled_in: Ember.computed('password', 'passwordConfirmation', 'accept_agreements', 'validations.isValid', function () {
      return Ember.isPresent(this.get('password')) && Ember.isPresent(this.get('passwordConfirmation')) && Ember.isPresent(this.get('accept_agreements')) && this.get('validations.isValid');
    }),
    text_changed: Ember.observer('password', 'passwordConfirmation', function () {
      if (this.get('server_errors')) {
        return this.set('server_errors', null);
      }
    }),
    actions: {
      accept() {
        this.set('submitted', true);
        let result = this.validateSync();

        if (result.validations.get('isInvalid')) {
          let errorMessages = result.validations.get('messages').join('<br> ');
          this.send('open_modal', 'confirm-modal', {
            i18nKey: 'need-information',
            question: errorMessages
          });
          return;
        }

        this.set('loading', true);
        let data = {
          invitation_token: this.get('invitation_token'),
          password: this.get('password'),
          password_confirmation: this.get('passwordConfirmation')
        };
        return this.get('ajax').post("/api/v2/users/accept", {
          data: data
        }).then(() => this.replaceRoute('users.invitation.accepted')).catch(response => {
          this.set('server_errors', response.payload.errors.mapBy('detail'));
        }).finally(() => {
          this.set('loading', false);
        });
      }

    }
  });

  _exports.default = _default;
});