define("manage/pods/companies/company/projects/project/digital/view/downloads/controller", ["exports", "ember-concurrency", "manage/pods/companies/company/projects/project/digital/constants"], function (_exports, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_constants.default, {
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    project: Ember.computed.readOnly('model.project'),
    fulfillment: Ember.computed.readOnly('model.fulfillment'),
    downloads: Ember.computed.readOnly('model.downloads'),
    filtered_downloads: Ember.computed.filterBy('downloads', 'isDeleted', false),
    queryParams: ['page', 'page_size', 'sortProperty', 'sortDirection'],
    // Paging
    page: 1,
    page_size: 50,
    // Sorting
    sortProperty: 'id',
    sortDirection: 'asc',
    sorted_by: ['id:asc'],
    sorted_downloads: Ember.computed.sort('filtered_downloads', 'sorted_by'),
    deleteDownloadTask: (0, _emberConcurrency.task)(function* (download) {
      download = yield download;
      download.deleteRecord();

      try {
        yield download.save();
      } catch (error) {
        download.rollbackAttributes();
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to remove."
        });
      }
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sortProperty') !== name) {
          this.set('sortProperty', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortProperty', name);
          this.set('sortDirection', direction);
        }

        return this.set('sorted_by', [`${this.get('sortProperty')}:${this.get('sortDirection')}`]);
      },

      refresh() {
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      },

      create_download() {
        let download = this.store.createRecord('digital-download', {
          project: this.get('project'),
          product: this.get('fulfillment.product'),
          fulfillment: this.get('fulfillment'),
          download_limit: 5
        });
        return this.send('open_modal', 'companies/company/projects/project/digital/edit-download-modal', {
          download: download,
          created: () => {
            this.send('refresh');
          }
        });
      },

      delete_download(download) {
        this.send('open_modal', 'delete-confirmation-modal', {
          entity: download,
          i18nKey: 'delete-download',
          i18nParams: {
            name: download.get('name') || download.get('file_name')
          },
          approve: () => {
            this.get('deleteDownloadTask').perform(download).then(() => {
              this.send('refresh');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});