define("manage/models/payment", ["exports", "@ember-data/model", "manage/mixins/stripe"], function (_exports, _model, _stripe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_stripe.default, {
    name: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    state: (0, _model.attr)('string')
  });

  _exports.default = _default;
});