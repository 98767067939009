define("manage/pods/companies/company/projects/project/welcome/location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ytSnDB4H",
    "block": "{\"symbols\":[\"state\"],\"statements\":[[1,[28,\"t-title\",[[23,0,[]]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui header settings\"],[8],[1,[28,\"t-route\",[\"header\"],null],false],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[1,[28,\"t-route\",[\"content.instructions\"],[[\"htmlSafe\"],[true]]],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"company\"]],\"country\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"country-dropdown\",null,[[\"country\",\"onChange\"],[[24,[\"company\",\"country\"]],[28,\"action\",[[23,0,[]],\"onCountryChange\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"company\",\"country\"]],[24,[\"company\",\"country\",\"states\",\"length\"]]],null]],null,{\"statements\":[[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\",\"class\"],[[24,[\"company\"]],\"state\",[24,[\"submitted\"]],\"state-dropdown\"]],{\"statements\":[[0,\"      \"],[7,\"label\",true],[10,\"for\",\"state-dropdown\"],[8],[0,\"\\n        State\\n      \"],[9],[0,\"\\n      \"],[5,\"power-select\",[],[[\"@triggerId\",\"@placeholder\",\"@searchField\",\"@searchEnabled\",\"@loadingMessage\",\"@selected\",\"@options\",\"@onChange\"],[\"state-dropdown\",\"Select State\",\"name\",true,\"Loading States\",[24,[\"company\",\"state\"]],[24,[\"company\",\"country\",\"states\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"company\",\"state\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",\"ui green button complete submit\"],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[1,[28,\"t\",[\"phrase.done\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/welcome/location/template.hbs"
    }
  });

  _exports.default = _default;
});