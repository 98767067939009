define("manage/pods/companies/company/projects/project/orders/view/components/add-transaction-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WfrU3MhM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Add Credit\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui basic segment form content\"],[8],[0,\"\\n  Adding a credit to a customers order will give them extra money to use within the site. This can be used if they have given money outside the platform or to give some sort of discount.\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small orange header\"],[8],[0,\"\\n    Enter the amount of the previous transaction, discount or credit below\\n  \"],[9],[0,\"\\n\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[24,[\"transaction\"]],\"amount\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"step\"],[\"number\",[24,[\"transaction\",\"amount\"]],\"Enter the amount in whole dollars\",\"0.01\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"Add\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/add-transaction-modal/template.hbs"
    }
  });

  _exports.default = _default;
});