define("manage/pods/companies/company/projects/project/orders/view/components/add-order-line-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "ember-cp-validations", "manage/config/environment"], function (_exports, _ssModal, _emberConcurrency, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    item_type: {
      description: "Item Type",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.isNew', function () {
          if (this.get('model.isNew') === true) {
            return false;
          }

          return true; // disabled by default, unless its new
        })
      })]
    },
    product_price_custom: {
      description: "Custom Product Price",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        gte: 0,
        disabled: Ember.computed.not('model.use_price_info')
      })]
    },
    shipping_price_custom: {
      description: "Custom Shipping Price",
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        gte: 0,
        disabled: Ember.computed.not('model.use_price_info')
      })]
    }
  });

  const parseNumber = function (property, isFloat) {
    const parse = function (value) {
      if (isFloat) {
        return parseFloat(value);
      }

      return parseInt(value, 10);
    };

    return Ember.computed(property, {
      get() {
        let number = parse(this.get(property));

        if (isNaN(number)) {
          return null;
        }

        return number;
      },

      set(key, value) {
        let number = parse(value);

        if (isNaN(number)) {
          this.set(property, null);
          return null;
        }

        this.set(property, number);
        return number;
      }

    });
  };

  var _default = Ember.Component.extend(_ssModal.default, validations, {
    classNames: ['add-order-line-modal', 'component'],
    store: Ember.inject.service(),
    orderTasks: Ember.inject.service(),
    item_types: [Ember.Object.create({
      product_type: "custom_bundles",
      title: "Custom Bundle"
    }), Ember.Object.create({
      product_type: "extra_bundles",
      title: "Reward Bundle available as an Extra"
    }) // Add sources in later
    ],
    line_types: [Ember.Object.create({
      id: "extra",
      title: "Extra"
    }), Ember.Object.create({
      id: "manual",
      title: "Reward"
    }), Ember.Object.create({
      id: "external",
      title: "External Purchase"
    })],
    // Internal variables
    isNew: false,
    item_type: null,
    showing_price_info: false,
    product_price_selection: 'default',
    shipping_price_selection: 'default',
    additional_setting: 'live',
    closable: false,

    ///////////////
    // Init
    ///////////////
    init() {
      this._super(...arguments); // Need to think about init with order_line


      if (Ember.isPresent(this.get('order_line'))) {
        this.get('initTaskEdit').perform(this.get('order_line'));
      } else {
        this.get('initTaskNew').perform(this.get('order'));
      }
    },

    // Come back to later if we add sources in
    // initTaskBase: task(function * (order) {
    //   // Setup item types
    //   let item_types = this.get('item_types').slice();
    //   let project = yield order.get('project');
    //   let sources = yield project.get('sources');
    //   for (let source of sources.toArray()) {
    //     item_types.push(EmberObject.create({
    //       id: source.get('id'),
    //       source: source,
    //       type: "source",
    //       is_bundle: true
    //     }));
    //   }
    //   this.set('item_types', item_types);
    // }),
    initTaskNew: (0, _emberConcurrency.task)(function* (order) {
      this.set('isNew', true);
      order = yield order; // yield this.get('initTaskBase').perform(order);
      // Setup order

      let order_line = yield this.get('orderTasks.generateOrderLine').perform(order, 'extra');
      this.set('order_line', order_line); // Don't generate order selection
    }),
    initTaskEdit: (0, _emberConcurrency.task)(function* (order_line) {
      this.set('isNew', false);
      order_line = yield order_line;
      let order = yield order_line.get('order'); // yield this.get('initTaskBase').perform(order);

      let product = yield order_line.get('product_bundle');
      yield this.get('store').findRecord('product', product.get('id'), {
        include: ['zones', 'zones.locations', 'mappings', 'mappings.child'].join(',')
      });
      this.set('order', order); // Check and initialize price data points

      if (this.get('order_line.price_data.is_fixed')) {
        if (this.get('order_line.price_data.settings.product') === 'fixed') {
          this.set('product_price_selection', 'custom');

          if (this.get('order_line.prices.product.amount') > 0) {
            this.set('_product_price_custom', this.get('order_line.prices.product.amount'));
          }
        }

        if (this.get('order_line.price_data.settings.shipping') === 'fixed') {
          this.set('shipping_price_selection', 'custom');

          if (this.get('order_line.prices.shipping.amount') > 0) {
            this.set('_shipping_price_custom', this.get('order_line.prices.shipping.amount'));
          }
        }

        if (this.get('order_line.price_data.settings.additional') === 'included') {
          this.set('additional_setting', 'included');
        }
      }
    }),
    ///////////////
    // End Init
    ///////////////
    rollbackTask: (0, _emberConcurrency.task)(function* () {
      let order_line = yield this.get('order_line');

      try {
        // New
        if (order_line.get('isNew')) {
          yield this.get('orderTasks.removeOrderLine').perform(order_line);
        } else {
          order_line.rollbackRelationships();
          order_line.rollbackAttributes();
        }

        this.closeModal();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to revert changes on close."
        });
      }
    }).drop(),
    loadItemTypeTask: (0, _emberConcurrency.task)(function* (item_type) {
      try {
        // This is the full ember object
        this.set('item_type', item_type);
        let order_line = yield this.get('order_line'); // clear product

        order_line.set('product_bundle', null);
        let order = yield this.get('order');
        return yield this.get('store').query('product', {
          filter: {
            project: order.get('project.id'),
            product_type: item_type.get('product_type')
          },
          page: {
            number: 1,
            size: _environment.default['max-page-size']
          },
          sort: 'name'
        });
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to load selected item for type."
        });
        return [];
      }
    }).drop(),
    setProductTask: (0, _emberConcurrency.task)(function* (product) {
      try {
        product = yield product; // Load full product details

        product = yield this.get('store').findRecord('product', product.get('id'), {
          include: ['zones', 'zones.locations', 'mappings', 'mappings.child'].join(',')
        });
        let order_line = yield this.get('order_line');
        order_line.set('product_bundle', product);
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to update product selection."
        });
      }
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('submitted', true);
        let {
          validations: modal_validations
        } = yield this.validate();

        if (!modal_validations.get('isValid')) {
          return;
        }

        let order = yield this.get('order');
        let order_line = yield this.get('order_line'); // Order line

        let {
          validations: line_validations
        } = yield order_line.validate({
          excludes: ['selections']
        });

        if (!line_validations.get('isValid')) {
          return;
        }

        yield this.get('orderTasks.save').perform(order, {
          validate: false,
          save_shipping_address: false
        });

        if (this.get('use_price_info')) {
          // Calculate price data
          let product_amount = null;
          let shipping_amount = null;
          let additional_setting = this.get('additional_setting');

          if (this.get('product_price_selection') === 'custom') {
            if (Ember.isBlank(this.get('product_price_custom'))) {
              product_amount = 0;
            } else {
              product_amount = this.get('product_price_custom');
            }
          }

          if (this.get('shipping_price_selection') === 'custom') {
            if (Ember.isBlank(this.get('shipping_price_custom'))) {
              shipping_amount = 0;
            } else {
              shipping_amount = this.get('shipping_price_custom');
            }
          }

          yield order_line.priceData('fixed', product_amount, shipping_amount, additional_setting);
        }

        yield this.get('store').findRecord('order', order.get('id'), {
          include: ['lines', 'selections'].join(',')
        });
        this.closeModal();
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to add Order Selection."
        });
      }
    }).drop(),
    isRunning: Ember.computed.or('initTaskNew.isRunning', 'initTaskEdit.isRunning', 'rollbackTask.isRunning', 'setProductTask.isRunning', 'saveTask.isRunning'),
    form_invalid: Ember.computed('submitted', 'validations.isInvalid', 'order_line.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      if (this.get('validations.isInvalid')) {
        return true;
      }

      return this.get('order_line.validations.isInvalid');
    }),
    show_selection_error_message: Ember.computed('submitted', 'order.is_completed', 'order_line.validations.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      } // TODO: Update backend to allow creating as a creator with partial information
      // if (!this.get('order.is_completed')) {
      //   return false;
      // }


      return this.get('order_line.validations.isInvalid');
    }),
    _product_price_custom: null,
    product_price_custom: parseNumber('_product_price_custom', true),
    _shipping_price_custom: null,
    shipping_price_custom: parseNumber('_shipping_price_custom', true),
    show_price_toggle: Ember.computed('isNew', 'order_line.price_data.is_fixed', function () {
      if (this.get('isNew')) {
        return false;
      }

      if (this.get('order_line.price_data.is_fixed')) {
        return false;
      }

      return true;
    }),
    use_price_info: Ember.computed('isNew', 'order_line.price_data.is_fixed', 'showing_price_info', function () {
      if (this.get('isNew')) {
        return true;
      }

      if (this.get('order_line.price_data.is_fixed')) {
        return true;
      }

      return this.get('showing_price_info');
    })
  });

  _exports.default = _default;
});