define("manage/pods/companies/company/projects/project/orders/tags/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    tags: Ember.computed.readOnly('model.tags'),
    router: Ember.inject.service(),
    queryParams: ['page', 'page_size', 'sort', 'sortDirection'],
    // Paging
    page: 1,
    page_size: 15,
    // Sorting
    sort: 'name',
    sortDirection: 'asc',
    deleteTagTask: (0, _emberConcurrency.task)(function* (tag) {
      tag = yield tag;

      try {
        yield tag.destroyRecord();
      } catch (e) {
        tag.rollbackAttributes();
        throw e;
      }

      this.transitionToRoute('companies.company.projects.project.orders.tags');
    }),
    actions: {
      sort(name, direction) {
        if (this.get('sort') !== name) {
          this.set('sort', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sort', name);
          this.set('sortDirection', direction);
        }
      },

      delete(tag) {
        if (this.get('deleteTagTask.isRunning')) {
          return;
        }

        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-tag',
          i18nParams: {
            color: tag.get('color'),
            name: tag.get('name'),
            total_orders: tag.get('stats.total_orders')
          },
          approve: () => {
            return this.get('deleteTagTask').perform(tag);
          }
        });
      },

      tagCreated() {
        // Refresh the route
        let currentRoute = Ember.getOwner(this).lookup('route:' + this.get('router.currentRouteName'));

        if (currentRoute) {
          currentRoute.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});