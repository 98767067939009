define("manage/pods/companies/company/projects/project/sources/pre-order/route", ["exports", "manage/mixins/restrict-by-role"], function (_exports, _restrictByRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend((0, _restrictByRole.default)('admin'), {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      let include = ['sources', 'sources.product-extra-global'].join(',');
      return Ember.RSVP.hash({
        project: this.store.findRecord('project', project.get('id'), {
          include: include
        }),
        crowdox_app: this.store.query('project-app', {
          filter: {
            project: project.get('id'),
            installed: false,
            type_slug: 'sales-sources',
            app_slug: 'source-crowdox'
          }
        }).then(results => {
          return results.objectAt(0);
        })
      });
    },

    redirect(model) {
      if (Ember.isPresent(model.project.get('crowdox_source.id'))) {
        return this.transitionTo('companies.company.projects.project.sources.configurations', model.project.get('crowdox_source.id'));
      }
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('selected_source', controller.get('clonable_sources.firstObject'));
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('has_started', false);
        controller.set('selected_source', null);
        controller.set('extra_selection', 'global');
      }
    }

  });

  _exports.default = _default;
});