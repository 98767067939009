define("manage/models/order-address", ["exports", "ember-cp-validations", "manage/utils/generated-model", "shared/helpers/can-access-feature"], function (_exports, _emberCpValidations, _generatedModel, _canAccessFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.country_only', 'model.project.address_name_is_split', function () {
          return this.get('model.country_only') || this.get('model.project.address_name_is_split') || false;
        })
      })]
    },
    company_name: {
      description: "Company Name",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    first_name: {
      description: "First Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'One of the name fields must be present',
        disabled: Ember.computed('model.country_only', 'model.project.address_name_is_single', 'model.middle_name', 'model.last_name', function () {
          return this.get('model.country_only') || this.get('model.project.address_name_is_single') || Ember.isPresent(this.get('model.middle_name')) || Ember.isPresent(this.get('model.last_name')) || false;
        })
      })]
    },
    middle_name: {
      description: "Middle Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'One of the name fields must be present',
        disabled: Ember.computed('model.country_only', 'model.project.address_name_is_single', 'model.first_name', 'model.last_name', function () {
          return this.get('model.country_only') || this.get('model.project.address_name_is_single') || Ember.isPresent(this.get('model.first_name')) || Ember.isPresent(this.get('model.last_name')) || false;
        })
      })]
    },
    last_name: {
      description: "Last Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'One of the name fields must be present',
        disabled: Ember.computed('model.country_only', 'model.project.address_name_is_single', 'model.first_name', 'model.middle_name', function () {
          return this.get('model.country_only') || this.get('model.project.address_name_is_single') || Ember.isPresent(this.get('model.first_name')) || Ember.isPresent(this.get('model.middle_name')) || false;
        })
      })]
    },
    address_1: {
      description: "Address 1",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.country.street_required', 'model.country_only', function () {
          if (this.get('model.country.street_required') === false) {
            return true;
          }

          return this.get('model.country_only') || false;
        })
      })]
    },
    address_2: {
      description: "Address 2",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    city: {
      description: "City",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.country.city_required', 'model.country_only', function () {
          if (this.get('model.country.city_required') === false) {
            return true;
          }

          return this.get('model.country_only') || false;
        })
      })]
    },
    state_name: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.override_address_validation', 'model.country.state_required', 'model.country_only', function () {
          if (this.get('model.override_address_validation')) {
            return true;
          }

          if (this.get('model.country.state_required') === false) {
            return true;
          }

          return this.get('model.country_only') || false;
        })
      })]
    },
    postal_code: {
      description: "Postal Code",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        message: Ember.computed('model.is_usa', 'model.country.name', function () {
          let description = "Postal Code";

          if (this.get('model.is_usa')) {
            description = "Zip Code";
          }

          return `${description} is required for ${this.get('model.country.name')}`;
        }),
        presence: true,
        disabled: Ember.computed('model.override_address_validation', 'model.country.postal_code_required', 'model.country_only', function () {
          if (this.get('model.override_address_validation')) {
            return true;
          }

          if (this.get('model.country.postal_code_required') === false) {
            return true;
          }

          return this.get('model.country_only') || false;
        })
      }), (0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        // regex: Ember.computed.readOnly('model.country.postal_code_format'),
        regex: Ember.computed('model.country.postal_code_format', function () {
          return new RegExp(this.get('model.country.postal_code_format'), 'i');
        }),
        message: Ember.computed('model.is_usa', 'model.country.name', function () {
          let description = "Postal Code";

          if (this.get('model.is_usa')) {
            description = "Zip Code";
          }

          return `${description} is not valid for ${this.get('model.country.name')}`;
        }),
        disabled: Ember.computed('model.override_address_validation', 'model.country.postal_code_required', 'model.country_only', 'model.country.postal_code_format', function () {
          if (this.get('model.override_address_validation')) {
            return true;
          }

          if (this.get('model.country.postal_code_required') === false) {
            return true;
          }

          if (this.get('model.country_only')) {
            return true;
          }

          if (Ember.isBlank(this.get('model.country.postal_code_format'))) {
            return true;
          }

          return false;
        })
      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    phone_number: {
      description: "Phone Number",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: Ember.computed('model.is_international', 'model.project', function () {
          if ((0, _canAccessFeature.canAccessFeature)([this.get('model.project'), 'phone-number-required-always'])) {
            return true;
          }

          return this.get('model.is_international') || false;
        }),
        disabled: Ember.computed('model.{override_address_validation,override_phone_validation,country_only,is_international,project}', function () {
          if (this.get('model.override_address_validation')) {
            return true;
          }

          if (this.get('model.override_phone_validation')) {
            return true;
          }

          if (this.get('model.country_only')) {
            return true;
          }

          if (this.get('model.is_international')) {
            return false;
          }

          if ((0, _canAccessFeature.canAccessFeature)([this.get('model.project'), 'phone-number-required-always'])) {
            return false;
          }

          return true;
        })
      })]
    }
  });

  var _default = (0, _generatedModel.default)('order-address').extend(Validations, {
    ajax: Ember.inject.service(),
    state_name: Ember.computed('state.abbr', 'state_text', function () {
      if (Ember.isPresent(this.get('state.abbr'))) {
        return this.get('state.abbr');
      }

      return this.get('state_text');
    }),
    is_usa: Ember.computed.equal('country.iso3', 'USA'),
    is_canada: Ember.computed.equal('country.iso3', 'CAN'),
    is_international: Ember.computed('country.iso3', 'project.country.iso3', function () {
      if ((this.get('country.iso3') || "").trim().toLowerCase() === (this.get('project.country.iso3') || "").trim().toLowerCase()) {
        // If country is blank or USA, assume domestic
        return false;
      } else {
        // Everything else must be international
        return true;
      }
    }),

    validate_external() {
      return this.get('ajax').postModel(this, 'validate');
    },

    normalized: Ember.computed('address_1', 'address_2', 'city', 'state_name', 'postal_code', 'country.iso3', function () {
      // Since things like apartments sometimes get moved to line 2, I need an identifier between lines
      // so the @2@ marks the place holder between lines. Tried to pick something that wouldn't be in an address.
      let address_array = [this.get('address_1'), "@2@", this.get('address_2'), this.get('city'), this.get('state_name'), this.get('postal_code'), this.get('country.iso3')].join(" ").split(" ");
      address_array = address_array.filter(function (part) {
        return part !== '';
      }).map(function (part) {
        return (part || "").toLowerCase();
      });
      return address_array.join(" ");
    })
  });

  _exports.default = _default;
});