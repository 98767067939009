define("manage/pods/companies/company/projects/project/products/components/edit-product-trait/component", ["exports", "manage/mixins/image-upload"], function (_exports, _imageUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend((0, _imageUpload.default)('product.image_path'), {
    // making more unique to avoid common work issues
    classNames: ['product-edit-product-trait', 'component'],
    current_user: Ember.inject.service('user'),
    auto_generate_sku: false,

    init() {
      this._super(...arguments);

      if (this.get('product.isNew') && Ember.isBlank(this.get('product.preliminary_variation.sku'))) {
        this.set('auto_generate_sku', true);
      }
    },

    // static variables
    product_types: [Ember.Object.create({
      name: 'Physical',
      value: 'physical'
    }), Ember.Object.create({
      name: 'Digital',
      value: 'digital'
    }), Ember.Object.create({
      name: 'Shipping',
      value: 'shipping'
    })],
    show_product_type: Ember.computed('product.{product_type,is_physical,is_digital}', function () {
      if (this.get('product.is_physical') || this.get('product.is_digital')) {
        return true;
      }

      if (Ember.isBlank(this.get('product.product_type'))) {
        return true;
      }

      return false;
    }),
    name_changed: Ember.observer('product.name', function () {
      if (this.get('auto_generate_sku') && this.get('product.preliminary_variation') != null) {
        this.set('product.preliminary_variation.sku', this.generatedSKU());
      }
    }),

    generatedSKU() {
      const product_name = this.get('product.name') || '';
      return product_name.replace(/'/g, '') // remove apostrophes (MEN'S -> MENS)
      .replace(/\W+/g, ' ') // turn all non-alphanumeric chars into spaces
      .trim() // trim start and end
      .toUpperCase() // uppercase all the things
      .replace(/\s+/g, '-'); // turn all whitespace into dashes
    },

    actions: {
      set_type(type_object) {
        let type = type_object.get('value');
        this.set('product.product_type', type); // We shouldn't need these, but admins can change types, so here it is

        if (this.get('product.is_shipping')) {
          this.set('product.price', null);
        }

        if (this.get('product.is_digital') || this.get('product.is_shipping')) {
          this.set('product.weight', null);
        } // Clear dimensions

      },

      upload_success_gallery(data) {
        this.get('product.gallery_image_paths').addObject(data.image_path);
      },

      clear_gallery_image(gallery_image_src) {
        let index = this.get('product.gallery_image_srcs').findIndex(gic => gic === gallery_image_src);
        this.get('product.gallery_image_paths').removeAt(index, 1);
      },

      reset_sku() {
        if (this.get('auto_generate_sku') === true) {
          this.set('product.preliminary_variation.sku', this.generatedSKU());
        }
      }

    }
  });

  _exports.default = _default;
});