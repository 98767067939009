define("manage/pods/companies/company/projects/project/orders/view/components/order-tracking-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // This is an array of tracking_parameter_value_order
    items: null,
    order: null
  });

  _exports.default = _default;
});