define("manage/helpers/date-age-in-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateAgeInDays = dateAgeInDays;
  _exports.default = void 0;

  // Counts the number of 24-hour periods between the date/time provided and the current date/time.
  // Always rounds down.
  function dateAgeInDays(_ref) {
    let [date] = _ref;

    if (date == null) {
      return 0;
    }

    return Math.floor((Date.now().valueOf() - date.valueOf()) / 86400000); // 86400000 = ms in 24 hours
  }

  var _default = Ember.Helper.helper(dateAgeInDays);

  _exports.default = _default;
});