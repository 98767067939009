define("manage/pods/admin/holidays/components/holiday-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    saving: false,

    saved() {},

    init() {
      this._super(...arguments);

      if (!this.get('holiday')) {
        this.set('holiday', this.get('store').createRecord('holiday', {
          notice_days: 3,
          message_before: `The Crowd Ox Success Team will be providing only limited support from {{starts_at}} through {{ends_at}}. If you believe you'll need to perform tasks in Crowd Ox that require our attention or assistance, please coordinate with us about it as soon as possible.`,
          message_during: `The Crowd Ox Success Team currently has limited support capability until {{ends_at}}. If you need help during this time, please be aware there may be a delay in communication and assistance. Our online resources and help can be found <a href="https://help.crowdox.com/" target="_blank" rel="noopener noreferrer">here</a>.`
        }));
      }
    },

    closing() {
      var record = this.get('holiday');

      if (record.get('isNew')) {
        record.deleteRecord();
      } else {
        record.rollbackAttributes();
      }
    },

    actions: {
      approve() {
        this.set('submitted', true);

        if (this.get('holiday.validations.isInvalid')) {
          return;
        }

        this.set('saving', true);
        this.get('holiday').save().then(() => {
          this.set('submitted', false);
          this.get('saved')(this.get('holiday'));
          this.closeModal();
        }).finally(() => {
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});