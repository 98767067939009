define("manage/mixins/models/product", ["exports", "ember-cp-validations", "manage/utils/fallback-sort", "ember-data-model-fragments/attributes"], function (_exports, _emberCpValidations, _fallbackSort, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const c = function (options, required_types) {
    required_types = Ember.A(required_types);
    options.disabled = Ember.computed('model.product_type', function () {
      return !required_types.includes(this.get('model.product_type'));
    });
    return options;
  };

  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    questions: {
      description: "Questions",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    },
    zones: {
      description: "Zones",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    },
    zone_locations: {
      description: "Zone Locations",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    },
    mappings: {
      description: "Mappings",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'A minimum of 1 product must be mapped up',
        disabled: Ember.computed('model.{is_option,is_bundle,configuration.id}', function () {
          // Options must always have mappings
          if (this.get('model.is_option')) {
            return false;
          }

          if (this.get('model.is_bundle')) {
            // If the bundle is mapped to a configuration, we ignore the requirement for now
            if (Ember.isPresent(this.get('model.configuration.id')) || Ember.isPresent(this.get('model.configuration.content'))) {
              return true;
            }

            return false;
          } // All other products ignore requirement


          return true;
        })
      }), (0, _emberCpValidations.validator)('inline', {
        dependentKeys: ['model.is_option', 'model.mappings.@each.{isDeleted,is_default,enabled}'],

        validate(value, options, model
        /*, attribute */
        ) {
          // Only effects option products
          if (!model.get('is_option')) {
            return true;
          }

          const mappings = model.get('mappings').filterBy('isDeleted', false); // No mappings, no checks

          if (mappings.get('length') === 0) {
            return true;
          } // If we have more than one default, return an error (should't happen)


          if (mappings.filterBy('is_default', true).get('length') > 1) {
            return "Only one product can be chosen as the default";
          } // We need at least one product selected


          if (mappings.filterBy('is_default', true).get('length') === 0) {
            return "Select a product to be the default";
          } // If anything is enabled and we have a disabled product as default, its an error


          if (mappings.isAny('enabled', true) && mappings.filterBy('enabled', false).filterBy('is_default', true).get('length') > 0) {
            return "Select an enabled product to be the default";
          } // We should only have 1 selected product as the default at this point and its enabled or they are all disabled


          return true;
        }

      })]
    },
    variants: {
      description: "Variants",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    },
    variations: {
      description: "Variations",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('inline', {
        dependentKeys: ['model.variations.@each.{isDeleted,is_default,enabled}'],

        validate(value, options, model
        /*, attribute */
        ) {
          const variations = model.get('variations').filterBy('isDeleted', false); // No variations, no checks

          if (variations.get('length') === 0) {
            return true;
          } // If we have more than one default, return an error (should't happen)


          if (variations.filterBy('is_default', true).get('length') > 1) {
            return "Only one variation can be chosen as the default";
          } // We need at least one variation selected


          if (variations.filterBy('is_default', true).get('length') === 0) {
            return "Select a variation to be the default";
          } // If anything is enabled and we have a disabled variation as default, its an error


          if (variations.isAny('enabled', true) && variations.filterBy('enabled', false).filterBy('is_default', true).get('length') > 0) {
            return "Select an enabled variation to be the default";
          } // We should only have 1 selected variation as the default at this point and its enabled or they are all disabled


          return true;
        }

      })]
    },
    digital_instructions: {
      description: "Digital Instructions",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 2000
      })]
    },
    product_type: {
      description: "Product Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['physical', 'digital', 'bundle', 'option', 'question', 'mapping', 'section', 'shipping']
      })]
    },
    sub_type: {
      description: "Sub Type",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    shipping_type: {
      description: "Shipping Type",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    wave_shipping: {
      description: "Wave Shipping",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    // trait properties
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 150
      }), (0, _emberCpValidations.validator)('presence', c({
        presence: true
      }, ['bundle', 'digital', 'option', 'physical', 'section', 'shipping'])), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['mapping', 'question']))]
    },
    description: {
      description: "Description",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 3500
      }), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['mapping', 'question']))]
    },
    price: {
      description: "Price",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: true,
        gte: 0
      }), (0, _emberCpValidations.validator)('presence', c({
        presence: true
      }, ['bundle', 'digital', 'physical'])), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['mapping', 'option', 'section', 'shipping']))]
    },
    vat_price_cents: {
      description: "VAT Prices",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    order_max_quantity: {
      description: "Order Max Quantity",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gte: 1
      }), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['mapping', 'option', 'question', 'section']))]
    },
    inventory_total_quantity: {
      description: "Inventory Total Quantity",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gte: 1
      }), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['mapping', 'option', 'question', 'section', 'shipping']))]
    },
    image_path: {
      description: "Image",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['mapping', 'question', 'section']))]
    },
    weight: {
      description: "Weight",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: true,
        gte: 0
      }), (0, _emberCpValidations.validator)('presence', c({
        presence: false
      }, ['bundle', 'digital', 'mapping', 'option', 'question', 'section', 'shipping']))]
    },
    dimensions: {
      description: "Dimensions",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    ajax: Ember.inject.service(),
    hint_id: Ember.computed('hint', 'id', function () {
      return [this.get('hint'), this.get('id')].compact().join(' - ');
    }),
    is_physical_or_digital: Ember.computed.or('is_physical', 'is_digital', 'is_shipping'),
    variations_sorted: (0, _fallbackSort.default)('variations', 'variant_names', 'id'),
    // @each two level helper
    questions_not_deleted: Ember.computed.filterBy('questions', 'isDeleted', false),
    question: Ember.computed.readOnly('questions_not_deleted.firstObject'),
    question_type: Ember.computed.readOnly('question.question_type'),
    products: Ember.computed.mapBy('mappings', 'parent'),
    mapping_product_names: Ember.computed('products.[]', 'mappings.@each.child', function () {
      var products = this.get('mappings').map(function (item) {
        return item.get('child.name');
      });

      if (products.length > 1) {
        return `${products.slice(0, -1).join(', ')} and ${products.slice(-1)}`;
      } else {
        return products[0];
      }
    }),
    // Helper method in several editing pages
    price_is_zero_and_changed: Ember.computed('isDirty', 'price_cents', function () {
      if (this.get('price_cents') === 0) {
        const changedAttributes = this.changedAttributes();
        return !!changedAttributes.price_cents;
      }

      return false;
    }),
    // Trait
    gallery_image_paths: (0, _attributes.array)('string'),
    dimensions: (0, _attributes.fragment)('product-dimensions', {
      defaultValue: {}
    }),

    update_unknown(action, product_type, other_product) {
      let other_product_id = null;

      if (Ember.isPresent(other_product)) {
        other_product_id = other_product.get('id');
      }

      return this.get('ajax').postModel(this, 'update_unknown', {
        data: {
          'product_action': action,
          'product_type': product_type,
          'other_product_id': other_product_id
        }
      });
    }

  });

  _exports.default = _default;
});