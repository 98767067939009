define("manage/pods/companies/company/projects/project/apps/components/install-app-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "ember-cp-validations", "manage/helpers/humanize"], function (_exports, _ssModal, _emberConcurrency, _emberCpValidations, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    classNames: ['install-app-modal', 'component'],
    closable: false,
    submitted: false,
    model: null,
    redirecting: false,

    init() {
      this._super(...arguments);

      this.set('submitted', false); // Custom Validations

      let data = this.get('app.settings') || {};
      let validations = {};
      this.get('app.app.options').forEach(option => {
        let validation = {
          description: (0, _humanize.humanize)([option.id]),
          validators: []
        };

        if (option.optional === undefined || option.optional == false) {
          validation.validators.push((0, _emberCpValidations.validator)('presence', true));
        }

        if ('default' in option && data[option.id] === undefined) {
          data[option.id] = option.default;
        }

        validations[option.id] = validation;
      });
      this.set('model', Ember.Object.extend((0, _emberCpValidations.buildValidations)(validations)).create(Ember.getOwner(this).ownerInjection(), data));
    },

    isRunning: Ember.computed.or('installTask.isRunning', 'redirecting'),
    installTask: (0, _emberConcurrency.task)(function* () {
      let app = yield this.get('app');
      let project = yield this.get('project');
      let model = yield this.get('model');
      let {
        validations: validations
      } = yield model.validate();

      if (!validations.get('isValid')) {
        return; // Not valid return so the ui updates
      }

      let data = model.getProperties(this.get('app.app.options').map(option => option.id));

      try {
        yield app.install(project, data);
      } catch (xhr) {
        if (xhr.payload && xhr.payload.errors) {
          this.set('error', xhr.payload.errors.map(error => error.detail || error.title).join(', '));
        } else {
          this.set('error', xhr.message || 'The install failed');
        }
      }
    }),
    actions: {
      install() {
        this.set('submitted', true);
        this.set('error', null);
        this.get('installTask').perform().then(() => {
          if (this.get('error') === null && this.get('model.validations.isValid')) {
            if (this.get('installed')) {
              this.get('installed')(this.get('app'));
            }

            if (Ember.isPresent(this.get('app.settings.redirect'))) {
              this.set('redirecting', true);
              window.location.href = this.get('app.settings.redirect');
            } else {
              this.closeModal();

              if (this.onCompletion) {
                this.onCompletion();
              }
            }
          }
        });
      },

      setSelectOption(option, selectOption) {
        this.send("setOption", option, selectOption.value);
      },

      setOption(option, value) {
        if ('updatable' in option && !option['updatable'] && this.get('app.installed')) {
          this.set('error', `You cannot change ${option.id} after it's been installed`);
          return;
        }

        this.set(`model.${option.id}`, value);
      },

      // Date is returned as an array and we only want the first value
      setDateOption(option, value) {
        if ('updatable' in option && !option['updatable'] && this.get('app.installed')) {
          this.set('error', `You cannot change ${option.id} after it's been installed`);
          return;
        }

        this.set(`model.${option.id}`, (value || [])[0]);
      }

    }
  });

  _exports.default = _default;
});