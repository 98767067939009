define("manage/models/price-data-price", ["exports", "ember-data-model-fragments/fragment", "shared/utils/monetize", "@ember-data/model"], function (_exports, _fragment, _monetize, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    type: (0, _model.attr)('string'),
    amount_cents: (0, _model.attr)('number'),
    amount_per_quantity_cents: (0, _model.attr)('number'),
    reference: (0, _model.attr)('string'),
    currency: null,
    // Initialized in price mixin
    amount: (0, _monetize.default)('amount_cents', 'currency'),
    amount_per_quantity: (0, _monetize.default)('amount_per_quantity_cents', 'currency')
  });

  _exports.default = _default;
});