define("manage/pods/companies/company/projects/project/sources/caps/route", ["exports", "manage/mixins/handle-transition"], function (_exports, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    productTasks: Ember.inject.service(),

    model() {
      let source = this.modelFor('companies.company.projects.project.sources');
      return Ember.RSVP.hash({
        project: this.modelFor('companies.company.projects.project'),
        source: this.store.findRecord('project-source', source.get('id'), {
          include: ['product-extra-global'].join(',')
        })
      }).then(_ref => {
        let {
          project,
          source
        } = _ref;
        return Ember.RSVP.hash({
          project,
          source,
          product_extra_global: this.store.findRecord('product', source.get('product_extra_global.id'), {
            include: ['zones', 'zones.locations'].join(',')
          })
        });
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.product_extra_global) {
          let product_extra_global = record.product_extra_global;
          this.handleTransition(transition, resolve => {
            this.get('productTasks.isDirty').perform(product_extra_global).then(isDirty => {
              if (isDirty) {
                this.send('open_modal', 'rollback-modal', {
                  model: product_extra_global,
                  modelName: "Cap",
                  serviceTaskAction: this.get('productTasks.rollback'),
                  promiseResolve: resolve
                });
              } else {
                // We don't have a dirty model, so mark as handled and retry
                resolve('retry');
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});