define("manage/components/ss-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is just a place hold so we can build other components
  var _default = Ember.Component.extend({
    classNames: ['bar'],
    attributeBindings: ['style']
  });

  _exports.default = _default;
});