define("manage/pods/not-found/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    renderTemplate() {
      if (this.get('session.isAuthenticated')) {
        this.render('shared/main', {
          into: 'application'
        });
        this.render('shared/not-found', {
          into: 'shared/main'
        });
        this.render('not-found', {
          into: 'shared/not-found'
        });
      } else {
        this.render('shared/not-found', {
          into: 'application'
        });
        this.render('not-found', {
          into: 'shared/not-found'
        });
      }
    }

  });

  _exports.default = _default;
});