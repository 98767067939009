define("manage/pods/companies/company/account/billing-info/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),

    model() {
      let company = this.modelFor('companies.company');
      return Ember.RSVP.hash({
        company: this.store.findRecord('company', company.get('id')),
        invoices: this.store.query('company-invoice', {
          filter: {
            company: company.get('id')
          },
          sort: '-invoice-date',
          page: {
            number: 1,
            size: 5
          }
        }),
        payment: this.store.createRecord('payment'),
        countries: this.store.peekAll('country')
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('state', null);
        controller.set('error_message', null);
        controller.set('editing', false);
      }
    }

  });

  _exports.default = _default;
});