define("manage/models/digital-download", ["exports", "manage/utils/generated-model", "ember-cp-validations"], function (_exports, _generatedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AllowedExtensions = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    attachment: {
      description: "Attachment",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    download_limit: {
      description: "Download Limit",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gte: 1
      })]
    }
  });
  const AllowedExtensions = ['epub', 'gif', 'jpeg', 'jpg', 'mobi', 'mov', 'mp3', 'mp4', 'mpg', 'mpeg', 'pdf', 'png', 'zip'];
  _exports.AllowedExtensions = AllowedExtensions;

  var _default = (0, _generatedModel.default)('digital-download').reopen(Validations, {
    ajax: Ember.inject.service(),
    download_url: Ember.computed('id', function () {
      return this.get('ajax').buildModelUrl(this, 'download');
    }),
    upload_url: Ember.computed('id', function () {
      return this.get('ajax').buildModelUrl(this, 'upload');
    })
  });

  _exports.default = _default;
});