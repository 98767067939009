define("manage/pods/admin/projects/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wUHRe7Wr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"admin.projects\"]],{\"statements\":[[0,\"\\n  Projects\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\"],[\"admin.projects.status\"]],{\"statements\":[[0,\"\\n    Fulfillment Status\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/admin/projects/menu/template.hbs"
    }
  });

  _exports.default = _default;
});