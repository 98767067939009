define("manage/pods/companies/company/projects/project/components/edit-location-zone-vat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    zone: null,
    // set in template
    project: Ember.computed.readOnly('zone.project'),
    tagName: "",

    init() {
      this._super(...arguments);

      this.set('shipping_category', Ember.Object.create({
        name: "shipping",
        public_id: "shipping"
      }));
      return Ember.run.scheduleOnce('afterRender', this, this.initValue);
    },

    initValue() {
      this.get('zone').initValue();
    }

  });

  _exports.default = _default;
});