define("manage/pods/companies/company/projects/project/orders/view/components/order-selection-extension/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['floated', 'right', 'order-selection-extension', 'component'],
    project: Ember.computed.readOnly('order_selection.project'),
    order_selection: null,
    can_edit: Ember.computed('order_selection.{is_locked,order.is_locked,in_bundle,has_choices}', function () {
      if (this.get('order_selection.is_locked') || this.get('order_selection.order.is_locked')) {
        return false;
      } // If its in a bundle the only thing we can change is variation or question information


      if (this.get('order_selection.in_bundle')) {
        // If its in a bundle, always return, don't default to below
        return this.get('order_selection.has_choices');
      } // If it doesn't have choices, you can edit quantity on a selection directly or price information


      return true;
    }),
    can_remove: Ember.computed('order_selection.{is_locked,order.is_locked,in_bundle}', function () {
      if (this.get('order_selection.is_locked') || this.get('order_selection.order.is_locked')) {
        return false;
      }

      return !this.get('order_selection.in_bundle');
    }),
    actions: {
      edit() {
        this.send('open_modal', 'companies/company/projects/project/orders/view/components/add-order-selection-modal', {
          order_selection: this.get('order_selection')
        });
      },

      remove() {
        this.send('open_modal', 'companies/company/projects/project/orders/view/components/remove-order-selection-modal', {
          order_selection: this.get('order_selection')
        });
      }

    }
  });

  _exports.default = _default;
});