define("manage/pods/companies/company/projects/project/apps/install/route", ["exports", "manage/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    current_user: Ember.inject.service('user'),
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sort_direction: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model(params) {
      this.set('storedParams', params);
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project,
        apps: this.search(project, params),
        app_types: this.store.query('app-type', {
          page: {
            number: 1,
            size: 25
          }
        })
      });
    },

    search(project, params) {
      // Filters
      let filters = {
        project: project.get('id'),
        type_slug: ['data-collection', 'shipping-and-fulfillment', 'data-exports', 'api-exports'],
        installed: false,
        search: params.search
      };

      if (this.get('current_user.role') == 'creator') {
        filters['role'] = 'creator';
      } // Paging


      let page = params.page;
      let pageSize = params.page_size; // Sorting

      let sortProperty = params.sort;
      let sortDirection = params.sort_direction;
      let sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      return this.store.query('project-app', {
        filter: filters,
        page: {
          number: page,
          size: pageSize
        },
        sort: sort
      });
    },

    actions: {
      refreshInstalledApps() {
        let project = this.modelFor('companies.company.projects.project');
        this.set('installed_apps', this.search(project, this.get('storedParams')));
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});