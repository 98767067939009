define("manage/pods/companies/company/projects/project/segments/components/generate-export-modal/component", ["exports", "manage/mixins/ss-modal", "manage/config/environment", "jquery"], function (_exports, _ssModal, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    // Because the dropdown renders in a "wormhole" it will close the modal
    // on each click to it without this.
    closable: false,
    // Passed in when invoked
    project: null,
    status: null,
    key: null,
    base_url: null,
    filters: null,
    // Defaults
    export: null,
    is_processing: false,
    can_mark_as_exported: Ember.computed.equal('status', 'not_exported'),
    api_url: _environment.default.urls.api,
    format_types: [Ember.Object.create({
      name: 'CSV',
      value: 'csv'
    }), Ember.Object.create({
      name: 'Excel',
      value: 'excel'
    })],
    actions: {
      cancel() {
        this.closeModal();
      },

      generate(mark_as_exported) {
        if (this.get('is_processing')) {
          return;
        }

        this.set('error', null);
        this.set('is_processing', true);

        let url = this.get('base_url') + '?' + _jquery.default.param(Object.assign(this.get('filters') || {}, {
          project_id: this.get('project.id'),
          file_format: this.get('export.export_type'),
          format_type: this.get('export.format_type'),
          mark_exported: mark_as_exported
        }));

        this.get('ajax').post(url).then(result => {
          this.set('jobId', result.data.job_id);
        }).catch(xhr => {
          this.set('error', xhr.responseText || xhr.message);
        }).finally(() => {
          this.set('is_processing', false);
        });
      },

      jobFailed(error) {
        this.set('jobId', null);
        this.set('error', error.message);
      }

    }
  });

  _exports.default = _default;
});