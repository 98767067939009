define("manage/pods/companies/company/projects/project/orders/view/components/resend-email-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HW3G5Jit",
    "block": "{\"symbols\":[\"email_type\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Resend Email\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic segment content \",[28,\"if\",[[24,[\"sendTask\",\"isRunning\"]],\"loading\"],null],\" \",[28,\"if\",[[24,[\"has_error\"]],\"error\"],null]]]],[8],[0,\"\\n\"],[4,\"ui-field\",null,[[\"model\",\"property\",\"submitted\"],[[23,0,[]],\"type\",[24,[\"submitted\"]]]],{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[0,\"Select an Email\"],[9],[0,\"\\n    \"],[5,\"power-select\",[],[[\"@placeholder\",\"@selected\",\"@options\",\"@renderInPlace\",\"@onChange\"],[\"Select an Email\",[22,\"email\"],[22,\"email_types\"],true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"email\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n    An error occurred while sending the email. If this continues please contact support.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button cancel\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui button positive\"],[11,\"onclick\",[28,\"perform\",[[24,[\"sendTask\"]]],null]],[8],[0,\"Send Email\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/resend-email-modal/template.hbs"
    }
  });

  _exports.default = _default;
});