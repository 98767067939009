define("manage/pods/companies/company/projects/project/segments/actions/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        project_segment_action: this.store.findRecord('project-segment-action', params.project_segment_action_id, {
          includes: "segment"
        }),
        errored_orders: this.store.query('project-segment-order', {
          include: "order",
          filter: {
            project: project.get('id'),
            segment: params.project_segment_id,
            state: `${params.project_segment_action_id}.errored`
          }
        })
      });
    }

  });

  _exports.default = _default;
});