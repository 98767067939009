define("manage/mixins/models/product/zone-location", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    zone: {
      description: "Location Zone",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    cost_type: {
      description: "Cost Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['shipping_product_based', 'extra_cap']
      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  });

  var _default = Ember.Mixin.create(Validations);

  _exports.default = _default;
});