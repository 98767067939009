define("manage/pods/companies/company/projects/project/questions/components/edit-product-question-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65rCwve7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"if\",[[28,\"and\",[[24,[\"question\",\"isNew\"]],[28,\"not\",[[24,[\"question\",\"created\"]]],null]],null],\"Add\",\"Edit\"],null],false],[0,\" Question\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"companies/company/projects/project/questions/edit-product-question\",null,[[\"question\",\"submitted\"],[[24,[\"question\"]],[24,[\"submitted\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui positive button\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"\\n    \"],[1,[28,\"if\",[[28,\"and\",[[24,[\"question\",\"isNew\"]],[28,\"not\",[[24,[\"question\",\"created\"]]],null]],null],\"Create\",\"Save\"],null],false],[0,\" Question\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/questions/components/edit-product-question-modal/template.hbs"
    }
  });

  _exports.default = _default;
});