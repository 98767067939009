define("manage/pods/admin/companies/company/edit/controller", ["exports", "ember-concurrency", "manage/utils/catch-real", "manage/config/environment"], function (_exports, _emberConcurrency, _catchReal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('model.company'),
    api_url: _environment.default.urls.api,
    session: Ember.inject.service(),
    isRunning: Ember.computed.alias('saveTask.isRunning'),

    save(company) {
      this.get('saveTask').perform(company).catch((0, _catchReal.default)(error => {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: "Unable to save."
        });
      }));
    },

    saveTask: (0, _emberConcurrency.task)(function* (company) {
      company = yield company;
      let {
        validations: validations
      } = yield company.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      yield company.save();
    }),
    deleteCompanyTask: (0, _emberConcurrency.task)(function* (company) {
      company = yield company;

      try {
        yield company.destroyRecord();
      } catch (e) {
        company.rollbackAttributes();
        throw e;
      }

      this.transitionToRoute('admin.companies');
    }),
    actions: {
      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        let company = this.get('company');
        this.save(company);
      },

      delete() {
        if (this.get('deleteCompanyTask.isRunning')) {
          return;
        }

        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-company',
          i18nParams: {
            company_name: this.company.name
          },
          approve: () => {
            return this.get('deleteCompanyTask').perform(this.company);
          }
        });
      }

    }
  });

  _exports.default = _default;
});