define("manage/templates/shared/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QOzvi5KS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"login wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"crowdox logo\"],[8],[0,\"\\n      \"],[1,[22,\"icon-ox\"],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"crowdox text\"],[8],[0,\"\\n      \"],[7,\"em\",true],[8],[0,\"Crowd\"],[9],[0,\" Ox\\n    \"],[9],[0,\"\\n\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui tiny header centered footer\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"terms field\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"https://crowdox.com/legal/terms\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"Terms of Use\"],[9],[0,\"\\n      - \"],[7,\"a\",true],[10,\"href\",\"https://crowdox.com/legal/cookies\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"Cookies\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/templates/shared/user.hbs"
    }
  });

  _exports.default = _default;
});