define("manage/pods/companies/company/projects/project/sources/story/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.computed.readOnly('model.project'),
    source: Ember.computed.readOnly('model'),
    submitted: false,
    preview_size: 'full',
    is_full: Ember.computed.equal('preview_size', 'full'),
    is_desktop: Ember.computed.equal('preview_size', 'desktop'),
    is_mobile: Ember.computed.equal('preview_size', 'mobile'),
    width_style: Ember.computed('preview_size', function () {
      let styles = [];

      if (this.get('is_desktop')) {
        styles.push('width: 750px');
        styles.push('margin: auto');
      }

      if (this.get('is_mobile')) {
        styles.push('width: 500px');
        styles.push('margin: auto');
      }

      return Ember.String.htmlSafe(styles.join(';'));
    }),
    saveTask: (0, _emberConcurrency.task)(function* (source) {
      source = yield source;
      let result = yield source.get('theme').validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      yield source.save();
    }),
    actions: {
      save() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform(this.get('source'));
      }

    }
  });

  _exports.default = _default;
});