define("manage/pods/companies/company/projects/project/orders/index/route", ["exports", "manage/mixins/order-filters", "manage/pods/companies/company/projects/project/orders/index/columns", "manage/utils/strip-non-printable"], function (_exports, _orderFilters, _columns, _stripNonPrintable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_columns.default, {
    queryParams: Object.assign(Object.keys(_orderFilters.filters).reduce((map, item) => {
      map[item] = {
        refreshModel: true
      };
      return map;
    }, {}), {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      }
    }),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      let segments = this.get('store').query('project-segment', {
        filter: {
          project: project.get('id'),
          hidden: false
        },
        page: {
          size: 100
        }
      });
      return Ember.RSVP.hash({
        project: project,
        orders: this.search(project, params),
        segments: segments,
        currentSegment: segments.then(function (segments) {
          return segments.find(segment => segment.get('id') == params['project_segment']);
        }),
        exportApps: this.store.query('project-app', {
          filter: {
            project: project.get('id'),
            type_slug: ['data-exports'],
            installed: true
          }
        })
      });
    },

    setupController(controller) {
      controller.set('resultsLoadSuccess', true);

      this._super(...arguments);
    },

    search(project, params) {
      let controller = this.controllerFor(this.routeName);
      let page = params.page;
      let pageSize = controller.get('page_size');
      let sortProperty = params.sort;
      let sortDirection = params.sortDirection;
      let sort;

      if (sortDirection === 'desc') {
        sort = `-${sortProperty}`;
      } else {
        sort = sortProperty;
      }

      return this.store.query('order', {
        filter: Object.assign(Object.keys(_orderFilters.filters).filter(item => Ember.isPresent(params[item])).reduce(function (map, item) {
          map[_orderFilters.filters[item].key || item] = (0, _stripNonPrintable.default)(params[item]);
          return map;
        }, {}), {
          project: project.get('id')
        }),
        // Includes and paging
        include: ['customer', 'current-configuration', 'current-configuration.product-reward', 'shipping-address', 'tags'].join(','),
        page: {
          number: page,
          size: pageSize
        },
        sort: sort
      });
    },

    actions: {
      error() {
        // The controller doesn't exist in some error situations
        if (this.controller) {
          this.controller.set('resultsLoadSuccess', false);
          return true;
        } else {
          return false;
        }
      }

    }
  });

  _exports.default = _default;
});