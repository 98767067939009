define("manage/components/error-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BdNjIXfW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Oh no! There was an unexpected error.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n    \"],[1,[22,\"description\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui basic secondary segment\"],[8],[0,\"\\n    If you continue to get this error, please contact us at \"],[7,\"a\",true],[10,\"href\",\"mailto:hello@crowdox.com\"],[8],[0,\"hello@crowdox.com\"],[9],[0,\".\\n\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"current_user\",\"is_admin\"]],[24,[\"error\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"admin\"],[8],[0,\"Error Information (Admin Only)\"],[9],[0,\"\\n\\n      \"],[1,[28,\"error-messages\",[[24,[\"error\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui ok button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n    Okay\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/components/error-modal/template.hbs"
    }
  });

  _exports.default = _default;
});