define("manage/pods/companies/company/projects/project/sources/theme/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    source: Ember.computed.readOnly('model.source'),
    project: Ember.computed.readOnly('model.project'),
    current_user: Ember.inject.service('user'),
    intl: Ember.inject.service('intl'),
    preview_size: 'desktop',
    is_desktop: Ember.computed.equal('preview_size', 'desktop'),
    is_tablet: Ember.computed.equal('preview_size', 'tablet'),
    is_mobile: Ember.computed.equal('preview_size', 'mobile'),
    themes: ['light', 'dark'],
    submitted: false,
    saveTask: (0, _emberConcurrency.task)(function* (source) {
      source = yield source;
      let result = yield source.get('theme').validate();

      if (result.validations.get('isInvalid')) {
        return;
      }

      yield source.save();
    }),
    actions: {
      save() {
        this.set('submitted', true);

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.get('saveTask').perform(this.get('source'));
      },

      copy_survey_theme() {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'copy-survey-theme',
          approve: () => {
            let project = this.get('project');
            let source = this.get('source');
            source.get('theme').copy(project.get('theme'));
          }
        });
      },

      upload_error(xhr) {
        return alert(xhr.responseText);
      },

      clear_social_image() {
        return this.set('source.theme.social_sharing_image_path', null);
      },

      upload_social_image(data) {
        return this.set('source.theme.social_sharing_image_path', data.image_path);
      }

    }
  });

  _exports.default = _default;
});