define("manage/pods/companies/company/projects/project/segments/components/create-segment/component", ["exports", "manage/helpers/decode-string", "manage/helpers/encode-string", "ember-concurrency", "manage/mixins/order-filters", "manage/mixins/ss-modal", "manage/utils/strip-non-printable"], function (_exports, _decodeString, _encodeString, _emberConcurrency, _orderFilters, _ssModal, _stripNonPrintable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _orderFilters.default, {
    classNames: ['create-segment', 'component'],
    closable: false,
    store: Ember.inject.service(),
    user: Ember.inject.service('user'),
    submitted: false,

    init() {
      this._super(...arguments);

      if (this.get('projectSegment') === undefined) {
        this.set('projectSegment', this.get('store').createRecord('project-segment', {
          project: this.get('project'),
          user: this.get('user'),
          category: 'filter',
          behavior: 'dynamic',
          hidden: false,
          closed: false,
          filter: {}
        }));
      }

      this.set('filter_data', Object.keys(this.get('filter_settings')).reduce((map, filter_id) => {
        if (filter_id === 'custom') {
          map['custom'] = {};
        } else if (filter_id.indexOf('custom.') === 0) {
          let custom_id = filter_id.substr(filter_id.indexOf('.') + 1);
          let custom_data = this.get('projectSegment.filter.custom') || {};
          let value;

          if (typeof custom_data === 'object') {
            value = custom_data[custom_id];
          } else if (typeof custom_data === 'string' && Ember.isPresent(custom_data)) {
            value = JSON.parse((0, _decodeString.decodeString)(custom_data))[custom_id];
          }

          if (map['custom'] == null) {
            map['custom'] = {};
          }

          map['custom'][custom_id] = value;
        } else {
          map[filter_id] = this.get(`projectSegment.filter.${filter_id}`);
        }

        return map;
      }, {}));
      this.build_filter_selections(this.get('filter_data'));
      this.set('filters', Ember.A());
    },

    filter_data: Ember.computed('projectSegment.filter', {
      get() {
        return this.get('projectSegment.filter');
      },

      set(_key, value) {
        this.set('projectSegment.filter', value);
        return value;
      }

    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.process_filters_from_data();
      this.process_friendly_text();
    },

    update: function (values) {
      this.set('projectSegment.filter', values);
    },
    saveSegmentTask: (0, _emberConcurrency.task)(function* () {
      // Handle the applying of the filters in the onClose action
      this.update_values_from_filters();
      let filters = this.get('projectSegment.filter');
      this.set('projectSegment.filter', Object.assign(Object.keys(filters).filter(item => Ember.isPresent(filters[item])).reduce((map, item) => {
        if (item === 'custom') {
          if (Object.keys(filters[item]).any(sub_key => Ember.isPresent(filters[item][sub_key]))) {
            let customValues = Object.keys(filters[item]).reject(subKey => Ember.isBlank(filters[item][subKey])).reduce((newValues, subKey) => {
              newValues[subKey] = filters[item][subKey];
              return newValues;
            }, {});
            map['custom'] = (0, _encodeString.encodeString)(JSON.stringify(customValues));
          }
        } else {
          map[item] = (0, _stripNonPrintable.default)(filters[item]);
        }

        return map;
      }, {}), {
        project: this.get('project.id')
      }));
      yield this.get('projectSegment').save().finally(() => {
        this.set('submitted', true);
      });

      if (this.get('create')) {
        this.get('create')(this.get('projectSegment'));
      }
    }),
    actions: {
      cancel() {
        // Reset all filters back to their data value
        this.process_filters_from_data();
        this.closeModal();
      },

      apply() {
        if (this.get('saveSegmentTask.isRunning')) {
          return;
        }

        this.get('saveSegmentTask').perform().then(() => {
          this.closeModal();
        });
      }

    }
  });

  _exports.default = _default;
});