define("manage/models/project-custom-field", ["exports", "manage/utils/generated-model", "shared/utils/fallback-sort", "ember-cp-validations"], function (_exports, _generatedModel, _fallbackSort, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to')]
    },
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    sort_order: {
      description: "Sort Order",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    description: {
      description: "Description",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    entity: {
      description: "Entity",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    field_type: {
      description: "Field Type",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    values: {
      description: "Values",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'A minimum of 1 value must be mapped up',
        disabled: Ember.computed.not('model.is_option')
      })]
    }
  });

  var _default = (0, _generatedModel.default)('project-custom-field').reopen(Validations, {
    values_sorted: (0, _fallbackSort.default)('values', 'name', 'id'),
    values_text: Ember.computed.mapBy('values_sorted', 'name'),
    is_option: Ember.computed.equal('field_type', 'option')
  });

  _exports.default = _default;
});