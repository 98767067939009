define("manage/pods/companies/company/projects/project/orders/components/app-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['access::admin', 'access::text'],

    init() {
      this._super(...arguments);

      this.set('order_status', this.get('app').order_status());
    }

  });

  _exports.default = _default;
});