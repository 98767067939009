define("manage/pods/companies/company/account/info/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mi4eGskq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui header\"],[10,\"style\",\"margin-top: 1rem;\"],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"content.locale\"],null],false],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"sub header\"],[8],[1,[28,\"t-component\",[[23,0,[]],\"content.locale_subheader\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui list\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"large globe middle aligned icon\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Country\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"company\",\"country\"]]],null,{\"statements\":[[0,\"          \"],[1,[24,[\"company\",\"country\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"em\",true],[8],[0,\"Not set\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"company\",\"state\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"State\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[1,[24,[\"company\",\"state\",\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/account/info/view/template.hbs"
    }
  });

  _exports.default = _default;
});