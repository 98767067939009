define("manage/components/bread-crumb/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['bread-crumb'],
    intl: Ember.inject.service(),

    init() {
      if (Ember.isBlank(this.text)) {
        let args = Object.keys(this.get('attrs')).reject(key => key == 'component').reduce((hash, key) => {
          hash[key] = this.get('attrs')[key];
          return hash;
        }, {});
        this.set('translatedText', this.get('intl').t(`route.${this.get('path')}.header`, args));
      }

      this._super(...arguments);
    }

  }).reopenClass({
    positionalParams: ['path']
  });

  _exports.default = _default;
});