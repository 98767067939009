define("manage/pods/companies/company/projects/project/orders/view/components/upgrade-order-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['upgrade-order-modal', 'component'],
    current_user: Ember.inject.service('user'),
    orderTasks: Ember.inject.service(),
    store: Ember.inject.service(),
    closable: false,
    // Testing
    'data-test-upgrade-order-modal': true,
    // variables
    order_line: null,
    project: Ember.computed.readOnly('order.project'),
    order: Ember.computed.readOnly('order_line.order'),
    validationErrors: null,

    init() {
      this._super(...arguments);

      this.set('initial_product_bundle', this.get('order_line.product_bundle'));
      this.set('configuration', this.get('initial_product_bundle.configuration'));
      this.set('initial_configuration', this.get('configuration'));
    },

    available_configurations: Ember.computed('order.source.configurations.@each.is_complete', 'initial_configuration', function () {
      return this.order.get('source.configurations_sorted').filter(config => !config.is_complete).map(config => {
        return {
          configuration: config,
          id: config.id,
          title: config.informational_title_with_amount,
          disabled: config.available_orders === 0 && config.id !== this.initial_configuration.get('id')
        };
      });
    }),
    updateOrderTask: (0, _emberConcurrency.task)(function* (order_line, configuration) {
      order_line = yield order_line;
      let order = yield order_line.get('order');
      configuration = yield configuration;

      if (order.get('is_completed')) {
        yield order.uncomplete(true);
      }

      if (order_line.get('is_reward')) {
        order.set('current_configuration', configuration);
      } // Update the order line


      order_line.set('product_bundle', configuration.get('product_reward'));
      yield this.get('orderTasks.save').perform(order, {
        validate: false,
        save_shipping_address: false
      });
      yield order.reload(); // The stats have changed for both the initial configuration and the new one. Reload them.

      yield this.store.query('project-configuration-stat', {
        filter: {
          id: [this.initial_configuration.get('stats.id'), this.configuration.get('stats.id')]
        }
      });
    }),
    rollbackTask: (0, _emberConcurrency.task)(function* (order_line) {
      order_line = yield order_line;
      let order = yield order_line.get('order');
      order_line.rollbackAttributes();
      order_line.rollbackRelationships();
      order.rollbackAttributes();
      order.rollbackRelationships();
    }),
    actions: {
      set_configuration(option) {
        this.set('configuration', option.configuration);
      },

      approve() {
        if (this.get('updateOrderTask.isRunning')) {
          return;
        }

        let order_line = this.get('order_line');
        let configuration = this.get('configuration');
        this.get('updateOrderTask').perform(order_line, configuration).then(() => {
          this.closeModal();
        }).catch(error => {
          if (error.get && error.get('isInvalid')) {
            this.set('validationErrors', error);
          } else {
            this.set('validationErrors', null);
          }

          this.set('has_error', true);
        });
      },

      close() {
        if (this.get('rollbackTask.isRunning')) {
          return;
        }

        let order_line = this.get('order_line');
        this.get('rollbackTask').perform(order_line).then(() => this.closeModal());
      }

    }
  });

  _exports.default = _default;
});