define("manage/pods/user/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),

    model() {
      const attemptedTransition = this.get('session.attemptedTransition');
      const redirectTarget = this.get('cookies').read('ember_simple_auth-redirectTarget');
      return {
        sessionInvalid: attemptedTransition != null || Ember.isPresent(redirectTarget)
      };
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
        controller.set('error', false);
        controller.set('loading', false);
        controller.set('logged_in', false);
        controller.set('identification', null);
        controller.set('password', null);
      }
    }

  });

  _exports.default = _default;
});