define("manage/pods/companies/company/projects/project/apps/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),

    model(params) {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project,
        app: this.store.findRecord('project-app', `${project.get('id')}-${params.app_id}`)
      });
    }

  });

  _exports.default = _default;
});