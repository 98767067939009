define("manage/mixins/models/project/configuration", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    source: {
      description: "Source",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    external_id: {
      description: "External Id",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    status: {
      description: "Status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['setup', 'live', 'complete']
      })]
    },
    can_select: {
      description: "Can Select",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    },
    product_reward: {
      description: "Product Reward",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product_extra: {
      description: "Product Extra",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    upgrade_type: {
      description: "Upgrade Type",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    informational_title: Ember.computed('product_reward.inventory_total_quantity', 'available_orders', 'product_reward.name', function () {
      var limit = this.get('product_reward.inventory_total_quantity') ? `${this.get('available_orders')} left of ${this.get('product_reward.inventory_total_quantity')}` : '';
      return `${this.get('product_reward.name')}${limit.length ? ' [' + limit + ']' : ''}`;
    }),
    informational_title_with_amount: Ember.computed('intl.locale', 'informational_title', 'project.currency', 'product_reward.price', function () {
      let formattedPrice = this.get('intl').formatNumber(this.get('product_reward.price'), {
        currency: this.get('project.currency')
      });
      return `${formattedPrice}: ${this.get('informational_title')}`;
    }),
    source_site: Ember.computed.alias('source.site'),
    // Aliases to avoid @each two levels
    product_reward_price: Ember.computed.readOnly('product_reward.price'),
    product_reward_name: Ember.computed.readOnly('product_reward.name'),
    is_live_or_complete: Ember.computed.or('is_live', 'is_complete'),
    // Helpers to avoid deprecation warnings
    product_reward_price_cents: Ember.computed.readOnly('product_reward.price_cents'),

    review() {
      return this.get('ajax').requestModel(this, 'review');
    }

  });

  _exports.default = _default;
});