define("manage/mixins/models/product/zone", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    product: {
      description: "Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    shipping: {
      description: "Shipping",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 0
      })]
    },
    // Don't validate name, it should generate it automatically. Only return backend errors
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },
    location_type: {
      description: "Location Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['everywhere_else', 'countries', 'states']
      })]
    },
    cost_type: {
      description: "Cost Type",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['shipping_product_based', 'extra_cap']
      })]
    },
    locations: {
      description: "Locations",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Must have at least one location selected'
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    intl: Ember.inject.service(),
    shipping_display: Ember.computed('intl.locale', 'shipping', 'project.currency', function () {
      return "companies/company/projects/project/location-zone-shipping";
    }),

    normalize() {
      if (Ember.isBlank(this.get('name'))) {
        this.set('name', this.get('generated_name'));
      }

      if (Ember.isBlank(this.get('shipping'))) {
        this.set('shipping', 0);
      }
    },

    initValue() {
      this.set('shipping', null);
    }

  });

  _exports.default = _default;
});