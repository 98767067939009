define("manage/components/t-title/component", ["exports", "manage/helpers/t-component-path"], function (_exports, _tComponentPath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    titlePath: Ember.computed('component', function () {
      return (0, _tComponentPath.componentPath)([this.component, 'title']).replace(/^controller/, 'route');
    })
  }).reopenClass({
    positionalParams: ['component']
  });

  _exports.default = _default;
});