define("manage/pods/companies/company/projects/project/settings/fields/components/column-entity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Llx4x3hp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t-route\",[[28,\"concat\",[\"content.entity.option.\",[24,[\"custom_field\",\"entity\"]]],null]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/settings/fields/components/column-entity/template.hbs"
    }
  });

  _exports.default = _default;
});