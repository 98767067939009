define("manage/pods/companies/company/projects/project/orders/view/components/order-line-extension/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['order-line-extension', 'component'],
    current_user: Ember.inject.service('user'),
    order_line: null,
    can_change: Ember.computed('order_line.{order.is_locked,is_bundle,is_reward}', function () {
      if (this.get('order_line.order.is_locked')) {
        return false;
      } // If it's not a bundle we can't change it


      if (!this.get('order_line.is_bundle')) {
        return false;
      } // Only let reward lines change


      return this.get('order_line.is_reward');
    }),
    can_edit: Ember.computed('order_line.{order.is_locked,is_bundle,is_reward}', function () {
      if (this.get('order_line.order.is_locked')) {
        return false;
      } // If it's not a bundle we can't change it


      if (!this.get('order_line.is_bundle')) {
        return false;
      } // Don't allow editing reward bundles


      return !this.get('order_line.is_reward');
    }),
    can_remove: Ember.computed('order_line.{order.is_locked,is_bundle,is_reward}', function () {
      if (this.get('order_line.order.is_locked')) {
        return false;
      } // If its not in a bundle, you can't remove the line


      if (!this.get('order_line.is_bundle')) {
        return false;
      } // If its not a reward line, you can remove the bundle


      return !this.get('order_line.is_reward');
    }),
    actions: {
      change() {
        this.send('open_modal', 'companies/company/projects/project/orders/view/upgrade-order-modal', {
          order_line: this.get('order_line')
        });
      },

      edit() {
        this.send('open_modal', 'companies/company/projects/project/orders/view/add-order-line-modal', {
          order_line: this.get('order_line')
        });
      },

      remove() {
        this.send('open_modal', 'companies/company/projects/project/orders/view/components/remove-order-line-modal', {
          order_line: this.get('order_line')
        });
      }

    }
  });

  _exports.default = _default;
});