define("manage/mirage/factories/project-source", ["exports", "shared/mirage/factories/project-source", "ember-cli-mirage"], function (_exports, _projectSource, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _projectSource.default.extend({
    settings: {
      email: {
        claim_rewards_ongoing: true,
        claim_rewards_ongoing_days_between: 0,
        claim_rewards_ongoing_attempts: 14,
        recover_rewards: true,
        recover_rewards_ongoing_days_between: 7,
        recover_rewards_attempts: 5,
        pre_order_recovery_ongoing: true
      }
    },
    kickstarter: (0, _emberCliMirage.trait)({
      site: 'kickstarter'
    }),
    crowdox: (0, _emberCliMirage.trait)({
      site: 'crowdox'
    })
  });

  _exports.default = _default;
});