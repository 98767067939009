define("manage/pods/companies/company/projects/project/digital/components/import-product-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/config/environment"], function (_exports, _ssModal, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['import-product-modal', 'component'],
    closable: false,
    store: Ember.inject.service(),
    project: null,
    products: null,
    emails: null,
    filteredProducts: Ember.computed.filterBy('products', 'isDeleted', false),
    selectedProducts: Ember.A(),
    selectedEmail: null,
    error: null,

    init() {
      this._super(...arguments);

      this.set('selectedProducts', Ember.A());
      this.get('loadProductsTask').perform();
      this.get('loadEmailsTask').perform();
    },

    isRunning: Ember.computed.or('loadProductsTask.isRunning', 'loadEmailsTask.isRunning', 'importProductsTask.isRunning'),
    loadProductsTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let products = yield this.get('store').query('product', {
        filter: {
          project: project.get('id'),
          product_type: ['digital'],
          digital_fulfillment: false
        },
        page: {
          size: _environment.default['max-page-size']
        }
      });
      this.set('products', products);
    }),
    loadEmailsTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let emails = yield this.get('store').query('project-email', {
        filter: {
          project: project.get('id'),
          template: 'digital-fulfillment,none'
        },
        page: {
          size: _environment.default['max-page-size']
        }
      });
      this.set('emails', emails);
      this.set('selectedEmail', emails.findBy('template', 'digital-fulfillment'));
    }),
    importProductsTask: (0, _emberConcurrency.task)(function* (sub_type) {
      let project_email = yield this.get('selectedEmail');
      let project = yield this.get('project');
      let selected_products = yield this.get('selectedProducts');

      for (var i = 0; i < selected_products.get('length'); i++) {
        let product = yield selected_products.objectAt(i);
        let fulfillment = this.get('store').createRecord('digital-fulfillment', {
          project: project,
          product: product,
          sub_type: sub_type,
          project_email: project_email
        });

        try {
          yield fulfillment.save();
        } catch (e) {
          this.set('error', `Unable to import ${product.get('name')}`);
          throw e;
        }
      }
    }),
    actions: {
      close() {
        this.closeModal();
      },

      toggleProduct(product) {
        if (this.get('selectedProducts').includes(product)) {
          this.get('selectedProducts').removeObject(product);
        } else {
          this.get('selectedProducts').addObject(product);
        }
      },

      importProducts(sub_type) {
        if (this.get('selectedProducts.length') === 0) {
          this.set('error', 'No product selected');
          return;
        }

        this.set('error', null);
        this.get('importProductsTask').perform(sub_type).then(() => {
          this.closeModal();
          this.get('completed')();
        });
      }

    }
  });

  _exports.default = _default;
});