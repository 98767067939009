define("manage/pods/companies/company/projects/project/segments/actions/components/action-status-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: ''
  });

  _exports.default = _default;
});