define("manage/mixins/filters/order-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    transaction_provider_options: [Ember.Object.create({
      id: 'Celery',
      title: 'Celery'
    }), Ember.Object.create({
      id: 'Fig',
      title: 'Fig'
    }), Ember.Object.create({
      id: 'Indiegogo',
      title: 'Indiegogo'
    }), Ember.Object.create({
      id: 'Kickstarter',
      title: 'Kickstarter'
    }), Ember.Object.create({
      id: 'Manual',
      title: 'Manual'
    }), Ember.Object.create({
      id: 'Other',
      title: 'Other'
    }), Ember.Object.create({
      id: 'PayPal',
      title: 'PayPal'
    }), Ember.Object.create({
      id: 'Shopify',
      title: 'Shopify'
    }), Ember.Object.create({
      id: 'Squarespace',
      title: 'Squarespace'
    }), Ember.Object.create({
      id: 'Stripe',
      title: 'Stripe'
    })]
  });

  _exports.default = _default;
});