define("manage/services/project-configuration-tasks", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    productTasks: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* (configuration) {
      configuration = yield configuration;
      let product_reward = yield configuration.get('product_reward');
      let product_extra = yield configuration.get('product_extra');

      if (configuration.get('isNew') && Ember.isBlank(product_extra)) {
        // Always reset this on is new.
        product_extra = yield configuration.get('source.product_extra_global');
        configuration.set('product_extra', product_extra);
      }

      let {
        validations: validations
      } = yield configuration.validate();

      if (!validations.get('isValid')) {
        throw validations;
      }

      yield this.get('productTasks.save').perform(product_reward);

      if (Ember.isPresent(product_extra)) {
        yield this.get('productTasks.save').perform(product_extra);
      }

      if (configuration.get('isDirty')) {
        yield configuration.save();
      }
    }),
    isDirtyProductReward: (0, _emberConcurrency.task)(function* (configuration) {
      configuration = yield configuration;

      if (configuration.get('isDirty')) {
        return true;
      }

      let product_reward = yield configuration.get('product_reward');
      return yield this.get('productTasks.isDirty').perform(product_reward);
    }),
    rollbackProductReward: (0, _emberConcurrency.task)(function* (configuration) {
      configuration = yield configuration;
      let product_reward = yield configuration.get('product_reward');
      yield this.get('productTasks.rollback').perform(product_reward);

      if (configuration.get('isNew')) {
        // Also rollback extras, since this is defaulted on create
        let product_extra = yield configuration.get('product_extra');

        if (Ember.isPresent(product_extra)) {
          yield this.get('productTasks.rollback').perform(product_extra);
        }
      }

      configuration.rollbackAttributes();
      configuration.rollbackRelationships();
    })
  });

  _exports.default = _default;
});