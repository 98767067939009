define("manage/pods/companies/company/projects/project/products/index/components/column-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MfutnTyl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"product\",\"product_type\"]],\"option\"],null],[28,\"eq\",[[24,[\"product\",\"product_type\"]],\"bundle\"],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"product\",\"stats\",\"mappings\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  --\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/index/components/column-products/template.hbs"
    }
  });

  _exports.default = _default;
});