define("manage/pods/companies/company/projects/project/digital/components/change-status-modal/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "manage/pods/companies/company/projects/project/digital/constants"], function (_exports, _ssModal, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _constants.default, {
    classNames: ['change-status-modal', 'component'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    isRunning: Ember.computed.alias('saveTask.isRunning'),
    originalStatus: null,
    newStatus: null,
    error: null,

    init() {
      this._super(...arguments);

      this.originalStatus = this.fulfillment.status;
      this.newStatus = this.fulfillment.status;
    },

    updateTask: (0, _emberConcurrency.task)(function* (fulfillment) {
      fulfillment.set('status', this.newStatus);

      if (fulfillment.hasDirtyAttributes) {
        try {
          yield fulfillment.save();
        } catch (e) {
          this.set('error', 'Error while saving. Unable to update status.');
          fulfillment.rollbackAttributes();
          throw e;
        }
      }
    }),

    performUpdateTask() {
      return this.get('updateTask').perform(this.fulfillment).then(() => this.closeModal());
    },

    actions: {
      close() {
        this.closeModal();
      },

      update() {
        // If there's a message present in i18n, display it and get verification from the user
        // before continuing.
        let intlKey = `digital-fulfillment-status-update.${this.newStatus}`;

        if (this.intl.exists(`confirm-modal.${intlKey}.title`)) {
          this.send('open_modal', 'confirm-modal', {
            i18nKey: intlKey,
            approve: () => {
              return this.performUpdateTask();
            }
          });
        } else {
          this.performUpdateTask();
        }
      }

    }
  });

  _exports.default = _default;
});