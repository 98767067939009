define("manage/utils/load-holidays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadHolidays;

  function loadHolidays(store) {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return store.query('holiday', {
      filter: {
        ends_at: `since=${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`
      },
      page: {
        size: 250
      }
    });
  }
});