define("manage/mixins/stripe", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    card: {
      description: "Credit Card",
      validators: [(0, _emberCpValidations.validator)('inline', {
        dependentKeys: ['model.stripeResults'],

        validate(value, options, model
        /*, attribute */
        ) {
          const {
            error,
            complete,
            empty
          } = model.get('stripeResults');

          if (error) {
            return error.message;
          } else if (empty) {
            return "Credit Card info can't be blank";
          } else if (!complete) {
            return "Credit Card info is incomplete";
          }

          return true;
        }

      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.country.iso2', function () {
          return this.get('model.country.iso2') != "US";
        }),
        message: "State can't be blank while in the US"
      }, {
        dependentKeys: ['model.country.iso2']
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    saving: false,
    submitted: false,
    name: null,
    country: null,
    state: null,
    card: null,
    stripeResults: {
      empty: true,
      authorization_token: null
    },
    cardComplete: Ember.computed('stripeResults', 'card', function () {
      // Stripe results are the most up to date
      if (this.get('stripeResults.complete') != null) {
        return this.get('stripeResults.complete');
      } // If we don't have stripe results, try to fall back to the card


      if (this.get('card._complete')) {
        return true;
      }

      return false;
    }),

    initializeCard(card) {
      if (Ember.isPresent(this.get('card'))) {
        this.get('card').off('change');
      }

      this.set('card', card);
      card.on('change', results => this.set('stripeResults', results));
    },

    async get_stripe_token() {
      this.set('submitted', true);
      const result = this.validateSync();

      if (result.validations.get('isInvalid')) {
        throw ":invalid";
      }

      if (this.get('saving')) {
        return;
      }

      this.set("saving", true);
      let token, error;

      try {
        ({
          token,
          error
        } = await window.stripe.createToken(this.get('card'), {
          name: this.get('name'),
          address_country: this.get('country.iso2'),
          address_state: this.get('state.name')
        }));
      } catch (e) {
        error = e;
      }

      if (error) {
        this.set("saving", false);
        throw error.message;
      }

      this.set('authorization_token', token.id);
      this.set("saving", false);
    }

  });

  _exports.default = _default;
});