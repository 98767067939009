define("manage/pods/admin/companies/company/components/transfer-project/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency"], function (_exports, _ssModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    closable: false,
    classNames: ['transfer-project-modal'],
    submitted: false,
    company_to: null,
    isRunning: Ember.computed.alias('transferTask.isRunning'),
    transferTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('submitted', true);
        let project = yield this.get('project');
        let company_to = yield this.get('company_to');
        let results = yield this.get('ajax').postModel(project, 'transfer', {
          data: {
            company_to_id: company_to.get('id')
          }
        });

        if (results.hasOwnProperty('ok')) {
          this.set('transferred', results.ok);
        }
      } catch (error) {
        let errorDetail = error;

        if (error.payload.hasOwnProperty('errors')) {
          errorDetail = error.payload.errors.map(err => {
            if (err.meta && err.meta.exception) {
              return err.meta.exception;
            } else {
              return err.title;
            }
          }).join("\n\n");
        }

        alert(`Unable to transfer!\n\n${errorDetail}`);
      }
    }),
    searchTask: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(800);
      return this.get('store').query('company', {
        filter: {
          search: value
        },
        sort: 'name',
        page: {
          size: 25
        }
      });
    }).restartable(),
    actions: {
      closeAndComplete() {
        this.closeModal();

        if (this.get('completed')) {
          this.get('completed')();
        }
      }

    }
  });

  _exports.default = _default;
});