define("manage/pods/companies/company/projects/project/segments/components/generate-shipping-export-modal/component", ["exports", "manage/mixins/ss-modal", "manage/config/environment", "manage/mixins/order-filters", "ember-concurrency"], function (_exports, _ssModal, _environment, _orderFilters, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    isRunning: Ember.computed.alias('generateTask.isRunning'),
    // Passed in when invoked
    project: null,
    status: null,
    key: null,
    base_url: null,
    filters: null,

    init() {
      this._super(...arguments);

      if (typeof this.get('filters') == 'function') {
        this.set('filters', this.get('filters')());
      }
    },

    export: null,
    is_processing: false,
    can_mark_as_exported: Ember.computed.equal('status', 'not_exported'),
    api_url: _environment.default.urls.api,
    lock_level: Ember.computed('filters.[]', function () {
      const applied_filters = Object.entries(this.get('filters')).reduce((arr, _ref) => {
        let [key, value] = _ref;
        if (!Ember.isBlank(value)) arr.push(key);
        return arr;
      }, []);
      const has_selection_filter = applied_filters.find(i => _orderFilters.filters[i] && _orderFilters.filters[i].level === 'selection');
      return has_selection_filter ? 'Selections' : 'Orders';
    }),
    export_types: Ember.computed('project.export_type', 'project.id', function () {
      var intl = this.get('intl');
      var export_options = Ember.A([{
        id: '10',
        export_type: 'crowdox',
        format_type: 'excel',
        capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'line-type', 'product-titles', 'product-skus', 'product-questions'],
        incapability: ['product-unit-price']
      }, {
        id: '11',
        export_type: 'crowdox',
        format_type: 'csv',
        capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'line-type', 'product-titles', 'product-skus', 'product-questions'],
        incapability: ['product-unit-price']
      }, {
        id: '12',
        export_type: 'crosstab',
        format_type: 'excel',
        capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'product-skus', 'answer-one'],
        incapability: ['product-titles', 'answers-multiple', 'product-unit-price']
      }, {
        id: '13',
        export_type: 'ship_station',
        format_type: 'csv',
        export_url: 'http://www.shipstation.com/',
        capability: ['name-and-email', 'source', 'order-date', 'amount-paid', 'notes', 'address', 'product-titles', 'product-skus', 'answers-product'],
        incapability: ['product-question-text', 'product-unit-price']
      }, {
        id: '14',
        export_type: 'funagain_logistics',
        format_type: 'csv',
        export_url: 'https://funagain.com/pages/logistics',
        capability: ['name-and-email', 'address', 'product-skus', 'product-titles'],
        incapability: ['source', 'order-date', 'amount-paid', 'notes', 'answers-product', 'product-question-text', 'product-unit-price']
      }, {
        id: '15',
        export_type: 'ship_bob',
        format_type: 'excel',
        export_url: 'https://www.shipbob.com/',
        capability: ['name-and-email', 'address', 'product-skus'],
        incapability: ['source', 'order-date', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles']
      }, {
        id: '16',
        export_type: 'ship_3pl',
        format_type: 'csv',
        export_url: 'http://3plcentral.com/',
        capability: ['name-and-email', 'order-date', 'notes', 'address', 'product-skus'],
        incapability: ['source', 'amount-paid', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles']
      }, {
        id: '17',
        export_type: 'whiplash',
        format_type: 'csv',
        export_url: 'https://www.whiplashmerch.com/',
        capability: ['name-and-email', 'address', 'product-skus', 'answer-one'],
        incapability: ['order-date', 'notes', 'source', 'amount-paid', 'product-unit-price', 'answers-multiple', 'product-question-text', 'product-titles', 'product-unit-price']
      }, {
        id: '18',
        export_type: 'floship',
        format_type: 'excel',
        export_url: 'https://www.floship.com/',
        capability: ['name-and-email', 'address', 'product-skus'],
        incapability: ['order-date', 'notes', 'source', 'amount-paid', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles', 'product-unit-price']
      }, {
        id: '19',
        export_type: 'james_and_james',
        format_type: 'csv',
        export_url: 'http://www.ecommercefulfilment.com/',
        capability: ['order-date', 'name-and-email', 'address', 'product-skus'],
        incapability: ['source', 'order-date', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text', 'product-titles']
      }, {
        id: '20',
        export_type: 'amplifier',
        format_type: 'csv',
        export_url: 'https://amplifier.com/',
        capability: ['order-date', 'name-and-email', 'address', 'product-titles', 'product-skus'],
        incapability: ['source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
      }, {
        id: '21',
        export_type: 'amplifier',
        format_type: 'excel',
        export_url: 'https://amplifier.com/',
        capability: ['order-date', 'name-and-email', 'address', 'product-titles', 'product-skus'],
        incapability: ['source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
      }, {
        id: '22',
        export_type: 'blackbox',
        format_type: 'excel',
        export_url: 'https://www.blackbox.cool/',
        capability: ['name-and-email', 'address', 'product-skus'],
        incapability: ['order-date', 'product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
      }, {
        id: '23',
        export_type: 'shipwire',
        format_type: 'csv',
        export_url: 'https://www.shipwire.com/',
        capability: ['order-date', 'name-and-email', 'address', 'product-skus'],
        incapability: ['product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
      }, {
        id: '24',
        export_type: 'bonver',
        format_type: 'xml',
        export_url: 'https://bonver.se/',
        capability: ['name-and-email', 'address', 'product-skus'],
        incapability: ['order-date', 'product-titles', 'source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
      }, {
        id: '26',
        export_type: 'quartermaster',
        format_type: 'excel',
        export_url: 'http://www.qmlogistics.com/',
        capability: ['order-date', 'name-and-email', 'address', 'phone-number', 'product-titles', 'product-skus'],
        incapability: ['source', 'amount-paid', 'notes', 'product-unit-price', 'answers-product', 'product-question-text']
      }].map(e => {
        e.name = intl.t(`order-exports.${e.export_type}.name`);
        e.capability = e.capability.map(key => intl.t(`order-exports.capabilities.${key}`));

        if (e.incapability) {
          e.incapability = e.incapability.map(key => intl.t(`order-exports.capabilities.${key}`));
        }

        return e;
      }));

      if (this.get('project.export_type') != null && !export_options.isAny('export_type', this.get('project.export_type'))) {
        export_options.unshiftObject({
          id: '0',
          name: Ember.String.capitalize(this.get('project.export_type').replace(/_/g, ' ')),
          export_type: this.get('project.export_type'),
          format_type: 'csv',
          capability: [intl.t('order-exports.capabilities.custom')]
        });
        export_options.unshiftObject({
          id: '1',
          name: Ember.String.capitalize(this.get('project.export_type').replace(/_/g, ' ')),
          export_type: this.get('project.export_type'),
          format_type: 'excel',
          capability: [intl.t('order-exports.capabilities.custom')]
        });
      }

      return export_options;
    }),
    generateTask: (0, _emberConcurrency.task)(function* (mark_exported) {
      try {
        let data = Object.assign({}, this.get('filters'), {
          project_id: this.get('project.id'),
          file_format: this.get('export.export_type'),
          format_type: this.get('export.format_type'),
          mark_exported
        });
        let result = yield this.get('ajax').post(this.get('base_url'), {
          data: data
        });

        if (result.data) {
          this.set('jobId', result.data.job_id);
        }
      } catch (xhr) {
        this.send('jobFailed', xhr.payload.error);
      }
    }),
    actions: {
      cancel() {
        this.closeModal();
      },

      generate(mark_exported) {
        if (this.isRunning) {
          return;
        }

        this.set('error', null);
        this.get('generateTask').perform(mark_exported);
      },

      jobFailed(error) {
        this.set('jobId', null);
        this.set('error', error.message);
      }

    }
  });

  _exports.default = _default;
});