define("manage/helpers/strip-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stripTags = stripTags;

  // Helper to strip all HTML tags out of the input, leaving only the text.
  function stripTags(_ref) {
    let [htmlString] = _ref;
    var doc = document.implementation.createHTMLDocument('');
    const div = doc.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent;
  }

  var _default = Ember.Helper.helper(stripTags);

  _exports.default = _default;
});