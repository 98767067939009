define("manage/pods/companies/company/projects/project/settings/fields/components/create-custom-field-modal/component", ["exports", "ember-inflector", "ember-concurrency", "manage/mixins/ss-modal", "jquery"], function (_exports, _emberInflector, _emberConcurrency, _ssModal, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['edit-custom-field-modal', 'component'],
    store: Ember.inject.service(),
    closable: false,
    submitted: false,
    lastField: false,
    base_tabindex: 1000,
    // We need to start higher, to not interfere with non modal tab indexes
    entities: ["product", // "customer",
    "order"],
    field_types: ["text", "number", "currency", "checkbox", // "date",
    "option"],
    visibilities: [Ember.Object.create({
      id: 'internal',
      name: 'Internal only'
    }), Ember.Object.create({
      id: 'external',
      name: 'External'
    })],

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    isRunning: Ember.computed.or('initTask.isRunning', 'saveTask.isRunning'),
    initTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let custom_field = yield this.get('custom_field');
      let entity = this.get('entity');

      if (Ember.isBlank(custom_field)) {
        let sort_orders = this.get('project.custom_fields').filterBy('entity', entity).mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        custom_field = this.get('store').createRecord('project-custom-field', {
          project: project,
          entity: this.get('entity'),
          sort_order: sort_order,
          field_type: 'text'
        });
        this.set('custom_field', custom_field);
      }
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      this.set('submitted', true);
      let custom_field = yield this.get('custom_field');
      yield custom_field.validate();

      if (custom_field.get('validations.isInvalid')) {
        return;
      }

      yield custom_field.save();
      let custom_field_values = yield custom_field.get('values');

      for (let index = 0; index < custom_field_values.length; index++) {
        const model = custom_field_values.objectAt(index);
        yield model.save();
      }

      this.send('close');
    }),
    deleteCustomFieldValue: (0, _emberConcurrency.task)(function* (custom_field_value) {
      let wasNew = custom_field_value.get('isNew');
      custom_field_value.deleteRecord();

      if (!wasNew) {
        try {
          yield custom_field_value.save();
        } catch (e) {
          // Revert the change
          custom_field_value.rollbackAttributes();
          throw e;
        }
      }
    }),
    // We don't let them delete values that are saved if new ones exist. Otherwise we get into a weird loop
    has_unsaved_new_values: Ember.computed('custom_field.isNew', 'custom_field.values.@each.isNew', function () {
      if (this.get('custom_field.isNew')) {
        return false; // Everything is new if the variant is new. ignore
      }

      if (this.get('custom_field.values').isAny('isNew')) {
        return true; // If we have any new variations, then we need to save before deleting old ones
      }

      return false;
    }),
    actions: {
      close() {
        if (this.get('custom_field.isDirty')) {
          this.get('custom_field').rollbackAttributes();
        }

        this.get('custom_field.values').forEach(value => {
          if (value.get('isDirty')) {
            value.rollbackAttributes();
          }
        });

        if (this.get('closed')) {
          this.get('closed')();
        }

        this.closeModal();
      },

      change_custom_field_type(type) {
        if (type === 'option') {
          if (!this.get('custom_field.values.length')) {
            this.send('add_custom_field_value');
          }
        } else {
          this.get('custom_field.values').forEach(v => this.send('delete_custom_field_value', v));
        }

        this.set('custom_field.field_type', type);
        return type;
      },

      add_custom_field_value() {
        let sort_orders = this.get('custom_field.values').mapBy('sort_order');
        sort_orders.push(0);
        let sort_order = Math.max(...sort_orders) + 1;
        this.get('store').createRecord('project-custom-field-value', {
          project: this.get('project'),
          custom_field: this.get('custom_field'),
          sort_order: sort_order
        });
      },

      order_custom_field_values(custom_field_values) {
        custom_field_values.forEach((custom_field_value, index) => custom_field_value.set('sort_order', index + 1));
      },

      delete_custom_field_value(custom_field_value) {
        if (this.get('deleteCustomFieldValue.isRunning')) {
          return;
        } // If its new, don't pop open a modal so we don't skip anything


        if (custom_field_value.get('isNew')) {
          this.get('deleteCustomFieldValue').perform(custom_field_value).catch(e => {
            this.send('open_modal', 'error-modal', {
              description: e.message
            });
          });
        } else {
          this.send('open_modal', 'confirm-modal', {
            i18nKey: 'delete-custom-field-value',
            i18nParams: {
              name: custom_field_value.get('name'),
              entities: (0, _emberInflector.pluralize)(custom_field_value.get('custom_field.entity'))
            },
            approve: () => {
              return this.get('deleteCustomFieldValue').perform(custom_field_value);
            }
          });
        }
      },

      // Last input checks
      enterPressed(index) {
        if (index + 1 === this.get('custom_field.values.length')) {
          this.send('add_custom_field_value');
          Ember.run.schedule('afterRender', this, function () {
            (0, _jquery.default)('input', this.element).last().focus();
          });
        } else {
          (0, _jquery.default)(`input[tabindex=${this.get('base_tabindex') + index + 1}]`, this.element).focus();
        }
      },

      focusIn(index) {
        if (index + 1 === this.get('custom_Field.values.length')) {
          this.set('lastField', true);
        }
      },

      focusOut() {
        this.set('lastField', false);
      }

    }
  });

  _exports.default = _default;
});