define("manage/mirage/factories/company", ["exports", "shared/mirage/factories/company"], function (_exports, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    name: 'The Company'
  });

  _exports.default = _default;
});