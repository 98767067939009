define("manage/pods/companies/company/account/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let company = this.modelFor('companies.company');
      return this.store.findRecord('company', company.get('id'), {
        include: ['users', 'companies-users'].join(',')
      });
    },

    actions: {
      refreshUsers() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});