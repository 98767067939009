define("manage/mixins/models/product/mapping", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project: {
      description: "Project",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    parent: {
      description: "Parent Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    child: {
      description: "Child Product",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    required_quantity: {
      description: "Required Quantity",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        gte: 1
      })]
    },
    is_default: {
      description: "Is Default",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    },
    additional_price: {
      description: "Additional Price",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        gte: 0
      })]
    }
  });

  var _default = Ember.Mixin.create(Validations, {
    // is_child_physical: Ember.computed.alias('child.is_act'),
    // is_child_digital: Ember.computed.alias('child.is_act'),
    is_child_is_physical_or_digital: Ember.computed.alias('child.is_physical_or_digital'),
    is_child_question: Ember.computed.alias('child.is_question'),
    is_child_option: Ember.computed.alias('child.is_option'),
    is_child_section: Ember.computed.alias('child.is_section'),
    is_child_bundle: Ember.computed.alias('child.is_bundle'),
    is_child_unknown: Ember.computed.alias('child.is_unknown')
  });

  _exports.default = _default;
});