define("manage/pods/companies/company/account/components/authorization-revoked/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'auth-revoked',
    company: null,
    type: null,
    is_revoked: Ember.computed('type', 'company.authorizations', function () {
      return (this.get('company.authorizations') || {})[this.get('type')] === 'revoked';
    })
  });

  _exports.default = _default;
});