define("manage/models/project-support", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment"], function (_exports, _model, _emberCpValidations, _attributes, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'email'
      }), (0, _emberCpValidations.validator)('format', {
        inverse: true,
        regex: /@.*crowdox\.com/,
        disabled: Ember.computed('model.project.company.demo', function () {
          if (this.get('model.project.company.demo')) {
            return this.get('model.project.company.demo');
          }

          return false;
        }),
        message: 'Email address should be a support email within your organization; it cannot be an address from crowdox.com'
      }), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    email: (0, _model.attr)('string'),
    project: (0, _attributes.fragmentOwner)()
  });

  _exports.default = _default;
});