define("manage/pods/companies/company/projects/project/stats/index/components/stat-configurations/component", ["exports", "ember-concurrency", "manage/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['order', 'status', 'graph', 'ui', 'segment'],
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      let project = this.get('project');
      let stats = Ember.A();

      if (this.get('show_order_statistics')) {
        stats = yield this.get('store').query('project-order-configuration', {
          filter: {
            project: project.get('id')
          },
          page: {
            size: _environment.default['max-page-size']
          }
        });
      } else {
        stats = yield this.get('generateExternalStatsTask').perform(project);
      }

      this.set('stats', stats);
    }),
    generateExternalStatsTask: (0, _emberConcurrency.task)(function* (project) {
      let configurations = yield project.get('configurations');
      let stats = Ember.A();

      for (let configuration of configurations.toArray()) {
        let product_reward = yield configuration.get('product_reward');
        let configuration_stats = yield configuration.get('stats');
        stats.push(this.get('store').createRecord('project-order-location', {
          project,
          title: product_reward.get('name'),
          count: configuration_stats.get('initial_order_count')
        }));
      }

      return stats;
    }),
    empty: Ember.computed.equal('stats.length', 0),
    order_configurations_total: Ember.computed('stats.@each.count', function () {
      return (this.get('stats') || Ember.A()).mapBy('count').reduce(function (a, b) {
        return a + b;
      }, 0);
    }),
    order_configurations_largest: Ember.computed('order_configurations_chart_data', function () {
      let data = this.get('order_configurations_chart_data');
      return {
        label: data.labels[data.labels.length - 1],
        percent: data.datasets[0].percents[data.datasets[0].percents.length - 1]
      };
    }),
    order_configurations_chart_data: Ember.computed('stats.@each.{count,title}', 'order_configurations_total', function () {
      let colors = ['#7DCA60', '#577101', '#DA4167', '#FCCA46'];
      let data = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          percents: [],
          borderWidth: []
        }]
      };
      let total = this.get('order_configurations_total');
      let intl = this.get('intl');
      let configurations = (this.get('stats') || Ember.A()).sortBy('count');
      let largest_levels = configurations.slice(-4);
      let total_left = largest_levels.mapBy('count').reduce(function (a, b) {
        return a + b;
      }, 0);
      largest_levels.forEach(function (item, i) {
        let percent = item.get('count') / total;
        data.labels.push(item.get('title'));
        data.datasets[0].data.push(item.get('count'));
        data.datasets[0].backgroundColor.push(colors[i]);
        data.datasets[0].percents.push(intl.formatNumber(percent, {
          style: 'percent'
        }));
        data.datasets[0].borderWidth.push(0);
      });

      if (configurations.get('length') > data.labels.length) {
        let other = total - total_left;
        let other_percent = other / total;
        data.labels.unshift("Everything else");
        data.datasets[0].data.unshift(other);
        data.datasets[0].backgroundColor.unshift("#F3F6FA");
        data.datasets[0].percents.unshift(intl.formatNumber(other_percent, {
          style: 'percent'
        }));
        data.datasets[0].borderWidth.unshift(0);
      }

      return data;
    })
  });

  _exports.default = _default;
});