define("manage/pods/companies/company/projects/project/theme/messages/index/route", ["exports", "manage/mixins/handle-transition"], function (_exports, _handleTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    model() {
      return this.modelFor('companies.company.projects.project.theme');
    },

    redirect(model) {
      this.transitionTo('companies.company.projects.project.theme.messages.edit', model.locales.firstObject);
    }

  });

  _exports.default = _default;
});