define("manage/pods/companies/company/projects/project/info/shipping/controller", ["exports", "manage/mixins/filters/info"], function (_exports, _info) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const generateLocations = function (parentModelName, productModelName) {
    return Ember.computed(`${parentModelName}.@each.${productModelName}`, function () {
      let results = {};

      for (let parent of this.get(parentModelName).toArray()) {
        let product = parent.get(productModelName);
        let zone_locations = product.get('shipping_product_based_zone_locations');

        for (let zone_location of zone_locations.toArray()) {
          // Doesn't really matter what we push in, as long as it has
          // is_everywhere, country, state
          results[`${zone_location.get('country.id')}/${zone_location.get('state.id')}`] = zone_location;
        }
      }

      return Object.values(results).sortBy('country.name');
    });
  };

  const generateMappingLocations = function (parentModelName, productModelName) {
    return Ember.computed(`${parentModelName}.@each.${productModelName}`, function () {
      let results = {};

      for (let parent of this.get(parentModelName).toArray()) {
        let product = parent.get(productModelName);

        if (Ember.isBlank(product) || Ember.isBlank(product.get('id'))) {
          continue;
        }

        let mappings = product.get('mappings');

        for (let mapping of mappings.toArray()) {
          let child_product = mapping.get('child');
          let zone_locations = child_product.get('shipping_product_based_zone_locations');

          for (let zone_location of zone_locations.toArray()) {
            // Doesn't really matter what we push in, as long as it has
            // is_everywhere, country, state
            results[`${zone_location.get('country.id')}/${zone_location.get('state.id')}`] = zone_location;
          }
        }
      }

      return Object.values(results).sortBy('country.name');
    });
  };

  var _default = Ember.Controller.extend(_info.default, {
    project: Ember.computed.readOnly('model'),
    product_reward_locations: generateLocations('configurations_sorted', 'product_reward'),
    configuration_extra_locations: generateMappingLocations('configurations_sorted', 'product_extra'),
    configuration_extra_locations_columns: Ember.computed('configuration_extra_locations.length', function () {
      return this.get('configuration_extra_locations.length') + 1;
    }),
    source_extra_locations: generateMappingLocations('project.sources', 'product_extra_global'),
    source_extra_locations_columns: Ember.computed('source_extra_locations.length', function () {
      return this.get('source_extra_locations.length') + 1;
    })
  });

  _exports.default = _default;
});