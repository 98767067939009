define("manage/pods/companies/company/projects/project/segments/actions/components/action-row/component", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['component', 'project-segment-action-row'],
    attributeBindings: ['segment_id:data-test-segment-action-row'],
    segment_id: Ember.computed.readOnly('project_segment_action.id'),
    current_user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    view_action: () => {},
    actions: {
      reload_action(project_segment_action) {
        project_segment_action.reload();
      },

      cancel_action(project_segment_action) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'segment-action-cancel',
          i18nParams: {},
          approve: () => project_segment_action.cancel()
        });
      },

      restart_action(project_segment_action) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'segment-action-restart',
          i18nParams: {},
          approve: () => project_segment_action.restart()
        });
      },

      view_action(project_segment_action) {
        return this.view_action(project_segment_action);
      }

    }
  });

  _exports.default = _default;
});