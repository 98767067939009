define("manage/pods/admin/companies/company/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    company: Ember.computed.readOnly('model')
  });

  _exports.default = _default;
});