define("manage/utils/logger", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "logger_error", {
    enumerable: true,
    get: function () {
      return _logger.logger_error;
    }
  });
  Object.defineProperty(_exports, "logger_info", {
    enumerable: true,
    get: function () {
      return _logger.logger_info;
    }
  });
  Object.defineProperty(_exports, "logger_warn", {
    enumerable: true,
    get: function () {
      return _logger.logger_warn;
    }
  });
});