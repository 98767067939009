define("manage/components/job-progress/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 2000;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    jobId: null,
    currentJobId: null,
    job: null,
    jobLastUpdate: null,

    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('jobId'))) {
        this.get('loadJobTask').perform(this.get('jobId'));
      }
    },

    checkNextJobId: Ember.observer('job.extra.next_job_id', function () {
      if (Ember.isPresent(this.get('job.extra.next_job_id'))) {
        this.set('jobId', this.get('job.extra.next_job_id'));
        this.get('loadJobTask').perform(this.get('jobId'));
      }
    }),

    retrieved() {},

    updated() {},

    complete() {},

    failed() {},

    loadJobTask: (0, _emberConcurrency.task)({
      attempts: 0,

      *perform(jobId) {
        let owner = this.get('owner');

        try {
          // loadJobTask could get called multiple times for the same job
          // If it does, just return
          if (Ember.isPresent(owner.get('currentJobId'))) {
            if (owner.get('currentJobId') === jobId) {
              return;
            }
          }

          owner.set('currentJobId', jobId); // We have either a new job, or not job.
          // If we have an old one unsubscribe

          let oldJob = yield this.get('job');

          if (Ember.isPresent(oldJob)) {
            owner.set('job', null);
          }

          let job = null;

          do {
            try {
              this.incrementProperty('attempts');
              job = yield owner.get('store').findRecord('job', jobId);
            } catch (e) {
              if (this.get('attempts') >= 5) {
                owner.get('failed')({
                  message: 'Unable to determine the job progress'
                });
                throw 'Unable to determine the job progress';
              }

              yield (0, _emberConcurrency.timeout)(DELAY);
            }
          } while (!job);

          if (job) {
            owner.set('job', job);
            owner.get('retrieved')(job);
            owner.get('checkJobStatusTask').perform();
          } else {
            // If we don't have a job, clear the current JobId
            owner.set('currentJobId', null);
          }
        } catch (e) {
          // Something died, clear the current Job Id
          owner.set('currentJobId', null);
          throw e;
        }
      }

    }),
    checkStatus: Ember.observer('job.{is_complete,is_failed}', function () {
      // The optional 'processed', 'failures', and 'success' that can be returned by the
      // job's extra hash are returned as strings. Changing api to return integers means
      // going into the bowels of the sidekiq-history gem. Since we're deprecating
      // Sidekiq, we'll opt to translate these figures client-side instead.
      let job = this.get('job');
      ['processed', 'failures', 'success'].forEach(prop => {
        if (job.extra && typeof job.extra[prop] === 'string') {
          job.extra[prop] = parseInt(job.extra[prop]);
        }
      });

      if (this.get('job.is_complete')) {
        this.get('complete')(job);
      } else if (this.get('job.is_failed')) {
        this.get('failed')(Object.assign({
          message: 'The job failed'
        }, job.getProperties('message', 'extra')));
      }
    }),
    checkJobStatusTask: (0, _emberConcurrency.task)(function* () {
      do {
        let jobLastUpdate = this.get('jobLastUpdate');
        let currentTime = new Date().getTime();

        if (currentTime - jobLastUpdate > DELAY + DELAY / 50) {
          try {
            let lastUpdatedAt = this.get('job.updated_at');
            yield this.get('job').reload();

            if (!this.get('isDestroyed')) {
              if (lastUpdatedAt != this.get('job.updated_at')) {
                this.get('updated')(this.get('job'));
              }

              this.set('jobLastUpdate', new Date().getTime());
            }
          } catch (e) {// Continue
          }
        }

        if (!this.get('isDestroyed')) {
          yield (0, _emberConcurrency.timeout)(DELAY);
        }
      } while (this.get('job') && !this.get('job.is_done') && !this.get('isDestroyed') && !Ember.testing);
    }).drop()
  });

  _exports.default = _default;
});