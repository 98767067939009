define("manage/pods/companies/company/disabled/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UMzwx+3h",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Company Disabled\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui header\"],[10,\"style\",\"width: 410px; margin: 50px auto 40px; color: #4a4a4a; font-size: 26px; line-height: 32px; font-weight: 600;\"],[8],[0,\"\\n  Access for \"],[1,[24,[\"company\",\"name\"]],false],[0,\" has been disabled\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[10,\"style\",\"margin-top: 25px;\"],[8],[0,\"\\n    If you feel that this is an error, please reach out to customer support at \"],[7,\"a\",true],[10,\"href\",\"mailto:hello@crowdox.com\"],[8],[0,\"hello@crowdox.com\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/disabled/template.hbs"
    }
  });

  _exports.default = _default;
});