define("manage/pods/companies/company/projects/project/orders/view/components/view-download-row/component", ["exports", "ember-concurrency", "manage/mixins/copy-text-actions"], function (_exports, _emberConcurrency, _copyTextActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_copyTextActions.default, {
    tagName: 'tr',
    resetDownloadTask: (0, _emberConcurrency.task)(function* (download) {
      download = yield download;
      download.set('downloads', 0);
      yield download.save();
    }),
    actions: {
      resetDownloads() {
        if (this.get('resetDownloadTask.isRunning')) {
          return;
        }

        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'reset-download',
          i18nParams: {
            limit: this.get('download.digital_download.download_limit')
          },
          approve: () => {
            return this.get('resetDownloadTask').perform(this.get('download'));
          }
        });
      }

    }
  });

  _exports.default = _default;
});