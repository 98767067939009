define("manage/mixins/models/order/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    validated_fully: Ember.computed('validation_level', 'override_address_validation', function () {
      return this.get('validation_level') === 'full' && !this.get('override_address_validation');
    }),
    validated_partially: Ember.computed('validation_level', 'override_address_validation', function () {
      return this.get('validation_level') === 'partial' && !this.get('override_address_validation');
    }),
    show_validation_result: Ember.computed.or('validated_fully', 'validated_partially')
  });

  _exports.default = _default;
});