define("manage/models/user", ["exports", "manage/utils/generated-model", "ember-cp-validations"], function (_exports, _generatedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Validations are here in stead of on shared, because it should be a manage only concern
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: "Email",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    full_name: {
      description: "Full Name",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    role: {
      description: "Role",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['admin', 'creator']
      })]
    },
    companies: {
      description: "Companies",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        // if its not a question, its required, if it is it should be blank
        presence: Ember.computed.readOnly('model.is_creator'),
        disabled: Ember.computed.readOnly('model.is_admin')
      })]
    }
  });

  var _default = (0, _generatedModel.default)('user').extend(Validations, {
    ajax: Ember.inject.service(),
    is_admin: Ember.computed.equal('role', 'admin'),
    is_creator: Ember.computed.equal('role', 'creator'),

    resend_invite() {
      return this.get('ajax').postModel(this, 'resend_invite', {
        data: {
          'users[][id]': this.get('id')
        }
      });
    }

  });

  _exports.default = _default;
});