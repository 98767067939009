define("manage/pods/companies/company/projects/project/components/upload-customers-modal/component", ["exports", "manage/helpers/order-query-params", "manage/config/environment", "jquery", "manage/mixins/ss-modal"], function (_exports, _orderQueryParams, _environment, _jquery, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['upload-customers-modal'],
    ajax: Ember.inject.service(),
    user: Ember.inject.service(),
    router: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    importUrl: Ember.computed('project.id', 'source.id', 'fileId', 'fileName', function () {
      let queryString = _jquery.default.param({
        preview: false,
        project_id: this.get('project.id'),
        source_id: this.get('source.id'),
        file_id: this.get('fileId'),
        file_name: this.get('fileName')
      });

      return `/api/v2/orders/import?${queryString}`;
    }),
    importPreviewUrl: '/api/v2/orders/import?preview=true',
    mode: 'preview',
    source: null,

    init() {
      this._super(...arguments); // If there's only one possible source, go ahead and select that source for the user.


      if (Ember.isEmpty(this.get('source')) && this.get('selectableSources.length') == 1) {
        this.set('source', this.get('selectableSources')[0]);
      }
    },

    isValid: Ember.computed('source.site', function () {
      return this.get('source.site') === "kickstarter" || this.get('source.site') === "indiegogo" || this.get('source.site') === "other" || this.get('source.site') === "crowdox" || this.get('source.site') === "squarespace" || this.get('source.site') === "celery" || this.get('source.site') === "shopify" || this.get('source.site') === "fig";
    }),
    selectableSources: Ember.computed('project', 'project.sources', function () {
      return this.get('project.sources').filterBy('import_type', 'file');
    }),

    processJobResult(result) {
      this.set('error', null);
      this.set('jobId', result.data.id);

      if (result.meta) {
        this.set('fileId', result.meta.file_id);
        this.set('fileName', result.meta.file_name);
      }
    },

    actions: {
      cancel() {
        this.closeModal();
      },

      uploadSuccess(result) {
        this.processJobResult(result);
        this.set('mode', 'preview');
      },

      uploadError(xhr) {
        this.send('open_modal', 'error-modal', {
          error: xhr.responseText,
          description: "Unable to process"
        });
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      },

      processNow() {
        this.set('jobId', null);
        this.get('ajax').post(this.get('importUrl')).then(result => {
          this.processJobResult({
            data: {
              id: result.data.id
            }
          });
          this.set('mode', 'live');
        }).catch(xhr => {
          this.processError(xhr);
        });
      },

      viewOrders(project_segment_id) {
        this.router.transitionTo('companies.company.projects.project.orders.index', {
          queryParams: (0, _orderQueryParams.orderQueryParams)(null, {
            project_segment: project_segment_id
          })
        });
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});