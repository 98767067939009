define("manage/services/app-registry", ["exports", "shipment-tracking", "external-sources", "order-disputes", "order-balances", "order-locking", "order-exporting", "order-printing"], function (_exports, _shipmentTracking, _externalSources, _orderDisputes, _orderBalances, _orderLocking, _orderExporting, _orderPrinting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    current_user: Ember.inject.service('user'),

    forProject() {
      let services = [];
      services.push(Ember.Object.create({
        app: _orderExporting.default,
        access: true
      }));
      services.push(Ember.Object.create({
        app: _shipmentTracking.default,
        access: true
      }));
      services.push(Ember.Object.create({
        app: _externalSources.default,
        access: true
      }));
      services.push(Ember.Object.create({
        app: _orderDisputes.default,
        access: true
      }));
      services.push(Ember.Object.create({
        app: _orderLocking.default,
        access: true
      }));
      services.push(Ember.Object.create({
        app: _orderBalances.default,
        access: true
      }));
      services.push(Ember.Object.create({
        app: _orderPrinting.default,
        access: true
      }));
      return services;
    }

  });

  _exports.default = _default;
});