define("manage/components/order-export-name/component", ["exports", "manage/mixins/order-filters"], function (_exports, _orderFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_orderFilters.default, {
    tagName: 'span',
    friendly_text: null,

    init() {
      this._super(...arguments);

      this.build_filter_selections(this.get('filter_data'));
      this.set('filters', Ember.A());
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.processData();
    },

    processData() {
      this.set('filters', Ember.A());
      this.process_filters_from_data();
      this.process_friendly_text();
    },

    watchForChanges: Ember.observer(...Object.keys(_orderFilters.filters).map(f => `filter_data.${f}`).concat([function () {
      this.processData();
    }])),

    update_friendly(friendly_text) {
      this.set('friendly_text', friendly_text || 'All Orders');
    }

  });

  _exports.default = _default;
});