define("manage/pods/companies/company/projects/project/orders/view/components/uncancel-order-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    has_error: false,
    actions: {
      approve() {
        if (this.get('loading')) {
          return;
        }

        this.set('loading', true);
        this.get('order').set('canceled_on', null);
        this.get('order').set('refused_on', null);
        this.get('order').save().then(() => {
          this.closeModal();
        }).catch(() => {
          this.get('order').rollbackAttributes();
          this.set('has_error', true);
        }).finally(() => {
          this.set('loading', false);
        });
      }

    }
  });

  _exports.default = _default;
});