define("manage/pods/companies/company/projects/project/products/components/row-product-variation-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5+mcySP7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"name\",\"value\"],[\"hidden\",\"utf8\",\"✓\"]]],false],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"class\",\"name\"],[\"file\",\"hidden\",\"file\"]]],false],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\"],[\"hidden\",\"project_id\",[24,[\"variation\",\"project\",\"id\"]]]]],false],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\"],[\"hidden\",\"type\",\"product-variation\"]]],false],[0,\"\\n\"],[14,1,[[24,[\"uploading\"]],[28,\"action\",[[23,0,[]],\"upload\"],null],[28,\"action\",[[23,0,[]],\"clear_image\"],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/products/components/row-product-variation-upload/template.hbs"
    }
  });

  _exports.default = _default;
});