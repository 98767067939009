define("manage/pods/companies/company/projects/create/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    current_user: Ember.inject.service('user'),
    ajax: Ember.inject.service(),
    company: Ember.computed.readOnly('model.company'),
    project: Ember.computed.readOnly('model.project'),
    categories: Ember.computed.readOnly('model.categories'),
    // This is used for pledge management
    createOrImport: 'import',
    // Image upload
    image_file: null,
    image_src: null,
    // Manually uploaded stream
    saveTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project'); // Save

      yield project.save();
      yield this.store.findRecord('project', project.get('id')); // If we're manually creating a pledge management project, that isn't imported.
      // We must create the Late Pledge source since it won't be auto created on the backend

      if (project.get('purpose') === 'pledge_management') {
        // Find the app
        let crowdox_app = yield this.store.query('project-app', {
          filter: {
            project: project.get('id'),
            installed: false,
            type_slug: 'sales-sources',
            app_slug: 'source-crowdox'
          }
        }).then(results => {
          return results.objectAt(0);
        }); // Install the app

        yield crowdox_app.install(project, {
          url: `https://app.crowdox.com/projects/${project.get('slug')}`
        });
      } // Reload the project


      yield this.store.findRecord('project', project.get('id'), {
        include: 'sources'
      });

      if (Ember.isPresent(this.get('image_file'))) {
        // We have to add the image after the project was created
        let form_data = new FormData();
        form_data.append('file', this.get('image_file'));
        form_data.append('utf8', '✓');
        form_data.append('type', 'project');
        form_data.append('project_id', project.get('id'));
        let result = yield this.get('ajax').post('/images', {
          processData: false,
          contentType: false,
          data: form_data
        });
        project.set('image_path', result.image_path);
        yield project.save();
      }
    }),
    parent_categories: Ember.computed('categories.@each.parent_category', function () {
      return this.get('categories').filter(category => category.get('parent_category.id') == null);
    }),
    actions: {
      uploaded(file) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          // Only set image_file once we have read that data
          this.set('image_file', file);
          this.set('image_src', reader.result);
        }, false);
        reader.readAsDataURL(file);
      },

      clear_image() {
        this.set('image_file', null);
        this.set('image_src', null);
      },

      save() {
        if (this.get('saveTask.isRunning')) {
          return;
        }

        if (this.get('project.purpose') === 'pledge_management') {
          this.set('project.shipping_type', 'product_price');
        } else {
          this.set('project.shipping_type', 'product_weight');
        }

        this.get('saveTask').perform().then(() => this.transitionToRoute('companies.company.projects.project', this.get('project.id')));
      },

      clear_child_category(project) {
        project.set('child_category', null);
      },

      onCountryChange(country) {
        this.set('project.country', country);
        this.set('project.currency_country', country);
        this.set('project.currency', country.get('currency_code'));
        this.set('project.currency_symbol', country.get('currency_symbol'));
      },

      project_imported(project) {
        this.set('model.project', project);
        this.transitionToRoute('companies.company.projects.project', this.get('project.id'));
      }

    }
  });

  _exports.default = _default;
});