define("manage/pods/companies/company/projects/project/sources/configurations/index/components/column-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KB8Vla+u",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[12,\"class\",\"item link\"]],[[\"@route\",\"@query\"],[\"companies.company.projects.project.orders.index\",[28,\"hash\",null,[[\"configuration\"],[[24,[\"configuration\",\"id\"]]]]]]],{\"statements\":[[0,\"\\n  \"],[1,[24,[\"configuration\",\"stats\",\"total_orders\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/sources/configurations/index/components/column-orders/template.hbs"
    }
  });

  _exports.default = _default;
});