define("manage/templates/components/ss-tab-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BHyR/Ax6",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"tabs\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui top attached tabular menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[11,\"class\",[29,[\"item \",[28,\"if\",[[23,1,[\"isActive\"]],\"active\"],null],\" \",[23,1,[\"class\"]]]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"toggle\",[23,1,[]]],null]],[11,\"data-tab\",[23,1,[\"elementId\"]]],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,2,[[28,\"hash\",null,[[\"tab\"],[[28,\"component\",[[24,[\"tabComponentName\"]]],[[\"menu\"],[[23,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/templates/components/ss-tab-menu.hbs"
    }
  });

  _exports.default = _default;
});