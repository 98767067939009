define("manage/pods/admin/companies/company/components/clone-products-to-project/component", ["exports", "manage/mixins/ss-modal", "ember-concurrency", "ember-cp-validations"], function (_exports, _ssModal, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    project_from: {
      description: "Source Project",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    project_to: {
      description: "Destination Project",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    physical: {
      description: "Physical Product Selection",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    },
    digital: {
      description: "Digital Product Selection",
      validators: [(0, _emberCpValidations.validator)('inclusion', {
        in: [true, false]
      })]
    }
  });

  var _default = Ember.Component.extend(_ssModal.default, Validations, {
    store: Ember.inject.service(),
    // Passed into component
    submitted: false,
    company: null,
    project_from: null,
    project_to: null,
    physical: true,
    digital: true,

    init() {
      this._super(...arguments);

      this.get('initTask').perform();
    },

    isRunning: Ember.computed.or('initTask.isRunning', 'cloneTask.isRunning'),
    initTask: (0, _emberConcurrency.task)(function* () {
      let company = yield this.get('company');
      let projects = yield this.get('store').query('project', {
        filter: {
          company: company.get('id')
        },
        page: {
          size: 100
        },
        include: 'company'
      });
      this.set('projects', projects);
      this.set('jobId', null);
    }),
    cloneTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('submitted', true);
        let {
          validations: validations
        } = yield this.validate();

        if (!validations.get('isValid')) {
          return; // Not valid return so the ui updates
        }

        let company = yield this.get('company');
        let project_from = yield this.get('project_from');
        let project_to = yield this.get('project_to');
        let physical = this.get('physical');
        let digital = this.get('digital');
        let product_types = [];

        if (physical === true) {
          product_types.push('physical');
        }

        if (digital === true) {
          product_types.push('digital');
        }

        if (Ember.isBlank(product_types)) {
          product_types.push('physical');
          product_types.push('digital');
        }

        let result = yield company.clone_products(project_from.get('id'), project_to.get('id'), product_types);
        this.set('jobId', result.data.id);
      } catch (error) {
        alert(`${error}\n\nUnable to clone!`);
      }
    })
  });

  _exports.default = _default;
});