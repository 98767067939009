define("manage/components/job-summary/component", ["exports", "ember-cli-string-helpers/helpers/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const intlPrefix = 'component.job-summary.messages';

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    messages: Ember.computed.alias('job.extra.summary'),
    recordTypes: Ember.computed('mode', function () {
      return this.mode === 'bulk-action' ? 'orders' : 'lines';
    }),
    title: Ember.computed('job.message', 'mode', function () {
      if (this.job && Ember.isPresent(this.job.message)) {
        return this.job.message;
      }

      let intlKey = `component.job-summary.title.${this.mode}`;

      if (this.intl.exists(intlKey)) {
        return this.intl.t(intlKey);
      }

      return null;
    }),
    showPreviewComplete: Ember.computed('job.extra.success', 'mode', 'previewSuccessful', function () {
      if (this.mode !== 'preview') {
        return false;
      }

      if (this.previewSuccessful != null) {
        return this.previewSuccessful;
      }

      return this.job && this.job.extra && this.job.extra.success;
    }),
    translatedMessages: Ember.computed('messages', function () {
      if (Ember.isEmpty(this.messages)) return {};
      let messagesArray = Object.entries(this.messages).map(_ref => {
        let [key, message] = _ref;
        let keyParts = key.split('|'); // Keys can be of the format "key|attr1=value1|attr2=value2"
        // Index 0 has the actual key name
        // Starting at the index 1, parse any params that exist.

        let params = {};

        for (var i = 1; i < keyParts.length; ++i) {
          let [attr, value] = keyParts[i].split('=');
          params[attr] = value;
        }

        let translatedTitle = this.translateMessage('title', keyParts[0], params);

        if (!translatedTitle) {
          // If that doesn't exist, just humanize the key name.
          translatedTitle = (0, _humanize.humanize)([keyParts[0]]);
        }

        let translatedDescription = this.translateMessage('description', keyParts[0], params);

        if (!translatedDescription) {
          // If that doesn't exist, keep the description that was sent to us by the API.
          translatedDescription = message.description;
        }

        return Object.assign({}, message, {
          key: key,
          title: translatedTitle,
          description: translatedDescription
        });
      }); // 'success', then 'skipped', then 'warning', then 'error'

      const levelValues = {
        'success': 1,
        'skipped': 2,
        'warning': 3,
        'error': 4
      }; // Sort first by level value, then by the value of the first line number

      messagesArray.sort((a, b) => {
        return levelValues[a.level] - levelValues[b.level] || a.records[0] - b.records[0];
      });
      return messagesArray;
    }),

    translateMessage(keyType, key, params) {
      let intlKey;

      if (this.mode == 'preview') {
        intlKey = `${intlPrefix}.${this.source}.${keyType}.preview.${key}`;

        if (this.intl.exists(intlKey)) {
          return this.intl.t(intlKey, params);
        }
      }

      intlKey = `${intlPrefix}.${this.source}.${keyType}.${key}`;

      if (this.intl.exists(intlKey)) {
        return this.intl.t(intlKey, params);
      }

      return null;
    }

  });

  _exports.default = _default;
});