define("manage/pods/redirect/route", ["exports", "ember-concurrency", "shared/utils/logger", "manage/models/order", "manage/models/product", "manage/models/project-configuration", "manage/models/project-source", "manage/models/project", "manage/models/company"], function (_exports, _emberConcurrency, _logger, _order, _product, _projectConfiguration, _projectSource, _project, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),

    model(params) {
      return this.get('getModel').perform(params);
    },

    getModel: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params.entity) || Ember.isBlank(params.entity_id)) {
        return null;
      }

      let entity = params.entity.toLowerCase().trim();
      let entity_id = params.entity_id.toLowerCase().trim();

      try {
        // o is reserved for order
        if (["order", "o"].includes(entity)) {
          return yield this.store.findRecord('order', entity_id, {
            include: 'project,project.company'
          });
        } // pd is reserved for product


        if (["product", "question", "choice", "pd"].includes(entity)) {
          return yield this.store.findRecord('product', entity_id, {
            include: 'project,project.company'
          });
        } // pc is reserved for project configuration


        if (["project_configuration", "configuration", "pc"].includes(entity)) {
          return yield this.store.findRecord('project-configuration', entity_id, {
            include: 'project,project.company,source'
          });
        } // s is reserved for project source


        if (["project_source", "source", "s"].includes(entity)) {
          return yield this.store.findRecord('project-source', entity_id, {
            include: 'project,project.company'
          });
        } // p is reserved for project


        if (["project", "p"].includes(entity)) {
          return yield this.store.findRecord('project', entity_id, {
            include: 'company'
          });
        } // c is reserved for company


        if (["company", "c"].includes(entity)) {
          return yield this.store.findRecord('company', entity_id);
        }
      } catch (error) {
        (0, _logger.logger_error)(`Unable to find entity:${entity} with id:${entity_id} on redirect`);
        (0, _logger.logger_error)(error);
        return null;
      }
    }),

    redirect(model) {
      if (model == null) {
        this.replaceWith('not-found');
        return;
      }

      if (model instanceof _order.default) {
        this.replaceWith(`/c/${model.get('project.company.id')}/projects/${model.get('project.id')}/orders/${model.get('id')}`);
        return;
      }

      if (model instanceof _product.default) {
        if (model.get('product_type') === 'question') {
          this.replaceWith(`/c/${model.get('project.company.id')}/projects/${model.get('project.id')}/questions/${model.get('id')}`);
          return;
        }

        if (['bundle', 'option'].includes(model.get('product_type'))) {
          this.replaceWith(`/c/${model.get('project.company.id')}/projects/${model.get('project.id')}/products/choices/${model.get('id')}`);
          return;
        } // Default to standard product


        this.replaceWith(`/c/${model.get('project.company.id')}/projects/${model.get('project.id')}/products/${model.get('id')}`);
        return;
      }

      if (model instanceof _projectConfiguration.default) {
        this.replaceWith(`/c/${model.get('project.company.id')}/projects/${model.get('project.id')}/sources/${model.get('source.id')}/configurations/${model.get('id')}`);
        return;
      }

      if (model instanceof _projectSource.default) {
        this.replaceWith(`/c/${model.get('project.company.id')}/projects/${model.get('project.id')}/sources/${model.get('id')}`);
        return;
      }

      if (model instanceof _project.default) {
        this.replaceWith(`/c/${model.get('company.id')}/projects/${model.get('id')}`);
        return;
      }

      if (model instanceof _company.default) {
        this.replaceWith(`/c/${model.get('id')}`);
        return;
      }

      (0, _logger.logger_warn)("We had a model and didn't find a route for it. Might want to debug this");
      this.replaceWith('not-found');
    }

  });

  _exports.default = _default;
});