define("manage/pods/companies/company/projects/project/sources/extras/components/section-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    submitted: false,
    errorMessage: null,
    productTasks: Ember.inject.service(),
    intl: Ember.inject.service(),
    // modal properties
    closable: false,

    init() {
      this._super(...arguments); // Initialize productIsNew based on the value when first entering the modal.
      // Otherwise, the text in the header and buttons change immediately after saving
      // a new record but before the modal closes.


      this.set('productIsNew', this.get('product.isNew'));
    },

    actions: {
      approve() {
        this.set('submitted', true);

        if (this.get('product.validations.isInvalid')) {
          return;
        }

        let product = this.get('product');
        this.get('productTasks.save').perform(product).then(() => {
          if (this.productIsNew) {
            this.get('all_products').addObject(product);
          }

          this.closeModal();
        }).catch(() => {
          this.set('errorMessage', this.get('intl').t('component.companies.company.projects.project.sources.extras.section-modal.error.cannot_save'));
        });
      },

      close() {
        Ember.RSVP.resolve(this.get('product')).then(product => {
          product.rollbackAttributes();
          this.closeModal();
        });
      }

    }
  });

  _exports.default = _default;
});