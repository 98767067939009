define("manage/pods/user/reset-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOMvreSW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Reset Password\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui message\"],[8],[0,\"\\n  You should be receiving an email with a reset password link in the next few minutes.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"two fields\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field aligned right\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"user.login\"]],{\"statements\":[[0,\"\\n      Back to Login\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/user/reset-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});