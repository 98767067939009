define("manage/pods/companies/company/projects/project/shipping/local-pickups/index/route", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return this.store.query('project-address', {
        filter: {
          project: project.get('id')
        },
        page: {
          size: _environment.default['max-page-size']
        }
      });
    }

  });

  _exports.default = _default;
});