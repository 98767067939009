define("manage/pods/admin/companies/company/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('company', params.company_id);
    },

    renderTemplate() {
      this.render('shared/main', {
        into: 'application'
      });
      this.render('admin/companies/company', {
        into: 'shared/main'
      });
      this.render('admin/companies/company/menu', {
        into: 'shared/main',
        outlet: 'menu'
      });
    }

  });

  _exports.default = _default;
});