define("manage/pods/companies/company/projects/project/theme/index/route", ["exports", "manage/mixins/handle-transition", "ember-concurrency"], function (_exports, _handleTransition, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_handleTransition.default, {
    model() {
      let project = this.modelFor('companies.company.projects.project');
      return Ember.RSVP.hash({
        project: project
      });
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.set('submitted', false);
      }
    },

    rollbackModelsTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      project.rollbackAttributes();
    }),
    actions: {
      willTransition(transition) {
        var record = this.modelFor(this.routeName);

        if (record && record.project) {
          this.handleTransition(transition, resolve => {
            if (record.project.get('hasDirtyAttributes')) {
              this.send('open_modal', 'rollback-modal', {
                model: record.project,
                modelName: "Theme Settings",
                serviceTaskAction: this.get('rollbackModelsTask'),
                promiseResolve: resolve
              });
            } else {
              // We don't have a dirty model, so mark as handled and retry
              resolve('retry');
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});