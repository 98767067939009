define("manage/pods/companies/company/account/invoices/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    current_user: Ember.inject.service('user'),
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    model(params) {
      let company = this.modelFor('companies.company');
      return Ember.RSVP.hash({
        company: company,
        invoices: this.store.query('company-invoice', {
          filter: {
            company: company.get('id')
          },
          sort: '-invoice-date',
          page: {
            number: params.page,
            size: 10
          }
        })
      });
    }

  });

  _exports.default = _default;
});