define("manage/models/project-address", ["exports", "manage/utils/generated-model"], function (_exports, _generatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _generatedModel.default)('project-address').reopen({
    state_name: Ember.computed('state.abbr', 'state_text', function () {
      if (Ember.isPresent(this.get('state.abbr'))) {
        return this.get('state.abbr');
      }

      return this.get('state_text');
    }),
    summary: Ember.computed('name', 'city', 'state_name', 'country.name', function () {
      let name = this.get('name');
      let parts = Ember.A();

      if (Ember.isPresent(this.get('city'))) {
        parts.push(this.get('city'));
      }

      if (Ember.isPresent(this.get('state_name'))) {
        parts.push(this.get('state_name'));
      }

      if (Ember.isPresent(this.get('country.name'))) {
        parts.push(this.get('country.name'));
      }

      if (Ember.isPresent(name) && Ember.isPresent(parts)) {
        return `${name} - ${parts.join(', ')}`;
      }

      if (Ember.isPresent(name)) {
        return name;
      }

      if (Ember.isPresent(parts)) {
        return parts.join(', ');
      }

      return "Local Pickup - Missing Information";
    }),
    detail: Ember.computed('name', 'address_1', 'address_2', 'city', 'state_name', 'postal_code', 'country', function () {
      let [name, address1, address2, city, state_name, zip, countryName] = ['name', 'address_1', 'address_2', 'city', 'state_name', 'postal_code', 'country.name'].map(property => {
        if (Ember.isPresent(this.get(property))) {
          return Ember.Handlebars.Utils.escapeExpression(this.get(property));
        }
      });
      let cityState = [city, state_name].filter(x => Ember.isPresent(x)).join(', ');
      let cityStateZip = [cityState, zip].filter(x => Ember.isPresent(x)).join(' ');
      let lines = [];

      if (Ember.isPresent(name)) {
        lines.push(`<b>${name}</b>`);
      }

      [address1, address2, cityStateZip, countryName].forEach(function (line) {
        if (Ember.isPresent(line)) {
          lines.push(line);
        }
      });

      if (lines.length > 0) {
        return Ember.String.htmlSafe(lines.join('<br>'));
      }

      return "Local Pickup - Missing Information";
    }),
    is_usa: Ember.computed.equal('country.iso3', 'USA'),
    is_canada: Ember.computed.equal('country.iso3', 'CAN'),
    is_international: Ember.computed('country.iso3', 'project.country.iso3', function () {
      if ((this.get('country.iso3') || "").trim().toLowerCase() === (this.get('project.country.iso3') || "").trim().toLowerCase()) {
        // If country is blank or USA, assume domestic
        return false;
      } else {
        // Everything else must be international
        return true;
      }
    })
  });

  _exports.default = _default;
});