define("manage/pods/companies/company/projects/project/sources/configurations/components/clone-configuration-modal/component", ["exports", "manage/mixins/ss-modal"], function (_exports, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    classNames: ['clone-configuration-modal', 'component'],
    ajax: Ember.inject.service(),
    user: Ember.inject.service(),
    configuration: null,
    name: null,
    description: null,
    clone_url: Ember.computed('configuration', function () {
      return this.get('ajax').buildModelUrl(this.get('configuration'), 'clone');
    }),

    init() {
      this._super(...arguments);

      this.set('name', this.get('configuration.product_reward.name'));
      this.set('description', this.get('configuration.product_reward.description'));
      this.set('source', this.get('configuration.source'));
    },

    selectableSources: Ember.computed('user.is_admin', 'source', 'configuration.project.sources.[]', function () {
      if (this.get('user.is_admin')) {
        return this.get('configuration.project.sources');
      } else {
        return Ember.A([this.get('source')]);
      }
    }),
    actions: {
      approve() {
        let data = {};

        if (this.get('name') !== this.get('configuration.product_reward.name')) {
          data.name = this.get('name');
        }

        if (this.get('description') !== this.get('configuration.product_reward.description')) {
          data.description = this.get('description');
        }

        if (this.get('source.id') !== this.get('configuration.source.id')) {
          data.project_source_id = this.get('source.id');
        }

        this.get('ajax').post(this.get('clone_url'), {
          data: data
        }).then(result => {
          this.closeModal();
          this.get('onSuccess')(result);
        }).catch(error => {
          this.get('onFailure')(error);
        });
      }

    }
  });

  _exports.default = _default;
});