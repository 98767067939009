define("manage/pods/companies/company/projects/project/orders/view/components/uncancel-order-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SrZt5bpT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Un-Cancel Order\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic segment content \",[28,\"if\",[[24,[\"is_processing\"]],\"loading\",\"\"],null],\" \",[28,\"if\",[[24,[\"has_error\"]],\"error\",\"\"],null]]]],[8],[0,\"\\n  This will reverse a previous cancelation created within manage or a refusal by a customer.\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui small orange header\"],[8],[0,\"\\n    Would you like un-cancel \"],[1,[24,[\"order\",\"customer\",\"full_name\"]],false],[0,\"?\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui error message\"],[8],[0,\"\\n    An error occurred while saving this customers order. If this continues please contact support.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button cancel\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"No\"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"Yes\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "manage/pods/companies/company/projects/project/orders/view/components/uncancel-order-modal/template.hbs"
    }
  });

  _exports.default = _default;
});