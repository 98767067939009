define("manage/pods/companies/company/projects/project/segments/components/bulk-action-modal/component", ["exports", "manage/mixins/ss-modal", "manage/mixins/segment-action"], function (_exports, _ssModal, _segmentAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, _segmentAction.default, {
    classNames: ['segment-modal', 'component'],
    classNameBindings: ['specificModalClass'],
    closable: false,
    type: Ember.computed.alias('modalType'),
    specificModalClass: Ember.computed('type', function () {
      return `${this.type}-modal`;
    }),
    actions: {
      perform() {
        this.set('submitted', true);

        if (this.get('actionTask.isRunning')) {
          return;
        }

        this.get('actionTask').perform();
      },

      jobFailed(error) {
        this.set('error', error.message);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});