define("manage/pods/companies/company/projects/status/controller", ["exports", "ember-concurrency", "shared/utils/logger"], function (_exports, _emberConcurrency, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('model.company'),
    projects: Ember.computed.readOnly('model.projects'),
    current_user: Ember.inject.service('user'),
    sorted_projects: Ember.computed('projects', function () {
      return this.get('projects').sortBy('created_at').reverse();
    }),
    fulfillment_statuses: Ember.A([{
      groupName: "Fulfillment Status",
      options: Ember.A([Ember.Object.create({
        value: "fulfilled",
        name: "Majority fulfilled (60% fulfilled or more)"
      }), Ember.Object.create({
        value: "partially_fulfilled",
        name: "Started fulfillment"
      }), Ember.Object.create({
        value: "not_fulfilled",
        name: "Not yet (gathering information or preparing)"
      })])
    }, {
      groupName: "Other",
      options: Ember.A([Ember.Object.create({
        value: "unable_to_fulfill",
        name: "Will not be fulfilling"
      })])
    }]),
    queryParams: ['project_id', 'fulfillment_status'],
    saveProjectTask: (0, _emberConcurrency.task)(function* (project) {
      project = yield project;
      project.set('taskStatus', 'saving');

      try {
        // If its a creator, the api updates automatically, but if its an admin, we need to set it.
        project.set('fulfillment_status_updated_by', 'creator');
        yield project.save();
        project.set('taskStatus', 'saved');
      } catch (e) {
        (0, _logger.logger_error)(e.message);
        project.set('taskStatus', 'error');
      }
    })
  });

  _exports.default = _default;
});