define("manage/pods/companies/company/projects/project/shipping/local-pickups/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project_addresses: Ember.computed.readOnly('model'),
    current_user: Ember.inject.service('user'),
    // Sorting
    sortProperty: 'id',
    sortDirection: 'asc',
    sorted_by: ['id:asc'],
    sorted_project_addresses: Ember.computed.sort('project_addresses', 'sorted_by'),
    actions: {
      sort(name, direction) {
        if (this.get('sortProperty') !== name) {
          this.set('sortProperty', name);
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortProperty', name);
          this.set('sortDirection', direction);
        }

        return this.set('sorted_by', [`${this.get('sortProperty')}:${this.get('sortDirection')}`]);
      },

      delete_local_pickup(project_addresses) {
        this.send('open_modal', 'confirm-modal', {
          i18nKey: 'delete-local-pickup',
          i18nParams: {
            name: project_addresses.get('summary')
          },
          approve: () => {
            return project_addresses.destroyRecord();
          }
        });
      }

    }
  });

  _exports.default = _default;
});